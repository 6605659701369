import React, { useState } from "react";
import UserProfilePopup from "../../pages/profile/components/UserProfilePopup";

const User = ({ img, fullName, role, userEmail, firstName, lastName }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();
  const [userDetails, setUserDetails] = useState({
    firstName,
    lastName,
    userEmail: userEmail,
    userImageSrc: img,
    roleName: role,
  });

  const handleModalOpen = (newPlacement) => (event) => {
    setModalOpen(!modalOpen);
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="user">
      <div style={{ height: "44px", display: "flex", alignItems: "center" }}>
        <img
          src={img}
          alt="user"
          height="35"
          width="35"
          style={{ borderRadius: "100%", cursor: "pointer" }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/icons/default-avatar.svg";
          }}
          onMouseOver={handleModalOpen("left-end")}
          onMouseOut={handleModalClose}
        />
        <UserProfilePopup
          openModal={modalOpen}
          onClose={handleModalClose}
          userDetails={userDetails}
          isHeader={false}
          //isDashboard={true}
          isDashboardTable={false}
          isCollaborators={true}
          placement={placement}
          anchorEl={anchorEl}
        />
      </div>
      <h3>
        {fullName} {role ? `(${role})` : ""}
      </h3>
    </div>
  );
};

export default User;
