import React from "react";
import AnalysisPage from "./components/AnalysisPage";
import "./AiAnalytics.scss";
import "./PreviousRuns.scss";

const AIAnalytics = () => {
  return (
    <AnalysisPage />
    // <PreviousRuns />
  );
};

export default AIAnalytics;
