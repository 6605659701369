import React from "react";
import OpenseadragonViewer from "../../../imageViewer/components/react-openseadragon-viewer";
import AnalysisAreaPreview from "./AnalysisAreaPreview";

const SlideViewerHighlightAreas = (slideData) => {
  return (
    <div className={"analysis-slide-viewer full-width"}>
      <div className="osd osd-viewer-page">
        <div className="row osd-row">
          <OpenseadragonViewer
            {...slideData}
            when={"analytics"}
            showToolbar={true}
            selectAnalysisType={slideData.selectAnalysisType}
          />
        </div>
      </div>

      <AnalysisAreaPreview
        setContinueButtonDisabled={slideData.setContinueButtonDisabled}
        annotationCoordinates={slideData.annotationCoordinates}
        selectedAreaName={slideData.selectedAreaName}
        setSelectedAreaName={slideData.setSelectedAreaName}
      />
    </div>
  );
};

export default SlideViewerHighlightAreas;
