import React, { useState } from "react";
import { StackedGraphAging } from "./StackedGraphAging";

const data = [
  {
    name: "0-1 day",
    type: 1,
    Pending: 13,
    Review: 12,
  },

  {
    name: "4-2 days",
    type: 1,
    Pending: 8,
    Review: 20,
  },

  {
    name: "2-4 days",
    type: 1,
    Pending: 14,
    Review: 10,
  },
  {
    name: "5+ days",
    type: 1,
    Pending: 11,
    Review: 18,
  },
];

const allKeys = ["Pending", "Review"];

const colors = {
  Review: "#0F0DA1",
  Pending: "#EC60F4",
};

const AverageTimeStatusChart = (props) => {
  const [keys, setKeys] = useState(allKeys);

  return (
    <div className="case-aging-container">
      <StackedGraphAging dateFormat={props.dateFormat} timezone={props.timezone} datasets={data} colors={colors} keys={keys} />
    </div>
  );
};

export default AverageTimeStatusChart;
