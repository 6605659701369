import React from "react";
import { TextField } from "@material-ui/core";
import { FadeIn } from "animate-components";
import Select from "react-select";
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from "../../../../components";
import { emailValidation } from "../../../../utils/utils";
import { Notify } from "../../../../components";
import { connect } from "react-redux";
import AddUserIcon from "../../../../assets/icons/add-new-user.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    color: "#9E9EA6",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#000000",
      fontSize: 14,
    },
  },
}));

const emailErrorMessage = "Enter valid Email";

const AddUser = ({
  showMessage,
  loggedUserDetails,
  addNewUserFn,
  onClose,
  rolesData,
}) => {
  const [userEmail, setUserEmail] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [roleId, setRoleId] = React.useState(null);

  const filterRoles = rolesData.filter(
    (role) => role.roleName !== "System Administrator"
  );
  const roleOptions = filterRoles.map((data) => {
    return {
      value: data.roleId,
      label: data.roleName,
    };
  });
  const handleSaveClick = () => {
    if (!emailValidation(userEmail)) {
      setShowError(true);
      return;
    }
    if (roleId && userEmail) {
      addNewUserFn({
        roleId,
        userEmail,
        dxAccountId: loggedUserDetails.dxAccountId,
      });
      onClose(false);
    } else {
      Notify({ value: "Fill all the feilds" });
    }
  };

  React.useEffect(() => {
    showMessage && setUserEmail("");
  }, [showMessage]);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            title="Add new user"
            onClick={() => onClose(false)}
            img={AddUserIcon}
            alt={"addUser"}
          />
          <div className="modal_middle">
            <div className="form-group">
              <TextField
                placeholder="Email Address"
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                  style: { fontSize: "14px" },
                  disableUnderline: true,
                  disableFocus: true,
                  color: "#9e9ea6 !important",
                }}
                name="email"
                value={userEmail}
                style={{ width: "100%" }}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
                autoFocus
              />
              {showError && (
                <p class="form-invalid-message">{emailErrorMessage}</p>
              )}
            </div>
            <div className="form-group">
              <Select
                options={roleOptions}
                classname="stain-select"
                placeholder="Select the role"
                customStyles={true}
                isSearchable={false}
                style={{
                  width: "100%",
                  height: "30px",
                  input: { fontSize: "14px" },
                  placeholder: { fontSize: "14px" },
                  color: "#9e9ea6",
                  focus: "disabled",
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={({ value }) => setRoleId(value)}
              />
            </div>
          </div>
          <div className="prompt-bottom" style={{ marginTop: 0 }}>
            <SecondaryButton label="Cancel" onClick={() => onClose(false)} />
            <PrimaryButton
              label="Invite"
              onClick={handleSaveClick}
              disabled={showError || !userEmail || !roleId}
              extraClass="prompt-done"
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  rolesData: state.Dashboard.rolesData,
});

export default connect(mapStateToProps)(AddUser);
