import React from "react";
import DefaultAvatar from "../../../assets/icons/default-avatar.svg";

const UserCard = ({ user, addSelf, id }) => {
  return (
    user.id !== id && (
      <div className="userCard" onClick={() => addSelf()}>
        <img
          src={user.user_image_src ? user.user_image_src : DefaultAvatar}
          style={{
            width: "40px",
            height: "40px",
            margin: "5px",
            borderRadius: "25px",
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = DefaultAvatar;
          }}
        />
        <div
          style={{
            height: "55px",
            display: "flex",
            flexDirection: "column",
            paddingTop: "7px",
          }}
        >
          <p className="searchName">
            {(typeof user.firstname !== "undefined"
              ? user.firstname
              : user.firstName) +
              " " +
              user.surname}
          </p>
          <p className="searchName">@{user.username}</p>
        </div>
      </div>
    )
  );
};

export default UserCard;
