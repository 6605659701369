import React from "react";
import { Notify } from "../../../components";
import axios from "../../../utils/axiosInstance";
import { connect } from "react-redux";
import { deleteMessage } from "../actions/groups-action";
import ClosePopupIcon from "../../../assets/icons/close-new.svg";
import DeleteIcon from "../../../assets/icons/delete-icon-new.svg";
const GroupDeleteMessage = ({ close, selectedBoard, toDelete, dispatch }) => {
  const deleteMsg = async () => {
    const { status, data } = await axios
      .put(
        "/api/groups/msgs/delete",
        { tumorBoardId: selectedBoard.id, msgId: toDelete },
        { withCredentials: true }
      )
      .catch(() => Notify({ value: "Error while deleting message." }));
    if (status === 200) {
      dispatch(deleteMessage(toDelete));
      close();
      Notify({ value: "Message deleted successfully." });
    }
  };

  return (
    <div id="create-board-overlay">
      <div id="leave-board">
        <div
          style={{
            width: "100%",
            height: "40px",
            display: "flex",
            flexDirection: "space-between",
          }}
          id="leave-header"
        >
          <div className="left-side">
            <img src={DeleteIcon} alt="delete" />
            <p>
              Delete Message
          </p>
          </div>
          <img
            src={ClosePopupIcon}
            id="close-create-board"
            onClick={() => close()}
          ></img>
        </div>
        <div id="leave-content" style={{ minHeight: 0 }}>
          <div className="content">
            <p style={{ color: "rgb(107, 124, 140)" }}>
              Are you sure you want to delete that message? This action{" "}
              <b>cannot</b> be undone.
            </p>
          </div>
        </div>
        <div id="leave-footer">
          <button id="cancel-btn" onClick={() => close()}>
            Cancel
          </button>
          <button id="leave-btn" onClick={() => deleteMsg()}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedBoard } = state.Groups;
  return {
    selectedBoard,
  };
};

export default connect(mapStateToProps)(GroupDeleteMessage);
