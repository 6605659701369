const initialState = {
  showLoader: false,
  previousRunsData: [],
  runAnalysisDone: false,
  runAnalysisPending: false,
  getAnalysisResultPending: false,
  getAnalysisResultSuccess: false,
  getAnalysisResultError: false,
  analysisResult: [],
  selectedAalysisResult: null,
  selectedAalysisId: null,
  selectedAalysisVisible: false,
  showLegend: true,
};

const mappedSelectedAnalsis = (runId, state, visible) => {
  const newState = state.map((stateObj) => {
    if (runId && stateObj.runId === runId) {
      return {
        ...stateObj,
        selected: visible ? true : false,
      };
    } else {
      return {
        ...stateObj,
      };
    }
  });
  return newState;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_LOADER":
      return {
        ...state,
        showLoader: action.payload,
      };

    case "GET_PREVIOUS_RUNS": {
      return {
        ...state,
        previousRunsData: action.payload,
      };
    }

    case "RUN_ANALYSIS": {
      return {
        ...state,
        runAnalysisDone: action.payload,
      };
    }

    case "RUN_ANALYSIS_PENDING": {
      return {
        ...state,
        runAnalysisPending: action.payload,
      };
    }

    case "DELETE_PREVIOUS_RUNS_ROW": {
      return {
        ...state,
        previousRunsData: state.previousRunsData,
      };
    }

    case "GET_ANALYSIS_RESULT_PENDING": {
      return {
        ...state,
        getAnalysisResultPending: action.status,
      };
    }

    case "GET_ANALYSIS_RESULT_SUCCESS": {
      return {
        ...state,
        getAnalysisResultSuccess: action.status,
        analysisResult: action.payload
          ? mappedSelectedAnalsis(null, action.payload)
          : [],
      };
    }

    case "GET_ANALYSIS_RESULT_ERROR": {
      return {
        ...state,
        getAnalysisResultError: action.status,
      };
    }

    case "SET_ANALYSIS_RESULT_BY_ID": {
      return {
        ...state,
        selectedAalysisResult: action.payload,
        selectedAalysisId: action.runId,
        selectedAalysisVisible: action.visible,
        analysisResult: mappedSelectedAnalsis(
          action.runId,
          state.analysisResult,
          action.visible
        ),
      };
    }

    case "SET_ANALYSIS_HEATMAP_RESULT_BY_ID": {
      return {
        ...state,
        selectedAalysisId: action.runId,
        selectedAalysisVisible: action.visible,
        analysisResult: mappedSelectedAnalsis(
          action.runId,
          state.analysisResult,
          action.visible
        ),
        showLegend: !state.showLegend,
      };
    }

    case "RESET_SET_ANALYSIS_RESULT_BY_ID": {
      return {
        ...state,
        selectedAalysisResult: null,
        selectedAalysisId: null,
        selectedAalysisVisible: false,
        showLegend: false,
      };
    }

    default:
      return state;
  }
};
