import React, { useState, useEffect } from 'react';
import { Table, Space, Spin } from 'antd';
import '../AiAnalytics.scss';
import '../PreviousRuns.scss';
import { Link } from 'react-router-dom';
import {
  getPreviousRunsData,
  deleteRowAction,
} from '../actions/aiAnalytics-action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Notify, Prompt } from '../../../components';
import PreviousDeleteIcon from '../../../assets/icons/previous-delete.svg';
import UpIcon from '../../../assets/icons/up-icons.svg';
import DownIcon from '../../../assets/icons/down-icons.svg';
import defaultAvatar from '../../../assets/icons/default-avatar.svg';

const AnalysisDashboard = ({
  getPreviousRuns,
  previousRunsData,
  deletePreviousRunsRow,
  history,
  showLoader,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const [isExpandId, setIsExpandId] = useState();
  const [isDeleteRowPopUp, setIsDeleteRowPopUp] = useState(false);
  const [deletedRow, setDeletedRow] = useState({});

  useEffect(() => {
    getPreviousRuns();
  }, []);

  const handleExpandColumn = (uniqueid, status) => {
    if (status) {
      setIsExpand(status);
      setIsExpandId(uniqueid);
    }
    setIsExpand(status);
  };

  const showPopup = (record) => {
    setIsDeleteRowPopUp(true);
    setDeletedRow(record);
  }

  const columns = [
    {
      title: '',
      key: 1,
      render: (text, record) => (
        <Space size="middle" className="isExpand">
          <div style={{ marginTop: 10 }} className="run-id">
            {isExpand && isExpandId === record.id ? (
              <span onClick={() => handleExpandColumn(record.id, false)}>
                <img src={DownIcon} alt="" />
              </span>
            ) : (
              <span onClick={() => handleExpandColumn(record.id, true)}>
                <img src={UpIcon} alt="" />
              </span>
            )}
          </div>
        </Space>
      ),
    },
    {
      title: 'RUN ID',
      dataIndex: 'runId',
      key: 'run',
      width: '8%',
      render: (text, record) => (
        <Space size="middle" className="run-details">
          <div style={{ marginTop: 11 }} className="run-id">
            {record.id}
          </div>
        </Space>
      ),
    },
    {
      title: 'SLIDE',
      dataIndex: 'slideName',
      key: 'slide',
      width: '14%',
      render: (text, record) => (
        <Space size="middle" className="slide-details">
          <div style={{ marginTop: 11 }} className="slide-name">
            {text[0].length > 20 ? text[0].substring(0, 15) + '...' : text[0]}
          </div>
        </Space>
      ),
    },
    {
      title: 'CASE NAME',
      dataIndex: 'caseName',
      key: 'case',
      width: '14%',
      render: (text, record) => (
        <Space size="middle" className="slide-details">
          <div style={{ marginTop: 11 }} className="slide-name">
            {text && text.length > 20 ? text.substring(0, 15) + '...' : text}
          </div>
        </Space>
      ),
    },
    {
      title: 'ANALYSIS TYPE',
      dataIndex: 'algorithm',
      key: 'algorithm',
      width: '14%',
    },
    {
      title: 'RESULTS',
      dataIndex: 'status',
      key: 'status',
      width: '18%',
      render: (text, record) => (
        <React.Fragment>
          <div
            className={
              text[0] === 'Paused'
                ? 'run-status-continer paused'
                : text[0] === 'Complete'
                  ? 'run-status-continer complete'
                  : 'run-status-continer pending'
            }
          >
            <span
              className={
                text[0] === 'Paused'
                  ? 'dot paused'
                  : text[0] === 'Complete'
                    ? 'dot complete'
                    : 'dot pending'
              }
            ></span>

            {text[0]}
          </div>
          <span className="status-date">{text[1]}</span>
          {isExpand && isExpandId === record.id ? (
            record.expandedData.analysisResultCount ? (
              <div className="slide-details-section">
                <div className="slide-item">
                  <span>
                    Number of nuclei:{' '}
                    {record.expandedData.analysisResultCount['neuclicCount']}
                  </span>
                </div>
                <div className="slide-item">
                  <span>
                    Nuclei area:{' '}
                    {record.expandedData.analysisResultCount['annotationArea']}
                    <sup>2</sup>
                  </span>
                </div>
                <div className="slide-item">
                  <span>
                    Nuclei density:{' '}
                    {record.expandedData.analysisResultCount['nucleiDensity']}
                    <sup>2</sup>
                  </span>
                </div>
                <div className="slide-item link">
                  {record.expandedData.runStatus === 'done' ? (
                    <span onClick={() => loadInViewer(record.expandedData)}>
                      View result
                    </span>
                  ) : (
                    <span>Result pending</span>
                  )}
                </div>
              </div>
            ) : (
              <div className="slide-details-section">
                <div className="slide-item link">
                  {record.expandedData.runStatus === 'done' ? (
                    <span onClick={() => loadInViewer(record.expandedData)}>
                      View result
                    </span>
                  ) : (
                    <span>Result pending</span>
                  )}
                </div>
              </div>
            )
          ) : (
            ''
          )}
        </React.Fragment>
      ),
    },

    {
      title: 'SLIDE DETAILS',
      dataIndex: 'slideDetails',
      key: 'slide',
      width: '22%',
      render: (text, record) => (
        <div className="slide-details-section">
          <div className="slide-item">
            <span>
              UniqueID:{' '}
              {record &&
                record.expandedData &&
                record.expandedData.slideDetails &&
                record.expandedData.slideDetails['uniqueid'] &&
                record.expandedData.slideDetails['uniqueid'].length > 10
                ? record.expandedData.slideDetails['uniqueid'].substring(
                  0,
                  10
                ) + '...'
                : record.expandedData.slideDetails['uniqueid']}
            </span>
          </div>

          {isExpand && isExpandId === record.id ? (
            <div>
              <div className="slide-item">
                <span>
                  Height: {record.expandedData.slideDetails['height']}
                </span>
              </div>
              <div className="slide-item">
                <span>Width: {record.expandedData.slideDetails['width']}</span>
              </div>
              <div className="slide-item">
                <span>Magnification: {1}</span>
              </div>
              <div className="slide-item">
                <span>
                  Resolution:{' '}
                  {`${record.expandedData.slideDetails['height']} x ${record.expandedData.slideDetails['width']}`}
                </span>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      title: 'RUN BY',
      dataIndex: 'runBy',
      key: 'runBy',
      width: '10%',
      render: (text, record) => (
        <div
          className="collaborators-list"
          style={{ width: '35px', marginTop: '11px', height: '35px' }}
        >
          <img src={record.userImageSrc || defaultAvatar} alt="" />
        </div>
      ),
    },
    {
      title: 'ACTION',
      dataIndex: '',
      key: 'x',
      width: '60px',
      render: (record, text) => (
        <a onClick={() => showPopup(record)}>
          <img src={PreviousDeleteIcon} alt="" />
        </a>
      ),
    },
  ];

  const loadInViewer = ({ keyFolder, thumbDataToken }) => {
    history.push({
      pathname: `/viewer`,
      search: `?image=${keyFolder}&thumb=${thumbDataToken}&isAnalysis=true`,
    });
  };

  const deleteRow = (row) => {
    deletePreviousRunsRow(
      { rowId: row.id },
      {
        onSuccess: afterRowDeleteSuccess,
        onError: afterRowDeleteFail,
      }
    );
    setIsDeleteRowPopUp(false)
  };

  const afterRowDeleteSuccess = () => {
    getPreviousRuns();
    Notify({
      value: 'Deleted successfully',
    });
  };

  const afterRowDeleteFail = () => {
    Notify({
      value: 'Deletion failed',
    });
  };

  return (
    <div className="previous-runs-main-container">
      <div className="header-section">
        <span className="previous-run-heading">Previous Runs</span>
        <div className="right-section">
          <Link className="continue_button run-btn" to={'/analysis'}>
            Run New Analysis
          </Link>
        </div>
      </div>
      <div className="table-section">
        <Table
          columns={columns}
          dataSource={previousRunsData}
          pagination={false}
          rowClassName="table-data"
          className="main-table"
          expandRowByClick={false}
          loading={{
            spinning: showLoader,
            indicator: (
              <div>
                <Spin />
              </div>
            ),
          }}
        />
      </div>

      {
        isDeleteRowPopUp &&
        <Prompt
          title="Delete Analysis"
          content="This analysis will be deleted. There's no undo so you won't be able to find it."
          actionText="Delete"
          action={() => deleteRow(deletedRow)}
          onClose={() => setIsDeleteRowPopUp(false)}
        />
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  previousRunsData: state.AIAnalytics.previousRunsData,
  showLoader: state.AIAnalytics.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getPreviousRuns: () => dispatch(getPreviousRunsData()),
  deletePreviousRunsRow: (row, callbackFn) =>
    dispatch(deleteRowAction(row, callbackFn)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalysisDashboard)
);
