import React, { useState } from "react";
import { Collapse } from "antd";
import "antd/dist/antd.css";
import clsx from "clsx";
import { PrimaryButton, SecondaryButton } from "../../../../components";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { updatePermissionsAction } from "../../actions/adminDashboard-action";
import { connect } from "react-redux";
import Loader from "../../../slidebox/components/SlideTable/Loader";
import { getUserDetailsAction } from "../../../../actions/global-action";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    opacity: 0.54,
    backgroundColor: "#4A4A4A",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },

    "input:disabled ~ &": {
      boxShadow: "none",
      backgroundColor: "#4B4B4B",
    },
  },
  checkedIcon: {
    backgroundColor: "#0F0DA1",
    opacity: 1,
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#0F0DA1",
    },
  },
});

function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
}

const PermissionsTable = ({
  allPermissionsData,
  updatePermissions,
  showLoader,
  getPermissions,
  collapseSidebar,
}) => {
  const roleNames = Object.keys(allPermissionsData);

  const featureNames = Object.keys(Object.values(allPermissionsData)[0]);
  const taskListData = Object.values(Object.values(allPermissionsData)[0]);

  const [checked, setChecked] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [showButtons, setShowButtons] = useState(false);

  const handleSelect = (event, index, role, subFeature, access) => {
    setChecked(!checked);
    setSelectedPermissions([
      ...selectedPermissions,
      { [role]: { [subFeature]: event.target.checked } },
    ]);
    setShowButtons(true);
  };

  const handleSubmitPermissions = () => {
    updatePermissions({ permissionsData: selectedPermissions });
    setShowButtons(false);
  };

  const handleCancel = () => {
    setSelectedPermissions([]);
    getPermissions();
    setShowButtons(false);
  };

  return showLoader ? (
    <Loader />
  ) : (
    <div className="permissions-main-container">
      <div className="buttons-container">
        {showButtons && (
          <div className="prompt-bottom">
            <SecondaryButton
              label="Cancel"
              onClick={handleCancel}
              extraClass="cancel-button"
            />
            <PrimaryButton
              label="Save"
              onClick={handleSubmitPermissions}
              extraClass="save-button"
            />
          </div>
        )}
      </div>
      <div
        className="permission-table-container"
        style={{
          width: collapseSidebar
            ? "calc(100vw - 10%)"
            : "calc(100vw - 10% - 80px)",
        }}
      >
        <table className="table">
          <thead>
            <tr className="fixed">
              <td className="features-heading"></td>
              {roleNames.map((role, index) => (
                <th id={index} className="table-header">
                  {role}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {featureNames.map((feature, index) => {
              const subFeatures = Object.keys(taskListData[index]);

              return (
                <React.Fragment>
                  <tr>
                    <th className="span" colSpan="20" scope="colgroup">
                      {feature}
                    </th>
                  </tr>
                  <tr>
                    <td style={{ textTransform: "capitalize" }}>
                      {subFeatures.map((tasks, t) => {
                        return (
                          <ul key={t}>
                            <li key={t}>{tasks}</li>
                          </ul>
                        );
                      })}
                    </td>
                    {roleNames.map((role, roleIndex) => {
                      const value = Object.values(allPermissionsData[role]);
                      const subTasks = Object.keys(value[index]);
                      const access = Object.values(value[index]);
                      const accessArr = Array.from(access);
                      return (
                        <td style={{ paddingLeft: "2%" }}>
                          <ul key={roleIndex} className="access-checkbox">
                            {accessArr.map((access, index) => {
                              return access === "edit" ? (
                                <li
                                  key={index}
                                  style={{ paddingBottom: "5px" }}
                                >
                                  <StyledCheckbox
                                    key={index}
                                    disabled
                                    defaultChecked={
                                      subTasks[index] === "account-details" &&
                                      role !== "System Administrator"
                                        ? false
                                        : true
                                    }
                                  />
                                </li>
                              ) : access === "view" ? (
                                <li
                                  key={index}
                                  style={{ paddingBottom: "5px" }}
                                >
                                  <StyledCheckbox
                                    key={index}
                                    defaultChecked
                                    onChange={(event) =>
                                      handleSelect(
                                        event,
                                        index,
                                        role,
                                        subFeatures[index],
                                        access
                                      )
                                    }
                                  />
                                </li>
                              ) : (
                                <li
                                  key={index}
                                  style={{ paddingBottom: "5px" }}
                                >
                                  <StyledCheckbox
                                    checked={checked[roleIndex]}
                                    key={index}
                                    onChange={(event) =>
                                      handleSelect(
                                        event,
                                        index,
                                        role,
                                        subFeatures[index],
                                        access
                                      )
                                    }
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
  showLoader: state.Global.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  updatePermissions: (payload) => dispatch(updatePermissionsAction(payload)),
  getPermissions: () => dispatch(getUserDetailsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsTable);
