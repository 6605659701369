import React from "react";
import UserSearch from "./components/DashboardItems/UserSearch";
import AddUser from "./components/DashboardItems/AddUser";
import AddPhysician from "./components/DashboardItems/AddPhysician";
import AddProcedureModal from "./components/DashboardItems/AddProcedureModal";
import "./AdminDashboard.scss";
import DashboardTable from "./components/DashboardTable/DashboardTable";
import ActivateOrDeactivateUser from "./components/DashboardItems/ActivateOrDeactivateUser";
import { bytesToSize } from "../../utils/utils";
import { connect } from "react-redux";
import {
  getUserListDataAction,
  addNewUserAction,
  searchDashboardUserListAction,
  changeDxUserRoleAction,
  activateOrDeactivateUserAction,
  resetMessageAction,
  resendInvitationAction,
  revokeInvitationAction,
  getSlideboxSlideDataAction,
  getAllRolesAction,
  getAllWorkgroupAction,
  addPhysicianAction,
  getAllPhysiciansAction,
  editPhysicianAction,
  addProcedureAction,
  getAllProceduresAction,
  editProcedureAction,
} from "./actions/adminDashboard-action";
import ChangeRoleType from "./components/DashboardItems/ChangeRoleType";
import { Notify } from "../../components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import {
  getAccountDetailsAction,
  getUserDetailsAction,
} from "../../actions/global-action";
import DeleteUserInvitationConfirm from "./components/DashboardItems/DeleteUserInvitationConfirm";
import Can from "./../../hoc/FullControllHoc/can";
import AnalyticsDashboard from "./components/DashboardAnalytics/AnalyticsDashboard";
import { FeatureGate } from "react-feature-gate";
import RolesTable from "./components/DashboardTable/RolesTable";
import PermissionsTable from "./components/DashboardTable/PermissionsTable";
import WorkgroupTable from "./components/Workgroups/WorkgroupTable";
import AccountSettings from "./components/DashboardItems/AccountSettings";
import OrderingPhysicians from "./components/DashboardItems/OrderingPhysicians";
import ProcedureMaster from "./components/DashboardItems/ProcedureMaster";

const AdminDashboard = ({
  showLoader,
  userList,
  getUserListFn,
  addNewUserFn,
  addPhysicianAction,
  getAllPhysicians,
  editPhysicianAction,
  addProcedureAction,
  getAllProcedures,
  editProcedureAction,
  activateOrDeactivateUserFn,
  loggedUserDetails,
  searchDashboardUserListFn,
  changeDxUserRoleFn,
  message,
  showMessage,
  resetMessageActionFn,
  getAccountDetails,
  getLoggedUserDetails,
  dxAccountDetails,
  resendInvitation,
  deleteInvitationFn,
  getSlideData,
  allPermissionsData,
  getRolesData,
  rolesData,
  getWorkgroupData,
  collapseSidebar,
  orderingPhysicians,
  proceduresList,
}) => {
  const [addNewUser, setAddNewUser] = React.useState(false);
  const [addNewPhysician, setAddNewPhysician] = React.useState(false);
  const [addNewProcedure, setAddNewProcedure] = React.useState(false);
  const [searchPhysician, setSearchPhysician] = React.useState('');
  const [searchProcedure, setSearchProcedure] = React.useState('');
  const [filteredPhysicians, setFilteredPhysicians] = React.useState([]);
  const [filteredProcedures, setFilteredProcedures] = React.useState([]);
  const [isPhysicianEdit, setIsPhysicianEdit] = React.useState(false);
  const [isProcedureEdit, setIsProcedureEdit] = React.useState(false);
  const [
    activateOrDeactivateUser,
    setActivateOrDeactivateUser,
  ] = React.useState(false);
  const [deleteUserInvitation, setDeleteUserInvitation] = React.useState(false);
  const [
    activateOrDeactivateUserData,
    setActivateOrDeactivateUserData,
  ] = React.useState(false);
  const [changeRoleType, setChangeRoleType] = React.useState(false);
  const [userRoleInfo, setUserRole] = React.useState({});

  const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);
  const [filterData, setFilterData] = React.useState(userList);
  const [blink, setBlink] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const membersTableRef = React.useRef(null);

  const displayTab =
    loggedUserDetails.roleName === "System Administrator" ? "inline" : "none";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    async function fetchPhysicians() {
      await getAllPhysicians();
      setFilteredPhysicians(orderingPhysicians);
    }
    fetchPhysicians();
  }, []);

  React.useEffect(() => {
    async function fetchProcedures() {
      await getAllProcedures();
      setFilteredProcedures(proceduresList);
    }
    fetchProcedures();
  }, []);

  React.useEffect(() => {
    getRolesData();
  }, []);

  React.useEffect(() => {
    getWorkgroupData();
  }, []);

  React.useEffect(() => {
    if (value === 2) {
      getAccountDetails();
    }
  }, [value]);

  React.useEffect(() => {
    getUserListFn();
    getLoggedUserDetails();
    getAccountDetails();
  }, []);

  React.useEffect(() => {
    if (changeRoleType || activateOrDeactivateUser) {
      return setIsPopUpOpen(true);
    }
    return setIsPopUpOpen(false);
  }, [changeRoleType, activateOrDeactivateUser]);

  React.useEffect(() => {
    setFilterData(userList);
  }, [userList]);

  React.useEffect(() => {
    if(membersTableRef.current) {
      membersTableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [userList]);
  
  React.useEffect(() => {
    showMessage &&
      Notify({
        value: message,
        done: () => resetMessageActionFn(),
      });
  }, [showMessage]);

  const displaySlidesUploaded = (type) => {
    getSlideData(type);
  };

  return (
    <div className="admin-dashboard-container">
      <div className="slide-user-list-wrapper">
        <div className="dashboard-tabs-and-storage-container">
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={handleChange}
            aria-label="details-drawer"
            className="details-drawer-tab-container"
          >
            <Tab
              onClick={(e) => setValue(0)}
              label="Roles"
              className="admin-bord-drawer-tab"
            />
            <Tab
              onClick={(e) => setValue(1)}
              label="Workgroups"
              className="admin-bord-drawer-tab"
              style={{ display: displayTab }}
            />
            <Tab
              onClick={(e) => setValue(2)}
              label="Permissions"
              className="admin-bord-drawer-tab"
              style={{ display: displayTab }}
            />
            <Can
              task={"manage-members"}
              taskContainer="dashboard"
              edit={() => (
                <Tab
                  onClick={(e) => setValue(3)}
                  label="Manage members"
                  className="admin-bord-drawer-tab"
                />
              )}
              noAccess={() => (
                <Tab
                  onClick={(e) => setValue(3)}
                  label="Member list"
                  className="admin-bord-drawer-tab"
                />
              )}
            />
            {loggedUserDetails.roleName === "System Administrator" &&
              <Tab
                onClick={(e) => setValue(4)}
                label="Procedure Master"
                className="admin-bord-drawer-tab"
              />
            }
            {loggedUserDetails.roleName === "System Administrator" &&
              <Tab
                onClick={(e) => setValue(5)}
                label="Ordering Physicians"
                className="admin-bord-drawer-tab"
              />
            }
            <div>
              <FeatureGate feature="analytics">
                <Can
                  task={"user-analytics"}
                  taskContainer="dashboard"
                  edit={() => (
                    <Tab
                      onClick={(e) => setValue(6)}
                      label="Analytics"
                      className="admin-bord-drawer-tab"
                    />
                  )}
                />
              </FeatureGate>
            </div>
            {
              <Can
                task={"account-details"}
                taskContainer="dashboard"
                edit={() => (
                  <Tab
                    onClick={(e) => setValue(7)}
                    label="Account Details"
                    className="admin-bord-drawer-tab"
                  />
                )}
              />
            }
          </Tabs>
        </div>

        {value === 0 && (
          <RolesTable
            {...{
              showLoader,
              userList,
              isPopUpOpen,
              blink,
            }}
          />
        )}

        {value === 1 && <WorkgroupTable userList={userList} />}

        {value === 2 && (
          <PermissionsTable
            allPermissionsData={allPermissionsData}
            collapseSidebar={collapseSidebar}
          />
        )}

        {value === 3 && (
          <React.Fragment>
            <UserSearch
              {...{
                setAddNewUser,
                userList,
                filterData,
                setBlink,
                getUserListFn,
                setFilterData,
                searchDashboardUserListFn,
                rolesData,
              }}
            />
            <DashboardTable
              {...{
                showLoader,
                activateOrDeactivateUser,
                setActivateOrDeactivateUser,
                filterData,
                setActivateOrDeactivateUserData,
                setChangeRoleType,
                isPopUpOpen,
                setUserRole,
                blink,
                setBlink,
                resendInvitation,
                setDeleteUserInvitation,
                rolesData,
                dxAccountDetails,
                loggedUserDetails,
                membersTableRef,
              }}
            />
          </React.Fragment>
        )}

        {value === 4 && (
          <ProcedureMaster
            {...{
              showLoader,
              blink,
              setSearchPhysician,
              setAddNewProcedure,
              filteredProcedures,
              setFilteredProcedures,
              proceduresList,
              setIsProcedureEdit,
              searchProcedure,
              setSearchProcedure,
            }}
          />
        )}
        {value === 5 && (
          <OrderingPhysicians
            {...{
              showLoader,
              blink,
              setAddNewPhysician,
              filteredPhysicians,
              setFilteredPhysicians,
              orderingPhysicians,
              setIsPhysicianEdit,
              searchPhysician,
              setSearchPhysician,
            }}
          />
        )}
        {value === 6 && (
          <AnalyticsDashboard displaySlidesUploaded={displaySlidesUploaded} />
        )}

        {value === 7 && (
          <AccountSettings
            dxAccountDetails={dxAccountDetails}
            bytesToSize={bytesToSize}
            loggedUserDetails={loggedUserDetails}
            getAccountDetails={getAccountDetails}
            getLoggedUserDetails={getLoggedUserDetails}
          />
        )}
      </div>

      {addNewUser && (
        <AddUser
          {...{
            loggedUserDetails,
            addNewUserFn,
            showMessage,
            onClose: () => setAddNewUser(!addNewUser),
          }}
        />
      )}

      {addNewPhysician && (
        <AddPhysician
          {...{
            onClose: () => setAddNewPhysician(!addNewPhysician),
            addPhysicianAction,
            getAllPhysicians,
            editPhysicianAction,
            isPhysicianEdit,
            setIsPhysicianEdit,
          }}
        />
      )}
      {addNewProcedure && (
        <AddProcedureModal
          {...{
            onClose: () => setAddNewProcedure(!addNewProcedure),
            addProcedureAction,
            getAllProcedures,
            editProcedureAction,
            isProcedureEdit,
            setIsProcedureEdit,
          }}
        />
      )}
      {activateOrDeactivateUser && (
        <ActivateOrDeactivateUser
          {...{
            activateOrDeactivateUserData,
            activateOrDeactivateUserFn,
            onClose: () =>
              setActivateOrDeactivateUser(!activateOrDeactivateUser),
          }}
        />
      )}

      {deleteUserInvitation && (
        <DeleteUserInvitationConfirm
          {...{
            deleteUserInvitation,
            deleteInvitationFn,
            onClose: () => setDeleteUserInvitation(!deleteUserInvitation),
          }}
        />
      )}

      {changeRoleType && (
        <ChangeRoleType
          {...{
            userRoleInfo,
            changeDxUserRoleFn,
            rolesData,
            onClose: () => setChangeRoleType(!changeRoleType),
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
  loggedUserStorageUsed: state.Global.loggedUserDetails.storageUsed,
  loggedUserStorageLimit: state.Global.loggedUserDetails.storageLimit,
  loggedUserDetails: state.Global.loggedUserDetails,
  dxAccountDetails: state.Global.dxAccountDetails,
  licenseTypeFeatures: state.Global.loggedUserDetails.licenseTypeAccessData,
  allPermissionsData: state.Global.allRolesPermissions,
  rolesData: state.Dashboard.rolesData,
  orderingPhysicians: state.Dashboard.orderingPhysicians,
  proceduresList: state.Dashboard.proceduresList,
});

const mapDispatchToProps = (dispatch) => ({
  getUserListFn: () => dispatch(getUserListDataAction()),
  addNewUserFn: (payload) => dispatch(addNewUserAction(payload)),
  addPhysicianAction: (payload) => dispatch(addPhysicianAction(payload)),
  addProcedureAction: (payload) => dispatch(addProcedureAction(payload)),
  activateOrDeactivateUserFn: (payload) =>
    dispatch(activateOrDeactivateUserAction(payload)),
  resendInvitation: (payload) => dispatch(resendInvitationAction(payload)),
  deleteInvitationFn: (payload) => dispatch(revokeInvitationAction(payload)),
  searchDashboardUserListFn: (payload) =>
    dispatch(searchDashboardUserListAction(payload)),
  changeDxUserRoleFn: (payload) => dispatch(changeDxUserRoleAction(payload)),
  getAccountDetails: () => dispatch(getAccountDetailsAction()),
  getLoggedUserDetails: () => dispatch(getUserDetailsAction()),
  resetMessageActionFn: () => dispatch(resetMessageAction()),
  getSlideData: (payload) => dispatch(getSlideboxSlideDataAction(payload)),
  getRolesData: () => dispatch(getAllRolesAction()),
  getWorkgroupData: () => dispatch(getAllWorkgroupAction()),
  getAllPhysicians: () => dispatch(getAllPhysiciansAction()),
  getAllProcedures: () => dispatch(getAllProceduresAction()),
  editPhysicianAction: (payload) => dispatch(editPhysicianAction(payload)),
  editProcedureAction: (payload) => dispatch(editProcedureAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
