import React, { useState } from "react";
import { FadeIn } from "animate-components";
import {
  Overlay,
  ModalHeader,
  SingleSelect,
  SecondaryButton,
  PrimaryButton,
  UserList,
  MemberInvitation,
} from "../src/components";
import { Notify } from "../src/components";
import { connect } from "react-redux";
import {
  setNewDirCollaboratorsAction,
  removeDirCollaboratorsAction,
} from "../src/pages/slidebox/actions/slidebox-action";
import DefaultAvatar from "../src/assets/icons/default-avatar.svg";
import CollabIcon from "../src/assets/icons/add-new-user.svg";

const SharedPopup = ({
  onClose,
  selectedListType,
  selectedList,
  selectedDirCollaborators,
  setNewDirCollaborators,
  removeDirCollaborators,
}) => {
  const [invitedCollaborators, setInvitedCollaborators] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSaveClick = () => {
    const data = {
      dirId: selectedList,
      dirType: selectedListType,
      collaborateUsers: invitedCollaborators,
    };
    setButtonDisabled(true);
    setNewDirCollaborators(data, {
      onSuccess: afterDirCollaborateSuccess,
      onError: afterDirCollaborateFail,
    });
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const afterDirCollaborateSuccess = () => {
    Notify({
      value: `${capitalizeFirstLetter(selectedListType)} shared successfully`,
    });
    onClose(false);
  };

  const afterDirCollaborateFail = () => {
    Notify({ value: `${selectedListType} failed to share` });
  };

  const addMember = (members) => {
    setInvitedCollaborators([...invitedCollaborators, members]);
  };

  const removeSlideboxCollaborators = (userId) => {
    const data = {
      dirId: selectedList,
      dirType: selectedListType,
      collaboratorId: userId,
    };
    removeDirCollaborators(data, {
      onSuccess: afterRemoveCollaborateSuccess,
      onError: afterRemoveCollaborateFail,
    });
  };

  const afterRemoveCollaborateSuccess = () => {
    Notify({ value: `Collaborator removed successfully` });
    onClose(false);
  };

  const afterRemoveCollaborateFail = () => {
    Notify({ value: `Failed to remove collaborator` });
  };

  const removeSelectedUsers = (userId) => {
    const updatedInvities = invitedCollaborators.filter(
      (data) => data.userID !== userId
    );
    setInvitedCollaborators(updatedInvities);
  };

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const Collaborator = ({
    name,
    img,
    role,
    onRemove,
    enableRemove,
    email,
    firstName,
    lastName,
  }) => {
    return (
      <div className="collaborator">
        <UserList
          img={img}
          fullName={name}
          role={role}
          userEmail={email}
          firstName={firstName}
          lastName={lastName}
        />
        <button
          type="button"
          onClick={enableRemove ? onRemove : null}
          disabled={enableRemove ? "" : "disabled"}
          className={
            "btn remove-collaborator " + (enableRemove ? "enabled" : "disabled")
          }
        >
          Remove
        </button>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            img={CollabIcon}
            alt={"collab"}
            title={`Collaborate ${selectedListType}`}
            onClick={() => onClose(false)}
          />
          <div className="modal_middle shared_popup">
            <React.Fragment>
              <div className="collaborator-list">
                {selectedDirCollaborators &&
                  selectedDirCollaborators.length > 0 &&
                  selectedDirCollaborators.map((c, i) => (
                    <Collaborator
                      key={i}
                      img={c.userImageSrc ? c.userImageSrc : DefaultAvatar}
                      name={`${c.firstName}${" "}${c.lastName}`}
                      role={c.roleName}
                      enableRemove={c.roleId === 3 ? true : false}
                      email={c.userEmail}
                      onRemove={() => removeSlideboxCollaborators(c.userID)}
                      firstName={c.firstName}
                      lastName={c.lastName}
                    />
                  ))}
              </div>
              <React.Fragment>
                <MemberInvitation
                  onMemberSelect={addMember}
                  isCollaborator={true}
                  filePath={selectedList}
                  alreadyInUsers={selectedDirCollaborators}
                />
                <div className="selected-collabortor-list">
                  {invitedCollaborators.map((member, i) => (
                    <div className="collaborator" key={i}>
                      <UserList
                        img={
                          member.userImageSrc
                            ? member.userImageSrc
                            : DefaultAvatar
                        }
                        fullName={`${member.firstName} ${member.lastName}`}
                      />
                      <button
                        type="button"
                        onClick={() => removeSelectedUsers(member.userID)}
                        className="btn removed remove-collaborator"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            </React.Fragment>
            <div className="prompt-bottom collaborate-popup">
              {invitedCollaborators.length > 0 && (
                <PrimaryButton
                  label={"Share"}
                  onClick={() => handleSaveClick()}
                  extraClass="prompt-done"
                  disabled={buttonDisabled}
                />
              )}
            </div>
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  selectedDirCollaborators: state.SlideBox.selectedDirCollaborators,
});

const mapDispatchToProps = (dispatch) => ({
  setNewDirCollaborators: (payload, action) =>
    dispatch(setNewDirCollaboratorsAction(payload, action)),
  removeDirCollaborators: (payload, action) =>
    dispatch(removeDirCollaboratorsAction(payload, action)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SharedPopup);
