import {
  dxAccountListApi,
  createDxAccountApi,
  adminUserLogoutApi,
  resendUserInvitationApi,
} from "../services/site-admin-dashboard-services";

const showLoader = (show) => {
  return {
    type: "SHOW_LOADER",
    showLoader: show,
  };
};

const setDxAccountListData = (data) => {
  return {
    type: "SET_DXACCOUNT_DETAILS",
    payload: data,
  };
};

const showMessage = (message) => {
  return {
    type: "SHOW_MESSAGE",
    message,
  };
};

const addNewDxAccount = (data, msg) => {
  return {
    type: "ADD_NEW_DX_ACCOUNT",
    payload: data,
    message: msg,
  };
};

const errorMessageAction = (message) => {
  return {
    type: "ERROR_MESSAGE",
    message,
  };
};

export const resetMessageAction = () => {
  return {
    type: "RESET_MESSAGE",
  };
};

export const getDxAccountListAction = () => {
  return async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const response = await dxAccountListApi();

      if (response.status === 200) {
        dispatch(setDxAccountListData(response.data));
      }
      dispatch(showLoader(false));
    } catch (error) {
      dispatch(showLoader(false));
      dispatch(setDxAccountListData([]));
    }
  };
};

export const createDxAccountAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await createDxAccountApi(data);
      if (response.data.success) {
        dispatch(addNewDxAccount(response.data.data, "Created successfully"));
        dispatch(getDxAccountListAction());
      } else {
        dispatch(
          errorMessageAction(
            response.data.message
              ? response.data.message
              : "Error while creating new dxAccount"
          )
        );
      }
    } catch (error) {
      dispatch(errorMessageAction("Error while creating new dxAccount"));
    }
  };
};

export const adminUserLogoutAction = () => {
  return async (dispatch) => {
    try {
      const response = await adminUserLogoutApi();
      if (response.status === 200) {
        window.location.href = "/admin-login";
        setTimeOut(() => {
          sessionStorage.clear();
        }, 100);
        dispatch({ type: "ADMIN_USER_LOGOUT" });
      }
    } catch (error) {}
  };
};

export const resendInvitationAction = ({ userId }) => async (dispatch) => {
  const successMessage = "Invitation email sent";
  const errorMessage = "Invitation email sent failed";
  try {
    const response = await resendUserInvitationApi({ userId: userId });
    if (response.data.success) {
      dispatch({
        type: "SHOW_MESSAGE",
        message: successMessage,
        data,
      });
      dispatch(getDxAccountListAction());
    } else {
      dispatch(errorMessageAction(`Error while ${errorMessage}`));
    }
  } catch (error) {
    dispatch(errorMessageAction(`Error while ${errorMessage}`));
  }
};
