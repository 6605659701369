import React, { useState } from "react";
import { connect } from "react-redux";
import ToolTip from "react-tooltip";
import InviteIcon from "../../../assets/icons/add-new-user.svg";
import ClosePopupIcon from "../../../assets/icons/close-new.svg";
import DefaultAvatar from "../../../assets/icons/default-avatar.svg";
import ThreeDotsIcon from "../../../assets/icons/more.svg";
import altIconGroup from "../../../assets/icons/tumor-board-default.jpg";
import { useNameFormat } from '../../../pages/adminDashboard/components/DashboardItems/useFormat';
import MemberInvitation from "../../../pages/groups/components/member-invitation";
import User from "../../../pages/groups/components/user";
import axios from "../../../utils/axiosInstance";
import Fade from "../../Animations/Fade";
import { Notify } from "../../index";
import AddUser from "../icons/add-user.svg";
import WindowAdd from '../icons/window-add.svg';
import Can from "./../../../hoc/FullControllHoc/can";

const ChatHeader = ({
  group,
  groupMembers,
  onChangeActiveTab,
  loggedUser,
  onOpenSlidebox,
}) => {
  const [openInvitation, setOpenInvitation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [members, setMembers] = useState([]);
  const addDefaultSrc = (ev) => {
    ev.target.src = altIconGroup;
  };
  const checkUser = (toCheck) => {
    if (toCheck.id === loggedUser) return false;
    const checked = members.filter((user) => {
      if (user.id === toCheck.id) return user.id;
    });
    return checked.length === 0;
  };
  const addMember = (user) => {
    if (checkUser(user)) {
      setErrorMessage(false)
      setMembers([user, ...members]);
    }
  };
  const closePopup = () => {
    setMembers([]);
    setOpenInvitation(false);
  };

  const inviteMembers = async () => {
    const membersIds = members.map((m) => m.id);
    if (membersIds.length === 0) {
      setErrorMessage(true);
      return;
    }
    let res;
    try {
      res = await axios.post(
        "/api/groups/addMembers",
        { tumorBoardId: group.id, members: membersIds },
        { withCredentials: true }
      );
    } catch (err) {
      Notify({ value: "Invite failed!" });
    }
    if (res.status === 201) Notify({ value: "Invitations sent." });
    closePopup();
  };

  const isCreator = group.creator.id === loggedUser;
  const isAdmin = group.admin;

  return (
    <div className="chat-header">
      <div className="group-img">
        <img
          className="chat-img"
          src={group.imgSrc ? group.imgSrc : altIconGroup}
          alt="group icon"
          onError={addDefaultSrc}
        />
      </div>
      <div className="chat-content">
        <div className="chat-info">
          <h3>
            {
              group.name
            }
          </h3>
          {groupMembers && (
            <div className="chat-number-info">
              <div className="member-count">
                {groupMembers.length === 1
                  ? `${groupMembers.length} member`
                  : `${groupMembers.length} members`}
              </div>
              <div className="online-count">
                {groupMembers.filter((m) => m.connected).length} online
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="chat-buttons-wrapper">
        {isAdmin || isCreator ? (
          <Can
            task={"add-slide"}
            taskContainer="groups"
            edit={() => (
              <img
                data-for="quorum-header-tooltip"
                data-tip="Add slide"
                src={WindowAdd}
                alt="add"
                className="add-img-btn"
                onClick={onOpenSlidebox}
              />
            )}
          />
        ) : null}
        {isAdmin || isCreator ? (
          <Can
            task={"manage-groups"}
            taskContainer="groups"
            edit={() => (
              <img
                data-for="quorum-header-tooltip"
                data-tip="Invite"
                className="add-user-img"
                src={AddUser}
                onClick={() => setOpenInvitation(!openInvitation)}
              />
            )}
          />
        ) : null}
        <Can
          task={"manage-groups"}
          taskContainer="groups"
          edit={() => (
            <img
              className="three-dots-img"
              onClick={onChangeActiveTab}
              src={ThreeDotsIcon}
            />
          )}
        />
      </div>
      {openInvitation && (
        <Fade show={openInvitation} duration="300ms">
          <div id="create-board-overlay">
            <div id="leave-board" className="invite-group-members">
              <div id="leave-header">
                <div className="inviteIcon">
                  <img src={InviteIcon} alt="invite" />
                  <p className="invite-members-header-text">Invite Members</p>
                </div>
                <img
                  src={ClosePopupIcon}
                  className="close-icon"
                  onClick={closePopup}
                />
              </div>

              <div id="leave-content" style={{ minHeight: 0 }}>
                <MemberInvitation onMemberSelect={addMember} />
                <div className="users">
                  {members.map((member, i) => (
                    <User
                      key={i}
                      // TODO: remove this absolute path
                      img={
                        member.user_image_src
                          ? member.user_image_src
                          : DefaultAvatar
                      }
                      fullName={useNameFormat(member.firstname, member.surname)}
                    />
                  ))}
                  {
                    errorMessage && <span style={{ color: 'red', fontSize: '12px' }}>Please add someone to invite</span>
                  }
                </div>
              </div>
              <div id="leave-footer">
                <button id="cancel-btn" onClick={closePopup}>
                  Cancel
                </button>
                <button id="leave-btn" onClick={inviteMembers}>
                  Invite
                </button>
              </div>
            </div>
          </div>
        </Fade>
      )}
      <ToolTip
        id="quorum-header-tooltip"
        place="bottom"
        type="dark"
        effect="solid"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loggedUserDetails } = state.Global;
  const { selectedBoard: group } = state.Groups;
  return {
    loggedUser: loggedUserDetails.userID,
    group,
  };
};
export default connect(mapStateToProps)(ChatHeader);
