import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DatePicker } from "antd";
import { addDays, subDays } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FeatureGate } from "react-feature-gate";
import { connect } from "react-redux";
import { procedureNames } from "../../../../config/constants";
import DropdownIcon from "../../../assets/icons/dropdown.svg";
import CloseIcon from "../../../assets/icons/CloseIcon.svg";
import { PrimaryButton } from "../../../components";
import {
  getWorklistSearchParameters,
  setSearchParams,
  updateWorklistSearchInfoAction,
  getWorklistDataAction,
} from "../actions/slidebox-action";
import { getAllPhysiciansAction, getAllProceduresAction } from "../../adminDashboard/actions/adminDashboard-action";

const { RangePicker } = DatePicker;

const dateDurationValues = [
  { id: 1, value: "-", label: "None" },
  { id: 1, value: "today", label: "Today" },
  { id: 2, value: "yesterday", label: "Yesterday" },
  { id: 3, value: "lastThreeDays", label: "Last 3 Days" },
  { id: 4, value: "lastWeek", label: "Last Week" },
  { id: 5, value: "tomorrow", label: "Tomorrow" },
  { id: 6, value: "nextThreeDays", label: "Next 3 Days" },
  { id: 7, value: "nextSevenDays", label: "Next 7 Days" },
];

const colorOptions = [
  { id: 1, value: "rgba(247, 65, 45, 0.5)" },
  { id: 2, value: "rgba(255, 58, 41, 0.9)" },
  { id: 3, value: "rgba(157, 27, 178, 0.51)" },
  { id: 4, value: "rgba(68, 190, 141, 0.56)" },
  { id: 5, value: "rgba(62, 78, 184, 0.51)" },
  { id: 6, value: "rgba(17, 148, 246, 0.54)" },
  { id: 7, value: "rgba(86, 87, 89, 0.49)" },
  { id: 8, value: "rgba(77, 115, 168, 0.43)" },
  { id: 9, value: "#009788" },
  { id: 10, value: "#FF8364" },
  { id: 11, value: "#89C540" },
  { id: 12, value: "#ECE7F8" },
];

const caseStatusValues = [
  { id: 1, value: "Ordered", label: "Ordered" },
  { id: 2, value: "Cancelled", label: "Cancelled" },
  { id: 3, value: "Arrived", label: "Arrived" },
  { id: 4, value: "Verified", label: "Verified" },
  { id: 5, value: "Transcribed", label: "Transcribed" },
  { id: 6, value: "Prelim Report", label: "Prelim Report" },
  { id: 7, value: "Reported", label: "Reported" },
];

const casePriorityValues = [
  { value: "standard", label: "Standard" },
  { value: "rush", label: "Rush" },
  { value: "stat", label: "Stat" },
];

export const SlideboxSearchDetails = ({
  memberDetails,
  setSearchParams,
  licenseTypeFeatures,
  setShowAdvancedSearchModal,
  currentWorklistColor,
  currentWorklistName,
  worklistId,
  worklistSearchParams,
  updateWorklist,
  refreshWorklist,
  orderingPhysicians,
  getAllPhysicians,
  getAllProcedures,
  proceduresList,
}) => {
  let mediaQuery;

  const activeMembers = memberDetails.filter((members) => {
    return members.status === "active";
  });

  const filteredAssignToMembers = activeMembers.map((members) => {
    const fullName = members.firstName + " " + members.lastName;
    const displayName =
      fullName.length > 18 ? fullName.substring(0, 18) + "..." : fullName;
    return {
      label: displayName,
      userId: members.userID,
    };
  });

  const [worklistName, setWorklistName] = useState(currentWorklistName);
  const [worklistColor, setWorklistColor] = useState(currentWorklistColor);

  const [colorPickerAnchorElement, setColorPickerAnchorElement] = useState(
    null
  );
  const isColorPickerMenuOpen = Boolean(colorPickerAnchorElement);

  const handleColorPickerClose = () => {
    setColorPickerAnchorElement(null);
  };

  const [width, setWidth] = useState(115);

  const [mrnNumber, setMrnNumber] = useState(
    worklistSearchParams.mrnNumber ? worklistSearchParams.mrnNumber : ""
  );
  const [accessionNumber, setAccessionNumber] = useState(
    worklistSearchParams.accessionNumber
      ? worklistSearchParams.accessionNumber
      : ""
  );
  const [patientName, setPatientName] = useState(
    worklistSearchParams.patientName ? worklistSearchParams.patientName : ""
  );
  const [proceduresOptions, setProceduresOptions] = useState([]);

  const [orderingPhysician, setOrderingPhysician] = useState(
    worklistSearchParams.orderingPhysicianId
      ? worklistSearchParams.orderingPhysicianId
      : ""
  );
  const [procedureName, setProcedureName] = useState(
    worklistSearchParams.procedureName ? worklistSearchParams.procedureName : ""
  );
  const [procedureDate, setProcedureDate] = useState({
    startDate: worklistSearchParams.startDate
      ? moment(new Date(worklistSearchParams.startDate)).format("yyyy-MM-DD")
      : "",
    endDate: worklistSearchParams.endDate
      ? moment(new Date(worklistSearchParams.endDate)).format("yyyy-MM-DD")
      : "",
  });
  const [caseStatus, setCaseStatus] = useState(
    worklistSearchParams.caseStatus ? worklistSearchParams.caseStatus : ""
  );
  const [casePriority, setCasePriority] = useState(
    worklistSearchParams.priority ? worklistSearchParams.priority : ""
  );
  const [assignedTo, setAssignedTo] = useState(
    worklistSearchParams.assignedTo
      ? +filteredAssignToMembers.find(
          (member) => member.userId === +worklistSearchParams.assignedTo
        ).userId
      : ""
  );
  const [referredTo, setReferredTo] = useState(
    worklistSearchParams.referredTo
      ? +filteredAssignToMembers.find(
          (member) => member.userId === +worklistSearchParams.referredTo
        ).userId
      : ""
  );
  const [labels, setLabels] = useState(
    worklistSearchParams.labels ? worklistSearchParams.labels : ""
  );

  const [dateRangeSelect, setDateRangeSelect] = useState("-");

  const handleApply = async () => {
    const searchParams = {
      // search: search,
      mrnNumber: mrnNumber,
      accessionNumber: accessionNumber,
      patientName: patientName,
      orderingPhysicianId: orderingPhysician,
      procedureName: procedureName,
      priority: casePriority,
      caseStatus: caseStatus,
      startDate:
        dateRangeSelect !== "-"
          ? dateRangeSelect
          : procedureDate.startDate !== ""
          ? moment(procedureDate.startDate).format("yyyy-MM-DD")
          : "",
      endDate:
        dateRangeSelect !== "-"
          ? dateRangeSelect
          : procedureDate.endDate !== ""
          ? moment(procedureDate.endDate).format("yyyy-MM-DD")
          : "",
      assignedTo: assignedTo,
      referredTo: referredTo,
      labels: labels,
    };
    const updatingObject = {
      searchResults: searchParams,
      worklistName: worklistName,
      worklistColor: worklistColor,
      worklistId,
    };
    updateWorklist(updatingObject);
    setSearchParams(searchParams);
    setShowAdvancedSearchModal(false);
    refreshWorklist({ worklistId });
  };

  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `All`;
    } else if (value && value.some((o) => o.value !== "*")) {
      return `${value ? value.map((item) => item.label) : ""}`;
    } else {
      return `${placeholderButtonLabel}`;
    }
  };

  function handleChangeValues(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }

  function handleChangeOption(value, event) {
    this.setState(value);
  }

  const handleSelectOption = (e, setState) => {
    setState(e.target.value);
  };

  const handleChangeProcedureName = (e) => {
    setProcedureName(e.target.value);
  };

  const handleDateChange = (e) => {
    setProcedureDate({
      ...procedureDate,
      [e.target.name]: moment(new Date(e.target.value)).format("yyyy-MM-DD"),
    });
  };

  useEffect(() => {
    async function fetchProcedures() {
      await getAllProcedures();
      setProceduresOptions(
        proceduresList &&
          proceduresList
            .filter((procedure) => procedure.status === "active")
            .map((procedure) => {
              console.log(procedure.procedureName);
              return {
                label: procedure.procedureName,
                value: procedure.procedureId,
              };
            })
      );
    }
    fetchProcedures();
  }, []);

  const customStyles = {
    menu: (provided, state) => ({
      width: 200,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      fontSize: 14,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    }),

    optionContainer: (provided, state) => ({
      marginLeft: 10,
      marginTop: 15,
      width: "100%",
    }),
    option: (provided, state) => ({
      width: "100%",
      padding: 5,
      paddingLeft: 10,
    }),
  };

  useEffect(() => {
    getAllPhysicians();
  }, []);

  useEffect(() => {
    mediaQuery = window.matchMedia("(min-width: 1610px)");
    if (mediaQuery.matches) {
      setWidth(190);
    } else {
      setWidth(115);
    }
  }, [mediaQuery, width]);

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="overlay"></div>
        <div
          className="advanced-search-form-container edit-worklist-container"
          data-test="edit-worklist-modal"
        >
          <div className="advanced-search-title">
            <p>Edit Worklist</p>
            <img
              className="close-icon"
              src={CloseIcon}
              alt="close"
              onClick={() => setShowAdvancedSearchModal(false)}
            />
          </div>
          <div className="advanced-search-form">
            <FeatureGate feature="patient_level_information">
              <div
                style={{
                  display: "flex",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 700,
                    color: "#201C37",
                    marginBottom: 12,
                    fontSize: 14,
                  }}
                >
                  Worklist Name
                </Typography>
                <div
                  style={{
                    width: "18px",
                    height: "18px",
                    borderRadius: "100%",
                    cursor: "pointer",
                    backgroundColor: worklistColor,
                    marginLeft: 5,
                  }}
                  onClick={(e) => setColorPickerAnchorElement(e.currentTarget)}
                />
              </div>
              <input
                type="text"
                id="worklist-name"
                name="worklist-name"
                className="form-item"
                value={worklistName}
                onChange={(e) => setWorklistName(e.target.value)}
              />
            </FeatureGate>
            <Typography
              style={{
                fontWeight: 700,
                color: "#201C37",
                marginBottom: 12,
                fontSize: 14,
              }}
            >
              Worklist Criteria
            </Typography>
            <div className="worklist-criteria">
              <div>
                <FeatureGate feature="patient_level_information">
                  <label htmlFor="mrn-number">MRN</label>
                  <input
                    type="text"
                    className="form-item"
                    value={mrnNumber}
                    name="mrn-number"
                    onChange={(e) => {
                      setMrnNumber(e.target.value);
                    }}
                  />
                </FeatureGate>
              </div>
              <div>
                <label htmlFor="accession-number">Accession Number</label>
                <input
                  type="text"
                  className="form-item"
                  name="accession-number"
                  value={accessionNumber}
                  onChange={(e) => {
                    setAccessionNumber(e.target.value);
                  }}
                />
              </div>
              <div className="patient-name-container">
                <FeatureGate feature="patient_level_information">
                  <label htmlFor="patient-name">Patient Name</label>
                  <input
                    type="text"
                    className="form-item patient-name"
                    name="patient-name"
                    value={patientName}
                    onChange={(e) => setPatientName(e.target.value)}
                  />
                </FeatureGate>
              </div>

              <FormControl className="single-selection">
                <label>Ordering Physician</label>
                <div>
                  <select
                    className="form-select"
                    value={
                      orderingPhysician ||
                      worklistSearchParams.orderingPhysicianId
                    }
                    onChange={(e) =>
                      setOrderingPhysician(parseInt(e.target.value))
                    }
                  >
                    <option aria-label="None" value=""></option>
                    {orderingPhysicians &&
                      orderingPhysicians.map((physician, index) => (
                        <option
                          key={index}
                          value={physician.orderingPhysicianId}
                        >{`${physician.orderingPhysicianFirstName} ${
                          physician.orderingPhysicianMiddleName
                            ? physician.orderingPhysicianMiddleName + " "
                            : ""
                        }${physician.orderingPhysicianLastName}`}</option>
                      ))}
                  </select>
                  <div className="dropdown-icon">
                    <img
                      src={DropdownIcon}
                      alt="dropdown icon"
                      className="dropdown-suffix"
                    />
                  </div>
                </div>
              </FormControl>

              <FormControl className="single-selection">
                <label>Procedure Name</label>
                <div>
                  <select
                    className="form-select"
                    value={procedureName}
                    onChange={handleChangeProcedureName}
                  >
                    <option aria-label="None" value=""></option>
                    {proceduresOptions.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <div className="dropdown-icon">
                    <img
                      src={DropdownIcon}
                      alt="dropdown icon"
                      className="dropdown-suffix"
                    />
                  </div>
                </div>
              </FormControl>
              <FormControl className="single-selection">
                <label>Assigned To</label>
                <div>
                  <select
                    className="form-select"
                    value={assignedTo}
                    onChange={(e) => handleSelectOption(e, setAssignedTo)}
                  >
                    <option aria-label="None" value=""></option>
                    {filteredAssignToMembers.map((item, index) => (
                      <option key={index} value={item.userId}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <div className="dropdown-icon">
                    <img
                      src={DropdownIcon}
                      alt="dropdown icon"
                      className="dropdown-suffix"
                    />
                  </div>
                </div>
              </FormControl>
              <FormControl className="single-selection">
                <label>Referred To</label>
                <div>
                  <select
                    className="form-select"
                    value={referredTo}
                    onChange={(e) => handleSelectOption(e, setReferredTo)}
                  >
                    <option aria-label="None" value=""></option>
                    {filteredAssignToMembers.map((item, index) => (
                      <option key={index} value={item.userId}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <div className="dropdown-icon">
                    <img
                      src={DropdownIcon}
                      alt="dropdown icon"
                      className="dropdown-suffix"
                    />
                  </div>
                </div>
              </FormControl>
              <FormControl className="single-selection">
                <label>Select Date Range</label>
                <div>
                  <select
                    className="form-select"
                    value={dateRangeSelect}
                    onChange={(e) => setDateRangeSelect(e.target.value)}
                  >
                    {dateDurationValues.map((dateDurationValue, index) => (
                      <option key={index} value={dateDurationValue.value}>
                        {dateDurationValue.label}
                      </option>
                    ))}
                  </select>
                  <div className="dropdown-icon">
                    <img
                      src={DropdownIcon}
                      alt="dropdown icon"
                      className="dropdown-suffix"
                    />
                  </div>
                </div>
              </FormControl>
              {dateRangeSelect === "-" ? (
                // <div className="calender-container">
                //   <div className="filter-option procedure-date">
                //     <label>Procedure Date</label>
                //     <RangePicker
                //       defaultValue={[
                //         procedureDate.startDate,
                //         procedureDate.endDate,
                //       ]}
                //       className="date-picker"
                //       format="YYYY-MM-DD"
                //       suffixIcon={
                //         <img
                //           src={CalenderIcon}
                //           alt="calender"
                //           className="end-date"
                //         />
                //       }
                //       onChange={handleDateChange}
                //       separator={
                //         <img
                //           src={CalenderIcon}
                //           alt="calender"
                //           style={{ marginRight: "2px" }}
                //         />
                //       }
                //     />
                //   </div>
                // </div>
                <React.Fragment>
                  <FormControl className="single-selection">
                    <label>From Date</label>
                    <input
                      className="form-select"
                      type="date"
                      name="startDate"
                      id="startDate"
                      value={moment(new Date(procedureDate.startDate)).format(
                        "yyyy-MM-DD"
                      )}
                      max={moment(new Date(procedureDate.endDate)).format(
                        "yyyy-MM-DD"
                      )}
                      onChange={handleDateChange}
                    />
                  </FormControl>
                  <FormControl className="single-selection to-date">
                    <label>To Date</label>
                    <input
                      className="form-select"
                      type="date"
                      name="endDate"
                      id="endDate"
                      min={moment(new Date(procedureDate.startDate)).format(
                        "yyyy-MM-DD"
                      )}
                      value={moment(new Date(procedureDate.endDate)).format(
                        "yyyy-MM-DD"
                      )}
                      onChange={handleDateChange}
                    />
                  </FormControl>
                </React.Fragment>
              ) : null}
              {/* <div className="status-and-priority"> */}
              <FormControl className="single-selection final-row">
                <label>Case Status</label>
                <div>
                  <select
                    className="form-select"
                    value={caseStatus}
                    onChange={(e) => handleSelectOption(e, setCaseStatus)}
                  >
                    <option aria-label="None" value=""></option>
                    {caseStatusValues.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <div className="dropdown-icon">
                    <img
                      src={DropdownIcon}
                      alt="dropdown icon"
                      className="dropdown-suffix"
                    />
                  </div>
                </div>
              </FormControl>
              <FormControl className="single-selection final-row">
                <label>Case Priority</label>
                <div>
                  <select
                    className="form-select"
                    value={casePriority}
                    onChange={(e) => handleSelectOption(e, setCasePriority)}
                  >
                    <option aria-label="None" value=""></option>
                    {casePriorityValues.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <div className="dropdown-icon">
                    <img
                      src={DropdownIcon}
                      alt="dropdown icon"
                      className="dropdown-suffix"
                    />
                  </div>
                </div>
              </FormControl>
              {/* </div> */}
              <FormControl className="single-selection final-row">
                <label>Labels</label>
                <input
                  type="text"
                  className="form-item"
                  value={labels}
                  onChange={(e) => setLabels(e.target.value)}
                />
              </FormControl>
            </div>
            <PrimaryButton
              style={{
                position: "relative",
                height: "40px",
                width: "136px",
                padding: "9px 12px",
                marginTop: "0",
                borderRadius: "10px",
              }}
              data-test="search-btn"
              label="Save Worklist"
              disabled={worklistName === ""}
              onClick={handleApply}
            />
          </div>
        </div>
        <Menu
          anchorEl={colorPickerAnchorElement}
          open={isColorPickerMenuOpen}
          onClose={handleColorPickerClose}
          className="worklist-color-overlay-container"
        >
          {/* {colorOptions.map((item, index) => (
            <div
              key={index}
              // style={{}}
              className='color-item'
              style={{ backgroundColor: item.value }}
            />
          ))} */}
          <div className="worklist-color-overlay">
            {colorOptions.map((item, index) => (
              <div
                key={index}
                className="color-item"
                style={{ backgroundColor: item.value }}
                onClick={(e) => {
                  setWorklistColor(item.value);
                  handleColorPickerClose(e);
                }}
              ></div>
            ))}
          </div>
        </Menu>
      </React.Fragment>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  roleName: state.Global.loggedUserDetails.roleName,
  licenseTypeFeatures: state.Global.loggedUserDetails.licenseTypeAccessData,
  proceduresList: state.Dashboard.proceduresList,
  memberDetails: state.SlideBox.userList,
  worklistSearchParams: state.SlideBox.searchParams,
  orderingPhysicians: state.Dashboard.orderingPhysicians,
});
const mapDispatchToProps = (dispatch) => ({
  setSearchParams: (payload) => dispatch(setSearchParams(payload)),
  getWorklistSearchParameters: (payload) =>
    dispatch(getWorklistSearchParameters(payload)),
  updateWorklist: (payload) =>
    dispatch(updateWorklistSearchInfoAction(payload)),
  refreshWorklist: (payload) => dispatch(getWorklistDataAction(payload)),
  getAllPhysicians: () => dispatch(getAllPhysiciansAction()),
  getAllProcedures: () => dispatch(getAllProceduresAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlideboxSearchDetails);
