import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import ArrowLeft from "../../../assets/icons/arrow_left.svg";
import UploadImageIcon from "../../../assets/icons/camera.svg";
import altAvatarIcon from "../../../assets/icons/default-avatar.svg";
import DropdownIcon from "../../../assets/icons/dropdown-icon.svg";
import PrivateGroupIcon from "../../../assets/icons/private-group-icon.svg";
import PublicGroupIcon from "../../../assets/icons/public-group-icon.svg";
import ThreeDots from "../../../assets/icons/three-dots.svg";
import { Notify } from "../../../components/index";
import { useNameFormat } from '../../adminDashboard/components/DashboardItems/useFormat';
import MemberInvation from "./member-invitation";
import User from "./user";

const GroupNew = ({
  onSubmit,
  footerNav,
  loading,
  changeActiveTab,
  groups,
  setLoading,
}) => {
  const [file, setFile] = useState({});
  const [bloblFileURL, setBloblFileURL] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [about, setAbout] = useState("");
  const [name, setName] = useState("");
  const [selectOpen, setSelectOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [error, setError] = useState("");

  const fileRef = useRef(null);

  useEffect(() => {
    if (footerNav) {
      footerNav("middle");
    }
  }, []);

  const submit = () => {
    let response = {
      file,
      isPublic,
      bio: about,
      name,
      members,
    };
    let count = 0;
    if (name === "") {
      Notify({ value: "Group name is required" });
      setLoading(false);
    }
    if (groups.length > 0) {
      groups.some((group) => {
        if (group.name.toLowerCase() === name.toLowerCase()) {
          Notify({ value: "Group with the same name already exists" });
          setLoading(false);
          count += 1;
          return;
        }
      });
    }
    if (count === 0) {
      onSubmit && onSubmit(response);
    }
  };

  const checkUser = (toCheck) => {
    const checked = members.filter((user) => {
      if (user.id === toCheck.id) return user.id;
    });
    return checked.length === 0;
  };

  const onUploadFile = (e) => {
    const { files } = e.target;
    setBloblFileURL(URL.createObjectURL(files[0]));
    setFile(files[0]);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setName(value);
  };

  const onAboutChange = (e) => {
    const { value } = e.target;
    setAbout(value);
  };

  const addSelf = (user) => {
    if (checkUser(user)) {
      setMembers([user, ...members]);
    }
  };
  return (
    <div className="group-new">
      <div className="group-new-content">
        <div className="go-to-previous-page" onClick={() => footerNav("left")}>
          <img src={ArrowLeft} alt="goback" />
        </div>
        <h3 className="group-header">New Group</h3>
        <div className="group-info">
          <div className="upload-input-wrapper">
            <div className="upload-img-wrapper">
              <img
                src={_.isEmpty(bloblFileURL) ? UploadImageIcon : bloblFileURL}
                style={
                  !_.isEmpty(bloblFileURL)
                    ? { borderRadius: "100%" }
                    : { width: "50px" }
                }
                alt="upload image"
              />
            </div>
            <input
              type="file"
              accept="image/*"
              ref={fileRef}
              className="group-name-input"
              onChange={onUploadFile}
            />
          </div>
          <div className="input-wrapper">
            <div className="name-and-type">
              <input type="text" onChange={onChange} placeholder="Group name" />
              <p class="invalid-message">{error}</p>
            </div>
            <div className="textarea-wrapper">
              <h3>About</h3>
              <textarea onChange={onAboutChange} className="aaaaaaa" />
            </div>
            <div className="group-new-members">
              <div className="group-new-title">
                <h3>Members</h3>
                <p>{members.length}</p>
              </div>
              <MemberInvation onMemberSelect={addSelf} />

              <div className="users">
                {members.map((member, i) => (
                  <User
                    key={i}
                    // TODO: remove this absolute path
                    img={member.user_image_src || altAvatarIcon}
                    fullName={`${useNameFormat(member.firstname, member.surname)}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="submit-button-wrapper">
          <button className="cancel-button" onClick={() => changeActiveTab("")}>
            Cancel
          </button>
          <button onClick={submit} className="create-button">
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
        <p class="invalid-message">{error}</p>
      </div>
    </div>
  );
};


export default GroupNew;
