import React, { useState } from "react";
import CreateNewDxAccountForm from "./CreateNewDxAccountForm";
import { PrimaryButton } from "../../../components";

const CreateNewDxAccount = ({ createDxAccount, getDxAccountList }) => {
  const [openCreateNewAccountForm, setOpenCreateNewAccountForm] = useState(
    false
  );

  const handleOpenModal = () => {
    setOpenCreateNewAccountForm(!openCreateNewAccountForm);
  };

  const handleModalClose = () => {
    setOpenCreateNewAccountForm(false);
  };

  return (
    <div className="user-list-container">
      <div className="form-button-container">
        <PrimaryButton
          label="Create New Dx Account"
          extraClass="form-button"
          onClick={handleOpenModal}
        />
      </div>
      <CreateNewDxAccountForm
        openModal={openCreateNewAccountForm}
        onClose={handleModalClose}
        createDxAccount={createDxAccount}
        getDxAccountList={getDxAccountList}
      />
    </div>
  );
};

export default CreateNewDxAccount;
