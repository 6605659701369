import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { PrimaryButton, SecondaryButton } from "../../../../components";
import { updateAccountDetailsAction } from "../../actions/adminDashboard-action";
import { uploadInstitutionLogo } from "../../../profile/actions/profile-action";
import Timezones from "./Timezones.json";

const AccountSettings = ({
  dxAccountDetails,
  bytesToSize,
  updateAccountDetails,
  loggedUserDetails,
  uploadInstitutionLogo,
  getAccountDetails,
  getLoggedUserDetails,
}) => {
  const [dateFormat, setDateFormat] = useState(
    dxAccountDetails && dxAccountDetails.date_format
      ? dxAccountDetails.date_format
      : "MM-DD-YYYY"
  );
  const [nameFormat, setNameFormat] = useState(
    dxAccountDetails && dxAccountDetails.display_name_format
      ? dxAccountDetails.display_name_format
      : "firstname lastname"
  );
  const [institutionName, setInstitutionName] = useState(
    loggedUserDetails && loggedUserDetails.institutionName
      ? loggedUserDetails.institutionName
      : ""
  );
  const [timezone, setTimezone] = useState(
    dxAccountDetails && dxAccountDetails.timezone /* id to be added. */
      ? dxAccountDetails.timezone
      : ""
  );
  const [inputKey, setInputKey] = useState("");
  const [logo, setLogo] = useState("");
  const logoInput = useRef();
  const TimezonesList = Timezones.sort((a, b) => {
    let fa = `${a.Timezone.toLowerCase()} - ${a.offset.toLowerCase()}`,
      fb = `${b.Timezone.toLowerCase()} - ${b.offset.toLowerCase()}`;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  const uploadLogo = (logo) => {
    uploadInstitutionLogo({
      file: logo.file,
      userId: loggedUserDetails.userID,
    });
  };

  const updateFields = async () => {
    const accountDetails = {
      institutionName: institutionName,
      dateFormat: dateFormat,
      nameFormat: nameFormat,
      timezone: timezone,
    };
    if (logo) {
      await uploadLogo(logo);
    }
    await updateAccountDetails({ accountDetails });
    await getAccountDetails();
    await getLoggedUserDetails();
    await setLogo("");
    await setInputKey(Math.random().toString(36));
  };

  useEffect(() => {
    getAccountDetails();
    getLoggedUserDetails();
  }, []);

  return (
    <div className="account-settings-container">
      <div className="account-settings">
        <div className="account-settings-form-container">
          {dxAccountDetails && (
            <React.Fragment>
              <div className="form-item">
                <p className="label">Institution Name</p>
                <input
                  type="text"
                  className="name-input"
                  placeholder="Enter Institution Name"
                  value={institutionName ? institutionName : ""}
                  onChange={(e) => {
                    setInstitutionName(e.target.value);
                  }}
                />
              </div>
              <div className="form-item logo-form-item">
                <p className="label">Institution Logo</p>
                <div
                  className="logo-button-container"
                  style={{ alignItems: logo ? "center" : "flex-start" }}
                >
                  {logo && (
                    <img
                      className="institution-logo-img"
                      src={logo.url}
                      alt="institution logo"
                    />
                  )}
                  <SecondaryButton
                    label={logo ? "Change Logo" : "Select Image"}
                    onClick={() => {
                      logoInput.current.click();
                    }}
                  />
                  {logo && (
                    <SecondaryButton
                      label="Remove Image"
                      onClick={() => {
                        setLogo("");
                        setInputKey(Math.random().toString(36));
                      }}
                      extraClass="remove-btn"
                    />
                  )}
                  <input
                    type="file"
                    ref={logoInput}
                    key={inputKey ? inputKey : ""}
                    onChange={(e) => {
                      setLogo({
                        url: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files[0],
                      });
                    }}
                    name="institution-logo"
                    id="institution-logo"
                  />
                </div>
              </div>
              <div className="form-item">
                <p className="label">Account ID</p>
                <p className="text">
                  {dxAccountDetails.dx_account_id &&
                    dxAccountDetails.dx_account_id}
                </p>
              </div>
              <div className="form-item">
                <p className="label">License Number</p>
                <p className="text">
                  {dxAccountDetails.license_number &&
                    dxAccountDetails.license_number}
                </p>
              </div>
              <div className="form-item">
                <p className="label">Licence End Date</p>
                <p className="text">
                  {dxAccountDetails.license_end_date &&
                    dxAccountDetails.license_end_date.split("T")[0]}
                </p>
              </div>
              <div className="form-item">
                <p className="label">Admin Email</p>
                <p className="text">
                  {dxAccountDetails.admin_email_id &&
                    dxAccountDetails.admin_email_id}
                </p>
              </div>
              <div className="form-item">
                <p className="label">Number of Users</p>
                <p className="text">
                  {dxAccountDetails.number_of_users &&
                    dxAccountDetails.number_of_users}
                </p>
              </div>
              <div className="form-item">
                <p className="label">Storage Limit</p>
                <p className="text">
                  {dxAccountDetails.storage_limit &&
                    bytesToSize(dxAccountDetails.storage_limit)}
                </p>
              </div>
              <div className="form-item">
                <p className="label">Timezone</p>
                <select
                  className="text select"
                  name="settings-item"
                  id="timezone"
                  value={timezone}
                  onChange={(e) => {
                    setTimezone(e.target.value);
                  }}
                >
                  {TimezonesList.map((item) => (
                    <option value={item.Timezone}>
                      {item.Timezone} - {item.offset}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-item">
                <p className="label">Date Format</p>
                <select
                  className="text select"
                  name="settings-item"
                  id="date-format"
                  value={dateFormat}
                  onChange={(e) => {
                    setDateFormat(e.target.value);
                  }}
                >
                  <option value="DD-MM-YYYY">dd-mm-yyyy</option>
                  <option value="MM-DD-YYYY">mm-dd-yyyy</option>
                </select>
              </div>
              <div className="form-item">
                <p className="label">Name Display Format</p>
                <select
                  className="text select"
                  name="settings-item"
                  id="name-format"
                  value={nameFormat}
                  onChange={(e) => {
                    setNameFormat(e.target.value);
                  }}
                >
                  <option value="lastname firstname">
                    Last name First name
                  </option>
                  <option value="firstname lastname">
                    First name Last name
                  </option>
                </select>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="submit-buttons prompt-bottom">
        <PrimaryButton
          label="Save"
          onClick={async () => {
            await updateFields();
            await getAccountDetails();
            await getLoggedUserDetails();
          }}
          extraClass="prompt-done"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateAccountDetails: (payload) =>
    dispatch(updateAccountDetailsAction(payload)),
  uploadInstitutionLogo: (payload) => dispatch(uploadInstitutionLogo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
