import React, { useState } from "react";
import { Slider } from "material-ui-slider";
import SliderToggle from "../icons/toggle-slide-manager-icon.svg";
import ToolTip from "react-tooltip";

const ViewerToolbarFilters = ({ viewer, OpenSeadragon }) => {
  const [selectedColorAdjustIcon, setSelectedColorAdjustIcon] = useState(false);

  const handleOsdFilters = (val, filterType) => {
    switch (filterType) {
      case "brightness":
        viewer.setFilterOptions({
          filters: {
            processors: OpenSeadragon.Filters.BRIGHTNESS(val),
          },
        });
        break;
      case "contrast":
        viewer.setFilterOptions({
          filters: {
            processors: OpenSeadragon.Filters.CONTRAST(val / 10),
          },
        });
        break;
    }
  };

  const toggleImageFilters = () => {
    setSelectedColorAdjustIcon(!selectedColorAdjustIcon);
  };

  return (
    <React.Fragment>
      <ul>
        <li
          id="show-slider"
          className={selectedColorAdjustIcon ? "active-tool" : "nonactive-tool"}
          data-for="viewer-toolbar"
          data-tip={"Toggle image filters"}
          onClick={toggleImageFilters}
        >
          <img src={SliderToggle} />
        </li>
      </ul>
      {selectedColorAdjustIcon && (
        <div className="right-image-menu-control-parent color-adjustment-menu">
          <p className="right-image-menu-color-control-title">Color Control</p>
          <div className="right-image-menu-item">
            <p>Brightness</p>
            <Slider
              min={-255}
              max={255}
              defaultValue={0}
              onChange={(e) => {
                handleOsdFilters(e, "brightness");
              }}
            />
          </div>
          <div className="right-image-menu-item">
            <p>Contrast</p>
            <Slider
              min={0}
              max={40}
              defaultValue={10}
              onChange={(e) => {
                handleOsdFilters(e, "contrast");
              }}
            />
          </div>
        </div>
      )}
      <ToolTip id="viewer-toolbar" place="bottom" type="info" effect="solid" />
    </React.Fragment>
  );
};

export default ViewerToolbarFilters;
