import React from 'react'
import Spinner from './spinner'

const Loading = () => {
  return (
    <div className="page_loading">
      <Spinner />
    </div>
  )
}

export default Loading
