import axios from "./axiosInstance";
import d from "./dom";
import Action from "./loginAction";
import { Notify } from "../components";
const jwt = require("jsonwebtoken");
/**
 * For username checker
 * @param {String} el
 */
export const username_checker = (el) => {
  let element = new d(el);
  let uc = new d(".username_checker");
  element.on("keyup", async (e) => {
    let value = e.target.value;
    uc.show();

    if (value) {
      let { data: count } = await axios.post("/user/username-checker", {
        value,
      });
      let el = document.getElementById("username_checker");
      el && el.parentNode.removeChild(el);
      let html;

      if (count == 0) {
        html =
          '<span id="username_checker" class="checker_text" data-valid="valid">username is available</span><span class="checker_icon"><i class="far fa-smile"></i></span>';

        uc.mutipleCSS({
          width: "160px",
          right: "-188px",
        });
      } else {
        html =
          '<span id="username_checker" class="checker_text" data-valid="invalid">username already taken</span><span class="checker_icon"><i class="far fa-frown"></i></span>';

        uc.mutipleCSS({
          width: "167px",
          right: "-194px",
        });
      }

      uc.html(html);
    } else {
      uc.hide();
    }
  });

  element.on("blur", () => uc.hide());
};

// export const username_checkapi = username => {
//   if (username) {
//     let { data: count } = await axios.post('/user/username-checker', { username });
//     let el = document.getElementById('username_checker');
//      el  &&  el.parentNode.removeChild(el) ;
//     let html;
//     if (count == 0) {
//       html =
//         '<span id="username_checker" class="checker_text" data-valid="valid">username is available</span><span class="checker_icon"><i class="far fa-smile"></i></span>'

//       uc.mutipleCSS({
//         width: '160px',
//         right: '-188px',
//       })
//     } else {
//       html =
//         '<span id="username_checker" class="checker_text" data-valid="invalid">username already taken</span><span class="checker_icon"><i class="far fa-frown"></i></span>'

//       uc.mutipleCSS({
//         width: '167px',
//         right: '-194px',
//       })
//     }
// }

/**
 * Common function for login & signup
 *
 * @param {Object} options Options
 * @param {Object} options.data
 * @param {String} options.btn
 * @param {String} options.url
 * @param {String} options.redirect
 * @param {String} options.defBtnValue
 */
export const commonLogin = (options) => {
  let { data, btn, url, redirect, defBtnValue, when } = options,
    overlay2 = new d(".overlay-2"),
    button = new d(btn),
    action = new Action(btn);

  action.start("Please wait..");
  axios
    .post(url, data)
    .then((s) => {
      let {
        data: {
          mssg,
          success,
          isPartialActive,
          userData,
          insertId,
          authToken,
          sessData,
        },
      } = s;
      if (success) {
        if (when === "signup") {
          new d(".s_submit").removeAttr("disabled");
          action.end(defBtnValue);
          const redirectPath =
            redirect === "/"
              ? "/"
              : location.protocol + "//" + location.host + redirect;

          Notify({
            value: mssg,
            done: () => (window.location.href = redirectPath),
          });
        }

        if (when === "login") {
          const redirectPath =
            redirect === "/"
              ? "/"
              : location.protocol + "//" + location.host + redirect;
          sessionStorage.setItem("isLogin", true);
          Notify({
            value: mssg,
            done: () => (window.location.href = localStorage.getItem("redirect_url") ? localStorage.getItem("redirect_url") : redirectPath),
          });
          const decoded = verifyToken();
          if (data.isChecked) {
            const secret = 'secret123kjkdfkafd';
            const tokenData = {
              dxAccountId: data.dxAccountId,
              username: data.username,
              password: data.password
            };
            const jsonToken = jwt.sign(tokenData, secret);
            localStorage.setItem("jsonToken", jsonToken);
          } else {
            if (decoded.username !== data.username && decoded.password !== data.password) {
              localStorage.clear();
            }
          }
        }

        if (when === "forgot_password") {
          new d(".reset-success-msg").html("");
          Notify({
            value: "Reset password link is sent to your mail",
          });
          new d(".reset-success-msg").html(mssg);
          action.end(defBtnValue);
          new d(".fp_submit").removeAttr("disabled");
        }

        if (when === "verifySignup") {
          new d(".s_verify_submit").removeAttr("disabled");
          action.end(defBtnValue);
          const redirectPath =
            redirect === "/"
              ? "/"
              : location.protocol + "//" + location.host + redirect;

          Notify({
            value: "Email verified successfully",
            done: () => (window.location.href = redirectPath),
          });
        }

        ///// Resend OTP

        if (when === "resendOtp") {
          new d(".resend_otp_btn").removeAttr("disabled");
          action.end(defBtnValue);
          const redirectPath =
            redirect === "/"
              ? "/"
              : location.protocol + "//" + location.host + redirect;
          Notify({
            value: "OTP sent",
            done: () => (window.location.href = redirectPath),
          });
        }

        //////// End of Resend OTP

        if (when === "reset_password") {
          new d(".s_submit").removeAttr("disabled");
          const redirectPath = redirect === "/";
          Notify({
            value: mssg,
            done: () => (window.location.href = redirectPath),
          });
        }
        if (when === "adminLogin") {
          // const redirectPath = "/site-administrator-dashboard";
          const redirectPath =
            redirect === "/"
              ? "/"
              : location.protocol + "//" + location.host + redirect;
          Notify({
            value: "Login Successful",
            done: () => (window.location.href = redirectPath),
          });
        }
      } else {
        if (when === "login") {
          sessionStorage.setItem("isLogin", true);
          Notify({
            value: mssg,
          });
          if (isPartialActive) {
            sessionStorage.setItem("socialLoginData", JSON.stringify(userData));
            new d("#signin-pending-text").html(
              "<p style='font-size: 12px;'><b>Account verification is pending.<a href='/verifySignUp'> Verify now?</a></b></p>"
            );
          }
        } else {
          Notify({
            value: mssg,
          });
        }

        new d(".l_submit").removeAttr("disabled");
        action.end(defBtnValue);
      }

      button.blur();
    })
    .catch((e) => {
      new d("#user_signup_btn").removeAttr("disabled");
    });
};

const verifyToken = () => {
  const token = localStorage.getItem('jsonToken');
  const secret = 'secret123kjkdfkafd';
  try {
    return jwt.verify(token, secret);
  } catch (error) {
    return null;
  }
};