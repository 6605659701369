import React from "react";
import { FadeIn } from "animate-components";
import { connect } from "react-redux";
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from "../../../../components";
import ChangeTypeIcon from "../../../../assets/icons/change-accout-type.svg";
import { useNameFormat } from "./useFormat";

const ChangeRoleType = ({
  onClose,
  userRoleInfo,
  changeDxUserRoleFn,
  rolesData,
}) => {
  const {
    firstName,
    lastName,
    roleName,
    userID: userId,
    roleId,
  } = userRoleInfo;
  const [newRoleName, setNewRoleName] = React.useState(roleName);
  const [newRoleId, setNewRoleId] = React.useState(roleId);

  const roleOptions = rolesData.map((data) => {
    return {
      id: data.roleId,
      name: data.roleName,
    };
  });

  const handleRoleChangeClick = () => {
    changeDxUserRoleFn({ newRoleId, newRoleName, userId });
    onClose(false);
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            title={`Change ${firstName}'s account type`}
            onClick={() => onClose(false)}
            img={ChangeTypeIcon}
            alt={"change-type"}
          />
          <div className="modal_middle">
            <p className="form-group role-description">
              Choose what account type {useNameFormat(firstName, lastName)} will
              have in the Crosscope workspace.
              <a href="#">Learn more about account types</a>
            </p>
            <div
              className="custom-radio"
              style={{ height: "200px", overflowY: "scroll" }}
            >
              {roleOptions.map(({ name, id }, index) => {
                return (
                  name !== "System Administrator" && (
                    <div className="form-group" key={index}>
                      {" "}
                      {/* this to be changed */}
                      <input
                        type="radio"
                        name="role"
                        value={name}
                        checked={newRoleName === name}
                        onChange={(e) => {
                          setNewRoleId(id);
                          setNewRoleName(e.currentTarget.value);
                        }}
                      />
                      <label>{name}</label>
                    </div>
                  )
                );
              })}
            </div>
          </div>
          <div className="prompt-bottom">
            <SecondaryButton label="Cancel" onClick={() => onClose(false)} />
            <PrimaryButton
              label="Save"
              onClick={handleRoleChangeClick}
              extraClass="prompt-done"
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  loggedUserRoleId: state.Global.loggedUserDetails.roleId,
  dxAccountDetails: state.Global.dxAccountDetails,
});

export default connect(mapStateToProps)(ChangeRoleType);
