import React, { useEffect, useState } from "react";
import AnalysisSectionSlideDetails from "./AnalysisSectionSlideDetails";
import "./AnalysisSection.scss";
import SlideViewerHighlightAreas from "../SlideViewer/SlideViewerHighlightAreas";

const HighlightAreaStep = ({
  selectedSlideDataToAnalyze,
  highlightAreas,
  selectAnalysisType,
  selectedAlgorithm,
  setSelectedAlgorithm,
  setContinueButtonDisabled,
  annotationCoordinates,
  setAnnotationCoordinates,
  runAnalysis,
  selectedAreaName,
  setSelectedAreaName,
}) => {
  const [imageMetaDataVal, setImageMetaDataVal] = useState(null);
  const [dziDataValue, setDziDataValue] = useState(null);
  const [higlightEnable, setHiglightEnable] = useState(false);
  const { uniqueId, dirDataName } = selectedSlideDataToAnalyze;

  useEffect(() => {
    const dziFilesUrl = selectedSlideDataToAnalyze.dzi_url;
    fetch(
      `${selectedSlideDataToAnalyze.dzi_url.split(".dzi_files/")[0]}.dzi.dzi`
    ).then((response) => {
      response.blob().then((blob) => {
        const reader = new FileReader();
        const ABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          const dziData = e.target.result; // This will give the xml string of that file
          const dziTileData = tileSourceFromData(dziData, dziFilesUrl);
          const config = {
            basename: "example/image",
            getImageURL: dziTileData,
            thumbnail: dziTileData,
            osdConfig: {
              setStrings: [{ name: "Tooltips.Home", value: "Reset" }],
              defaultZoomLevel: 0,
              tileSources: dziTileData,
              navigatorPosition: "ABSOLUTE",
              navigatorTop: "50px",
              navigatorLeft: "0px",
              navigatorHeight: "120px",
              navigatorWidth: "145px",
              visibilityRatio: 1.0,
              constrainDuringPan: true,
              sequenceMode: false,
              showReferenceStrip: false,
              showNavigator: false,
              navigatorAutoFade: false,
              zoomInButton: "zoom-in",
              zoomOutButton: "zoom-out",
              homeButton: "home",
              fullPageButton: "full-page",
              rotate: "rotate",
              crossOriginPolicy: "Anonymous",
              canvasDrag: false,
              slideId: selectedSlideDataToAnalyze.slidebox_slide_id,
            },
            pages: [
              {
                id: 0,
                title: "MLK",
                sidebarThumbnail: selectedSlideDataToAnalyze.dzi_url,
                transcript: "MLK",
                viewer: "OSD_VIEWER",
                cdmCollection: "mpls",
                cdmIdentifier: "3188",
                infoURL: selectedSlideDataToAnalyze.dzi_url,
              },
            ],
          };
          setDziDataValue(config);
          fetchImageMetadata(dziFilesUrl);
        };

        if (ABS) reader.readAsBinaryString(blob);
        else reader.readAsArrayBuffer(blob);
      });
    });
  }, []);

  const fetchImageMetadata = (url) => {
    fetch(url + "vips-properties.xml").then((response) => {
      response.blob().then((blob) => {
        const reader = new FileReader();
        const ABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          const metadata = parseMetadataXML(e.target.result);
          setImageMetaDataVal(metadata);
        };

        if (ABS) reader.readAsBinaryString(blob);
        else reader.readAsArrayBuffer(blob);
      });
    });
  };

  const parseMetadataXML = (data) => {
    let imageMetadata = {};
    const xml = $($.parseXML(data));
    const image = xml.find("image");
    const props = image.children().children();
    for (let p in props) {
      let property = props[p];
      if (property.firstElementChild) {
        let key = property.firstElementChild.innerHTML,
          value = property.lastElementChild.innerHTML;
        imageMetadata[key] = value;
      }
    }
    return imageMetadata;
  };

  const tileSourceFromData = (data, filesUrl) => {
    const xml = $($.parseXML(data));
    const image = xml.find("Image");
    const size = xml.find("Size");
    let dzi = {
      Image: {
        xmlns: image.attr("xmlns"),
        Url: filesUrl,
        Format: image.attr("Format"),
        Overlap: image.attr("Overlap"),
        TileSize: image.attr("TileSize"),
        Size: {
          Height: size.attr("Height"),
          Width: size.attr("Width"),
        },
      },
    };

    return dzi;
  };

  return (
    <React.Fragment>
      {imageMetaDataVal && (
        <SlideViewerHighlightAreas
          selectAnalysisType={selectAnalysisType}
          imageMetadata={imageMetaDataVal}
          setContinueButtonDisabled={setContinueButtonDisabled}
          {...dziDataValue}
          higlightEnable={higlightEnable}
          highlightAreas={highlightAreas}
          annotationCoordinates={annotationCoordinates}
          setAnnotationCoordinates={setAnnotationCoordinates}
          runAnalysis={runAnalysis}
          selectedAreaName={selectedAreaName}
          setSelectedAreaName={setSelectedAreaName}
        />
      )}
    </React.Fragment>
  );
};

export default HighlightAreaStep;
