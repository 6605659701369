import React, { useState, useEffect, useRef } from "react";
import {
  select,
  scaleBand,
  axisBottom,
  axisLeft,
  scaleLinear,
  stack,
  max,
} from "d3";
import * as d3 from "d3";
import moment from "moment";
import { useDateFormat } from '../../DashboardItems/useFormat';

export const StackedGraphAging = ({ datasets, keys, colors, dateFormat, timezone }) => {
  const [data, setData] = useState(datasets);
  const svgRef = useRef();
  const wrapperRef = useRef();
  const date = useDateFormat({ date: moment([])})

  useEffect(() => {
    const svg = select(svgRef.current);
    // const { width, height } = wrapperRef.current.getBoundingClientRect();
    const width = 400;
    const height = 300;
    const MARGINS = {
      top: 0,
      right: 10,
      bottom: 30,
      left: 50,
    };
    const stackGenerator = stack().keys(keys);
    const layers = stackGenerator(data);
    const extent = [
      0,
      max(layers, (layer) => max(layer, (sequence) => sequence[1])),
    ];
    const yScale = scaleLinear()
      .domain(d3.extent([...extent]))
      .range([height - MARGINS.top, MARGINS.bottom]);

    const x0Scale = scaleBand()
      .domain(data.map((d) => d.name))
      // .range([0, width])
      .range([MARGINS.left, width - MARGINS.right])
      .padding(0.46);
    const x1Scale = scaleBand()
      .domain(data.map((d) => d.type))
      .rangeRound([0, x0Scale.bandwidth()])
      .padding(0.12);

    const xAix = axisBottom(x0Scale);
    const yAix = axisLeft(yScale);

    svg
      .select(".x-axis")
      .attr("transform", `translate(0, ${height})`)
      .call(xAix);
    svg
      .select(".y-axis")
      .attr("transform", `translate(${MARGINS.left}, 0 )`)
      .call(yAix);

    svg
      .selectAll(".layer")
      .data(layers)
      .join("g")
      .attr("class", "layer")
      .attr("fill", (layer) => colors[layer.key])
      .selectAll("rect")
      .data((layer) => layer)
      .join("rect")
      .attr(
        "x",
        (sequence) => x0Scale(sequence.data.name) + x1Scale(sequence.data.type)
      )
      .attr("width", 45)
      .attr("y", (sequence) => yScale(sequence[1]))
      .attr("height", (sequence) => yScale(sequence[0]) - yScale(sequence[1]));

    svg
      .select(".x-axis")
      .selectAll(".tick")
      .on("click", (e) => {
        const filteredD = data.map((d) => {
          return {
            name: d.name,
            Affiliate: d.name === e ? 0 : d.Affiliate,
            Social: d.name === e ? 0 : d.Social,
            Media: d.name === e ? 0 : d.Media,
          };
        });
        setData(filteredD);
      });
    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -50)
      .attr("x", 0 - height / 2)
      .attr("dy", "5em")
      .style("text-anchor", "middle")
      .style("letter-spacing", "1px")
      .style("font-size", "14px")
      .style("font-family", "Roboto, sans-serif")
      .text("Number of Cases");
  }, [data, keys, colors]);

  return (
    <div ref={wrapperRef} className="case-aging-chart">
      <span className="chart-heading">
        Case Ageing Summary for Active cases
      </span>
      <span className="date">{date}</span>

      <svg ref={svgRef} style={{ width: "100%", height: "110%" }}>
        <g className="x-axis" />
        <g className="y-axis" />
      </svg>
      <div className="chart-indicators">
        <div className="circle review"></div>
        <span className="status review">Under Review</span>
        <div className="circle pending"></div>
        <span className="status pending">Pending</span>
      </div>
    </div>
  );
};
