import axios from "../../../utils/axiosInstance";

export const notificationListApi = async () => {
  try {
    const resData = await axios.get(`/api/notification/getNotifications`);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const clearNotificationApi = async () => {
  try {
    return await axios.post(`/api/notification/clearNotification`);
  } catch (err) {
    throw err;
  }
};

export const deleteNotificationApi = async (id) => {
  try {
    const resData = await axios.post(
      `/api/notification/deleteNotification/${id}`
    );
    return resData;
  } catch (error) {
    //console.log(error);
  }
};

export const unreadNotificationsApi = async () => {
  try {
    const resData = await axios.get(`/api/notification/getUnreadNotifications`);
    return resData;
  } catch (error) {
    //console.log(error);
  }
};

export const changeNotificationStatusApi = async (id) => {
  try {
    const resData = await axios.post(
      `api/notification/updateNotificationStatus/${id}`
    );
    return resData;
  } catch (error) {
    //console.log(error);
  }
};

