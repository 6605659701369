import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Header, SessionTimeout } from "./components";
import AppRoutes from "./App-routes";
import { SocketContext } from "./utils/serviceContext";
import ProgressBarComponent from "./pages/slidebox/components/SlideItems/ProgressBar";
import "antd/dist/antd.css";
import "./App.scss";
import * as slideBoxActions from "./pages/slidebox/actions/slidebox-action";
import * as groupActions from "./pages/groups/actions/groups-action";
import * as globleActions from "./actions/global-action";
import * as notificationActions from "./pages/notifications/actions/notification-actions";
import * as dashboardActions from "./pages/adminDashboard/actions/adminDashboard-action";
import { FeatureGate, FeatureProvider } from "react-feature-gate";

class App extends Component {
  static contextType = SocketContext;

  componentDidMount = () => {
    this.props.getUserDetailsAction();
    this.props.fetchUserBoards();
    this.props.getNotificationsAction();
    this.props.getUnreadNotifications();
    this.props.getAllWorkgroupAction();
  };

  componentWillMount() {
    this.context.onMessage().subscribe((msg) => {
      const data = msg.eventData;
      if (
        data.msg.user_id !== this.props.userData.userID ||
        data.msg.user_id !== 0
      ) {
        const _message = {
          email: data.sender.email,
          firstName: data.sender.firstname,
          surname: data.sender.surname,
          username: data.sender.username,
          id: msg.msgId,
          userImageSrc: data.sender.userImageSrc,
          board: { id: parseInt(data.tumorBoardId) },
          type: data.msg.group_message_type,
          time: data.msg.group_message_time,
          body: data.msg.group_message_msg,
          userId: data.msg.user_id,
          img: data.msg.group_message_img,
          // status: data.msg.tumor_board_status,
        };
        this.props.addMessage(_message);
      }
    });
    this.context.onMessageDeleted().subscribe(({ msgId, tumorBoardId }) => {
      if (this.props.selectedBoard) {
        if (parseInt(this.props.selectedBoard.id) === parseInt(tumorBoardId)) {
          this.props.deleteMessage(parseInt(msgId));
        }
      }
    });
    this.context
      .onMessageUpdated()
      .subscribe(({ msgId, tumorBoardId, msgData }) => {
        if (this.props.selectedBoard) {
          if (
            parseInt(this.props.selectedBoard.id) === parseInt(tumorBoardId)
          ) {
            this.props.editMessage(parseInt(msgId), msgData.group_message_msg);
          }
        }
      });

    this.context.onRequest().subscribe(async (req) => {
      if (req.type === "invitation" || req.type === "suggestion") {
        this.props.addInvitation(req);
        this.props.getNotificationsAction();
      } else {
        this.props.addRequest(req);
      }
    });
  }

  closeSnackbar = (conversionComplete = null, clearAll = false) => {
    this.props.showSnackBarComponent({
      show: false,
      fileList: [],
      conversionComplete,
      percentCompleted: null,
    });
    if (clearAll) {
      this.props.clearUploadingList();
    }
  };

  toggleSidebar = (toggle) => {
    this.props.toggleSidebarAction(!toggle);
  };

  render() {
    const { count, unreadNotificationList } = this.props;

    // const noti_read =
    //   count.length !== 0 &&
    //   count.filter((noti) => noti.notificationRead === false);
    const {
      showSnackBar,
      fileList,
      conversionComplete,
      percentCompleted,
      userData,
    } = this.props;
    return (
      <FeatureProvider features={this.props.licenseTypeFeatures}>
        <Router>
          <div className="app">
            <Header
              // un={2}
              un={unreadNotificationList}
              activeRoute="/"
              windowSize=""
              isMobile={false}
              userData={this.props.userData}
              toggleSidebar={this.toggleSidebar}
              collapseSidebar={!this.props.showSidebar}
              isLoggedUser
              isFeedLoad
            />
            <SessionTimeout />
            <AppRoutes
              isMobile={false}
              collapseSidebar={this.props.showSidebar}
              isLoggedUser
              userData={userData}
              features={this.props.licenseTypeFeatures}
              selectedFolderData={this.props.selectedFolderData}
            />

            {showSnackBar && (
              <ProgressBarComponent
                open={showSnackBar}
                fileList={fileList}
                conversionComplete={conversionComplete}
                percentCompleted={percentCompleted}
                closeCallBack={(conversionComplete, clearAll) =>
                  this.closeSnackbar(conversionComplete, clearAll)
                }
              />
            )}
          </div>
        </Router>
      </FeatureProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  showSnackBar: state.SlideBox.showSnackBar,
  fileList: state.SlideBox.fileList,
  conversionComplete: state.SlideBox.conversionComplete,
  percentCompleted: state.SlideBox.percentCompleted,
  showSidebar: state.Global.showSidebar,
  userData: state.Global.loggedUserDetails,
  count: state.Notifications.notificationList,
  unreadNotificationList: state.Notifications.unreadNotificationList,
  licenseTypeFeatures: state.Global.loggedUserDetails.licenseTypeAccessData,
  selectedBoard: state.Groups.selectedBoard,
  selectedFolderData: state.SlideBox.selectedFolderData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...slideBoxActions,
      ...globleActions,
      ...groupActions,
      ...notificationActions,
      ...dashboardActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
export { App as PureApp };
