import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage/session';
import SlideBox from '../pages/slidebox/reducers/slidebox-reducer';
import Global from './global-reducer';
import Viewer from '../pages/imageViewer/reducers/viewer-reducer';
import Dashboard from '../pages/adminDashboard/reducers/adminDashboard-reducer';
import Groups from '../pages/groups/reducers/groups-reducer';
import Notifications from '../pages/notifications/reducers/notification-reducer';
import SiteAdministrator from '../pages/siteAdminDashboard/reducers/site-admin-dashboard-reducer';
import AIAnalytics from '../pages/aiAnalytics/reducers/aiAnalytics-reducer';

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root');
    state.Global.loggedUserDetails = {};
    state.Global.otherUserDetails = {};
    state.Global.isLoggedUser = false;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  SlideBox: SlideBox,
  Global: Global,
  Viewer: Viewer,
  Dashboard: Dashboard,
  Groups: Groups,
  Notifications: Notifications,
  SiteAdministrator: SiteAdministrator,
  AIAnalytics: AIAnalytics,
});

export default rootReducer;
