const initialState = {
  showLoader: false,
  notificationList: [],
  unreadNotificationList: [],
  notifications: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "GET_NOTIFICATIONS": {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    case "SET_NOTIFICATION_LIST":
      const data = action.payload;
      return {
        ...state,
        notificationList: data,
        unreadNotificationList: data.filter(
          (notifications) => notifications.status === "unread"
        ),
      };
    case "DELETE_NOTIFICATION":
      return {
        ...state,
        notificationList: state.notificationList.filter(
          (notification) => notification.notifyId !== action.payload
        ),
      };
    case "SET_UNREAD_NOTIFICATIONS":
      return {
        ...state,
        unreadNotificationList: action.payload,
      };
    case "UPDATE_NOTIFICATION_STATUS":
      return {
        ...state,
        unreadNotificationList: state.unreadNotificationList.filter(
          (unreadNotification) => unreadNotification.notifyId !== action.payload
        ),
      };

    default:
      return state;
  }
}
