import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { MedicalTags } from "../../../config/constants";
import { StainTags } from "../../../config/constants";
import _ from "lodash";
import axios from '../../utils/axiosInstance';

const customStyles = {
  container: (provided) => ({
    ...provided,
    marginTop: 20,
    backgroundColor: "#eee",
    border: "none",
    outline: "none",
    borderRadius: 5,
  }),
  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    backgroundColor: "#eee",
    border: "none",
    outline: "none",
    borderRadius: 5,
  }),
  menu: (provided, state) => ({
    ...provided,
    
   marginTop: 30
  }),
};

const defaultStyles = {
  container: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
  }),
};

export default class SingleSelect extends Component {
  constructor(props) {
    super(props);
    const wait = 2000; // milliseconds
    const loadOptions = (inputValue) => this.getAsyncOptions(inputValue);
    this.debouncedLoadOptions = _.debounce(loadOptions, wait);
    this.state = {
      labelsArrayFromDb: [],
    }
  }

  async componentDidMount() {
    const res = await axios.get('/api/viewer/getUserSlideLabels');
    if (res.data.data === '') {
      return;
    }
    const array = res.data.data.split(',').map((labelValue) => {
      return { value: labelValue, label: labelValue };
    });
    this.setState({ labelsArrayFromDb: array });
  }

  getAsyncOptions = (inputValue) => {
    if (inputValue) {
      const tagType =
        this.props.type === "stains"
          ? StainTags
          : this.props.type === "user-list"
          ? this.props.members
          : MedicalTags;
      return new Promise((resolve) => {
        setTimeout(() => {
          // adding unique labels from db to the array
          this.state.labelsArrayFromDb.forEach((label) => {
            const index = tagType.findIndex((val) => val.value === label.value);
            if (index === -1) tagType.push(label);
          })

          const filtered = _.filter(tagType, (o) =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          if (filtered.length > 0) {
            resolve(filtered.slice(0, 1));
          } else {
            resolve([{ value: inputValue, label: inputValue }]);
          }
        }, 100);
      });
    }
  };

  render() {
    return (
      <AsyncSelect
        isMulti={this.props.type === "stains" ? false : true}
        className={this.props.classname}
        classNamePrefix="select"
        name="search"
        loadOptions={this.getAsyncOptions}
        onChange={this.props.handleChange}
        placeholder={this.props.placeholder || "Select..."}
        styles={this.props.customStyles ? customStyles : defaultStyles}
        defaultValue={this.props.defaultValue}
        onKeyDown={this.props.onKeyDown}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
        }}
        menuPlacement="bottom"
        
      />
    );
  }
}
