import React from "react";
import DefaultAvatar from "../../assets/icons/default-avatar.svg";

const UserCard = ({ user, addSelf }) => {
  return (
    user.userID && (
      <div className="userCard" onClick={() => addSelf()}>
        <img
          src={user.userImageSrc ? user.userImageSrc : DefaultAvatar}
          style={{
            width: "40px",
            height: "40px",
            margin: "5px",
            borderRadius: "25px",
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = DefaultAvatar;
          }}
        />
        <div
          style={{
            height: "55px",
            display: "flex",
            flexDirection: "column",
            paddingTop: "7px",
          }}
        >
          <p className="searchName">
            {(typeof user.firstName !== "undefined"
              ? user.firstName
              : user.firstName) +
              " " +
              user.lastName}
          </p>
          <p className="searchName">
            @{user.userName || user.username || user.firstName}
          </p>
        </div>
      </div>
    )
  );
};

export default UserCard;
