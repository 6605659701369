import React from 'react';
import { FadeIn } from 'animate-components';
import { Overlay, PrimaryButton, SecondaryButton } from '../../../components';
import ClosePopupIcon from '../../../assets/icons/close-new.svg';
import Snapshot from '../../../assets/icons/snapshot.png';

const ScreenshotPopUp = ({
  onSnapshotClose,
  openSnapshotUrl,
  downloadSnapshot,
  attachtoCase,
}) => {
  return (
    <React.Fragment>
      <Overlay />

      <div className="prompt snapshot">
        <FadeIn duration="300ms">
          <div className="prompt-top">
            <div className="top-icon-and-text">
              <img src={Snapshot} />
              <span className="prompt-title">Selected Area</span>
            </div>
            <img
              className="close-icon"
              src={ClosePopupIcon}
              onClick={() => onSnapshotClose(true)}
            />
          </div>

          <div className="prompt-middle">
            <div className="prompt-content">
              <img src={openSnapshotUrl} alt="" />
            </div>
          </div>

          <div className="prompt-bottom">
            <SecondaryButton
              label="Cancel"
              onClick={() => onSnapshotClose(true)}
              extraClass="setWidth"
            />
            <PrimaryButton
              label="Download"
              extraClass="prompt-download setWidth"
              onClick={downloadSnapshot}
            />
            {/* <button className="attachToCase setWidth" onClick={attachtoCase}>
              Attach To Case
            </button> */}
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

export default ScreenshotPopUp;
