import React from "react";
import moment from "moment";

const TimeItem = ({ time, timezone }) => {
  const formattedTime = moment(time).utcOffset(timezone && timezone).format("dddd, MMMM Do");
  return (
    <div className="time-item">
      <p>{formattedTime}</p>
    </div>
  );
};

export default TimeItem;
