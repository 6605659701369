import React, { useState, useEffect} from 'react'

const ColumnResizeHandle = ({ resizeRef }) => {
    const [mouseDown, setMouseDown] = useState(false);
  
    useEffect(() => {
      const handleMouseMove = (e) => {
        handleResize(e.movementX);
      }
  
      if(mouseDown) {
        window.addEventListener('mousemove', handleMouseMove);
      }
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }, [mouseDown, handleResize]);
  
    useEffect(() => {
      const handleMouseUp = () => setMouseDown(false);
      window.addEventListener('mouseup', handleMouseUp);
      return ()=> {
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }, []);
  
    const handleMouseDown = () => {
      setMouseDown(true);
    }
  
    const handleResize = (movementX) => {
      if(!resizeRef.current) return;
      const { width } = resizeRef.current.getBoundingClientRect();
  
      if (width + movementX >= 135 && width + movementX <= 255) resizeRef.current.style.width = `${width + movementX}px`;
    }
  
    return (
      <div 
        className="resize-handle" 
        onMouseDown={()=>handleMouseDown()}
      ></div>
    )
  }

export default ColumnResizeHandle;