import React, { Fragment } from 'react';
import { string, func, bool, oneOfType } from 'prop-types';
import classNames from 'classnames';

const PrimaryButton = ({ label, onClick, extraClass, disabled, ...props }) => {
  return (
    <Fragment>
      {props.href ? (
        <a
          href={props.href ? props.href : ''}
          className={classNames(
            'pri_btn',
            extraClass,
            disabled ? 'a_disabled' : ''
          )}
          onClick={!disabled ? onClick : null}
          {...props}
        >
          {typeof label == 'function' ? label() : label}
        </a>
      ) : (
        <a
          className={classNames(
            'pri_btn',
            extraClass,
            disabled ? 'a_disabled' : ''
          )}
          onClick={!disabled ? onClick : null}
          {...props}
        >
          {typeof label == 'function' ? label() : label}
        </a>
      )}
    </Fragment>
  );
};

PrimaryButton.defaultProps = {
  label: '',
  disabled: false,
  extraClass: '',
};

PrimaryButton.propTypes = {
  label: oneOfType([string, func]).isRequired,
  onClick: func.isRequired,
  extraClass: string,
  disabled: bool,
};

export default PrimaryButton;
