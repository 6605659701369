import React from "react";
import ZoomInStreams from "../icons/ZoomIn-Streams.svg";
import ZoomOutStreams from "../icons/ZoomOut-Streams.svg";
import fullPageIcon from "../icons/expand.svg";

const OpenSeadragonControls = ({
  when,
  isMobile,
  viewerId,
  keyFolder,
  postId,
}) => {
  const styles = {
    listStyle: {
      background: "#222C50",
      fontSize: 20,
      textAlign: "center",
      borderRadius: "100%",
    },
    linkStyle: {
      position: "relative",
      background: "#222C50",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      borderRadius: "100%",
    },
    mobileLinkStyle: {
      background: "#222C50",
      display: "inline-block",
      height: 21,
      width: 21,
    },
  };
  return (
    <ul className="osd-toolbar edit-tools">
      <li
        style={
          when == "central_feed" && !isMobile
            ? {
                ...styles.listStyle,
                marginBottom: 15,
                width: 35,
                height: 35,
              }
            : when == "central_feed" && isMobile
            ? {
                ...styles.listStyle,
                marginTop: -5,
                marginBottom: 5,
                padding: 5,
              }
            : null
        }
      >
        <a
          id={`zoom-in-${viewerId}`}
          style={
            when == "central_feed" && !isMobile
              ? styles.linkStyle
              : when == "central_feed" && isMobile
              ? styles.mobileLinkStyle
              : null
          }
        >
          {when == "central_feed" && (
            <img src={ZoomInStreams} style={{ width: "100%" }} />
          )}
        </a>
      </li>
      <li
        style={
          when == "central_feed" && !isMobile
            ? {
                ...styles.listStyle,
                width: 35,
                height: 35,
                marginBottom: 15,
              }
            : when == "central_feed" && isMobile
            ? { ...styles.listStyle, padding: 5 }
            : null
        }
      >
        <a
          id={`zoom-out-${viewerId}`}
          style={
            when == "central_feed" && !isMobile
              ? {
                  ...styles.listStyle,
                  width: 35,
                  height: 35,
                  marginBottom: 15,
                }
              : when == "central_feed" && isMobile
              ? styles.mobileLinkStyle
              : null
          }
        >
          {when == "central_feed" && (
            <img src={ZoomOutStreams} style={{ width: "100%" }} />
          )}
        </a>
      </li>
      {viewerId && (
        <li
          style={
            when == "central_feed" && !isMobile
              ? { ...styles.listStyle, width: 35, height: 35 }
              : when == "central_feed" && isMobile
              ? { ...styles.listStyle, padding: 5 }
              : null
          }
        >
          <a
            id={`full-page-${viewerId}`}
            style={
              when == "central_feed" && !isMobile
                ? styles.linkStyle
                : when == "central_feed" && isMobile
                ? styles.mobileLinkStyle
                : null
            }
            href={`${window.location.origin}/viewer?image=${keyFolder}&post=${postId}`}
            target="_blank"
          >
            {when == "central_feed" && (
              <img src={fullPageIcon} style={{ width: "100%" }} />
            )}
          </a>
        </li>
      )}
    </ul>
  );
};

export default OpenSeadragonControls;
