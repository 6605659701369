import React, { useState } from "react";
import { FadeIn } from "animate-components";

import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from "../../../components";
import { Select } from "antd";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { connect } from "react-redux";
import { updateSystemWorklistAction } from "../actions/slidebox-action";

const customStyles = {
  menu: (provided, state) => ({
    width: 265,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontSize: 14,
    fontFamily: `"Roboto", sans-serif`,
  }),

  optionContainer: (provided, state) => ({
    marginLeft: 10,
    marginTop: 15,
    width: "100%",
  }),
  option: (provided, state) => ({
    width: "100%",
    padding: 5,
    paddingLeft: 10,
  }),
};

const ManageWorklist = ({
  systemWorklists,
  workgroupsData,
  setOpenManageWorklistModal,
  updateSystemWorklist,
}) => {
  const [workgroups, setWorkgroups] = useState([]);
  const [selectedWorklist, setSelectedWorklist] = useState("");

  const handleSave = () => {
    updateSystemWorklist({
      worklistId: selectedWorklist,
      workgroupIds: workgroups.map((workgroup) => workgroup.id),
    });
    setOpenManageWorklistModal(false);
  };

  const workgroupList = workgroupsData.map((workgroup) => {
    return {
      id: workgroup.workgroupId,
      value: workgroup.workgroupName,
      label: workgroup.workgroupName,
    };
  });

  const handleChangeSelectedWorklist = (value) => {
    setSelectedWorklist(value);
    const workgroupsWorklist = systemWorklists.filter(
      (worklist) => worklist.worklistId === value
    );
    const { workgroupsData } = workgroupsWorklist[0];
    const workgroupOptions = workgroupsData.map((workgroup) => {
      return {
        id: workgroup.workgroupId,
        value: workgroup.workgroupName,
        label: workgroup.workgroupName,
      };
    });
    setWorkgroups(workgroupOptions);
  };
  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `All`;
    } else if (value && value.some((o) => o.value !== "*")) {
      return `${value ? value.map((item) => item.label) : ""}`;
    } else {
      return `${placeholderButtonLabel}`;
    }
  };

  function handleChangeValues(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(value);
    } else {
      this.setState(value);
    }
  }

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt manage-worklist-prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            img=""
            alt="#"
            title="Manage Worklist"
            onClick={() => setOpenManageWorklistModal(false)}
            id="modalHeader"
          />
          <div className="manage-worklist-modal_middle">
            <React.Fragment>
              <div
                className="worklist-type-container"
                style={{ width: "100%" }}
              >
                <label htmlFor="worklist-name">Select Worklist</label>

                <Select
                  placeholder="Select Worklist"
                  style={{ width: 265 }}
                  value={selectedWorklist}
                  onChange={handleChangeSelectedWorklist}
                >
                  {systemWorklists.map((worklist) => (
                    <Select.Option
                      key={worklist.worklistId}
                      value={worklist.worklistId}
                      style={{ paddingLeft: "5px", fontSize: "13px" }}
                    >
                      {worklist.worklistName}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="permission-container">
                <label htmlFor={"worklist-permission"}>
                  Worklist Permission
                </label>

                <ReactMultiSelectCheckboxes
                  options={[...workgroupList]}
                  placeholderButtonLabel=""
                  getDropdownButtonLabel={getDropdownButtonLabel}
                  value={workgroups}
                  onChange={handleChangeValues}
                  setState={setWorkgroups}
                  styles={customStyles}
                  hideSearch={true}
                  className={
                    selectedWorklist === ""
                      ? "worklist-permission-dropdown disabled"
                      : "worklist-permission-dropdown"
                  }
                />
              </div>
            </React.Fragment>
          </div>
          <div className="prompt-bottom">
            <SecondaryButton
              label="Cancel"
              onClick={() => {
                setOpenManageWorklistModal(false);
              }}
            />
            <PrimaryButton
              label="Save"
              onClick={handleSave}
              disabled={
                selectedWorklist === "" || workgroups.length === 0
                  ? true
                  : false
              }
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  systemWorklists: state.SlideBox.systemWorklists,
  workgroupsData: state.Dashboard.workgroupData,
});

const mapDispatchToProps = (dispatch) => ({
  updateSystemWorklist: (payload) =>
    dispatch(updateSystemWorklistAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageWorklist);
