import React, { useState } from "react";
import PropTypes from "prop-types";
import AppLink from "./link";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { keysObject, valuesObject } from "../../../utils/utils";
import {
  deleteNotificationAction,
  getNotificationsAction,
  getAllNotifications,
} from "../actions/notification-actions";
import { connect } from "react-redux";
import { Notify, PrimaryButton, SecondaryButton } from "../../../components";
import axios from "../../../utils/axiosInstance";
import { fetchUserBoards } from "../../groups/actions/groups-action";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));
const NotificationTypePost = ({
  post_id,
  postid_token,
  type,
  deleteNotification,
  getNotificationList,
  getNotis,
  groupInvitationId,
  invitedGroupId,
  notificationList,
  setNotifications,
  fetchUserBoards,
}) => {
  const classes = useStyles();
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  // React.useEffect(() => {
  //   getNotificationList()
  // }, [])

  // React.useEffect(() => {
  //   getNotificationList()
  //   setNotifications(notificationList)
  // }, [notificationList])

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={5}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }} n
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  const open = Boolean(anchorEl);
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.common.white,
        }, 
      },
    },
  }))(MenuItem);

  const ButtonAttributes = {
    Delete_Notification: {
      onClick: () => {
        deleteNotification(post_id);
        Notify({ value: "Notification deleted successfully." });
        setAnchorEl(null);
      },
    },
  };

  const handleClick = (post_id) => (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = (e, clearSelectedRowIndex) => {
    clearSelectedRowIndex === "backdropClick" && setSelectedRowIndex("");
    setAnchorEl(null);
  };

  // const handleDeleteNotification = (post_id) => {
  //   deleteNotification(post_id);
  //   Notify({ value: "Notification deleted successfully." });
  //   getNotificationList();
  // };

  const handleAcceptInvitation = async (groupInvitationId, invitedGroupId) => {
    const { status } = await axios
      .post("/api/groups/acceptInvitation", {
        invitationId: groupInvitationId,
        tumorBoardId: invitedGroupId,
      })
      .catch(() => {
        Notify({ value: "There was an error while accepting invitation." });
      });
    if (status === 200) {
      Notify({ value: "You joined the group successfully." });
      getNotificationList();
      fetchUserBoards();
    }
  };

  const handleRejectInvitation = async (invitationId) => {
    const { status } = await axios
      .delete(`/api/groups/rejectInvitation/${invitationId}`, {
        withCredentials: true,
      })
      .catch(() => {
        Notify({ value: "There was an error while rejecting invitation." });
      });
    if (status === 200) {
      Notify({ value: "Invitation rejected." });
      getNotificationList();
    }
  };

  return (
    <React.Fragment>
      {/* {type === "slidebox" && (
        <AppLink
          url={`/viewer?image=${postid_token}`}
          className="pri_btn"
          label={'Open image'}
        />
      )} */}
      <div className="noti_right prompt-bottom">
        {
          type === "groups" && (
            <SecondaryButton
              label="Reject"
              extraClass="reject"
              onClick={() => handleRejectInvitation(groupInvitationId)}
            />
          )
        }
        {
          type === "groups" && (
            <PrimaryButton
              label="Accept"
              onClick={() =>
                handleAcceptInvitation(groupInvitationId, invitedGroupId)
              }
              extraClass="prompt-done"
            />
          )
        }
      </div>

      {/* {type === "groups" && <AppLink
        url={`/groups`}
        className="pri_btn"
        label={"Open group"}
      />} */}

      {/* {type === "case" && (
        <AppLink url={`/slidebox`} className="pri_btn" label={"Show case"} />
      )} */}
      {/* <div style={{ cursor: "pointer" }}>
        <span
          onClick={() => handleDeleteNotification(post_id)}
          className="clear_noti"
          data-tip="Delete notification"
        >
          <i class="fas fa-times"></i>
        </span>
        <ToolTip place="bottom" type="info" effect="solid" />
      </div> */}
    </React.Fragment>
  );
};

NotificationTypePost.propTypes = {
  post_id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  notificationList: state.Notifications.notificationList,
});

const mapDispatchToProps = (dispatch) => ({
  deleteNotification: (id) => dispatch(deleteNotificationAction(id)),
  getNotificationList: () => dispatch(getNotificationsAction()),
  getNotis: () => dispatch(getAllNotifications()),
  setNotifications: (payload) => dispatch(setNotifications(payload)),
  fetchUserBoards: () => dispatch(fetchUserBoards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationTypePost);
