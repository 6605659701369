import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import AddUserIcon from '../../assets/icons/add-new-user.svg';
import axios from '../../utils/axiosInstance';
import UserCard from './UserCard';

const MemberInvitation = ({
  onMemberSelect,
  userDetails,
  isCollaborator,
  selectedList,
  assignUsersCurrent,
  alreadyInUsers = null,
}) => {
  const [searching, setSearching] = useState('');
  const membersRef = useRef(null);
  const [timeout, setTimeoutState] = useState(undefined);
  const [results, setResults] = useState([]);
  const [done, setDone] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [userAlredySelected, setUserAlredySelected] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
      if (timeout) clearTimeout(timeout);
    };
  }, []);
  useEffect(() => {
    setResults([]);
    setDone(false);
    clearTimeout(timeout);
    if (searching !== '') {
      setTimeoutState(setTimeout(search, 1000));
    }
  }, [searching]);

  const addSelf = (user) => {
    onMemberSelect(user);
    setSearching('');
    membersRef.current.value = '';
  };
  const search = async () => {
    const apiUrl = `/api/user/getCollaborateUsers?filter=${encodeURI(
      searching
    )}&filePath=${encodeURI(selectedList)}`;
    const { status, data } = await axios.get(apiUrl, { withCredentials: true });
    if (status === 200 && typeof data.body !== 'undefined' && mounted) {
      const dataList = isCollaborator
        ? data.body.filter((u) => u.userID !== userDetails.userID)
        : data.body;
      const filterDataList = alreadyInUsers
        ? dataList.filter(filterAlredyAssignUsers)
        : dataList;
      setResults(filterDataList);
      setDone(true);
    }
  };

  const filterAlredyAssignUsers = (listUserData) => {
    const alreadyInUsersArray = alreadyInUsers.map((data) => data.userID);
    const isAlredySelected = alreadyInUsersArray.includes(listUserData.userID);
    if (!isAlredySelected) {
      return listUserData;
    } else {
      setUserAlredySelected(true);
    }
  };

  return (
    <div className="members-input-wrapper">
      <img src={AddUserIcon} alt="user add" />
      <input
        type="text"
        placeholder={isCollaborator ? 'Invite people' : 'Users'}
        onChange={() => {
          setSearching(membersRef.current.value.trim());
        }}
        ref={membersRef}
        spellCheck={false}
        disabled={isCollaborator ? false : assignUsersCurrent.length !== 0}
      />
      {searching !== '' && (
        <div
          className="usersSearch"
          id={results.length > 0 ? 'withResults' : 'noResults'}
        >
          {!done && <p className="infoText">Searching...</p>}
          {done && results.length === 0 && (
            <p className="infoText">
              {userAlredySelected
                ? 'User is already selected or could not find any users'
                : 'Could not find any users.'}
            </p>
          )}
          {results.map((user, i) => (
            <UserCard user={user} key={i} addSelf={() => addSelf(user)} />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.Global.loggedUserDetails,
  ...state.SlideBox.selectedListData,
});

export default connect(mapStateToProps)(MemberInvitation);
