import { check } from "../../utils/permissonCheck";

const Can = (props) =>
  check(props.task, props.taskContainer) ? props.edit() : props.noAccess();

Can.defaultProps = {
  edit: () => null,
  noAccess: () => null,
};

export default Can;
