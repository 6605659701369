import React, { useState, useEffect, useRef } from "react";
import ColorIcon from "../../../../assets/icons/colorIcon.svg";
import RefreshIcon from "../../../../assets/icons/refresh-icon.svg";
import TrashIcon from "../../../../assets/icons/trash-icon.svg";
import { connect } from "react-redux";
import {
  closeTab,
  getMyWorklistsAction,
  updateWorklistAction,
  getWorklistDataAction,
  getWorklistSearchParameters,
} from "../../actions/slidebox-action";
import { useOutsideClick } from "../../../../utils/utils";
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from "../../../../components";
import { FadeIn } from "animate-components";
import SlideboxSearchDetails from "../SlideboxSearchDetails";

const WorklistContextMenu = ({
  updateWorklist,
  contextOpen,
  setContextOpen,
  selectedWorklist,
  allTabs,
  closeTab,
  refreshWorklist,
  isSystemWorklist,
  role,
  x,
  y,
  isAllCasesWorklist,
  getWorklistSearchParameters,
  currentWorklistColor,
  currentWorklistName,
}) => {
  const [colorOverlay, setColorOverlay] = useState(false);
  const modalRef = useRef(null);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [showWorklistDetailsModal, setShowWorklistDetailsModal] = useState(
    false
  );

  useOutsideClick(modalRef, () => {
    setColorOverlay(false);
  });

  useEffect(() => {
    function handler(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setContextOpen(false);
      }
    }
    window.addEventListener("mousedown", handler);
    return () => {
      window.removeEventListener("mousedown", handler);
    };
  }, []);

  const contextMenuOptions = [
    {
      id: 1,
      icon: ColorIcon,
      name: "Edit Worklist",
    },
    {
      id: 2,
      icon: RefreshIcon,
      name: "Refresh Worklist",
    },
    {
      id: 3,
      icon: TrashIcon,
      name: "Delete Worklist",
    },
  ];

  const contextMenuOptionsAllCases = [
    {
      id: 1,
      icon: ColorIcon,
      name: "Change Tab Color",
    },
    {
      id: 2,
      icon: RefreshIcon,
      name: "Refresh Worklist",
    },
  ];

  const colorOptions = [
    { id: 1, value: "rgba(247, 65, 45, 0.5)" },
    { id: 2, value: "rgba(255, 58, 41, 0.9)" },
    { id: 3, value: "rgba(157, 27, 178, 0.51)" },
    { id: 4, value: "rgba(68, 190, 141, 0.56)" },
    { id: 5, value: "rgba(62, 78, 184, 0.51)" },
    { id: 6, value: "rgba(17, 148, 246, 0.54)" },
    { id: 7, value: "rgba(86, 87, 89, 0.49)" },
    { id: 8, value: "rgba(77, 115, 168, 0.43)" },
    { id: 9, value: "#009788" },
    { id: 10, value: "#FF8364" },
    { id: 11, value: "#89C540" },
    { id: 12, value: "#ECE7F8" },
  ];

  const handleContextOption = async (id) => {
    if (id === 1) {
      // setColorOverlay(true);
      await getWorklistSearchParameters(selectedWorklist);
      setShowWorklistDetailsModal(true);
    } else if (id === 2) {
      refreshWorklist({ worklistId: selectedWorklist });
    } else if (id === 3) {
      setConfirmationModal(true);
    } else if (id === 4) {
    }

    setContextOpen(false);
  };

  const handleColorChange = (value) => {
    updateWorklist({
      worklistId: selectedWorklist,
      worklistColour: value,
      actionType: "change-colour",
    });
    setColorOverlay(false);
  };

  return (
    <React.Fragment>
      <div
        className={`worklist-context-menu ${contextOpen ? "open" : ""}`}
        ref={modalRef}
        style={{ top: y, left: x }}
      >
        {isAllCasesWorklist
          ? contextMenuOptionsAllCases.map((item, index) => {
              if (role === "System Administrator") {
                return (
                  <div
                    index={item.id}
                    className="context-item"
                    onClick={() => handleContextOption(item.id)}
                  >
                    <img src={item.icon} alt="context icon" />
                    <p>{item.name}</p>
                  </div>
                );
              } else if (!isSystemWorklist || index < 2) {
                return (
                  <div
                    index={item.id}
                    className="context-item"
                    onClick={(e) => handleContextOption(item.id)}
                  >
                    <img src={item.icon} alt="context icon" />
                    <p>{item.name}</p>
                  </div>
                );
              }
            })
          : contextMenuOptions.map((item, index) => {
              if (role === "System Administrator") {
                return (
                  <div
                    index={item.id}
                    className="context-item"
                    onClick={(e) => handleContextOption(item.id)}
                  >
                    <img src={item.icon} alt="context icon" />
                    <p>{item.name}</p>
                  </div>
                );
              } else if (!isSystemWorklist || index < 2) {
                return (
                  <div
                    index={item.id}
                    className="context-item"
                    onClick={(e) => handleContextOption(item.id)}
                  >
                    <img src={item.icon} alt="context icon" />
                    <p>{item.name}</p>
                  </div>
                );
              }
            })}
      </div>
      {colorOverlay && (
        <div className="worklist-color-overlay" style={{ top: y, left: x }}>
          {colorOptions.map((item) => (
            <div
              className="color-item"
              style={{ backgroundColor: item.value }}
              onClick={() => {
                handleColorChange(item.value);
              }}
            ></div>
          ))}
        </div>
      )}

      {confirmationModal && (
        <div>
          <Overlay />
          <div className="prompt priority-prompt">
            <FadeIn duration="300ms">
              <ModalHeader
                img={TrashIcon}
                alt="trash icon"
                title="Delete Worklist"
                onClick={() => setConfirmationModal(false)}
                id="modalHeader"
              />
              <div className="delete-worklist-modal_middle">
                <p className="confirmation-text" style={{ marginLeft: "10%" }}>
                  Are you sure you want to Delete the selected Worklist?
                </p>
              </div>
              <div className="prompt-bottom">
                <SecondaryButton
                  label="Cancel"
                  onClick={() => setConfirmationModal(false)}
                />
                <PrimaryButton
                  label="Confirm"
                  onClick={() => {
                    updateWorklist({
                      worklistId: selectedWorklist,
                      actionType: "delete-worklist",
                    });
                    allTabs &&
                      allTabs.findIndex(
                        (tab) => tab.worklistId === selectedWorklist
                      ) &&
                      closeTab(
                        allTabs.findIndex(
                          (tab) => tab.worklistId === selectedWorklist
                        )
                      );
                    setConfirmationModal(false);
                  }}
                />
              </div>
            </FadeIn>
          </div>
        </div>
      )}
      {showWorklistDetailsModal && (
        <SlideboxSearchDetails
          worklistId={selectedWorklist}
          currentWorklistColor={currentWorklistColor}
          currentWorklistName={currentWorklistName}
          setShowAdvancedSearchModal={setShowWorklistDetailsModal}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  allTabs: state.SlideBox.allTabs,
  role: state.Global.loggedUserDetails.roleName,
});

const mapDispatchToProps = (dispatch) => ({
  closeTab: (payload) => dispatch(closeTab(payload)),
  getMyWorklists: () => dispatch(getMyWorklistsAction()),
  updateWorklist: (payload) => dispatch(updateWorklistAction(payload)),
  refreshWorklist: (payload) => dispatch(getWorklistDataAction(payload)),
  getWorklistSearchParameters: (payload) =>
    dispatch(getWorklistSearchParameters(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorklistContextMenu);
