import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router";
import ToolTip from "react-tooltip";
import { FeatureGate } from "react-feature-gate";
import StandardIcon from "../../../../../assets/icons/standard.svg";
import RushIcon from "../../../../../assets/icons/rush.svg";
import StatIcon from "../../../../../assets/icons/stat.svg";
import { animateScroll } from "react-scroll";
import {
  loadSlideboxDirDataList,
  setOpenListDirectory,
  updateSlideBoxSlideDetails,
  updateSlideBoxCaseDetails,
  updateSlideboxLabels,
  updateCaseStatusAction,
  getAllCaseDetailsTabsInfoAction,
  getNotesHistoryAction,
  getFileDownloadUrlAction,
  addNotesAction,
  setReturnToWorklistAction,
} from "../../../actions/slidebox-action";
import { getAllPhysiciansAction } from "../../../../adminDashboard/actions/adminDashboard-action";
import DetailClose from "../../../../../assets/icons/CloseIcon.svg";
import ImageTag from ".././ImageTag";
import ConfidentialIcon from "../../../../../assets/icons/vip-case.svg";
import "react-multi-carousel/lib/styles.css";
import { useNameFormat } from "../../../../adminDashboard/components/DashboardItems/useFormat";
import PatientDetailsTab from "./PatientDetailsTab";
import CaseDetailsTab from "./CaseDetailsTab";
import SlidesTab from "./SlidesTab";
import ActivityTab from "./ActivityTab";
import CreateCaseModal from "../../../../../pages/createCase/CreateCaseModal";

const drawerWidth = '45vw';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: 0,
    zIndex: 1.5,
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: (props) => (props.notInViewer === true ? "0px" : "76px"),
    position: (props) => props.notInViewer === true && "relative",
    zIndex: 1,
    height: (props) =>
      props.notInViewer === true ? "calc(100vh - 120px)" : "inherit",
    bottom: (props) => !(props.notInViewer === true) && "20px",
    overflow: "hidden",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  sidebarHeading: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    height: 65,
    backgroundColor: "#ECE7F8",
    width: "100%",
  },
  iconIndicators: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  folderIcon: {
    marginRight: 20,
  },
  titleDetails: {
    marginTop: 0,
    marginBottom: 0,
  },
  patientDetails: {
    color: "#2A1B53",
    fontSize: 14,
    marginBottom: 0,
    lineHeight: "25px",
    fontWeight: 500,
  },
  mrnNo: {
    color: "#2A1B53",
    fontSize: 14,
    marginBottom: 0,
    lineHeight: "25px",
    fontWeight: 500,
  },
  historicalDataLink: {
    color: "#4E12C3 !important",
    fontWeight: 500,
    marginLeft: "auto",
    marginBottom: "1rem",
    textDecoration: "underline !important",
    "&:hover": {
      color: "#4E12C3",
    },
  },
  titleDetailsRight: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  caseName: {
    color: "#2A1B53",
    fontSize: 14,
    marginBottom: 0,
    lineHeight: "25px",
    textTransform: "capitalize",
    fontWeight: 500,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // width: 200,
  },
  accessionNo: {
    color: "#2A1B53",
    fontSize: 14,
    marginBottom: 0,
    lineHeight: "25px",
    textTransform: "capitalize",
    fontWeight: 500,
  },
  img: {
    width: 21,
    height: 19,
    marginTop: 8,
  },
  title: {
    marginBottom: 0,
    cursor: "pointer",
    "&:hover": {
      fontWeight: "bold",
      textDecoration: "underline",
      color: "#1e0191",
    },
    fontSize: "18px",
  },
  caseDetailsClose: {
    width: 12,
    height: 12,
  },
  closeIcon: {
    marginBottom: "1rem",
    marginLeft: 10,
    opacity: 0.8,
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
  },

  imageInfoIconHeight: {
    maxWidth: 100,
    maxHeight: 100,
    display: "table",
    margin: [[0, "auto"]],
  },
  emptyDetailContainer: {
    margin: [[0, "auto"]],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyDetailIcon: {
    padding: 20,
    opacity: 0.6,
  },

  caseDetailsHeader: {
    marginBottom: 27,
    fontWeight: 600,
    fontSize: 16,
    color: "#2A1B53",
  },
  hideIcon: {
    opacity: 0,
  },

  listRoot: {
    display: "flex !important",
    flexDirection: "column",
    overflow: (props) => !(props.notInViewer === true) && "hidden",
    height: "100%",
    paddingTop: 0,
  },
}));

const Details = ({
  showDetails,
  setShowDetails,
  selectedDetails = {},
  updateSlideBoxDirDetails,
  history,
  loadSlideboxDirDataList,
  setOpenListDirectory,
  slideDirectory,
  selectedDirActivities,
  allSildeinfo,
  getAllTabsInfo,

  allAttachmentsInfo,

  selectedPatientInfo,
  selectedCaseInfo,
  memberDetails,
  setSelectedDir,
  updateCaseDetails,
  licenseTypeFeatures,
  updateLabels,
  tabOption,
  setTabOption,
  openNotesSection,
  setOpenNotesSection,
  openLabelsSection,
  setOpenLabelsSection,
  isOpenedFromGroups,
  isFolderSelected,
  setIsFolderSelected,
  notesHistoryData,
  setSelectedSlideData,
  notInViewer,
  selectedList,
  getFileDownloadUrl,
  getNotesHistory,
  addNotesFn,
  referredNotesData,
  getAllPhysicians,
  orderingPhysicians,
  worklistColumnData,
  showAdvancedSearchModal,
  openAllContainers,
  setReturnToWorklist,
  currentTab,
  selectedListItem,
  proceduresList,
}) => {
  const classes = useStyles({ notInViewer });
  const [editing, setEditing] = useState(false);
  const [currentCase, setCurrentCase] = useState("");
  const [openCreateCase, setOpenCreateCase] = useState(false);
  const [patientDetails, setPatientDetails] = useState({});
  const [openCaseTab, setOpenCaseTab] = useState(false);
  const [openSlideTab, setOpenSlideTab] = useState(false);

  const historicalCasesRef = useRef();

  const handleTabChange = (tab) => {
    setTabOption(tab);
  };
  const handleCloseCreateCase = () => {
    setOpenCreateCase(false);
  };

  useEffect(() => {
    setPatientDetails({
      firstName: selectedPatientInfo.first_name,
      middleName: selectedPatientInfo.middle_name,
      lastName: selectedPatientInfo.last_name,
      mrnNumber: selectedPatientInfo.mrn_number,
      dob: selectedPatientInfo.dob,
      age: 1,
      gender: selectedPatientInfo.gender,
    });
  }, [selectedPatientInfo]);

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: "details-section",
      duration: 100,
    });
  };

  const priorityOptions = [
    {
      value: "standard",
      img: StandardIcon,
    },
    {
      value: "rush",
      img: RushIcon,
    },
    {
      value: "stat",
      img: StatIcon,
    },
  ];

  const setShowDetailsPopup = () => {
    setEditing(false);
    setShowDetails(false);
  };

  const dateSort = (a, b) => {
    return new Date(b.procedure_date) - new Date(a.procedure_date);
  };

  const handleOpenHistoricalCase = (
    caseId,
    setCurrentCase,
    setSelectedDir,
    selectedDetails
  ) => {
    setSelectedDir({ dirId: caseId, parentDir: 1, dirType: "case" });
    setCurrentCase(selectedDetails.dirDataId);
    setTabOption("slides");
    getAllTabsInfo({
      dirId: caseId,
      dirType: "case",
    });
  };

  const toggleExpansible = (e) => {
    let el = e.target ? e.target : e;
    el.classList.toggle("active");
    let content = el.nextElementSibling;
    if (!content) {
      return false;
    }
    if (!el.classList.contains("active")) {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={showDetails && !showAdvancedSearchModal}
        classes={{ paper: classes.drawerPaper }}
      >
        <List className={classes.listRoot}>
          <div className={classes.sidebarHeading}>
            <div className={classes.iconIndicators}>
              <img
                src={
                  selectedDetails.priority &&
                  priorityOptions.filter(
                    (item) => item.value === selectedDetails.priority
                  )[0].img
                }
                className={classes.casePriority}
                style={{ opacity: selectedDetails.priority ? 1 : 0 }}
                alt="priority"
              />
              <FeatureGate feature="patient_level_information">
                <div className={classes.confidentialIcon}>
                  <ImageTag
                    src={ConfidentialIcon}
                    alt="confidential patient"
                    className={classes.hideIcon}
                  />
                </div>
              </FeatureGate>
            </div>
            <FeatureGate feature="patient_level_information">
              <div className={classes.titleDetails}>
                <p className={classes.patientDetails}>{`${useNameFormat(
                  (selectedPatientInfo.first_name || " ") +
                    " " +
                    (selectedPatientInfo.middle_name || " "),
                  selectedPatientInfo.last_name || " "
                ) || "-"} / ${
                  selectedPatientInfo.gender === "Male"
                    ? "M"
                    : selectedPatientInfo.gender === "Female"
                    ? "F"
                    : "-"
                } / ${moment().diff(selectedPatientInfo.dob, "years")}`}</p>
                <p
                  className={classes.mrnNo}
                >{`MRN : ${selectedPatientInfo.mrn_number || "-"}`}</p>
              </div>
            </FeatureGate>

            {tabOption === "patient" ? (
              <a
                className={classes.historicalDataLink}
                onClick={() => {
                  if (currentCase) {
                    setSelectedDir({
                      dirId: currentCase,
                      parentDir: 1,
                      dirType: "case",
                    });
                  } else {
                    !historicalCasesRef.current.classList.contains("active") &&
                      historicalCasesRef.current.click();
                  }
                  scrollToBottom();
                }}
              >
                {currentCase
                  ? "New cases available"
                  : "Historical data available"}
              </a>
            ) : (
              <div className={classes.titleDetailsRight}>
                <p className={classes.caseName}>
                {selectedDetails.procedureId ?
                      proceduresList.find((procedure) => +procedure.procedureId === +selectedDetails.procedureId).procedureName
                      : '—'}
                </p>
                <p className={classes.accessionNo}>
                  Acc.No: {selectedDetails.accessionNumber || "—"}
                </p>
              </div>
            )}
            <div className={classes.closeIcon}>
              <ImageTag
                src={DetailClose}
                alt=""
                className={classes.caseDetailsClose}
                onClick={() => {
                  setShowDetailsPopup();
                }}
              />
            </div>
          </div>
          <div className="tabOption">
            <FeatureGate feature="patient_level_information">
              <div
                className={tabOption === "patient" ? "active tab" : "tab"}
                onClick={() => handleTabChange("patient")}
              >
                <span>PATIENT</span>
              </div>
            </FeatureGate>

            <div
              className={tabOption === "case" ? "active tab" : "tab"}
              onClick={() => handleTabChange("case")}
            >
              <span>CASE</span>
            </div>
            <div
              className={tabOption === "slides" ? "active tab" : "tab"}
              onClick={() => handleTabChange("slides")}
            >
              <span>SLIDES</span>
            </div>
            <div
              className={tabOption === "activity" ? "active tab" : "tab"}
              onClick={() => handleTabChange("activity")}
            >
              <span>ACTIVITY</span>
            </div>
          </div>

          {tabOption === "patient" && (
            <PatientDetailsTab
              selectedDetails={selectedDetails}
              setSelectedDir={setSelectedDir}
              setCurrentCase={setCurrentCase}
              selectedPatientInfo={selectedPatientInfo}
              toggleExpansible={toggleExpansible}
              historicalCasesRef={historicalCasesRef}
              dateSort={dateSort}
              updateSlideBoxDirDetails={updateSlideBoxDirDetails}
              handleOpenHistoricalCase={handleOpenHistoricalCase}
              selectedDirId={selectedList}
            />
          )}
          {tabOption === "case" && (
            <CaseDetailsTab
              proceduresList={proceduresList}
              worklistColumnData={worklistColumnData}
              selectedCaseInfo={selectedCaseInfo}
              editing={editing}
              setEditing={setEditing}
              toggleExpansible={toggleExpansible}
              setCurrentCase={setCurrentCase}
              selectedDetails={selectedDetails}
              setSelectedDir={setSelectedDir}
              allAttachmentsInfo={allAttachmentsInfo}
              selectedPatientInfo={selectedPatientInfo}
              dateSort={dateSort}
              handleOpenHistoricalCase={handleOpenHistoricalCase}
              memberDetails={memberDetails}
              updateCaseDetails={updateCaseDetails}
              licenseTypeFeatures={licenseTypeFeatures}
              updateLabels={updateLabels}
              allPatientDetails={patientDetails}
              openCreateCase={openCreateCase}
              setOpenCreateCase={setOpenCreateCase}
              setOpenFromCaseTab={setOpenCaseTab}
              setOpenFromSlideTab={setOpenSlideTab}
              openNotesSection={openNotesSection}
              setOpenNotesSection={setOpenNotesSection}
              openLabelsSection={openLabelsSection}
              setOpenLabelsSection={setOpenLabelsSection}
              getFileDownloadUrl={getFileDownloadUrl}
              selectedDirId={selectedList}
              notesHistoryData={notesHistoryData}
              getNotesHistory={getNotesHistory}
              addNotesFn={addNotesFn}
              referredNotesData={referredNotesData}
              getAllPhysicians={getAllPhysicians}
              orderingPhysicians={orderingPhysicians}
            />
          )}
          {tabOption === "slides" && (
            <SlidesTab
              selectedListItem={selectedListItem}
              currentTab={currentTab}
              setReturnToWorklist={setReturnToWorklist}
              openAllContainers={openAllContainers}
              history={history}
              selectedDetails={selectedDetails}
              setSelectedDir={setSelectedDir}
              setCurrentCase={setCurrentCase}
              allSildeinfo={allSildeinfo}
              setOpenCreateCase={setOpenCreateCase}
              setOpenFromSlideTab={setOpenSlideTab}
              setOpenFromCaseTab={setOpenCaseTab}
              openCreateCase={openCreateCase}
              selectedPatientInfo={selectedPatientInfo}
              toggleExpansible={toggleExpansible}
              dateSort={dateSort}
              handleOpenHistoricalCase={handleOpenHistoricalCase}
              isOpenedFromGroups={isOpenedFromGroups}
              isFolderSelected={isFolderSelected}
              setIsFolderSelected={setIsFolderSelected}
              setSelectedSlideData={setSelectedSlideData}
              licenseTypeFeatures={licenseTypeFeatures}
            />
          )}

          {tabOption === "activity" && (
            <ActivityTab activityData={selectedDirActivities} />
          )}
        </List>
        {/* )} */}

        <ToolTip id="slide-name" place="bottom" type="dark" effect="solid" />
      </Drawer>

      {openCreateCase && (
        <CreateCaseModal
          onClose={handleCloseCreateCase}
          licenseTypeFeatures={licenseTypeFeatures}
          openSlideTab={openSlideTab}
          allPatientDetails={patientDetails}
          openCaseTab={openCaseTab}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  slideDirectory: state.SlideBox.folders,
  selectedDirActivities: state.SlideBox.selectedDirActivities,
  userDetails: state.Global.loggedUserDetails,
  selectedCaseAssignUsers: state.SlideBox.selectedCaseAssignUsers,
  ...state.SlideBox.selectedFolderData,
  ...state.SlideBox.selectedListData,
  allSildeinfo: state.SlideBox.allSlides,
  allAttachmentsInfo: state.SlideBox.allAttachments,
  memberDetails: state.SlideBox.userList,
  slideDirDataList: state.SlideBox.dirDataList,
  selectedPatientInfo: state.SlideBox.selectedPatientInfo,
  selectedCaseInfo: state.SlideBox.selectedCaseInfo,
  licenseTypeFeatures: state.Global.loggedUserDetails.licenseTypeAccessData,
  notesHistoryData: state.SlideBox.notesHistoryData,
  referredNotesData: state.SlideBox.referredNotesData,
  orderingPhysicians: state.Dashboard.orderingPhysicians,
  currentTab: state.SlideBox.currentTab,
  proceduresList: state.Dashboard.proceduresList,
});

const mapDispatchToProps = (dispatch) => ({
  updateSlideBoxDirDetails: (payload) =>
    dispatch(updateSlideBoxSlideDetails(payload)),
  updateCaseDetails: (payload) => dispatch(updateSlideBoxCaseDetails(payload)),
  updateLabels: (payload) => dispatch(updateSlideboxLabels(payload)),
  loadSlideboxDirDataList: (payload) =>
    dispatch(loadSlideboxDirDataList(payload)),
  setOpenListDirectory: (payload) => dispatch(setOpenListDirectory(payload)),
  updateCaseStatus: (payload) => dispatch(updateCaseStatusAction(payload)),
  getAllTabsInfo: (payload) =>
    dispatch(getAllCaseDetailsTabsInfoAction(payload)),
  getNotesHistory: (payload) => dispatch(getNotesHistoryAction(payload)),
  getFileDownloadUrl: (payload, callbackFn) =>
    dispatch(getFileDownloadUrlAction(payload, callbackFn)),
  addNotesFn: (payload) => dispatch(addNotesAction(payload)),
  getAllPhysicians: () => dispatch(getAllPhysiciansAction()),
  setReturnToWorklist: (payload) =>
    dispatch(setReturnToWorklistAction(payload)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Details)
);
