import React, { useState } from 'react';
import { FadeIn } from 'animate-components';
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  UserList,
  MemberInvitation,
} from '../src/components';
import { Notify } from '../src/components';
import { connect } from 'react-redux';
import {
  setNewDirCollaboratorsAction,
  removeDirCollaboratorsAction,
  setNewAssignUserToCaseAction,
  removeAssignUserToCaseAction,
} from '../src/pages/slidebox/actions/slidebox-action';
import DefaultAvatar from '../src/assets/icons/default-avatar.svg';
import AssignIcon from '../src/assets/icons/assign-page.svg';
import { useNameFormat } from '../src/pages/adminDashboard/components/DashboardItems/useFormat'

const AssignUserPopup = ({
  onClose,
  selectedListType,
  selectedList,
  selectedCaseAssignUsers,
  setNewAssignUsers,
  removeAssignUsers,
  dxAccountId,
  dxAccountDetails,
}) => {
  const [assignUsers, setAssignUsers] = useState([]);
  const [assignUsersCurrent, setAssignUsersCurrent] = useState([]);
  const [assignWarning, setAssignWarning] = useState(null);

  const handleSaveClick = (isConfirm = false) => {
    if (
      selectedCaseAssignUsers &&
      selectedCaseAssignUsers.length > 0 &&
      !isConfirm
    ) {
      setAssignWarning(
        `The case is already assigned, remove existing assignee and add new.`
      );
    } else {
      const data = {
        dirId: selectedList,
        dirType: selectedListType,
        assignUsersId: assignUsers.map((userData) => userData.userID),
        assignUsers: assignUsers,
      };
      setNewAssignUsers(data, {
        onSuccess: afterCaseAssignSuccess,
        onError: afterCaseAssignFail,
      });
    }
  };

  const afterCaseAssignSuccess = () => {
    setAssignUsersCurrent([]);
    Notify({ value: `Case assigned successfully` });
    onClose(false);
  };

  const afterCaseAssignFail = () => {
    Notify({ value: `${selectedListType} failed to assign` });
  };

  const assignUsersHandler = (userData) => {
    setAssignUsersCurrent([...assignUsers, userData]);
    setAssignUsers([...assignUsers, userData]);
  };

  const removeSlideboxAssignUsers = (userId) => {
    const data = {
      dirId: selectedList,
      dirType: selectedListType,
      assignUsers: userId,
      dxAccountId: dxAccountId,
    };
    removeAssignUsers(data, {
      onSuccess: afterRemoveAssignUserSuccess,
      onError: afterRemoveAssignUserFail,
    });
  };

  const afterRemoveAssignUserSuccess = () => {
    Notify({ value: `Removed assigned user successfully` });
  };

  const afterRemoveAssignUserFail = () => {
    Notify({ value: `Failed to remove assigned user` });
  };

  const removeSelectedUsers = (userId) => {
    setAssignUsers([]);
    setAssignUsersCurrent([]);
    const updatedInvities = assignUsers.filter((data) => data.id !== userId);
    setAssignUsers(updatedInvities);
  };

  const AssignUsers = ({
    name,
    img,
    role,
    onRemove,
    enableRemove,
    firstName,
    lastName,
    userEmail,
  }) => {
    return (
      <div className="collaborator">
        <UserList
          img={img}
          fullName={name}
          role={role}
          firstName={firstName}
          lastName={lastName}
          userEmail={userEmail}
        />

        <button
          type="button"
          onClick={enableRemove ? onRemove : null}
          disabled={enableRemove ? '' : 'disabled'}
          className="btn btn-light remove-collaborator"
        >
          Remove
          </button>


      </div>
    );
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            title={`Assign case`}
            onClick={() => onClose(false)}
            img={AssignIcon}
            alt={'assign case'}
          />
          <div className="modal_middle shared_popup">
            <React.Fragment>
              <div className="collaborator-list">
                {selectedCaseAssignUsers &&
                  selectedCaseAssignUsers.length > 0 &&
                  selectedCaseAssignUsers.map((c, i) => (
                    <AssignUsers
                      key={i}
                      img={c.userImageSrc ? c.userImageSrc : DefaultAvatar}
                      name={`${useNameFormat(c.firstName, c.lastName)}`}
                      role={c.roleName}
                      enableRemove={true}
                      onRemove={() => removeSlideboxAssignUsers(c.userID)}
                      firstName={c.firstName}
                      lastName={c.lastName}
                      userEmail={c.userEmail}
                    />
                  ))}
              </div>
              <React.Fragment>
                <MemberInvitation
                  onMemberSelect={assignUsersHandler}
                  isCollaborator={false}
                  filePath={selectedList}
                  assignUsersCurrent={assignUsersCurrent}
                  alreadyInUsers={selectedCaseAssignUsers}
                />
                <div className="selected-collabortor-list">
                  {assignUsersCurrent.map((member, i) => (
                    <div className="collaborator" key={i}>
                      <UserList
                        img={
                          member.userImageSrc
                            ? member.userImageSrc
                            : DefaultAvatar
                        }
                        fullName={`${useNameFormat(member.firstName, member.lastName)}`}
                        firstName={member.firstName}
                        lastName={member.lastName}
                        userEmail={member.userEmail}
                        role={member.roleName}
                      />
                      <button
                        type="button"
                        onClick={() => removeSelectedUsers(member.userId)}
                        className="btn btn-light remove-collaborator "
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </React.Fragment>
              {assignWarning && (
                <span className="assign-confirm-message">{assignWarning}</span>
              )}
            </React.Fragment>
            <div className="prompt-bottom collaborate-popup">
              {assignUsers.length > 0 && (
                <PrimaryButton
                  label={assignWarning ? 'Confirm' : 'Assign'}
                  onClick={() => handleSaveClick(assignWarning ? true : false)}
                  extraClass="prompt-done assign-user-btn"
                />
              )}
            </div>
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  selectedCaseAssignUsers: state.SlideBox.selectedCaseAssignUsers,
  dxAccountId: state.Global.loggedUserDetails.dxAccountId,
  dxAccountDetails: state.Global.dxAccountDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setNewDirCollaborators: (payload, action) =>
    dispatch(setNewDirCollaboratorsAction(payload, action)),
  removeDirCollaborators: (payload, action) =>
    dispatch(removeDirCollaboratorsAction(payload, action)),
  setNewAssignUsers: (payload, action) =>
    dispatch(setNewAssignUserToCaseAction(payload, action)),
  removeAssignUsers: (payload, action) =>
    dispatch(removeAssignUserToCaseAction(payload, action)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AssignUserPopup);
