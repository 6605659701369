import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import SiteAdminThreeVerticalDots from "./SiteAdminThreeVerticalDots";
import { bytesToSize } from "../../../utils/utils";
import EmptyMessage from "../../slidebox/components/SlideTable/EmptyMessage";
import Loader from "../../slidebox/components/SlideTable/Loader";
import { useDateFormat } from "../../adminDashboard/components/DashboardItems/useFormat";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: "100%",
  },
  arrowIcon: {
    color: "#6a6e72",
    fontSize: 18,
    marginLeft: 5,
  },
});

const SiteAdministratorDashboardTable = ({
  dxAccountDetails,
  showLoader,
  resendInvitation,
}) => {
  const classes = useStyles();
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  return showLoader ? (
    <Loader />
  ) : dxAccountDetails.length > 0 ? (
    <TableContainer className="table-scroll" elevation={0} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className="table-padding">
            <TableCell align="center">Account ID</TableCell>
            <TableCell align="center">Admin Email</TableCell>
            {/* <TableCell align="center">License Type</TableCell> */}
            <TableCell align="center">License Number</TableCell>
            <TableCell align="center">License start date</TableCell>
            <TableCell align="center">License end date</TableCell>
            <TableCell align="center">User Limit</TableCell>
            <TableCell align="center">Number of active users</TableCell>
            <TableCell align="center">Storage Used</TableCell>
            <TableCell align="center">Storage Limit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dxAccountDetails &&
            dxAccountDetails.length > 0 &&
            dxAccountDetails.map((details, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{details.dx_account_id}</TableCell>
                  <TableCell align="center">{details.admin_email_id}</TableCell>
                  {/* <TableCell align="center">{""}</TableCell> */}
                  <TableCell align="center" className="role-capitalize">
                    {details.license_number}
                  </TableCell>
                  <TableCell align="center">
                    {useDateFormat({ date: details.license_start_date })}
                  </TableCell>
                  <TableCell align="center">
                    {useDateFormat({ date: details.license_end_date })}
                  </TableCell>
                  <TableCell align="center" className="role-capitalize">
                    {details.userLimit}
                  </TableCell>
                  <TableCell align="center" className="role-capitalize">
                    {details.activeUsers[0].numberOfActiveUsers}
                  </TableCell>
                  <TableCell align="center" className="role-capitalize">
                    {bytesToSize(details.storage_used)}
                  </TableCell>
                  <TableCell align="center" className="role-capitalize">
                    {bytesToSize(details.storage_limit)}
                  </TableCell>
                  <SiteAdminThreeVerticalDots
                    selectedRowIndex={selectedRowIndex}
                    anchorEl={anchorEl}
                    setSelectedRowIndex={setSelectedRowIndex}
                    index={index}
                    resendInvitation={resendInvitation}
                    details={details}
                  />
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <EmptyMessage mainMessage="No data found" />
  );
};

export default SiteAdministratorDashboardTable;
