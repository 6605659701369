import React, { Fragment, Component } from "react";
import { NavLink } from "react-router-dom";
import { string } from "prop-types";

class AppLink extends Component {
  render() {
    let { label, url, children, iconClass, ...props } = this.props;

    return (
      <Fragment>
        <NavLink to={url} exact {...props}>
          <span className={iconClass}></span>
          {children ? children : label}
        </NavLink>
      </Fragment>
    );
  }
}

AppLink.defaultProps = {
  label: "",
  url: "",
};

AppLink.propTypes = {
  label: string,
  url: string.isRequired,
};

export default AppLink;
