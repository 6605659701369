import {
  loggedUserDetailsApi,
  useLogoutApi,
  dxAccountDetailsApi,
} from "../services/global-service";

const getUserDetails = (data) => {
  return {
    type: "GET_USER_DETAILS",
    payload: data,
  };
};

const showLoader = (show) => {
  return {
    type: "SHOW_LOADER",
    showLoader: show,
  };
};

const getDxAccountDetails = (data) => {
  return {
    type: "GET_DX_ACCOUNT_DETAILS",
    payload: data,
  };
};

export const resetPicUpload = (payload) => {
  return {
    type: "RESET_PROFILE_PIC",
    payload,
  };
};

export const updateUserPropileAction = (data) => {
  return {
    type: "SET_USER_PROFILE",
    payload: data,
  };
};

export const updateInstitutionLogoAction = (data) => {
  return {
    type: "SET_INSTITUTION_LOGO",
    payload: data,
  };
};

export const uploadProfilePicPending = (data) => {
  return {
    type: "SET_PROFILE_UPLOAD_PENDING",
    payload: data,
  };
};

export const userLogoutAction = () => {
  return async (dispatch) => {
    try {
      const response = await useLogoutApi();
      if (response.status === 200) {
        window.location.href = "/logout";
        setTimeOut(() => {
          sessionStorage.clear();
        }, 100);
        dispatch({ type: "USER_LOGOUT" });
      }
    } catch (error) {}
  };
};

export const getUserDetailsAction = () => {
  return async (dispatch) => {
    try {
      dispatch(showLoader(true));

      const response = await loggedUserDetailsApi();
      if (response.status === 200) {
        if (response.data.body[0].status === "deactivated") {
          dispatch(userLogoutAction());
        }
        dispatch(
          getUserDetails({
            userDetails: response.data.body[0],
            allRolesPermissions: response.data.allRolesPermission,
            isSystemAdmin: response.data.isSystemAdmin,
          })
        );
        dispatch(showLoader(false));
      }
    } catch (error) {
      dispatch(getUserDetails({}));
      dispatch(showLoader(false));
    }
  };
};

export const getAccountDetailsAction = () => {
  return async (dispatch) => {
    try {
      const response = await dxAccountDetailsApi();
      if (response.status === 200) {
        dispatch(getDxAccountDetails(response.data.body[0]));
      }
    } catch (error) {
      dispatch(getDxAccountDetails({}));
    }
  };
};

export const toggleSidebarAction = (toggle) => {
  return {
    type: "TOGGLE_SIDEBAR",
    payload: toggle,
  };
};
