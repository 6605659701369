import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Input } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { PrimaryButton, Notify } from "../../../../components";
import Loader from "../../../slidebox/components/SlideTable/Loader";
import {
  getAllWorkgroupAction,
  addWorkgroupAction,
  editWorkgroupAction,
  deleteWorkgroupAction,
  editWorkgroupMembersAction,
} from "../../actions/adminDashboard-action";
import EditIcon from "../../../../assets/icons/EditRoleIcon.svg";
import { SingleSelect } from "../../../../components";
import cancelIcon from "../../../../assets/icons/remove-user.png";
import ToolTip from "react-tooltip";

const useStyles = makeStyles({
  container: {
    height: "55vh !important",
  },
});

const WorkgroupTable = ({
  showLoader,
  isPopUpOpen,
  userList,
  loggedUserEmail,
  loggedUserRoleId,
  blink,
  setBlink,
  workgroupData,
  getWorkgroupData,
  addWorkgroup,
  editWorkgroupNameFn,
  deleteWorkgroup,
  editWorkgroupMembersFn,
  ...rest
}) => {
  const classes = useStyles();

  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [showCloseIcon, setShowCloseIcon] = useState([]);
  const [workgroupName, setWorkgroupName] = useState("");
  const [workgroupMembers, setWorkgroupMembers] = useState([]);
  const [workgroupEdit, setWorkgroupEdit] = useState([]);
  const [addNewWorkgroup, setAddWorkgroup] = useState(false);
  const [editWorkgroupName, setEditWorkgroupName] = useState([]);
  const [userLength, setUserLength] = useState(3);
  const [editWorkgroupMembers, setWorkgroupEditMembers] = useState([]);
  const [editMemberList, setEditMemberList] = useState([]);

  const memberList = userList.map((user) => {
    return {
      value: user.userID,
      label: user.firstName + " " + user.lastName,
    };
  });

  const handleAddNewWorkgroup = () => {
    if (addNewWorkgroup === false) {
      console.log("edit");
      setAddWorkgroup(true);
      setWorkgroupName("");
    } else {
      if (workgroupName === "") {
        Notify({ value: "Workgroup name is required" });
        return;
      } else {
        addWorkgroup({
          workgroupName,
          addWorkgroupName: true,
          workgroupUsers: workgroupMembers.map((member) => member.value),
        });
        setAddWorkgroup(false);
      }
    }
  };

  const handleSubmitEditWorkgroup = (event, workgroupId, workgroupName) => {
    if (event.key === "Enter") {
      if (Object.values(editWorkgroupName)[0] === "") {
        Notify({ value: "Workgroup name is required" });
        return;
      } else {
        editWorkgroupNameFn({
          workgroupId: workgroupId,
          workgroupName: Object.values(editWorkgroupName)[0],
        });
        setAddWorkgroup(false);
        setWorkgroupEdit([]);
        setShowCloseIcon([]);
        setEditWorkgroupName([]);
      }
    }
  };

  const handleWorkgroupEdit = (index) => {
    setWorkgroupEdit({ [index]: true });
    setShowCloseIcon({ [index]: true });
  };

  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption);
    setWorkgroupMembers(selectedOption);
  };

  const handleAddOrRemoveUsers = (selectedOption) => {
    setEditMemberList(selectedOption);
  };

  const handleDeleteWorkgroup = (workgroupData) => {
    deleteWorkgroup({
      workgroupId: workgroupData.workgroupId,
      workgroupName: workgroupData.workgroupName,
    });
  };

  const handleEditWorkgroupMembers = (index) => {
    setWorkgroupEditMembers({ [index]: true });
  };

  const handleSubmitEditWorkgroupMembers = (event, workgroupData, index) => {
    if (event.key === "Enter") {
      if (editMemberList === null) {
        editWorkgroupMembersFn({
          workgroupId: workgroupData.workgroupId,
          workgroupUsers: [],
        });
      } else {
        editWorkgroupMembersFn({
          workgroupId: workgroupData.workgroupId,
          workgroupUsers: editMemberList.map((member) => member.value),
        });
      }
      setWorkgroupEditMembers({ [index]: false });
    }
  };

  useEffect(() => {
    !isPopUpOpen && setSelectedRowIndex("");
  }, [isPopUpOpen]);

  useEffect(() => {
    getWorkgroupData();
  }, []);

  return showLoader ? (
    <Loader />
  ) : (
    <div className="workgroup-container">
      <div className="buttons-container">
        <PrimaryButton
          label="Add Workgroup"
          onClick={() => handleAddNewWorkgroup()}
          extraClass="add-workgroup"
        />
      </div>
      <div className="workgroup-list-container">
        <TableContainer
          className={classes.container + "table-scroll"}
          elevation={0}
          component={Paper}
        >
          <Table className="workgroup-table" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" className="table-heading">
                  <span className="workgroup-list-name">Workgroup Name</span>
                </TableCell>
                <TableCell align="left">Members</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={blink ? "blink-user-list-table" : ""}>
              {workgroupData &&
                workgroupData.map((workgroup, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        align="left"
                        className="workgroup-capitalize workgroup-name"
                        style={{ width: "25%" }}
                      >
                        {!workgroupEdit[index] && workgroup.workgroupName}

                        {workgroupEdit[index] && showCloseIcon[index] && (
                          <Input
                            className="workgroup-name-value"
                            defaultValue={workgroup.workgroupName}
                            value={editWorkgroupName[index]}
                            onChange={(e) =>
                              setEditWorkgroupName({ [index]: e.target.value })
                            }
                            onKeyDown={(e) =>
                              handleSubmitEditWorkgroup(
                                e,
                                workgroup.workgroupId,
                                workgroup.workgroupName
                              )
                            }
                          />
                        )}

                        {showCloseIcon[index] && (
                          <span
                            onClick={() => {
                              setShowCloseIcon({ [index]: false });
                              setWorkgroupEdit({ [index]: false });
                            }}
                          >
                            <img
                              width="12px"
                              height="12px"
                              src={cancelIcon}
                              className="close-icon"
                              alt="Cancel"
                            />
                          </span>
                        )}

                        {(!showCloseIcon[index] ||
                          showCloseIcon.length === 0) &&
                          workgroup.workgroupName !== "Default Workgroup" && (
                            <img
                              width="12px"
                              height="12px"
                              src={EditIcon}
                              className="edit-workgroup-icon"
                              onClick={() => handleWorkgroupEdit(index)}
                            />
                          )}
                      </TableCell>
                      <TableCell align="left" style={{ width: "60%" }}>
                        <div>
                          {!editWorkgroupMembers[index] &&
                            workgroup.userList
                              .slice(0, userLength)
                              .map((user, index) => (
                                <span className="member-name">
                                  {user.firstName + " " + user.lastName}
                                </span>
                              ))}
                          <span
                            className="user-count"
                            data-for="workgroup-tooltip"
                            data-tip={workgroup.userList
                              .slice(userLength, workgroup.userList.length)
                              .map(
                                (user) => user.firstName + " " + user.lastName
                              )}
                          >
                            {!editWorkgroupMembers[index] &&
                            workgroup.userList.length > userLength
                              ? workgroup.userList.length -
                                userLength +
                                " " +
                                "more"
                              : ""}
                          </span>
                          {editWorkgroupMembers[index] && (
                            <SingleSelect
                              options={[]}
                              handleChange={handleAddOrRemoveUsers}
                              placeholder="Enter User Name"
                              customStyles={true}
                              style={{
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              type={"user-list"}
                              members={memberList}
                              onKeyDown={(event) => {
                                handleSubmitEditWorkgroupMembers(
                                  event,
                                  workgroup,
                                  index
                                );
                              }}
                              defaultValue={workgroup.userList.map(
                                (member) => ({
                                  value: member.userId,
                                  label:
                                    member.firstName + " " + member.lastName,
                                })
                              )}
                            />
                          )}
                          {!editWorkgroupMembers[index] &&
                            workgroup.workgroupName !== "Default Workgroup" && (
                              <img
                                width="12px"
                                height="12px"
                                src={EditIcon}
                                className="edit-workgroup-icon"
                                onClick={() =>
                                  handleEditWorkgroupMembers(index)
                                }
                              />
                            )}
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="workgroup-action"
                        onClick={() => handleDeleteWorkgroup(workgroup)}
                      >
                        {workgroup.workgroupName !== "Default Workgroup"
                          ? "Delete"
                          : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {addNewWorkgroup && (
                <TableRow>
                  <TableCell
                    align="left"
                    className="workgroup-capitalize workgroup-name"
                    style={{ width: "25%" }}
                  >
                    <Input
                      className="add-new-workgroup-input"
                      value={workgroupName}
                      onChange={(e) => setWorkgroupName(e.target.value)}
                      placeholder="Enter Workgroup Name"
                      autoFocus
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    className="workgroup-capitalize workgroup-name"
                  >
                    <SingleSelect
                      options={[]}
                      handleChange={handleSelectChange}
                      placeholder="Enter User Name"
                      customStyles={true}
                      style={{ width: "100%", backgroundColor: "white" }}
                      type={"user-list"}
                      members={memberList}
                      /* onKeyDown={handleSubmitAddWorkgroup}
                       */ classname="workgroup-users"
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <ToolTip
            id="workgroup-tooltip"
            place="top"
            type="dark"
            effect="solid"
          />
        </TableContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
  workgroupData: state.Dashboard.workgroupData,
});

const mapDispatchToProps = (dispatch) => ({
  getWorkgroupData: () => dispatch(getAllWorkgroupAction()),
  addWorkgroup: (payload) => dispatch(addWorkgroupAction(payload)),
  editWorkgroupNameFn: (payload) => dispatch(editWorkgroupAction(payload)),
  editWorkgroupMembersFn: (payload) =>
    dispatch(editWorkgroupMembersAction(payload)),
  deleteWorkgroup: (payload) => dispatch(deleteWorkgroupAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkgroupTable);
