import React, { Component } from "react";
import "./ContextMenu.scss";
import ViewDetailsIcon from "../../../src/assets/icons/case-details.svg";
import SlideTrayIcon from "../../../src/assets/icons/slide-tray.svg";
import AddAttachmentsIcon from "../../../src/assets/icons/add-attachment-icon.svg";
import AddSlidesIcon from "../../../src/assets/icons/add-slides-icon.svg";
import ViewSlidesIcon from "../../../src/assets/icons/view-slides-icon.svg";
import AddLabelsIcon from "../../../src/assets/icons/labels-icon.svg";
import RefreshIcon from "../../../src/assets/icons/refresh-icon.svg";
import StatusIcon from "../../../src/assets/icons/case-status-icon.svg";
import { connect } from "react-redux";
import { setSlideBoxRightClickSelectedAction, setReturnToWorklistAction } from "../../pages/slidebox/actions/slidebox-action";
import { getUserDetailsAction } from "../../actions/global-action";
import { Notify } from "../../components";

export class ContextMenu extends Component {
  state = {
    visible: false,
    rightClickElement: null,
  };

  componentDidMount() {
    document.addEventListener("contextmenu", this._handleContextMenu);
    document.addEventListener("click", this._handleClick);
    document.addEventListener("scroll", this._handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("contextmenu", this._handleContextMenu);
    document.removeEventListener("click", this._handleClick);
    document.removeEventListener("scroll", this._handleScroll);
  }

  _handleContextMenu = async (event) => {
    if (this.props.isModal || this.props.importSlide) {
      event.preventDefault();
      this.setState({ visible: false });
      return;
    }
    event.preventDefault();
    if (
      event.path[0].className.includes("selected-table-cell-color") ||
      event.path[0].id.includes("last-breadcrum-item") ||
      event.path[0].className.includes("grid-view-card")
    ) {
      if (event.path[0].className.includes("breadcrumb-child")) {
        let element = document.getElementById("last-breadcrum-item");
        element.classList.add("selected");
        await this.setState({ rightClickElement: "non-list" });
        this.props.setSlideBoxRightClickSelected("non-list");
      } else {
        await this.setState({ rightClickElement: "list" });
        this.props.setSlideBoxRightClickSelected("list");
      }
      this.setState({ visible: true });
      const clickX = event.clientX;
      const clickY = event.clientY;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const rootW = this.root.offsetWidth;
      const rootH = this.root.offsetHeight;

      const right = screenW - clickX > rootW;
      const left = !right;
      const top = screenH - clickY > rootH;
      const bottom = !top;

      if (right) {
        this.root.style.left = `${clickX + 5}px`;
      }

      if (left) {
        this.root.style.left = `${clickX - rootW - 5}px`;
      }

      if (top) {
        this.root.style.top = `${clickY + 5}px`;
      }

      if (bottom) {
        this.root.style.top = `${clickY - rootH - 5}px`;
      }
    }
  };

  _handleClick = (event) => {
    let element = document.getElementById("last-breadcrum-item");
    element && element.classList.remove("selected");
    const { visible } = this.state;
    const wasOutside = !(event.target.contains === this.root);
    if (wasOutside && visible) this.setState({ visible: false });
  };

  _handleScroll = () => {
    const { visible } = this.state;
    if (visible) this.setState({ visible: false });
  };

  onInfoLoad = () => {
    this.props.setShowDetails();
    this.props.setTabOption("case");
    this.props.setOpenAllContainers(false);
    this.props.setSelectedDetails(this.props.selectedListItem);
  };

  onSlideTabLoad = () => {
    this.props.setShowDetails();
    this.props.setTabOption("slides");
    this.props.setOpenAllContainers(true);
    this.props.setSelectedDetails(this.props.selectedListItem);
  };

  onClickAddNotes = () => {
    this.props.setShowDetails();
    this.props.setSelectedDetails(this.props.selectedListItem);
    this.props.setTabOption("case");
    this.props.setOpenNotesSection(true);
    this.props.setOpenLabelsSection(false);
  };

  onClickAddLabels = () => {
    this.props.setShowDetails();
    this.props.setSelectedDetails(this.props.selectedListItem);
    this.props.setTabOption("case");
    this.props.setOpenLabelsSection(true);
    this.props.setOpenNotesSection(false);
  };

  onAddSlideToGroups = () => {
    this.props.showGroupsSelectPopup();
  };

  onAddSlides = () => {
    this.props.openCreateCaseModal();
    this.props.setOpenSlideTab(true);
  };

  onAddAttachments = () => {
    this.props.openCreateCaseModal();
    this.props.setOpenAttachmentTab(true);
    this.props.setOpenSlideTab(false);
  };

  onClickRefresh = () => {
    this.props.getAllTabsInfo({ dirId: this.props.selectedDirId });
    Notify({ value: "Refreshed successfully" });
  };

  onChangePriority = () => {
    this.props.setShowPriorityModal(true);
  };

  onChangeCaseStatus = () => {
    this.props.setShowCaseStatusModal(true);
  };

  handleOpenViewer = () => {
    if(!this.props.allSlides.length) {
      Notify({ value: "No slides available" });
      return;
    }
    this.props.setReturnToWorklist({ 
      tab: this.props.currentTab, 
      selectedItem: this.props.selectedListItem, 
      return: true 
    });
    const slide = this.props.allSlides[0];
    this.props.history.push({
      pathname: `/viewer`,
      search: `?image=${slide.dzi_key_folder}&thumb=${slide.thumbDataToken}`,
    });
  };

  render() {
    if (this.props.isModal) {
      return null;
    }
    let selectedDir = null;
    if (this.state.rightClickElement === "list") {
      selectedDir = {
        type: this.props.selectedListData.selectedListType,
        keyFolder:
          this.props.selectedListData.selectedListType === "slide"
            ? this.props.selectedListSlideData.keyFolder
            : null,
      };
    } else {
      selectedDir = {
        type: this.props.selectedFolderData.selectedFolderType,
      };
    }
    const { visible } = this.state;
    return (
      (visible || null) && (
        <div
          ref={(ref) => {
            this.root = ref;
          }}
          data-test="context-menu"
          className="contextMenu"
        >
          <div className="contextMenu--option" onClick={this.onInfoLoad}>
            <img src={ViewDetailsIcon} alt="view details" />
            <span>View Case Details</span>
          </div>

          <div className="contextMenu--option" onClick={this.onSlideTabLoad}>
            <img src={SlideTrayIcon} alt="view details" />
            <span>View Slide Tray</span>
          </div>

          <div className="contextMenu--option" onClick={this.handleOpenViewer}>
            <img src={ViewSlidesIcon} alt="view details" />
            <span>View Slides in Viewer</span>
          </div>

          <div className="contextMenu--separator" />

          <div className="contextMenu--option" onClick={this.onAddSlides}>
            <img src={AddSlidesIcon} alt="view details" />
            <span>Add Slides</span>
          </div>
          <div className="contextMenu--option" onClick={this.onAddAttachments}>
            <img src={AddAttachmentsIcon} alt="view details" />
            <span>Add Attachment</span>
          </div>
          {/* To be implemented later.. */}
          {/* <div className="contextMenu--option" onClick={this.onClickAddNotes}>
            <img src={AddNotesIcon} alt="view details" />
            <span>Add Notes</span>
          </div> */}
          <div className="contextMenu--option" onClick={this.onClickAddLabels}>
            <img src={AddLabelsIcon} alt="view details" />
            <span>Add Labels</span>
          </div>

          <div className="contextMenu--separator" />

          <div className="contextMenu--option" onClick={this.onClickRefresh}>
            <img src={RefreshIcon} alt="view details" />
            <span>Refresh Case</span>
          </div>

          <div
            className="contextMenu--option"
            onClick={this.onChangeCaseStatus}
          >
            <img src={StatusIcon} alt="view details" />
            <span>Set Case Status</span>
          </div>

          <div className="contextMenu--option" onClick={this.onChangePriority}>
            <img src={StatusIcon} alt="view details" />
            <span>Set Case Priority</span>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  slideDirectory: state.SlideBox.folders,
  slideDirDataList: state.SlideBox.dirDataList,
  showBreadCrumbIcon: state.SlideBox.breadCrumbIcon,
  showLoader: state.SlideBox.showLoader,
  isSearch: state.SlideBox.isSearchData,
  userDetails: state.Global.loggedUserDetails,
  selectedRightClickItem: state.SlideBox.selectedRightClickItem,
  selectedFolderData: state.SlideBox.selectedFolderData,
  selectedListData: state.SlideBox.selectedListData,
  selectedListSlideData: state.SlideBox.selectedListSlideData,
  allSlides: state.SlideBox.allSlides,
  currentTab: state.SlideBox.currentTab,
});

const mapDispatchToProps = (dispatch) => ({
  setSlideBoxRightClickSelected: (payload) =>
    dispatch(setSlideBoxRightClickSelectedAction(payload)),
  setReturnToWorklist: (payload) =>
    dispatch(setReturnToWorklistAction(payload)),
  getLoggedUserDetails: () => dispatch(getUserDetailsAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
