const initialState = {
  loggedUserDetails: {},
  otherUserDetails: null,
  dxAccountDetails: null,
  isLoggedUser: false,
  windowSize: null,
  showSidebar: true,
  profileUploadPending: false,
  permissions: null,
  allRolesPermissions: null,
  isSystemAdmin: null,
  showLoader: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_DETAILS": {
      return {
        ...state,
        loggedUserDetails: action.payload.userDetails,
        permissions: action.payload.userDetails.accessLevelData,
        allRolesPermissions: action.payload.allRolesPermissions,
        isSystemAdmin: action.payload.isSystemAdmin,
      };
    }

    case "RESET_PROFILE_PIC": {
      return {
        ...state,
        loggedUserDetails: {
          ...state.loggedUserDetails,
          userImageSrc: null,
        },
      };
    }
    case "SET_USER_PROFILE": {
      return {
        ...state,
        loggedUserDetails: {
          ...state.loggedUserDetails,
          userImageSrc: action.payload,
        },
      };
    }
    case "SET_INSTITUTION_LOGO": {
      return {
        ...state,
        loggedUserDetails: {
          ...state.loggedUserDetails,
          institution_logo_url: action.payload,
        },
      };
    }

    case "SET_PROFILE_UPLOAD_PENDING": {
      return {
        ...state,
        profileUploadPending: action.payload,
      };
    }

    case "GET_DX_ACCOUNT_DETAILS": {
      return {
        ...state,
        dxAccountDetails: action.payload,
      };
    }

    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        showSidebar: action.payload,
      };
    case "SHOW_LOADER": {
      return {
        ...state,
        showLoader: action.showLoader,
      };
    }
    default:
      return state;
  }
};
