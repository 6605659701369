import axios from "../../../utils/axiosInstance";

export const dxAccountListApi = async () => {
  try {
    const resData = await axios.get(`/api/siteAdmin/getDxAccountDetails`);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const createDxAccountApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      `/api/siteAdmin/createDxAccount`,
      dataParams
    );
    return resData;
  } catch (error) {
    throw error;
  }
};

export const adminUserLogoutApi = async () => {
  try {
    const resData = await axios.get(`/api/siteAdmin/logoutAdmin`);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const resendUserInvitationApi = async (data) => {
  try {
    return await axios.post(`/api/siteAdmin/resendAdminInvitation`, data);
  } catch (err) {
    throw err;
  }
};
