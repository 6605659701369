import async from "react-select/async";
import { getUserDetailsAction } from "../../../actions/global-action";
import { getNotificationsAction } from "../../notifications/actions/notification-actions";
import {
  slideBoxFileApi,
  slideBoxCreateDirApi,
  slideBoxRenameDirApi,
  worklistSearchParameters,
  slideBoxLoadDirApi,
  slideBoxLoadDirDataListApi,
  slideBoxDeleteDirApi,
  slideBoxSearchDirApi,
  slideBoxDirDescriptionUpdateApi,
  slideBoxCaseDetailsUpdateApi,
  slideBoxMoveDirApi,
  slideBoxDirCollaboratorsApi,
  slideBoxCollaboratorsApi,
  slideBoxRemoveCollaboratorsApi,
  slideBoxAssignUserToCaseApi,
  slideBoxRemoveAssignUserToCaseApi,
  slideBoxDirAssignUsersApi,
  slideBoxDirActivityApi,
  updateCaseStatusApi,
  getCaseStatusApi,
  dashboardUserListApi,
  slideboxAdvancedSearchApi,
  slideBoxFileDownloadApi,
  slideBoxLabelsUpdateApi,
  getCaseDetailsInfoApi,
  patientSearchApi,
  createNewWorklistApi,
  updateWorklistSearchInfoApi,
  getWorklistForTreeviewApi,
  updateWorklistApi,
  getWorklistDataApi,
  updateSystemWorklistApi,
  setOpenTabApi,
  getOpenTabsApi,
  setCloseTabApi,
  changePriorityApi,
  getNotesHistoryApi,
  checkAccessionNumberApi,
  addNotesApi,
  deleteSlideApi,
  updateStainTypeApi,
} from "../services/slidebox-service";

const setFolderLists = (data) => {
  return {
    type: "SET_FOLDER_LISTS",
    data,
  };
};

const setSlideBoxDirList = (dirList) => {
  return {
    type: "SET_FOLDER_LISTS",
    payload: dirList,
  };
};

const setSlideBoxDirDataList = (dirDataList) => {
  return {
    type: "SET_FOLDER_DATA_LISTS",
    payload: dirDataList,
  };
};

const setCreateNewDir = (newDir) => {
  return {
    type: "SET_NEW_DIRECTORY",
    payload: newDir,
  };
};

const setRenameDir = (slideName, slideId, dirType) => {
  return {
    type: "RENAME_DIRECTORY",
    payload: { slideName, slideId, dirType },
  };
};

const setDeleteDir = (dirId, dirType) => {
  return {
    type: dirType !== "folder" ? "DELETE_DIRECTORY" : "DELETE_FOLDER",
    dirId,
    dirType,
  };
};

const showLoader = (show) => {
  return {
    type: "SHOW_LOADER_IN_SLIDEBOX",
    showLoader: show,
  };
};

export const switchViewAction = (toggle) => {
  return {
    type: "SWITCH_VIEW",
    isListView: toggle,
  };
};

export const switchListViewAction = (toggle) => {
  return {
    type: "SWITCH_LIST_VIEW",
    isPatientView: toggle,
  };
};

const setSearchDirData = (searchResult) => {
  return {
    type: "SET_SEARCH_DIR_DATA",
    payload: searchResult,
  };
};

const setSlideBoxMoveDir = (payload) => {
  return {
    type: "SET_SLIDEBOX_MOVE_DIR",
    payload,
  };
};

export const resetMoveDirActive = (payload) => {
  return {
    type: "RESET_MOVE_DIR_ACTIVE",
    payload,
  };
};

const setSlideBoxDirCollaborators = (collaborators) => {
  return {
    type: "SET_SLIDEBOX_DIR_COLLABORATORS",
    payload: collaborators,
  };
};

const setSlideBoxCaseAssign = (users, dataParams) => {
  return {
    type: "SET_SLIDEBOX_CASE_ASSIGN_USERS",
    payload: users,
    caseData: dataParams,
  };
};

const setSlideBoxActivities = (activityData) => {
  return {
    type: "SET_SLIDEBOX_DIR_ACTIVITY",
    payload: activityData,
  };
};

const setSlideBoxCollaborators = (collaborators) => {
  return {
    type: "SET_SLIDEBOX_NEW_COLLABORATORS",
    payload: collaborators,
  };
};

const setSlideBoxRemoveCollaborators = (userId) => {
  return {
    type: "SET_SLIDEBOX_REMOVE_COLLABORATORS",
    payload: userId,
  };
};

const updateStainType = (data) => {
  return {
    type: "UPDATE_STAIN_TYPE",
    payload: data,
  };
};

const updateLabels = (data) => {
  return {
    type: "SLIDEBOX_LABELS_UPDATE",
    payload: data,
  };
};

export const newTab = (data) => {
  return {
    type: "NEW_TAB",
    payload: data,
  };
};

const allOpenTabs = (data) => {
  return {
    type: "ALL_OPEN_TABS",
    payload: data,
  };
};

export const setCurrentTab = (data) => {
  return {
    type: "SET_CURRENT_TAB",
    payload: data,
  };
};

export const closeTab = (data) => {
  return {
    type: "CLOSE_TAB",
    payload: data,
  };
};

export const closeFinalTab = (data) => {
  return {
    type: "CLOSE_FINAL_TAB",
    payload: data,
  };
};

export const updateTabAction = (data) => {
  return {
    type: "UPDATE_TAB_DATA",
    payload: data,
  };
};

const getSlides = (data) => {
  return {
    type: "GET_ALL_SLIDES",
    payload: data,
  };
};

const getMyWorklists = (data) => {
  return {
    type: "GET_ALL_MY_WORKLISTS",
    payload: data,
  };
};

const getWorklistColumnData = (data) => {
  return {
    type: "GET_WORKLIST_COLUMN_DATA",
    payload: data,
  };
};

const getSytemWorklists = (data) => {
  return {
    type: "GET_ALL_SYSTEM_WORKLISTS",
    payload: data,
  };
};

const getAttachments = (data) => {
  return {
    type: "GET_ALL_ATTACHMENTS",
    payload: data,
  };
};

const getPatientInfo = (data) => {
  return {
    type: "GET_SELECTED_PATIENT_DETAILS",
    payload: data,
  };
};

const getCaseInfo = (data) => {
  return {
    type: "GET_SELECTED_CASE_DETAILS",
    payload: data,
  };
};

const updateWorklistSearchParameters = (data) => {
  return {
    type: "UPDATE_WORKLIST_SEARCH_PARAMS",
    payload: data,
  };
};

const createWorklist = (data) => {
  return {
    type: "CREATE_WORKLIST",
    payload: data,
  };
};

const worklistTableValues = (data) => {
  return {
    type: "UPDATE_WORKLIST_COLUMNS_DATA",
    payload: data,
  };
};

const updateWorklistPatientColumns = (data) => {
  return {
    type: "UPDATE_WORKLIST_PATIENT_COLUMNS",
    payload: data,
  };
};

const changePriority = (data) => {
  return {
    type: "UPDATE_PRIORITY",
    payload: data,
  };
};

const setNotesHistory = (data) => {
  return {
    type: "SET_NOTES_HISTORY",
    payload: data,
  };
};
const accessionNumberStatus = (data) => {
  return {
    type: "ACCESSION_NUMBER",
    payload: data,
  };
};

export const setDirectories = (folders) => {
  return {
    type: "SET_FOLDERS",
    data: {
      folders,
    },
  };
};

export const setExpandDirectory = ({ dirId, listId, parentDir, dirType }) => {
  return {
    type: "SET_EXPAND_FOLDERS",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

const setSearchPatientData = (searchResult) => {
  return {
    type: "SET_SEARCH_PATIENT_DATA",
    payload: searchResult,
  };
};

const showMessage = (message) => {
  return {
    type: "SHOW_SLIDEBOX_MESSAGE",
    message,
  };
};

const changeTabColor = (payload) => {
  return {
    type: "CHANGE_TAB_COLOR",
    payload,
  };
};

export const setReturnToWorklistAction = (payload) => {
  return {
    type: "SET_RETURN_TO_WORKLIST",
    payload,
  };
};

export const resetSlideboxMessageAction = () => {
  return {
    type: "RESET_SLIDEBOX_MESSAGE",
  };
};

export const setExpandDirectoryInMove = ({
  dirId,
  listId,
  parentDir,
  dirType,
}) => {
  return {
    type: "SET_EXPAND_FOLDERS_IN_MOVE",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const getWorklistSearchParameters = (worklistId) => {
  return async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const response = await worklistSearchParameters(worklistId);
      if (response.data.success) {
        const parameters = response.data.parameters;
        dispatch(setSearchParams(parameters));
        dispatch(showLoader(false));
      }
    } catch (error) {
      dispatch(showLoader(false));
    }
  };
};

export const getSearchDirData = (data) => {
  return async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const response = await slideBoxSearchDirApi(data);
      if (response.data.success) {
        dispatch(setSearchDirData(response.data.data));
        dispatch(showLoader(false));
      }
    } catch (error) {
      dispatch(setSearchDirData([]));
      dispatch(showLoader(false));
    }
  };
};

export const setOpenDirectory = ({ dirId, listId, parentDir, dirType }) => {
  return {
    type: "SET_OPEN_FOLDERS",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setOpenDirectoryInMove = ({
  dirId,
  listId,
  parentDir,
  dirType,
}) => {
  return {
    type: "SET_OPEN_FOLDERS_IN_MOVE",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setSelectedDirectory = ({ dirId, listId, parentDir, dirType }) => {
  return {
    type: "SET_SELECTED_DIRS",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setSelectedDirectoryInMove = ({
  dirId,
  listId,
  parentDir,
  dirType,
}) => {
  return {
    type: "SET_SELECTED_DIRS_IN_MOVE",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setOpenListDirectory = ({ dirId, listId, parentDir, dirType }) => {
  return {
    type: "SET_OPEN_LIST_FOLDERS",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setSelectedListDirectory = ({
  dirId,
  listId,
  parentDir,
  dirType,
}) => {
  return {
    type: "SET_SELECTED_LIST_DIRS",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setSelectedDirCollaborators = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxDirCollaboratorsApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxDirCollaborators(response.data.data));
      } else {
      }
    } catch (error) {}
  };
};

export const setSelectedDirAssignUsers = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxDirAssignUsersApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxCaseAssign(response.data.data, dataParams[0]));
      } else {
      }
    } catch (error) {}
  };
};

export const setSelectedListActivity = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxDirActivityApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxActivities(response.data.data));
      } else {
      }
    } catch (error) {}
  };
};

export const setSelectedUploadFolder = (folder) => {
  return {
    type: "SET_SELECTED_UPLOAD_FOLDER",
    data: {
      folder,
    },
  };
};

export const setSlideBoxStorageSize = (data) => {
  return {
    type: "SET_SLIDE_BOX_SIZE",
    data,
  };
};

export const setSlideBoxLoading = (loading) => {
  return {
    type: "SET_SLIDE_BOX_LOADING",
    loading,
  };
};

export const addTumorBoardEnable = (enable) => {
  return {
    type: "SET_ADD_TUMOR_BOARDS_ENABLE",
    enable,
  };
};

export const setCreateDirId = (dirId) => {
  return {
    type: "SET_CREATE_DIR_ID",
    payload: dirId,
  };
};
export const fetchSlideBoxFolders = (
  folder = "slidebox",
  type = "withFolder"
) => {
  return async (dispatch) => {
    dispatch(setSlideBoxLoading(true));
    dispatch(getUserDetailsAction());
    try {
      const response = await slideBoxFileApi({ folder: folder, type });
      if (response.status === 200) {
        dispatch(setFolderLists(response.data));
      }
    } catch (error) {}
  };
};

export const setSlideBoxStorageLimit = () => {
  return async (dispatch) => {
    try {
      const response = await slideBoxStorageDetailsApi();
      dispatch(setSlideBoxStorageSize(response.data));
    } catch (error) {}
  };
};

export const createSlideBoxDirectory = (data) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxCreateDirApi(data);
      if (response.data.success) {
        dispatch(setCreateNewDir(response.data.data));
        dispatch(setCreateDirId(response.data.data.dirId));
        dispatch(
          getWorklistDataAction({
            worklistId: data.defaultWorklistId.worklistId,
          })
        );
      } else {
      }
    } catch (error) {}
  };
};

export const renameSlideBoxDirectory = (data) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxRenameDirApi("slide", data);
      if (response.data.success) {
        dispatch(setRenameDir(data.slideName, data.slideId, "slide"));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateStainTypeAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await updateStainTypeApi(data);
      if (response.data.success) {
        dispatch(updateStainType(data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteSlideBoxItemAction = (dirType, data, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxDeleteDirApi(dirType, data);
      if (response.data.success) {
        dispatch(setDeleteDir(data.dirId, dirType));
        dispatch(getUserDetailsAction());
        onSuccess();
      } else {
        onError();
      }
    } catch (error) {
      onError();
    }
  };
};

export const loadSlideboxDirectory = () => {
  return async (dispatch) => {
    try {
      const response = await slideBoxLoadDirApi();
      dispatch(setSlideBoxDirList(response.data));
      dispatch(getUserDetailsAction());
    } catch (error) {}
  };
};

export const loadSlideboxDirDataList = (dataParams) => {
  return async (dispatch) => {
    try {
      dispatch(showLoader(true));
      dispatch(getWorklistColumnData({}));
      const response = await slideBoxLoadDirDataListApi(dataParams);
      dispatch(setSlideBoxDirDataList(response.data));
      dispatch(getUserDetailsAction());
      dispatch(showLoader(false));
      // dispatch(switchViewAction(true));
    } catch (error) {}
  };
};

export const refreshWorklist = (dataParams) => {
  return async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const response = await slideBoxLoadDirDataListApi(dataParams);
      dispatch(setSlideBoxDirDataList(response.data));
      dispatch(getUserDetailsAction());
      dispatch(showLoader(false));
    } catch (error) {}
  };
};

export const showSnackBarComponent = ({
  show,
  fileList,
  conversionComplete,
  percentCompleted,
}) => {
  return {
    type: "SHOW_SNACK_BAR_COMPONENT",
    payload: { show, fileList, conversionComplete, percentCompleted },
  };
};

export const setFileUploadProgress = ({ fileIndex, percentCompleted }) => {
  return {
    type: "SET_FILE_UPLOAD_PROGRESS",
    payload: { fileIndex, percentCompleted },
  };
};

export const setFileCanceller = ({ fileIndex, canceller }) => {
  return {
    type: "SET_FILE_CANCELLER",
    payload: { fileIndex, canceller },
  };
};

export const resetSlideUploadState = () => {
  return {
    type: "RESET_SLIDE_UPLOAD_STATE",
  };
};

export const removeUploadingFile = (fileIndex) => {
  return {
    type: "REMOVE_UPLOADING_FILE",
    payload: { fileIndex },
  };
};

export const clearUploadingList = () => {
  return {
    type: "CLEAR_UPLOADING_LIST",
  };
};

export const breadcrumbAction = (payload) => {
  return {
    type: "BREADCRUMB_ICONS_STATUS",
    payload,
  };
};

export const setSlideBoxDirDetailsUpdate = (payload) => {
  return {
    type: "SLIDEBOX_DIR_DETAILS_UPDATE",
    payload,
  };
};

export const setSlideBoxCaseDetails = (payload) => {
  return {
    type: "SLIDEBOX_CASE_DETAILS_UPDATE",
    payload,
  };
};

export const setSlideBoxMovePopupAction = (payload) => {
  return {
    type: "SET_SLIDEBOX_MOVE_POPUP_STATE",
    payload,
  };
};

export const setSlideBoxAssignUserToCase = (payload, dirId) => {
  return {
    type: "SET_SLIDEBOX_CASE_ASSIGN",
    payload,
    dirId,
  };
};

export const removeSlideBoxAssignUserToCase = (payload) => {
  return {
    type: "REMOVE_SLIDEBOX_CASE_ASSIGN",
    payload,
  };
};

export const updateSlideBoxSlideDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxDirDescriptionUpdateApi(data);
      if (response.data.success) {
        dispatch(showMessage(response.data.msg));
        dispatch(updateWorklistPatientColumns(data));
        dispatch(setSlideBoxDirDetailsUpdate(data));
        dispatch(getAllCaseDetailsTabsInfoAction({ dirId: data.dirId }));
      }
    } catch (error) {}
  };
};

export const updateSlideBoxCaseDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxCaseDetailsUpdateApi(data);
      if (response.data.success) {
        dispatch(showMessage(response.data.msg));
        dispatch(worklistTableValues(response.data.updatedCaseDetails[0]));
        dispatch(getAllCaseDetailsTabsInfoAction({ dirId: data.dirId }));
        dispatch(
          setSelectedListActivity({
            dirId: data.dirId,
            dirType: "case",
          })
        );
      }
    } catch (error) {}
  };
};

export const deleteSlideAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await deleteSlideApi(data);
      if (response.data.success) {
      }
    } catch (error) {}
  };
};

export const updateSlideboxLabels = (data) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxLabelsUpdateApi(data);
      if (response.data.success) {
        dispatch(updateLabels(data));
      }
    } catch (error) {}
  };
};

export const setSlideBoxMoveDirAction = (dataParams, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxMoveDirApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxMoveDir(dataParams));
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };
};

export const setNewDirCollaboratorsAction = (dataParams, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxCollaboratorsApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxCollaborators(dataParams.collaborateUsers));
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };
};

export const setNewAssignUserToCaseAction = (dataParams, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxAssignUserToCaseApi(dataParams);
      if (response.data.success) {
        dispatch(
          setSlideBoxAssignUserToCase(dataParams.assignUsers, dataParams.dirId)
        );
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };
};

export const removeAssignUserToCaseAction = (dataParams, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxRemoveAssignUserToCaseApi(dataParams);
      if (response.data.success) {
        dispatch(removeSlideBoxAssignUserToCase(dataParams));
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };
};

export const removeDirCollaboratorsAction = (dataParams, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxRemoveCollaboratorsApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxRemoveCollaborators(dataParams.collaboratorId));
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };
};

export const setSlideBoxRightClickSelectedAction = (payload) => {
  return {
    type: "SET_SLIDEBOX_RIGHT_CLICK_SLECTED",
    payload,
  };
};

export const setSelectedSlideData = (payload) => {
  return {
    type: "SET_SLIDE_DATA_FROM_LIST",
    payload,
  };
};

const setCaseReviewStatus = (data) => {
  return {
    type: "SET_CASE_REVIEW_STATUS",
    payload: data,
  };
};

export const getCaseStatusAction = () => {
  return async (dispatch) => {
    try {
      const response = await getCaseStatusApi();
      if (response.status === 200) {
        // dispatch(setCaseReviewStatus(response.data));
      }
    } catch (error) {
      dispatch(getCaseStatusApi([]));
    }
  };
};

export const updateCaseStatusAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await updateCaseStatusApi(data);
      if (response.status === 200) {
        dispatch(setCaseReviewStatus(response.data));
        dispatch(getNotificationsAction());
        if (!data.isFirstSlide) {
          dispatch(showMessage(response.data.msg));
        }
        dispatch(
          setSelectedListActivity({
            dirId: response.data.dirId,
            dirType: "case",
          })
        );
      }
    } catch (error) {
      // dispatch(updateCaseStatusApi([]));
    }
  };
};

export const setSearchParams = (data) => {
  return {
    type: "SET_SEARCH_PARAMS",
    payload: data,
  };
};

export const getFileDownloadUrlAction = (dataParams, callbackFn) => {
  return async () => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxFileDownloadApi(dataParams);
      if (response.data.success) {
        onSuccess(response.data.data);
      }
    } catch (error) {
      onError();
    }
  };
};

export const deselectListDirAction = () => {
  return {
    type: "UNSET_SELECTED_LIST_DATA",
  };
};

export const getAllCaseDetailsTabsInfoAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await getCaseDetailsInfoApi(data);
      if (response.data.success) {
        dispatch(getSlides(response.data.slidesInfo));
        dispatch(getAttachments(response.data.documentsInfo));
        dispatch(
          getPatientInfo({
            patientInfo: response.data.patientInfo[0],
            historicalCases: response.data.historicalCases,
          })
        );
        dispatch(
          getCaseInfo({
            caseInfo: response.data.caseInfo[0],
            totalContainers: response.data.totalContainers,
            totalSections: response.data.totalSections,
            totalBlocks: response.data.totalBlocks,
            totalSlides: response.data.totalSlides,
            labels: response.data.caseInfo[1],
            data,
          })
        );
      }
    } catch (error) {}
  };
};

export const getPatientData = (data) => {
  return async (dispatch) => {
    try {
      const response = await patientSearchApi(data);

      if (response.data.success) {
        dispatch(setSearchPatientData(response.data.body));
        dispatch(showLoader(false));
      }
    } catch (error) {
      dispatch(setSearchDirData([]));
      dispatch(showLoader(false));
    }
  };
};

export const getMyWorklistsAction = () => {
  return async (dispatch) => {
    try {
      const response = await getWorklistForTreeviewApi();
      if (response.data.success) {
        dispatch(getMyWorklists(response.data.myWorklistsData));
        dispatch(getSytemWorklists(response.data.systemWorklistsData));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateWorklistSearchInfoAction = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await updateWorklistSearchInfoApi(dataParams);
      if (response.data.success) {
        dispatch(showMessage(response.data.msg));
        dispatch(
          changeTabColor({
            worklistId: dataParams.worklistId,
            color: dataParams.worklistColour,
          })
        );
        dispatch(updateWorklistSearchParameters(dataParams));
        dispatch(getMyWorklistsAction());
        dispatch(getWorklistDataAction({ worklistId: dataParams.worklistId }));
        dispatch(getAllOpenTabsAction());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createNewWorklistAction = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await createNewWorklistApi(dataParams);
      if (response.data.success) {
        dispatch(showMessage(response.data.msg));
        dispatch(createWorklist(dataParams));
        dispatch(getMyWorklistsAction());
      } else {
        dispatch(showMessage(response.data.msg));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateWorklistAction = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await updateWorklistApi(dataParams);
      if (response.data.success) {
        if (dataParams.actionType === "change-colour") {
          dispatch(
            changeTabColor({
              worklistId: dataParams.worklistId,
              color: dataParams.worklistColour,
            })
          );
          dispatch(showMessage(response.data.msg));
        } else {
          dispatch(getAllOpenTabsAction());
          dispatch(showMessage(response.data.msg));
          dispatch(getMyWorklistsAction());
        }
      } else {
        dispatch(showMessage(response.data.msg));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getWorklistDataAction = (dataParams) => {
  return async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const response = await getWorklistDataApi(dataParams);
      if (response.data.success) {
        dispatch(getWorklistColumnData(response.data.allDirectoriesData));
        dispatch(showLoader(false));
      }
    } catch (error) {
      console.log(error);
      dispatch(showLoader(false));
    }
  };
};

export const updateSystemWorklistAction = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await updateSystemWorklistApi(dataParams);
      if (response.data.success) {
        dispatch(showMessage(response.data.msg));
        dispatch(getMyWorklistsAction());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const setOpenTabAction = (dataParams) => {
  return async (dispatch) => {
    try {
      if(dataParams.finalTab) {
        await dispatch(setCloseFinalTabAction({
          index: 7,
          worklistId: dataParams.finalTab.worklistId,
          worklistType: dataParams.finalTab.worklistType,
        }));
      }
      const response = await setOpenTabApi(dataParams);
      if (response.data.success) {
        dispatch(newTab(dataParams));
      }
    } catch (error) {}
  };
};

export const openAllCasesTab = () => {
  return async (dispatch) => {
    try {
      const openTabs = await getOpenTabsApi();
      const allCasesWorklist = openTabs.data.allOpenWorklists
      .find((worklist) => worklist.label === 'All Cases');

      dispatch(setCurrentTab(allCasesWorklist));
    } catch (error) {
      console.log(error);
    }
  }
}

export const getAllOpenTabsAction = () => {
  return async (dispatch) => {
    try {
      const response = await getOpenTabsApi();
      if (response.data.success) {
        const allCasesWorklist = response.data.allOpenWorklists.filter(
          (worklist) => worklist.label === "All Cases"
        );
        dispatch(allOpenTabs(response.data.allOpenWorklists));
        // dispatch(setCurrentTab(allCasesWorklist[0]));
      }
    } catch (error) {}
  };
};

export const setCloseTabAction = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await setCloseTabApi(dataParams);
      if (response.data.success) {
        dispatch(closeTab(dataParams));
      }
    } catch (error) {}
  };
};

export const setCloseFinalTabAction = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await setCloseTabApi(dataParams);
      if (response.data.success) {
        dispatch(closeFinalTab(dataParams));
      }
    } catch (error) {}
  };
};

export const changePriorityAction = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await changePriorityApi(dataParams);
      if (response.data.success) {
        dispatch(changePriority(dataParams));
        dispatch(showMessage(response.data.msg));
      }
    } catch (error) {}
  };
};

export const getNotesHistoryAction = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await getNotesHistoryApi(dataParams);

      if (response.data.success) {
        dispatch(setNotesHistory(response.data.allNotesData));
      }
    } catch (error) {}
  };
};

export const checkAccessionNumberAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await checkAccessionNumberApi(data);
      if (response.data.success) {
        dispatch(accessionNumberStatus(response.data));
      }
    } catch (error) {}
  };
};

export const addNotesAction = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await addNotesApi(dataParams);
      if (response.data.success) {
        dispatch(showMessage(response.data.msg));
        dispatch(getNotesHistoryAction({ caseId: dataParams.caseId }));
      } else {
        dispatch(showMessage(response.data.msg));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
