import React, { useState } from "react";
import SlideBoxDataSection from "./components/SlideBoxDataSection";
import SlideboxTreeview from "./components/SlideboxTreeview";
import "./SlideBox.scss";
import { connect } from "react-redux";

const SlideBoxPage = ({
  isModal,
  setOpenSlidebox,
  isFolderSelected,
  setIsFolderSelected,
  isOpenedFromGroups,
}) => {
  const [
    slideboxLeftSiderIsCollapse,
    setSlideboxLeftSiderIsCollapse,
  ] = useState(true);

  const [isSearchTabActive, setIsSearchTabActive] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showAdvancedSearchModal, setShowAdvancedSearchModal] = useState(false);

  return (
    <div className="slidebar-container">
      <SlideboxTreeview
        {...{
          isModal,
          slideboxLeftSiderIsCollapse,
          setSlideboxLeftSiderIsCollapse,
          isSearchTabActive,
          setIsSearchTabActive,
          isOpenedFromGroups,
          showAdvancedSearchModal,
          setShowAdvancedSearchModal,
        }}
      />

      <SlideBoxDataSection
        isModal={isModal}
        setOpenSlidebox={setOpenSlidebox}
        isFolderSelected={isFolderSelected}
        setIsFolderSelected={setIsFolderSelected}
        slideboxLeftSiderIsCollapse={slideboxLeftSiderIsCollapse}
        isOpenedFromGroups={isOpenedFromGroups}
        isSearchTabActive={isSearchTabActive}
        setIsSearchTabActive={setIsSearchTabActive}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        showAdvancedSearchModal={showAdvancedSearchModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.SlideBox.selectedListData,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SlideBoxPage);
