import React, { Fragment, useEffect, useState } from "react";
import { FadeIn } from "animate-components";
import { FeatureGate } from "react-feature-gate";
import ClosePopupIcon from "../../assets/icons/close-new.svg";
import Case from "../../assets/icons/case-close.svg";
import { Overlay } from "../../components";
import "./CreateCaseModal.scss";
import CreatePatientTab from "./components/CreatePatientTab/CreatePatientTab";
import CreateCaseTab from "./components/CreateCaseTab/CreateCaseTab";
import UploadAttachments from "./components/UploadAttachmentsTab/UploadAttachments";
import UploadSlides from "./components/UploadSlidesTab/UploadSlides";

const CreateCaseModal = ({
  onClose,
  licenseTypeFeatures,
  openSlideTab,
  openCaseTab,
  allPatientDetails,
}) => {
  const [openTab, setOpenTab] = useState(
    openSlideTab
      ? "slide"
      : openCaseTab
      ? "attachment"
      : licenseTypeFeatures.patient_level_information
      ? "patient"
      : "create_case"
  );

  const [promptTitle, setPromptTitle] = useState(
    openSlideTab
    ? "Add slides to the case"
    : openCaseTab
    ? "Add attachments to the case"
    : "Create case"
  );

  const [patientDetails, setPatientDetails] = useState(
    allPatientDetails ? allPatientDetails : {}
  );
  const [caseDetails, setCaseDetails] = useState({});

  const [patientAge, setPatientAge] = useState(null);
  const [caseData, setCaseData] = useState({});
  const [patientInfo, setPatientInfo] = useState({});

  useEffect(() => {
    const age = getAge(patientDetails.dob);
    setPatientAge(age);
  }, [patientDetails.dob]);

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  return (
    <Fragment>
      <Overlay />

      <div className="create-case-modal prompt">
        <FadeIn duration="300ms">
          <div className="prompt-top bg-color">
            <div className="top-icon-and-text">
              <img src={Case} alt="Delete" />
              <span className="prompt-title">{promptTitle}</span>
            </div>
            <img
              className="close-icon"
              src={ClosePopupIcon}
              onClick={onClose}
            />
          </div>

          <div className="tabs">
            <FeatureGate feature="patient_level_information">
              <span className={openTab === "patient" ? "activeTab" : ""}>
                Patient
              </span>
            </FeatureGate>

            <span className={openTab === "create_case" ? "activeTab" : ""}>
              Case
            </span>
            <span className={openTab === "slide" ? "activeTab" : ""}>
              Slide
            </span>
            <span className={openTab === "attachment" ? "activeTab" : ""}>
              Attachment
            </span>
          </div>

          {openTab === "patient" && (
            <FeatureGate feature="patient_level_information">
              <CreatePatientTab
                setOpenTab={setOpenTab}
                onClose={onClose}
                patientDetails={patientDetails}
                setPatientDetails={setPatientDetails}
                patientInfo={patientInfo}
                setPatientInfo={setPatientInfo}
              />
            </FeatureGate>
          )}
          {openTab === "create_case" && (
            <CreateCaseTab
              setOpenTab={setOpenTab}
              onClose={onClose}
              patientDetails={patientDetails}
              age={patientAge}
              caseData={caseData}
              setCaseData={setCaseData}
              licenseTypeFeatures={licenseTypeFeatures}
              caseDetails={caseDetails}
              setCaseDetails={setCaseDetails}
            />
          )}
          {openTab === "attachment" && (
            <UploadAttachments
              onClose={onClose}
              patientDetails={patientDetails}
              caseData={caseData}
              age={patientAge}
            />
          )}
          {openTab === "slide" && (
            <UploadSlides
              setOpenTab={setOpenTab}
              onClose={onClose}
              patientDetails={patientDetails}
              caseData={caseData}
              age={patientAge}
            />
          )}
        </FadeIn>
      </div>
    </Fragment>
  );
};

export default CreateCaseModal;
