import React, { useState, useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import { connect } from 'react-redux';

const ViewerScaleBar = ({
  OPENSEADRAGONVIEWER,
  imageMetadata,
  scaleVal,
  overlay,
  zoomValueBar,
  scaleUnit,
}) => {
  const [microscopeMaxZoom, setMicroscopeMaxZoom] = useState(20);
  // const [magnificationLevel, setmagnificationLevel] = useState(1);

  useEffect(() => {
    setMicroscopeMaxZoom(
      imageMetadata['openslide.objective-power']
        ? imageMetadata['openslide.objective-power']
        : 20
    );
  }, [imageMetadata]);

  const setZoomLevel = (selectedZoomLevel) => {
    const maxZoom = OPENSEADRAGONVIEWER.viewport.getMaxZoom();
    const osdZoomVal = (selectedZoomLevel * maxZoom) / microscopeMaxZoom;
    OPENSEADRAGONVIEWER.viewport.zoomTo(osdZoomVal);
  };

  const handleChange = (event, newValue) => {
    setZoomLevel(newValue);
    if (typeof overlay.fabricCanvas() !== 'undefined' && overlay) {
      overlay
        .fabricCanvas()
        .getObjects()
        .some((obj) => {
          obj.strokeWidth = 80 / newValue;
        });
    }
  };

  const marks = [
    {
      value: 1,
      label: '1x',
    },
    {
      value: 2,
      label: '2x',
    },
    {
      value: 5,
      label: '5x',
    },
    {
      value: 10,
      label: '10x',
    },
    {
      value: 20,
      label: '20x',
    },
  ];
  const marks_40 = [
    {
      value: 40,
      label: '40x',
    },
  ];

  return (
    <React.Fragment>
      <div className="bottom_right_status_bar">
        <div className="status_bar_top">
          <span
            style={{
              display: isNaN(imageMetadata['aperio.MPP']) ? 'none' : 'block',
            }}
          >
            {scaleVal} {scaleUnit}
          </span>
        </div>
        <div className="status_item_top">
          <span>{`Magnification:  ${zoomValueBar}`}</span>
        </div>
      </div>

      <div className="scale_bar_slider">
        <Slider
          orientation="vertical"
          defaultValue={1}
          value={parseFloat(zoomValueBar)}
          aria-labelledby="discrete-slider-custom"
          step={0.1}
          valueLabelDisplay="auto"
          onChange={handleChange}
          marks={microscopeMaxZoom == 20 ? marks : [...marks, ...marks_40]}
          min={1}
          max={microscopeMaxZoom == 40 ? 40 : 20}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  scaleUnit: state.Viewer.scaleUnit,
});

export default connect(mapStateToProps)(ViewerScaleBar);
