import React, { useState } from "react";
import { StackedGraph } from "./StackedGraph";

const data = [
  {
    name: "Jan",
    type: 1,
    Pending: 5,
    Review: 2,
  },

  {
    name: "Feb",
    type: 1,
    Pending: 3,
    Review: 4,
  },

  {
    name: "March",
    type: 1,
    Pending: 4,
    Review: 6,
  },
  {
    name: "April",
    type: 1,
    Pending: 5,
    Review: 8,
  },
  {
    name: "May",
    type: 1,
    Pending: 6,
    Review: 14,
  },
];

const allKeys = ["Pending", "Review"];

const colors = {
  Pending: "#0F0DA1",
  Review: "#6332B3",
};

const AverageTimeStatusChart = () => {
  const [keys, setKeys] = useState(allKeys);

  return <StackedGraph datasets={data} colors={colors} keys={keys} />;
};

export default AverageTimeStatusChart;
