import React, { Fragment, useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { switchViewAction } from "../src/pages/slidebox/actions/slidebox-action";
import { switchListViewAction } from "../src/pages/slidebox/actions/slidebox-action";
import { connect } from "react-redux";
import {
  loadSlideboxDirDataList,
  getSearchData,
  updateCaseStatusAction,
  getCaseStatusAction,
  setSelectedListActivity,
} from "../src/pages/slidebox/actions/slidebox-action";
import PatientViewIcon from "../src/assets/icons/PatientViewIcon.svg";
import CaseViewIcon from "../src/assets/icons/case-view-icon.svg";

const SlideboxHeader = ({
  selectedList,
  showDetails,
  setSelectedDetails,
  outSideList,
  switchView,
  setSwitchView,
  switchViewAction,
  isListView,
  when,
  worklistData,
}) => {
  const handleSwitchView = () => {
    setSwitchView(!switchView);
    switchViewAction(!switchView);
  };

  useEffect(() => {
    if (!showDetails) {
      if (outSideList) {
        if (selectedList !== undefined) {
          const mappedData = {
            caseOrFolder: selectedList.caseOrFolder,
            description: selectedList.description,
            dirDataId: selectedList.dirId,
            dirDataName: selectedList.dirName,
            dirDataSize: selectedList.dirSize,
            first_name: selectedList.first_name,
            last_name: selectedList.last_name,
            annotationCount: selectedList.annotationCount,
            assignTo: selectedList.assignTo,
            review_status: selectedList.review_status,
            lastModified: selectedList.lastModified,
            notes: selectedList.notes,
            stainType: null,
            uniqueId: null,
          };

          setSelectedDetails(mappedData);
        }
      } else {
        setSelectedDetails(selectedList);
      }
    } else {
      if (selectedList && typeof selectedList.dirId !== "undefined") {
        const mappedData = {
          caseOrFolder: selectedList.caseOrFolder,
          description: selectedList.description,
          dirDataId: selectedList.dirId,
          dirDataName: selectedList.dirName,
          dirDataSize: selectedList.dirSize,
          annotationCount: selectedList.annotationCount,
          first_name: selectedList.first_name,
          last_name: selectedList.last_name,
          assignTo: selectedList.assignTo,
          review_status: selectedList.review_status,
          lastModified: selectedList.lastModified,
          notes: selectedList.notes,
          stainType: null,
          uniqueId: null,
        };
        setSelectedDetails(mappedData);
      }
      if (selectedList && typeof selectedList.dirDataId !== "undefined") {
        setSelectedDetails(selectedList);
      }
    }
  }, [selectedList]);

  return (
    <div className="slidebox-header" data-test="slideboxHeader">
      <div className="slidebox-selected"></div>
      {/* Hide Patient Level view temporarily  */}
      {/* {when !== "analysis" && worklistData.length > 0 && (
        <div className="slidebox-view" data-test="slideView">
          {isListView ? (
            <IconButton
              color="info"
              aria-label="change view"
              component="span"
              data-for="worklist-view-tooltip"
              data-tip="Patient View"
              onClick={handleSwitchView}
            >
              <img src={PatientViewIcon} />
            </IconButton>
          ) : (
            <IconButton
              color="info"
              aria-label="change view"
              component="span"
              data-for="worklist-view-tooltip"
              data-tip="Case View"
              onClick={handleSwitchView}
            >
              <img src={CaseViewIcon} />
            </IconButton>
          )}
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isListView: state.SlideBox.isListView,
  isPatientView: state.SlideBox.isPatientView,
  worklistData: state.SlideBox.worklistColumnData,
});

const mapDispatchToProps = (dispatch) => ({
  switchViewAction: (payload) => dispatch(switchViewAction(payload)),
  switchListViewAction: (payload) => dispatch(switchListViewAction(payload)),
  getSearchDirData: (payload) => dispatch(getSearchDirData(payload)),
  getSearchData: (payload) => dispatch(getSearchData(payload)),
  loadSlideboxDirDataList: (payload) =>
    dispatch(loadSlideboxDirDataList(payload)),
  getCaseStatus: (payload) => dispatch(getCaseStatusAction(payload)),
  updateCaseStatus: (payload) => dispatch(updateCaseStatusAction(payload)),
  setSelectedListActivity: (payload) =>
    dispatch(setSelectedListActivity(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlideboxHeader);
