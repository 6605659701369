import axios from '../../../utils/axiosInstance';

export const getPreviousRunsApi = async () => {
  try {
    return await axios.get(`/api/aiAnalytics/previousRuns`);
  } catch (err) {
    throw err;
  }
};

export const runAnalysisApi = async (data) => {
  try {
    if (data.algorithm === 1) {
      return await axios.post(`/api/aiAnalytics/run-analysis`, data);
    } else {
      return await axios.post(`/api/aiAnalytics/run-orion-analysis`, data);
    }
  } catch (err) {
    throw err;
  }
};

export const deletePreviousRunsRowApi = async (data) => {
  try {
    return await axios.post(`/api/aiAnalytics/deletePreviousRunsRow`, data);
  } catch (err) {
    throw err;
  }
};

export const getAnalysisResultApi = async (data) => {
  try {
    return await axios.get(`/api/aiAnalytics/getAnalysisResult`, {
      params: data,
    });
  } catch (err) {
    throw err;
  }
};
