import {
  ComplexImageTypes,
  UploadFileTypes,
} from "../../../../config/constants";
import { bytesToSize, loadParentDirArray } from "../../../utils/utils";

export const getFoldersFromResponse = (response) => {
  const foldersListResponse = response.Contents || [];
  let folderList = [];
  let folderId = 100;

  for (let i = 0; i < foldersListResponse.length; i++) {
    const folderData = foldersListResponse[i];

    const itemList = folderData.Key.replace(/\/\//g, "/").split("/");

    let parentFolder = "";
    let parentPath = "";
    let firstIndex = false;

    itemList.forEach((item, index) => {
      if (item.trim() === "") {
        return;
      }
      if (index === itemList.length - 1 && isFile(item)) {
        let parent = folderList.find((folder) => folder.path === parentPath);
        if (!parent) {
          return;
        }
        parent.files.push({
          name: item,
          parentName: parentFolder,
          parentId: parentPath,
          path: parentPath + "/" + item,
          modified_date: new Date(folderData.upload_time),
          id: parentFolder + item + new Date(folderData.upload_time).valueOf(),
          size: folderData.Size,
          storageClass: folderData.StorageClass,
          eTag: folderData.ETag,
          thumbNail: folderData.thumbNail,
        });
      } else if (
        !folderList.find(
          (folder) => folder.name === item && folder.parentId === parentPath
        )
      ) {
        folderList.push({
          name: item,
          parentName: parentFolder,
          modified_date: new Date(folderData.LastModified),
          files: [],
          path: itemList.filter((item, itemInd) => itemInd <= index).join("/"),
          id: item + "__" + folderId,
          parentId: parentPath,
        });
      }

      folderId = folderId + 1;
      parentPath = itemList
        .filter((item, itemInd) => itemInd <= index)
        .join("/");
      parentFolder = item;
    });
  }

  return folderList.map((folder) => {
    let size = 0;
    folder.files.forEach((file) => {
      size += file.size;
    });
    folder.size = size;
    return folder;
  });
};

let fileTypes = UploadFileTypes.toString()
  .split("|")
  .map((type) => {
    return type.replace("/", "");
  });

const FILE_EXTENSIONS = [...ComplexImageTypes, ...fileTypes];

const isFile = (name) => {
  let extensions = name.split(".");
  if (extensions && extensions.length > 0) {
    return FILE_EXTENSIONS.includes(
      extensions[extensions.length - 1].toLowerCase()
    );
  } else {
    return false;
  }
};

export const getTreeStructure = (folders) => {
  let treeStructure = [];
  folders
    .filter((obj) => obj.path !== "slidebox")
    .forEach((folder) => {
      let parent = findParent(folder, treeStructure);
      if (!parent) {
        treeStructure.push({
          ...folder,
          key: folder.path,
          title: folder.name,
        });
      } else {
        if (!parent.children) {
          parent.children = [];
        }
        parent.children.push({
          ...folder,
          key: folder.path,
          title: folder.name,
        });
      }
    });
  if (folders.length > 0) {
    treeStructure[0].title = "My Slides";
  } else {
    alert();
    treeStructure.push({
      title: "test",
    });
  }

  return treeStructure;
};

const findParent = (folder, treeStructure) => {
  if (treeStructure.find((obj) => obj.path === folder.parentId)) {
    return treeStructure.find((obj) => obj.path === folder.parentId);
  }
  let parent = null;
  for (let i = 0; i < treeStructure.length; i++) {
    parent = findParent(folder, treeStructure[i].children);
    if (parent) {
      return parent;
    }
  }
  return parent;
};

export const setExpandFolder = (data, folders) => {
  const { folder, dirType } = data;
  const dirId = dirType === "case" ? `case-${folder}` : `folder-${folder}`;
  const mappedFolders = folders.map((folderData) => {
    const runDirId =
      folderData.caseOrFolder === "case"
        ? `case-${folderData.dirId}`
        : `folder-${folderData.dirId}`;
    if (runDirId === dirId) {
      return {
        ...folderData,
        open: !folderData.open,
      };
    } else {
      return folderData;
    }
  });

  return mappedFolders;
};

export const setExpandChildFolder = (data, folders) => {
  const { folder, dirType } = data;
  const dirId = dirType === "case" ? `case-${folder}` : `folder-${folder}`;
  const mappedFolders = folders.map((folderData) => {
    const runDirId =
      folderData.caseOrFolder === "case"
        ? `case-${folderData.dirId}`
        : `folder-${folderData.dirId}`;
    if (runDirId === dirId) {
      return {
        ...folderData,
        open: !folderData.open,
        active: true,
        selected: true,
      };
    } else {
      return folderData;
    }
  });

  return mappedFolders;
};

export const setOpenFolder = (data, folders) => {
  const { folder, dirType } = data;
  const dirId = dirType === "case" ? `case-${folder}` : `folder-${folder}`;
  const mappedFolders = folders.map((folderData) => {
    const runDirId =
      folderData.caseOrFolder === "case"
        ? `case-${folderData.dirId}`
        : `folder-${folderData.dirId}`;
    if (runDirId === dirId) {
      return {
        ...folderData,
        open: !folderData.open,
        active: true,
        selected: true,
      };
    } else {
      if (folderData.parentDir) {
        return {
          ...folderData,
          open: false,
          active: false,
          selected: false,
        };
      } else {
        return {
          ...folderData,
          open: true,
          active: false,
          selected: true,
        };
      }
    }
  });

  return mappedFolders;
};

export const setOpenChildFolder = (data, folders) => {
  const { folder, parentFolder, dirType } = data;
  const dirId = dirType === "case" ? `case-${folder}` : `folder-${folder}`;
  const openDirArray = loadParentDirArray(
    folder,
    dirType,
    parentFolder,
    folders
  );
  const mappedFolders = folders.map((folderData) => {
    if (openDirArray.includes(folderData.pk) && folderData.pk === dirId) {
      return {
        ...folderData,
        active: true,
        selected: true,
      };
    } else {
      if (openDirArray.includes(folderData.pk) && folderData.pk !== dirId) {
        return {
          ...folderData,
          active: false,
          selected: true,
        };
      } else {
        return {
          ...folderData,
          active: false,
          selected: false,
        };
      }
    }
  });

  return mappedFolders;
};

export const setSelectedFolder = (data, folders) => {
  const { folder, dirType } = data;
  const dirId = dirType === "case" ? `case-${folder}` : `folder-${folder}`;
  const mappedFolders = folders.map((folderData) => {
    const runDirId =
      folderData.caseOrFolder === "case"
        ? `case-${folderData.dirId}`
        : `folder-${folderData.dirId}`;
    if (runDirId === dirId) {
      return {
        ...folderData,
        active: true,
        selected: true,
      };
    } else {
      if (folderData.parentDir) {
        return {
          ...folderData,
          active: false,
          selected: false,
        };
      } else {
        return {
          ...folderData,
          active: false,
          selected: true,
        };
      }
    }
  });

  return mappedFolders;
};

export const setSelectedChildFolder = (data, folders) => {
  const { folder, parentFolder, dirType } = data;
  const dirId = dirType === "case" ? `case-${folder}` : `folder-${folder}`;
  const openDirArray = loadParentDirArray(
    folder,
    dirType,
    parentFolder,
    folders
  );
  const mappedFolders = folders.map((folderData) => {
    if (openDirArray.includes(folderData.pk) && folderData.pk === dirId) {
      return {
        ...folderData,
        active: true,
        selected: true,
      };
    } else {
      if (openDirArray.includes(folderData.pk) && folderData.pk !== dirId) {
        return {
          ...folderData,
          active: false,
          selected: true,
        };
      } else {
        return {
          ...folderData,
          active: false,
          selected: false,
        };
      }
    }
  });

  return mappedFolders;
};

export const setSelectedListChildFolder = (data, folders) => {
  const { folder, parentFolder, dirType } = data;
  const dirId = dirType === "case" ? `case-${folder}` : `folder-${folder}`;
  const parentDirId = `folder-${parentFolder}`;
  const mappedFolders = folders.map((folderData) => {
    const runDirId =
      folderData.caseOrFolder === "case"
        ? `case-${folderData.dirId}`
        : `folder-${folderData.dirId}`;
    if (runDirId === dirId) {
      return {
        ...folderData,
        active: true,
      };
    } else {
      if (folderData.parentDir) {
        if (runDirId === parentDirId) {
          return {
            ...folderData,
            active: false,
          };
        } else {
          return {
            ...folderData,
            active: false,
          };
        }
      } else {
        return {
          ...folderData,
          active: false,
        };
      }
    }
  });

  return mappedFolders;
};

export const setSelectedListFolder = (data, folders) => {
  const { folder, dirType } = data;
  const dirId = dirType === "case" ? `case-${folder}` : `folder-${folder}`;
  const mappedFolders = folders.map((folderData) => {
    const runDirId =
      folderData.caseOrFolder === "case"
        ? `case-${folderData.dirId}`
        : `folder-${folderData.dirId}`;
    if (runDirId === dirId) {
      return {
        ...folderData,
        active: true,
      };
    } else {
      if (folder.parentDir) {
        return {
          ...folderData,
          active: false,
        };
      } else {
        return {
          ...folderData,
          active: true,
        };
      }
    }
  });

  return mappedFolders;
};

export const setOpenFolderName = (folderListId, folders) => {
  const selectedFolder = folders.filter(
    (folder) => folder.listId === folderListId
  );
  return selectedFolder[0]["dirName"];
};

export const setOpenSlideName = (slideListId, foldersList) => {
  const selectedFolderListData = foldersList.filter(
    (list) => list.dirDataId === slideListId
  );
  return selectedFolderListData[0]["dirDataName"];
};

export const setDescription = (folderListId, folders) => {
  const selectedFolder = folders.filter(
    (folder) => folder.listId === folderListId
  );
  return selectedFolder[0]["description"];
};

export const updateFileData = (files, fileIndex = -1, data) => {
  if (!files[fileIndex]) return files;
  if (data["progress"]) {
    const seconds = Math.floor(Date.now() - files[fileIndex].uploadedAt) / 1000;
    const speed = bytesToSize(
      Math.floor((files[fileIndex].size * data["progress"]) / 100) / seconds
    );
    data.speed = `${speed}/s`;
  }
  for (let key in data) {
    files[fileIndex][key] = data[key];
  }
  return [...files];
};

export const removeFile = (files, fileIndex = -1) => {
  if (!files[fileIndex]) return files;
  // files.splice(fileIndex, 1)
  files[fileIndex] = undefined;
  return [...files];
};

export const setRenameFolderName = (dirId, newDirName, dirs, dirType) => {
  const dirList = dirs.map((dir) => {
    if (dir.dirId === dirId && dir.caseOrFolder === dirType) {
      return {
        ...dir,
        dirName: newDirName,
      };
    } else {
      return dir;
    }
  });
  return dirList;
};

export const setRenameListFolderName = (dirId, newDirName, lists) => {
  const dirList = lists.map((list) => {
    if (list.dirDataId === dirId) {
      return {
        ...list,
        dirDataName: newDirName,
      };
    } else {
      return list;
    }
  });
  return dirList;
};

export const setDeleteFolder = (dirId, dirs) => {
  const dirList = dirs.filter((dir) => dir.dirId !== dirId);
  return dirList;
};

export const setDirDataListFolder = (dirDataId, dirDataList) => {
  const dirList = dirDataList.filter((dir) => dir.dirDataId !== dirDataId);
  return dirList;
};

export const updateDirDetails = (dirData, dirDataList) => {
  let { dirId, dirDescription, dirType, notes, stainType } = dirData;
  const dirList = dirDataList.map((data) => {
    if (data.dirDataId === dirId && data.caseOrFolder === dirType) {
      if (dirDescription) {
        return {
          ...data,
          description: dirDescription,
        };
      }
      if (notes) {
        return {
          ...data,
          notes: notes,
        };
      }
      if (stainType) {
        return {
          ...data,
          stainType: stainType,
        };
      }
    } else {
      return data;
    }
  });
  return dirList;
};

export const setUpdateFolder = (dirData, folderData) => {
  let { selectedDir, moveDirId, selectedDirType, moveDirType } = dirData;
  const folders = folderData.map((data) => {
    if (
      data.dirId === selectedDir &&
      data.caseOrFolder === selectedDirType &&
      moveDirType !== "slide"
    ) {
      return {
        ...data,
        parentDir: moveDirId,
        pkParentDir: `${moveDirType}-${moveDirId}`,
      };
    } else {
      return data;
    }
  });
  return folders;
};

export const setUpdateMoveFolder = (dirData, moveFolderData) => {
  let {
    selectedDir,
    destinationDirId,
    selectedDirType,
    destinationDirType,
  } = dirData;
  const moveFolders = moveFolderData.map((data) => {
    if (
      data.dirId === selectedDir &&
      data.caseOrFolder === selectedDirType &&
      moveDirType !== "slide"
    ) {
      return {
        ...data,
        parentDir: destinationDirId,
        pkParentDir: `${destinationDirType}-${destinationDirId}`,
      };
    } else {
      return data;
    }
  });
  return moveFolders;
};

export const removeCollaborators = (userId, selectedDirCollaborators) => {
  const updateDirCollaborators = selectedDirCollaborators.filter(
    (data) => data.userID !== userId
  );
  return updateDirCollaborators;
};

export const updateCaseAssignUser = (dirId, dirList) => {
  const updateDirList = dirList.map((data) => {
    if (data.dirDataId === dirId && data.caseOrFolder === "case") {
      return {
        ...data,
        assignTo: null,
      };
    } else {
      return data;
    }
  });
  return updateDirList;
};

export const setCaseAssignUser = (userData, dirId, dirList) => {
  const updateDirList = dirList.map((data) => {
    if (data.dirDataId === dirId && data.caseOrFolder === "case") {
      return {
        ...data,
        assignTo: [
          {
            first_name: userData[0].firstName,
            last_name: userData[0].lastName,
            role_id: userData[0].roleId,
            role_name: userData[0].roleName,
            user_email: userData[0].userEmail,
            user_image_src:
              typeof userData[0].userImageSrc !== "undefined"
                ? userData[0].userImageSrc
                : null,
          },
        ],
      };
    } else {
      return data;
    }
  });
  return updateDirList;
};
