import React, { useState, useEffect } from "react";
import { procedureNames } from "../../../../config/constants";
import FilterIcon from "../../../assets/icons/FilterIcon.svg";
import CalenderIcon from "../../../assets/icons/calender-icon.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DropdownIcon from "../../../assets/icons/select-dropdown-icon.svg";
import CloseIcon from "../../../assets/icons/CloseIcon.svg";
import { Overlay, PrimaryButton, SecondaryButton } from "../../../components";
import { DatePicker } from "antd";
import {
  setSearchParams,
  createNewWorklistAction,
  getMyWorklistsAction,
  setCurrentTab,
} from "../actions/slidebox-action";
import {
  getAllPhysiciansAction,
  getAllProceduresAction,
} from "../../adminDashboard/actions/adminDashboard-action";
import { connect } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
// import { getSearchDirData } from '../actions/slidebox-action';

import { FeatureGate } from "react-feature-gate";
import { addDays, subDays } from "date-fns";
import moment from "moment";
import CreateWorklist from "./CreateWorklist";

const { RangePicker } = DatePicker;

const dateDurationValues = [
  { id: 1, value: "-", label: "None" },
  { id: 1, value: "today", label: "Today" },
  { id: 2, value: "yesterday", label: "Yesterday" },
  { id: 3, value: "lastThreeDays", label: "Last 3 Days" },
  { id: 4, value: "lastWeek", label: "Last Week" },
  { id: 5, value: "tomorrow", label: "Tomorrow" },
  { id: 6, value: "nextThreeDays", label: "Next 3 Days" },
  { id: 7, value: "nextSevenDays", label: "Next 7 Days" },
];

export const SlideboxFilters = ({
  memberDetails,
  getSearchDirData,
  search,
  setSearchParams,
  licenseTypeFeatures,
  setIsSearchTabActive,
  showAdvancedSearchModal,
  setShowAdvancedSearchModal,
  orderingPhysicians,
  getAllPhysicians,
  loggedUserDetails,
  workgroupData,
  createWorklistAction,
  getMyWorklists,
  setCurrentTab,
  getAllProcedures,
  proceduresList,
}) => {
  let mediaQuery;

  const [createWorklist, setCreateWorklist] = useState(false);

  const [width, setWidth] = useState(115);
  const [assignedTo, setAssignedTo] = useState("");
  const [caseStatus, setCaseStatus] = useState("");
  const [casePriority, setCasePriority] = useState("");
  const [firstName, setFirstName] = useState("");
  const [mrnNumber, setMrnNumber] = useState("");
  const [accessionNumber, setAccessionNumber] = useState("");
  const [procedureName, setProcedureName] = useState("");
  const [dateRangeSelect, setDateRangeSelect] = useState("-");
  const [orderingPhysician, setOrderingPhysician] = useState("");
  const [procedureDate, setProcedureDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [labels, setLabels] = useState("");
  const [referredTo, setReferredTo] = useState("");
  const [proceduresOptions, setProceduresOptions] = useState([]);

  const clearFields = () => {
    setAssignedTo("");
    setCaseStatus("");
    setCasePriority("");
    setFirstName("");
    setMrnNumber("");
    setAccessionNumber("");
    setProcedureName("");
    setDateRangeSelect("-");
    setOrderingPhysician("");
    setProcedureDate({});
    setLabels("");
    setReferredTo("");
  };

  const caseStatusValues = [
    { id: 1, value: "Ordered", label: "Ordered" },
    { id: 2, value: "Cancelled", label: "Cancelled" },
    { id: 3, value: "Arrived", label: "Arrived" },
    { id: 4, value: "Verified", label: "Verified" },
    { id: 5, value: "Transcribed", label: "Transcribed" },
    { id: 6, value: "Prelim Report", label: "Prelim Report" },
    { id: 7, value: "Reported", label: "Reported" },
  ];

  const casePriorityValues = [
    { value: "standard", label: "Standard" },
    { value: "rush", label: "Rush" },
    { value: "stat", label: "Stat" },
  ];

  const activeMembers = memberDetails.filter((members) => {
    return members.status === "active";
  });

  const filteredAssignToMembers = activeMembers.map((members) => {
    const fullName = members.firstName + " " + members.lastName;
    const displayName =
      fullName.length > 18 ? fullName.substring(0, 18) + "..." : fullName;
    return {
      value: members.userID,
      label: displayName,
      userId: members.userID,
    };
  });

  const getSearchParamsObj = () => {
    const searchParams = {
      search: search,
      mrnNumber: mrnNumber ? mrnNumber : "",
      accessionNumber: accessionNumber ? accessionNumber : "",
      patientName: firstName ? firstName : "",
      orderingPhysicianId: orderingPhysician ? orderingPhysician : "",
      procedureName: procedureName !== "none" ? procedureName : "",
      priority: casePriority !== "none" ? casePriority : "",
      caseStatus: caseStatus !== "none" ? caseStatus : "",
      startDate:
        dateRangeSelect !== "-"
          ? dateRangeSelect
          : procedureDate.startDate !== ""
          ? moment(procedureDate.startDate).format("yyyy-MM-DD")
          : "",
      endDate:
        dateRangeSelect !== "-"
          ? dateRangeSelect
          : procedureDate.endDate !== ""
          ? moment(procedureDate.endDate).format("yyyy-MM-DD")
          : "",
      assignedTo: assignedTo !== "none" ? assignedTo : "",
      referredTo: referredTo !== "none" ? referredTo : "",
      labels: labels ? labels : "",
    };
    return searchParams;
  };

  const handleApply = () => {
    const searchParams = getSearchParamsObj();
    getSearchDirData(searchParams);
    setSearchParams(searchParams);
    setShowAdvancedSearchModal(false);
    setIsSearchTabActive(true);
    clearFields();
  };

  const handleSetAsWorklist = () => {
    const searchParams = getSearchParamsObj();
    const isEmpty = Object.values(searchParams).every((value) => value === "");
    if (isEmpty) return;
    setShowAdvancedSearchModal(false);
    setCreateWorklist(true);
    setSearchParams(searchParams);
    clearFields();
  };

  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `All`;
    } else if (value && value.some((o) => o.value !== "*")) {
      return `${value ? value.map((item) => item.label) : ""}`;
    } else {
      return `${placeholderButtonLabel}`;
    }
  };

  function handleChangeValues(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }

  function handleChangeOption(value, event) {
    this.setState(value);
  }

  const handleSelectOption = (e, setState) => {
    setState(e.target.value);
  };

  const handleChangeProcedureName = (e) => {
    setProcedureName(e.target.value);
  };

  const customStyles = {
    menu: (provided, state) => ({
      width: 200,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      fontSize: 14,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    }),

    optionContainer: (provided, state) => ({
      marginLeft: 10,
      marginTop: 15,
      width: "100%",
    }),
    option: (provided, state) => ({
      width: "100%",
      padding: 5,
      paddingLeft: 10,
    }),
  };

  useEffect(() => {
    async function fetchProcedures() {
      await getAllProcedures();
      setProceduresOptions(
        proceduresList &&
          proceduresList
            .filter((procedure) => procedure.status === "active")
            .map((procedure) => {
              console.log(procedure.procedureName);
              return {
                label: procedure.procedureName,
                value: procedure.procedureId,
              };
            })
      );
    }
    fetchProcedures();
  }, []);

  useEffect(() => {
    getAllPhysicians();
  }, []);

  useEffect(() => {
    mediaQuery = window.matchMedia("(min-width: 1610px)");
    if (mediaQuery.matches) {
      setWidth(190);
    } else {
      setWidth(115);
    }
  }, [mediaQuery, width]);

  return (
    <React.Fragment>
      <button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={() => {
          setShowAdvancedSearchModal((prevState) => !prevState);
        }}
        style={{
          backgroundColor: "transparent",
          borderRadius: "5px",
          outline: "none",
          position: "absolute",
          right: 10,
          height: 15,
          top: 8,
          border: "none",
          display: "flex",
        }}
      >
        <img
          src={FilterIcon}
          alt="filter"
          style={{
            height: "15px",
            width: "15px",
          }}
        />
      </button>

      {showAdvancedSearchModal && (
        <React.Fragment>
          <Overlay />
          <div
            className="advanced-search-form-container search-filter prompt"
            data-test="search-and-filter-modal"
          >
            <div className="advanced-search-title">
              <p>Advanced Search</p>
              <img
                className="close-icon"
                src={CloseIcon}
                alt="close"
                onClick={() => setShowAdvancedSearchModal(false)}
              />
            </div>
            <div className="advanced-search-form" style={{ height: "100%" }}>
              <div className="worklist-criteria">
                <div>
                  <FeatureGate feature="patient_level_information">
                    <label htmlFor="mrn-number">MRN</label>
                    <input
                      type="text"
                      className="form-item"
                      value={mrnNumber}
                      name="mrn-number"
                      onChange={(e) => {
                        setMrnNumber(e.target.value);
                      }}
                    />
                  </FeatureGate>
                </div>
                <div>
                  <label htmlFor="accession-number">Accession Number</label>
                  <input
                    type="text"
                    className="form-item"
                    name="accession-number"
                    value={accessionNumber}
                    onChange={(e) => {
                      setAccessionNumber(e.target.value);
                    }}
                  />
                </div>
                <div className="patient-name-container">
                  <FeatureGate feature="patient_level_information">
                    <label htmlFor="patient-name">Patient Name</label>
                    <input
                      type="text"
                      className="form-item patient-name"
                      name="patient-name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </FeatureGate>
                </div>

                <FormControl className="single-selection">
                  <label>Ordering Physician</label>
                  <div>
                    <select
                      className="form-select"
                      value={orderingPhysician}
                      onChange={(e) =>
                        setOrderingPhysician(parseInt(e.target.value))
                      }
                    >
                      <option aria-label="None" value=""></option>
                      {orderingPhysicians &&
                        orderingPhysicians.map((physician, index) => (
                          <option
                            key={index}
                            value={physician.orderingPhysicianId}
                          >{`${physician.orderingPhysicianFirstName} ${
                            physician.orderingPhysicianMiddleName
                              ? physician.orderingPhysicianMiddleName + " "
                              : ""
                          }${physician.orderingPhysicianLastName}`}</option>
                        ))}
                    </select>
                    <div className="dropdown-icon">
                      <img
                        src={DropdownIcon}
                        alt="dropdown icon"
                        className="dropdown-suffix"
                      />
                    </div>
                  </div>
                </FormControl>

                <FormControl className="single-selection">
                  <label>Procedure Name</label>
                  <div>
                    <select
                      className="form-select"
                      value={procedureName}
                      onChange={handleChangeProcedureName}
                    >
                      <option aria-label="None" value=""></option>
                      {proceduresOptions.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                    <div className="dropdown-icon">
                      <img
                        src={DropdownIcon}
                        alt="dropdown icon"
                        className="dropdown-suffix"
                      />
                    </div>
                  </div>
                </FormControl>
                <FormControl className="single-selection">
                  <label>Assigned To</label>
                  <div>
                    <select
                      className="form-select"
                      value={assignedTo}
                      onChange={(e) => handleSelectOption(e, setAssignedTo)}
                    >
                      <option aria-label="None" value=""></option>
                      {filteredAssignToMembers.map((item, index) => (
                        <option key={index} value={item.userId}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                    <div className="dropdown-icon">
                      <img
                        src={DropdownIcon}
                        alt="dropdown icon"
                        className="dropdown-suffix"
                      />
                    </div>
                  </div>
                </FormControl>
                <FormControl className="single-selection">
                  <label>Referred To</label>
                  <div>
                    <select
                      className="form-select"
                      value={referredTo}
                      onChange={(e) => handleSelectOption(e, setReferredTo)}
                    >
                      <option aria-label="None" value=""></option>
                      {filteredAssignToMembers.map((item, index) => (
                        <option key={index} value={item.userId}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                    <div className="dropdown-icon">
                      <img
                        src={DropdownIcon}
                        alt="dropdown icon"
                        className="dropdown-suffix"
                      />
                    </div>
                  </div>
                </FormControl>
                <FormControl className="single-selection">
                  <label>Select Date Range</label>
                  <div>
                    <select
                      className="form-select"
                      value={dateRangeSelect}
                      onChange={(e) => setDateRangeSelect(e.target.value)}
                    >
                      {dateDurationValues.map((dateDurationValue, index) => (
                        <option key={index} value={dateDurationValue.value}>
                          {dateDurationValue.label}
                        </option>
                      ))}
                    </select>
                    <div className="dropdown-icon">
                      <img
                        src={DropdownIcon}
                        alt="dropdown icon"
                        className="dropdown-suffix"
                      />
                    </div>
                  </div>
                </FormControl>
                {dateRangeSelect === "-" ? (
                  <React.Fragment>
                    <FormControl className="single-selection">
                      <label>From Date</label>
                      <input
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        className="form-select"
                        type="date"
                        name="startDate"
                        id="startDate"
                        value={procedureDate.startDate}
                        onChange={(e) =>
                          setProcedureDate({
                            ...procedureDate,
                            startDate: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl className="single-selection to-date">
                      <label>To Date</label>
                      <input
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        className="form-select"
                        type="date"
                        name="endDate"
                        id="endDate"
                        value={procedureDate.endDate}
                        onChange={(e) =>
                          setProcedureDate({
                            ...procedureDate,
                            endDate: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </React.Fragment>
                ) : null}
                {/* <div className="status-and-priority"> */}
                <FormControl className="single-selection final-row">
                  <label>Case Status</label>
                  <div>
                    <select
                      className="form-select"
                      value={caseStatus}
                      onChange={(e) => handleSelectOption(e, setCaseStatus)}
                    >
                      <option aria-label="None" value=""></option>
                      {caseStatusValues.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                    <div className="dropdown-icon">
                      <img
                        src={DropdownIcon}
                        alt="dropdown icon"
                        className="dropdown-suffix"
                      />
                    </div>
                  </div>
                </FormControl>
                <FormControl className="single-selection final-row">
                  <label>Case Priority</label>
                  <div>
                    <select
                      className="form-select"
                      value={casePriority}
                      onChange={(e) => handleSelectOption(e, setCasePriority)}
                    >
                      <option aria-label="None" value=""></option>
                      {casePriorityValues.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                    <div className="dropdown-icon">
                      <img
                        src={DropdownIcon}
                        alt="dropdown icon"
                        className="dropdown-suffix"
                      />
                    </div>
                  </div>
                </FormControl>
                {/* </div> */}
                <FormControl className="single-selection final-row">
                  <label>Labels</label>
                  <input
                    type="text"
                    className="form-item"
                    value={labels}
                    onChange={(e) => setLabels(e.target.value)}
                  />
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "11px",
                  justifyContent: "flex-end",
                }}
              >
                <SecondaryButton
                  style={{
                    width: "194px",
                    height: "39px",
                    padding: "0 5px",
                    borderRadius: "10px",
                  }}
                  onClick={handleSetAsWorklist}
                  data-test="set-new-worklist-btn"
                  label="Set as New Worklist"
                />
                <PrimaryButton
                  style={{
                    position: "relative",
                    height: "39px",
                    width: "130px",
                    padding: "0 40px",
                    marginTop: "5px",
                    borderRadius: "10px",
                  }}
                  data-test="search-btn"
                  label="Search"
                  onClick={handleApply}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {createWorklist && (
        <CreateWorklist
          {...{
            createWorklistAction,
            setCreateWorklist,
            getMyWorklists,
            role: loggedUserDetails.roleName,
            workgroupData,
            setIsSearchTabActive: () => {},
            setCurrentTab,
          }}
        />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  roleName: state.Global.loggedUserDetails.roleName,
  licenseTypeFeatures: state.Global.loggedUserDetails.licenseTypeAccessData,
  orderingPhysicians: state.Dashboard.orderingPhysicians,
  loggedUserDetails: state.Global.loggedUserDetails,
  workgroupData: state.Dashboard.workgroupData,
  proceduresList: state.Dashboard.proceduresList,
  // memberDetails: state.SlideBox.userList,
});
const mapDispatchToProps = (dispatch) => ({
  setSearchParams: (payload) => dispatch(setSearchParams(payload)),
  getAllPhysicians: () => dispatch(getAllPhysiciansAction()),
  createWorklistAction: (payload) => dispatch(createNewWorklistAction(payload)),
  getMyWorklists: (payload) => dispatch(getMyWorklistsAction(payload)),
  setCurrentTab: (payload) => dispatch(setCurrentTab(payload)),
  getAllProcedures: () => dispatch(getAllProceduresAction()),
  // getSearchDirData: (payload) => dispatch(getSearchDirData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlideboxFilters);
