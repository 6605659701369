import React, { useState} from "react";
import { FadeIn } from "animate-components";
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Notify,
} from "../../../components";
import { Select } from "antd";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { connect } from "react-redux";

const customStyles = {
  menu: (provided, state) => ({
    width: 265,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontSize: 14,
    fontFamily: `"Roboto", sans-serif`,
  }),

  optionContainer: (provided, state) => ({
    marginLeft: 10,
    marginTop: 15,
    width: "100%",
  }),
  option: (provided, state) => ({
    width: "100%",
    padding: 5,
    paddingLeft: 10,
  }),
};

const CreateWorklist = ({
  setCreateWorklist,
  createWorklistAction,
  searchParams,
  role,

  workgroupData,
  setIsSearchTabActive,
}) => {
  const [colorOverlay, setColorOverlay] = useState(false);
  const [worklistName, setWorklistName] = useState("");
  const [color, setColor] = useState("#ECE7F8");
  const [workgroups, setWorkgroups] = useState([]);
  const [worklistType, setWorklistType] = useState("personal");
  const colorOptions = [
    { id: 1, value: "rgba(247, 65, 45, 0.5)" },
    { id: 2, value: "rgba(255, 58, 41, 0.9)" },
    { id: 3, value: "rgba(157, 27, 178, 0.51)" },
    { id: 4, value: "rgba(68, 190, 141, 0.56)" },
    { id: 5, value: "rgba(62, 78, 184, 0.51)" },
    { id: 6, value: "rgba(17, 148, 246, 0.54)" },
    { id: 7, value: "rgba(86, 87, 89, 0.49)" },
    { id: 8, value: "rgba(77, 115, 168, 0.43)" },
    { id: 9, value: "#009788" },
    { id: 10, value: "#FF8364" },
    { id: 11, value: "#89C540" },
    { id: 12, value: "#ECE7F8" },
  ];

  const handleColorChange = (item) => {
    setColor(item.value);
    setColorOverlay(false);
  };

  const handleCreate = () => {
    if (worklistName === "") {
      Notify({ value: "Worklist name is required" });
      return;
    } else {
      createWorklistAction({
        worklistName: worklistName ? worklistName : "",
        worklistColor: color ? color : "",
        searchResults: searchParams ? searchParams : "",
        worklistType,
        workgroupIds: workgroups.map((workgroup) => workgroup.id),
      });
      setCreateWorklist(false);
      setIsSearchTabActive(false);
    }
  };

  const workgroupList = workgroupData.map((workgroup) => {
    return {
      id: workgroup.workgroupId,
      value: workgroup.workgroupName,
      label: workgroup.workgroupName,
    };
  });

  const handleChangeWorklistType = (value) => {
    setWorklistType(value);
  };

  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `All`;
    } else if (value && value.some((o) => o.value !== "*")) {
      return `${value ? value.map((item) => item.label) : ""}`;
    } else {
      return `${placeholderButtonLabel}`;
    }
  };

  function handleChangeValues(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(value);
    } else {
      this.setState(value);
    }
  }
  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt create-worklist-prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            img=""
            alt="#"
            title="Create Worklist"
            onClick={() => setCreateWorklist(false)}
            id="modalHeader"
          />
          <div className="create-worklist-modal_middle">
            <div className="worklist-name-container">
              <label htmlFor="worklist-name">Enter Worklist Name</label>
              <div className="worklist-input-container">
                <input
                  type="text"
                  id="worklist-name"
                  value={worklistName}
                  onChange={(e) => {
                    setWorklistName(e.target.value);
                  }}
                />
                <div className="color-container">
                  <div
                    className="current-selection"
                    style={{ backgroundColor: color }}
                    onClick={() => {
                      setColorOverlay(!colorOverlay);
                    }}
                  ></div>
                  {colorOverlay && (
                    <div className="color-overlay-container">
                      {colorOptions.map((item) => (
                        <div
                          className="color-item"
                          style={{ backgroundColor: item.value }}
                          onClick={() => {
                            handleColorChange(item);
                          }}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {role === "System Administrator" && (
              <React.Fragment>
                <div
                  className="worklist-type-container"
                  style={{ width: "100%" }}
                >
                  <label htmlFor="worklist-name">Worklist Type</label>

                  <Select
                    placeholder="Select Worklist Type"
                    style={{ width: 265 }}
                    value={worklistType}
                    onChange={handleChangeWorklistType}
                  >
                    <Select.Option value={"personal"}>My Worklist</Select.Option>
                    <Select.Option value={"system"}>System</Select.Option>
                  </Select>
                </div>
                <div
                  className={
                    worklistType === "personal"
                      ? `permission-container disabled`
                      : "permission-container"
                  }
                >
                  <label htmlFor={"worklist-permission"}>
                    Worklist Permission
                  </label>

                  <ReactMultiSelectCheckboxes
                    options={
                      worklistType === "personal" ? [] : [...workgroupList]
                    }
                    placeholderButtonLabel="Assign workgroups"
                    getDropdownButtonLabel={getDropdownButtonLabel}
                    value={workgroups}
                    onChange={handleChangeValues}
                    setState={setWorkgroups}
                    styles={customStyles}
                    hideSearch={true}
                    className={
                      worklistType === "personal"
                        ? "worklist-permission-dropdown disabled"
                        : "worklist-permission-dropdown"
                    }
                  />
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="prompt-bottom">
            <SecondaryButton
              label="Cancel"
              onClick={() => {
                setCreateWorklist(false);
              }}
            />
            <PrimaryButton
              label="Create"
              onClick={() => {
                handleCreate();
              }}
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  searchParams: state.SlideBox.searchParams,
})

export default connect(mapStateToProps, null)(CreateWorklist);
