import React, { useState, useRef, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { Notify } from '../src/components';
import { FadeIn } from 'animate-components';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import * as slideBoxActions from '../src/pages/slidebox/actions/slidebox-action';
import RenameIcon from '../src/assets/icons/remane.png';
import CreateIcon from '../src/assets/icons/createIcon.svg';
import StandardIcon from '../src/assets/icons/standard.svg';
import RushIcon from '../src/assets/icons/rush.svg';
import StatIcon from '../src/assets/icons/stat.svg';
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from '../src/components';

// To create folder/case and to rename folders/cases/slides/documents
const CreateDirectory = ({
  dirType,
  isFile,
  onClose,
  selectedDir,
  rename = false,
  createSlideBoxDirectory,
  renameSlideBoxDirectory,
  loadSlideboxDirDataList,
  selectedFolder,
  selectedFolderType,
}) => {
  const [caseName, toggleCaseName] = rename
    ? useState(selectedDir.name)
    : useState('');
  const [nameError, setNameError] = useState(true);
  const placeholder = `Enter ${dirType} name`;
  const [caseAccession, setCaseAccession] = useState('');
  const [priority, setPriority] = useState(null);

  const priorityOptions = [
    {
      value: 1,
      label: (
        <React.Fragment>
          <img src={StandardIcon} alt="" className="label-icon" />
          <span className="label-span">STANDARD</span>
        </React.Fragment>
      ),
    },
    {
      value: 2,
      label: (
        <React.Fragment>
          <img src={RushIcon} alt="" className="label-icon" />
          <span className="label-span">RUSH</span>
        </React.Fragment>
      ),
    },
    {
      value: 3,
      label: (
        <React.Fragment>
          <img src={StatIcon} alt="" className="label-icon" />
          <span className="label-span">STAT</span>
        </React.Fragment>
      ),
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      document.getElementsByTagName('input')[0].focus();
      document.getElementsByTagName('input')[0].select();
    }, 1);
  }, []);

  const handleSaveClick = () => {
    if (caseName.trim() === '') {
      return;
    }
    if (!rename) {
      createSlideBoxDirectory(
        dirType,
        {
          dirName: caseName,
          parentDir: selectedDir,
          parentDirType: selectedFolderType,
          accessionNumber: caseAccession,
          priority: priority,
        },
        {
          onSuccess: afterDirCreateSuccess,
          onError: afterDirCreateFail,
        }
      );
    } else {
      renameSlideBoxDirectory(
        dirType,
        {
          dirName: caseName,
          dirId: selectedDir.slideItemId,
        },
        {
          onSuccess: afterDirRenameSuccess,
          onError: afterDirRenameFail,
        }
      );
    }
  };

  const checkNameError = (name) => {
    toggleCaseName(name);
    if (name === '') {
      setNameError(true);
    }
    const regex = /^[A-Za-z0-9]/;
    if (regex.test(name)) {
      setNameError(false);
    }
    if (!regex.test(name) && name !== '') {
      Notify({
        value: `${capitalizeFirstLetter(
          dirType
        )} name should not contain special character`,
      });
      setNameError(true);
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const afterDirCreateSuccess = () => {
    loadSlideboxDirDataList({
      dirId: selectedFolder,
      dirType: selectedFolderType,
    });
    Notify({ value: `${capitalizeFirstLetter(dirType)} created successfully` });
    onClose();
  };

  const afterDirCreateFail = (msg) => {
    Notify({ value: `${capitalizeFirstLetter(dirType)} ${msg}` });
    onClose();
  };

  const afterDirRenameSuccess = () => {
    Notify({ value: `${capitalizeFirstLetter(dirType)} renamed successfully` });
    onClose();
  };

  const afterDirRenameFail = (msg) => {
    Notify({ value: `${capitalizeFirstLetter(dirType)} ${msg}` });
    onClose();
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            img={rename ? `${RenameIcon}` : `${CreateIcon}`}
            alt={'remane'}
            title={
              rename
                ? isFile
                  ? 'Rename File'
                  : `Rename ${dirType}`
                : `Create new ${dirType}`
            }
            onClick={() => onClose(false)}
            id="modalHeader"
          />
          <div className="modal_middle">
            <div>
              <TextField
                disableUnderline={true}
                id="CaseNameTextField"
                InputProps={{
                  style: { fontSize: '14px' },
                  disableUnderline: true,
                }}
                value={caseName}
                style={{
                  width: '100%',
                  padding: '8px 0px',
                  fontFamily: "'Roboto', sans-serif",
                }}
                onChange={(e) => checkNameError(e.target.value)}
                autoFocus={true}
                placeholder={placeholder}
              />
            </div>
            <div>
              <TextField
                disableUnderline={true}
                id="caseAccessionTextField"
                InputProps={{
                  style: { fontSize: '14px' },
                  disableUnderline: true,
                }}
                value={caseAccession}
                style={{
                  width: '100%',
                  padding: '8px 0px',
                  fontFamily: "'Roboto', sans-serif",
                }}
                onChange={(e) => setCaseAccession(e.target.value)}
                autoFocus={true}
                placeholder="Enter Case Accession #(optional)"
              />
            </div>
            <div className="form-group">
              <Select
                options={priorityOptions}
                classname="stain-select"
                placeholder="Select Case Priority"
                customStyles={true}
                isSearchable={false}
                style={{
                  width: '100%',
                  height: '30px',
                  input: { fontSize: '14px' },
                  placeholder: { fontSize: '14px' },
                  color: '#9e9ea6',
                  focus: 'disabled',
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={({ value }) => setPriority(value)}
              />
            </div>
          </div>
          <div className="prompt-bottom" style={{ marginTop: 0 }}>
            <SecondaryButton label="Cancel" onClick={() => onClose(false)} />
            <PrimaryButton
              id="savePrimaryButton"
              label="Save"
              onClick={handleSaveClick}
              disabled={nameError || caseName.trim() === ''}
              extraClass="prompt-done"
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...slideBoxActions }, dispatch);

export default connect(null, mapDispatchToProps)(CreateDirectory);
