import React from "react";
import { Link, withRouter } from "react-router-dom";
import { FeatureGate } from "react-feature-gate";
import ToolTip from "react-tooltip";

const routeConfig = [
 {
  url: '/',
  name: 'Worklists',
  tag: 'slidebox img',
 },
 // {

 //   url: "/groups",
 //   name: "Groups",
 //   tag: "groups img",
 // },
 // {
 //   url: "/analysis-dashboard",
 //   name: "Analysis",
 //   tag: "analysis img",
 // },
 {
  url: '/dashboard',
  name: 'Dashboard',
  tag: 'dashboard img',
 },
];

const Route = ({ activeUrl, name, tag, url, collapse }) => {
  return (
    <div
      className={`route ${
        activeUrl === "/analysis"
          ? url.includes(activeUrl)
            ? "active"
            : ""
          : url === activeUrl
          ? "active"
          : ""
      }`}
      id={
        name === "Worklists"
          ? "leftSidebarSlidebox"
          : name === "Dashboard"
          ? "leftSidebarDashboard"
          : ""
      }
    >
      {
        <Link
          to={url}
          className={name}
          data-for="sidebar-collapsed-tooltip"
          data-tip={name}
        >
          <div className={tag}></div>
          <span>{name}</span>
        </Link>
      }
      {collapse && (
        <ToolTip
          id="sidebar-collapsed-tooltip"
          place="right"
          type="dark"
          effect="solid"
        />
      )}
    </div>
  );
};

const LeftSidebar = ({ collapseSidebar, history, userData }) => {
  const path = history.location.pathname.replace(/(?!^)(\W.+)/, "");
  let routeData = routeConfig;

  return (
    <div
      className={`left-sidebar-component sidebar-${
        collapseSidebar ? "collapse" : "open"
      }`}
    >
      <div
        className="fixed-container"
        style={{ width: `${fixedWidth(collapseSidebar)}` }}
      >
        {routeData.map((route, index) => {
          if (route.name === "Analysis") {
            return (
              <FeatureGate feature="ai_analysis">
                <Route
                  key={index}
                  {...route}
                  activeUrl={path}
                  collapse={collapseSidebar}
                />
              </FeatureGate>
            );
          } else {
            return (
              <Route
                key={index}
                {...route}
                activeUrl={path}
                collapse={collapseSidebar}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

const fixedWidth = (collapseSidebar) => {
  if (collapseSidebar) {
    return "71px";
  } else {
    return "150px";
  }
};

export default withRouter(LeftSidebar);
