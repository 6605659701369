import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import EyePngIcon from "../icons/open-eye.png";
import ThumbPrevIcon from "../icons/chevron-left.png";
import ThumbNextIcon from "../icons/chevron-right.png";
import SplitImageAddIcon from "../icons/plus-icon.png";
import ToolTip from "react-tooltip";
import CloseIcon from "../icons/close-new.png";
import LoopIcon from '@material-ui/icons/Loop';
import axios from "../../../utils/axiosInstance";
import { Notify } from "../../../components";
import { loadQuorum } from "../../slidebox/actions/slidebox-action";

export const SlideThumbnail = ({
  slides,
  changeSlide,
  defultKeyFolder,
  loadFrom,
  when,
  highlightSlide,
  groupId,
  isAdmin,
  splitSlide,
  toggleThumbnailSlider,
  loadQuorumData,
  loadSlidesData,
  reloadImages,
}) => {
  const [productListSteps, setproductListSteps] = useState(0);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [productAmount, setproductAmount] = useState(0);
  let productListWidth = 0;
  const listItemsRef = [];
  useEffect(() => {
    const productList = document.querySelector(".js-product-list");
    let tmpProductAmount = productAmount;
    listItemsRef.forEach((item, index) => {
      const selectedImage = sessionStorage.getItem("selectedSlide")
        ? sessionStorage.getItem("selectedSlide")
        : 0;
      if (selectedImage) {
        if (index === parseInt(selectedImage)) {
          item.style.opacity = "0.5";
        } else {
          item.style.opacity = "1";
        }
      } else if (item.id.trim() === defultKeyFolder.trim()) {
        item.style.opacity = "0.5";
      }

      tmpProductAmount++;
      productListWidth += 240;
      productList.style.width = `${productListWidth}px`;
    });
    setproductAmount(tmpProductAmount + 1);
  }, [slides]);

  const carouselNext = () => {
    const productAmountVisible = Math.floor(
      document.getElementById("viewer-carousel-content").clientWidth / 125
    );
    if (productListSteps < productAmount - productAmountVisible) {
      setproductListSteps(productListSteps + 1);
      moveProductList(productListSteps + 1);
    }
  };

  const carouselPrev = () => {
    if (productListSteps > 0) {
      setproductListSteps(productListSteps - 1);
      moveProductList(productListSteps - 1);
    }
  };

  const moveProductList = (step) => {
    const productList = document.querySelector(".js-product-list");
    productList.style.transform = `translateX(-${125 * step}px)`;
  };

  const refreshSlides = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const folderKey = urlParams.get("image");
    const thumb = urlParams.get("thumb");
    reloadImages(folderKey, thumb);
  }

  const loadClickedSlide = (e, slideData) => {
    e.preventDefault();
    changeSlide(slideData);
  };

  const loadSplittedView = (e, slideData) => {
    e.preventDefault();
    e.stopPropagation();
    splitSlide(slideData);
  };

  return (
    <div
      id="viewer-carousel-content"
      className="carousel custom-viewer-carousel"
    >
      <div className="close-icon-container">
        <img
          src={CloseIcon}
          className="disable-slide-tray-icon"
          onClick={toggleThumbnailSlider}
        />
      </div>
      <div className="refresh-icon-container">
        <LoopIcon
          className="refresh-slide-tray-icon"
          data-test="refresh-icon"
          onClick={refreshSlides}
        />
      </div>

      <div className="carousel__inner-content">
        <span
          className="carousel__control js-carousel-prev"
          onClick={carouselPrev}
        >
          <img src={ThumbPrevIcon} className="icon" />
        </span>
        <span
          href="#"
          className="carousel__control js-carousel-next"
          onClick={carouselNext}
        >
          <img src={ThumbNextIcon} className="icon" />
        </span>
        <ul className="product-list js-product-list">
          {slides.map((slide, index) => (
            <React.Fragment>
              <li
                className="product-list__item"
                id={slide.keyFolder}
                onClick={(e) => loadClickedSlide(e, slide)}
                ref={(listItem) => (listItemsRef[index] = listItem)}
                key={index}
              >
                {defultKeyFolder !== slide.keyFolder && (
                  <div
                    className={"add-to-split-viewer"}
                    onClick={(e) => loadSplittedView(e, slide)}
                  >
                    <img src={SplitImageAddIcon} />
                  </div>
                )}
                <div data-slide={index + 1} className="product">
                  <a className="slide-thumbnail-link">
                    <img
                      className={`slide-thumbnail-img ${
                        highlightSlide && highlightSlide == slide.slideId
                          ? "highlight-slide"
                          : ""
                      }`}
                      src={slide.thumbnail}
                    />
                    <div className="slide-thumbnail-overlay">
                      <img className="overlay-icon" src={EyePngIcon} />
                    </div>
                  </a>
                  <span className="slide-stain">
                    {slide.slideStain ? slide.slideStain : " "}
                  </span>
                  <span
                    data-tip={slide.slideName ? slide.slideName : ""}
                    className="slide-name"
                    data-for="thumbnail-toolbar"
                  >
                    {`${
                      slide.slideName && when && when !== "groups"
                        ? slide.slideName.substring(0, 10) + "..."
                        : ""
                    }`}
                  </span>
                </div>
              </li>
            </React.Fragment>
          ))}
        </ul>
        <ToolTip
          id="thumbnail-toolbar"
          place="top"
          type="info"
          effect="solid"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  loadQuorumData: (payload) => dispatch(loadQuorum(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlideThumbnail);
