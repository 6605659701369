import React from "react";
import { Switch, Route } from "react-router-dom";
import SlideBoxPage from "./pages/slidebox/SlideBox";
import SlideBoxUpload from "./pages/slidebox/SlideBox-upload";
// import ImageViewer from './pages/imageViewer/ImageViewer';
import ImageViewer from "./pages/slideSplitViewer/viewer-container";

import { LeftSidebar } from "./components";
import AdminDashboard from "./pages/adminDashboard/AdminDashboard";
import Groups from "./pages/groups/Groups";
import Notifications from "./pages/notifications/components/Notifications";
import AIAnalytics from "./pages/aiAnalytics/AiAnalytics";
import AnalysisDashboard from "./pages/aiAnalytics/AnalysisDashboard";
import PreviousRuns from "./pages/aiAnalytics/components/PreviousRuns";

const AppRoutes = ({
  collapseSidebar,
  isLoggedUser,
  userData,
  selectedFolderData,
  features,
}) => {
  return (
    <div className="main-page-container">
      {isLoggedUser && (
        <React.Fragment>
          <LeftSidebar collapseSidebar={collapseSidebar} userData={userData} />
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <div style={{ flex: 1, overflow: "hidden" }}>
                  <SlideBoxPage {...props} showUsageBar={true} />
                </div>
              )}
            />
            <Route
              exact
              path={
                selectedFolderData.selectedFolder !== 1
                  ? "/slidebox/upload"
                  : "/"
              }
              render={(props) => {
                return selectedFolderData.selectedFolder !== 1 ? (
                  <SlideBoxUpload {...props} />
                ) : (
                  <SlideBoxPage {...props} showUsageBar={true} />
                );
              }}
            />
            <Route
              path="/viewer"
              render={(props) => (
                <ImageViewer
                  {...props}
                  when="viewer"
                  showCollapsibleSidebar={true}
                />
              )}
            />
            <Route
              path="/dashboard"
              render={(props) => 
              <AdminDashboard
                {...props}
                collapseSidebar={collapseSidebar}
              />
            }
            />
            <Route path="/groups" render={(props) => <Groups {...props} />} />
            <Route
              path="/notifications"
              render={(props) => <Notifications {...props} />}
            />
            <Route
              path="/analysis-dashboard"
              render={(props) => <AnalysisDashboard {...props} />}
            />
            <Route
              path="/analysis"
              render={(props) => <AIAnalytics {...props} />}
            />
            <Route
              path="/analysis-previous-runs"
              render={(props) => <PreviousRuns {...props} />}
            />
            {/* <Route component={Error} /> */}
          </Switch>
        </React.Fragment>
      )}
    </div>
  );
};

export default AppRoutes;
