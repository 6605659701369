import React from "react";
import PropTypes from "prop-types";
import OpenSeadragon from "../utils/fabricjs-overlay";
import SlideSpliteSlideRemoveIcon from "../icons/minus-icon.png";
import ViewerScaleBar from "./viewer-scalebar";
import { htmlDecode } from "../../../utils/utils";
import {
	setViewerScaleUnit,
	setViewerDefaultScaleUnit,
	setViewerScaleRatio,
	setViewerDefaultScaleRatio,
	setViewerFullScreen,
} from "../../imageViewer/actions/viewer-action";
import { connect } from "react-redux";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "../utils/react-opeanseadragon-filter";
import { SocketContext } from "../../../utils/serviceContext";
import "../utils/openseadragon-imaging-helper";
import {
	liveShareOnAction,
	liveShareUserOnAction,
} from "../actions/viewer-action";
import { updateSelectedBoardLiveSync } from "../../../pages/groups/actions/groups-action";

class OSDViewer extends React.Component {
	constructor(props) {
		super(props);
		this._config = this._config.bind(this);
		this._id = this._id.bind(this);
		this._currentPageIndex = this._currentPageIndex.bind(this);
		this._osdViewer = this._osdViewer.bind(this);
		this.setStrings = this.setStrings.bind(this);
		this.setStringsItems = this.setStringsItems.bind(this);
		this.state = {
			pages: props.pages,
			osdDisplay: {},
			textDisplay: { display: "none" },
			currentRotate: 0,
			currentImageId: 0,
			mouseOverImage: false,
			mouseOverImageSet: [],
			zoomValueBar: 1,
			imageZoomValue: 0,
			WebCoordinates: { x: 0, y: 0 },
			viewPortCoordinates: { x: 0, y: 0 },
			imageCoordinates: { x: 0, y: 0 },
			curColor: "#000000",
			scaleVal: 1,
		};
		this.angleInRad = 0;
		this.microscopeMaxZoom = 20;
		this.overlay = null;
		if (
			this.props.viewerData["config"]["imageMetadata"][
				"openslide.objective-power"
			]
		) {
			this.microscopeMaxZoom = this.props.viewerData["config"][
				"imageMetadata"
			]["openslide.objective-power"];
		}
	}
	static contextType = SocketContext;

	componentDidMount() {
		this.initSocketConnection();

		this.setEmptyStrings();
		this.loadCanvas();
		window.addEventListener("keydown", this.handleKeyDown);
	}

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.liveShareIsOn !== this.props.liveShareIsOn) {
			if (this.props.groupAdmin) {
				this.socketInstance.emit("viewerLiveShareOnOff", {
					slideId: this.props.osdConfig.slideId,
					when: this.props.when,
					status: nextProps.liveShareIsOn,
					featureId:
						this.props.when === "quorum"
							? this.props.quorum_id
							: this.props.when === "groups"
							? this.props.groupId
							: null,
				});
			}
		}

		if (nextProps.userLiveShareOn !== this.props.userLiveShareOn) {
			this.socketInstance.emit("viewerLiveShareOnOffByNonAdmin", {
				slideId: this.props.osdConfig.slideId,
				when: this.props.when,
				status: nextProps.userLiveShareOn,
				featureId:
					this.props.when === "quorum"
						? this.props.quorum_id
						: this.props.when === "groups"
						? this.props.groupId
						: null,
			});
		}
		if (
			nextProps.viewerInstance !== this.props.viewerInstance &&
			typeof nextProps.viewerInstance !== "undefined" &&
			typeof nextProps.viewerIndex !== "undefined"
		) {
			nextProps.viewerInstance.addHandler("zoom", (event) => {
				this.osdZoomHandler(event);
			});
			nextProps.viewerInstance.addHandler("rotate", (event) => {
				// this.osdRotateHandler(event);
			});
			this.props.setViewerObject(nextProps.viewerInstance);
			const zoom = nextProps.viewerInstance.viewport.getZoom().toFixed(3);
			const imageZoom = nextProps.viewerInstance.viewport
				.viewportToImageZoom(zoom)
				.toFixed(3);
			this.setState({
				zoomValueBar: zoom,
				imageZoomValue: imageZoom,
			});
			const imageJson = {
				scale: this.props.viewerData["config"]["imageMetadata"][
					"width"
				],
			};
			this.heatMapOverlay = nextProps.viewerInstance.fabricjsOverlay(
				imageJson
			);
			this.overlay = nextProps.viewerInstance.fabricjsOverlay(imageJson);
			this.props.setOverlayInstance(
				this.overlay,
				nextProps.viewerIndex,
				this.heatMapOverlay
			);
		}
		if (
			nextProps.activeSnapShot !== this.props.activeSnapShot &&
			typeof nextProps.activeSnapShot !== "undefined"
		) {
			if (!nextProps.activeSnapShot) {
				this.cropendFn(null, true);
			}
		}
		if (nextProps.viewerInstance) {
			this.imagingHelper = nextProps.viewerInstance.activateImagingHelper(
				{
					onImageViewChanged: (event) =>
						this.onImageViewChanged(event),
				}
			);
		}
	};

	loadCanvas = async () => {
		this.setStrings();
		if (typeof window.OpenSeadragon !== "undefined") {
			this.props.setViewerInstance(
				window.OpenSeadragon(this._config()),
				this.props.viewerIndex
			);
		} else {
			this.props.setViewerInstance(
				OpenSeadragon(this._config()),
				this.props.viewerIndex
			);
		}

		this.props.setButtonInstance(
			new OpenSeadragon.Button({
				element: this.props.setFullScreenRef.current,
				onClick: this.enableFullScreen,
				tooltip: "",
			})
		);
	};

	onImageViewChanged(event) {
		if (this.props.liveShareIsOn) {
			if (this.props.groupAdmin) {
				this.socketInstance.emit("viewerLiveShareData", {
					width: event.viewportWidth,
					height: event.viewportHeight,
					centerX: event.viewportCenter.x,
					centerY: event.viewportCenter.y,
					zoomFactor: event.zoomFactor,
					slideId: this.props.osdConfig.slideId,
					featureId:
						this.props.when === "groups"
							? this.props.groupId
							: null,
				});
			}

			if (
				this.props.liveSyncMetaData &&
				this.props.userDetails.userID ===
					this.props.liveSyncMetaData.liveBroadcasterId
			) {
				this.socketInstance.emit("viewerLiveShareData", {
					width: event.viewportWidth,
					height: event.viewportHeight,
					centerX: event.viewportCenter.x,
					centerY: event.viewportCenter.y,
					zoomFactor: event.zoomFactor,
					slideId: this.props.osdConfig.slideId,
					featureId: null,
				});
			}
		}
	}

	initSocketConnection = () => {
		this.socketInstance = this.context.getSocketInstance();
		if (this.props.when === "groups") {
			const liveShareStatus =
				this.props.selectedGroupData &&
				this.props.selectedGroupData.slideLiveShareStatus === 1
					? true
					: false;
			const groupMemberArray =
				this.props.selectedMembers &&
				this.props.selectedMembers.length > 0
					? this.props.selectedMembers.filter(
							({ id }) => id === this.props.userDetails.userID
					  )
					: null;
			const userSlideShare = groupMemberArray
				? groupMemberArray[0].userLiveShareStatus
					? true
					: false
				: false;
			this.props.liveShareOn(liveShareStatus);
			this.props.liveShareUserOn(userSlideShare);
			this.context.onViewerSyncroniztion().subscribe((data) => {
				if (
					data.slideId === this.props.osdConfig.slideId &&
					data.userId !== this.props.userDetails.userID &&
					this.props.userLiveShareOn
				) {
					this.imagingHelper.setView(
						data.width,
						data.height,
						new OpenSeadragon.Point(data.centerX, data.centerY),
						false
					);
				}
			});
			this.context.onViewerSyncroniztionStart().subscribe((data) => {
				if (
					data.slideId === this.props.osdConfig.slideId &&
					data.userId !== this.props.userDetails.userID
				) {
					this.props.liveShareUserOn(data.status);
				}
				this.props.updateGroupLiveSynStat(data);
			});
		}

		if (this.props.liveSyncMetaData) {
			this.props.liveShareOn(true);
			this.props.liveShareUserOn(true);
			this.context.onViewerSyncroniztion().subscribe((data) => {
				if (
					data.slideId === this.props.osdConfig.slideId &&
					data.userId !== this.props.userDetails.userID &&
					this.props.userLiveShareOn
				) {
					this.imagingHelper.setView(
						data.width,
						data.height,
						new OpenSeadragon.Point(data.centerX, data.centerY),
						false
					);
				}
			});
			this.context.onViewerSyncroniztionStart().subscribe((data) => {
				if (
					data.slideId === this.props.osdConfig.slideId &&
					data.userId !== this.props.userDetails.userID
				) {
					this.props.liveShareUserOn(data.status);
				}
			});
		}
	};

	enableFullScreen = () => {
		this.props.setViewerFullScreen(!this.props.viewerFullScreen);
	};

	setEmptyStrings = () => {
		OpenSeadragon.setString("Tooltips.Home", "");
		OpenSeadragon.setString("Tooltips.ZoomOut", "");
		OpenSeadragon.setString("Tooltips.ZoomIn", "");
		OpenSeadragon.setString("Tooltips.FullPage", "");
		OpenSeadragon.setString("Tooltips.ScreenshotToggle", "");
	};

	osdZoomHandler = (event) => {
		if (this.props.selectedViewer !== this.props.viewerIndex) {
			return false;
		}

		const defZoom = this.props.viewerInstance.viewport.getHomeZoom();
		const maxZoom = this.props.viewerInstance.viewport.getMaxZoom();
		const zoom = (event.zoom * this.microscopeMaxZoom) / maxZoom;
		let currentScale =
			((this.props.viewerData["config"]["imageMetadata"]["aperio.MPP"] *
				maxZoom) /
				event.zoom) *
			100;

		if (currentScale > 1000) {
			currentScale /= 1000;
			currentScale = currentScale.toFixed(2);
			this.props.setScaleUnit("mm");
			this.props.setScaleRatio(
				this.props.viewerData["config"]["imageMetadata"]["aperio.MPP"] *
					0.1
			);
		} else if (Number.isNaN(currentScale)) {
			this.props.setDefaultScaleUnit("cm");
			this.props.setDefaultScaleRatio();
		} else {
			currentScale = currentScale.toFixed(2);
			this.props.setScaleUnit(htmlDecode("&#181;m"));
			this.props.setScaleRatio(
				this.props.viewerData["config"]["imageMetadata"]["aperio.MPP"] *
					0.1
			);
		}

		this.setState({
			zoomValueBar: zoom.toFixed(3),
			scaleVal: currentScale,
		});
		this.forceUpdate();
		if (
			typeof this.overlay.fabricCanvas() !== "undefined" &&
			this.overlay
		) {
			this.overlay
				.fabricCanvas()
				.getObjects()
				.some((obj) => {
					if (obj.type !== "path") {
						obj.strokeWidth = 80 / this.state.zoomValueBar;
					}
				});
		}
	};

	viewportZoom = (e) => {
		let currentZoom = this.props.viewerInstance.viewport.getZoom(true);
	};

	rotateImageHandler = (val) => {
		this.props.viewerInstance.viewport.setRotation(val);
		this.angleInRad = (val * Math.PI) / 180;
	};

	osdRotateHandler = (event) => {
		var objs = this.overlay.fabricCanvas().getObjects();
		for (var i = 0; i < objs.length; i++) {
			// console.log(objs[i].type, event.degrees )
			if (objs[i].type == "rect") {
				if (objs[i].centeredRotation) {
					objs[i].centeredRotation = false;
				}
				objs[i].rotate(event.degrees);
			}
		}
	};

	handleOsdFilters = (val, filterType) => {
		switch (filterType) {
			case "brightness":
				this.props.viewerInstance.setFilterOptions({
					filters: {
						processors: OpenSeadragon.Filters.BRIGHTNESS(val),
					},
				});
				break;
			case "saturation":
				break;
			case "contrast":
				this.props.viewerInstance.setFilterOptions({
					filters: {
						processors: OpenSeadragon.Filters.CONTRAST(val / 10),
					},
				});
				break;
			case "hue":
				break;
		}
	};

	setStringsItems() {
		return typeof this.props.osdConfig.setStrings !== "undefined"
			? this.props.osdConfig.setStrings
			: [];
	}

	setStrings() {
		this.setStringsItems().map((item) =>
			OpenSeadragon.setString(item.name, `${item.value}`)
		);
	}

	_currentPageIndex() {
		let urlParts = window.location.href.split("/");
		return parseInt(
			urlParts
				.map((part, i) => {
					if (part === "image") {
						return urlParts[i + 1];
					}
					return "";
				})
				.join("")
				.trim(),
			[0],
			10
		);
	}

	_currentPage() {
		return this.state.pages[this._currentPageIndex()];
	}

	_config() {
		return Object.assign(this.props.viewerData["config"]["osdConfig"], {
			id: this.props.viewerData["id"],
		});
	}

	_id() {
		return parseInt(this.props.match.params.id, 10);
	}

	_osdViewer() {
		const { when } = this.props;
		const style = {
			height: "90%",
		};
		return (
			<div
				key={this.props.viewerData.id}
				className={
					this.props.viewerDataArray.length === 1
						? "openseadragon container-viewer"
						: this.props.selectedViewer === this.props.viewerIndex
						? "openseadragon container-viewer split selected"
						: "openseadragon container-viewer split"
				}
				style={style}
				id={this.props.viewerData.id}
				onClick={() => this.props.viewerOnClick(this.props.viewerIndex)}
			>
				{this.props.viewerDataArray.length !== 1 && (
					<div className="viewer-toolbar-mini-container">
						<ul>
							<li
								id="remove-split-view"
								className={"nonactive-tool remove-slide-li"}
								data-for="viewer-toolbar"
								data-tip={"Remove slide"}
								onClick={(e) =>
									this.props.removeSplitSlide(
										e,
										this.props.viewerIndex
									)
								}
							>
								<img src={SlideSpliteSlideRemoveIcon} />
							</li>
						</ul>
					</div>
				)}

				<ViewerScaleBar
					OPENSEADRAGONVIEWER={this.props.viewerInstance}
					imageMetadata={
						this.props.viewerData["config"]["imageMetadata"]
					}
					scaleVal={this.state.scaleVal}
					overlay={this.overlay}
					zoomValueBar={this.state.zoomValueBar}
				/>
			</div>
		);
	}

	handleHomeClick = () => {
		if (this.props.viewerInstance.viewport) {
			this.props.viewerInstance.viewport.setRotation(0);
			this.props.viewerInstance.viewport.goHome();
			this.angleInRad = 0;
			this.setBounds();
		}
	};

	cropendFn = async (e, removeCrop = false) => {
		await this.props.cropImage(removeCrop);
		this.loadCanvas();
	};

	render() {
		if (typeof this.props.viewerInstance !== "undefined") {
			this.props.viewerInstance.goToPage(this.props.currentPageId);
		}
		return (
			<React.Fragment>
				{this.props.cropData && (
					<Cropper
						style={{ width: "100%" }}
						initialAspectRatio={1}
						viewMode={1}
						className="crop-section"
						crossOrigin="false"
						ref={this.props.setRef}
						src={this.props.cropData}
						guides={false}
						cropend={this.cropendFn}
						autoCrop={false}
						movable={false}
					/>
				)}
				{!this.props.cropData && this._osdViewer()}
			</React.Fragment>
		);
	}
}

OSDViewer.defaultProps = {
	viewSearchText: "",
	osdConfig: {
		defaultZoomLevel: 0,
		tileSources: [],
		setStrings: [],
		visibilityRatio: 1.0,
		constrainDuringPan: true,
	},
};

OSDViewer.propTypes = {
	osdConfig: PropTypes.shape({
		defaultZoomLevel: PropTypes.number,
		tileSources: PropTypes.array,
		setStrings: PropTypes.array,
		visibilityRatio: 1.0,
		constrainDuringPan: true,
	}),
	viewSearchText: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
	setScaleUnit: (payload) => dispatch(setViewerScaleUnit(payload)),
	setDefaultScaleUnit: () => dispatch(setViewerDefaultScaleUnit()),
	setScaleRatio: (payload) => dispatch(setViewerScaleRatio(payload)),
	setDefaultScaleRatio: () => dispatch(setViewerDefaultScaleRatio()),
	setViewerFullScreen: (payload) => dispatch(setViewerFullScreen(payload)),
	liveShareOn: (payload) => dispatch(liveShareOnAction(payload)),
	liveShareUserOn: (payload) => dispatch(liveShareUserOnAction(payload)),
	updateGroupLiveSynStat: (payload) =>
		dispatch(updateSelectedBoardLiveSync(payload)),
});

const mapStateToProps = (state) => ({
	userDetails: state.Global.loggedUserDetails,
	windowSize: state.Global.windowSize,
	isMobile: false,
	liveShareIsOn: state.Viewer.liveShareOn,
	selectedGroupData: state.Groups.selectedBoard,
	userLiveShareOn: state.Viewer.userLiveShareOn,
	selectedMembers: state.Groups.selectedMembers,
	scaleUnit: state.Viewer.scaleUnit,
	liveSyncMetaData: state.Viewer.liveSyncMetaData,
	viewerDataArray: state.Viewer.viewerDataArray,
	viewerFullScreen: state.Viewer.viewerFullScreen,
});

export default connect(mapStateToProps, mapDispatchToProps)(OSDViewer);
