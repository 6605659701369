import React, { useState } from "react";
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from "../../../../components";
import { FadeIn } from "animate-components";
import { Dropzone } from "../../../../components";
import UploadIcon from "../../../../assets/icons/upload.svg";
import UploadAnno from "../../../../assets/icons/upload-anno.svg";
import closeIcon from "../../../../assets/icons/close-new.svg";
import axios from "../../../../utils/axiosInstance";
import { updateAnnotionDataAction } from "../../actions/viewer-action";
import { Notify } from "../../../../components";
import { connect } from "react-redux";
import ToolTip from "react-tooltip";
import { isNull } from "lodash";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "react-tooltip";

const AnnotationUploadPopup = ({
  onClose,
  updateAnnotionData,
  slideUniqueId,
}) => {
  const [files, setFiles] = useState([]);

  const onUploadFile = async (event) => {
    const filename = event.target.files[0].name;
    if (filename.substring(filename.length - 4) === "json") {
      setFiles([...event.target.files]);
    } else {
      Notify({
        value: "File type should be JSON",
      });
    }
  };

  const onDropzonUploadFile = async (dropzoneFiles) => {
    setFiles([...dropzoneFiles]);
  };

  const toggleUploadFile = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      if (file.size > 5242880) {
        Notify({ value: "File size should be less than 5 MB" });
        return;
      }
      formData.append("file", file);
    });
    formData.append("slideUniqueId", slideUniqueId);

    let {
      data: { success, mssg, insertedDataArray },
    } = await axios.post("/api/viewer/uploadAnnotation", formData);

    if (success) {
      insertedDataArray.forEach((dataSet) => {
        updateAnnotionData({
          ...dataSet.insertData.annotation,
          pk: dataSet.insertId,
          slide_id: dataSet.insertData.slide_id,
          user_id: dataSet.insertData.user_id,
          first_name: dataSet.insertData.first_name,
          surname: dataSet.insertData.last_name,
          is_public: dataSet.insertData.is_public,
          toggle: "on",
          isAnnotationUpload: true,
        });
      });

      Notify({
        value: mssg,
      });
      onClose();
    }
  };

  const onRemoveFile = () => {
    setFiles([]);
  };

  const bytesToSize = (bytes, seperator = "") => {
    const sizes = ["Bytes", "KB", "MB"];
    if (bytes == 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`;
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt annotation-upload-popup">
        <FadeIn duration="300ms">
          <ModalHeader
            title={"Upload annotations"}
            onClick={onClose}
            img={UploadAnno}
            alt={"upload"}
          />
          <div className="modal_middle" style={{ height: "245px" }}>
            <div className="modal_content" style={{ marginTop: "15px" }}>
              <div className="slidebox-upload-container">
                <div className="upload-content" id="upload-content-area">
                  <div className="container">
                    <InfoOutlinedIcon
                      style={{ float: "right" }}
                      color="disabled"
                      data-place="left"
                      data-tip={`Supported file format:- JSON, File size:5 MB`}
                      data-for="annotation-popup-tooltip"
                    />
                    <div className="upload-area">
                      <div className="upload-area-border">
                        <div className="drag-and-drop-wrapper">
                          <img src={UploadIcon} alt="drag and drop" />
                          <Dropzone setDragAndDropFiles={onDropzonUploadFile} />
                        </div>
                        <div className="input-file-wrapper">
                          <button>CHOOSE FILES</button>
                          <input onChange={onUploadFile} type="file" />
                        </div>
                      </div>
                    </div>

                    <div className="list-items">
                      {files.map((file, index) => (
                        <div key={index} className="file-item">
                          <span
                            className="file-item-name"
                            data-for="annotation-popup-tooltip"
                            data-tip={file.name}
                          >
                            {file.name.split(".")[0]}
                          </span>
                          <label
                            className="checkbox-container file-item-remove"
                            onClick={() => {
                              onRemoveFile(index);
                            }}
                          >
                            <img src={closeIcon} alt="close" />
                          </label>
                          <span className="file-item-size">
                            {bytesToSize(file.size)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="prompt-bottom"
            style={{
              marginTop: 0,
              justifyContent: "flex-end",
              padding: "0 0 10px 0",
            }}
          >
            <Tooltip
              id="upload-annotation-tooltip"
              place="top"
              type="dark"
              effect="solid"
            />
            <SecondaryButton
              extraClass="annotation-btn"
              label={"Cancel"}
              onClick={onClose}
            />
            <PrimaryButton
              extraClass="annotation-btn"
              label={"Upload"}
              onClick={toggleUploadFile ? toggleUploadFile : isNull}
              disabled={files.length > 0 ? false : true}
            />
          </div>
        </FadeIn>
        <ToolTip
          id="annotation-popup-tooltip"
          place="bottom"
          type="dark"
          effect="solid"
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  slideUniqueId: state.Viewer.slideUniqueId,
});

const mapDispatchToProps = (dispatch) => ({
  updateAnnotionData: (payload) => dispatch(updateAnnotionDataAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnotationUploadPopup);
