import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";

import Divider from "@material-ui/core/Divider";

import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";

import ClosePopupIcon from "../../../assets/icons/close-new.svg";
import AccountIcon from "../../../assets/icons/account-preference.svg";
import { Overlay } from "../../../components";
import { FadeIn } from "animate-components";
import ChangePassword from "./ChangePassword";
import { AppBar, Drawer, Toolbar } from "@material-ui/core";
import NotificationPreference from "./NotificationPreference";

const drawerWidth = 152;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxHeight: "465px",
  },
  title: {
    color: '#0F0DA1',
    fontSize: '16px',
    marginLeft: '10px',
    fontWeight: '700'
  },
  img: {
    marginLeft: '-3px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff'
  },
  drawer: {
    width: drawerWidth,
    backgroundColor: "#ffffff",
    // width: "auto",
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    // width: "auto"
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    alignContent: "center",
  },
}));

const AccountPreferences = ({ openModal, onClose, userDetails }) => {
  const classes = useStyles();

  const [renderChangePassword, setRenderChangePassword] = React.useState(false);
  const [renderNotification, setRenderNotification] = React.useState(false);
  const [modalMiddle, setModalMiddle] = React.useState(true);

  const handleRenderChangePassword = () => {
    setRenderChangePassword(true);
    setRenderNotification(false);
    setModalMiddle(!modalMiddle)
  };


  const handleRenderNotification = () => {
    setRenderNotification(true);
    setRenderChangePassword(false);
    setModalMiddle(false);
  };

  const closeModal = () => {
    onClose();
    setModalMiddle(true);
    setRenderNotification(false);
    setRenderChangePassword(false);
  }

  return (
    openModal && (
      <React.Fragment>
        <Overlay />
        <div className="modal_big">
          <div className="prompt-preferences">
            <FadeIn duration="300ms">
              <div className={classes.root}>
                <CssBaseline />

                <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar className="prompt-top">
                    <div className="prompt-title">
                      <img className={classes.img} src={AccountIcon} alt={"account"} />
                      <span className={classes.title}>Account Preferences</span>
                    </div>
                    <img
                      className="close-icon"
                      src={ClosePopupIcon}
                      onClick={() => {
                        onClose(closeModal);
                        setRenderChangePassword(false);
                      }}
                    />
                  </Toolbar>
                </AppBar>

                <Drawer
                  className={classes.drawer}
                  variant="permanent"
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  <Toolbar />
                  <div className={classes.drawerContainer}>
                    <List>
                      {["Change Password"].map((text, index) => (
                        <ListItem button key={text}>
                          <ListItemText
                            primary={text}
                            onClick={handleRenderChangePassword}
                          />
                          <Divider />
                        </ListItem>
                      ))}

                      <ListItem button>
                        <ListItemText
                          primary="Notifications"
                          onClick={handleRenderNotification}
                        />
                        <Divider />
                      </ListItem>
                    </List>
                  </div>
                </Drawer>
                <main className={classes.content}>
                  <Toolbar />
                  <div className="modal_middle">
                    {(renderChangePassword || modalMiddle) && (
                      <div className="d-flex justify-content-between box-sizing">
                        <div className="change-password">
                          <ChangePassword
                            userDetails={userDetails}
                            onClose={closeModal}
                          />
                        </div>
                        <div className="password-hints">
                          <p className="must-Contain">Password must contain:</p>
                          <div className="hints">
                            <p>At least 1 upper case letter (A-Z)</p>
                            <p>At least 1 number (0-9)</p>
                            <p>At least 8 characters</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {renderNotification && (
                      <NotificationPreference
                        userDetails={userDetails}
                        onClose={handleRenderNotification}
                        closeModal={closeModal}
                      />
                    )}
                  </div>
                </main>
              </div>
            </FadeIn>
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default AccountPreferences;
