import React, { useState } from "react";
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from "../../../../components";
import { Select } from "antd";
import InfoIcon from "../../../../assets/icons/info-icon.svg";

const { Option } = Select;

const CaseStatusModal = ({
  selectedDirId,
  updateCaseStatus,
  setShowCaseStatusModal,
  caseStatusOptions,
  selectedCaseInfo,
}) => {
  const [caseStatusValue, setCaseStatusValue] = useState(
    selectedCaseInfo.caseStatus
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleConfirmClick = () => {
    updateCaseStatus({ dirId: selectedDirId, reviewStatus: caseStatusValue });
    setShowCaseStatusModal(false);
    setShowConfirmationModal(false);
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt case-status-modal">
        <ModalHeader
          
          title="Change Case Status"
          onClick={() => setShowCaseStatusModal(false)}
        />
        <div className="modal_middle">
          <Select
            onChange={(value) => {
              setCaseStatusValue(value);
            }}
            className="priority-dropdown"
            value={caseStatusValue}
          >
            {caseStatusOptions.map((item, index) => {
              return (
                <Option
                  key={index}
                  value={item.value}
                  className="dropdown-options"
                  style={{ marginLeft: 10 }}
                >
                  {item.label}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="prompt-bottom" style={{ marginTop: 0 }}>
          <SecondaryButton
            label="Cancel"
            onClick={() => setShowCaseStatusModal(false)}
          />
          <PrimaryButton
            label="Save"
            onClick={() => setShowConfirmationModal(true)}
            extraClass="prompt-done"
            disabled={caseStatusValue === selectedCaseInfo.caseStatus}
          />
        </div>
      </div>
      {showConfirmationModal && (
        <React.Fragment>
          <Overlay />
          <div className="prompt priority-prompt">
            <ModalHeader
              img={InfoIcon}
              alt="info icon"
              title="Status Update"
              onClick={() => setShowConfirmationModal(false)}
              id="modalHeader"
            />
            <div className="modal_middle">
              <p className="Confirmation text">
                Are you sure you want to update the status of this case?
              </p>
            </div>
            <div className="prompt-bottom">
              <SecondaryButton
                label="Cancel"
                onClick={() => setShowConfirmationModal(false)}
              />
              <PrimaryButton label="Confirm" onClick={handleConfirmClick} />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CaseStatusModal;
