import React from "react";
import { Link } from "react-router-dom";
import Can from "../../../../hoc/FullControllHoc/can";

let isSelectedFolderCase = "case";
const EmptyMessage = ({ mainMessage, subMessage, selectedFolderType }) => {
  return (
    <div className="empty-message-container">
      <div className="empty-message-chid">
        <div className="empty-message-chid-2">
          <div className="empty-message-chid-3" />
          <div className="empty-title">
            <div className="empty-main-message">
              {selectedFolderType === isSelectedFolderCase ? (
                <Can
                  task={"create-case"}
                  taskContainer="slidebox"
                  edit={() => (
                    <Link id="uploadImagesLinkTag" to="/slidebox/upload">
                      {mainMessage}
                    </Link>
                  )}
                  noAccess={() => mainMessage}
                />
              ) : (
                mainMessage
              )}
            </div>
            <div className="empty-sub-message">{subMessage}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyMessage;
