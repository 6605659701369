import React, { useState } from "react";
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from "../../../../components";
import { Select } from "antd";

const { Option } = Select;

const ChangePriorityModal = ({
  selectedDirId,
  changePriorityFn,
  setShowPriorityModal,
  selectedCaseInfo,
}) => {
  const [priorityValue, setPriorityValue] = useState(selectedCaseInfo.priority);
  const priorityOptions = [
    {
      label: "standard",
      value: "Standard",
    },
    { label: "rush", value: "Rush" },
    { label: "stat", value: "Stat" },
  ];
  const handleSaveClick = () => {
    changePriorityFn({ dirId: selectedDirId, priority: priorityValue });
    setShowPriorityModal(false);
  };
  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt change-priority-modal">
        <ModalHeader
          
          title="Change Case Priority"
          onClick={() => setShowPriorityModal(false)}
        />
        <div className="modal_middle">
          <Select
            onChange={(value) => {
              setPriorityValue(value);
            }}
            className="priority-dropdown"
            value={priorityValue}
          >
            {priorityOptions.map((item, index) => {
              return (
                <Option
                  key={index}
                  value={item.label}
                  className="dropdown-options"
                  style={{ marginLeft: 10 }}
                >
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="prompt-bottom" style={{ marginTop: 0 }}>
          <SecondaryButton
            label="Cancel"
            onClick={() => setShowPriorityModal(false)}
          />
          <PrimaryButton
            label="Save"
            onClick={handleSaveClick}
            extraClass="prompt-done"
            disabled={
              priorityValue === selectedCaseInfo.priority ? true : false
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangePriorityModal;
