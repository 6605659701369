import { convertNodeToElement } from "react-html-parser";

const initialState = {
  imageValue: null,
  type: null,
  isQuorumImageLoad: false,
  annotationType: null,
  isAnnotationActive: false,
  setAnnotations: false,
  isQuorumImageInit: false,
  removedAnnotation: false,
  when: null,
  annotatedTime: null,
  annotationColor: null,
  userAnnotation: [],
  annotationData: [],
  toogleData: null,
  liveShareOn: false,
  userLiveShareOn: false,
  isSoketDataUpdate: false,
  scaleUnit: "cm", // default unit to cm
  scaleRatio: 0.01,
  liveSyncUrl: null,
  liveSyncMetaData: null,
  annotationDownloadUrl: null,
  slideUniqueId: null,
  isAnnotationUpload: null,
  liveSyncPopupload: false,
  selectedAnnotationId: null,
  annotationPopupShow: null,
  description: "",
  viewerActivityLogId: null,
  viewerActivityData: [],
  viewerDataArray: [],
  roiData: [],
  annotationDataForHighlightAreas: [],
  heatMapData: [],
  selectedSlideLabels: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_ANNOTATION_DATA": {
      const data = action.payload;
      return {
        ...state,
        userAnnotation: data,
      };
    }

    case "SET_CURRENT_ANNOTATION_DATA": {
      const data = action.payload;
      return {
        ...state,
        annotationData: data,
      };
    }

    case "SET_DEFAULT_SCALE_UNIT": {
      return {
        ...state,
        scaleUnit: initialState.scaleUnit,
      };
    }

    case "SET_SCALE_UNIT": {
      const data = action.payload;
      return {
        ...state,
        scaleUnit: data,
      };
    }

    case "SET_DEFAULT_SCALE_RATIO": {
      return {
        ...state,
        scaleRatio: initialState.scaleRatio,
      };
    }

    case "SET_SCALE_RATIO": {
      const data = action.payload;
      return {
        ...state,
        scaleRatio: data,
      };
    }

    case "DELETE_ANNOTATION_POPUP": {
      const deletedId = action.payload;
      return {
        ...state,
        selectedAnnotationId: deletedId,
        annotationPopupShow: action.status,
      };
    }

    case "DELETE_ANNOTATION_DATA": {
      const deletedId = action.payload;
      return {
        ...state,
        annotationData: state.annotationData.filter(
          (data) => data.pk !== deletedId
        ),
      };
    }

    case "UPDATE_CURRENT_ANNOTATION_DATA": {
      const data = action.payload;
      return {
        ...state,
        annotationData: [...state.annotationData, data],
        isAnnotationUpload:
          typeof data.isAnnotationUpload !== "undefined"
            ? data.isAnnotationUpload
            : null,
      };
    }

    case "RESET_ANNOTATION_UPLOAD": {
      return {
        ...state,
        isAnnotationUpload: null,
      };
    }

    case "UPDATE_CURRENT_ANNOTATION_DATA_IN_VALIDATE": {
      const data = action.payload;
      return {
        ...state,
        annotationData: [...state.annotationData, ...data],
        isSoketDataUpdate: true,
      };
    }

    case "SET_SOCKET_DATE_ACTION_FALSE": {
      const data = action.payload;
      return {
        ...state,
        isSoketDataUpdate: false,
      };
    }

    case "EDIT_CURRENT_ANNOTATION_DATA": {
      const data = action.payload;
      const filteredAnnoData = state.annotationData.filter(
        (data) => data.pk !== action.pk
      );
      return {
        ...state,
        annotationData: [...filteredAnnoData, data],
      };
    }

    case "PUBLIC_ANNOTATION_DATA": {
      const data = action.payload;
      const updatedAnnoData = state.annotationData.map((dataSet) => {
        if (data.user_id === dataSet.user_id) {
          return {
            ...dataSet,
            is_public: data.status,
          };
        }
        return dataSet;
      });
      return {
        ...state,
        annotationData: updatedAnnoData,
      };
    }

    case "TOGGLE_ANNOTATION_DATA": {
      const payloadData = action.payload;
      return {
        ...state,
        annotationData: state.annotationData.map((data) => {
          if (
            payloadData.annotationIds &&
            payloadData.annotationIds.length > 0 &&
            payloadData.annotationIds.includes(data.pk)
          ) {
            return {
              ...data,
              toggle: payloadData.onOrOff,
            };
          }
          return data;
        }),
        toogleData: payloadData,
      };
    }

    case "VALIDATE_ANNOTATION_DATA": {
      const payloadData = action.payload;
      return {
        ...state,
        annotationData: state.annotationData.map((data) => {
          if (payloadData.includes(data.pk)) {
            return {
              ...data,
              isValidate: true,
            };
          }
          return data;
        }),
      };
    }

    case "SET_ANNOTATION_TYPE": {
      const type = action.payload;
      return {
        ...state,
        annotationType: type,
      };
    }

    case "SET_ANNOTATION_COLOR": {
      const color = action.payload;
      return {
        ...state,
        annotationColor: color,
      };
    }

    case "SET_ANNOTATION_ACTIVE": {
      const status = action.payload;
      return {
        ...state,
        isAnnotationActive: status,
      };
    }

    case "SET_LIVE_SHARE_ACTIVE": {
      const status = action.payload;
      return {
        ...state,
        liveShareOn: status,
      };
    }

    case "SET_LIVE_SHARE_USER_ACTIVE": {
      const status = action.payload;
      return {
        ...state,
        userLiveShareOn: status,
      };
    }

    case "SET_GENERATE_LIVESYNC_URL": {
      return {
        ...state,
        liveSyncUrl: action.payload,
        liveSyncPopupload: action.payload ? true : !state.liveSyncPopupload,
      };
    }

    case "SET_LIVESYNC_META_DATA": {
      return {
        ...state,
        liveSyncMetaData: action.payload,
      };
    }

    case "RESET_VIEWER_ANNOTATION": {
      return {
        ...initialState,
      };
    }

    case "SET_DOWNLOAD_ANNOTATION_URL": {
      return {
        ...state,
        annotationDownloadUrl: action.payload,
      };
    }

    case "RESET_DOWNLOAD_ANNOTATION": {
      return {
        ...state,
        annotationDownloadUrl: null,
      };
    }

    case "SET_SLIDE_UNIQUE_DATA": {
      return {
        ...state,
        slideUniqueId: action.payload,
      };
    }

    case "SET_VIEWER_ACTIVITY_LOG": {
      return {
        ...state,
        viewerActivityLogId: action.payload,
      };
    }

    case "GET_VIEWER_ACTIVITY_LOG": {
      return {
        ...state,
        viewerActivityData: action.payload,
      };
    }

    case "GET_SLIDE_HEAT_MAP_DATA": {
      return {
        ...state,
        heatMapData: action.payload,
      };
    }

    case "RESET_ANNOTATION_DOWNLOAD_URL": {
      return {
        ...state,
        annotationDownloadUrl: null,
      };
    }

    case "ANNOTATION_DESCRIPTION_UPDATE": {
      const newDetails = state.annotationData.map((data) => {
        if (data.unique_id === action.payload.uniqueId) {
          return {
            ...data,
            description: action.payload.description,
          };
        } else {
          return data;
        }
      });
      return {
        ...state,
        annotationData: newDetails,
      };
    }

    case "GET_ANNOTATION_DESCRIPTION": {
      return {
        ...state,
        description: action.payload,
      };
    }

    case "SET_CURRENT_ANNOTATION_DESCRIPTION": {
      const data = action.payload;
      return {
        ...state,
        description: data,
      };
    }

    case "SET_VIEWER_DZI_DATA_ARRAY": {
      return {
        ...state,
        viewerDataArray: action.payload,
      };
    }

    case "SET_ROI": {
      return {
        ...state,
        roiData: action.payload ? [action.payload] : [],
      };
    }

    case "SET_CURRENT_ANNOTATION_DATA_FOR_HIGHLIGHT_AREAS": {
      const data = action.payload;
      return {
        ...state,
        annotationData: [],
      };
    }

    case "SET_CURRENT_ANNOTATION_DATA_FOR_RUN_ANALYSIS": {
      const data = action.payload;
      return {
        ...state,
        annotationData: action.payload,
      };
    }

    case "CLOSE_DELETE_ANNOTATION_POPUP": {
      return {
        ...state,
        annotationPopupShow: action.status,
      };
    }

    case "SET_VIEWER_LABELS": {
      return {
        ...state,
        selectedSlideLabels: action.payload,
      };
    }

    default:
      return state;
  }
};
