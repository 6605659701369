import React from "react";
import OpenseadragonViewer from "../../../imageViewer/components/react-openseadragon-viewer";
import AnalysisAreaPreview from "./AnalysisAreaPreview";

const SlideViewr = (slideData) => {
  return (
    <div
      className={
        slideData.highlightAreas === true
          ? "analysis-slide-viewer full-width"
          : "analysis-slide-viewer"
      }
    >
      <div className="osd osd-viewer-page">
        <div className="row osd-row">
          <OpenseadragonViewer
            {...slideData}
            when={"analytics"}
            showToolbar={slideData.highlightAreas ? true : false}
            selectAnalysisType={slideData.selectAnalysisType}
          />
        </div>
      </div>
      {slideData.highlightAreas === true && (
        <AnalysisAreaPreview
          setContinueButtonDisabled={slideData.setContinueButtonDisabled}
          annotationCoordinates={slideData.annotationCoordinates}
        />
      )}
    </div>
  );
};

export default SlideViewr;
