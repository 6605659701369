const initialState = {
  showLoader: false,
  dxAccountDetails: [],
  showMessage: false,
  message: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SHOW_LOADER": {
      return {
        ...state,
        showLoader: action.showLoader,
      };
    }
    case "SET_DXACCOUNT_DETAILS": {
      return {
        ...state,
        dxAccountDetails: action.payload,
      };
    }
    case "ADD_NEW_DX_ACCOUNT": {
      return {
        ...state,
        dxAccountDetails: [action.payload, ...state.dxAccountDetails],
        message: action.message,
        showMessage: true,
      };
    }
    case "SHOW_MESSAGE": {
      return {
        ...state,
        message: action.message,
        showMessage: true,
      };
    }
    case "ERROR_MESSAGE":
      return {
        ...state,
        showMessage: true,
        message: action.message,
      };
    case "RESET_MESSAGE":
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    default:
      return state;
  }
}
