import * as methods from "./methods";

const initialState = {
  showLoader: false,
  userList: [],
  message: "",
  showMessage: false,
  caseData: [],
  slideData: [],
  viewerAnnotationData: [],
  caseStatusData: [],
  caseSummary: [],
  rolesData: [],
  orderingPhysicians: [],
  proceduresList: [],
  workgroupData: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_USER_LIST":
      return {
        ...state,
        userList: action.payload,
      };
    case "DASHBOARD_SEARCH_LIST":
      return {
        ...state,
        userList: action.payload,
      };
    case "SHOW_DASHBOARD_LOADER":
      return {
        ...state,
        showLoader: action.showLoader,
      };

    case "ADD_NEW_USER_INVITATION":
      return {
        ...state,
        userList: [action.payload, ...state.userList],
        message: action.message,
        showMessage: true,
      };

    case "ACTIVATE_OR_DEACTIVATE_USER":
      return {
        ...state,
        userList: methods.activateOrDeactivateUserListStatusMethod(
          [...state.userList],
          action.data,
          false
        ),
      };

    case "REVOKE_USER_INVITATION":
      return {
        ...state,
        userList: state.userList.filter(
          (filterData) => filterData.userID !== action.payload
        ),
      };

    case "SHOW_MESSAGE":
      return {
        ...state,
        message: action.message,
        showMessage: true,
      };
    case "ERROR_MESSAGE":
      return {
        ...state,
        showMessage: true,
        message: action.message,
      };
    case "RESET_MESSAGE":
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    case "CHANGE_DX_USER_ROLE_TYPE":
      return {
        ...state,
        userList: methods.activateOrDeactivateUserListStatusMethod(
          [...state.userList],
          action.data,
          true
        ),
      };
    case "SET_CASE_DATA":
      return {
        ...state,
        caseData: action.payload,
      };

    case "SET_CASE_STATUS_DATA":
      return {
        ...state,
        caseStatusData: action.payload,
      };

    case "SET_SLIDE_DATA":
      return {
        ...state,
        slideData: action.payload,
      };
    case "SET_VIEWER_ANNOTATION_DATA":
      return {
        ...state,
        viewerAnnotationData: action.payload,
      };

    case "SET_CASE_SUMMARY":
      return {
        ...state,
        caseSummary: action.payload,
      };
    case "SET_ROLES_DATA":
      return {
        ...state,
        rolesData: action.payload,
      };
    case "SET_PHYSICIANS_DATA":
      return {
        ...state,
        orderingPhysicians: action.payload,
      };
    case "SET_PROCEDURES_DATA":
      return {
        ...state,
        proceduresList: action.payload,
      };

    case "SET_WORKGROUPS_DATA":
      return {
        ...state,
        workgroupData: action.payload,
      };
    default:
      return state;
  }
}
