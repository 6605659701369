import React from "react";
import { FadeIn } from "animate-components";
import {
  Overlay,
  PrimaryButton,
  SecondaryButton,
} from "../../../../components";
import { Notify } from "../../../../components";
import CaseIcon from "../../../../assets/icons/case-view-icon.svg";
import CloseIcon from "../../../../assets/icons/close-new.svg";
import { makeStyles } from "@material-ui/core/styles";

const AddPhysician = ({
  onClose,
  addPhysicianAction,
  isPhysicianEdit,
  setIsPhysicianEdit,
  editPhysicianAction,
}) => {
  const [error, setError] = React.useState({
    first_name: false,
    last_name: false,
  });
  const [physicianData, setPhysicianData] = React.useState(isPhysicianEdit ? {...isPhysicianEdit} : {
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: 'Male',
    qualification: '',
    status: 'active'
  });

  const handleChange = (e) => {
    if(['first_name', 'last_name'].includes(e.target.name) && e.target.value === '') {
      setError({...error, [e.target.name]: true });
    } else if(['first_name', 'last_name'].includes(e.target.name) && e.target.value !== '') {
      setError({...error, [e.target.name]: false });
    }
    setPhysicianData({...physicianData, [e.target.name]: e.target.value});
  }

  const handleSaveClick = () => {
    addPhysicianAction(physicianData);
    onClose(false);
    setIsPhysicianEdit({});
  };

  const handleEditPhysician = () => {
    editPhysicianAction({ ...physicianData, id: isPhysicianEdit.id });
    onClose(false);
    setIsPhysicianEdit({});
  }

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt add-physician-modal" data-test="add-physician-modal">
        <FadeIn duration="300ms">
        <div className="modal-header">
            <div>
                <img src={CaseIcon} alt="case" />
                <p className="title">{isPhysicianEdit ? 'Edit' : 'Create'} Ordering Physician</p>
            </div>
            <img className="close-icon" src={CloseIcon} alt="close" onClick={() => onClose(false)} />
        </div>
          <div className="modal_middle">
            <div className="form-group">
                <div className="physician-name">
            <div className="input-field">
                <label>
                First Name<sup className="required">*</sup>
                </label>
                <input
                    type="text"
                    required
                    name="first_name"
                    data-test="first-name"
                    onChange={handleChange}
                    value={physicianData.first_name}
                />
                <span>{error.first_name && 'Enter first name'}</span>
            </div>
            <div className="input-field">
                <label>Middle Name</label>
                <input
                    type="text"
                    name="middle_name"
                    onChange={handleChange}
                    value={physicianData.middle_name}
                />
            </div>
            <div className="input-field">
                <label>
                Last Name<sup className="required">*</sup>
                </label>
                <input
                    type="text"
                    value={physicianData.last_name}
                    name="last_name"
                    data-test="last-name"
                    onChange={handleChange}
                    required
                />
                <span>{error.last_name && 'Enter last name'}</span>
            </div>
            </div>
            </div>

            <div className="form-group">
                <div className="gender">
                    <span>
                        Gender{!isPhysicianEdit && <sup className="required">*</sup>}
                    </span>
                    <label className="specific-gender male">
                        Male
                        <input
                            type="radio"
                            name="gender"
                            value="Male"
                            checked={physicianData.gender === "Male"}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <label className="specific-gender">
                        Female
                        <input
                            type="radio"
                            name="gender"
                            value="Female"
                            checked={physicianData.gender === "Female"}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
            </div>

            <div className="input-field qualification">
                <label>Qualification</label>
                <input
                    type="text"
                    onChange={handleChange}
                    name="qualification"
                    value={physicianData.qualification}
                />
            </div>
            {isPhysicianEdit && (
              <div className="input-field deactivate">
                  <input type="checkbox" name="deactivate" id="deactivate" checked={physicianData.status === 'inactive'} onChange={(e) => {
                    e.target.checked ? 
                    setPhysicianData({...physicianData, status: 'inactive' })
                    : setPhysicianData({...physicianData, status: 'active' })
                  }}/>
                  <label htmlFor="deactivate">Deactivate</label>
              </div>
            )}
          </div>
          <div className="prompt-bottom" style={{ marginTop: 0 }}>
            <SecondaryButton label="Cancel" onClick={() => {
              onClose(false)
              setIsPhysicianEdit({});
            }} />
            <PrimaryButton
              label="Save"
              data-test="save-btn"
              onClick={() => !isPhysicianEdit ? handleSaveClick() : handleEditPhysician()}
              disabled={!physicianData.first_name || !physicianData.last_name}
              extraClass="prompt-done"
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

export default AddPhysician;
