import React from "react";
import Logo from "../../../assets/images/crosscope-logo.svg";
import { SecondaryButton } from "../../../components";

export default function AdminHeader({ userLogOut }) {
  return (
    <div className="admin-header">
      <div className="logo" onClick={(e) => topToPage(e)}>
        <img src={Logo} alt="Crosscope" onClick={(e) => topToPage(e)} />
      </div>
      <div className="prompt-bottom" style={{ marginTop: 0 }}>
        <SecondaryButton label="Logout" onClick={userLogOut} />
      </div>
    </div>
  );
}
