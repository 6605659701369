import React, { useEffect, useState, useRef } from "react";
import AttachItem from "../../../slidebox/components/SlideUpload/AttachItem";
import axios from "../../../../utils/axiosInstance";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Notify } from "../../../../components";
import Tooltip from "react-tooltip";
import Select from "react-select";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { getUserDetailsAction } from "../../../../actions/global-action";
import { getNotificationsAction } from "../../../../pages/notifications/actions/notification-actions";
import { ComplexImageTypes } from "../../../../../config/constants";

import AddNewRowEnableIcon from "../../../../assets/icons/add-row-enable-icon.svg";
import AddNewRowDisableIcon from "../../../../assets/icons/add-row-disable-icon.svg";
import AddSlideIcon from "../../../../assets/icons/add-slides-icon.svg";
import DeleteRowIcon from "../../../../assets/icons/delete-row-icon.svg";
import CloseIcon from "../../../../assets/icons/close-new.svg";
import { PrimaryButton, SecondaryButton } from "../../../../components";
import StorageAlert from "../../../slidebox/components/SlideUpload/StorageAlert";
import {
  showSnackBarComponent,
  setFileUploadProgress,
  setFileCanceller,
  resetSlideUploadState,
  loadSlideboxDirDataList,
  updateCaseStatusAction,
  getAllCaseDetailsTabsInfoAction,
  deleteSlideAction,
  renameSlideBoxDirectory,
  updateStainTypeAction,
} from "../../../slidebox/actions/slidebox-action";
import "./UploadSlides.scss";
import { useDateFormat } from "../../../adminDashboard/components/DashboardItems/useFormat";
import { FeatureGate } from "react-feature-gate";
import { animateScroll } from "react-scroll";
import ExistingSlides from "./ExistingSlides";

const UploadSlides = ({
  onClose,
  patientDetails,
  caseData,
  age,
  parentFiles,
  fileList,
  resetUploadState,
  selectedFolderData,
  loggedUserDetails,
  getLoggedUserDetails,
  showSnackBarComponent,
  setFileUploadProgress,
  setFileCanceller,
  selectedFolder,
  selectedFolderType,
  history,
  conversionComplete,

  getNotificationList,
  id,
  setOpenTab,

  allSlides,
  deleteSlide,
  updateCaseStatus,
  selectedListData,
  getcaseDetails,
  renameSlide,
  updateStainType,
}) => {
  const [currentFile, setCurrentFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileEdit, setFileEdit] = useState(false);
  const [changedFileName, setChangedFileName] = useState(null);
  const [fileSavingS3Path, setFileSavingS3Path] = useState(null);
  const [uplodFileIndex, setUplodFileIndex] = useState(null);
  const [fileNameExits, setFileNameExits] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [defaultSelectedStain, setDefaultSelectedStain] = useState("H&E");
  const [showMoveFilePopup, setShowMoveFilePopup] = useState(false);
  const [storageLimitExceed, setStorageLimitExceed] = useState(false);
  const [storageIsExceedPopup, setStorageIsExceedPopup] = useState(false);
  const [addNewRow, setAddNewRow] = useState([88]);
  const [slotNumber, setSlotNumber] = useState([
    {
      container: 1,
      block: 1,
      section: 1,
    },
  ]);

  const slideRef = useRef([]);

  useEffect(() => {
    if (parentFiles) {
      let temp = normalizeFile(parentFiles);
      setFiles(temp);
    }
    if (fileList.length === 0) {
      resetUploadState();
    }
  }, []);

  useEffect(() => {
    (async function loadSlides() {
      await loadSlideboxDirDataList({
        dirId: selectedFolder,
        dirType: selectedFolderType ? "case" : "root",
      });
      if (allSlides && allSlides.length > 0) {
        let temp = allSlides.map((item) => {
          const { container, block, section } = item;
          return {
            container,
            block,
            section,
          };
        });
        temp = temp.filter(
          (val, idx, self) =>
            idx ===
            self.findIndex(
              (t) =>
                t.container === val.container &&
                t.block === val.block &&
                t.section === val.section
            )
        );
        setSlotNumber([
          ...temp,
          {
            container: temp.slice(-1)[0].container,
            block: temp.slice(-1)[0].block,
            section: temp.slice(-1)[0].section + 1,
          },
        ]);
        let randArr = Array.from(
          { length: temp && temp.length },
          () => Math.random() * 100
        );
        setAddNewRow([...addNewRow, ...randArr]);
      }
    })();
  }, []);

  const normalizeFile = (files, index) => {
    let tempFiles = [];
    let newFiles;

    for (let i = 0; i < files.length; i++) {
      const nameSplitted = files[i].name.split(".");
      const extention = nameSplitted[nameSplitted.length - 1];
      const isComplexImage = checkImageIsComplex(extention);
      files[i].container = slotNumber[index].container;
      files[i].block = slotNumber[index].block;
      files[i].section = slotNumber[index].section;
      files[i].slotIndex = index;
      try {
        if (isComplexImage) {
          files[i]["checked"] = true;
          tempFiles[i] = files[i];
        } else {
          throw new TypeError();
        }
      } catch (e) {
        Notify({ value: "Unsupported File Format" });
      }
    }
    if (selectedFolderData.selectedFolderType !== "case") {
      newFiles = tempFiles.filter((fileData) => {
        const nameSplitted = fileData.name.split(".");
        if (
          nameSplitted[nameSplitted.length - 1] !== "doc" &&
          nameSplitted[nameSplitted.length - 1] !== "docx" &&
          nameSplitted[nameSplitted.length - 1] !== "pdf"
        ) {
          return true;
        } else {
          Notify({ value: "Documents cannot be uploaded to Folder" });
        }
      });
    } else {
      newFiles = tempFiles;
    }
    return newFiles;
  };

  const checkImageIsComplex = (imgtype) => {
    return ComplexImageTypes.some((type) => type === imgtype.toLowerCase());
  };

  const onUploadFile = (event, index) => {
    const target = event.target;
    const currentFileName = target.files[0].name;
    setCurrentFile(currentFileName);
    const isNewFile =
      files.find((file) => file.name === target.files[0].name) === undefined;
    if (!isNewFile) return;
    const newFiles = [...files, ...normalizeFile(target.files, index)];
    setFiles(newFiles);
    setErrorCount(0);
    checkStorageLimitExceed(files);
  };

  const onDropzonUploadFile = async (dropzoneFiles) => {
    await setFiles([...files, ...normalizeFile(dropzoneFiles)]);
    setErrorCount(0);
    checkStorageLimitExceed(files);
  };

  const toggleUploadFile = (listOfFiles) => {
    const dxAccountId = loggedUserDetails.dxAccountId;
    const rootPath = "slidebox/" + dxAccountId + "/";
    const rootPathDocument = "documents/" + dxAccountId + "/";
    const filesLength = listOfFiles.length;
    setFileSavingS3Path(rootPath);
    setFileSavingS3Path(fileSavingS3Path);
    listOfFiles.forEach((file) => (file.uploadedAt = Date.now()));
    if (listOfFiles.length > 0) {
      listOfFiles.forEach((fileData, index) => {
        const fileSplitedArray = fileData.name.split(".");
        const fileExtention = fileSplitedArray[fileSplitedArray.length - 1];
        fileData["extention"] = fileExtention;
        const uploadingIndex = index + fileList.length; // fileIndex is used to update store
        let filePath;
        if (
          fileExtention === "docx" ||
          fileExtention === "doc" ||
          fileExtention === "pdf"
        ) {
          filePath = rootPathDocument;
        } else {
          filePath = rootPath;
        }
        axios
          .get("api/slidebox/getPresignedPutUrl", {
            params: {
              fileName: filePath + fileData.name,
              fileType: fileData.type,
            },
          })
          .then((response) => {
            uploadToS3Direct(
              response.data,
              fileData,
              uploadingIndex,
              filesLength
            );
            getLoggedUserDetails();
          })
          .catch((e) => console.warn(e));
      });
      showSnackBarComponent({
        show: true,
        fileList: listOfFiles,
        conversionComplete: null,
        percentCompleted: null,
      });
    } else {
      Notify({ value: "Please select the image" });
    }
  };

  const uploadToS3Direct = (data, file, fileIndex, filesLength) => {
    const fileData = new FormData();
    Object.keys(data.params).forEach((key) => {
      if (key === "content-type") {
        fileData.append(key, "");
      } else {
        fileData.append(key, data.params[key]);
      }
    });
    fileData.append("file", file);
    let config = {
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setFileUploadProgress({ fileIndex, percentCompleted });
      },
      cancelToken: new axios.CancelToken((cancel) => {
        setFileCanceller({ fileIndex, canceller: cancel });
      }),
    };
    axios.post(data.endpoint_url, fileData, config).then((response) => {
      handleOnUploadToDataBase(response, file, filesLength);
      getLoggedUserDetails();
    });
  };

  const handleOnUploadToDataBase = async (response, file, filesLength) => {
    let fileIndex = uplodFileIndex ? uplodFileIndex + 1 : 1;
    await setUplodFileIndex(fileIndex);
    try {
      let data = {
        originalFileName: file.name,
        fileName: file.name,
        uniquePath: "slidebox",
        isLargeFile: file.size > 20971520,
        fileIndex: fileIndex,
        stain:
          typeof file.stain === "undefined" ? defaultSelectedStain : file.stain,
        filesLength,
        selectedFolderId: selectedListData.selectedList,
        selectedFolderType: "case",
        caseDetails: caseData,
        container: file.container,
        block: file.block,
        section: file.section,
      };

      let fileUploadUrl;

      const isComplexImage = checkImageIsComplex(file.extention);
      if (isComplexImage) {
        fileUploadUrl = "api/slidebox/uploadFiles";
        if (allSlides.length === 0) {
          updateCaseStatus({
            dirId: selectedListData.selectedList,
            reviewStatus: "arrived",
            isFirstSlide: true,
          });
        }
      } else {
        throw new TypeError();
      }
      if (!caseData) {
        throw new CaseError("Case details not provided", 404);
      }

      axios
        .post(fileUploadUrl, data)
        .then((response) => {
          if (response.data.msg === "success") {
            getcaseDetails({
              dirId: selectedListData.selectedList,
              dirType: "case",
            });
            if (filesLength === fileIndex) {
              history.push("/", {
                folderSelected: true,
                folderPath: fileSavingS3Path,
              });
            }
            getLoggedUserDetails();
          }
        })
        .catch((e) => console.warn(e));
    } catch (error) {
      if (error.name === "TypeError") {
        Notify({ value: "Unsupported file format" });
      } else if (error.name === "CaseError") {
        Notify({ value: error.message });
      } else {
        console.log(error, "file error");
        Notify({ value: "Please select the image" });
      }
    }
  };

  const onChangeCheck = async (index) => {
    const tempFile = _.merge({}, files[index], {
      checked: !files[index].checked,
    });
    files[index] = tempFile;
    await setFiles(files);
    setErrorCount(0);
  };

  const onRemoveFile = (index) => {
    if (files[index]) {
      files.splice(index, 1);
    }
    setFiles(files);
    setErrorCount(0);
    checkStorageLimitExceed(files);
  };

  const handleOnUpload = (response, folderPath) => {
    if (response) {
      history.push("/", {
        folderSelected: true,
        folderPath: folderPath,
      });
      getLoggedUserDetails();
    }
  };

  const goBack = () => {
    setOpenTab("create_case");
  };

  const fileRenameInit = (file, index) => {
    setChangedFileName(file.name);
    setFileEdit(true);
    setSelectedFileToRename(index);
  };

  const fileNameChangeDone = (file, editFileIndex, changedFileNameState) => {
    const splittingIndex = file.name.lastIndexOf(".");
    const extension = file.name.substring(splittingIndex + 1);
    const newFileFullName = `${changedFileNameState}.${extension}`;
    const modifyFile = new File([file], newFileFullName, {
      type: file.type,
    });
    modifyFile["checked"] = true;
    modifyFile["originalFileName"] = newFileFullName;
    modifyFile["container"] = slotNumber[file.slotIndex].container;
    modifyFile["block"] = slotNumber[file.slotIndex].block;
    modifyFile["section"] = slotNumber[file.slotIndex].section;
    modifyFile["slotIndex"] = file.slotIndex;

    const filesList = files;
    const modifiedFilesList = filesList.map((data, index) => {
      if (index === editFileIndex && changedFileNameState) {
        return modifyFile;
      } else {
        return data;
      }
    });
    setFiles(modifiedFilesList);
    setFileEdit(false);
    setErrorCount(0);
  };

  const showWarning = (event) => {
    event.preventDefault();
    Notify({ value: "Please check the agreement!!" });
  };

  const onClickUpload = (event) => {
    if (!storageLimitExceed) {
      const filesToBeUploaded = files.filter((file) => file.checked);
      toggleUploadFile(filesToBeUploaded);
      getLoggedUserDetails();
      setOpenTab("attachment");
    } else {
      setStorageIsExceedPopup(true);
    }
  };

  const setFileNameStateError = (status) => {
    const errorCount = status
      ? errorCount + 1
      : errorCount == 0
      ? 0
      : errorCount - 1;
    setFileNameExits(status);
    setErrorCount(errorCount);
  };

  const setWrapperRef = (node) => {
    wrapperRef = node;
  };

  const checkStorageLimitExceed = (files) => {
    const { storageLimit, storageUsed } = loggedUserDetails;
    const currentUploadSize = files.reduce((a, file) => a + file.size, 0);
    const totalCurrentFilesSize = currentUploadSize + storageUsed;

    if (totalCurrentFilesSize > storageLimit) {
      setStorageLimitExceed(true);
      return true;
    } else {
      setStorageLimitExceed(false);
      return false;
    }
  };

  const closeStorageExceedPopup = () => {
    setStorageIsExceedPopup(false);
  };

  const handleUploadSlides = (event, index) => {
    const tempFiles = files.filter((item) => item.slotIndex === index);

    if (!storageLimitExceed) {
      const filesToBeUploaded = tempFiles.filter((file) => file.checked);
      toggleUploadFile(filesToBeUploaded);
      getLoggedUserDetails();
    } else {
      setStorageIsExceedPopup(true);
    }
  };

  useEffect(() => {
    if (conversionComplete === "Uploaded") {
      getNotificationList();
    }
  }, [conversionComplete]);

  const scrollDown = () => {
    animateScroll.scrollToBottom({
      containerId: "upload-slides-container",
      duration: 100,
    });
  };

  const handleReactSelectMenuOpen = () => {
    setTimeout(() => {
      scrollDown();
    }, 1);
  };

  const handleAddNewRow = (index) => {
    setSlotNumber([
      ...slotNumber,
      {
        container: slotNumber[index].container,
        block: slotNumber[index].block,
        section: slotNumber[index].section + 1,
      },
    ]);
    setAddNewRow([...addNewRow, Math.random() * 100]);
    setTimeout(() => {
      scrollDown();
    }, 1);
  };

  const handleAddNewSlides = (index) => {
    slideRef.current[index].click();
    scrollDown();
  };
  const handleSlotChange = (value, index, slotType) => {
    const tempSlot = [...slotNumber];
    tempSlot[index][slotType] = value;
    if (slotType === "container") {
      tempSlot[index].block = 1;
    }
    tempSlot[index].section = 1;
    setSlotNumber(tempSlot);
  };

  const slotOptions = (index, slotType) => {
    let options = [
      {
        label: slotNumber[index][slotType],
        value: slotNumber[index][slotType],
      },
    ];
    if (addNewRow.length > 1) {
      if (
        slotType === "container" &&
        options[0].value === slotNumber.slice(-2)[0].container
      ) {
        options = [
          ...options,
          {
            label: slotNumber[index][slotType] + 1,
            value: slotNumber[index][slotType] + 1,
          },
        ];
      } else if (
        slotType === "block" &&
        slotNumber.slice(-2)[0].container === slotNumber[index]["container"] &&
        slotNumber.slice(-2)[0].block === slotNumber[index]["block"]
      ) {
        options = [
          ...options,
          {
            label: slotNumber[index][slotType] + 1,
            value: slotNumber[index][slotType] + 1,
          },
        ];
      }
    }
    return options;
  };

  const handleDeleteRow = (rowId) => {
    const AddNewRow = addNewRow.filter((item, index) => index !== rowId);
    const Files = files.filter((item) => item.slotIndex !== rowId);
    const slotRow = slotNumber;
    slotRow.pop();
    setAddNewRow(AddNewRow);
    setSlotNumber(slotRow);
    setFiles(Files);
  };

  return (
    <div className="slide-upload-page" data-test="uploadFileContainer">
      <FeatureGate feature="patient_level_information">
        <div className="patient-details">
          <span className="patient-name">
             {Object.keys(patientDetails).length > 0 &&
              patientDetails.firstName +
                " " +
                (patientDetails.middleName || "") +
                " " +
                patientDetails.lastName +
                "/" +
                patientDetails.gender[0]}
          </span>
          <div className="mrn-dob-age">
            <span>MRN Number: {patientDetails.mrnNumber}</span>
            <span>D.O.B: {useDateFormat({ date: patientDetails.dob })}</span>
            <span>AGE: {age}</span>
          </div>
        </div>
      </FeatureGate>

      <div className="slide-upload-container">
        <div className="upload-content" id="upload-content-area">
          <div className="container" id="upload-slides-container">
            <input
              multiple
              onChange={onUploadFile}
              type="file"
              data-test="upload-file"
            />
            <div className="folder-heading">
              <span>Container</span>
              <span>Block</span>
              <span>Section</span>
              <span style={{ marginLeft: "auto", width: "30px" }}>
                <InfoOutlinedIcon
                  style={{ fontSize: 20 }}
                  color="disabled"
                  data-place="left"
                  data-tip={`Supported file formats:- ${ComplexImageTypes.join(
                    ", "
                  )}`}
                  data-for="upload-slidebox-tooltip"
                />
              </span>
            </div>
            {addNewRow.map((row, index) => (
              <div key={index}>
                <div className="folder-select-action">
                  <div className="folder-select">
                    <Select
                      options={slotOptions(index, "container")}
                      className="react-select-dropdown"
                      customStyles={true}
                      isSearchable={false}
                      value={{
                        label: slotNumber[index].container,
                        value: slotNumber[index].container,
                      }}
                      style={{
                        width: "10%",
                        height: "30px",
                        input: { fontSize: "12px" },
                        placeholder: { fontSize: "12px" }, //change here
                        color: "#9e9ea6",
                        focus: "disabled",
                      }}
                      onMenuOpen={() => handleReactSelectMenuOpen()}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={({ value }) =>
                        handleSlotChange(value, index, "container")
                      }
                      isDisabled={!(index + 1 === addNewRow.length)}
                    />
                    <Select
                      options={slotOptions(index, "block")}
                      className="react-select-dropdown"
                      customStyles={true}
                      isSearchable={false}
                      value={{
                        label: slotNumber[index].block,
                        value: slotNumber[index].block,
                      }}
                      style={{
                        width: "10%",
                        height: "30px",
                        input: { fontSize: "12px" },
                        placeholder: { fontSize: "12px" },
                        color: "#9e9ea6",
                        focus: "disabled",
                      }}
                      onMenuOpen={() => handleReactSelectMenuOpen()}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={({ value }) =>
                        handleSlotChange(value, index, "block")
                      }
                      isDisabled={!(index + 1 === addNewRow.length)}
                    />
                    <Select
                      options={[
                        {
                          label: slotNumber[index].section,
                          value: slotNumber[index].section,
                        },
                      ]}
                      className="react-select-dropdown"
                      customStyles={true}
                      isSearchable={false}
                      value={{
                        label: slotNumber[index].section,
                        value: slotNumber[index].section,
                      }}
                      style={{
                        width: "10%",
                        height: "30px",
                        input: { fontSize: "12px" },
                        placeholder: { fontSize: "12px" },
                        color: "#9e9ea6",
                        focus: "disabled",
                      }}
                      onMenuOpen={() => handleReactSelectMenuOpen()}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={({ value }) => {}}
                      isDisabled={!(index + 1 === addNewRow.length)}
                    />
                  </div>
                  <div className="action-container">
                    {index + 1 === addNewRow.length ? (
                      <img
                        src={AddNewRowEnableIcon}
                        alt="addNewRow"
                        onClick={() => handleAddNewRow(index)}
                        data-tip="Add New Row"
                        data-for="upload-slidebox-tooltip"
                      />
                    ) : (
                      <img src={AddNewRowDisableIcon} alt="addNewRow" />
                    )}
                    <div class="image-upload">
                      <label for="file-input">
                        <img
                          src={AddSlideIcon}
                          alt="addSlides"
                          onClick={() => handleAddNewSlides(index)}
                          data-tip="Add Slides"
                          data-for="upload-slidebox-tooltip"
                        />
                      </label>

                      <input
                        multiple
                        ref={(el) => (slideRef.current[index] = el)}
                        onChange={(e) => onUploadFile(e, index)}
                        type="file"
                        data-test="upload-file"
                        value=""
                      />
                    </div>

                    {addNewRow.length > 1 && (
                      <img
                        src={DeleteRowIcon}
                        alt="deleteRow"
                        onClick={() => handleDeleteRow(index)}
                        data-tip="Delete Row"
                        data-for="upload-slidebox-tooltip"
                      />
                    )}
                  </div>
                </div>
                <div className="slide-item">
                  <div className="select-item-header">
                    {files.length > 0 &&
                      files.filter((item) => item.slotIndex === index)[0] && (
                        <React.Fragment>
                          <span>File name</span>
                          <span>Stain</span>
                        </React.Fragment>
                      )}
                  </div>
                  <div className="list-items" id="uploadedFilesListContainer">
                    {allSlides &&
                      allSlides.length > 0 &&
                      allSlides.map(
                        (file, i) =>
                          file.container === slotNumber[index].container &&
                          file.block === slotNumber[index].block &&
                          file.section === slotNumber[index].section && (
                            <ExistingSlides
                              // changedFileName={changedFileName}
                              key={i}
                              file={file}
                              index={i}
                              renameSlideAction={renameSlide}
                              updateStainType={updateStainType}
                              deleteSlide={deleteSlide}
                              getcaseDetails={getcaseDetails}
                              selectedListData={selectedListData}
                              // fileNameEditDone={fileNameChangeDone}
                              // onChangeCheck={onChangeCheck}
                              // onRemoveFile={onRemoveFile}
                              // userId={id}
                              // uploadFileNameError={setFileNameStateError}
                            />
                          )
                      )}
                    {files.map(
                      (file, i) =>
                        file.slotIndex === index && (
                          <AttachItem
                            changedFileName={changedFileName}
                            key={i}
                            file={file}
                            index={i}
                            fileNameEditDone={fileNameChangeDone}
                            onChangeCheck={onChangeCheck}
                            onRemoveFile={onRemoveFile}
                            userId={id}
                            uploadFileNameError={setFileNameStateError}
                          />
                        )
                    )}
                  </div>
                </div>
                <Tooltip
                  id="upload-slidebox-tooltip"
                  place="top"
                  type="dark"
                  effect="solid"
                />
              </div>
            ))}
            <div className="submit-wrapper">
              <div className="d-flex justify-content-between align-items-center">
                <div className="prompt-bottom">
                  {files.length === 0 ? (
                    <PrimaryButton
                      label="Upload later"
                      extraClass="upload-all-btn"
                      onClick={() => setOpenTab("attachment")}
                    />
                  ) : (
                    <React.Fragment>
                      <SecondaryButton
                        label="Cancel"
                        onClick={() => onClose()}
                      />
                      <PrimaryButton
                        label="Upload All"
                        extraClass="upload-all-btn"
                        disabled={errorCount > 0 || files.length === 0}
                        onClick={(e) => onClickUpload(e)}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {storageIsExceedPopup && (
        <StorageAlert
          acceptMessage={closeStorageExceedPopup}
          closeStorageAlert={closeStorageExceedPopup}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedUserDetails: state.Global.loggedUserDetails,
  fileList: state.SlideBox.fileList,
  folders: state.SlideBox.folders,
  selectedFolderData: state.SlideBox.selectedFolderData,
  conversionComplete: state.SlideBox.conversionComplete,
  showSnackbar: state.SlideBox.showSnackBar,
  allSlides: state.SlideBox.allSlides,
  selectedListData: state.SlideBox.selectedListData,
  ...state.SlideBox.selectedFolderData,
});

const mapDispatchToProps = (dispatch) => ({
  showSnackBarComponent: (payload) => dispatch(showSnackBarComponent(payload)),
  setFileUploadProgress: (payload) => dispatch(setFileUploadProgress(payload)),
  setFileCanceller: (payload) => dispatch(setFileCanceller(payload)),
  getLoggedUserDetails: () => dispatch(getUserDetailsAction()),
  resetUploadState: () => dispatch(resetSlideUploadState()),
  getNotificationList: () => dispatch(getNotificationsAction()),
  loadSlideboxDirDataList: (payload) =>
    dispatch(loadSlideboxDirDataList(payload)),
  updateCaseStatus: (payload) => dispatch(updateCaseStatusAction(payload)),
  getcaseDetails: (payload) =>
    dispatch(getAllCaseDetailsTabsInfoAction(payload)),
  deleteSlide: (payload) => dispatch(deleteSlideAction(payload)),
  renameSlide: (payload) => dispatch(renameSlideBoxDirectory(payload)),
  updateStainType: (payload) => dispatch(updateStainTypeAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UploadSlides));
