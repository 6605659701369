import React, { useEffect, useState } from "react";
import "../../AiAnalytics.scss";
import { Select } from "antd";

const AnalysisTypeSlideDetails = ({
  imageMetaData,
  uniqueId,
  selectedSlideDataToAnalyze,
  selectedAlgorithm,
  setSelectedAlgorithm,
  setContinueButtonDisabled,
  selectedDirDetails,
  selectedFolderData,
  fromSlidebox,
}) => {
  const { Option } = Select;
  const { description, dirDataName } = selectedDirDetails;

  const toggleExpansible = (e) => {
    let el = e.target ? e.target : e;
    el.classList.toggle("active");
    let content = el.nextElementSibling;
    if (!content) {
      return false;
    }
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  };

  const handleSelectOption = (value) => {
    setSelectedAlgorithm(value);
    setContinueButtonDisabled(false);
  };

  useEffect(() => {
    const expansibleItems = document.getElementsByClassName("default_open");
    for (let i = 0; i < expansibleItems.length; i++) {
      toggleExpansible(expansibleItems[i]);
    }
  }, []);

  return (
    <div className="image-details-section">
      <div className="slide-details-section">
        <div className="expansible-item">
          <div
            className="expansible-header default_open"
            onClick={toggleExpansible}
          >
            Slide Details
          </div>
          <div class="expansible-content">
            <div className="item">
              <div className="slide-item-heading item-slide-name">Name:</div>
              <div className="item-value" id="slideUniqueIdContainer">
                {selectedSlideDataToAnalyze.dirDataName.length > 20
                  ? selectedSlideDataToAnalyze.dirDataName.substring(0, 20) +
                    "..."
                  : selectedSlideDataToAnalyze.dirDataName}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading item-unique-id">
                Unique-ID:
              </div>
              <div className="item-value" id="slideUniqueIdContainer">
                {uniqueId && uniqueId.length > 30
                  ? `${uniqueId.slice(0, 30)}
                    ${uniqueId.slice(30, 60)}
                    ${uniqueId.slice(60, 90)}
                    ${uniqueId.slice(90, 120)}
                    ${uniqueId.slice(120, 150)}
                    ${uniqueId.slice(150, 180)}
                    ${uniqueId.slice(180, uniqueId.length)}
                    `
                  : uniqueId}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading item-height">Height:</div>
              <div className="item-value">
                {imageMetaData !== undefined && imageMetaData.height}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading item-width">Width:</div>
              <div className="item-value">
                {imageMetaData !== undefined && imageMetaData.width}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading item-magnification">
                Magnification:
              </div>
              <div className="item-value">
                {imageMetaData !== undefined &&
                imageMetaData["openslide.objective-power"]
                  ? imageMetaData["openslide.objective-power"]
                  : "-"}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading item-resolution">
                Resolution:
              </div>
              <div className="item-value">
                {imageMetaData !== undefined && imageMetaData["aperio.MPP"]
                  ? imageMetaData["aperio.MPP"]
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slide-details-section">
        <div className="expansible-item">
          <h3
            className="expansible-header default_open heading-font"
            onClick={toggleExpansible}
          >
            Case Details
          </h3>
          <div class="expansible-content">
            <div className="item">
              <div className="slide-item-heading">Case Name:</div>
              <div className="item-value" id="slideUniqueIdContainer">
                {fromSlidebox
                  ? selectedDirDetails.dirDataName
                  : selectedFolderData.selectedFolderName}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading">Description</div>
              <div className="item-value" id="slideUniqueIdContainer">
                {fromSlidebox ? description : selectedFolderData.description}
              </div>
            </div>
          </div>
        </div>
      </div>

      <h3 className="analysis-type-title">Analysis Type</h3>
      <div className="algorithm-dropdown">
        <Select
          name="algorithm"
          id="algorithm"
          onChange={handleSelectOption}
          value={selectedAlgorithm}
          placeholder="Select Analysis Type"
        >
          <Option value="Nuclei Segmentation">Nuclei Segmentation</Option>
          {/* <Option value="Gleason Grading">Gleason Grading</Option> */}
          <Option value="Orion AI">Orion AI</Option>
        </Select>
      </div>
    </div>
  );
};

export default AnalysisTypeSlideDetails;
