import React, { useState, useEffect } from "react";
import {
  PrimaryButton,
  SecondaryButton,
  Overlay,
  ModalHeader,
} from "../../../components";
import { FadeIn } from "animate-components";
import { makeStyles } from "@material-ui/core/styles";

import CreateNewAccountIcon from "../../../assets/icons/new-account.svg";
import { Form, Input, Select, DatePicker } from "antd";
import "antd/dist/antd.css";

const useStyles = makeStyles({
  dropdownOption: {
    marginLeft: 10,
  },
});

const CreateNewDxAccountForm = ({
  openModal,
  onClose,
  createDxAccount,
  getDxAccountList,
}) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [usersCount, setUsersCount] = useState();
  const [totalStorage, setTotalStorage] = useState();
  const [macId, setMacId] = useState("");
  const [licenseStartDate, setLicenseStartDate] = useState();
  const [licenseEndDate, setLicenseEndDate] = useState();
  const [licenseTypeValue, setLicenseTypeValue] = useState();

  const licenseType = [
    {
      value: 1,
      label: "Dx Demo",
    },
    {
      value: 2,
      label: "Portal Demo",
    },
    {
      value: 3,
      label: "Dx Basic",
    },
    {
      value: 4,
      label: "Portal Basic",
    },
    {
      value: 5,
      label: "AI Plus",
    },
    {
      value: 6,
      label: "Enterprise Plus",
    },
  ];

  const storageLimit = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 100,
      label: "100",
    },
    {
      value: 500,
      label: "500",
    },
    {
      value: 1024,
      label: "1024",
    },
  ];

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeUserCount = (e) => {
    setUsersCount(e);
  };

  const handleChangeStorage = (e) => {
    setTotalStorage(e);
  };

  const handleChangeMacId = (e) => {
    setMacId(e.target.value);
  };

  const handleChangeLicenseType = (e) => {
    setLicenseTypeValue(e);
  };

  const handleChangeLicenseStartDate = (date, dateString) => {
    setLicenseStartDate(dateString);
  };
  const handleChangeLicenseEndDate = (date, dateString) => {
    setLicenseEndDate(dateString);
  };

  const [componentSize, setComponentSize] = useState("default");

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };

  const handleCreateAccount = () => {
    if (!email || !totalStorage || !usersCount) {
      return;
    } else {
      createDxAccount({
        userEmail: email,
        numberOfUsers: usersCount,
        totalStorage: totalStorage,
        macId: macId,
        licenseStartDate: licenseStartDate,
        licenseEndDate: licenseEndDate,
        licenseType: licenseTypeValue,
      });
      setEmail("");
      setUsersCount();
      setTotalStorage();
      setMacId("");
      setLicenseStartDate();
      setLicenseEndDate();
      onClose(false);
    }
  };

  useEffect(() => {
    getDxAccountList();
  }, []);

  return (
    openModal && (
      <div className="form-container">
        <Overlay />
        <div className="prompt new-dx-account">
          <FadeIn duration="300ms">
            <ModalHeader
              title="Create New Account"
              onClick={() => onClose(false)}
              img={CreateNewAccountIcon}
            />
            <div className="modal_middle site-admin">
              <Form
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 14,
                }}
                layout="vertical"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
              >
                <Form.Item label="Admin Email Address">
                  <Input
                    placeholder="Enter Email Address"
                    className="input-field"
                    type="email"
                    value={email}
                    autoFocus
                    required
                    onChange={handleChangeEmail}
                    autoFocus
                  />
                </Form.Item>
                <div className="dropdown-container">
                  <div className="dropdown-section">
                    <Form.Item label="Number of Users" labelCol={{ span: 18 }}>
                      <Select
                        placeholder="Number of Users"
                        className="dropdown"
                        value={usersCount}
                        onChange={(e) => handleChangeUserCount(e)}
                      >
                        <Select.Option
                          className={classes.dropdownOption}
                          value={10}
                        >
                          10
                        </Select.Option>
                        <Select.Option
                          className={classes.dropdownOption}
                          value={50}
                        >
                          50
                        </Select.Option>
                        <Select.Option
                          className={classes.dropdownOption}
                          value={100}
                        >
                          100
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="dropdown-section">
                    <Form.Item label="Total Storage" labelCol={{ span: 18 }}>
                      <Select
                        placeholder="Total Storage"
                        className="dropdown"
                        value={totalStorage}
                        onChange={(e) => handleChangeStorage(e)}
                      >
                        {storageLimit.map((storage) => (
                          <Select.Option
                            value={storage.value}
                            className={classes.dropdownOption}
                          >
                            {storage.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="dropdown-section">
                  <Form.Item label="License Type" labelCol={{ span: 18 }}>
                    <Select
                      placeholder="License Type"
                      className="dropdown"
                      value={licenseTypeValue}
                      onChange={(e) => handleChangeLicenseType(e)}
                    >
                      {licenseType.map((type) => (
                        <Select.Option
                          value={type.value}
                          style={{ marginLeft: 10 }}
                        >
                          {type.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <Form.Item label="Mac Id">
                  <Input
                    placeholder="Enter Mac Id"
                    className="input-field"
                    value={macId}
                    onChange={handleChangeMacId}
                  />
                </Form.Item>
                <div className="date-picker-container">
                  <div className="date-picker">
                    <Form.Item
                      label="License Start Date"
                      labelCol={{ span: 18 }}
                    >
                      <DatePicker
                        className="date-picker"
                        // value={licenseStartDate}
                        onChange={handleChangeLicenseStartDate}
                      />
                    </Form.Item>
                  </div>
                  <div className="date-picker">
                    <Form.Item label="License End Date" labelCol={{ span: 18 }}>
                      <DatePicker
                        className="date-picker"
                        // value={licenseEndDate}
                        onChange={handleChangeLicenseEndDate}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
              <div className="prompt-bottom" style={{ marginTop: 0 }}>
                <SecondaryButton
                  label="Cancel"
                  onClick={() => onClose(false)}
                />
                <PrimaryButton
                  label="Create"
                  onClick={handleCreateAccount}
                  extraClass="prompt-done"
                />
              </div>
            </div>
          </FadeIn>
        </div>
      </div>
    )
  );
};

export default CreateNewDxAccountForm;
