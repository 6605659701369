import React, { useRef, useState } from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import {
  getSlideboxCaseDataAction,
  getSlideboxNewAndCompletedCaseDataAction,
} from "../../../actions/adminDashboard-action";
import MonthChart from "./MonthChart";
import WeekChart from "./WeekChart";
import DayChart from "./DayChart";

const ProductivityBarChart = ({
  caseStatusData,
  getSlideboxCaseData,
  getSlideboxCaseStatusData,
}) => {
  const svgRef = useRef();
  const [monthSelected, setMonthSelected] = useState(true);
  const [weekSelected, setWeekSelected] = useState(false);
  const [daySelected, setDaySelected] = useState(false);

  const displayProductivityData = (type) => {
    if (type === "week") {
      setWeekSelected(true);
      setMonthSelected(false);
      setDaySelected(false);
    } else if (type === "month") {
      setMonthSelected(true);
      setWeekSelected(false);
      setDaySelected(false);
    } else if (type === "day") {
      setMonthSelected(false);
      setWeekSelected(false);
      setDaySelected(true);
    }
    getSlideboxCaseData();
    getSlideboxCaseStatusData(type);
  };

  return (
    caseStatusData.length > 0 && (
      <div className="new-completed-container">
        <div className="chart-header">
          <span class="chart-heading">New Vs. Completed Cases</span>
          <div className="button-section">
            <div
              className={`button-container ${daySelected ? `selected` : ""}`}
            >
              <span
                className="chart-button"
                onClick={() => displayProductivityData("day")}
              >
                D
              </span>
            </div>
            <div
              className={`button-container ${weekSelected ? `selected` : ""}`}
              onClick={() => displayProductivityData("week")}
            >
              <span className="chart-button">W</span>
            </div>
            <div
              className={`button-container ${monthSelected ? `selected` : ""}`}
              onClick={() => displayProductivityData("month")}
            >
              <span className="chart-button">M</span>
            </div>
          </div>
        </div>
        {monthSelected && caseStatusData.length > 0 && (
          <MonthChart caseStatusData={caseStatusData} />
        )}
        {weekSelected && <WeekChart />}
        {daySelected && <DayChart />}
        <div className="chart-indicators stacked">
          <div className="circle completed"></div>
          <span className="status completed">Completed</span>
          <div className="circle new"></div>
          <span className="status new">New</span>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
  caseStatusData: state.Dashboard.caseStatusData,
});

const mapDispatchToProps = (dispatch) => ({
  getSlideboxCaseStatusData: (payload) =>
    dispatch(getSlideboxNewAndCompletedCaseDataAction(payload)),
  getSlideboxCaseData: () => dispatch(getSlideboxCaseDataAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductivityBarChart);
