import { store } from '../store';

export const check = (task, taskContainer, action, data) => {
  const { Global } = store.getState();
  const permissions =
    Global.permissions !== null &&
    typeof Global.permissions !== 'undefined' &&
    Global.permissions[taskContainer][task];
  if (!permissions || permissions === 'noAccess') {
    return false;
  } else {
    return true;
  }
};
