import moment from 'moment';
import { store } from '../../../../store'
import { connect } from 'react-redux'

export const useNameFormat = (firstname, lastname) => {
    const format = store.getState().Global.loggedUserDetails.displayNameFormat;
    const fullname = format === "firstname lastname" ? `${firstname} ${lastname}` : `${lastname} ${firstname}`
    return fullname
}

export const useDateFormat = ({ date, timeFormat }) => {
    const format = store.getState().Global.loggedUserDetails.date_format;
    const timezone = store.getState().Global.loggedUserDetails.timezone;
    const formattedDate = moment(date).utcOffset(timezone ? timezone : '+0:00').format(timeFormat ? timeFormat : format ? format : 'MM-DD-YYYY')
    return formattedDate;
}