import React from "react";
import More from "../../../assets/icons/more.svg";
import ImageTag from "../../slidebox/components/SlideItems/ImageTag";
import { Menu, Dropdown } from "antd";

const SiteAdminThreeVerticalDots = ({
  selectedRowIndex,
  setSelectedRowIndex,
  index,
  resendInvitation,
  details,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setSelectedRowIndex(index);
  };

  const handleResendInvitation = (userId) => {
    resendInvitation({ userId: userId });
    setAnchorEl(null);
  };

  const menu = () => {
    let expired = false;
    if (
      details.invitationExpiryAt &&
      details.invitationExpiryAt.invitation_expiry_at
    ) {
      expired =
        new Date() >= new Date(details.invitationExpiryAt.invitation_expiry_at);
    }
    return (
      <Menu>
        <Menu.Item key="0">
          <span>Change License Type</span>
        </Menu.Item>
        {details.accountStatus &&
          details.accountStatus.status === "invited" &&
          expired && (
            <Menu.Item
              key="1"
              onClick={() =>
                handleResendInvitation(details.accountStatus.user_id)
              }
            >
              <span>Resend Invitation</span>
            </Menu.Item>
          )}
      </Menu>
    );
  };

  return (
    <div>
      <Dropdown overlay={menu} trigger={["click"]}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <ImageTag
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={`user-details-more-icon site-admin
              ${selectedRowIndex === index ? "active" : ""}`}
            src={More}
            anchorEl={anchorEl}
          />
        </a>
      </Dropdown>
    </div>
  );
};

export default SiteAdminThreeVerticalDots;
