import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { deleteProcedureAction } from "../../actions/adminDashboard-action";
import Paper from "@material-ui/core/Paper";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { PrimaryButton, Notify } from "../../../../components";
import Loader from "../../../slidebox/components/SlideTable/Loader";
import EditIcon from "../../../../assets/icons/InfoEdit.svg";
import DeleteIcon from "../../../../assets/icons/trash-icon.svg";
import SearchIcon from "../../../../assets/icons/search-physician-icon.svg";
import CloseIcon from "../../../../assets/icons/remove-user.png";
import Can from "../../../../hoc/FullControllHoc/can";

const useStyles = makeStyles({
  container: {
    height: "55vh !important",
  },
});

export const ProcedureMaster = ({
  showLoader,
  blink,
  setAddNewProcedure,
  filteredProcedures,
  setFilteredProcedures,
  proceduresList,
  deleteProcedure,
  searchProcedure,
  setSearchProcedure,
  setIsProcedureEdit,
  ...rest
}) => {
  const classes = useStyles();

  const rowsPerPage = 3;
  const [page, setPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = () => {
    if(searchProcedure) {
      const searchQuery = searchProcedure.toLowerCase();
      setFilteredProcedures(proceduresList && proceduresList.filter(procedure => procedure.procedureName.toLowerCase().includes(searchQuery)));
      setIsSearching(true);
      setPage(0);
    }
  }

  const handleDelete = async (procedure_id) => {
    if(filteredProcedures.sort(sortProcedures).slice(page*rowsPerPage, page*rowsPerPage + rowsPerPage).length - 1 <= 0 && page !== 0) {
      setPage(page - 1);
    }
    await deleteProcedure(procedure_id);
  }

  const sortProcedures = (a, b) => {
    return b.procedure_id - a.procedure_id
  }

  useEffect(() => {
    setFilteredProcedures(proceduresList);
  }, [proceduresList])

  return showLoader ? (
    <Loader />
  ) : (
    <div className="ordering-physicians-list roles-container" data-test="procedures-list">
      <div className="header-container">
        <div className="search-input" data-test="procedure-search-bar">
            <input
                className="filter-input"
                type="text"
                placeholder="Search Procedure"
                value={searchProcedure}
                onChange={(e) => setSearchProcedure(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            />
            {!isSearching ? <img
              src={SearchIcon}
              className="searchIcon"
              onClick={handleSearch}
              alt="search"
            /> : <img
              src={CloseIcon}
              className="close-icon"
              width="12px"
              height="12px"
              onClick={() => {
                setIsSearching(false);
                setSearchProcedure('');
                setFilteredProcedures(proceduresList);
              }}
              alt="close"
            /> }
        </div>
        <Can
          task={"manage-role"}
          taskContainer="roles"
          edit={() => (
            <PrimaryButton
              label="ADD NEW PROCEDURE"
              extraClass="add-physician"
              onClick={() => {
                setAddNewProcedure(true); 
                setIsProcedureEdit(false);
              }}
            />
          )}
        />
      </div>
      <div className="roles-list-container">
        <TableContainer
          className={classes.container + "table-scroll"}
          data-test="procedures-table"
          elevation={0}
          component={Paper}
        >
          <Table className="roles-table physician-table" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" className="table-heading physician-name-heading">
                  Procedure
                </TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={blink ? "blink-user-list-table" : ""}>
              {filteredProcedures &&
                filteredProcedures
                .sort(sortProcedures)
                .slice(page*rowsPerPage, page*rowsPerPage + rowsPerPage)
                .map((procedure, index) => {
                  return (
                      <TableRow data-test="procedure-table-row" key={index}>
                          <React.Fragment>
                            <TableCell
                              align="left"
                              className="role-name"
                            >
                              {procedure.procedureName}
                                        <img
                                          width="14px"
                                          height="14px"
                                          src={EditIcon}
                                          onClick={() => {
                                            setAddNewProcedure(true); 
                                            setIsProcedureEdit({ 
                                              id: procedure.procedureId,
                                              procedureName: procedure.procedureName,
                                              procedureCode: procedure.procedureCode,
                                              status: procedure.status,
                                            });
                                          }}
                                          className="edit-role-icon"
                                        />
                                        {procedure.used_in_case === "not_used" && <img
                                          width="15px"
                                          height="15px"
                                          src={DeleteIcon}
                                          data-test="delete-button"
                                          onClick={() => handleDelete(procedure.procedureId)}
                                          className="edit-role-icon"
                                        />}
                            </TableCell>
                            <TableCell
                              align="left"
                              className="role-capitalize"
                              style={{ width: "25%" }}
                            >
                              {procedure.status}
                            </TableCell>
                          </React.Fragment>
                      </TableRow>
                  )})}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ 
            width: '100%', 
            display: 'flex', 
            justifyContent: 'flex-end',
            marginTop: '1rem'
          }}>
          <Pagination
            shape='rounded'
            data-test='table-pagination'
            count={Math.ceil(filteredProcedures && filteredProcedures.length / rowsPerPage)}
            page={page + 1}
            disabled={filteredProcedures && filteredProcedures.length <= rowsPerPage}
            onChange={(e, currPage) => setPage(currPage - 1)}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  deleteProcedure: (payload) => dispatch(deleteProcedureAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureMaster);