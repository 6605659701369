import React, {useEffect, useState, useRef} from 'react'
import { FadeIn } from 'animate-components';
import { connect } from "react-redux";
import axios from "../../../../utils/axiosInstance";
import { Notify } from "../../../../components";
import DocIcon from '../../../../assets/icons/DocIcon.svg'
import PdfIcon from '../../../../assets/icons/PdfIcon.svg'
import CsvIcon from '../../../../assets/icons/csvIcon.svg'
import TextIcon from '../../../../assets/icons/textIcon.svg'
import ImgIcon from '../../../../assets/icons/imgIcon.svg'
import AddAttachmentIcon from "../../../../assets/icons/attachments.svg";
import { withRouter } from "react-router";
import { getUserDetailsAction } from "../../../../actions/global-action"
import { 
    setFileCanceller,
    setFileUploadProgress,
  } from "../../actions/slidebox-action";
import {
    Overlay,
    ModalHeader,
    PrimaryButton,
    SecondaryButton,
  } from '../../../../components';


const AddAttachment = ({
    loggedUserDetails,
    history,
    fileList,
    setFileUploadProgress,
    setFileCanceller,
    getLoggedUserDetails,
    selectedListType,
    selectedList,
    attachInput,
    setAttachInput
}) => {
    const [attachment, setAttachment] = useState(null);
    const [attachModal, setAttachModal] = useState(false);
    const [filename, setFilename] = useState('');
    const [fileSavingPath, setFileSavingPath] = useState(null)
    const fileInput = useRef(null);

    useEffect(()=> {
        if(attachment !== null){
          setFilename(attachment.name.split('.'))
        }
      }, [attachment])
    
    useEffect(()=> {
        if(attachInput){
            fileInput.current.click()
            setAttachInput(false)
        }
    }, [attachInput])
    
      const sizeInMb = (x) => {
        const units = ['bytes', 'KB', 'MB']
        let l = 0, n = parseInt(x, 10) || 0;
    
        while(n >= 1024 && ++l){
          n = n/1024;
        }
        return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
      }
    
      const toggleUploadFile = (file) => {
        const dxAccountId = loggedUserDetails.dxAccountId;
        const rootPath = "slidebox/" + dxAccountId + "/";
        const rootPathDocument = "documents/" + dxAccountId + "/";
        file.uploadedAt = Date.now()
        setFileSavingPath(rootPath)
        if (file) {
            const fileSplitedArray = file.name.split(".");
            const fileExtension = fileSplitedArray[fileSplitedArray.length - 1];
            const uploadingIndex = fileList.length;
            let filePath;
            if (
              fileExtension === "docx" ||
              fileExtension === "doc" ||
              fileExtension === "csv" ||
              fileExtension === "xls" ||
              fileExtension === "xlsx" ||
              fileExtension === "pdf"
            ) {
              filePath = rootPathDocument;
            } else {
              filePath = rootPath;
            }
            axios
              .get("api/slidebox/getPresignedPutUrl", {
                params: {
                  fileName: filePath + file.name,
                  fileType: file.type,
                },
              })
              .then((response) => {
                uploadToS3Direct(
                  response.data,
                  file,
                  uploadingIndex,
                  1
                );
                getLoggedUserDetails();
              })
              .catch((e) => console.warn(e));
        } else {
          Notify({ value: "No files selected" });
        }
      };
    
      const uploadToS3Direct = (data, file, fileIndex, filesLength) => {
        const fileData = new FormData();
        Object.keys(data.params).forEach((key) => {
          if (key === "content-type") {
            fileData.append(key, "");
          } else {
            fileData.append(key, data.params[key]);
          }
        });
        fileData.append("file", file);
        let config = {
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setFileUploadProgress({ fileIndex, percentCompleted });
          },
          cancelToken: new axios.CancelToken((cancel) => {
            setFileCanceller({ fileIndex, canceller: cancel });
          }),
        };
        axios.post(data.endpoint_url, fileData, config).then((response) => {
          handleOnUploadToDataBase(response, file, filesLength);
          getLoggedUserDetails();
        });
      };
    
      const handleOnUploadToDataBase = async (response, file, filesLength) => {
        let fileIndex = 1
        try {
          let data = {
            originalFileName: file.name,
            fileName: file.name,
            uniquePath: "slidebox",
            isLargeFile: file.size > 20971520,
            fileIndex: fileIndex,
            stain: "",
            filesLength,
            selectedFolderId: selectedList,
            selectedFolderType: selectedListType,
          };
    
          let fileUploadUrl;
          if (
            filename[filename.length - 1] === "docx" ||
            filename[filename.length - 1] === "doc" ||
            filename[filename.length - 1] === "txt" ||
            filename[filename.length - 1] === "xls" ||
            filename[filename.length - 1] === "xlsx" ||
            filename[filename.length - 1] === "bmp" ||
            filename[filename.length - 1] === "pdf"
          ) {
            fileUploadUrl = "api/slidebox/addSlideboxDocuments";
          } else {
            throw new TypeError()
          }
    
          axios
            .post(fileUploadUrl, data)
            .then((response) => {
              if (response.data.msg === "success") {
                if (filesLength === fileIndex) {
                  history.push("/", {
                    folderSelected: true,
                    folderPath: fileSavingPath,
                  });
                }
                getLoggedUserDetails();
                Notify({ value: "File Uploaded Successfully" });
              }
            })
            .catch((e) => console.warn(e));
        } catch (error) {
          if(error.name === "TypeError") {
            Notify({ value: "Unsupported file format" });
          } else {
            Notify({ value: "Please select file" });
          }
        }
      };

    return (
        <React.Fragment>
            <input
                type="file"
                name="file"
                ref={fileInput}
                onChange={(e)=> {
                    setAttachment(e.target.files[0])
                    setAttachModal(true)
                }}
                style={{display: 'none'}}
            />
            {attachModal && (
                <React.Fragment>
                <Overlay />
                <div className="prompt">
                    <FadeIn duration="300ms">
                    <ModalHeader
                        img={AddAttachmentIcon}
                        alt='attachment'
                        title='Add file to case'
                        onClick={() => setAttachModal(false)}
                        id="modalHeader"
                    />
                    <div className="prompt-middle attachment-modal">
                    {filename[filename.length - 1] === 'pdf' ? (
                        <img src={PdfIcon} className="file-type-icon" alt="pdf" />
                        ) : filename[filename.length - 1] === 'csv' || filename[filename.length - 1] === 'xls' || filename[filename.length - 1] === 'xlsx' ? (
                        <img src={CsvIcon} className="file-type-icon" alt="csv" />
                        ) : filename[filename.length - 1] === 'docx' || filename[filename.length - 1] === 'doc' ? (
                        <img src={DocIcon} className="file-type-icon" alt="doc" />
                        ) : filename[filename.length - 1] === 'jpg' || filename[filename.length - 1] === 'png' || filename[filename.length - 1] === 'jpeg' || filename[filename.length - 1] === 'bmp' ? (
                        <img src={ImgIcon} className="file-type-icon" alt="doc" />
                        ) : (
                        <img src={TextIcon} className="file-type-icon" alt="doc" />
                        )}
                        <div className="file-details">
                        <h4 className="file-name">{attachment && attachment.name}</h4>
                        <p className="file-size">{attachment && sizeInMb(attachment.size)}</p>
                        </div>
                    </div>
                    <div className="prompt-bottom">
                        <SecondaryButton label="Cancel" onClick={()=> setAttachModal(false)}/>
                        <PrimaryButton
                        label="Attach"
                        onClick={()=> {
                            toggleUploadFile(attachment)
                            setAttachModal(false)
                        }}
                        />
                    </div>
                    </FadeIn>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    loggedUserDetails: state.Global.loggedUserDetails,
    selectedListData: state.SlideBox.selectedListData,
    ...state.SlideBox.selectedFolderData,
    ...state.SlideBox.selectedListData,
    fileList: state.SlideBox.fileList,
  });

const mapDispatchToProps = (dispatch) => ({
    getLoggedUserDetails: () => dispatch(getUserDetailsAction()),
    setFileUploadProgress: (payload) => dispatch(setFileUploadProgress(payload)),
    setFileCanceller: (payload) => dispatch(setFileCanceller(payload)),
  });

  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddAttachment)
  );
