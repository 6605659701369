import axios from "../../../utils/axiosInstance";

export const dashboardUserListApi = async () => {
  try {
    return await axios.get(`/api/user/dxAccountUsers`);
  } catch (err) {
    throw err;
  }
};

export const slideBoxFileApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      `/api/slidebox/listS3Objects"`,
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxStorageDetailsApi = async () => {
  try {
    const resData = await axios.post("/api/slidebox/getSlideBoxStorageDetails");
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxCreateDirApi = async (dataParams) => {
  try {
    const resData = await axios.post(`/api/slidebox/createCase`, {
      ...dataParams,
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxRenameDirApi = async (dirType, dataParams) => {
  try {
    let endPoint = null;
    if (dirType === "folder") {
      endPoint = "renameFolder";
    }
    if (dirType === "case") {
      endPoint = "renameCase";
    }
    if (dirType === "slide") {
      endPoint = "renameSlide";
    }
    if (dirType === "document") {
      endPoint = "renameDocument";
    }

    const resData = await axios.post(`/api/slidebox/${endPoint}`, dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxLoadDirApi = async () => {
  try {
    const resData = await axios.get("/api/slidebox/getSlideBoxDirectoryList");
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxLoadDirDataListApi = async (dataParams) => {
  try {
    const resData = await axios.get("/api/slidebox/getSlideBoxDirDataList", {
      params: dataParams,
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const deleteSlideApi = async (dataParams) => {
  try {
    const resData = await axios.post("/api/slidebox/deleteSlide", dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxDeleteDirApi = async (dirType, dataParams) => {
  try {
    let endPoint = null;
    if (dirType === "folder") {
      endPoint = "deleteFolder";
    }
    if (dirType === "case") {
      endPoint = "deleteCase";
    }
    if (dirType === "slide") {
      endPoint = "deleteSlide";
    }
    if (dirType === "document") {
      endPoint = "deleteDocument";
    }

    const resData = await axios.post(`/api/slidebox/${endPoint}`, dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const worklistSearchParameters = async (worklistId) => {
  try {
    const resData = await axios.get(
      "/api/slidebox/getWorklistSearchParameters",
      {
        params: { worklistId },
      }
    );
    return resData;
  } catch (error) {
    throw error;
  }
};

export const slideBoxSearchDirApi = async (searchData) => {
  try {
    const resData = await axios.get(
      "/api/slidebox/getSlideBoxSearchDirDataList",
      {
        params: searchData,
      }
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxDirDescriptionUpdateApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/updateSlideDirDetails",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxCaseDetailsUpdateApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/updateCaseDetails",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxLabelsUpdateApi = async (dataParams) => {
  try {
    const resData = await axios.post("/api/slidebox/addLables", dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxMoveDirApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/moveDirectories",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxDirCollaboratorsApi = async (dataParams) => {
  try {
    const resData = await axios.get("/api/slidebox/getDirCollaborators", {
      params: dataParams,
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxDirAssignUsersApi = async (dataParams) => {
  try {
    const resData = await axios.get("/api/slidebox/getDirAssignUsers", {
      params: dataParams,
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxDirActivityApi = async (dataParams) => {
  try {
    const resData = await axios.get("/api/slidebox/slideboxActivity", {
      params: dataParams,
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxCollaboratorsApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/addDirCollaborators",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxRemoveCollaboratorsApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/removeDirCollaborators",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxAssignUserToCaseApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/assignUserToCase",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxRemoveAssignUserToCaseApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/removeAssignUserToCase",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const getCaseStatusApi = async () => {
  try {
    const resData = await axios.get("/api/slidebox/getCaseReviewStatus");
    return resData;
  } catch (error) {
    throw error;
  }
};

export const updateCaseStatusApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/updateCaseReviewStatus",
      dataParams
    );
    return resData;
  } catch (error) {
    throw error;
  }
};

export const slideboxAdvancedSearchApi = (dataParams) => {
  try {
    const resData = axios.post(
      "/api/slidebox/getAdvancedSearchDetails",
      dataParams
    );
    return resData;
  } catch (err) {
    //console.log(err);
  }
};

export const slideBoxFileDownloadApi = (dataParams) => {
  try {
    const resData = axios.get("/api/slidebox/getFileDownloadUrl", {
      params: dataParams,
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const getCaseDetailsInfoApi = (dataParams) => {
  try {
    const resData = axios.get("/api/slidebox/getCaseDetailsInfo", {
      params: dataParams,
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const patientSearchApi = async (search) => {
  try {
    const resData = await axios.get("/api/slidebox/getPatientDetails", {
      params: {
        search,
      },
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const updateWorklistSearchInfoApi = (dataParams) => {
  try {
    const resData = axios.patch("/api/slidebox/updateWorklist", dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const createNewWorklistApi = (dataParams) => {
  try {
    const resData = axios.post("/api/slidebox/createWorklist", dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const getWorklistForTreeviewApi = () => {
  try {
    const resData = axios.get("/api/slidebox/getWorklistForTreeview");
    return resData;
  } catch (err) {
    throw err;
  }
};

export const updateWorklistApi = (dataParams) => {
  try {
    const resData = axios.post("/api/slidebox/worklistActions", dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const getWorklistDataApi = (dataParams) => {
  try {
    const resData = axios.get("/api/slidebox/getWorklistData", {
      params: dataParams,
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const updateSystemWorklistApi = (dataParams) => {
  try {
    const resData = axios.post(
      "/api/slidebox/updateSystemWorklist",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const setOpenTabApi = (dataParams) => {
  try {
    const resData = axios.post("/api/slidebox/setOpenTab", dataParams);
    return resData;
  } catch (error) {
    throw err;
  }
};

export const setCloseTabApi = (dataParams) => {
  try {
    const resData = axios.post("/api/slidebox/setCloseTab", dataParams);
    return resData;
  } catch (error) {
    throw err;
  }
};

export const getOpenTabsApi = (dataParams) => {
  try {
    const resData = axios.get("/api/slidebox/getOpenTabs", dataParams);
    return resData;
  } catch (error) {
    throw err;
  }
};

export const changePriorityApi = (dataParams) => {
  try {
    const resData = axios.post("/api/slidebox/changePriority", dataParams);
    return resData;
  } catch (error) {
    throw err;
  }
};

export const getNotesHistoryApi = (dataParams) => {
  try {
    const resData = axios.get("/api/slidebox/getNotes", { params: dataParams });
    return resData;
  } catch (error) {
    throw err;
  }
};

export const checkAccessionNumberApi = (dataParams) => {
  try {
    const resData = axios.get("/api/slidebox/accessionNumber", {
      params: dataParams,
    });
    return resData;
  } catch (error) {
    throw err;
  }
};

export const addNotesApi = (dataParams) => {
  try {
    const resData = axios.post("/api/slidebox/addNotes", dataParams);
    return resData;
  } catch (error) {
    throw err;
  }
};

export const updateStainTypeApi = (dataParams) => {
  try {
    const resData = axios.put(
      `/api/slidebox/stain-type?slideId=${dataParams.slideId}`,
      dataParams
    );
    return resData;
  } catch (error) {
    throw error;
  }
};
