import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { deletePhysicianAction } from "../../actions/adminDashboard-action";
import Paper from "@material-ui/core/Paper";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { PrimaryButton, Notify } from "../../../../components";
import Loader from "../../../slidebox/components/SlideTable/Loader";
import EditIcon from "../../../../assets/icons/InfoEdit.svg";
import DeleteIcon from "../../../../assets/icons/trash-icon.svg";
import SearchIcon from "../../../../assets/icons/search-physician-icon.svg";
import CloseIcon from "../../../../assets/icons/remove-user.png";
import Can from "../../../../hoc/FullControllHoc/can";

const useStyles = makeStyles({
  container: {
    height: "55vh !important",
  },
});

export const OrderingPhysicians = ({
  showLoader,
  blink,
  setAddNewPhysician,
  filteredPhysicians,
  setFilteredPhysicians,
  orderingPhysicians,
  deletePhysician,
  searchPhysician,
  setSearchPhysician,
  setIsPhysicianEdit,
  ...rest
}) => {
  const classes = useStyles();

  const [editRow, setEditRow] = useState(-1);
  const rowsPerPage = 3;
  const [page, setPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = () => {
    if(searchPhysician) {
      const searchQuery = searchPhysician.toLowerCase();
      setFilteredPhysicians(orderingPhysicians && orderingPhysicians.filter(physician => `${physician.orderingPhysicianFirstName} ${physician.orderingPhysicianMiddleName ? physician.orderingPhysicianMiddleName + ' ' : ''}${physician.orderingPhysicianLastName}`.toLowerCase().includes(searchQuery)));
      setIsSearching(true);
      setPage(0);
    }
  }

  const handleDelete = async (physician_id) => {
    if(filteredPhysicians.sort(sortPhysicians).slice(page*rowsPerPage, page*rowsPerPage + rowsPerPage).length - 1 <= 0 && page !== 0) {
      setPage(page - 1);
    }
    await deletePhysician(physician_id);
  }

  const sortPhysicians = (a, b) => {
    return b.orderingPhysicianId - a.orderingPhysicianId
  }

  useEffect(() => {
    setFilteredPhysicians(orderingPhysicians);
  }, [orderingPhysicians])

  return showLoader ? (
    <Loader />
  ) : (
    <div className="ordering-physicians-list roles-container" data-test="ordering-physicians">
      <div className="header-container">
        <div className="search-input" data-test="physician-search-bar">
            <input
                className="filter-input"
                type="text"
                placeholder="Search Physician"
                value={searchPhysician}
                onChange={(e) => setSearchPhysician(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            />
            {!isSearching ? <img
              src={SearchIcon}
              className="searchIcon"
              onClick={handleSearch}
              alt="search"
            /> : <img
              src={CloseIcon}
              className="close-icon"
              width="12px"
              height="12px"
              onClick={() => {
                setIsSearching(false);
                setSearchPhysician('');
                setFilteredPhysicians(orderingPhysicians);
              }}
              alt="close"
            /> }
        </div>
        <Can
          task={"manage-role"}
          taskContainer="roles"
          edit={() => (
            <PrimaryButton
              label="ADD PHYSICIAN"
              extraClass="add-physician"
              onClick={() => {
                setAddNewPhysician(true); 
                setIsPhysicianEdit(false);
              }}
            />
          )}
        />
      </div>
      <div className="roles-list-container">
        <TableContainer
          className={classes.container + "table-scroll"}
          data-test="ordering-physician-table"
          elevation={0}
          component={Paper}
        >
          <Table className="roles-table physician-table" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" className="table-heading physician-name-heading">
                  Ordering Physician
                </TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={blink ? "blink-user-list-table" : ""}>
              {filteredPhysicians &&
                filteredPhysicians
                .sort(sortPhysicians)
                .slice(page*rowsPerPage, page*rowsPerPage + rowsPerPage)
                .map((physician, index) => {
                  return (
                      <TableRow data-test="physician-table-row" key={index}>
                          <React.Fragment>
                            <TableCell
                              align="left"
                              className="role-name"
                            >
                              {`${physician.orderingPhysicianFirstName} ${physician.orderingPhysicianMiddleName ? (physician.orderingPhysicianMiddleName + ' ') : ''}${physician.orderingPhysicianLastName}`}
                                        <img
                                          width="14px"
                                          height="14px"
                                          src={EditIcon}
                                          onClick={() => {
                                            setAddNewPhysician(true); 
                                            setIsPhysicianEdit({ 
                                              id: physician.orderingPhysicianId,
                                              first_name: physician.orderingPhysicianFirstName,
                                              middle_name: physician.orderingPhysicianMiddleName,
                                              last_name: physician.orderingPhysicianLastName,
                                              gender: physician.gender,
                                              qualification: physician.qualification,
                                              status: physician.status,
                                            });
                                          }}
                                          className="edit-role-icon"
                                        />
                                        {physician.used_in_case === "not_used" && <img
                                          width="15px"
                                          height="15px"
                                          src={DeleteIcon}
                                          data-test="delete-button"
                                          onClick={() => handleDelete(physician.orderingPhysicianId)}
                                          className="edit-role-icon"
                                        />}
                            </TableCell>
                            <TableCell
                              align="left"
                              className="role-capitalize"
                              style={{ width: "25%" }}
                            >
                              {physician.status}
                            </TableCell>
                          </React.Fragment>
                      </TableRow>
                  )})}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ 
            width: '100%', 
            display: 'flex', 
            justifyContent: 'flex-end',
            marginTop: '1rem'
          }}>
          <Pagination
            shape='rounded'
            data-test='table-pagination'
            count={Math.ceil(filteredPhysicians && filteredPhysicians.length / rowsPerPage)}
            page={page + 1}
            disabled={filteredPhysicians && filteredPhysicians.length <= rowsPerPage}
            onChange={(e, currPage) => setPage(currPage - 1)}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  deletePhysician: (payload) => dispatch(deletePhysicianAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderingPhysicians);