import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import ArrowLeft from "../../../assets/icons/arrow_left.svg";
import UploadImageIcon from "../../../assets/icons/camera.svg";
import altAvatarIcon from "../../../assets/icons/default-avatar.svg";
import { useNameFormat } from '../../adminDashboard/components/DashboardItems/useFormat';
import MemberInvation from "./member-invitation";
import User from "./user";

const GroupEdit = ({
  group,
  onSubmit,
  editError,
  footerNav,
  changeActiveTab,
}) => {
  const [file, setFile] = useState();
  const [bloblFileURL, setBloblFileURL] = useState(group.imgSrc);
  const [isPublic, setIsPublic] = useState(group.type === "public");
  const [about, setAbout] = useState(group.bio);
  const [name, setName] = useState(group.name);
  const [members, setMembers] = useState(group.members || []);
  const [nameError, setNameError] = useState();
  const [error, setError] = useState(editError);

  const fileRef = useRef(null);

  useEffect(() => {
    setError(editError);
  }, [editError]);

  const submit = () => {
    let req = {
      file,
      isPublic,
      bio: about,
      name,
      members,
    };

    onSubmit && onSubmit(req);
  };

  const checkUser = (toCheck) => {
    const checked = members.filter((user) => {
      if (user.id === toCheck.id) return user.id;
    });
    return checked.length === 0;
  };

  const onUploadFile = (e) => {
    const { files } = e.target;
    setBloblFileURL(URL.createObjectURL(files[0]));
    setFile(files[0]);
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    if (value === "") {
      setNameError("Please enter a Group name");
    } else {
      setNameError();
    }
    setName(value);
  };

  const onAboutChange = (e) => {
    const { value } = e.target;
    setAbout(value);
  };

  const addSelf = (user) => {
    if (checkUser(user)) {
      setMembers([user, ...members]);
    }
  };

  return (
    <div className="group-new">
      <div className="group-new-content">
        <div className="go-to-previous-page" onClick={() => footerNav("left")}>
          <img src={ArrowLeft} alt="go back" />
        </div>
        <h3 className="group-header">Edit Group</h3>
        <div className="group-info">
          <div className="upload-input-wrapper">
            <div className="upload-img-wrapper">
              <img
                src={_.isEmpty(bloblFileURL) ? UploadImageIcon : bloblFileURL}
                style={
                  !_.isEmpty(bloblFileURL)
                    ? { borderRadius: "100%" }
                    : { width: "50px" }
                }
                alt="upload image"
              />
            </div>
            <input
              type="file"
              accept="image/*"
              ref={fileRef}
              onChange={onUploadFile}
            />
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              onChange={onChange}
              placeholder="Group name"
              value={name}
            />
            {nameError && <p class="invalid-message">{nameError}</p>}
            <div className="textarea-wrapper">
              <h3>About</h3>
              <textarea onChange={onAboutChange} value={about || ""} />
            </div>
            <div className="group-new-members">
              <div className="group-new-title">
                <h3>Members</h3>
                <p>{members.length}</p>
              </div>
              <MemberInvation onMemberSelect={addSelf} />
              <div className="users">
                {members.map((member, i) => (
                  <User
                    key={i}
                    // TODO: remove this absolute path
                    img={member.userImageSrc || altAvatarIcon}
                    fullName={useNameFormat(member.firstName || member.firstname, member.surname)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="submit-button-wrapper">
          <button
            onClick={() => changeActiveTab("details")}
            className="cancel-button"
          >
            Cancel
          </button>
          <button
            onClick={submit}
            disabled={nameError}
            className="create-button"
          >
            Save
          </button>
          <p style={{ color: "red" }}>{error}</p>
        </div>
      </div>
    </div>
  );
};


export default GroupEdit;
