import {
  notificationListApi,
  clearNotificationApi,
  deleteNotificationApi,
  unreadNotificationsApi,
  changeNotificationStatusApi,
  acceptGroupJoinInvitationApi,
} from "../services/notification-service";

export const showLoader = (show) => {
  return {
    type: "SHOW_NOTIFICATIONS_LOADER",
    showLoader: show,
  };
};

const setNotificationList = (notifications) => {
  return {
    type: "SET_NOTIFICATION_LIST",
    payload: notifications,
  };
};

const deleteNotification = (id) => {
  return {
    type: "DELETE_NOTIFICATION",
    payload: id,
  };
};

const getNotifications = (data) => {
  return {
    type: "GET_NOTIFICATIONS",
    payload: data,
  };
};

const setUnreadNotificationList = (notifications) => {
  return {
    type: "SET_UNREAD_NOTIFICATIONS",
    payload: notifications,
  };
};

const updateNotificationStatus = (id) => {
  return {
    type: "UPDATE_NOTIFICATION_STATUS",
    payload: id,
  };
};
export const getAllNotifications = () => {
  return async (dispatch) => {
    try {
      const response = await notificationListApi();
      if (response.status === 200) {
        dispatch(getNotifications(response.body));
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getNotificationsAction = () => async (dispatch) => {
  try {
    dispatch(showLoader(true));
    const response = await notificationListApi();
    if (response.status === 200) {
      dispatch(setNotificationList(response.data.body));
    }
    dispatch(showLoader(false));
  } catch (error) {
    //console.log(error);
  }
};

export const clearNotificationAction = () => async (dispatch) => {
  try {
    const response = await clearNotificationApi();
    if (response.status === 200) {
      dispatch(setNotificationList([]));
    }
  } catch (error) {
    //console.log(error);
  }
};

export const deleteNotificationAction = (id) => async (dispatch) => {
  try {
    const response = await deleteNotificationApi(id);
    if (response.status === 200) {
      dispatch(showLoader(true));
      dispatch(deleteNotification(id));
      // dispatch();
    }
  } catch (error) {
    //console.log(error);
  }
};

export const getUnreadNotifications = () => async (dispatch) => {
  try {
    const response = await unreadNotificationsApi();
    if (response.status === 200) {
      dispatch(setUnreadNotificationList(response.data.body));
    }
  } catch (error) {
    //console.log(error);
  }
};

export const changeNotificationStatusAction = (id) => async (dispatch) => {
  try {
    const response = await changeNotificationStatusApi(id);
    if (response.status === 200) {
      dispatch(updateNotificationStatus(id));
      dispatch(getUnreadNotifications());
      dispatch(showLoader(false));
    }
  } catch (error) {
    //console.log(error);
  }
};
