import {
  getPreviousRunsApi,
  runAnalysisApi,
  deletePreviousRunsRowApi,
  getAnalysisResultApi,
} from '../services/aiAnalytics-service';

const getPreviousRuns = (data) => {
  return {
    type: 'GET_PREVIOUS_RUNS',
    payload: data,
  };
};

const showLoader = (show) => {
  return {
    type: 'SHOW_LOADER',
    payload: show,
  };
};

const runAnalysisSuccess = (data) => {
  return {
    type: 'RUN_ANALYSIS',
    payload: data,
  };
};

const runAnalysisPending = (data) => {
  return {
    type: 'RUN_ANALYSIS_PENDING',
    payload: data,
  };
};

const deleteRow = (data) => {
  return {
    type: 'DELETE_PREVIOUS_RUNS_ROW',
    payload: data,
  };
};

const getAnalysisResultPending = (status) => {
  return {
    type: 'GET_ANALYSIS_RESULT_PENDING',
    status,
  };
};

const getAnalysisResultSuccess = (status, data) => {
  return {
    type: 'GET_ANALYSIS_RESULT_SUCCESS',
    status,
    payload: data,
  };
};

const getAnalysisResultError = (status) => {
  return {
    type: 'GET_ANALYSIS_RESULT_ERROR',
    status,
  };
};

export const getPreviousRunsData = () => {
  return async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const response = await getPreviousRunsApi();
      if (response.data.success) {
        dispatch(getPreviousRuns(response.data.data));
        dispatch(showLoader(false));
      }
    } catch (error) {
      dispatch(getPreviousRuns([]));
      dispatch(showLoader(false));
    }
  };
};

export const runAnalysisAction = (data, callbackFn) => {
  const { onSuccess, onError } = callbackFn;
  return async (dispatch) => {
    try {
      dispatch(runAnalysisPending(true));
      dispatch(runAnalysisSuccess(false));
      const response = await runAnalysisApi(data);
      if (response.data.success) {
        dispatch(runAnalysisSuccess(true));
        dispatch(runAnalysisPending(false));
        onSuccess();
      } else {
        dispatch(runAnalysisSuccess(false));
        dispatch(runAnalysisPending(false));
        onError();
      }
    } catch (error) {
      dispatch(runAnalysisSuccess(false));
      dispatch(runAnalysisPending(false));
      onError();
    }
  };
};

export const deleteRowAction = (row, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      dispatch(showLoader(true));
      const response = await deletePreviousRunsRowApi(row);
      if (response.data.success) {
        dispatch(deleteRow(response.data.data));
        dispatch(showLoader(false));
        onSuccess();
      }
    } catch (error) {
      dispatch(showLoader(false));
      onError();
    }
  };
};

export const getAnalysisResultAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getAnalysisResultPending(true));
      dispatch(getAnalysisResultSuccess(false));
      dispatch(getAnalysisResultError(false));
      const response = await getAnalysisResultApi(data);
      if (response.data.success) {
        dispatch(
          getAnalysisResultSuccess(true, response.data.analysisDataArray)
        );
        dispatch(getAnalysisResultPending(false));
      } else {
        dispatch(runAnalysisPending(false));
        dispatch(getAnalysisResultError(true));
      }
    } catch (error) {
      dispatch(runAnalysisPending(false));
      dispatch(getAnalysisResultError(true));
    }
  };
};

export const showAnalysisResultInViewerAction = (
  data,
  runId,
  visible,
  isHeatmap = false
) => {
  if (isHeatmap) {
    return {
      type: 'SET_ANALYSIS_HEATMAP_RESULT_BY_ID',
      payload: data,
      runId: runId,
      visible: visible,
      isHeatmap,
    };
  } else {
    return {
      type: 'SET_ANALYSIS_RESULT_BY_ID',
      payload: data,
      runId: runId,
      visible: visible,
      isHeatmap,
    };
  }
};

export const resetShowAnalysisResultInViewerAction = () => {
  return {
    type: 'RESET_SET_ANALYSIS_RESULT_BY_ID',
  };
};
