import React from "react";
import OpenseadragonViewerRunAnalysis from "../../../../pages/imageViewer/components/react-openseadragon-viewer-run-analysis";
import AnalysisAreaPreview from "./AnalysisAreaPreview";

const SlideViewerRunAnalysis = (slideData) => {
  return (
    <div
      className={
        slideData.selectAnalysisType && !slideData.highlightAreas
          ? "analysis-slide-viewer full-width"
          : "analysis-slide-viewer"
      }
    >
      <div className="osd osd-viewer-page">
        <div className="row osd-row">
          <OpenseadragonViewerRunAnalysis
            {...slideData}
            when={"analytics"}
            showToolbar={false}
            selectAnalysisType={slideData.selectAnalysisType}
            runAnalysis={slideData.runAnalysis}
          />
        </div>
      </div>
    </div>
  );
};

export default SlideViewerRunAnalysis;
