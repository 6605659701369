import SortIcon from "@material-ui/icons/Sort";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toggleSidebarAction } from "../../../actions/global-action";
import DropdownIcon from "../../../assets/icons/dropdown-icon.svg";
import Fade from "../../../components/Animations/Fade";
import axios from "../../../utils/axiosInstance";
import { setPublicGroups } from "../actions/groups-action";
import GroupItem from "./group-item";
import LeftTabHeader from "./left-tab-header";

const GroupFlatList = ({
  groups,
  onSetGroupState,
  selectedGroupItemIndex,
  onSelectGroupItem,
  onSelectedPublicGroupItem,
  onChangeActiveTab,
  expanded,
  publicGroups,
  dispatch,
  footerNav,
  loggedUserDetails,
  dxAccountDetails,
}) => {
  const [collapseGroup, setCollapseGroup] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState(groups);
  const [inputValue, setInputValue] = useState("");
  const [timeout, setTimeoutState] = useState(undefined);
  const [mounted, setMounted] = useState(false);
  const [isSelectedPublicGroup, setIsSelectedPublicGroup] = useState(false);
  const [done, setDone] = useState(false);
  const [initialPublicGroupSet, setInitialPublicGroupSet] = useState(false);
  const inputRef = useRef(null);
  const [ascending, setAscending] = useState(true);
  const dateFormat =
    dxAccountDetails && dxAccountDetails.date_format
      ? dxAccountDetails.date_format
      : "MM-DD-YYYY";
  const timezone =
    dxAccountDetails && dxAccountDetails.timezone
      ? dxAccountDetails.timezone
      : "";

  useEffect(() => {
    setMounted(true);
    search();
    return () => {
      setMounted(false);
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    setGroupState(groups);
    setFilteredGroups(groups);
  }, [groups]);

  useEffect(() => {
    if (!footerNav) {
      if (expanded) {
        setCollapseGroup(false);
      } else {
        setCollapseGroup(true);
      }
    }
  }, [expanded]);

  useEffect(() => {
    if (inputValue !== "") {
      setDone(false);
      clearTimeout(timeout);
      if (inputValue !== "") {
        setTimeoutState(setTimeout(search, 200));
      }
      const newGroups = groups.filter((board) => {
        if (board.name.toLowerCase().includes(inputValue.toLowerCase()))
          return board;
      });
      setGroupState(newGroups);
    } else {
      setGroupState(groups);
      search();
    }
  }, [inputValue]);

  const setGroupState = (groups) => {
    setFilteredGroups(groups);
    onSetGroupState(groups);
  };

  const onChangeCollapseGroup = () => {
    if (collapseGroup) {
      onSelectGroupItem && onSelectGroupItem(-1);
    }
    setCollapseGroup(!collapseGroup);
  };

  const search = async () => {
    const currentInputValue = inputValue;
    await axios
      .get(`/api/groups/newGroups?filter=${encodeURI(currentInputValue)}`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200 && mounted) {
          const groups = [];
          response.data.TumorBoards.forEach((board) => {
            groups.push({
              creator: {
                firstname: board.firstname,
                surname: board.surname,
                username: board.username,
                id: board.group_creator,
              },
              bio: board.group_bio,
              created: board.group_created,
              id: board.group_id,
              imgSrc: board.group_img_src,
              name: board.group_name,
              slide: board.group_slide,
              type: board.group_type,
              dx_account_id: board.dx_account_id,
              isPublicGroup: true,
              admin: board.group_creator === loggedUserDetails.userID,
            });
          });
          setDone(true);
          onSetPublicGroup(groups);
        }
        if (response.status === 200 && !initialPublicGroupSet) {
          const groups = [];
          response.data.TumorBoards.forEach((board) => {
            groups.push({
              creator: {
                firstname: board.firstname,
                surname: board.surname,
                username: board.username,
                id: board.group_creator,
              },
              bio: board.group_bio,
              created: board.group_created,
              id: board.group_id,
              imgSrc: board.group_img_src,
              name: board.group_name,
              slide: board.group_slide,
              type: board.group_type,
              dx_account_id: board.dx_account_id,
              isPublicGroup: true,
              admin: board.group_creator === loggedUserDetails.userID,
            });
          });
          setInitialPublicGroupSet(true);
          onSetPublicGroup(groups);
        }
      });
  };

  const onSetPublicGroup = (groups) => {
    dispatch(setPublicGroups(groups));
  };

  const onChangeHandler = (event) => {
    setInputValue(inputRef.current.value.trim());
  };

  const onSelectedGroupItem = (index, isPublicGroup, isMobile) => {
    !isMobile ? setCollapseGroup(true) : null;
    if (!isPublicGroup) {
      onSelectGroupItem && onSelectGroupItem(index);
    } else {
      onSelectedPublicGroupItem && onSelectedPublicGroupItem(index);
    }
    changeActiveTab("image");
  };

  const changeActiveTab = (activeTab) => {
    onChangeActiveTab && onChangeActiveTab(activeTab);
  };

  const selectGroup = (index, isPublic, isMobile) => {
    dispatch(toggleSidebarAction(true));
    if (isPublic) {
      onSelectedGroupItem(index, true, isMobile);
      setIsSelectedPublicGroup(true);
    } else {
      onSelectedGroupItem(index, null, isMobile);
      setIsSelectedPublicGroup(false);
    }
  };

  return (
    <React.Fragment>
      <div
        id="group-flatlist"
        className={`group-flatlist-component group-flat-${
          collapseGroup ? "close" : "open"
        }`}
      >
        {collapseGroup && (
          <button className="dropdown-btn" onClick={onChangeCollapseGroup}>
            <img src={DropdownIcon} alt="arrow" />
          </button>
        )}
        <LeftTabHeader
          collapse={!collapseGroup}
          onChangeActiveTab={changeActiveTab}
          onChangeCollapseGroup={onChangeCollapseGroup}
          footerNav={footerNav}
        />

        {!collapseGroup && (
          <div
            className="search_box"
            style={{ padding: "5px 5px", display: "block" }}
          >
            <input
              className="search"
              style={{ width: "350px", height: "35px" }}
              type="text"
              placeholder="Search"
              onChange={onChangeHandler}
              defaultValue={inputValue}
              spellCheck="false"
              autoComplete="false"
              ref={inputRef}
            />
          </div>
        )}
        <div class="group-list">
          {inputValue === "" && (
            <React.Fragment>
              <p id="my-boards">
                {filteredGroups.length !== 0 && (
                  <SortIcon
                    onClick={() => {
                      setAscending(!ascending);
                    }}
                  />
                )}
              </p>
              {ascending
                ? filteredGroups
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((group, index) => (
                      <GroupItem
                        key={index}
                        {...group}
                        collapse={collapseGroup}
                        index={index}
                        selectedGroupItem={selectGroup}
                        active={
                          !isSelectedPublicGroup &&
                          selectedGroupItemIndex === index
                        }
                        footerNav={footerNav}
                        dateFormat={dateFormat}
                        timezone={timezone}
                        dxAccountDetails={dxAccountDetails}
                      />
                    ))
                : filteredGroups
                    .sort((b, a) => a.name.localeCompare(b.name))
                    .map((group, index) => (
                      <GroupItem
                        key={index}
                        {...group}
                        collapse={collapseGroup}
                        index={index}
                        selectedGroupItem={selectGroup}
                        active={
                          !isSelectedPublicGroup &&
                          selectedGroupItemIndex === index
                        }
                        footerNav={footerNav}
                        dateFormat={dateFormat}
                        timezone={timezone}
                        dxAccountDetails={dxAccountDetails}
                      />
                    ))}
            </React.Fragment>
          )}
          <Fade
            show={inputValue !== "" && !done}
            duration="300ms"
            delay={300}
            chaining={300}
          >
            <p id="emptyTB">Searching...</p>
          </Fade>
          <Fade
            show={inputValue !== "" && done && publicGroups.length === 0}
            duration="300ms"
            delay={300}
          >
            <p id="emptyTB">Could not find any public groups.</p>
          </Fade>
          {inputValue !== "" &&
            publicGroups.map((group, index) => (
              <GroupItem
                key={index}
                {...group}
                collapse={collapseGroup}
                index={index}
                selectedGroupItem={() => selectGroup(index, true)}
                active={
                  isSelectedPublicGroup && selectedGroupItemIndex === index
                }
                footerNav={footerNav}
                dateFormat={dateFormat}
                timezone={timezone}
                dxAccountDetails={dxAccountDetails}
              />
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { publicGroups } = state.Groups;
  const { loggedUserDetails, dxAccountDetails } = state.Global;
  return {
    publicGroups,
    loggedUserDetails,
    dxAccountDetails,
  };
};

export default connect(mapStateToProps)(GroupFlatList);
