import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ClosePopupIcon from '../../../assets/icons/close-new.svg';
import DeleteIcon from '../../../assets/icons/delete-icon-new.svg';
import { Notify, PrimaryButton, SecondaryButton } from '../../../components';
import axios from '../../../utils/axiosInstance';
import { deleteTB, setSelectedBoard } from '../actions/groups-action';

const GroupDeletePopup = ({
  close,
  loggedUser,
  selectedBoard,
  dispatch,
  changeActiveTab,
}) => {
  const [isCreator, setIsCreator] = useState(false);

  useEffect(() => {
    setIsCreator(selectedBoard.creator.id === loggedUser);
  }, []);

  const leave = async () => {
    if (isCreator) {
      const { status } = await axios
        .delete(`/api/groups/${selectedBoard.id}`, {
          withCredentials: true,
        })
        .catch(console.log);
      if (status === 200) {
        close();
        dispatch(setSelectedBoard(null));
        dispatch(deleteTB(selectedBoard.id));
        Notify({ value: 'Group Deleted Successfully' });
      }
    } else {
      const { status } = await axios
        .patch(
          '/api/groups/leaveTB',
          { tumorBoardId: selectedBoard.id },
          { withCredentials: true }
        )
        .catch(console.log);
      if (status === 200) {
        close();
        dispatch(setSelectedBoard(null));
        dispatch(deleteTB(selectedBoard.id));
      }
    }
    changeActiveTab('');
  };

  return (
    selectedBoard && (
      <div className="prompt">
        <div className="prompt-top">
          <div className="top-icon-and-text">
            <img src={DeleteIcon} alt="delete" />
            <span className="prompt-title">Delete group</span>
          </div>
          <img
            className="group-delete-close-icon"
            src={ClosePopupIcon}
            id="close-create-board"
            onClick={() => close()}
          />
        </div>
        <div class="prompt-middle">
          <div>
            <span className="prompt-content">
              Are you sure you want to delete {`"${selectedBoard.name}"`}? This
              action <b>cannot</b> be undone.
            </span>
          </div>
        </div>
        <div class="prompt-bottom">
          <SecondaryButton label="Cancel" onClick={() => close()} />
          <PrimaryButton label="Delete" onClick={() => leave()} />
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  const { loggedUserDetails } = state.Global;
  const { selectedMembers, selectedBoard } = state.Groups;
  return {
    loggedUser: loggedUserDetails.userID,
    selectedMembers,
    selectedBoard,
  };
};

export default connect(mapStateToProps)(GroupDeletePopup);
