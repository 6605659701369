import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FolderTreeView from "./SlideItems/FolderTreeView";
import {
  setOpenDirectory,
  loadSlideboxDirectory,
  loadSlideboxDirDataList,
  setSelectedDirectory,
  setExpandDirectory,
  getSearchDirData,
  setSelectedDirAssignUsers,
  setSelectedDirCollaborators,
  getMyWorklistsAction,
  setOpenTabAction,
  getWorklistDataAction,
  getAllOpenTabsAction,
  setSearchParams,
  setCurrentTab,
} from "../actions/slidebox-action";
import SlideboxSearch from "./SlideboxSearch";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, List, CssBaseline, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getUserDetailsAction } from "../../../actions/global-action";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    left: "auto",
    top: 54,
    border: "none",
    position: "unset",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "scroll",
  },
  drawerClose: {
    left: "auto",
    overflowX: "scroll",
    top: 54,
    zIndex: 1,
    border: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    width: theme.spacing(3) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(3),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  iconButton: {
    outline: "none !important",
    float: "right",
    width: 20,
    height: 20,
    marginTop: 10,
    padding: "0 !important",
    fontSize: 20,
  },
}));

const SlideboxTreeview = ({
  setSelectedDirectory,
  setExpandDirectory,
  setOpenDirectory,
  loadSlideboxDirectory,
  selectedListType,
  folderType,
  selectedFolderId,
  getSearchData,
  slideDirDataList,
  isListView,
  dxAccountUsers,
  loadSlideboxDirDataList,
  getSearchDirData,
  uploadPageLoad,
  userData,
  isModal,
  getLoggedUserDetails,
  setSelectedDirAssignUsers,
  setSelectedDirCollaborators,
  getMyWorklists,
  myWorklists,
  systemWorklists,
  newTab,
  allTabs,
  slideboxLeftSiderIsCollapse,
  setSlideboxLeftSiderIsCollapse,
  getWorklistData,
  allOpenTabsFn,
  setSearchParams,
  currentTab,
  isSearchTabActive,
  setIsSearchTabActive,
  setCurrentTab,
  isOpenedFromGroups,
  showAdvancedSearchModal,
  setShowAdvancedSearchModal,
}) => {
  const classes = useStyles();
  const [filteredData, setFilteredData] = useState(slideDirDataList);
  const [radioButtonChecked, setRadioButtonChecked] = useState({});

  useEffect(() => {
    if (!uploadPageLoad) {
      loadSlideboxDirectory();
    } else {
    }
    getLoggedUserDetails();
    getMyWorklists();
  }, []);

  const handleDrawer = () => {
    setSlideboxLeftSiderIsCollapse(!slideboxLeftSiderIsCollapse);
  };

  useEffect(() => {
    allOpenTabsFn();
  }, []);

  return (
    <div className={`${classes.root}`}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: slideboxLeftSiderIsCollapse,
          [classes.drawerClose]: !slideboxLeftSiderIsCollapse,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: slideboxLeftSiderIsCollapse,
            [classes.drawerClose]: !slideboxLeftSiderIsCollapse,
          }),
        }}
      >
        <List className="tree-view-section">
          <div className="action-buttons-newUpload">
            {slideboxLeftSiderIsCollapse && (
              <div className="slidebox-search-container">
                <SlideboxSearch
                  getSearchDirData={getSearchDirData}
                  selectedListType={selectedListType}
                  loadSlideboxDirDataList={loadSlideboxDirDataList}
                  folderType={folderType}
                  selectedFolderId={selectedFolderId}
                  getSearchData={getSearchData}
                  slideDirDataList={slideDirDataList}
                  setFilteredData={setFilteredData}
                  memberDetails={dxAccountUsers}
                  isModal={isModal}
                  filteredData={filteredData}
                  isListView={isListView}
                  setRadioButtonChecked={setRadioButtonChecked}
                  setSearchParams={setSearchParams}
                  isSearchTabActive={isSearchTabActive}
                  setIsSearchTabActive={setIsSearchTabActive}
                  showAdvancedSearchModal={showAdvancedSearchModal}
                  setShowAdvancedSearchModal={setShowAdvancedSearchModal}
                />
              </div>
            )}
            {!isModal && (
              <IconButton onClick={handleDrawer} className={classes.iconButton}>
                {slideboxLeftSiderIsCollapse ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            )}
          </div>

          <div>
            <FolderTreeView
              newTab={newTab}
              allTabs={allTabs}
              setIsSearchTabActive={setIsSearchTabActive}
              setCurrentTab={setCurrentTab}
              setOpenDirectory={setOpenDirectory}
              setSelectedDirectory={setSelectedDirectory}
              setExpandDirectory={setExpandDirectory}
              loadSlideboxDirDataList={loadSlideboxDirDataList}
              slideboxLeftSiderIsCollapse={slideboxLeftSiderIsCollapse}
              isMovePopup={false}
              setSelectedDirCollaborators={setSelectedDirCollaborators}
              setSelectedDirAssignUsers={setSelectedDirAssignUsers}
              myWorklists={myWorklists}
              systemWorklists={systemWorklists}
              roleName={userData.roleName}
              getWorklistData={getWorklistData}
              currentTab={currentTab}
              isOpenedFromGroups={isOpenedFromGroups}
            />
          </div>
        </List>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allTabs: state.SlideBox.allTabs,
  slideDirectory: state.SlideBox.folders,
  uploadPageLoad: state.SlideBox.uploadPageLoad,
  userData: state.Global.loggedUserDetails,
  ...state.SlideBox.selectedFolderData,
  ...state.SlideBox.selectedListData,
  slideDirDataList: state.SlideBox.dirDataList,
  dxAccountUsers: state.SlideBox.userList,
  myWorklists: state.SlideBox.myWorklists,
  systemWorklists: state.SlideBox.systemWorklists,
  folderType: state.SlideBox.selectedFolderData.selectedFolderType,
  selectedFolderId: state.SlideBox.selectedFolderData.selectedFolder,
  currentTab: state.SlideBox.currentTab,
});

const mapDispatchToProps = (dispatch) => ({
  newTab: (payload) => dispatch(setOpenTabAction(payload)),
  setOpenDirectory: (payload) => dispatch(setOpenDirectory(payload)),
  getMyWorklists: () => dispatch(getMyWorklistsAction()),
  setSelectedDirectory: (payload) => dispatch(setSelectedDirectory(payload)),
  setExpandDirectory: (payload) => dispatch(setExpandDirectory(payload)),
  loadSlideboxDirectory: () => dispatch(loadSlideboxDirectory()),
  loadSlideboxDirDataList: (payload) =>
    dispatch(loadSlideboxDirDataList(payload)),
  getSearchDirData: (payload) => dispatch(getSearchDirData(payload)),
  getLoggedUserDetails: () => dispatch(getUserDetailsAction()),
  setSelectedDirAssignUsers: (payload) =>
    dispatch(setSelectedDirAssignUsers(payload)),
  setSelectedDirCollaborators: (payload) =>
    dispatch(setSelectedDirCollaborators(payload)),
  getWorklistData: (payload) => dispatch(getWorklistDataAction(payload)),
  allOpenTabsFn: () => dispatch(getAllOpenTabsAction()),
  setSearchParams: (payload) => dispatch(setSearchParams(payload)),
  setCurrentTab: (payload) => dispatch(setCurrentTab(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlideboxTreeview);
