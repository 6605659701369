import React from "react";

const PatientCard = ({ patient, handleAddPatientInField }) => {
  return (
    <div
      className="patient-card"
      onClick={() => handleAddPatientInField(patient)}
    >
      <div className="basic-info">
        <div className="name-mrnNumber">
          <h4>
            {patient.middleName === null
              ? patient.firstName + " " + patient.lastName
              : patient.firstName +
                " " +
                patient.middleName +
                " " +
                patient.lastName}
          </h4>
          <span>{patient.mrnNumber && patient.mrnNumber}</span>
        </div>
        <span>{patient.contactNumber}</span>
        <span>{patient.patientEmail}</span>
      </div>
    </div>
  );
};

export default PatientCard;
