import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import { keysObject, valuesObject } from "../../../../utils/utils";
import filterArray from "./FilterArray";
import FilterIcon from "../../../../assets/icons/filter-icon.svg";

export const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={5}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

let filter = {
  roleName: [],
  status: [],
};

let counter = {};

const DashboardFilter = ({
  setFilterData,
  filterData,
  userList,
  setBlink,
  rolesData,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const roleNames = rolesData.map((data) => data.roleName);

  const handleClose = () => setAnchorEl(null);
  let filterAttributes = {
    Role: roleNames,
    Account_status: ["active", "deactivated"],
  };
  React.useEffect(() => {
    handleResetClick();
  }, [userList]);

  React.useEffect(() => {
    counter = {};
    filterData.forEach(({ roleName, status }) => {
      counter[roleName] = (counter[roleName] || 0) + 1;
      counter[status] = (counter[status] || 0) + 1;
    });
  }, [filterData]);

  const handleFilterChange = (name) => async (event) => {
    setBlink(true);
    let finalName = name === "Role" ? "roleName" : "status";
    const nameValue = event.target.value;
    const checked = event.target.checked;
    Object.keys(filter).forEach(async (filterKeys) => {
      if (filterKeys === finalName && checked) {
        filter[filterKeys].push(nameValue);
      }
      if (filterKeys === finalName && !checked) {
        filter[filterKeys].forEach((removeValue, index) => {
          if (removeValue === nameValue) {
            filter[filterKeys].splice(index, 1);
          }
        });
      }
    });
    setTimeout(() => {
      setFilterData(filterArray(userList, filter));
      setBlink(false);
    }, 500);
  };

  const handleResetClick = () => {
    const items = document.getElementsByName("checkbox");
    for (let i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox") items[i].checked = false;
    }
    filter = {
      roleName: [],
      status: [],
    };
    setBlink(true);
    setTimeout(() => {
      setFilterData(userList);
      setBlink(false);
    }, 500);
  };

  return (
    <React.Fragment>
      <button
        className="filter-btn"
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={FilterIcon} alt="" />
        {/* <i className="fas fa-filter-h"></i> */}
        Filters
      </button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="filter-dropdown"
      >
        <div className="filter-heading">
          <h5 className="filter-dropdown-title">Filter by</h5>
          <div className="reset-filters-btn">
            <button onClick={handleResetClick}>Reset filters</button>
          </div>
        </div>
        <div className="filter-wrap">
          <div className="filter-container">
            {valuesObject(filterAttributes).map((data, index) => {
              const tName = keysObject(filterAttributes)[index].replace(
                /_/g,
                " "
              );
              return (
                <div className="filter-dropdown-wrapper" key={index}>
                  <div className="filter-box">
                    <h5>{tName}</h5>
                    <div className="filter-checkbox-container">
                      {data.map((name, allIndex) => {
                        return (
                          <React.Fragment key={allIndex}>
                            <div className="filter-checkbox">
                              <input
                                type="checkbox"
                                name="checkbox"
                                value={name}
                                onChange={handleFilterChange(tName)}
                              />
                              <label htmlFor={allIndex}>
                                {name} ({counter[name] || 0})
                              </label>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </StyledMenu>
    </React.Fragment>
  );
};

export default DashboardFilter;
