import React from "react";
import emptyNotifiction from '../../assets/images/empty-notification.svg'
import PropTypes from "prop-types";

const Nothing = (props) => {
  return (
    <div className="home_last_mssg">
      <img src={emptyNotifiction} alt="emptyNotifiction" />
      <p>No new <span>Notification</span> <br /> Any new alerts will appear here</p>
    </div>
  );
};

Nothing.defaultProps = {
  mssg: "Hello, a message for you!!",
  showMssg: true,
  secondMssg: "",
  conPage: false,
};
Nothing.propTypes = {
  mssg: PropTypes.string,
  showMssg: PropTypes.bool,
  secondMssg: PropTypes.string,
  conPage: PropTypes.bool,
};

export default Nothing;
