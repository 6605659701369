import React, { useState, useEffect, useContext, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Notify } from "../../../components";
import SlideboxHeader from "../../../../deprecated/SlideboxHeader";
import SlideboxTabs from "./SlideboxTabs";
import SlideboxListView from "./SlideboxListView";
import * as slideboxAction from "../actions/slidebox-action";
import CreateDirectory from "../../../../deprecated/CreateDirectory";
import DeleteConfirm from "../../../../deprecated/DeleteConfirm";
import SharedPopup from "../../../../deprecated/SharedPopup";
import { SocketContext } from "../../../utils/serviceContext";
import GroupsSelection from "../../../../deprecated/AddToGroupPopup";
import MoveToPopUp from "../../../../deprecated/MoveToPopUp";
import Details from "./SlideItems/caseDetailsOverlay/Details";
import AssignUserPopup from "../../../../deprecated/AssignUserPopup";
import * as dashBoardAction from "../../adminDashboard/actions/adminDashboard-action";
import * as globalAction from "../../../actions/global-action";
import { useOutsideClick } from "../../../utils/utils";

const SlideBoxDataSection = ({
  slideDirectory,
  slideDirDataList,
  showLoader,
  selectedList,
  selectedListType,
  selectedListName,
  selectedFolder,
  selectedFolderType,
  selectedFolderName,
  loadSlideboxDirDataList,
  deleteSlideBoxItemAction,
  setSelectedListDirectory,
  setSelectedDirCollaborators,
  setSelectedDirAssignUsers,
  setOpenListDirectory,
  isSearch,
  selectedListSlideData,
  setSelectedSlideData,
  movePopupState,
  setSlideBoxMovePopupAction,
  setSelectedListActivity,
  isListView,
  isPatientView,
  getSearchDirData,
  getUserListDataAction,
  getUserDetailsAction,
  isModal,
  getFileDownloadUrlAction,
  deselectListDirAction,
  isFolderSelected,
  setIsFolderSelected,
  when,
  setContinueButtonDisabled,
  setSelectedSlideDataToAnalyze,
  importSlide,
  selectedSlideDataToAnalyze,
  message,
  showMessage,
  resetSlideboxMessageAction,
  worklistColumnData,
  isOpenedFromGroups,
  isSearchTabActive,
  setIsSearchTabActive,
  showDetails,
  setShowDetails,
  showAdvancedSearchModal,
}) => {
  const listRef = useRef();
  const socket = useContext(SocketContext);
  const [openCreateDir, setOpenCreateDir] = useState(false);
  const [dirType, setdDirType] = useState(null);
  const [openDeleteDir, setOpenDeleteDir] = useState(false);
  const [openSharedPopup, setOpenSharedPopup] = useState(false);
  const [showGroupsSelectionPopup, setShowGroupsSelectionPopup] = useState(
    false
  );

  const [openAllContainers, setOpenAllContainers] = useState(false);
  const [outSideList, setOutSideList] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [openAssignUserPopup, setOpenAssignUserPopup] = useState(false);
  const [switchView, setSwitchView] = useState(isListView);
  const [switchListView, setSwitchListView] = useState(isPatientView);
  const [filteredData, setFilteredData] = useState(slideDirDataList);
  const [label, setLabel] = useState("");
  const [radioButtonChecked, setRadioButtonChecked] = useState({});

  const [tabOption, setTabOption] = useState("slides");
  const [openNotesSection, setOpenNotesSection] = useState(false);
  const [openLabelsSection, setOpenLabelsSection] = useState(false);
  const [showCreateCaseModal, setShowCreateCaseModal] = useState(false);

  useOutsideClick(listRef, () => {
    deselectListDirAction();
    setRadioButtonChecked({});
    setShowDetails(false);
  });

  useEffect(() => {
    getUserDetailsAction();
    getUserListDataAction();
    getUserDetailsAction();
  }, []);

  // reloads slidebox when conversion is done
  useEffect(() => {
    getUserDetailsAction();
    socket.onConversionDone().subscribe(() => {
      loadSlideboxDirDataList({
        dirId: selectedFolder,
        dirType: selectedFolderType ? dirType : "root",
      });
    });
  }, []);

  useEffect(() => {
    showMessage &&
      Notify({
        value: message,
        done: () => resetSlideboxMessageAction(),
      });
  }, [showMessage]);

  // set the filtered results in filterData State
  useEffect(() => {
    setFilteredData(slideDirDataList);
  }, [setFilteredData, slideDirDataList]);

  // set state to declare the create directory modal is closed
  const createDirOnClose = () => {
    setOpenCreateDir(false);
  };

  // to load the contents of the folder/case entered into via Breadcrumb or ListView
  const toggleDirHandler = ({ dirId, parentDir, dirType }) => {
    setSelectedDetails({});
    const folderListId = slideDirectory.filter(
      (dirData) => dirData.dirId === dirId && dirData.caseOrFolder === dirType
    );
    loadSlideboxDirDataList({
      dirId: dirId,
      dirType: dirType ? dirType : "root",
    });
    setOpenListDirectory({
      dirId,
      listId: folderListId[0]["listId"],
      parentDir,
      dirType: dirType ? dirType : "root",
    });

    getUserDetailsAction();
  };

  // setter function for selecting folder or case from the list view
  const setSelectedDir = ({ dirId, parentDir, dirType }) => {
    const folderListId = slideDirectory.filter(
      (dirData) => dirData.dirId === dirId && dirData.caseOrFolder === dirType
    );
    setSelectedListDirectory({
      dirId,
      listId: folderListId.length > 0 ? folderListId[0]["listId"] : dirId,
      parentDir,
      dirType: dirType,
    });

    setSelectedListActivity({
      dirId,
      dirType,
    });

    if (dirType === "case" || dirType === "folder") {
      setSelectedDirCollaborators({
        dirId,
        dirType: dirType,
      });
      if (dirType === "case") {
        setSelectedDirAssignUsers({
          dirId,
          dirType: dirType,
        });
      }
      if (isModal) {
        setIsFolderSelected(true);
      }
    }

    const slideData = worklistColumnData.filter(
      (dirData) =>
        dirData.dirDataId === dirId && dirData.caseOrFolder === "slide"
    );
    if (isModal) {
      setIsFolderSelected(true);
    }
    setSelectedSlideData(slideData[0]);
  };

  // delete case/folder/slide modal
  const deleteDirItemPopup = (outSideListSelect = false) => {
    getUserDetailsAction();
    setOutSideList(outSideListSelect);
    setOpenDeleteDir(!openDeleteDir);
  };

  // handler for deleting case/folder/
  const handleDeleteDirItem = (e) => {
    setShowDetails(false);
    deleteSlideBoxItemAction(
      outSideList ? selectedFolderType : selectedListType,
      {
        dirId: outSideList ? selectedFolder : selectedList,
      },
      {
        onSuccess: afterDirDeleteSuccess,
        onError: afterDirDeleteFail,
      }
    );
    getUserDetailsAction();
  };

  const renameSlideItem = (outSideListSelect = false) => {
    setOutSideList(outSideListSelect);
    setOpenCreateDir(true);
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const afterDirDeleteSuccess = () => {
    Notify({
      value: `${capitalizeFirstLetter(selectedListType)} deleted successfully`,
    });

    getUserDetailsAction();

    deleteDirItemPopup(false);
  };

  const afterDirDeleteFail = () => {
    Notify({
      value: `${capitalizeFirstLetter(selectedListType)} deletion failed`,
    });
    deleteDirItemPopup(false);
  };

  const showDirDetails = (outSideListSelect = false) => {
    getUserDetailsAction();
    setSelectedListActivity({
      dirId: selectedList,
      dirType: selectedListType,
    });
    setOutSideList(outSideListSelect);
    setShowDetails(true);
  };

  const showSharedPopup = (outSideListSelect = false) => {
    setOutSideList(outSideListSelect);
    setOpenSharedPopup(!openSharedPopup);
  };

  const handleToggleMovePopup = (outSideListSelect = false) => {
    setOutSideList(outSideListSelect);
    setSlideBoxMovePopupAction(!movePopupState);
  };

  const showGroupsSelectPopup = () => {
    setShowGroupsSelectionPopup(!showGroupsSelectionPopup);
  };

  const downloadSlide = (outSideListSelect = false) => {
    setOutSideList(outSideListSelect);
    let downloadFilePath;
    if (selectedDetails.caseOrFolder === "slide") {
      downloadFilePath = `${selectedDetails.slidePath}/${selectedDetails.dirDataOriName}`;
    }
    if (selectedDetails.caseOrFolder === "document") {
      downloadFilePath = selectedDetails.slidePath;
    }
    getFileDownloadUrlAction(
      { filePath: downloadFilePath },
      {
        onSuccess: downloadFile,
        onError: downloadFileFail,
      }
    );
  };

  const downloadFile = (newFileUrl) => {
    let element = document.createElement("a");
    element.setAttribute("href", newFileUrl);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const downloadFileFail = () => {
    Notify({ value: `${selectedListType} download failed` });
  };

  const openCreateCaseModal = () => {
    setShowCreateCaseModal(!showCreateCaseModal);
  };

  return (
    <div
      className={`${
        importSlide
          ? "slideboxData-container analysis-table"
          : "slideboxData-container"
      }`}
      ref={listRef}
    >
      <div className="worklist-header">
        <SlideboxTabs
          setCurrentItem={setSelectedDir}
          isSearchTabActive={isSearchTabActive}
          setIsSearchTabActive={setIsSearchTabActive}
          setSelectedDetails={setSelectedDetails}
        />

        <SlideboxHeader
          {...{
            selectedList: outSideList
              ? slideDirectory.find(
                  ({ dirId, caseOrFolder }) =>
                    dirId === selectedFolder &&
                    caseOrFolder === selectedFolderType
                )
              : slideDirDataList.find(
                  ({ dirDataId, caseOrFolder }) =>
                    dirDataId === selectedList &&
                    caseOrFolder === selectedListType
                ),
            showDetails,
            setSelectedDetails,
            outSideList,
            switchView,
            setSwitchView,
            when,
          }}
        />
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", overflow: "hidden" }}
      >
        <div className={`slidebar${showDetails && !showAdvancedSearchModal ? " active" : ""}`}>
          <SlideboxListView
            setOpenAllContainers={setOpenAllContainers}
            getFileDownloadUrlAction={getFileDownloadUrlAction}
            selectedDetails={selectedDetails}
            slideDirDataList={slideDirDataList}
            showLoader={showLoader}
            toggleDirHandler={toggleDirHandler}
            setSelectedDir={setSelectedDir}
            isSearch={isSearch}
            showSharedPopup={showSharedPopup}
            deleteDirItemPopup={deleteDirItemPopup}
            renameSlideItem={renameSlideItem}
            handleToggleMovePopup={handleToggleMovePopup}
            setShowDetails={showDirDetails}
            setSelectedDetails={setSelectedDetails}
            selectedListItem={worklistColumnData.find(
              ({ dirDataId }) => dirDataId === selectedList
            )}
            showGroupsSelectPopup={showGroupsSelectPopup}
            switchView={switchView}
            setSwitchView={setSwitchView}
            switchListView={switchListView}
            setSwitchListView={setSwitchListView}
            selectedListType={selectedListType}
            selectedList={selectedList}
            selectedFolderType={selectedFolderType}
            isListView={isListView}
            downloadSlide={downloadSlide}
            filteredData={filteredData}
            isModal={isModal}
            when={when}
            setContinueButtonDisabled={setContinueButtonDisabled}
            setSelectedSlideDataToAnalyze={setSelectedSlideDataToAnalyze}
            importSlide={importSlide}
            selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
            getSearchDirData={getSearchDirData}
            setLabel={setLabel}
            radioButtonChecked={radioButtonChecked}
            setRadioButtonChecked={setRadioButtonChecked}
            tabOption={tabOption}
            setTabOption={setTabOption}
            openNotesSection={openNotesSection}
            setOpenNotesSection={setOpenNotesSection}
            openCreateCaseModal={openCreateCaseModal}
            showCreateCaseModal={showCreateCaseModal}
            setShowCreateCaseModal={setShowCreateCaseModal}
            openLabelsSection={openLabelsSection}
            setOpenLabelsSection={setOpenLabelsSection}
          />
        </div>
        {when !== "analysis" && openCreateDir && (
          <CreateDirectory
            dirType={outSideList ? selectedFolderType : selectedListType}
            isFile={
              outSideList
                ? false
                : selectedListType === "slide" ||
                  selectedListType === "document"
                ? true
                : false
            }
            rename={true}
            onClose={createDirOnClose}
            selectedDir={{
              slideItemId: outSideList ? selectedFolder : selectedList,
              name: outSideList ? selectedFolderName : selectedListName,
            }}
            selectedFolderType={selectedFolderType}
            selectedFolder={selectedFolder}
          />
        )}
        {openDeleteDir && (
          <DeleteConfirm
            dirType={outSideList ? selectedFolderType : selectedListType}
            isFile={outSideList ? false : selectedListType ? false : true}
            handleDelete={handleDeleteDirItem}
            modalClose={() => deleteDirItemPopup(false)}
          />
        )}
        {openSharedPopup && (
          <SharedPopup
            onClose={setOpenSharedPopup}
            selectedListType={
              outSideList ? selectedFolderType : selectedListType
            }
            selectedList={outSideList ? selectedFolder : selectedList}
          />
        )}
        {openAssignUserPopup && (
          <AssignUserPopup
            onClose={setOpenAssignUserPopup}
            selectedListType={
              outSideList ? selectedFolderType : selectedListType
            }
            selectedList={outSideList ? selectedFolder : selectedList}
          />
        )}
        {showGroupsSelectionPopup && (
          <GroupsSelection
            dialogOpen={showGroupsSelectionPopup}
            onClose={showGroupsSelectPopup}
            selectedFile={selectedListSlideData}
          />
        )}
        {movePopupState && (
          <MoveToPopUp
            outSideList={outSideList}
            onClose={() => handleToggleMovePopup()}
          />
        )}
        {selectedDetails && (
          <Details
            {...{
              worklistColumnData,
              showDetails,
              setShowDetails,
              selectedDetails,
              selectedListItem: worklistColumnData.find(
                ({ dirDataId }) => dirDataId === selectedList
              ),
              getFileDownloadUrlAction,
              setSelectedDetails,
              setSelectedDir,
              tabOption,
              setTabOption,
              openAllContainers,
              openNotesSection,
              setOpenNotesSection,
              openLabelsSection,
              setOpenLabelsSection,
              setSelectedSlideData,
              showAdvancedSearchModal,
            }}
            isOpenedFromGroups={isOpenedFromGroups}
            isFolderSelected={isFolderSelected}
            setIsFolderSelected={setIsFolderSelected}
            notInViewer={true}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  slideDirectory: state.SlideBox.folders,
  slideDirDataList: state.SlideBox.dirDataList,
  showLoader: state.SlideBox.showLoader,
  isSearch: state.SlideBox.isSearchData,
  selectedRightClickItem: state.SlideBox.selectedRightClickItem,
  selectedListSlideData: state.SlideBox.selectedListSlideData,
  dirDataList: state.SlideBox.dirDataList,
  movePopupState: state.SlideBox.movePopupState,
  isListView: state.SlideBox.isListView,
  isPatientView: state.SlideBox.isPatientView,
  isMoveDirActive: state.SlideBox.isMoveDirActive,
  message: state.SlideBox.message,
  worklistColumnData: state.SlideBox.worklistColumnData,
  showMessage: state.SlideBox.showMessage,
  ...state.SlideBox.selectedFolderData,
  ...state.SlideBox.selectedListData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...slideboxAction, ...dashBoardAction, ...globalAction },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlideBoxDataSection);
