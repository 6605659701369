import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addTumorBoardEnable } from "../src/pages/slidebox/actions/slidebox-action";
import {
  setSelectedBoard,
  setSlideUrl,
} from "../src/pages/groups/actions/groups-action";
import axios from "../src/utils/axiosInstance";
import { Checkbox, withStyles } from "@material-ui/core";
import { FadeIn } from "animate-components";
import {
  ModalHeader,
  Overlay,
  PrimaryButton,
  SecondaryButton,
} from "../src/components";
import { Notify } from "../src/components";
import DefaultGroupIcon from "../src/assets/icons/tumor-board-default.jpg";
import AddGroup from "../src/assets/icons/add-group.svg";
import { useNameFormat } from "../src/pages/adminDashboard/components/DashboardItems/useFormat";

const styles = {
  dialogPaper: {
    width: 300,
  },
  checkboxRoot: {
    color: "#0b569f !important",
  },
};

const GroupsSelection = ({
  myBoards,
  classes,
  history,
  dispatch,
  dialogOpen,
  onClose,
  selectedFile,
  dxAccountDetails,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTumorBoard, setSelectedTumorBoard] = useState(null);
  const [tumorBoardIndex, setTumorBoardIndex] = useState(null);

  useEffect(() => {
    setOpenDialog(true);
  }, dialogOpen);

  const handleOnSelectBoard = (e, board, index) => {
    setTumorBoardIndex(index);
    setSelectedTumorBoard(e.target.checked ? board.id : null);
  };
  const handleOnAdd = () => {
    const selectedBoard = myBoards.find(
      (board) => board.id === selectedTumorBoard
    );
    selectedBoard.slide = [
      {
        dzi_data: `${selectedFile.dzi_url.split(".dzi_files/")[0]}.dzi.dzi`,
        dzi_url: selectedFile.dzi_url,
      },
    ];
    selectedBoard.slideId = selectedFile.dirDataId;
    setTumorBoardSlide(selectedBoard);
  };

  const setTumorBoardSlide = async (folderPath) => {
    let body = {
      tumorBoardId: folderPath.id,
      slide: JSON.stringify(folderPath.slide[0]),
      slideId: selectedFile.dirDataId,
    };
    Notify({ value: "File adding!!" });
    const result = await axios

      .put("/api/groups/slidePath", body)
      .catch((err) => {
        Notify({ value: err.response.data.message });
        return false;
      });
    if (typeof result.status !== "undefined" && result.status === 200) {
      dispatch(
        setSlideUrl(
          { slide: folderPath.slide[0], slideId: folderPath.slideId },
          true
        )
      );
      folderPath.current = folderPath.slide.length - 1;
      dispatch(setSelectedBoard(folderPath));
      let getFileDataResult = await axios.post("/api/slidebox/getFileData", {
        keyFolder: selectedFile.keyFolder,
      });
      //   if (getFileDataResult.status === 200) {
      //     let post_id = folderPath.id;
      //     let key_folder = getFileDataResult.data[0].key_folder;
      //     await axios.post('/api/updateSlidebox', {
      //       moduleName: 'tumor_board',
      //       moduleId: post_id,
      //       filename: selectedFile.dirDataName,
      //       filePath: selectedFile.parentDir,
      //       keyFolder: key_folder,
      //     });
      //   }
      Notify({ value: "Slide added successfully!!" });
      history.push({
        pathname: `/groups`,
        state: {
          selectedGroupIndex: tumorBoardIndex,
        },
      });
      dispatch(addTumorBoardEnable(false));
      setOpenDialog(false);
    } else {
      setOpenDialog(false);
    }
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="customModal group-selection-modal prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            title={"Select group"}
            img={AddGroup}
            alt={"addGroup"}
            onClick={() => onClose(false)}
          />
          <div className="modal_middle">
            {myBoards.map((board, index) => {
              return (
                board.admin === true && (
                  <div className="group-item">
                    <Checkbox
                      classes={{ checked: classes.checkboxRoot }}
                      checked={selectedTumorBoard === board.id}
                      onClick={(e) => handleOnSelectBoard(e, board, index)}
                    />
                    <div className="group-item-component">
                      <div className="group-img">
                        <img
                          className="icon-img"
                          src={board.imgSrc ? board.imgSrc : DefaultGroupIcon}
                          alt={board.name}
                          onError={(ev) => (ev.target.src = DefaultGroupIcon)}
                        />
                      </div>
                      <div className="layout">
                        <div className="title">
                          <div className="leftside">
                            <h4>{board.name}</h4>
                          </div>
                        </div>
                        <div className="description">
                          <p>{`Admin: ${useNameFormat(
                            board.creator.firstname,
                            board.creator.surname
                          )}`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
          <div className="prompt-bottom" style={{ marginTop: 0 }}>
            <SecondaryButton label="Cancel" onClick={() => onClose(false)} />
            <PrimaryButton
              label="Add"
              onClick={handleOnAdd}
              disabled={!selectedTumorBoard}
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { file } = state.Groups;
  const { loggedUserDetails, dxAccountDetails } = state.Global;
  const { myBoards } = state.Groups;
  return {
    isMobile: false,
    file,
    loggedUserDetails,
    myBoards,
    dxAccountDetails,
  };
};

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(GroupsSelection))
);
