import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import CreateWorklist from "./CreateWorklist";
import {
  setCurrentTab,
  setSelectedDirectory,
  loadSlideboxDirDataList,
  createNewWorklistAction,
  updateWorklistAction,
  updateTabAction,
  getMyWorklistsAction,
  getWorklistDataAction,
  setSelectedDirCollaborators,
  setSelectedDirAssignUsers,
  getSearchDirData,
  setCloseTabAction,
  setReturnToWorklistAction,
} from "../actions/slidebox-action";
import WorklistContextMenu from "./SlideItems/WorklistContextMenu";
import Tooltip from "@material-ui/core/Tooltip";
// import ToolTip from "react-tooltip";
import { FeatureGate } from "react-feature-gate";
import { Notify } from "../../../components";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  tooltip: {
    fontSize: '13px',
    padding: '8px 21px',
    background: '#222',
  },
  arrow: {
    color: '#222',
  }
})

const SlideboxTabs = ({
  isSearch,
  allTabs,
  closeTab,
  currentTab,
  setCurrentTab,
  setSelectedDirectory,
  setSelectedDirAssignUsers,
  setSelectedDirCollaborators,
  loadSlideboxDirDataList,
  createWorklistAction,
  searchParams,
  updateWorklist,
  updateTab,
  getMyWorklists,
  loggedUserDetails,
  getWorklistData,
  getSearchDirData,
  workgroupData,
  isSearchTabActive,
  setIsSearchTabActive,
  userWorklists,
  systemWorklists,
  returnToWorklistData,
  setReturnToWorklist,
  setSelectedDetails,
  setCurrentItem,
}) => {
  const [createWorklist, setCreateWorklist] = useState(false);
  const [worklistName, setWorklistName] = useState("");
  const [currentWorklistColor, setCurrentWorklistColor] = useState("");
  const [worklistRename, setWorklistRename] = useState(null);
  const [contextOpen, setContextOpen] = useState(false);
  const [contextPos, setContextPos] = useState({});
  const [contextIndex, setContextIndex] = useState(null);
  const [selectedWorklist, setSelectedWorklist] = useState("");
  const [isSystemWorklist, setIsSystemWorklist] = useState(false);
  const [isAllCasesWorklist, setIsAllCasesWorklist] = useState(false);
  const renameRef = useRef();

  const classes = useStyles();

  useEffect(() => {
    if (!currentTab) {
      setCurrentTab(0);
    }
    setReturnToWorklist({ ...returnToWorklistData });
  }, []);

  useEffect(() => {
    if (!isSearch) {
      setIsSearchTabActive(false);
      setCurrentTab(allTabs.length && allTabs[0]);
      getWorklistData({ worklistId: allTabs.length && allTabs[0].worklistId });
    }
  }, [isSearch]);

  useEffect(() => {
    (async function reopenWorklist() {
      if(returnToWorklistData && returnToWorklistData.return) {
        const { dirDataId, listDataId, parentDir, caseOrFolder } = returnToWorklistData.selectedItem; 
        setCurrentTab(returnToWorklistData.tab);
        await getWorklistData({ worklistId: returnToWorklistData.tab.worklistId });
        await getWorklistData({ worklistId: returnToWorklistData.tab.worklistId });
        await setReturnToWorklist({ return: false });
        await setSelectedDetails(returnToWorklistData.selectedItem && returnToWorklistData.selectedItem);
        setCurrentItem({ dirId: dirDataId, listId: listDataId, parentDir, dirType: caseOrFolder });
      }
    }
    )();
  }, [returnToWorklistData])

  useEffect(() => {
    function handler(event) {
      if (renameRef.current && !renameRef.current.contains(event.target)) {
        setWorklistRename(null);
      }
    }
    window.addEventListener("mousedown", handler);
    return () => {
      window.removeEventListener("mousedown", handler);
    };
  }, []);

  const setSelectedDir = ({ dirId, listId, parentDir, dirType }) => {
    loadSlideboxDirDataList({
      dirId: dirId,
      dirType: parentDir ? dirType : "root",
    });
    setSelectedDirectory({
      dirId,
      listId,
      parentDir,
      dirType: parentDir ? dirType : "root",
    });
    if (dirType === "case" || dirType === "folder") {
      setSelectedDirCollaborators({
        dirId,
        dirType: dirType,
      });
      if (dirType === "case") {
        setSelectedDirAssignUsers({
          dirId,
          dirType: dirType,
        });
      }
    }
  };

  const handleTabSelect = (tab, worklistId) => {
    setCurrentTab(tab);
    if (worklistId === -1) {
      setSelectedDir({
        dirId: 1,
        listId: 1,
        parentDir: null,
        dirType: null,
      });
    } else {
      getWorklistData({ worklistId });
    }
  };

  const handleWorklistNameSave = ({ e, id, index }) => {
    const allWorklists = [...userWorklists, ...systemWorklists];
    if (e.keyCode === 13) {
      const worklistExists = allWorklists.filter(
        (worklist) =>
          worklist.worklistName.toLowerCase() === worklistName.toLowerCase()
      );
      if (worklistExists.length > 0) {
        Notify({ value: "Worklist already exists" });
        return;
      }
      if (worklistName === "") {
        Notify({ value: "Worklist name cannot be empty" });
        return;
      } else {
        updateWorklist({
          worklistId: id,
          newWorklistName: worklistName,
          actionType: "rename-worklist",
        });
        updateTab({
          index: index,
          newName: worklistName,
          actionType: "rename-worklist",
        });
        setWorklistRename(null);
        getMyWorklists();
      }
    }
  };

  const handleContextOpen = (
    e,
    id,
    index,
    worklistType,
    worklistName,
    worklistColor
  ) => {
    setWorklistName(worklistName);
    setCurrentWorklistColor(worklistColor);
    e.preventDefault();
    if (worklistName === "All Cases") {
      setIsAllCasesWorklist(true);
    } else {
      setIsAllCasesWorklist(false);
    }
    setContextPos({
      x:
        e.clientX + 200 > window.innerWidth
          ? window.innerWidth - 200
          : e.clientX,
      y:
        e.clientY + 120 > window.innerHeight
          ? window.innerHeight - 110
          : e.clientY,
    });
    setContextOpen(true);
    setContextIndex(index);
    setSelectedWorklist(id);
    setIsSystemWorklist(worklistType === "system" ? true : false);
  };

  const handleClickTab = (tab, worklistId) => {
    handleTabSelect(tab, worklistId);
    setIsSearchTabActive(false);
  };
  return (
    <React.Fragment>
      <div className="slidebox-tabs-container">
        {isSearch && (
          <div
            className={`search-result-tab ${isSearchTabActive ? "active" : "inactive"
              }`}
            onClick={() => {
              getSearchDirData(searchParams);
              setIsSearchTabActive(true);
            }}
          >
            <p>Search Results</p>
            <Tooltip
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            title='Create Worklist' arrow style={{fontSize: '13px', padding: '8px 21px'}}>
              <AddCircleIcon
                onMouseOver={() => console.warn('mouse over')}
                onClick={() => {
                  setCreateWorklist(true);
                }}
                style={{
                  color: '#260da2',
                  cursor: 'pointer',
                  width: '16px',
                  height: '16px',
                }}
                data-for='search-result-tab-icon'
                data-tip='Create Worklist'
              />
            </Tooltip>
          </div>
        )}
        {allTabs &&
          allTabs.map((tab, index) => {
            return (
              <div
                className={`tab ${currentTab &&
                    currentTab.worklistId === tab.worklistId &&
                    !isSearchTabActive
                    ? "open"
                    : ""
                  }`}
                style={{
                  borderLeft: tab.worklistColor
                    ? `5px solid ${tab.worklistColor}`
                    : "5px solid #ECE7F8",
                }}
                onClick={() => handleClickTab(tab, tab.worklistId)}
                onContextMenu={(e) => {
                  handleContextOpen(
                    e,
                    tab.worklistId,
                    index,
                    tab.worklistType,
                    tab.label,
                    tab.worklistColor
                  );
                }}
                data-test="tab-name"
                onDoubleClick={() => {
                  if (tab.label !== "All Cases") {
                    setWorklistRename(index);
                    setWorklistName(tab.label);
                  }
                }}
              >
                {worklistRename === index ? (
                  <input
                    type="text"
                    ref={renameRef}
                    autoFocus
                    placeholder="Type name"
                    data-test="rename-input"
                    value={worklistName && worklistName}
                    onKeyDown={(e) =>
                      handleWorklistNameSave({ e, id: tab.worklistId, index })
                    }
                    onChange={(e) => setWorklistName(e.target.value)}
                  />
                ) : (
                  <p
                    style={{
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                    }}
                  >
                    {tab.label}
                  </p>
                )}
                {tab.label !== "All Cases" && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      closeTab({
                        index,
                        worklistId: tab.worklistId,
                        worklistType: tab.worklistType,
                      });
                      if (!isSearchTabActive) {
                        getWorklistData({
                          worklistId: allTabs.find(
                            (tab) => tab.label === "All Cases"
                          ).worklistId,
                        });
                      } else {
                        getSearchDirData(searchParams);
                        setIsSearchTabActive(true);
                      }
                    }}
                  >
                    +
                  </span>
                )}
              </div>
            );
          })}
        {createWorklist && (
          <CreateWorklist
            {...{
              searchParams,
              createWorklistAction,
              setCreateWorklist,
              getMyWorklists,
              role: loggedUserDetails.roleName,
              workgroupData,
              setIsSearchTabActive,
              setCurrentTab,
            }}
          />
        )}
      </div>
      <WorklistContextMenu
        {...{
          x: contextPos && contextPos.x,
          y: contextPos && contextPos.y,
          contextOpen,
          setContextOpen,
          selectedWorklist,
          updateTab,
          contextIndex,
          isSystemWorklist,
          isAllCasesWorklist,
          setIsSearchTabActive,
          currentWorklistColor,
          currentWorklistName: worklistName,
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isSearch: state.SlideBox.isSearchData,
  allTabs: state.SlideBox.allTabs,
  currentTab: state.SlideBox.currentTab,
  searchParams: state.SlideBox.searchParams,
  loggedUserDetails: state.Global.loggedUserDetails,
  workgroupData: state.Dashboard.workgroupData,
  userWorklists: state.SlideBox.myWorklists,
  systemWorklists: state.SlideBox.systemWorklists,
  returnToWorklistData: state.SlideBox.returnToWorklistData,
});

const mapDispatchToProps = (dispatch) => ({
  closeTab: (payload) => dispatch(setCloseTabAction(payload)),
  setCurrentTab: (payload) => dispatch(setCurrentTab(payload)),
  createWorklistAction: (payload) => dispatch(createNewWorklistAction(payload)),
  setSelectedDirectory: (payload) => dispatch(setSelectedDirectory(payload)),
  loadSlideboxDirDataList: (payload) =>
    dispatch(loadSlideboxDirDataList(payload)),
  updateWorklist: (payload) => dispatch(updateWorklistAction(payload)),
  updateTab: (payload) => dispatch(updateTabAction(payload)),
  getMyWorklists: (payload) => dispatch(getMyWorklistsAction(payload)),
  getWorklistData: (payload) => dispatch(getWorklistDataAction(payload)),
  getSearchDirData: (payload) => dispatch(getSearchDirData(payload)),
  setSelectedDirAssignUsers: (payload) =>
    dispatch(setSelectedDirAssignUsers(payload)),
  setSelectedDirCollaborators: (payload) =>
    dispatch(setSelectedDirCollaborators(payload)),
  setReturnToWorklist: (payload) =>
    dispatch(setReturnToWorklistAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlideboxTabs);
