import React, { Component } from "react";

export default class ChangeAvatarPreview extends Component {
  render() {
    return (
      <React.Fragment>
        <div class="edit-image-preview-area" id="create-profile-image">
          <div class="image-btn-area">
            <input
              type="submit"
              name=""
              value="Cancel"
              className="sec_btn cancel_btn"
              id="s_p_image_cancle"
              style={{
                backgroundColor: "#222c50",
                color: "#fff",
              }}
              onClick={this.props.cancleUpload}
            />
            <input
              type="submit"
              name=""
              value="Upload"
              className="up_btn"
              id="s_p_image_upload"
              onClick={this.props.uploadProfileHandler}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
