import React, { useState, useEffect } from "react";
import SiteAdministratorDashboardTable from "./SiteAdministratorDashboardTable";
import CreateNewDxAccount from "./CreateNewDxAccount";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import {
  getDxAccountListAction,
  createDxAccountAction,
  adminUserLogoutAction,
  resendInvitationAction,
  resetMessageAction,
} from "../actions/site-admin-dashboard-action";
import { Notify } from "../../../components";
import AdminHeader from "./AdminHeader";
import "../siteAdmin.scss";

const SiteAdministratorDashboard = ({
  getDxAccountList,
  dxAccountDetails,
  numberOfActiveUsers,
  createDxAccount,
  showLoader,
  showMessage,
  message,
  userLogOut,
  resendInvitation,
  resetMessageActionFn,
}) => {
  const [value, setValue] = useState(0);
  const [showOptions, setShowOptions] = useState(0);
  const toggleOptions = (e) => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    getDxAccountList();
  }, []);

  useEffect(() => {
    showMessage &&
      Notify({
        value: message,
        done: () => resetMessageActionFn(),
      });
  }, [showMessage]);
  return (
    <div className="admin-dashboard-container">
      <AdminHeader isLoggedUser userLogOut={userLogOut} />
      <div className="slide-user-list-wrapper">
        <Tabs
          value={value}
          indicatorColor="primary"
          //   onChange={handleChange}
          aria-label="details-drawer"
          className="details-drawer-tab-container"
        >
          <Tab label="Manage Accounts" className="admin-bord-drawer-tab" />
        </Tabs>
        <CreateNewDxAccount
          createDxAccount={createDxAccount}
          getDxAccountList={getDxAccountList}
        />
        <SiteAdministratorDashboardTable
          dxAccountDetails={dxAccountDetails}
          numberOfActiveUsers={numberOfActiveUsers}
          showLoader={showLoader}
          resendInvitation={resendInvitation}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dxAccountDetails: state.SiteAdministrator.dxAccountDetails,
  showLoader: state.SiteAdministrator.showLoader,
  showMessage: state.SiteAdministrator.showMessage,
  message: state.SiteAdministrator.message,
});

const mapDispatchToProps = (dispatch) => ({
  getDxAccountList: () => dispatch(getDxAccountListAction()),
  createDxAccount: (payload) => dispatch(createDxAccountAction(payload)),
  userLogOut: () => dispatch(adminUserLogoutAction()),
  resendInvitation: (payload) => dispatch(resendInvitationAction(payload)),
  resetMessageActionFn: () => dispatch(resetMessageAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteAdministratorDashboard);
