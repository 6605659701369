import React from "react";
import NewLineChart from "./NewLineChart";

const ViewerAnnotationLineChart = ({ viewerAnnotationData }) => {
  return (
    <div id="viewer-annotations">
      <NewLineChart data={viewerAnnotationData} />
    </div>
  );
};

export default ViewerAnnotationLineChart;
