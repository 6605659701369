import React, { useEffect, useState } from "react";
import "../../AiAnalytics.scss";
import EditIcon from "@material-ui/icons/CreateOutlined";
import { Prompt } from "../../../../components";

const RunAnalysisTypeSlideDetails = ({
  imageMetaData,
  uniqueId,
  selectedSlideDataToAnalyze,
  state,
  selectedAlgorithm,
  setSelectedAlgorithm,
  setContinueButtonDisabled,
  setProgressState,
  selectedAreaName,
  selectedDirDetails,
}) => {
  const [changeAnalysisType, setChangeAnalysisType] = useState(false);
  const [changeSelectedArea, setChangeSelectedArea] = useState(false);
  const toggleExpansible = (e) => {
    let el = e.target ? e.target : e;
    el.classList.toggle("active");
    let content = el.nextElementSibling;
    if (!content) {
      return false;
    }
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  };

  const handleActivateSelectAnalysisTypeStep = () => {
    setProgressState({
      ...state,
      highlightAreas: false,
      selectAnalysisType: true,
      runAnalysis: false,
    });
  };

  const handleActivateHighlightAreaStep = () => {
    setProgressState({
      ...state,
      highlightAreas: true,
      runAnalysis: false,
    });
  };

  useEffect(() => {
    const expansibleItems = document.getElementsByClassName("default_open");
    for (let i = 0; i < expansibleItems.length; i++) {
      toggleExpansible(expansibleItems[i]);
    }
  }, []);

  return (
    <div className="image-details-section">
      <div className="slide-details-section">
        <div className="expansible-item">
          <div
            className="expansible-header default_open"
            onClick={toggleExpansible}
          >
            Slide Details
          </div>
          <div class="expansible-content">
            <div className="item">
              <div className="slide-item-heading item-slide-name">Name:</div>
              <div className="item-value" id="slideUniqueIdContainer">
                {selectedSlideDataToAnalyze.dirDataName.length > 20
                  ? selectedSlideDataToAnalyze.dirDataName.substring(0, 20) +
                  "..."
                  : selectedSlideDataToAnalyze.dirDataName}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading item-unique-id">
                Unique Id:
              </div>
              <div className="item-value" id="slideUniqueIdContainer">
                {uniqueId && uniqueId.length > 30
                  ? `${uniqueId.substring(0, 30)}
                    ${uniqueId.slice(30, uniqueId.length)}`
                  : uniqueId}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading item-height">Height:</div>
              <div className="item-value">
                {imageMetaData !== undefined && imageMetaData.height}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading item-width">Width:</div>
              <div className="item-value">
                {imageMetaData !== undefined && imageMetaData.width}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading item-magnification">
                Magnification:
              </div>
              <div className="item-value">
                {imageMetaData !== undefined &&
                  imageMetaData["openslide.objective-power"]
                  ? imageMetaData["openslide.objective-power"]
                  : "-"}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading item-resolution">
                Resolution:
              </div>
              <div className="item-value">
                {imageMetaData !== undefined && imageMetaData["aperio.MPP"]
                  ? imageMetaData["aperio.MPP"]
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="slide-details-section">
        <div className="expansible-item">
          <h3
            className="expansible-header default_open heading-font"
            onClick={toggleExpansible}
          >
            Case Details
          </h3>
          <div class="expansible-content">
            <div className="item">
              <div className="slide-item-heading">Case Name:</div>
              <div className="item-value" id="slideUniqueIdContainer">
                {selectedDirDetails.dirDataName}
              </div>
            </div>
            <div className="item">
              <div className="slide-item-heading">Description</div>
              <div className="item-value" id="slideUniqueIdContainer">
                {selectedDirDetails.description}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="slide-details-section">
        <div className="expansible-item">
          <EditIcon
            className="analysis-edit-icon"
            onClick={() => setChangeAnalysisType(true)}
          />
          {changeAnalysisType && (
            <Prompt
              title={"Select Analysis Type"}
              content="Do you want to re-select Analysis Type?"
              actionText="Confirm"
              customClass={"analytic-prompt"}
              action={handleActivateSelectAnalysisTypeStep}
              back={() => setChangeAnalysisType(false)}
            />
          )}

          <h3
            className="expansible-header default_open heading-font"
            onClick={toggleExpansible}
          >
            Analysis Type
          </h3>
          <div class="expansible-content">
            <div className="item">
              <div className="analysis-type-selected">{selectedAlgorithm} </div>
            </div>
          </div>
        </div>
      </div>

      {selectedAlgorithm === "Nuclei Segmentation" && (
        <div className="slide-details-section">
          <div className="expansible-item">
            <EditIcon
              className="analysis-edit-icon"
              onClick={() => setChangeSelectedArea(true)}
            />
            {changeSelectedArea && (
              <Prompt
                title={"Change Selected Area"}
                content="Do you want to select another area on the slide?"
                actionText="Confirm"
                customClass={"analytic-prompt"}
                action={handleActivateHighlightAreaStep}
                back={() => setChangeSelectedArea(false)}
              />
            )}
            <h3
              className="expansible-header default_open heading-font"
              onClick={toggleExpansible}
            >
              Selected Area
            </h3>
            <div class="expansible-content">
              <div className="item">
                <div className="selected-area-value">{selectedAreaName}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RunAnalysisTypeSlideDetails;
