import React from 'react';
import Logo from '../../assets/images/crosscope-logo.svg';

const HeaderLogo = ({ topToPage }) => (
  <div className="logo" onClick={(e) => topToPage(e)}>
    <img
      src={Logo}
      alt="Crosscope"
      onClick={(e) => topToPage(e)}
    />
  </div>
);

export default HeaderLogo;
