import React from 'react';
import './AnalysisDashboard.scss';
import { Link } from 'react-router-dom';
import One from '../../assets/icons/one.svg'
import Two from '../../assets/icons/two.svg'
import Three from '../../assets/icons/three.svg'
import ImportSlide from '../../assets/icons/import-slide.svg'
import SelectType from '../../assets/icons/select-analysis.svg'
import Analyze from '../../assets/icons/analyze-icon.svg'
import RunAnalysis from '../../assets/images/runAnalysis.png'

const AnalysisDashboard = () => {
  return (
    <div className="dashboard-container">
      <div className="ai-platform">
        <p className="ai-powered">AI-Powered Analysis Platform</p>
        <p className="ai-algorithms">Use AI algorithms to analyze slides in three steps</p>
      </div>
      <div className="analysis-option">
        <div className="import-slide">
          <div className="option-name">
            <img src={One} alt="one" />
            <span>Import Slide</span>
          </div>
          <div className="option-icon">
            <img src={ImportSlide} alt="import" />
          </div>
        </div>
        <div className="import-slide">
          <div className="option-name">
            <img src={Two} alt="two" />
            <span>Select Analysis Type</span>
          </div>
          <div className="option-icon">
            <img src={SelectType} alt="selectType" />
          </div>
        </div>
        <div className="import-slide">
          <div className="option-name">
            <img src={Three} alt="three" />
            <span>Run Analysis</span>
          </div>
          <div className="option-icon">
            <img src={RunAnalysis} alt="runAnalysis" />
          </div>
        </div>
      </div>
      <div className="slide-to-analyze">
        <img src={Analyze} alt="analyze" />
        <Link
          to={'/analysis'}
        >
          Select Slide to analyze
        </Link>
        <p>Select from Slidebox</p>
      </div>
      <p className="or">Or</p>
      <div className="previous-run">
        <Link
          to={'/analysis-previous-runs'}
        >
          View Previous Runs
      </Link>
      </div>
      {/* <div className="header-section">
        <div className="right-section">
          
            Previous Runs
        </Link>
        </div>
        <div className="right-section">
          <Link className="continue_button run-btn" to={'/analysis'}>
            Run New Analysis
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default AnalysisDashboard;
