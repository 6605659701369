import {
  getViewerLiveSyncUrlApi,
  downloadAnnotationApi,
  updateDescriptionAPI,
  getAnnotationDescriptionApi,
  setActivityLogApi,
  getActivityLogApi,
} from '../services/viewer-service';

export const SET_ANNOTATION_DATA = 'SET_ANNOTATION_DATA';
export const SET_CURRENT_ANNOTATION_DATA = 'SET_CURRENT_ANNOTATION_DATA';
export const SET_ANNOTATION_TYPE = 'SET_ANNOTATION_TYPE';
export const SET_ANNOTATION_COLOR = 'SET_ANNOTATION_COLOR';
export const SET_ANNOTATION_ACTIVE = 'SET_ANNOTATION_ACTIVE';

export const VIEWER_IMAGE_INIT_PENDIG = 'VIEWER_IMAGE_INIT_PENDIG';
export const VIEWER_IMAGE_INIT_SUCCESS = 'VIEWER_IMAGE_INIT_SUCCESS';
export const VIEWER_IMAGE_INIT_ERROR = 'VIEWER_IMAGE_INIT_ERROR';

export const GET_VIEWER_IMAGE_PENDING = 'GET_VIEWER_IMAGE_PENDING';
export const GET_VIEWER_IMAGE_SUCCESS = 'GET_VIEWER_IMAGE_SUCCESS';
export const GET_VIEWER_IMAGE_ERROR = 'GET_VIEWER_IMAGE_ERROR';
export const RESET_VIEWER_ANNOTATION = 'RESET_VIEWER_ANNOTATION';
export const UPDATE_CURRENT_ANNOTATION_DATA = 'UPDATE_CURRENT_ANNOTATION_DATA';
export const DELETE_ANNOTATION_DATA = 'DELETE_ANNOTATION_DATA';
export const EDIT_CURRENT_ANNOTATION_DATA = 'EDIT_CURRENT_ANNOTATION_DATA';
export const PUBLIC_ANNOTATION_DATA = 'PUBLIC_ANNOTATION_DATA';
export const TOGGLE_ANNOTATION_DATA = 'TOGGLE_ANNOTATION_DATA';
export const VALIDATE_ANNOTATION_DATA = 'VALIDATE_ANNOTATION_DATA';
export const SET_LIVE_SHARE_ACTIVE = 'SET_LIVE_SHARE_ACTIVE';
export const SET_LIVE_SHARE_USER_ACTIVE = 'SET_LIVE_SHARE_USER_ACTIVE';
export const SET_VIEWER_ACTIVITY_LOG = 'SET_VIEWER_ACTIVITY_LOG';
export const GET_VIEWER_ACTIVITY_LOG = 'GET_VIEWER_ACTIVITY_LOG';

export const UPDATE_CURRENT_ANNOTATION_DATA_IN_VALIDATE =
  'UPDATE_CURRENT_ANNOTATION_DATA_IN_VALIDATE';

export const SET_SOCKET_DATE_ACTION_FALSE = 'SET_SOCKET_DATE_ACTION_FALSE';
export const SET_GENERATE_LIVESYNC_URL = 'SET_GENERATE_LIVESYNC_URL';
export const SET_LIVESYNC_META_DATA = 'SET_LIVESYNC_META_DATA';
export const RESET_VIEWER_DATA = 'RESET_VIEWER_DATA';
export const SET_DOWNLOAD_ANNOTATION_URL = 'SET_DOWNLOAD_ANNOTATION_URL';
export const SET_SLIDE_UNIQUE_DATA = 'SET_SLIDE_UNIQUE_DATA';
export const RESET_ANNOTATION_DOWNLOAD_URL = 'RESET_ANNOTATION_DOWNLOAD_URL';

export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const SET_CURRENT_ANNOTATION_DESCRIPTION =
  'SET_CURRENT_ANNOTATION_DESCRIPTION';
export const SET_VIEWER_DZI_DATA_ARRAY = 'SET_VIEWER_DZI_DATA_ARRAY';

export const RESET_ANNOTATION_UPLOAD = 'RESET_ANNOTATION_UPLOAD';

export const SET_CURRENT_ANNOTATION_DATA_FOR_RUN_ANALYSIS =
  'SET_CURRENT_ANNOTATION_DATA_FOR_RUN_ANALYSIS';

export const SET_CURRENT_ANNOTATION_DATA_FOR_HIGHLIGHT_AREAS =
  'SET_CURRENT_ANNOTATION_DATA_FOR_HIGHLIGHT_AREAS';

export const RESET_DOWNLOAD_ANNOTATION = 'RESET_DOWNLOAD_ANNOTATION';

const setDownloadAnnotationUrl = (downloadUrl) => ({
  type: SET_DOWNLOAD_ANNOTATION_URL,
  payload: downloadUrl,
});

export const setAnnotionDataAction = (annotationData) => ({
  type: SET_CURRENT_ANNOTATION_DATA,
  payload: annotationData,
});

export const setAnnotionDataForHighlightAreasAction = (annotationData) => ({
  type: SET_CURRENT_ANNOTATION_DATA_FOR_HIGHLIGHT_AREAS,
  payload: annotationData,
});

export const setAnnotionDataForRunAnalysisAction = (annotationData) => ({
  type: SET_CURRENT_ANNOTATION_DATA_FOR_RUN_ANALYSIS,
  payload: annotationData,
});

export const setViewerScaleUnit = (unit) => ({
  type: 'SET_SCALE_UNIT',
  payload: unit,
});

export const setViewerDefaultScaleUnit = () => ({
  type: 'SET_DEFAULT_SCALE_UNIT',
});

export const setViewerScaleRatio = (ratio) => ({
  type: 'SET_SCALE_RATIO',
  payload: ratio,
});

export const setViewerDefaultScaleRatio = () => ({
  type: 'SET_DEFAULT_SCALE_RATIO',
});

export const deleteAnnotionPopupAction = ({ status, annotationId }) => ({
  type: 'DELETE_ANNOTATION_POPUP',
  payload: annotationId,
  status,
});

export const deleteAnnotionDataAction = (annotationId) => ({
  type: DELETE_ANNOTATION_DATA,
  payload: annotationId,
});

export const updateAnnotionDataAction = (annotation) => ({
  type: UPDATE_CURRENT_ANNOTATION_DATA,
  payload: annotation,
});

export const resetAnnotationUploadAction = () => ({
  type: RESET_ANNOTATION_UPLOAD,
});

export const updateAnnotionDataInValidateAction = (annotationArray) => ({
  type: UPDATE_CURRENT_ANNOTATION_DATA_IN_VALIDATE,
  payload: annotationArray,
});

export const editAnnotionDataAction = (annotation) => ({
  type: EDIT_CURRENT_ANNOTATION_DATA,
  payload: annotation.annotationData,
  pk: annotation.pk,
});

export const publicAnnotationDataAction = (data) => ({
  type: PUBLIC_ANNOTATION_DATA,
  payload: data,
});

export const toggleAnnotationDataAction = (data) => ({
  type: TOGGLE_ANNOTATION_DATA,
  payload: data,
});

export const validateAnnotionDataAction = (data) => ({
  type: VALIDATE_ANNOTATION_DATA,
  payload: data,
});

export const setAnnotionTypeAction = (annotationType) => ({
  type: SET_ANNOTATION_TYPE,
  payload: annotationType,
});

export const setAnnotionActiveAction = (annotationActiveStatus) => ({
  type: SET_ANNOTATION_ACTIVE,
  payload: annotationActiveStatus,
});

export const setAnnotionColorAction = (annotationColor) => ({
  type: SET_ANNOTATION_COLOR,
  payload: annotationColor,
});

export const resetViewerAnnotationAction = () => ({
  type: RESET_VIEWER_ANNOTATION,
  payload: {},
});

export const initAnnotationAction = (data) => async (dispatch) => {
  const { type, status } = data;
  dispatch(setAnnotionTypeAction(type));
  dispatch(setAnnotionActiveAction(status));
};

export const liveShareOnAction = (status) => ({
  type: SET_LIVE_SHARE_ACTIVE,
  payload: status,
});

export const liveShareUserOnAction = (status) => ({
  type: SET_LIVE_SHARE_USER_ACTIVE,
  payload: status,
});

export const setSocketDataUpdateAction = (status) => ({
  type: SET_SOCKET_DATE_ACTION_FALSE,
  payload: status,
});

export const gernerateLiveSyncUrl = (urlLink) => ({
  type: SET_GENERATE_LIVESYNC_URL,
  payload: urlLink,
});

export const gernerateLiveSyncUrlAction = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await getViewerLiveSyncUrlApi(dataParams);
      if (response.data.success) {
        dispatch(gernerateLiveSyncUrl(response.data.data));
      } else {
      }
    } catch (error) {}
  };
};

export const downloadAnnotationAction = (dataParams) => async (dispatch) => {
  try {
    dispatch(setDownloadAnnotationUrl(null));
    const response = await downloadAnnotationApi(dataParams);
    if (response.data.success) {
      dispatch(setDownloadAnnotationUrl(response.data.fileUrl));
    } else {
      dispatch(setDownloadAnnotationUrl(null));
    }
  } catch (error) {
    dispatch(setDownloadAnnotationUrl(null));
  }
};

export const setLiveSyncMetaDataAction = (metaData) => ({
  type: SET_LIVESYNC_META_DATA,
  payload: metaData,
});

export const setAnnotationUniqueDataAction = (slideUniqueData) => ({
  type: SET_SLIDE_UNIQUE_DATA,
  payload: slideUniqueData,
});

const setViewerActivityLog = (logId) => ({
  type: SET_VIEWER_ACTIVITY_LOG,
  payload: logId,
});

const getViewerActivityLog = (viewerActivityData) => ({
  type: GET_VIEWER_ACTIVITY_LOG,
  payload: viewerActivityData,
});

export const resetDownloadUrlAction = () => ({
  type: RESET_ANNOTATION_DOWNLOAD_URL,
});

export const setAnnotationDescriptionAction = (description) => ({
  type: SET_CURRENT_ANNOTATION_DESCRIPTION,
  payload: description,
});

export const setAnnotationDescription = (payload) => {
  return {
    type: 'ANNOTATION_DESCRIPTION_UPDATE',
    payload,
  };
};

export const getAnnotationDescription = (payload) => {
  return {
    type: 'GET_ANNOTATION_DESCRIPTION',
    payload,
  };
};

export const setViewerDataArray = (payload) => {
  return {
    type: 'SET_VIEWER_DZI_DATA_ARRAY',
    payload,
  };
};

export const updateDescriptionAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await updateDescriptionAPI(data);
      if (response.status === 200) {
        dispatch(setAnnotationDescription(response.data));
      } else {
      }
    } catch (error) {
      //console.log(error);
    }
  };
};
export const setSlideActivityLogAction = (dataParams) => {
  return async (dispatch) => {
    try {
      dispatch(setViewerActivityLog(null));
      const response = await setActivityLogApi(dataParams);
      if (response.data.success) {
        dispatch(setViewerActivityLog(response.data.logId));
      } else {
        dispatch(setViewerActivityLog(null));
      }
    } catch (error) {
      dispatch(setViewerActivityLog(null));
    }
  };
};
export const getSlideActivityLogAction = (dataParams) => {
  return async (dispatch) => {
    try {
      dispatch(getViewerActivityLog([]));
      const response = await getActivityLogApi(dataParams);
      if (response.data.success) {
        dispatch(getViewerActivityLog(response.data.data));
      } else {
        dispatch(getViewerActivityLog(null));
      }
    } catch (error) {
      dispatch(setViewerActivityLog(null));
    }
  };
};

export const setViewerFullScreen = (status) => ({
  type: 'SET_VIEWER_FULL_SCREEN',
  status,
});

export const setRoi = (selectionJson) => {
  return {
    type: 'SET_ROI',
    payload: selectionJson,
  };
};

export const resetDownloadAnnotationAction = () => {
  return {
    type: 'RESET_DOWNLOAD_ANNOTATION',
  };
};
