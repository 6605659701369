import React, { useEffect, useRef, useState } from 'react';
import ZoomIn from '../icons/zoom-icon.svg';
import ZoomOut from '../icons/zoom-out.svg';
import Home from '../icons/home-new-icon.svg';
import FullScreen from '../icons/full-page.svg';
import Rotate from '../icons/rotate.svg';
import ToolTip from 'react-tooltip';

import CircularSlider from '@fseehawer/react-circular-slider';
import { FeatureGate } from 'react-feature-gate';

const ViewerToolbarBasic = ({
  overlay,
  viewer,
  OpenSeadragon,
  enableFullScreen,
  enableFullScreenState,
  setFullScreenRef,
}) => {
  const [showCustomRotate, setShowCustomRotate] = useState(false);
  const [rotationStart, setRotationStart] = useState(false);
  const [orgAngleArray, setOrgAngleArray] = useState(null);
  const homeRef = useRef(null);
  const rotateRef = useRef(null);
  useEffect(() => {
    if (typeof viewer !== 'undefined') {
      const homeButton = new OpenSeadragon.Button({
        element: homeRef.current,
        onClick: handleHomeClick,
        tooltip: 'Home/Reset',
      });
    }
  }, [viewer]);

  const handleHomeClick = () => {
    if (typeof viewer !== 'undefined') {
      viewer.viewport.setRotation(0);
      viewer.viewport.goHome();
    }
  };

  const rotateImageHandler = (val) => {
    viewer.viewport.setRotation(val);
    rotateObject(val);
  };

  const handleRotate = () => {
    setShowCustomRotate(!showCustomRotate);
  };

  const handleFullScreen = () => {
    enableFullScreen();
  };

  const rotateObject = (angle) => {
    let canvas = overlay.fabricCanvas();
    let canvasCenter = new fabric.Point(0, 0); // center of canvas
    if (!rotationStart && !orgAngleArray) {
      setRotationStart(true);
      let objArray = canvas
        .getObjects()
        .map((data) => ({ orgAngle: data.angle }));
      setOrgAngleArray(objArray);
    }

    canvas.getObjects().forEach((obj, index) => {
      let objectOrigin = new fabric.Point(obj.left, obj.top);
      let orgAngleVal = orgAngleArray
        ? orgAngleArray[index]['orgAngle']
        : obj.angle;
      let radians = fabric.util.degreesToRadians(
        angle - (obj.angle - orgAngleVal)
      );
      let newAngle = orgAngleVal + angle;
      let angleValue = newAngle > 360 ? newAngle - 360 : newAngle;
      let new_loc = fabric.util.rotatePoint(
        objectOrigin,
        canvasCenter,
        radians
      );
      obj.top = new_loc.y;
      obj.left = new_loc.x;
      obj.angle = angleValue;
      obj.setCoords(); //rotate each object by the same angle
      canvas.renderAll();
    });
  };

  return (
    <React.Fragment>
      <ul>
        <li id="zoom-in" data-for="viewer-toolbar" data-tip={'Zoom in'}>
          <img src={ZoomIn} />
        </li>
        <li id="zoom-out" data-for="viewer-toolbar" data-tip={'Zoom out'}>
          <img src={ZoomOut} />
        </li>
        <li id="home" ref={homeRef} data-for="viewer-toolbar" data-tip={'Home'}>
          <img src={Home} />
        </li>
        <li
          id="full-page"
          ref={setFullScreenRef}
          data-for="viewer-toolbar"
          data-tip={'Full page'}
          className={enableFullScreenState ? 'active-tool' : ''}
        >
          <img src={FullScreen} />
        </li>
        <li
          id="rotate"
          className={
            showCustomRotate ||
              (typeof viewer !== 'undefined'
                ? viewer.viewport.getRotation() === 0
                  ? false
                  : true
                : false)
              ? 'active-tool'
              : 'nonactive-tool'
          }
          ref={rotateRef}
          data-for="viewer-toolbar"
          data-tip={'Toggle rotate wheel'}
        >
          <img src={Rotate} onClick={handleRotate} style={{ marginTop: '-2px' }} />
        </li>
        {/* <FeatureGate feature="rotateSlideImage">
          <li
            id="rotate"
            className={
              showCustomRotate ||
              (typeof viewer !== 'undefined'
                ? viewer.viewport.getRotation() === 0
                  ? false
                  : true
                : false)
                ? 'active-tool'
                : 'nonactive-tool'
            }
            ref={rotateRef}
            data-for="viewer-toolbar"
            data-tip={'Toggle rotate wheel'}
          >
            <img src={Rotate} onClick={handleRotate} />
          </li>
        </FeatureGate> */}
      </ul>
      {showCustomRotate && (
        <li className="circular-slider">
          <CircularSlider
            value
            width={80}
            progressSize={12}
            trackSize={12}
            verticalOffset={0}
            valueFontSize={1}
            labelFontSize={0}
            appendToValue={'°'}
            labelColor={'#3f51b5'}
            progressColorFrom={'#8791cc'}
            progressColorTo={'#5a649c'}
            knobColor={'#3f51b5'}
            onChange={(v) => rotateImageHandler(v)}
          />
        </li>
      )}
      <ToolTip id="viewer-toolbar" place="bottom" type="info" effect="solid" />
    </React.Fragment>
  );
};

export default ViewerToolbarBasic;
