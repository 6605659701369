import { fabric } from "fabric";

const OpenseadragonAnnotations = {
  arrow: '',
  line: '',
  rectangle: '',
  circle: '',
  ellipse: '',
  text: '',
  cursor: '',
  polygon: '',
  brush: '',
  isDown: '',
  textboxPreview: '',
  origX: '',
  origY: '',

  currentAnnotationType: 'arrow', //stores the type of the current annotation being drawn so we know which variable (arrow/line/rectangle/ellipse/text etc) to serialize on mouse:up

  overlay: '',

  /* Store imageJson locally */
  //imageJson: "",

  /*
    Stores annotation [primary key] to [annotation json] mappings for all annotations currently drawn on the canvas {pk: annotation json}
    Used to check if an annotation is on the canvas to prevent duplicate loadAnnotations() calls from the user
    */
  annotationsDict: {},

  /* the mouse can be in 3 modes:
     1.) OSD (for interaction w/ OSD viewer, drag/scroll/zoom around the map
     2.) addAnnotation (disable OSD mode and enable click/drag on fabricJS canvas to draw an annotation)
     3.) editAnnotation (disable OSD mode and allow editing of existing annotations (but do not draw onclicK)
     getters and setters are below
     */
  mouseMode: 'OSD',

  /* Stores whether the image annotation toolbar is currently hidden or not. Can be visible/invisible*/
  imageAnnotationToolbarStatus: 'invisible',

  /*
    Annotation type we draw on canvas on click (arrow on default), changed by #annotationType radio menu
    */
  annotationType: 'arrow',

  /*
    Default annotation color to draw annotations in
    */
  currentAnnotationColor: 'red',

  /*
    Default annotation size
    */
  currentAnnotationSize: 'medium',

  strokeWidthForDrawing: 15,

  /*
    Dictionary of different annotation sizes. Stroke is the stroke size used for non-arrow shapes. Arrow size is controlled by headlen.
    TODO: add font sizes
    */
  annotationSizes: {
    small: {
      stroke: 5,
      arrow: 50,
    },
    medium: {
      stroke: 15,
      arrow: 75,
    },
    large: {
      stroke: 25,
      arrow: 100,
    },
  },

  /*
    Global setting to show/hide toolbar on default
    */
  showToolbar: 'false',

  /*
    Global setting to show/hide annotations on default
    */
  showAnnotations: 'true',

  mousecursor: new fabric.Circle({
    left: -100,
    top: -100,
    radius: 60,
    fill: 'rgba(255,255,0,0.5)',
    stroke: 'black',
    originX: 'center',
    originY: 'center',
  }),

  /*
    Clear OpenseadragonAnnotations global variables (that will have been set by previous images if the viewer was loaded before)
    Initialize member variables
    */
  initialize: function(overlay, osdViewer, isHeatMapOverlay) {
    /* Clear variables */
    this.annotationsDict = {};

    /* Initialize member variables */
    //this.imageJson = imageJson;

    this.viewer = osdViewer; // TODO: do we need to load this every time?
    if (isHeatMapOverlay) {
      this.heatMapOverlay = overlay;
    } else {
      this.overlay = overlay;
    }

    this.currentAnnotationType = 'arrow';

    this.annotationType = 'arrow';
    this.currentAnnotationColor = 'red';
    this.imageAnnotationToolbarStatus = 'invisible';
    this.strokeWidthForDrawing = 15;

    // $("#colorPicker").spectrum(spectrumOptions);

    /* Load and display annotations */
    // OpenseadragonAnnotations.getAnnotations();

    if (OpenseadragonAnnotations.showAnnotations == 'false') {
      // This code is duplicated in the getAnnotations callback to deal with async
      //console.log("activated almonds");
      // $("#off").click();
      OpenseadragonAnnotations.turnAnnotationsOnOff('off');
    }

    /* Update the currentAnnotationSize radio menubar to have the correct annotationSize selected */
    if (OpenseadragonAnnotations.currentAnnotationSize == 'small') {
      // $("#small").click();
    } else if (OpenseadragonAnnotations.currentAnnotationSize == 'medium') {
      // $("#medium").click();
    } else {
      // $("#large").click();
    }

    this.mouseMode = 'OSD';
    this.setMouseMode(this.mouseMode);

    /****************************************************************************************************************

                                             E V E N T  L I S T E N E R S

                                             *****************************************************************************************************************/
    //event listeners
    // fabricJS mouse-down event listener

    /*
        mouse:down event listener
        On mousedown:
            - mark isDown as true. On mouse:up, we draw annotations if isDown is true.
            - set origX, origY as the initial click location.
            - initialize the correct function based on what the currentAnnotationType is.
            */
    this.overlay.fabricCanvas().observe('mouse:down', function(o) {
      OpenseadragonAnnotations.setMouseMode(OpenseadragonAnnotations.mouseMode);
      if (OpenseadragonAnnotations.getMouseMode() == 'addAnnotation') {
        OpenseadragonAnnotations.isDown = true;

        var pointer = OpenseadragonAnnotations.overlay
          .fabricCanvas()
          .getPointer(o.e);
        OpenseadragonAnnotations.origX = pointer.x;
        OpenseadragonAnnotations.origY = pointer.y;
        switch (OpenseadragonAnnotations.annotationType) {
          case 'arrow':
            OpenseadragonAnnotations.drawArrow(pointer.x, pointer.y);
            break;
          case 'rectangle':
            OpenseadragonAnnotations.initializeRectangle(pointer.x, pointer.y);
            break;
          case 'ellipse':
            OpenseadragonAnnotations.initializeEllipse(pointer.x, pointer.y);
            break;
          case 'text':
            OpenseadragonAnnotations.initializeTextboxPreview(
              pointer.x,
              pointer.y
            );
            break;
          case 'brush':
            OpenseadragonAnnotations.initializeBrush(pointer.x, pointer.y);
            //OpenseadragonAnnotations.drawPolygon(pointer.x, pointer.y);
            break;
          default:
            // console.log(
            //   "That shouldn't have happened :( Undefined annotationType"
            // );
            // console.log(
            //   "The undefined type entered is: " + this.annotationType
            // );
            throw new Error('Tried to switch to an undefined annotationType');
        }
      }
    });

    /*
        mouse:up event listener
        If isDown is true and the mouse if moved, redraw the currentAnnotationShape on canvas with the new current mouse position.
        */
    this.overlay.fabricCanvas().observe('mouse:move', function(o) {
      if (!OpenseadragonAnnotations.isDown) return;
      var pointer = OpenseadragonAnnotations.overlay
        .fabricCanvas()
        .getPointer(o.e);
      switch (OpenseadragonAnnotations.annotationType) {
        case 'arrow':
          OpenseadragonAnnotations.updateArrow(pointer.x, pointer.y);
          break;
        case 'rectangle':
          OpenseadragonAnnotations.updateRectangleWidth(pointer.x, pointer.y);
          break;
        case 'ellipse':
          OpenseadragonAnnotations.updateEllipse(pointer.x, pointer.y);
          break;
        case 'text':
          OpenseadragonAnnotations.updateTextboxPreview(pointer.x, pointer.y);
          break;
        case 'brush':
          OpenseadragonAnnotations.updatePolygon(pointer.x, pointer.y);
          break;
        default:
          // console.log(
          //   "That shouldn't have happened :( Undefined annotationType"
          // );
          // console.log("The undefined type entered is: " + this.annotationType);
          throw new Error('Tried to switch to an undefined annotationType');
      }
      OpenseadragonAnnotations.overlay.fabricCanvas().renderAll();
    });

    /* event listener that handles resizing the textbox based on amount of text */
    this.overlay.fabricCanvas().on('text:changed', function(opt) {
      var t1 = opt.target;
      if (t1.width > t1.fixedWidth) {
        t1.fontSize *= t1.fixedWidth / (t1.width + 1);
        t1.width = t1.fixedWidth;
      }
    });

    /*
        mouse:up event listener
        If we are in addAnnotation mode and the mouse is lifted, save the currentAnnotationShape to Django
        */
    this.overlay.fabricCanvas().on('mouse:up', function(o) {
      if (OpenseadragonAnnotations.getMouseMode() == 'addAnnotation') {
        var pointerOnMouseUp = OpenseadragonAnnotations.overlay
          .fabricCanvas()
          .getPointer(event.e);

        // save annotation to database
        OpenseadragonAnnotations.createNewSerialization(
          OpenseadragonAnnotations.currentAnnotationType,
          pointerOnMouseUp.x,
          pointerOnMouseUp.y
        );

        // Set fabric interactivity to false
        OpenseadragonAnnotations.setFabricCanvasInteractivity(false);

        // If we just added a textbox, stay in edit mode so the user can edit. Otherwise, return to OSD navigation mode.
        if (OpenseadragonAnnotations.currentAnnotationType.type == 'text') {
          OpenseadragonAnnotations.setMouseMode('editAnnotation'); // break out into edit mode
          // $("#editAnnotation").click(); // set nav bar to editAnnotation
          //console.log("text added");
        }
        OpenseadragonAnnotations.setMouseMode('OSD');
      }
      OpenseadragonAnnotations.isDown = false;
      // OpenseadragonAnnotations.saveAnnotation(); // show the pop-up with annnotation name to type and save or cancel
      // on save it adds to mysql etc...
      // on cancel it should remove the object from the mysql canvas.
    });

    // Update the database entry of any modified object
    // this.overlay.fabricCanvas().on("object:modified", function() {
    //   const canvasObjects = this.overlay.fabricCanvas().getActiveObject();
    //   // OpenseadragonAnnotations.updateSerialization(
    //     console.log("object is modified: ", canvasObjects);
    //   //   OpenseadragonAnnotations.overlay.fabricCanvas().getActiveObject()
    //   // );
    // });
  }, // end of initialize

  /****************************************************************************************************************

                                    A N N O T A T I O N S (Initializers and Updaters)

                                    *****************************************************************************************************************/
  //annotations

  //Euclidean distance between (x1,y1) and (x2,y2)
  distanceFormula: function(x1, y1, x2, y2) {
    var xDist = Math.pow(x1 - x2, 2);
    var yDist = Math.pow(y1 - y2, 2);
    return Math.sqrt(xDist + yDist);
  },

  initializeEllipse: function(x, y) {
    this.ellipse = new fabric.Ellipse({
      left: x,
      top: y,
      radius: 1,
      strokeWidth: this.strokeWidthForDrawing, // this.annotationSizes[this.currentAnnotationSize]["stroke"],
      stroke: this.currentAnnotationColor,
      fill: '',
      originX: 'center',
      originY: 'center',
      scaleX: 1,
      scaleY: 1,
      type: 'ellipse',
      size: this.currentAnnotationSize,
    });
    this.currentAnnotationType = this.ellipse;
    this.overlay.fabricCanvas().add(this.ellipse);
  },

  updateEllipse: function(x, y) {
    this.ellipse.set({
      rx: Math.abs(this.origX - x),
      ry: Math.abs(this.origY - y),
    });
    this.currentAnnotationType = this.ellipse;
  },

  initializeRectangle: function(x, y) {
    this.rectangle = new fabric.Rect({
      left: x,
      top: y,
      fill: '',
      strokeWidth: this.strokeWidthForDrawing, //this.annotationSizes[this.currentAnnotationSize]["stroke"],
      stroke: this.currentAnnotationColor,
      width: 1,
      height: 1,
      scaleX: 1,
      scaleY: 1,
      type: 'rect',
      size: this.currentAnnotationSize,
    });
    this.currentAnnotationType = this.rectangle;
    this.overlay.fabricCanvas().add(this.rectangle);
  },

  updateRectangleWidth: function(x, y) {
    var width = Math.abs(x - this.origX);
    var height = Math.abs(y - this.origY);
    this.rectangle.set({ width: width, height: height });
    this.currentAnnotationType = this.rectangle;
  },

  initializeTextboxPreview: function(x, y) {
    this.textboxPreview = new fabric.Rect({
      left: x,
      top: y,
      fill: '',
      strokeWidth: this.strokeWidthForDrawing, //this.annotationSizes[this.currentAnnotationSize]["stroke"],
      stroke: this.currentAnnotationColor,
      width: 1,
      height: 1,
      type: 'textboxPreview',
      size: this.currentAnnotationSize,
    });
    this.currentAnnotationType = this.textboxPreview;
    this.overlay.fabricCanvas().add(this.textboxPreview);
  },

  updateTextboxPreview: function(x, y) {
    var width = Math.abs(x - this.origX);
    var height = Math.abs(y - this.origY);
    this.textboxPreview.set({ width: width, height: height });
    this.currentAnnotationType = this.textboxPreview;
  },

  /*
     Arrows are initialized in a strange way. Arrows aren't provided by fabricjs so you need to create them yourself. We do this by
     computing a group of points (in calculateArrowPoints()) and then creating a polygon that encloses all of those points (so we're
     really drawing a polygon, not an arrow). Taken from https://jsfiddle.net/6e17oxc3/
     */
  drawArrow: function(x, y) {
    var headlen = this.annotationSizes[this.currentAnnotationSize]['arrow']; // arrow head size
    this.arrow = new fabric.Polyline(
      this.calculateArrowPoints(this.origX, this.origY, x, y, headlen),
      {
        fill: this.currentAnnotationColor,
        stroke: this.currentAnnotationColor,
        opacity: 1,
        strokeWidth: 2,
        originX: 'left',
        originY: 'top',
        scaleX: 1,
        scaleY: 1,
        type: 'arrow',
        size: this.currentAnnotationSize,
      }
    );
    this.currentAnnotationType = this.arrow;
    this.overlay.fabricCanvas().add(this.arrow);
  },

  updateArrow: function(x, y) {
    var headlen = this.annotationSizes[this.currentAnnotationSize]['arrow']; // current arrow head size
    this.overlay.fabricCanvas().remove(this.arrow);
    var angle = Math.atan2(y - this.origY, x - this.origX);

    // bring the line end back some to account for arrow head.
    x = x - headlen * Math.cos(angle);
    y = y - headlen * Math.sin(angle);

    // calculate the points.
    var pointsArray = this.calculateArrowPoints(x, y, headlen);
    var oldArrowSize = this.arrow.size;
    this.arrow = new fabric.Polyline(pointsArray, {
      fill: this.currentAnnotationColor,
      stroke: this.currentAnnotationColor,
      opacity: 1,
      strokeWidth: 2,
      originX: 'left',
      originY: 'top',
      type: 'arrow',
      size: oldArrowSize,
    });
    this.currentAnnotationType = this.arrow;
    this.overlay.fabricCanvas().add(this.arrow);
    this.overlay.fabricCanvas().renderAll();
  },

  // Compute set of points to create arrow shape
  calculateArrowPoints: function(x, y, headlen) {
    var angle = Math.atan2(y - this.origY, x - this.origX);
    var headlen = this.annotationSizes[this.currentAnnotationSize]['arrow'],
      // bring the line end back some to account for arrow head.
      x = x - headlen * Math.cos(angle);
    y = y - headlen * Math.sin(angle);

    var points = [
      {
        x: this.origX, // start point
        y: this.origY,
      },
      {
        x: this.origX - (headlen / 4) * Math.cos(angle - Math.PI / 2),
        y: this.origY - (headlen / 4) * Math.sin(angle - Math.PI / 2),
      },
      {
        x: x - (headlen / 4) * Math.cos(angle - Math.PI / 2),
        y: y - (headlen / 4) * Math.sin(angle - Math.PI / 2),
      },
      {
        x: x - headlen * Math.cos(angle - Math.PI / 2),
        y: y - headlen * Math.sin(angle - Math.PI / 2),
      },
      {
        x: x + headlen * Math.cos(angle), // tip
        y: y + headlen * Math.sin(angle),
      },
      {
        x: x - headlen * Math.cos(angle + Math.PI / 2),
        y: y - headlen * Math.sin(angle + Math.PI / 2),
      },
      {
        x: x - (headlen / 4) * Math.cos(angle + Math.PI / 2),
        y: y - (headlen / 4) * Math.sin(angle + Math.PI / 2),
      },
      {
        x: this.origX - (headlen / 4) * Math.cos(angle + Math.PI / 2),
        y: this.origY - (headlen / 4) * Math.sin(angle + Math.PI / 2),
      },
      {
        x: this.origX,
        y: this.origY,
      },
    ];
    return points;
  },

  initializeBrush: function(x, y) {
    // this.brushPath = [];
  },

  updatePolygon: function(x, y) {
    // this.brushPath.push({x,y});
  },

  setFabricCanvasInteractivity: function(boolean) {
    this.overlay.fabricCanvas().forEachObject(function(object) {
      object.selectable = boolean;
    });
  },

  deselectFabricObjects: function() {
    this.overlay
      .fabricCanvas()
      .deactivateAll()
      .renderAll();
  },

  setMouseMode: function(mode) {
    switch (mode) {
      case 'OSD':
        this.mouseMode = 'OSD';
        this.setFabricCanvasInteractivity(false);
        this.deselectFabricObjects();
        this.viewer.setMouseNavEnabled(true);
        break;
      case 'addAnnotation':
        this.mouseMode = 'addAnnotation';
        this.setFabricCanvasInteractivity(false);
        this.deselectFabricObjects();
        this.viewer.setMouseNavEnabled(false);
        break;
      case 'editAnnotation':
        this.mouseMode = 'editAnnotation';
        this.setFabricCanvasInteractivity(true);
        this.viewer.setMouseNavEnabled(false);
        break;
      case 'editSingleAnnotation':
        this.mouseMode = 'editAnnotation';
        this.viewer.setMouseNavEnabled(false);
        break;
      default:
        //console.log(mode);
        throw 'Tried to set invalid mouse mode';
    }
  },

  getMouseMode: function() {
    return this.mouseMode;
  },

  /*
    We duplicate objects before serializing them because... TODO: why do we have to duplicate again? It's definitely
    */
  duplicateObject: function(object) {
    var objectCopy = {
      left: object['left'],
      top: object['top'],
      stroke: object['stroke'],
      strokeWidth: object['strokeWidth'],
      originX: object['originX'],
      originY: object['originY'],
      fill: object['fill'],
      angle: object['angle'],
      type: object['type'],
      scaleX: object['scaleX'],
      scaleY: object['scaleY'],
      points: object['points'],
      text: object['text'],
      rx: object['rx'],
      ry: object['ry'],
      height: object['height'],
      width: object['width'],
      pk: object['pk'],
      image: object['image'],
      size: object['size'],
      path: object['path'],
      pathOffset: object['pathOffset'],
    };
    return objectCopy;
  },

  /* JSON currentAnnotationShape -> Ajax -> Django ORM/MariaDB */
  createNewSerialization: function(fabricObject, x, y) {
    if (fabricObject.type == 'textboxPreview') {
      this.text = new fabric.Textbox('MyText', {
        width: x - this.origX,
        top: this.origY,
        left: this.origX,
        fontSize: 100,
        stroke: this.currentAnnotationColor,
        fill: this.currentAnnotationColor,
        borderColor: this.currentAnnotationColor,
        textAlign: 'center',
        scaleX: 1,
        scaleY: 1,
        type: 'text',
        size: this.currentAnnotationSize,
      });
      this.currentAnnotationType = this.text;
      this.overlay.fabricCanvas().add(this.text);
      this.textboxPreview.remove();
      fabricObject = this.text;
    }

    // This next block was modified, might not work so maybe don't base your code off of it
    // // Color edge cases/aesthetic
    // var temp = this.duplicateObject(fabricObject);
    // if(fabricObject.type == "arrow") { //arrow only needs fill
    // 	temp["fill"] = fabricObject["fill"];
    //     temp["stroke"] = fabricObject["fill"]  //assign stroke to a random color to keep database happy. We ignore this when we repaint arrow on load
    //
    // }else if (fabricObject.type == "text") { //text needs both stroke and fill
    // 	temp["stroke"] = fabricObject["stroke"];
    // 	temp["fill"] = fabricObject["fill"];
    // } else { //everything else only needs stroke
    // 	temp["stroke"] = fabricObject["stroke"];
    //     temp["fill"] = fabricObject["stroke"];  //assign fill to a random color to keep database happy. We ignore this when we repaint any non-arrow on load
    // }

    // This ajax call should be good though
    // $.ajax({
    // 	type: "POST",
    // 	url: '/xgds_image/addAnnotation/',
    // 	datatype: 'json',
    // 	data: {
    // 		annotation: JSON.stringify(temp),
    // 		image_pk: this.imageJson["pk"]
    // 	},
    // 	success: function (data) {
    // 		fabricObject.set({pk: data["pk"], image: data["image_pk"]});
    //         OpenseadragonAnnotations.annotationsDict[data["pk"]] = data; // add current annotation to annotationsDict
    //     },
    //     error: function (e) {
    //     	console.log("Ajax error");
    //     	console.log(e);
    //     }
    // });
  },

  deleteCurrentAnnotation: function() {
    const canvasObjects = this.overlay.fabricCanvas().getObjects();
    if (canvasObjects.length !== 0) {
      const currentAnntation = canvasObjects[canvasObjects.length - 1];
      this.overlay.fabricCanvas().remove(currentAnntation);
    }
  },

  // Select the currently selected annotation from the canvas and pass on to deleteAnnotation
  deleteActiveAnnotation: function() {
    // Break out if no annotation is currently selected
    if (this.overlay.fabricCanvas().getActiveObject() == null) {
      alert('Please select the annotation you would like to delete');
      return;
    }
    var annotation = this.overlay.fabricCanvas().getActiveObject();
    this.deleteAnnotation(annotation);
  },

  // Get all objects from canvas and pass each one to deleteAnnotation()
  deleteAllAnnotations: function() {
    var objects = OpenseadragonAnnotations.overlay.fabricCanvas().getObjects();
    /* if objects is null, catch */
    if (objects.length == 0) {
      //console.log("No annotations on canvas to delete");
      return;
    }

    var objectsLength = objects.length;
    for (var i = 0; i < objectsLength; i++) {
      this.deleteAnnotation(objects[objectsLength - i - 1]);
    }
  },

  /*
     Given an annotation model, add it to the canvas if not already drawn
     */
  addAnnotationToCanvas: function(annotationJson) {
    // if (annotationJson["pk"] in this.annotationsDict) {
    //   console.log(
    //     "Annotation is already drawn on canvas, aborting load for this annotation"
    //   );
    //   return;
    // } else {
    //   //otherwise, add annotation to annotationsDict and draw it by calling one of the addShapeToCanvas() functions below
    //   this.annotationsDict[annotationJson["pk"]] = annotationJson;
    // }

    if (annotationJson['type'] == 'rect') {
      this.addRectToCanvas(annotationJson);
    } else if (annotationJson['type'] == 'ellipse') {
      this.addEllipseToCanvas(annotationJson);
    } else if (annotationJson['type'] == 'arrow') {
      this.addArrowToCanvas(annotationJson);
    } else if (annotationJson['type'] == 'text') {
      this.addTextToCanvas(annotationJson);
    } else if (annotationJson['type'] == 'path') {
      this.addBrushToCanvas(annotationJson);
    } else {
      throw new Error(
        'Tried to load an undefined shape to canvas (can only load rectangles, ellipses, arrows, lines'
      );
    }
  },

  addRectToCanvas: function(annotationJson) {
    this.rect = new fabric.Rect({
      left: annotationJson['left'],
      top: annotationJson['top'],
      stroke:
        typeof annotationJson['stroke'] !== 'undefined'
          ? annotationJson['stroke']
          : this.currentAnnotationColor, // todo: hex?
      strokeWidth: annotationJson['strokeWidth'],
      originX: annotationJson['originX'],
      originY: annotationJson['originY'],
      fill: annotationJson['fill'], //don't support fill
      angle: annotationJson['angle'],
      width: annotationJson['width'],
      height: annotationJson['height'],
      type: 'rect',
      scaleX: annotationJson['scaleX'],
      scaleY: annotationJson['scaleY'],
      pk: annotationJson['pk'],
      image: annotationJson['image'],
      size: annotationJson['size'],
      text: annotationJson['text'],
      annotationType: annotationJson['annotationType'],
    });

    this.overlay.fabricCanvas().add(this.rect);
    this.overlay.fabricCanvas().renderAll();
  },

  addBrushToCanvas: function(annotationJson) {
    fabric.util.enlivenObjects([annotationJson], (objects) => {
      var origRenderOnAddRemove = this.overlay.fabricCanvas().renderOnAddRemove;
      this.overlay.fabricCanvas().renderOnAddRemove = false;
      objects.forEach((o) => {
        this.overlay.fabricCanvas().add(o);
      });
      this.overlay.fabricCanvas().renderOnAddRemove = origRenderOnAddRemove;
      this.overlay.fabricCanvas().renderAll();
    });
  },

  addEllipseToCanvas: function(annotationJson) {
    this.ellipse = new fabric.Ellipse({
      left: annotationJson['left'],
      top: annotationJson['top'],
      stroke:
        typeof annotationJson['stroke'] !== 'undefined'
          ? annotationJson['stroke']
          : this.currentAnnotationColor,
      strokeWidth: annotationJson['strokeWidth'],
      originX: annotationJson['originX'],
      originY: annotationJson['originY'],
      fill: '', //don't support fill
      angle: annotationJson['angle'],
      rx: annotationJson['rx'],
      ry: annotationJson['ry'],
      type: 'ellipse',
      scaleX: annotationJson['scaleX'],
      scaleY: annotationJson['scaleY'],
      pk: annotationJson['pk'],
      image: annotationJson['image'],
      size: annotationJson['size'],
      text: annotationJson['text'],
    });
    this.overlay.fabricCanvas().add(this.ellipse);
    this.overlay.fabricCanvas().renderAll();
  },

  addArrowToCanvas: function(annotationJson) {
    /* Arrows "stroke" is actually their fill. Their stroke/border is always black */
    this.arrow = new fabric.Polyline(annotationJson['points'], {
      left: annotationJson['left'],
      top: annotationJson['top'],
      stroke:
        typeof annotationJson['stroke'] !== 'undefined'
          ? annotationJson['stroke']
          : this.currentAnnotationColor,
      strokeWidth: annotationJson['strokeWidth'],
      originX: annotationJson['originX'],
      originY: annotationJson['originY'],
      fill: annotationJson['fill'], // todo: hex? fill or strokeColor?
      angle: annotationJson['angle'],
      type: 'arrow',
      scaleX: annotationJson['scaleX'],
      scaleY: annotationJson['scaleY'],
      pk: annotationJson['pk'],
      image: annotationJson['image'],
      size: annotationJson['size'],
      text: annotationJson['text'],
      annotationType: annotationJson['annotationType'],
    });

    this.overlay.fabricCanvas().add(this.arrow);
    this.overlay.fabricCanvas().renderAll();
  },

  addTextToCanvas: function(annotationJson) {
    this.text = new fabric.Textbox('hello world', {
      left: annotationJson['left'],
      top: annotationJson['top'],
      stroke:
        typeof annotationJson['stroke'] !== 'undefined'
          ? annotationJson['stroke']
          : this.currentAnnotationColor,
      strokeWidth: annotationJson['strokeWidth'],
      originX: annotationJson['originX'],
      originY: annotationJson['originY'],
      fill: annotationJson['fill'], // todo: hex? fill?
      borderColor: annotationJson['fill'], // todo: hex? fill?
      angle: annotationJson['angle'],
      width: annotationJson['width'],
      height: annotationJson['height'],
      text: annotationJson['text'], //text should be the right field here //todonow: this may be the wrong thing to call it.
      type: 'text',
      scaleX: annotationJson['scaleX'],
      scaleY: annotationJson['scaleY'],
      pk: annotationJson['pk'],
      image: annotationJson['image'],
      textAlign: 'center',
      fontSize: 100, //Font size is static for now
      size: annotationJson['size'],
      annotationType: annotationJson['annotationType'],
    });

    this.overlay.fabricCanvas().add(this.text);
    this.overlay.fabricCanvas().renderAll();
  },

  turnAnnotationsOnOff: function(onOrOff) {
    var objects = OpenseadragonAnnotations.overlay.fabricCanvas().getObjects();
    if (onOrOff == 'off') {
      OpenseadragonAnnotations.showAnnotations = 'false';
      for (var i = 0; i < objects.length; i++) {
        //set all objects as invisible and lock in position
        objects[i].visible = false;
        objects[i].lockMovementX = true;
        objects[i].lockMovementY = true;
        objects[i].lockRotation = true;
        objects[i].lockScalingFlip = true;
        objects[i].lockScalingX = true;
        objects[i].lockScalingY = true;
        objects[i].lockSkewingX = true;
        objects[i].lockSkewingY = true;
        objects[i].lockUniScaling = true;
      }
    } else {
      OpenseadragonAnnotations.showAnnotations = 'true';
      //set all objects as visible and unlock
      for (var i = 0; i < objects.length; i++) {
        objects[i].visible = true;
        objects[i].lockMovementX = false;
        objects[i].lockMovementY = false;
        objects[i].lockRotation = false;
        objects[i].lockScalingFlip = false;
        objects[i].lockScalingX = false;
        objects[i].lockScalingY = false;
        objects[i].lockSkewingX = false;
        objects[i].lockSkewingY = false;
        objects[i].lockUniScaling = false;
      }
    }
    OpenseadragonAnnotations.overlay.fabricCanvas().renderAll();
  },

  turnAnnotationsOnOffByUser: function(onOrOff, annotationIds) {
    const objects = OpenseadragonAnnotations.overlay
      .fabricCanvas()
      .getObjects();
    if (!annotationIds || annotationIds.length === 0) {
      return false;
    }
    const userObjects = objects.filter((dataSet) =>
      annotationIds.includes(dataSet.pk)
    );

    if (onOrOff == 'off') {
      for (var i = 0; i < userObjects.length; i++) {
        //set all objects as invisible and lock in position
        userObjects[i].visible = false;
        userObjects[i].lockMovementX = true;
        userObjects[i].lockMovementY = true;
        userObjects[i].lockRotation = true;
        userObjects[i].lockScalingFlip = true;
        userObjects[i].lockScalingX = true;
        userObjects[i].lockScalingY = true;
        userObjects[i].lockSkewingX = true;
        userObjects[i].lockSkewingY = true;
        userObjects[i].lockUniScaling = true;
      }
    } else {
      //set all objects as visible and unlock
      for (var i = 0; i < userObjects.length; i++) {
        userObjects[i].visible = true;
        userObjects[i].lockMovementX = false;
        userObjects[i].lockMovementY = false;
        userObjects[i].lockRotation = false;
        userObjects[i].lockScalingFlip = false;
        userObjects[i].lockScalingX = false;
        userObjects[i].lockScalingY = false;
        userObjects[i].lockSkewingX = false;
        userObjects[i].lockSkewingY = false;
        userObjects[i].lockUniScaling = false;
      }
    }
    OpenseadragonAnnotations.overlay.fabricCanvas().renderAll();
  },

  appendAllAnnotations: function(annotations, when, addAnnotations) {
    const filteredAnnotationArray = this.removeDuplicates(annotations, 'pk');
    filteredAnnotationArray.forEach((annotation) => {
      OpenseadragonAnnotations.addAnnotationToCanvas(annotation);
      if (when == 'quorum' && !addAnnotations) {
        OpenseadragonAnnotations.turnAnnotationsOnOff('off');
      }
    });
  },

  changeAllAnnotationStrokeColor: function(color) {
    this.currentAnnotationColor = color;
  },

  refresAnnotationData: function(newData) {
    const canvas = this.overlay.fabricCanvas();
    canvas.remove(...canvas.getObjects());
    newData.forEach((annotation) => {
      OpenseadragonAnnotations.addAnnotationToCanvas(annotation);
    });
  },

  removeDuplicates: function(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  },

  appendPolygon: function(
    fabricPoints,
    selectedAalysisId,
    selectedAalysisVisible
  ) {
    let dataArray = [];
    if (selectedAalysisVisible) {
      fabricPoints.forEach((geoData) => {
        const minX = geoData.GeoJSON.coordinates[0].reduce(
          (min, p) => (p.x < min ? p.x : min),
          geoData.GeoJSON.coordinates[0][0].x
        );
        const minY = geoData.GeoJSON.coordinates[0].reduce(
          (min, p) => (p.y < min ? p.y : min),
          geoData.GeoJSON.coordinates[0][0].y
        );
        this.ploygon = new fabric.Polygon(geoData.GeoJSON.coordinates[0], {
          left: minX,
          top: minY,
          fill: '',
          stroke: 'blue',
          opacity: 1,
          strokeWidth: 10,
          type: 'polygon',
        });
        dataArray.push(this.ploygon);
      });
    }

    let fabricObjects = this.overlay.fabricCanvas().getObjects();
    if (fabricObjects.length === 32) {
      this.overlay.fabricCanvas().clear();
      let group = new fabric.Group(dataArray, {
        id: selectedAalysisId,
      });
      this.currentAnnotationType = 'polygon';
      this.overlay.fabricCanvas().add(group);
      this.overlay.fabricCanvas().renderAll();
    } else {
      if (!selectedAalysisVisible) {
        fabricObjects.forEach((obj) => {
          if (obj.id === selectedAalysisId) {
            this.overlay.fabricCanvas().remove(obj);
            this.overlay.fabricCanvas().renderAll();
          }
        });
      } else {
        let group = new fabric.Group(dataArray, {
          id: selectedAalysisId,
        });
        this.currentAnnotationType = 'polygon';
        this.overlay.fabricCanvas().add(group);
        this.overlay.fabricCanvas().renderAll();
      }
    }
  },

  appendHeatMapReact: function(heatMapJSON, selectedAalysisVisible) {
    let dataArray = [];
    heatMapJSON.forEach((rectDataObj) => {
      this.rectangle = new fabric.Rect({
        ...rectDataObj,
        size: this.currentAnnotationSize,
      });
      dataArray.push(this.rectangle);
    });
    let fabricObjects = this.heatMapOverlay.fabricCanvas().getObjects();
    if (selectedAalysisVisible) {
      let group = new fabric.Group(dataArray);
      this.currentAnnotationType = 'react';
      this.heatMapOverlay.fabricCanvas().add(group);
      this.heatMapOverlay.fabricCanvas().renderAll();
    } else {
      fabricObjects.forEach((obj) => {
        this.heatMapOverlay.fabricCanvas().remove(obj);
        this.heatMapOverlay.fabricCanvas().renderAll();
      });
    }
  },

  appendAnalysisData: function(
    fabricPoints,
    selectedAalysisId,
    selectedAalysisVisible
  ) {
    OpenseadragonAnnotations.appendPolygon(
      fabricPoints,
      selectedAalysisId,
      selectedAalysisVisible
    );
  },

  appendHeatMapData: function(dataObj, selectedAalysisVisible) {
    OpenseadragonAnnotations.appendHeatMapReact(
      dataObj,
      selectedAalysisVisible
    );
  },
}; // end of namespace

module.exports = OpenseadragonAnnotations;
