import * as methods from "./methods";

const initialState = {
  folders: [],
  moveFolders: [],
  dirDataList: [],
  fileList: [],
  selectedMoveFolderData: {
    selectedMoveFolder: null,
    selectedMoveFolderName: null,
    selectedMoveFolderType: null,
  },
  selectedFolderData: {
    selectedFolder: null,
    selectedFolderName: null,
    selectedFolderType: null,
    description: null,
  },
  selectedListData: {
    selectedList: null,
    selectedListName: null,
    selectedListType: null,
  },
  showSnackBar: false,
  conversionComplete: false,
  percentCompleted: false,
  breadCrumbIcon: { showIcon: false },
  showLoader: false,
  isSearchData: false,
  uploadPageLoad: false,
  movePopupState: false,
  selectedRightClickItem: null,
  selectedListSlideData: null,
  selectedCaseAssignUsers: [],
  selectedDirActivities: [],
  selectedPatientInfo: [],
  selectedCaseInfo: [],
  isListView: true,
  isPatientView: false,
  caseReviewStatus: "",
  userList: [],
  isMoveDirActive: false,
  allSlides: [],
  allAttachments: [],
  patientDataList: [],
  allTabs: [],
  currentTab: {},
  myWorklists: [],
  systemWorklists: [],
  worklistColumnData: [],
  searchParams: {},
  newCaseDirId: null,
  message: "",
  showMessage: false,
  selectedDetails: null,
  notesHistoryData: [],
  referredNotesData: [],
  isAccessionNumberUnique: true,
  returnToWorklistData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_LIST":
      return {
        ...state,
        userList: action.payload,
      };

    case "SET_SLIDE_BOX_LOADING": {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case "SET_FOLDER_LISTS": {
      return {
        ...state,
        folders: action.payload,
        moveFolders: action.payload,
        selectedListData: initialState.selectedListData,
        selectedFolderData: {
          selectedFolder:
            action.payload.length > 0 ? action.payload[0]["dirId"] : null,
          selectedFolderName:
            action.payload.length > 0 ? action.payload[0]["dirName"] : null,
          selectedFolderType: "root",
        },
      };
    }

    case "SET_FOLDERS": {
      const { folders } = action.data;
      return {
        ...state,
        folders,
        moveFolders: folders,
      };
    }

    case "SET_SELECTED_UPLOAD_FOLDER": {
      const { folder } = action.data;
      return {
        ...state,
        selectedUploadFolder: folder,
      };
    }

    case "SET_LOAD_QUOROM": {
      return {
        ...state,
        isQuorumLoaded: true,
        quorumImageFromSlideBox: action.data,
      };
    }

    case "SET_CASESTREAM_MODAL": {
      return {
        ...state,
        casestreamModalData: action.data,
      };
    }

    case "SET_ADD_QUOROM_BUTTON": {
      return {
        ...state,
        addQuorumEnableButton: action.data,
      };
    }

    case "SET_ADD_TUMOR_BOARDS_ENABLE": {
      return {
        ...state,
        addTumorBoardEnableButton: action.enable,
      };
    }

    case "SET_EXPAND_FOLDERS": {
      return {
        ...state,
        folders: action.parentFolder
          ? methods.setExpandChildFolder(action, state.folders)
          : methods.setExpandFolder(action, state.folders),
        selectedFolderData: {
          selectedFolder: action.folder,
          selectedFolderName: methods.setOpenFolderName(
            action.listId,
            state.folders
          ),
          selectedFolderType: action.dirType,
        },
      };
    }

    case "SET_EXPAND_FOLDERS_IN_MOVE":
      {
        return {
          ...state,
          moveFolders: action.parentFolder
            ? methods.setExpandChildFolder(action, state.moveFolders)
            : methods.setExpandFolder(action, state.moveFolders),
          selectedMoveFolderData: {
            selectedMoveFolder: action.folder,
            selectedMoveFolderName: methods.setOpenFolderName(
              action.listId,
              state.moveFolders
            ),
            selectedMoveFolderType: action.dirType,
          },
        };
      }
      s;

    case "SET_SELECTED_DIRS": {
      return {
        ...state,
        folders: action.parentFolder
          ? methods.setSelectedChildFolder(action, state.folders)
          : methods.setSelectedFolder(action, state.folders),
        selectedFolderData: {
          selectedFolder: action.folder,
          selectedFolderName: methods.setOpenFolderName(
            action.listId,
            state.folders
          ),
          description: methods.setDescription(action.listId, state.folders),
          selectedFolderType: action.dirType,
        },
      };
    }

    case "SET_SELECTED_DIRS_IN_MOVE": {
      return {
        ...state,
        moveFolders: action.parentFolder
          ? methods.setSelectedChildFolder(action, state.moveFolders)
          : methods.setSelectedFolder(action, state.moveFolders),
        selectedMoveFolderData: {
          selectedMoveFolder: action.folder,
          selectedMoveFolderName: methods.setOpenFolderName(
            action.listId,
            state.moveFolders
          ),
          selectedMoveFolderType: action.dirType,
        },
      };
    }

    case "SET_OPEN_FOLDERS": {
      return {
        ...state,
        folders: action.parentFolder
          ? methods.setOpenChildFolder(action, state.folders)
          : methods.setOpenFolder(action, state.folders),
        selectedFolderData: {
          selectedFolder: action.folder,
          selectedFolderName: methods.setOpenFolderName(
            action.listId,
            state.folders
          ),
          selectedFolderType: action.dirType,
        },
      };
    }

    case "SET_OPEN_FOLDERS_IN_MOVE": {
      return {
        ...state,
        moveFolders: action.parentFolder
          ? methods.setOpenChildFolder(action, state.moveFolders)
          : methods.setOpenFolder(action, state.moveFolders),
        selectedMoveFolderData: {
          selectedMoveFolder: action.folder,
          selectedMoveFolderName: methods.setOpenFolderName(
            action.listId,
            state.moveFolders
          ),
          selectedMoveFolderType: action.dirType,
        },
      };
    }

    case "SET_OPEN_LIST_FOLDERS": {
      return {
        ...state,
        folders: action.parentFolder
          ? methods.setOpenChildFolder(action, state.folders)
          : methods.setOpenFolder(action, state.folders),
        selectedFolderData: {
          selectedFolder: action.folder,
          selectedFolderName: methods.setOpenFolderName(
            action.listId,
            state.folders
          ),
          selectedFolderType: action.dirType,
          description: methods.setDescription(action.listId, state.folders),
        },
        selectedListData: initialState.selectedListData,
      };
    }

    case "SET_SELECTED_LIST_DIRS": {
      return {
        ...state,

        selectedListData: {
          selectedList: action.folder,
          // selectedListName:
          //   action.dirType === "slide" || action.dirType === "document"
          //     ? methods.setOpenSlideName(action.listId, state.worklistData)
          //     : methods.setOpenFolderName(action.listId, state.folders),
          selectedListType: action.dirType,
        },
      };
    }

    case "SET_SLIDE_DATA_FROM_LIST": {
      return {
        ...state,
        selectedListSlideData: action.payload,
      };
    }

    case "SET_RETURN_TO_WORKLIST": {
      return {
        ...state,
        returnToWorklistData: {...state.returnToWorklistData, ...action.payload},
      };
    }

    case "SET_NEW_DIRECTORY": {
      return {
        ...state,
        folders: [
          ...state.folders,
          { ...action.payload, listId: state.folders.length + 1 },
        ],
        moveFolders: [
          ...state.moveFolders,
          { ...action.payload, listId: state.moveFolders.length + 1 },
        ],
        selectedListData: {
          selectedList: action.payload.dirId,
          selectedListType: "case",
        },
        currentTab: {
          ...state.allTabs[0],
        },
      };
    }

    case "SET_SEARCH_PARAMS": {
      return {
        ...state,
        searchParams: action.payload,
      };
    }

    case "RENAME_DIRECTORY": {
      return {
        ...state,
        folders: methods.setRenameFolderName(
          action.dirId,
          action.dirName,
          state.folders,
          action.dirType
        ),
        moveFolders: methods.setRenameFolderName(
          action.dirId,
          action.dirName,
          state.moveFolders,
          action.dirType
        ),
        dirDataList: methods.setRenameListFolderName(
          action.dirId,
          action.dirName,
          state.dirDataList
        ),
        selectedListData: {
          ...state.selectedListData,
          selectedListName: action.dirName,
        },
        selectedFolderData: {
          ...state.selectedFolderData,
          selectedFolderName: action.dirName,
        },
        allSlides: state.allSlides.map((slide) => {
          if (slide.slidebox_slide_id === action.payload.slideId) {
            return {
              ...slide,
              slide_name: action.payload.slideName,
            };
          } else {
            return { ...slide };
          }
        }),
      };
    }

    case "DELETE_DIRECTORY": {
      return {
        ...state,
        folders:
          action.dirType === "slide" || action.dirType === "document"
            ? state.folders
            : methods.setDeleteFolder(action.dirId, state.folders),
        moveFolders:
          action.dirType === "slide" || action.dirType === "document"
            ? state.moveFolders
            : methods.setDeleteFolder(action.dirId, state.moveFolders),
        dirDataList: methods.setDirDataListFolder(
          action.dirId,
          state.dirDataList
        ),
        selectedListData: {
          selectedList: null,
          selectedListName: null,
          selectedListType: null,
        },
      };
    }

    case "DELETE_FOLDER": {
      return {
        ...state,
        dirDataList: methods.setDirDataListFolder(
          action.dirId,
          state.dirDataList
        ),
        selectedListData: {
          selectedList: null,
          selectedListName: null,
          selectedListType: null,
        },
      };
    }

    case "SET_FOLDER_DATA_LISTS": {
      return {
        ...state,
        dirDataList: action.payload,
        isSearchData: false,
        uploadPageLoad: false,
        selectedListData: {
          selectedList: null,
          selectedListName: null,
          selectedListType: null,
        },
      };
    }

    case "UNSET_SELECTED_LIST_DATA": {
      return {
        ...state,
        isSearchData: false,
        uploadPageLoad: false,
        selectedListData: {
          selectedList: null,
          selectedListName: null,
          selectedListType: null,
        },
      };
    }

    case "SHOW_SNACK_BAR_COMPONENT": {
      return {
        ...state,
        showSnackBar: action.payload.show,
        fileList: [...state.fileList, ...action.payload.fileList],
        conversionComplete: action.payload.conversionComplete,
        percentCompleted: action.payload.percentCompleted,
      };
    }

    case "SET_FILE_UPLOAD_PROGRESS": {
      return {
        ...state,
        fileList: methods.updateFileData(
          state.fileList,
          action.payload.fileIndex,
          {
            progress: action.payload.percentCompleted,
          }
        ),
      };
    }

    case "SET_FILE_CANCELLER": {
      return {
        ...state,
        fileList: methods.updateFileData(
          state.fileList,
          action.payload.fileIndex,
          {
            canceller: action.payload.canceller,
          }
        ),
      };
    }

    case "REMOVE_UPLOADING_FILE": {
      return {
        ...state,
        fileList: methods.removeFile(state.fileList, action.payload.fileIndex),
      };
    }

    case "CLEAR_UPLOADING_LIST": {
      return {
        ...state,
        fileList: [],
      };
    }

    case "BREADCRUMB_ICONS_STATUS": {
      return {
        ...state,
        breadCrumbIcon: action.payload,
      };
    }

    case "SHOW_LOADER_IN_SLIDEBOX": {
      return {
        ...state,
        showLoader: action.showLoader,
      };
    }

    case "SWITCH_VIEW": {
      return {
        ...state,
        isListView: action.isListView,
      };
    }
    // test 1
    case "SWITCH_LIST_VIEW": {
      return {
        ...state,
        isPatientView: action.isPatientView,
      };
    }

    case "SET_SEARCH_DIR_DATA": {
      return {
        ...state,
        worklistColumnData: action.payload,
        isSearchData: true,
        currentTab: null,
      };
    }

    case "RESET_SLIDE_UPLOAD_STATE": {
      return {
        ...state,
        showSnackBar: false,
        conversionComplete: false,
        percentCompleted: false,
        uploadPageLoad: true,
      };
    }

    case "SLIDEBOX_DIR_DETAILS_UPDATE": {
      return {
        ...state,
        dirDataList: methods.updateDirDetails(
          action.payload,
          state.dirDataList
        ),
      };
    }

    case "SLIDEBOX_CASE_DETAILS_UPDATE": {
      return {
        ...state,
        selectedCaseInfo: { ...action.payload },
      };
    }

    case "NEW_TAB": {
        return {
          ...state,
          allTabs: [
            ...state.allTabs,
            {
              worklistId: action.payload.worklistId,
              label: action.payload.label,
              worklistColor: action.payload.worklistColor,
              worklistType: action.payload.worklistType,
            },
          ],
          currentTab: {
            worklistId: action.payload.worklistId,
            label: action.payload.label,
            worklistColor: action.payload.worklistColor,
          },
        };
    }

    case "ALL_OPEN_TABS": {
      return {
        ...state,
        allTabs: action.payload,
      };
    }

    case "SET_CURRENT_TAB": {
      return {
        ...state,
        currentTab: action.payload,
      };
    }

    case "CLOSE_TAB": {
      let tabs = [...state.allTabs];
      tabs.splice(action.payload.index, 1);

      return {
        ...state,
        allTabs: tabs,
        worklistColumnData: tabs.length > 0 ? state.worklistColumnData : [],
        currentTab: tabs.find((tab) => tab.label === "All Cases"),
      };
    }

    case "CLOSE_FINAL_TAB": {
      let tabs = [...state.allTabs];
      tabs.splice(action.payload.index, 1);

      return {
        ...state,
        allTabs: tabs,
        worklistColumnData: tabs.length > 0 ? state.worklistColumnData : [],
      };
    }

    case "UPDATE_TAB_DATA": {
      var tabs = [...state.allTabs];
      if (action.payload.actionType === "rename-worklist") {
        tabs[action.payload.index].label = action.payload.newName;
      } else if (action.payload.actionType === "update-color") {
        tabs[action.payload.index].worklistColor = action.payload.worklistColor;
      }
      return {
        ...state,
        allTabs: tabs,
      };
    }

    case "SLIDEBOX_LABELS_UPDATE": {
      return {
        ...state,
        worklistColumnData: state.worklistColumnData.map((item) => {
          if (
            item.dirDataId === action.payload.entityId &&
            item.caseOrFolder === action.payload.entityType
          ) {
            return {
              ...item,
              labels:
                action.payload.labels &&
                action.payload.labels.map((label) => label.value).toString(),
            };
          } else {
            return item;
          }
        }),
      };
    }

    case "UPDATE_WORKLIST_COLUMNS_DATA": {
      const {
        accessionNumber,
        procedureName,
        procedureDate,
        orderingPhysician,
        priority,
        caseStatus,
        assignedToFirstName,
        assignedToLastName,
        referredToFirstName,
        referredToLastName,
        notes,
      } = action.payload;
      return {
        ...state,
        worklistColumnData: state.worklistColumnData.map((item) => {
          if (item.dirDataId === action.payload.dirId) {
            return {
              ...item,
              accessionNumber: accessionNumber,
              dirDataName: procedureName,
              procedureDate,
              orderingPhysician,
              priority,
              review_status: caseStatus,
              referredToFirstName,
              referredToLastName,
              notes,
              assignTo:
                assignedToFirstName !== null
                  ? [
                      {
                        first_name: assignedToFirstName,
                        last_name: assignedToLastName,
                      },
                    ]
                  : [],
            };
          } else {
            return item;
          }
        }),
      };
    }

    case "SET_SLIDEBOX_MOVE_POPUP_STATE": {
      return {
        ...state,
        moveFolders: state.moveFolders.map((dirData) => {
          return {
            ...dirData,
            active: dirData.dirId === 1 ? true : false,
          };
        }),
        movePopupState: action.payload,
        selectedMoveFolderData: {
          selectedMoveFolder: state.moveFolders[0]["dirId"],
          selectedMoveFolderName: state.moveFolders[0]["dirName"],
          selectedMoveFolderType: "root",
        },
      };
    }

    case "SET_SLIDEBOX_MOVE_DIR": {
      return {
        ...state,
        folders: methods.setUpdateFolder(action.payload, state.folders),
        moveFolders: methods.setUpdateMoveFolder(
          action.payload,
          state.moveFolders
        ),
        dirDataList: methods.setDirDataListFolder(
          action.payload.selectedDir,
          state.dirDataList
        ),
      };
    }

    case "RESET_MOVE_DIR_ACTIVE": {
      return {
        ...state,
        isMoveDirActive: action.payload,
      };
    }

    case "SET_SLIDEBOX_DIR_COLLABORATORS": {
      return {
        ...state,
        selectedDirCollaborators: action.payload,
      };
    }

    case "SET_SLIDEBOX_NEW_COLLABORATORS": {
      return {
        ...state,
        selectedDirCollaborators: [
          ...state.selectedDirCollaborators,
          ...action.payload,
        ],
      };
    }

    case "SET_SLIDEBOX_REMOVE_COLLABORATORS": {
      return {
        ...state,
        selectedDirCollaborators: methods.removeCollaborators(
          action.payload,
          state.selectedDirCollaborators
        ),
      };
    }

    case "SET_SLIDEBOX_RIGHT_CLICK_SLECTED": {
      return {
        ...state,
        selectedRightClickItem: action.payload,
      };
    }

    case "SET_SLIDEBOX_CASE_ASSIGN": {
      return {
        ...state,
        selectedCaseAssignUsers: action.payload,
        dirDataList:
          typeof action.dirId !== "undefined"
            ? methods.setCaseAssignUser(
                action.payload,
                action.dirId,
                state.dirDataList
              )
            : state.dirDataList,
      };
    }

    case "SET_SLIDEBOX_CASE_ASSIGN_USERS": {
      return {
        ...state,
        selectedCaseAssignUsers: action.payload,
      };
    }

    case "SET_SLIDEBOX_DIR_ACTIVITY": {
      return {
        ...state,
        selectedDirActivities: action.payload,
      };
    }

    case "REMOVE_SLIDEBOX_CASE_ASSIGN": {
      return {
        ...state,
        selectedCaseAssignUsers: null,
        dirDataList: methods.updateCaseAssignUser(
          action.payload.dirId,
          state.dirDataList
        ),
      };
    }

    case "SET_SLIDEBOX_DIR_ASSIGN_USERS": {
      return {
        ...state,
        selectedCaseAssignUsers: action.payload,
      };
    }

    case "SET_CASE_REVIEW_STATUS": {
      return {
        ...state,
        worklistColumnData: state.worklistColumnData.map((item) => {
          if (item.dirDataId === action.payload.dirId) {
            return {
              ...item,
              review_status: action.payload.reviewStatus,
              lastModified: action.payload.lastModified,
            };
          } else {
            return item;
          }
        }),
        selectedCaseInfo: {
          ...state.selectedCaseInfo,
          caseStatus: action.payload.reviewStatus,
        },
      };
    }

    case "SET_ADVANCED_SEARCH_DATA": {
      return {
        ...state,
        dirDataList: action.payload,
      };
    }

    case "UPDATE_STAIN_TYPE": {
      return {
        ...state,
        allSlides: state.allSlides.map((slide) => {
          if (slide.slidebox_slide_id === action.payload.slideId) {
            return {
              ...slide,
              slide_stain: action.payload.stainType,
            };
          } else {
            return { ...slide };
          }
        }),
        dirDataList: state.dirDataList.map((item) => {
          if (item.dirDataId === action.payload.dirId) {
            return {
              ...item,
              stainType: action.payload.stainType,
            };
          } else {
            return item;
          }
        }),
      };
    }

    case "GET_ALL_SLIDES": {
      return {
        ...state,
        allSlides: action.payload,
      };
    }

    case "GET_ALL_MY_WORKLISTS": {
      return {
        ...state,
        myWorklists: action.payload,
      };
    }

    case "GET_ALL_SYSTEM_WORKLISTS": {
      return {
        ...state,
        systemWorklists: action.payload,
      };
    }

    case "GET_WORKLIST_COLUMN_DATA": {
      return {
        ...state,
        worklistColumnData: action.payload,
      };
    }

    case "GET_ALL_TABS": {
      return {
        ...state,
        allTabs: action.payload,
      };
    }

    case "GET_ALL_ATTACHMENTS": {
      return {
        ...state,
        allAttachments: action.payload,
      };
    }

    case "GET_SELECTED_PATIENT_DETAILS": {
      return {
        ...state,
        selectedPatientInfo: {
          ...action.payload.patientInfo,
          historical_cases: action.payload.historicalCases,
        },
      };
    }

    case "GET_SELECTED_CASE_DETAILS": {
      const selectedDirDetails = state.worklistColumnData.map((item) => {
        if (item.dirDataId === action.payload.data.dirId) {
          return {
            ...item,
          };
        }
      });
      return {
        ...state,
        selectedCaseInfo: {
          ...action.payload.caseInfo,
          totalContainers: action.payload.totalContainers,
          totalSections: action.payload.totalSections,
          totalBlocks: action.payload.totalBlocks,
          totalSlides: action.payload.totalSlides,
          labels: action.payload.labels,
        },
        selectedDetails: selectedDirDetails[0],
      };
    }

    case "SET_SEARCH_PATIENT_DATA": {
      return {
        ...state,
        patientDataList: action.payload,
      };
    }

    case "SET_CREATE_DIR_ID": {
      return {
        ...state,
        newCaseDirId: action.payload,
      };
    }

    case "SHOW_SLIDEBOX_MESSAGE":
      return {
        ...state,
        message: action.message,
        showMessage: true,
      };

    case "RESET_SLIDEBOX_MESSAGE":
      return {
        ...state,
        message: "",
        showMessage: false,
      };

    case "UPDATE_WORKLIST_PATIENT_COLUMNS": {
      const {
        mrnNumber,
        firstName,
        middleName,
        lastName,
        gender,
        dob,
        ssn,
        contactNumber,
        patientEmail,
        patientId,
      } = action.payload.patientDetails;
      return {
        ...state,
        worklistColumnData: state.worklistColumnData.map((item) => {
          if (item.patientId === patientId) {
            return {
              ...item,
              mrnNumber,
              patientFirstName: firstName,
              middleName,
              patientLastName: lastName,
              gender,
              dob,
              ssn,
              contactNumber,
              patientEmail,
            };
          } else {
            return item;
          }
        }),
      };
    }

    case "UPDATE_PRIORITY": {
      return {
        ...state,
        worklistColumnData: state.worklistColumnData.map((item) => {
          if (item.dirDataId === action.payload.dirId) {
            return {
              ...item,
              priority: action.payload.priority,
            };
          } else {
            return item;
          }
        }),
        selectedCaseInfo: {
          ...state.selectedCaseInfo,
          priority: action.payload.priority,
        },
      };
    }

    case "SET_NOTES_HISTORY": {
      const notesHistory = action.payload.filter(
        (data) => data.noteType === "other"
      );
      const referredNotes = action.payload.filter(
        (data) => data.noteType === "referred"
      );
      return {
        ...state,
        notesHistoryData: notesHistory,
        referredNotesData: referredNotes,
      };
    }
    case "CREATE_WORKLIST":
    case "UPDATE_WORKLIST_SEARCH_PARAMS": {
      return {
        ...state,
        currentTab: state.allTabs.find((tab) => tab.label === "All Cases"),
        isSearchData: false,
      };
    }

    case "CHANGE_TAB_COLOR": {
      return {
        ...state,
        allTabs: state.allTabs.map((item) => {
          if (item.worklistId === action.payload.worklistId) {
            return {
              ...item,
              worklistColor: action.payload.color,
            };
          } else {
            return item;
          }
        }),
      };
    }

    case "ACCESSION_NUMBER": {
      return {
        ...state,
        isAccessionNumberUnique: action.payload.isAccessionNumberUnique,
      };
    }

    default:
      return state;
  }
};
