import React, { Fragment, useEffect, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Bell from "../../assets/icons/BellIcon.svg";
import DefaultAvatar from "../../assets/icons/default-avatar.svg";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import PDFViewer from "../../pages/profile/components/PDFViewer";
import pdfReaderImg from "../../assets/icons/pdfReader.svg";
import CreateCaseModal from "../../pages/createCase/CreateCaseModal";
import ToolTip from "react-tooltip";
import { PrimaryButton } from "../../components";
import Can from "../../hoc/FullControllHoc/can";

const HeaderTopLinks = ({
  userProfileImage,
  loggedUserDetails,
  notificationCount,
  toggleOptions,
  history,
  licenseTypeFeatures,
  orderingPhysicians,
}) => {
  const [pathname, setPathname] = useState(history.location.pathname);
  const [openCreateCase, setOpenCreateCase] = useState(false);
  const [len, setLen] = useState(0);
  const unlisten = history.listen((location) => setPathname(location.pathname));

  useEffect(() => {
    return unlisten;
  }, [unlisten]);

  useEffect(() => {
    setLen(notificationCount);
  }, [notificationCount]);

  const [openUserGuideModal, setOpenUserGuideModal] = React.useState(false);

  const handleUserGuideModalOpen = () => {
    setOpenUserGuideModal(!openUserGuideModal);
  };
  const handleUserGuideModalClose = () => {
    setOpenUserGuideModal(false);
  };

  const handleOpenCreateCase = () => {
    setOpenCreateCase(!openCreateCase);
  };

  const handleCloseCreateCase = () => {
    setOpenCreateCase(false);
  };
  const canOpenCreateCase = orderingPhysicians.length && orderingPhysicians.find((e) => e.status === 'active');
  return (
    <Fragment>
      {loggedUserDetails && loggedUserDetails.institutionName && (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          whiteSpace: 'nowrap',
        }}>
          <p className="institution-name">{loggedUserDetails.institutionName}</p>
        </div>
      )}
      {pathname === "/" ? (
        <Can
          task="create-case"
          taskContainer="slidebox"
          edit={() => (
            <div className="create-case-btn">
              <ToolTip
                id="create-case-button"
                place="bottom"
                type="dark"
                effect="solid"
              />
                <PrimaryButton
                  data-for='create-case-button'
                  data-tip={canOpenCreateCase ? '' : 'Go to Dashboard > Ordering Physicians to create a physician first'}
                  label="Create Case"
                  onClick={() => {
                    if (canOpenCreateCase) handleOpenCreateCase();
                  }}
                />
            </div>
          )}
        />
      ) : null}
      <PDFViewer
        openModal={openUserGuideModal}
        onClose={handleUserGuideModalClose}
      />
      <IconButton
        color="info"
        aria-label="info"
        component="span"
        onClick={handleUserGuideModalOpen}
        className="pdf-viewer-icon"
      >
        <img
          src={pdfReaderImg}
          alt=""
          style={{ width: "20px", height: "20px", color: "#1E0191" }} //icon to be added here
        />
      </IconButton>
      <IconButton color="info" aria-label="info" component="span">
        <NavLink
          to="/notifications"
          activeClassName="ha_active"
          className="notification"
        >
          {notificationCount ? (
            <span className="notification_span nav_icon" data-badge={len}>
              <img
                className="bell-image"
                src={Bell}
                style={{ width: "20px", height: "20px", color: "#1E0191" }}
              />
            </span>
          ) : (
            <span className="notification_empty_span nav_icon">
              <img
                className="bell-image"
                src={Bell}
                style={{ width: "20px", height: "20px", color: "#1E0191" }}
              />
            </span>
          )}
        </NavLink>
      </IconButton>
      {loggedUserDetails && loggedUserDetails.institutionLogoUrl ? (
        <div className="institution-profile-container">
          <img
            className="institution-logo"
            src={loggedUserDetails.institutionLogoUrl}
            alt="institution logo"
          />
          <a
            activeClassname="ha_active"
            className="sp"
            onClick={(e) => toggleOptions(e)}
          >
            <img
              src={userProfileImage !== null ? userProfileImage : DefaultAvatar}
              alt="avatar"
              className="sp_img"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = { DefaultAvatar };
              }}
              id="profile-nav-image"
            />
          </a>
        </div>
      ) : (
        <a
          activeClassname="ha_active"
          className="sp"
          onClick={(e) => toggleOptions(e)}
        >
          <img
            src={userProfileImage !== null ? userProfileImage : DefaultAvatar}
            alt="avatar"
            className="sp_img"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = { DefaultAvatar };
            }}
            id="profile-nav-image"
          />
        </a>
      )}
      {openCreateCase && (
        <CreateCaseModal
          onClose={handleCloseCreateCase}
          licenseTypeFeatures={licenseTypeFeatures}
          openFromCaseDetails={false}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  licenseTypeFeatures: state.Global.loggedUserDetails.licenseTypeAccessData,
  notificationCount:
    state.Notifications.unreadNotificationList !== undefined &&
    state.Notifications.unreadNotificationList.length,
  dxAccountDetails: state.Global.dxAccountDetails,
  loggedUserDetails: state.Global.loggedUserDetails,
  orderingPhysicians: state.Dashboard.orderingPhysicians,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderTopLinks));
