import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import altIconGroup from "../../../assets/icons/tumor-board-default.jpg";
import Fade from "../../../components/Animations/Fade";
import Can from "./../../../hoc/FullControllHoc/can";
import GroupDeletePopup from "./group-delete-popup";
import GroupDetailsUser from "./group-details-user";

const GroupDetails = ({
  group,
  onEdit,
  onGroupRefresh,
  loggedUser,
  selectedMembers,
  changeActiveTab,
  onClickEmitter,
  footerNav,
  setSelectedGroupItemIndex,
}) => {
  const handleGroupDelete = () => {
    setSelectedGroupItemIndex();
    setShowDeletePopup(true);
  };

  useEffect(() => {
    onClickEmitter.addListener("onGroupClick", (target) => {
      if (!target.getAttribute("ispopup")) {
        setUserPopupIndex(-1);
      }
    });
    return () => {
      onClickEmitter.removeAllListeners(["onGroupClick"]);
    };
  }, []);

  useEffect(() => {
    if (footerNav) {
      footerNav("middle");
    }
  }, []);

  const isCreator = group.creator.id === loggedUser;
  const isAdmin = group.admin;
  const addDefaultSrc = (ev) => {
    ev.target.src = altIconGroup;
  };
  const [userPopupIndex, setUserPopupIndex] = useState(-1);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const onUserOptionClick = (index) => {
    setUserPopupIndex(index);
  };
  const onTitleClick = () => {
    changeActiveTab("image");
  };
  return (
    <div className="group-selected">
      <div className="group-selected-content">
        <div className="header">
          <img
            src={group.imgSrc ? group.imgSrc : altIconGroup}
            alt="group icon"
            onError={addDefaultSrc}
          />
          <div className="header-description">
            <h3 className="title" onClick={onTitleClick}>
              {group.name}
            </h3>
            {group.members && (
              <p className="members">
                {" "}
                {group.members.length <= 1
                  ? `${group.members.length} member`
                  : `${group.members.length} members`}
              </p>
            )}
          </div>
          {(isAdmin || isCreator) && (
            <div className="submit-button-wrapper">
              <Can
                task={"manage-groups"}
                taskContainer="groups"
                edit={() => (
                  <button className="edit-group" onClick={onEdit}>
                    Edit
                  </button>
                )}
              />
              <Can
                task={"manage-groups"}
                taskContainer="groups"
                edit={() => (
                  <button className="delete-group" onClick={handleGroupDelete}>
                    Delete
                  </button>
                )}
              />
              {showDeletePopup && (
                <Fade show={true} duration="300ms">
                  <div id="delete-group-overlay">
                    <GroupDeletePopup
                      close={() => setShowDeletePopup(false)}
                      changeActiveTab={changeActiveTab}
                    />
                  </div>
                </Fade>
              )}
            </div>
          )}
        </div>
        <div className="about-content">
          <h3 className="title">About</h3>
          <div className="description">{group.bio}</div>
        </div>
        <div className="members">
          <div className="title">
            <h3>Members</h3>
            <p>{group.members ? group.members.length : 0}</p>
          </div>
          <div className="users">
            {selectedMembers
              ? selectedMembers.map((m, i) => (
                  <GroupDetailsUser
                    key={i}
                    member={m}
                    onGroupRefresh={onGroupRefresh}
                    onOptionClick={() => onUserOptionClick(i)}
                    popupActive={userPopupIndex === i}
                    group={group}
                    changeActiveTab={changeActiveTab}
                  />
                ))
              : undefined}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { loggedUserDetails } = state.Global;
  const { selectedMembers, onClickEmitter } = state.Groups;
  return {
    loggedUser: loggedUserDetails.userID,
    selectedMembers,
    onClickEmitter,
  };
};
export default connect(mapStateToProps)(GroupDetails);
