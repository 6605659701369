import * as fn from "../src/utils/utils";
import * as User from "../src/utils/userSystemUtils";
import { Notify } from "../src/components";
import axios from "../src/utils/axiosInstance";
import d from "../src/utils/dom";

const jwt = require("jsonwebtoken");

let profileImage;

new d(".nh_logo").on("click", () => (location.href = "/"));

// View Password for signup
new d(".s_p_s").on("click", () =>
  fn.viewPassword({
    input: "#s_password",
    icon: ".s_p_s",
  })
);

// View Password for login
new d(".s_p_l").on("click", () =>
  fn.viewPassword({
    input: "#l_password",
    icon: ".s_p_l",
  })
);

// // Filtering illegal characters
fn.replacer(".s_username", "normal");
fn.replacer(".s_firstname", "normal");
fn.replacer(".s_middlename", "normal");
fn.replacer(".s_surname", "normal");

// Username checker
// User.username_checker(".s_username");

// User Signup
new d("form.form_register").on("submit", (e) => {
  e.preventDefault();
  let text = new d("#password-validate-text-msg");
  let firstNameValidateText = new d("#firstname-validate-text");

  let lastNameValidateText = new d("#lastname-validate-text");
  let usernameValidateText = new d("#username-validate-text");
  let genderValidateText = new d("#gender-validate-text");
  let dobValidateText = new d("#dob-validate-text");
  let mobilePhoneValidateText = new d("#mobilephone-validate-text");
  let licenseNumberValidateText = new d("#licensenumber-validate-text");
  let availabilityValidateText = new d("#availability-validate-text");
  new d(".s_submit").setAttr("disabled", true);
  let firstName = new d("#s_firstname").val(),
    middleName = new d("#s_middlename").val(),
    employeeId = new d("#s_employeeid").val(),
    lastName = new d("#s_lastname").val(),
    username = new d("#s_username").val(),
    gender = new d("#s_gender").val(),
    dob = new d("#s_dob").val(),
    homePhone = new d("#s_homephone").val(),
    mobilePhone = new d("#s_mobilephone").val(),
    nationalIdentityNumber = new d("#s_nidnumber").val(),
    userLicenseNumber = new d("#s_licensenumber").val(),
    designation = new d("#s_designation").val(),
    availability = new d("#s_availability").val(),
    department = new d("#s_department").val(),
    lab = new d("#s_lab").val(),
    location = new d("#s_location").val(),
    userEmail = new d("#s_email").val(),
    dxAccountId = new d("#s_dx_account_id").val(),
    roleId = new d("#s_roleId").val(),
    password = new d("#s_password").val(),
    cpassword = new d("#s_cpassword").val(),
    checkbox = document.getElementById("s_terms").checked;

  if (!firstName) {
    new d(".s_submit").removeAttr("disabled");
    firstNameValidateText.html(
      `<span style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;margin-bottom: 3px;">First Name is missing</span>`
    );
  } else if (!lastName) {
    new d(".s_submit").removeAttr("disabled");
    lastNameValidateText.html(
      `<span style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;margin-bottom: 3px;">Last Name is missing</span>`
    );
  } else if (!username) {
    new d(".s_submit").removeAttr("disabled");
    usernameValidateText.html(
      `<span style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;margin-bottom: 3px;">Username is missing</span>`
    );
  } else if (!gender) {
    new d(".s_submit").removeAttr("disabled");
    genderValidateText.html(
      `<span style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;margin-bottom: 3px;">Gender is missing</span>`
    );
  } else if (!dob) {
    new d(".s_submit").removeAttr("disabled");
    dobValidateText.html(
      `<span style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px; margin-left:0px;margin-bottom: 3px;">Date of Birth is missing</span>`
    );
  } else if (!mobilePhone) {
    new d(".s_submit").removeAttr("disabled");
    mobilePhoneValidateText.html(
      `<span style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px; margin-left:0px;margin-bottom: 3px;">Phone Number is missing</span>`
    );
  } else if (!userLicenseNumber) {
    new d(".s_submit").removeAttr("disabled");
    licenseNumberValidateText.html(
      `<span style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px; margin-left:0px;margin-bottom: 3px;">License Number is missing</span>`
    );
  } else if (!availability) {
    new d(".s_submit").removeAttr("disabled");
    availabilityValidateText.html(
      `<span style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;margin-bottom: 3px;">Availability is missing</span>`
    );
  } else if (password !== cpassword) {
    new d(".s_submit").removeAttr("disabled");
    text.html(
      `<p style="color:red; font-size: 12px; margin: 0px; margin-top: 5px; padding: 0px;margin-bottom: 3px;">Password and Confirm Password are different.</p>`
    );
  } else if (checkbox === false) {
    new d(".s_submit").removeAttr("disabled");
    text.html(
      `<p style="color:red; font-size: 12px;">Please accept the terms of service before proceeding to signup.</p>`
    );
  } else {
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.length >= 6
    ) {
      const sessData = {
        firstName,
        middleName,
        lastName,
        username,
        userEmail,
        employeeId,
        gender,
        dob,
        homePhone,
        mobilePhone,
        nationalIdentityNumber,
        userLicenseNumber,
        designation,
        dxAccountId,
        availability,
        department,
        lab,
        location,
      };
      let signupOpt = {
        data: {
          firstName,
          middleName,
          lastName,
          username,
          gender,
          dob,
          homePhone,
          mobilePhone,
          nationalIdentityNumber,
          userLicenseNumber,
          designation,
          dxAccountId,
          availability,
          department,
          lab,
          location,
          userEmail,
          employeeId,

          roleId,
          password,
        },
        when: "signup",
        btn: ".s_submit",
        url: "/api/auth/signUp",
        redirect: "/verifySignUp",
        defBtnValue: "Sign Up",
      };
      sessionStorage.setItem("socialLoginData", JSON.stringify(sessData));
      User.commonLogin(signupOpt);
    } else {
      new d("#s_password").addClass("s_password_error");
    }
  }
});

new d("form.form_verify").on("submit", (e) => {
  e.preventDefault();
  let text = new d("#password-validate-text");
  new d(".s_submit").setAttr("disabled", true);
  let digit1 = new d("#digit-1").val(),
    digit2 = new d("#digit-2").val(),
    digit3 = new d("#digit-3").val(),
    digit4 = new d("#digit-4").val(),
    digit5 = new d("#digit-5").val(),
    digit6 = new d("#digit-6").val();

  if (!digit1 && !digit2 && !digit3 && !digit4 && !digit5 && !digit6) {
    new d(".s_submit").removeAttr("disabled");
    text.html(`<p style="color:red; font-size: 12px;">Values are missing.</p>`);
  } else {
    let verifyOpt = {
      data: {
        verifyCode: `${digit1}${digit2}${digit3}${digit4}${digit5}${digit6}`,
      },
      when: "verifySignup",
      btn: ".s_verify_submit",
      url: "/api/auth/verifySignup",
      redirect: "/",
      defBtnValue: "Verify",
    };
    User.commonLogin(verifyOpt);
  }
});

////// Resend verification token

new d(".resend_otp_btn").on("click", (e) => {
  e.preventDefault();
  let obj = sessionStorage.getItem("socialLoginData");
  let sessData = JSON.parse(obj);
  const firstName = sessData.firstName;
  const middleName = sessData.middleName;
  const lastName = sessData.lastName;
  const gender = sessData.gender;
  const dob = sessData.dob;
  const homePhone = sessData.homePhone;
  const mobilePhone = sessData.mobilePhone;
  const nationalIdentityNumber = sessData.nationalIdentityNumber;
  const userLicenseNumber = sessData.userLicenseNumber;
  const designation = sessData.designation;
  const dxAccountId = sessData.dxAccountId;
  const availability = sessData.availability;
  const department = sessData.department;
  const lab = sessData.lab;
  const location = sessData.location;
  const userEmail = sessData.userEmail;
  const employeeId = sessData.employeeId;

  if (
    firstName &&
    lastName &&
    userEmail &&
    gender &&
    dob &&
    mobilePhone &&
    userLicenseNumber &&
    availability
  ) {
    const sessData_2 = {
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      homePhone,
      mobilePhone,
      nationalIdentityNumber,
      userLicenseNumber,
      designation,
      dxAccountId,
      availability,
      department,
      lab,
      location,
      userEmail,
      employeeId,
    };
    let resendOtpOption = {
      data: {
        firstName,
        middleName,
        lastName,
        gender,
        dob,
        homePhone,
        mobilePhone,
        nationalIdentityNumber,
        userLicenseNumber,
        designation,
        dxAccountId,
        availability,
        department,
        lab,
        location,
        userEmail,
        employeeId,
      },
      when: "resendOtp",
      btn: ".resend_otp_btn",
      url: "/api/auth/resendOtp",
      redirect: "/verifySignUp",
      defBtnValue: "Resend OTP",
    };
    sessionStorage.setItem("socialLoginData_2", JSON.stringify(sessData_2));
    User.commonLogin(resendOtpOption);
  }
});

/////// end of resend OTP

document.getElementById("s_firstname") &&
  document.getElementById("s_firstname").addEventListener("keyup", function() {
    let firstNameText = new d("#firstname-validate-text");
    const firstNameField = new d("#s_firstname").val();
    if (firstNameField.length > 0) {
      firstNameText.html("");
    } else {
      firstNameText.html(
        `<p style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;margin-bottom: 5px;">First Name is missing</p>`
      );
    }
  });

document.getElementById("s_lastname") &&
  document.getElementById("s_lastname").addEventListener("keyup", function() {
    let lastNameText = new d("#lastname-validate-text");
    const firstNameField = new d("#s_lastname").val();
    if (firstNameField.length > 0) {
      lastNameText.html("");
    } else {
      lastNameText.html(
        `<p style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;margin-bottom: 5px;">Last Name is missing</p>`
      );
    }
  });

document.getElementById("s_username") &&
  document.getElementById("s_username").addEventListener("keyup", function() {
    let usernameText = new d("#username-validate-text");
    const firstNameField = new d("#s_username").val();
    if (firstNameField.length > 0) {
      usernameText.html("");
    } else {
      usernameText.html(
        `<p style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;margin-bottom: 5px;">Username is missing</p>`
      );
    }
  });

document.getElementById("s_gender") &&
  document.getElementById("s_gender").addEventListener("focusout", function() {
    let genderText = new d("#gender-validate-text");
    const genderField = new d("#s_gender").val();
    if (genderField === "male") {
      genderText.html("");
    } else if (genderField === "female") {
      genderText.html("");
    } else if (genderField === "other") {
      genderText.html("");
    } else {
      genderText.html(
        `<p style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;">Gender is missing</p>`
      );
    }
  });

document.getElementById("s_dob") &&
  document.getElementById("s_dob").addEventListener("focusout", function() {
    let dobText = new d("#dob-validate-text");
    const dobField = new d("#s_dob").val();
    if (dobField.length > 0) {
      dobText.html("");
    } else {
      dobText.html(
        `<p style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px; margin-left: 0px">Date of Birth is missing</p>`
      );
    }
  });

document.getElementById("s_mobilephone") &&
  document
    .getElementById("s_mobilephone")
    .addEventListener("focusout", function() {
      let mobilePhoneText = new d("#mobilephone-validate-text");
      const mobilePhoneField = new d("#s_mobilephone").val();
      if (
        mobilePhoneField.length === 10 &&
        mobilePhoneField.match(/^[0-9]+$/)
      ) {
        mobilePhoneText.html("");
      } else {
        mobilePhoneText.html(
          `<p style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;">Enter valid Number</p>`
        );
      }
    });

document.getElementById("s_licensenumber") &&
  document
    .getElementById("s_licensenumber")
    .addEventListener("focusout", function() {
      let licenseNumberText = new d("#licensenumber-validate-text");
      const licenseNumberField = new d("#s_licensenumber").val();
      if (licenseNumberField.length > 0) {
        licenseNumberText.html("");
      } else {
        licenseNumberText.html(
          `<p style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px; margin-left: 20px">License Number is missing</p>`
        );
      }
    });

document.getElementById("s_availability") &&
  document
    .getElementById("s_availability")
    .addEventListener("focusout", function() {
      let availabilityText = new d("#availability-validate-text");
      const availabilityField = new d("#s_availability").val();
      if (availabilityField !== "") {
        availabilityText.html("");
      } else {
        availabilityText.html(
          `<p style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;">Availability is missing</p>`
        );
      }
    });

document.getElementById("s_email") &&
  document.getElementById("s_email").addEventListener("keyup", function() {
    let userEmailText = new d("#email-validate-text");
    const firstNameField = new d("#s_email").val();
    if (firstNameField.length > 0) {
      userEmailText.html("");
    } else {
      userEmailText.html(
        `<p style="color:red; font-size: 12px; margin: 0px; padding: 0px;margin-top: 5px;">User email is missing</p>`
      );
    }
  });

document.getElementById("s_password");
document.getElementById("s_password") &&
  document.getElementById("s_password").addEventListener("keyup", function() {
    const password = new d("#s_password").val();
    const text = new d("#password-strength-text");
    const passwordField = new d("#password-group");
    const cPasswordField = new d("#cpassword-group");
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.length >= 6
    ) {
      text.html("");
      passwordField.removeClass("error");
      cPasswordField.removeClass("error");
    } else {
      let errorMsg = "";
      if (!password.match(/[A-Z]/g) || !password.match(/[0-9]/g)) {
        if (!password.match(/[A-Z]/g) && password.match(/[0-9]/g)) {
          errorMsg = "Password must contain at least one uppercase.";
        }

        if (!password.match(/[0-9]/g) && password.match(/[A-Z]/g)) {
          errorMsg = "Password must contain at least one number.";
        }

        if (!password.match(/[A-Z]/g) && !password.match(/[0-9]/g)) {
          errorMsg =
            "Password must contain at least one uppercase and one number.";
        }
      } else {
        if (!password.match(/[a-z]/g)) {
          errorMsg = "Password must contain lowercase.";
        }
        if (password.length < 6) {
          errorMsg = "Password must be at least 6 characters long.";
        }
      }
      passwordField.addClass("error");
      cPasswordField.addClass("error");
      text.html(
        `<p style="color:red; font-size: 12px; margin-bottom: 0px;">${errorMsg}</p>`
      );
    }
  });

// User login
new d(".l_submit").on("click", (e) => {
  e.preventDefault();
  login();
});

const verifyToken = (token) => {
  const secret = "secret123kjkdfkafd";
  try {
    return jwt.verify(token, secret);
  } catch (error) {
    return null;
  }
};

window.onload = (event) => {
  const token = localStorage.getItem("jsonToken");
  if (token) {
    const decoded = verifyToken(token);
    document.getElementById("dxaccountid").value = decoded.dxAccountId;
    document.getElementById("username").value = decoded.username;
    document.getElementById("password").value = decoded.password;
  }

  if (JSON.parse(localStorage.getItem("_expired"))) {
    document.getElementById("expired").innerHTML +=
      "<p>Your session has expired. Please login to continue working.</p>";
    localStorage.removeItem("_expired");
  }
};

new d(".l_password").on("keydown", (e) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    login();
  }
});

new d(".al_password").on("keydown", (event) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    adminLogin();
  }
});

// CLOSE QUICK MODAL
new d(".q_l_m_cancel").on("click", () => {
  new d(".overlay-2-black").hide();
  new d("#q_l_password").setValue("");
  new d(".q_l_model").hide();
});

// CLEAR ALL QUICK LOGINS
new d(".clear_all_ql").on("click", async (e) => {
  e.preventDefault();
  await axios.post("/api/clear-all-quick-logins");
  Notify({
    value: "Cleared all quick logins!!",
    done: () => location.reload(),
  });
});

// FORGOT PASSWORD
new d("form.form_fp").on("submit", async (e) => {
  e.preventDefault();
  let email = new d(".fp_email").val();
  if (!email) {
    Notify({ value: "Email field is empty!!" });
  } else {
    let fpOpt = {
      data: { userEmail: email },
      when: "forgot_password",
      btn: ".fp_submit",
      url: "/api/auth/password-retrieve",
      redirect: "/forgot-password",
      defBtnValue: "Retrieve",
    };
    User.commonLogin(fpOpt);
  }
});

new d("form.form_fp_reset").on("submit", async (e) => {
  e.preventDefault();
  const password = new d("#s_password").val();
  const cpassword = new d("#s_cpassword").val();
  const token = window.location.search.split("=")[1];

  if (password !== cpassword) {
    Notify({ value: "The password and confirmation password do not match." });
  } else {
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.length >= 6
    ) {
      let fpOpt = {
        data: { password, cpassword, token },
        when: "reset_password",
        btn: ".fp_submit",
        url: "/api/auth/reset-password",
        redirect: "/login",
        defBtnValue: "Reset",
      };
      User.commonLogin(fpOpt);
    } else {
      new d("#s_password").addClass("s_password_error");
    }
  }
});

new d(".outside_post").on("load", (e) => {
  e.preventDefault();
  $("#outsidePostSignupshow").modal("show");
});

new d(".s_register_next").on("click", (e) => {
  e.preventDefault();
  clearSessions();
  window.location.href = "/";
});

new d("#profile_file_input").on("change", function(e) {
  e.preventDefault();
  new d(".image-preview-area").addClass("hide");
  new d(".image-upload-area").removeClass("hide");
  readFile(e);
});

new d("#s_p_image_upload").on("click", function(e) {
  e.preventDefault();
  if (profileImage) {
    new d("#s_p_image_upload").setAttr("disabled", true);
    profileImage.result("blob").then(function(blobData) {
      const options = {
        file: blobData,
        user_id: sessionStorage.getItem("signupId"),
      };
      upload_avatar(options);
    });
  } else {
    Notify({ value: "Please select profile image" });
  }
});

new d("#s_p_image_select").on("click", function(e) {
  e.preventDefault();
  document.getElementById("profile_file_input").click();
});

new d("#s_p_image_change").on("click", function(e) {
  e.preventDefault();
  document.getElementById("profile_file_input").click();
});

const login = () => {
  let dxAccountId = new d(".l_dx_account_id").val();
  let username = new d(".l_username").val();
  let password = new d(".l_password").val();
  let redirectUrl = new d(".login-container").getAttr("data-redirect");
  let isChecked = document.getElementById("l_remember").checked;

  if (!username || !password || !dxAccountId) {
    Notify({ value: "Values are missing!!" });
  } else {
    let loginOpt = {
      data: {
        username,
        password,
        dxAccountId,
        isChecked,
      },
      when: "login",
      btn: ".l_submit",
      url: "/api/auth/signIn",
      redirect: redirectUrl ? redirectUrl : "/",
      defBtnValue: "SIGN IN",
    };
    User.commonLogin(loginOpt);
  }
};

const adminLogin = () => {
  let password = new d(".al_password").val();
  let adminLoginOpt = {
    data: {
      password,
      when: "adminLogin",
    },
    when: "adminLogin",
    btn: "al_submit",
    url: "/api/siteAdmin/siteAdminLogin",
    redirect: "/site-administrator-dashboard",
  };
  User.commonLogin(adminLoginOpt);
};

const clearSessions = () => {
  sessionStorage.removeItem("setProfileImage");
  sessionStorage.removeItem("socialLoginData");
  sessionStorage.removeItem("signupId");
};

// OTP functionality

// OTP functionality

const divNode = document.getElementById("otp-container");
if (divNode) {
  const inputNodes = divNode.getElementsByTagName("input");

  function getCodeBoxElement(index) {
    return document.getElementById("digit-" + index);
  }

  for (let i = 0; i < inputNodes.length; ++i) {
    let inputNode = inputNodes[i];
    new d(`#${inputNode.id}`).on("focus", function(event) {
      const index = parseInt(event.target.id.split("-")[1]);
      for (let item = 1; item < index; item++) {
        const currentElement = getCodeBoxElement(item);
        if (!currentElement.value) {
          currentElement.focus();
        }
      }
    });

    new d(`#${inputNode.id}`).on("keyup", function(event) {
      const eventCode = event.which || event.keyCode;
      const index = parseInt(event.target.id.split("-")[1]);
      if (getCodeBoxElement(index).value.length === 1) {
        if (index !== 6) {
          getCodeBoxElement(index + 1).focus();
        } else {
          getCodeBoxElement(index).blur();
          new d(".s_verify_submit").removeAttr("disabled");
        }
      }
      if (eventCode === 8 && index !== 1) {
        getCodeBoxElement(index).focus();
      }
    });
  }
}

new d(".al_submit").on("click", (e) => {
  e.preventDefault();
  adminLogin();
});
