import React, { useEffect, useState } from 'react';
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from '../../../components';
import Select from 'react-select';
import { TextField } from '@material-ui/core';
import { FadeIn } from 'animate-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIconSuccess from '../icons/clipboard-approve.png';
import CopyIcon from '../icons/board-copy.svg';
import { Notify } from '../../../components';
import LiveSycnIcon from '../../../assets/icons/live-sycn.png';

const options = [
  { value: '1', label: '1h' },
  { value: '2', label: '2h' },
  { value: '3', label: '3h' },
  { value: '24', label: '24h' },
];

const LiveSyncUrlPopup = ({ generateLiveSyncUrl, onClose, liveSyncUrl }) => {
  const [validPeriod, setValidPeriod] = useState(1);
  const [copytoClipboard, setCopytoClipboard] = useState(false);
  const handleSelectTimeChange = ({ value }) => {
    setValidPeriod(parseInt(value));
  };

  const generateSyncUrl = () => {
    generateLiveSyncUrl(validPeriod);
  };

  const setCopytoClipboardValue = (url) => {
    Notify({ value: 'Copied to Clipboard!' });
    window.location.href = url;
    onClose();
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt annotation-upload-popup">
        <FadeIn duration="300ms">
          <ModalHeader
            title={'Generate LiveSync URL'}
            onClick={onClose}
            img={LiveSycnIcon}
            alt={'live-sync'}
          />
          <div className="modal_middle">
            <div className="modal_content" style={{ marginTop: '15px' }}>
              <div className="live-share-popup-continer">
                <span style={{ marginBottom: '10px' }}>Link validity</span>
                <Select
                  options={options}
                  classname="stain-select"
                  placeholder="Duration"
                  style={{ width: '100%', marginTop: 10 }}
                  onChange={handleSelectTimeChange}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={options[0]}
                  isSearchable={false}
                />
                {liveSyncUrl && (
                  <React.Fragment>
                    <TextField
                      InputProps={{
                        style: { fontSize: '16px', width: '92%' },
                      }}
                      value={`${location.origin}${liveSyncUrl}`}
                      style={{
                        width: '90%',
                        padding: '0 7px 0 0',
                        marginTop: '20px',
                        display: 'inline-block',
                      }}
                      disabled={true}
                    />
                    <CopyToClipboard
                      text={`${location.origin}${liveSyncUrl}`}
                      onCopy={() => setCopytoClipboard(!copytoClipboard)}
                    >
                      <div className="code-box" title="Copy to clipboard">
                        <img
                          className={copytoClipboard ? 'copy-success' : ''}
                          src={copytoClipboard ? CopyIconSuccess : CopyIcon}
                        />
                      </div>
                    </CopyToClipboard>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div
            className="prompt-bottom"
            style={{
              marginTop: 0,
              padding: '0 0 10px 0',
            }}
          >
            <SecondaryButton label={'Cancel'} onClick={onClose} />
            {liveSyncUrl && (
              <CopyToClipboard
                text={`${location.origin}${liveSyncUrl}`}
                onCopy={() => setCopytoClipboardValue(liveSyncUrl)}
              >
                <PrimaryButton
                  extraClass="annotation-btn large live-share"
                  label={'Copy Link and Join'}
                />
              </CopyToClipboard>
            )}

            {!liveSyncUrl && (
              <PrimaryButton
                extraClass="annotation-btn"
                label={'Generate'}
                onClick={generateSyncUrl}
              />
            )}
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

export default LiveSyncUrlPopup;
