import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { keysObject, valuesObject } from "../../../../utils/utils";
import ImageTag from "../../../slidebox/components/SlideItems/ImageTag";
import More from "../../../../assets/icons/more.svg";
import { StyledMenu } from "../DashboardItems/DashboardFilter";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const DashboardTableMoreOption = ({
  index,
  selectedRowIndex,
  setSelectedRowIndex,
  setActivateOrDeactivateUserData,
  setChangeRoleType,
  userList,
  setActivateOrDeactivateUser,
  setUserRole,
  resendInvitation,
  setDeleteUserInvitation,
  loggedUserDetails,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setSelectedRowIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e, clearSelectedRowIndex) => {
    clearSelectedRowIndex === "backdropClick" && setSelectedRowIndex("");
    setAnchorEl(null);
  };

  let expired = false;
  if (userList.invitationExpiryAt) {
    expired = new Date() >= new Date(userList.invitationExpiryAt);
  }

  const ButtonAttributes = {
    ...(userList.status === "active" && loggedUserDetails && loggedUserDetails.roleName === "System Administrator" ? {
          Change_account_type: {
            onClick: () => {
              setUserRole(userList);
              setChangeRoleType(true);
              handleClose(true);
            },
          },
        }
      : []),
    ...(userList.status !== "invited" && loggedUserDetails.roleName === "System Administrator"
      ? {
          [`${
            userList.status === "active"
              ? "Deactivate_account"
              : "Activate_account"
          }`]: {
            onClick: () => {
              setActivateOrDeactivateUserData(userList);
              setActivateOrDeactivateUser(true);
              handleClose(true);
            },
          },
        }
      : []),
    ...(userList.status === "invited" && expired
      ? {
          Resend_invitation: {
            onClick: () => {
              resendInvitation(userList);
              handleClose(true);
              setSelectedRowIndex("");
            },
          },
        }
      : []),
    ...(userList.status === "invited"
      ? {
          Revoke_invitation: {
            onClick: () => {
              setDeleteUserInvitation(userList);
              handleClose(true);
            },
          },
        }
      : []),
  };

  return valuesObject(ButtonAttributes).length !== 0 && (
    <div>
      <ImageTag
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={`user-details-more-icon 
                ${selectedRowIndex === index ? "active" : ""}`}
        src={More}
      />
      {Boolean(anchorEl) && (
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ pointerEvents: 'none' }}
        >
              {valuesObject(ButtonAttributes).map(({ ...all }, index) => (
                <ClickAwayListener onClickAway={() => {
                  if(Boolean(anchorEl)) {
                    setSelectedRowIndex("");
                    setAnchorEl(null);
                  }
                }}>
                  <StyledMenuItem style={{ pointerEvents: 'auto' }} className="dropdown-li" {...all} key={index}>
                    <ListItemText
                      primary={keysObject(ButtonAttributes)[index].replace(/_/g, " ")}
                    />
                  </StyledMenuItem>
                </ClickAwayListener>
              ))}
        </StyledMenu>
      )}
    </div>
  );
};

export default DashboardTableMoreOption;
