import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLogoutApi } from "../../services/global-service";
import { FadeIn } from 'animate-components';
import AlarmIcon from '../../assets/icons/alarm-clock.svg'
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from '../../components';

const SessionTimeout = ({ logout }) => {
  const timeout = 30;
  const [showSessionModal, setShowSessionModal] = useState(false)
  const [sessionCountdown, setSessionCountdown] = useState(5 * 60000)


  const startInterval = () => {
    updateExpiredTime();

    const interval = setInterval(() => {
      const expire = parseInt(localStorage.getItem("_expiredTime"))
      if(expire <= (Date.now() + 5 * 60000)){
        setShowSessionModal(true)
      }
      if (expire < Date.now() || !window.navigator.onLine) {
        logOut();
      }
    }, 1000);
  };

  const startCountdown = () => {
      let interval = setInterval(() => {
        setSessionCountdown(s => s - 1000)
      }, 1000);

  }

  const logOut = async (e) => {
    try {
      const response = await useLogoutApi();
      if (response.status === 200) {
        localStorage.setItem("_expired", true);
        localStorage.setItem("redirect_url", window.location.href);
        window.location.href = "/logout";
        logout;
      }
    } catch (error) {
      console.log("Unable to logout");
    }
  };

  const updateExpiredTime = () => {
    localStorage.setItem("_expiredTime", Date.now() + timeout * 60000);
  };

  const tracker = () => {
    window.addEventListener("mousemove", updateExpiredTime);
    window.addEventListener("scroll", updateExpiredTime);
    window.addEventListener("keydown", updateExpiredTime);
  };

  const milliToMins = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return (
      seconds == 60 ?
      (minutes+1) + ":00" :
      minutes + ":" + (seconds < 10 ? "0" : "") + seconds
    );
  }

  useEffect(() => {
    tracker();
    startInterval();
    startCountdown();
    localStorage.removeItem('redirect_url')
  }, []);

  useEffect(()=> {
    if(sessionCountdown <= 0){
      logOut();
    }
    if(!showSessionModal && sessionCountdown < 5 * 60000){
      setSessionCountdown(5 * 60000)
    }
  })

  return (
    <React.Fragment>
      {showSessionModal && (
        <React.Fragment>
        <Overlay />
        <div className="prompt session-prompt">
          <FadeIn duration="300ms">
            <ModalHeader
              img={AlarmIcon}
              alt='#'
              title='Session Timeout'
              onClick={() => setShowSessionModal(false)}
              id="modalHeader"
            />
            <div className="modal_middle">
              <p className="expire">Your session will expire in <span className="expire-time">{milliToMins(sessionCountdown)}</span> minutes</p>
              <p className="expire">Click on "Keep Working" if you want to continue and stay logged in.</p>
            </div>
            <div className="prompt-bottom">
              <SecondaryButton label="Log Off" onClick={logOut}/>
              <PrimaryButton
                label="Keep Working"
                onClick={()=> setShowSessionModal(false)}
              />
            </div>
          </FadeIn>
          </div>
      </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch({ type: "USER_LOGOUT" }),
});

export default connect(mapDispatchToProps)(SessionTimeout);
