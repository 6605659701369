import React, { useState, useEffect, useContext } from 'react';
import RectangularAnno from '../icons/anno-ractangle-icon.svg';
import CircleAnno from '../icons/annotation-circle-icon.svg';
import PolygonAnno from '../icons/brush-anno.svg';
import HideAnno from '../icons/hide-annotation-icon.svg';
import ShowAnno from '../icons/show-annotation.svg';
import EditAnno from '../icons/edit-anno.svg';
import ColorChangeIcon from '../icons/paint-plate-new.svg';
import { Slider } from 'material-ui-slider';
import ToolTip from 'react-tooltip';
import { Prompt } from '../../../components';
import { SocketContext } from '../../../utils/serviceContext';

import openseadragonAnnotations from '../utils/openseadragon-annotations';

import AnnotationEditPopup from './annotation-edit-popup';
import AnnotationDetailsPopup from './annotation-details-popup';
import AnnotationColorPicker from './annotation-color-picker';
//import {hideColorPicker} from "./annotation-details-popup";

import {
  setAnnotations,
  getAnnotations,
  updateAnnotations,
  toggleAnnotations,
  setSocketDataUpdateFlase,
  getAnnotationDescription,
  getAnnotationsForHighlightAreasSection,
  getAnnotationsForRunAnalysisSection,
} from '../utils/viewer-utils';
import { connect } from 'react-redux';
import { getRandomColor } from '../../../utils/utils';

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const ViewerToolbarAnnotation = ({
  viewer,
  overlay,
  // zoomValueBar,
  slideId,
  dispatch,
  allAnnotationData,
  toogleAnnotation,
  when,
  featureId,
  userDetails,
  postIsMine,
  fromCaseStream,
  slideOwnerOrCollaborator,
  postIsValidate,
  isSoketDataUpdate,
  isAnnotationUpload,
  deleteAnnotionPopup,
  selectedAnnotationId,
  annotationPopupShow,
  resetAnnotationUpload,
  setAnnotationCoordinates,
  highlightAreas,
  runAnalysis,
}) => {
  const socket = useContext(SocketContext);
  const [forceReloadAnnotationData, setForceReloadAnnotationData] = useState(
    false
  );
  const [pageMounted, setPageMounted] = useState(false);
  const [showAnnotations, setShowAnnotations] = useState(true);
  const [addAnnotations, setAddAnnotations] = useState(false);
  const [editAnnotations, setEditAnnotations] = useState(false);
  const [currentAnnotationType, setCurrentAnnotationType] = useState(null);
  const [currentAnnotations, setCurrentAnnotations] = useState(null);
  const [currentOrgAnnotations, setCurrentOrgAnnotations] = useState(null);
  const [currentAnnotationName, setCurrentAnnotationName] = useState(null);
  const [BrushWidth, setBrushWidth] = useState(60);
  const [showAnnotationDataPopup, setShowAnnotationDataPopup] = useState(false);
  const [showAnnotationEditPopup, setShowAnnotationEditPopup] = useState(false);
  const [globleColorChangeActive, setGlobleColorChangeActive] = useState(false);
  const [showBrushSizeTool, setshowBrushSizeTool] = useState(false);
  const [globleAnnotationColor, setGlobleAnnotationColor] = useState('#FF0000');
  const [otherUserAnnotations, setOtherUserAnnotations] = useState(null);
  const [currentUserAnnotations, setCurrentUserAnnotations] = useState(null);
  const [editAnnotationData, setEditAnnotationData] = useState(null);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  useEffect(() => {
    socket.onUpdateBoardAnnotation().subscribe((data) => {
      if (
        when === 'groups' &&
        featureId === parseInt(data.tumorBoardId) &&
        userDetails.userID !== data.ownerId
      ) {
        setForceReloadAnnotationData(true);
        getAnnotations({ dispatch, slideId, when, featureId });
      }
    });
  }, []);

  useEffect(() => {
    socket.getAnnotationMessageSubject().subscribe((item) => {
      if (item && overlay && item.fromSrc === 'collapsible-sidebar') {
        // get active object
        let activeObj = overlay
          .fabricCanvas()
          .getObjects()
          .find(
            (i) =>
              i.top === item.top &&
              i.left === item.left &&
              i.width === item.width
          );
        activeObj.set({ text: item.text, pk: item.pk });
        handleEditAnnotation(null, activeObj);
      }
    });
  }, [overlay, currentUserAnnotations]);

  useEffect(() => {
    if (typeof viewer !== 'undefined' && overlay) {
      setPageMounted(true);

      if (when !== 'analytics') {
        getAnnotations({ dispatch, slideId, when, featureId });
        getAnnotationDescription({ slideId, dispatch });
      }
      if (highlightAreas) {
        getAnnotationsForHighlightAreasSection({
          dispatch,
          slideId,
          when,
          featureId,
        });
      }

      if (runAnalysis) {
        getAnnotationsForRunAnalysisSection({
          dispatch,
          slideId,
          when,
          featureId,
        });
      }

      openseadragonAnnotations.initialize(overlay, viewer);
      if (when === 'quorum' && !postIsMine) {
        const globleColorInQuorum = getRandomColor();
        setGlobleAnnotationColor(globleColorInQuorum);
      }
    }
  }, [overlay, runAnalysis]);

  useEffect(() => {
    if (runAnalysis) {
      getAnnotationsForRunAnalysisSection({
        dispatch,
        slideId,
        when,
        featureId,
      });
    }
  }, [runAnalysis]);

  useEffect(() => {
    if (
      typeof viewer !== 'undefined' &&
      overlay &&
      (addAnnotations ||
        pageMounted ||
        isSoketDataUpdate ||
        isAnnotationUpload ||
        runAnalysis)
    ) {
      handleAnnotation('rectangle');
      setUserAnnoatationData(allAnnotationData);
      openseadragonAnnotations.appendAllAnnotations(
        pageMounted
          ? allAnnotationData
          : allAnnotationData.length > 0
            ? [allAnnotationData[allAnnotationData.length - 1]]
            : [],
        when,
        addAnnotations
      );
      setPageMounted(false);
      setAddAnnotations(false);
      setSocketDataUpdateFlase({ dispatch });
      if (isAnnotationUpload) {
        resetAnnotationUpload();
      }
    } else if (
      typeof viewer !== 'undefined' &&
      overlay &&
      forceReloadAnnotationData
    ) {
      setUserAnnoatationData(allAnnotationData);
      openseadragonAnnotations.refresAnnotationData(allAnnotationData);
      setForceReloadAnnotationData(false);
    }
  }, [allAnnotationData, runAnalysis]);

  useEffect(() => {
    if (
      toogleAnnotation &&
      !editAnnotations &&
      typeof viewer !== 'undefined' &&
      overlay
    ) {
      const { annotationIds, onOrOff } = toogleAnnotation;
      if (annotationIds) {
        openseadragonAnnotations.turnAnnotationsOnOffByUser(
          onOrOff,
          annotationIds
        );
      }
    }
  }, [toogleAnnotation]);

  useEffect(() => {
    if (!selectedAnnotationId && !annotationPopupShow && overlay) {
      hideDetailsPopup();
    }
  }, [selectedAnnotationId, annotationPopupShow]);

  const sentChangeAnnotationEvent = (item) => {
    socket
      .getAnnotationMessageSubject()
      .next({ ...item, fromSrc: 'toolbar-annotation' });
  };

  const switchCursor = (isDrawing = false) => {
    if (isDrawing) {
      overlay.fabricCanvas().moveCursor = 'crosshair';
      overlay.fabricCanvas().hoverCursor = 'crosshair';
    } else {
      overlay.fabricCanvas().moveCursor = 'move';
      overlay.fabricCanvas().hoverCursor = 'move';
    }
  };

  const handleAnnotation = (annotationType) => {
    // e.preventDefault();
    if (currentAnnotationType !== annotationType) {
      setGlobleColorChangeActive(false);
      if (when === 'quorum' && !postIsMine) {
        openseadragonAnnotations.changeAllAnnotationStrokeColor(
          globleAnnotationColor
        );
      }
      openseadragonAnnotations.setMouseMode('addAnnotation');
      switchCursor(true);
      openseadragonAnnotations.annotationType = annotationType;
      // var strokeWidth = 80 / zoomValueBar;
      // openseadragonAnnotations.strokeWidthForDrawing = strokeWidth;
      if (annotationType == 'brush') {
        resetBrushSize();
        toggleshowBrushSizeTool();
        if (!overlay.fabricCanvas().isDrawingMode) {
          overlay.fabricCanvas().isDrawingMode = true;
          overlay.fabricCanvas().freeDrawingBrush.width = BrushWidth;
          overlay.fabricCanvas().freeDrawingBrush.color = convertHexToRGBA(
            globleAnnotationColor,
            50
          );
        }
      }
      setCurrentAnnotationType(annotationType);
    } else {
      setCurrentAnnotationType(null);
      switchCursor(false);
      openseadragonAnnotations.setMouseMode('OSD');
      if (annotationType == 'brush') {
        overlay.fabricCanvas().isDrawingMode = false;
      }
      if (annotationType == 'brush') {
        toggleshowBrushSizeTool();
      }
    }
  };

  const handleEditAnnotation = (e, activeAnnotation) => {
    if (e) {
      e.preventDefault();
    }
    setCurrentAnnotationType(null);
    if (!editAnnotations) {
      hideOtherUserAnnotations();
      overlay
        .fabricCanvas()
        .getObjects()
        .some((obj) => {
          obj.set({
            transparentCorners: false,
            cornerColor: 'blue',
            cornerStrokeColor: 'red',
            borderColor: 'red',
            cornerSize: 12,
            padding: 10,
            cornerStyle: 'circle',
            borderDashArray: [3, 3],
          });
        });
      openseadragonAnnotations.setMouseMode('editAnnotation');
      // set active annotation selected from sidebar
      if (activeAnnotation) {
        overlay.fabricCanvas().setActiveObject(activeAnnotation);
        onSelectAnnotationToEdit(activeAnnotation);
      }
    } else {
      if (when !== 'quorum' && showAnnotations) {
        openseadragonAnnotations.turnAnnotationsOnOff('on');
      }
      openseadragonAnnotations.setMouseMode('OSD');
    }
    setEditAnnotations(!editAnnotations);
  };

  const toggleAnnotation = (e) => {
    e.preventDefault();
    if (showAnnotations) {
      openseadragonAnnotations.setMouseMode('OSD');
      openseadragonAnnotations.turnAnnotationsOnOff('off');
    } else {
      openseadragonAnnotations.turnAnnotationsOnOff('on');
    }
    setShowAnnotations(!showAnnotations);
  };

  const onClose = () => {
    if (currentAnnotationType) {
      openseadragonAnnotations.deleteCurrentAnnotation();
    } else {
      handleSaveClick();
      handleAnnotation('rectangle');
    }
    setShowAnnotationDataPopup(false);
    setShowAnnotationEditPopup(false);
    setCurrentAnnotations(null);
    setCurrentAnnotationType('rectangle');
    openseadragonAnnotations.setMouseMode('addAnnotation');
    switchCursor(true);
    openseadragonAnnotations.annotationType = 'rectangle';
  };

  const setAnnoatationName = (name) => {
    setCurrentAnnotationName(name);
  };

  const handleSaveClick = () => {
    viewer.addHandler('canvas-click', (event) => {
      handleAnnotation('rectangle');

      // The canvas-click event gives us a position in web coordinates.
      let webPoint = event.position;

      // Convert that to viewport coordinates, the lingua franca of OpenSeadragon coordinates.
      let viewportPoint = viewer.viewport.pointFromPixel(webPoint);

      // Convert from viewport coordinates to image coordinates.
      let imagePoint = viewer.viewport.viewportToImageCoordinates(
        viewportPoint
      );

      // Show the results.
      setAnnotationCoordinates([
        webPoint.x,
        webPoint.y,
        imagePoint.x,
        imagePoint.y,
      ]);
    });

    if (currentAnnotationType) {
      const data = {
        ...currentAnnotations,
        text: currentAnnotationName,
      };
      setAddAnnotations(true);
      setAnnotations({
        annotationData: data,
        slideId,
        featureId,
        dispatch,
        when,
        callback: deleteCurrentDraw,
      });
    } else {
      const canvasObject = overlay.fabricCanvas().getActiveObject();
      canvasObject.set({ text: currentAnnotationName });
      const data = {
        ...currentAnnotations,
        text: currentAnnotationName,
      };
      updateAnnotations({ annotationData: data, slideId, when, dispatch });
    }
    setCurrentAnnotationName(null);
    setShowAnnotationDataPopup(false);
  };

  const deleteCurrentDraw = () => {
    openseadragonAnnotations.deleteCurrentAnnotation();
  };

  const editBtnOnClick = () => {
    setEditAnnotationData(currentOrgAnnotations);
    setShowAnnotationEditPopup(false);
    setShowAnnotationDataPopup(true);
  };

  const deleteBtnOnClick = () => {
    const annotationId = currentAnnotations.pk;
    deleteAnnotionPopup({ status: true, annotationId });
  };

  const resetBrushSize = () => {
    setBrushSizeInAnnotation(60);
  };

  const onColorChange = (color) => {
    const canvasObject = overlay.fabricCanvas().getActiveObject();
    if (canvasObject.type == 'path') {
      var updatedColor = convertHexToRGBA(color['hex'], 50);
    } else {
      var updatedColor = color['hex'];
    }
    canvasObject.set({ stroke: updatedColor });
    const data = {
      ...currentAnnotations,
      stroke: updatedColor,
    };
    updateAnnotations({ annotationData: data, slideId, dispatch });
    overlay.fabricCanvas().renderAll();
    setOpenColorPicker(false);
  };

  const hideDetailsPopup = () => {
    overlay.fabricCanvas().remove(currentOrgAnnotations);
    overlay.fabricCanvas().renderAll();
    setShowAnnotationEditPopup(false);
  };

  const changeGlobleAnnotationColor = () => {
    setGlobleColorChangeActive(!globleColorChangeActive);
  };

  const onGlobleAnnoColorChange = (color) => {
    setGlobleAnnotationColor(color['hex']);
    openseadragonAnnotations.changeAllAnnotationStrokeColor(color['hex']);
    setGlobleColorChangeActive(!globleColorChangeActive);
  };

  const toggleshowBrushSizeTool = () => {
    setshowBrushSizeTool(!showBrushSizeTool);
  };

  const convertHexToRGBA = (hex, opacity) => {
    const tempHex = hex.replace('#', '');
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  };

  const onSelectAnnotationToEdit = (activeObjs) => {
    const convertedObj = openseadragonAnnotations.duplicateObject(activeObjs);
    setCurrentOrgAnnotations(activeObjs);
    setCurrentAnnotationName(convertedObj['text']);
    setCurrentAnnotations(convertedObj);
    setShowAnnotationEditPopup(true);
    sentChangeAnnotationEvent(convertedObj);
  };

  if (overlay) {
    overlay.fabricCanvas().observe('mouse:down', (o) => {
      if (
        openseadragonAnnotations.getMouseMode() === 'addAnnotation' &&
        openseadragonAnnotations.annotationType === 'brush'
      ) {
        toggleshowBrushSizeTool();
      }
    });

    overlay.fabricCanvas().observe('mouse:up', (o) => {
      if (openseadragonAnnotations.getMouseMode() === 'addAnnotation') {
        switchCursor(false);
        setCurrentAnnotationName(null);
        if (openseadragonAnnotations.annotationType === 'brush') {
          overlay.fabricCanvas().isDrawingMode = false;
        }
        const canvasObjects = overlay.fabricCanvas().getObjects();
        if (canvasObjects.length !== 0) {
          const last = canvasObjects[canvasObjects.length - 1];
          const convertedObj = openseadragonAnnotations.duplicateObject(last);
          setCurrentAnnotations(convertedObj);
          if (when !== 'quorum') {
            setEditAnnotationData(last);
            setShowAnnotationDataPopup(true);
          } else {
            const data = {
              ...convertedObj,
              text: featureId ? 'quorum' : 'initial-annotation',
            };
            setAddAnnotations(true);
            setAnnotations({
              annotationData: data,
              slideId,
              featureId,
              dispatch,
              when,
              callback: deleteCurrentDraw,
            });
            setCurrentAnnotationType(null);
          }
        }
      }
      if (openseadragonAnnotations.getMouseMode() === 'editAnnotation') {
        const canvasObjects = overlay.fabricCanvas().getActiveObject();
        if (canvasObjects) {
          if (!showAnnotationEditPopup) {
            onSelectAnnotationToEdit(canvasObjects);
          }
        }
      }
    });

    overlay.fabricCanvas().observe('before:selection:cleared', (o) => {
      if (openseadragonAnnotations.getMouseMode() !== 'OSD') {
        openseadragonAnnotations.setMouseMode('OSD');
        const convertedObj = openseadragonAnnotations.duplicateObject(o.target);
        setEditAnnotations(false);
        setShowAnnotationEditPopup(false);
        setShowAnnotationDataPopup(false);
        updateAnnotations({
          annotationData: convertedObj,
          slideId,
          dispatch,
        });

        if (when !== 'quorum' && showAnnotations) {
          openseadragonAnnotations.turnAnnotationsOnOff('on');
        }
      }
    });
  }

  const setUserAnnoatationData = (allAnnotationData) => {
    if (allAnnotationData) {
      const dataSet = allAnnotationData.reduce((r, a) => {
        r[a.user_id] = [...(r[a.user_id] || []), a];
        return r;
      }, {});

      const modifyAnnotationData = Object.keys(dataSet).map((key) => {
        return {
          user_id: parseInt(key),
          firstname: dataSet[key][0]['firstname'],
          surname: dataSet[key][0]['surname'],
          slide_id: dataSet[key][0]['slide_id'],
          annotationIds: dataSet[key].map((annoData) => annoData.pk),
          quorum_color: dataSet[key][0]['stroke'],
          is_public: dataSet[key][0]['is_public'],
          toggle: dataSet[key][0]['toggle'],
          isValidate: dataSet[key][0]['isValidate'],
        };
      });

      const otherUserAnnoataions = modifyAnnotationData
        .filter((userAnnotation) => {
          if (
            userAnnotation.user_id !== userDetails.userID &&
            userAnnotation.user_id !== 0
          ) {
            return true;
          }
        })
        .map((filterData) => filterData.annotationIds);

      const currentUserAnnoataions = modifyAnnotationData
        .filter((userAnnotation) => {
          if (
            userAnnotation.user_id === userDetails.userID ||
            userAnnotation.user_id === 0
          ) {
            return true;
          }
        })
        .map((filterData) => filterData.annotationIds)[0];

      setOtherUserAnnotations(otherUserAnnoataions.flat(1));
      setCurrentUserAnnotations(currentUserAnnoataions);
    }
  };

  const hideOtherUserAnnotations = () => {
    openseadragonAnnotations.turnAnnotationsOnOffByUser(
      'on',
      currentUserAnnotations
    );
    openseadragonAnnotations.turnAnnotationsOnOffByUser(
      'off',
      otherUserAnnotations
    );
    const otherUserData = {
      annotationIds: otherUserAnnotations,
      onOrOff: 'off',
      dispatch: dispatch,
    };
    toggleAnnotations(otherUserData);

    const currentUserData = {
      annotationIds: currentUserAnnotations,
      onOrOff: 'on',
      dispatch: dispatch,
    };
    toggleAnnotations(currentUserData);
  };

  const setBrushSizeInAnnotation = (width) => {
    setBrushWidth(width);
    overlay.fabricCanvas().isDrawingMode = true;
    overlay.fabricCanvas().freeDrawingBrush.width = width;
    overlay.fabricCanvas().freeDrawingBrush.color = convertHexToRGBA(
      globleAnnotationColor,
      50
    );
  };
  return (
    <React.Fragment>
      <ul>
        {!runAnalysis && (
          <li
            id="toggle-annotation"
            className={!showAnnotations ? 'active-tool' : 'nonactive-tool'}
            data-for="viewer-toolbar"
            data-tip={'Toggle annotations'}
            onClick={toggleAnnotation}
          >
            <img src={showAnnotations ? ShowAnno : HideAnno} />
          </li>
        )}

        {postIsMine ||
          slideOwnerOrCollaborator ||
          when === 'groups' ||
          when === 'viewer' ||
          (when === 'analytics' && highlightAreas && (
            <React.Fragment>
              <li
                id="circle-annotation"
                className={
                  !showAnnotations || postIsValidate
                    ? 'anno-tool-disable'
                    : currentAnnotationType === 'ellipse'
                      ? 'active-tool'
                      : ''
                }
                data-for="viewer-toolbar"
                data-tip={'Annotate ellipse'}
                onClick={(e) =>
                  showAnnotations &&
                  !postIsValidate &&
                  handleAnnotation(e, 'ellipse')
                }
              >
                <img src={CircleAnno} />
              </li>
              <li
                id="polygon-annotation"
                className={
                  !showAnnotations || postIsValidate
                    ? 'anno-tool-disable'
                    : currentAnnotationType === 'brush'
                      ? 'active-tool'
                      : ''
                }
                data-for="viewer-toolbar"
                data-tip={'Annotate brush'}
                onClick={(e) =>
                  showAnnotations &&
                  !postIsValidate &&
                  handleAnnotation(e, 'brush')
                }
              >
                <img src={PolygonAnno} />
              </li>
              <li
                id="rect-annotation"
                className={
                  !showAnnotations || postIsValidate
                    ? 'anno-tool-disable'
                    : currentAnnotationType === 'rectangle'
                      ? 'active-tool'
                      : ''
                }
                data-for="viewer-toolbar"
                data-tip={'Annotate rectangle'}
                onClick={(e) =>
                  showAnnotations &&
                  !postIsValidate &&
                  handleAnnotation('rectangle')
                }
              >
                <img src={RectangularAnno} />
              </li>
            </React.Fragment>
          ))}
        {(postIsMine ||
          slideOwnerOrCollaborator ||
          when === 'groups' ||
          when === 'viewer') && (
            <React.Fragment>
              <li
                id="circle-annotation"
                className={
                  !showAnnotations || postIsValidate
                    ? 'anno-tool-disable'
                    : currentAnnotationType === 'ellipse'
                      ? 'active-tool'
                      : ''
                }
                data-for="viewer-toolbar"
                data-tip={'Annotate ellipse'}
                onClick={(e) =>
                  showAnnotations &&
                  !postIsValidate &&
                  handleAnnotation(e, 'ellipse')
                }
              >
                <img src={CircleAnno} />
              </li>
              <li
                id="polygon-annotation"
                className={
                  !showAnnotations || postIsValidate
                    ? 'anno-tool-disable'
                    : currentAnnotationType === 'brush'
                      ? 'active-tool'
                      : ''
                }
                data-for="viewer-toolbar"
                data-tip={'Annotate brush'}
                onClick={(e) =>
                  showAnnotations &&
                  !postIsValidate &&
                  handleAnnotation(e, 'brush')
                }
              >
                <img src={PolygonAnno} />
              </li>
              <li
                id="rect-annotation"
                className={
                  !showAnnotations || postIsValidate
                    ? 'anno-tool-disable'
                    : currentAnnotationType === 'arrow'
                      ? 'active-tool'
                      : ''
                }
                data-for="viewer-toolbar"
                data-tip={'Annotate arrow'}
                onClick={(e) =>
                  showAnnotations &&
                  !postIsValidate &&
                  handleAnnotation(e, 'arrow')
                }
              >
                <img src={RectangularAnno} />
              </li>

              <li
                id="edit-annotation"
                className={
                  !showAnnotations
                    ? 'anno-tool-disable'
                    : editAnnotations
                      ? 'active-tool'
                      : 'nonactive-tool'
                }
                data-for="viewer-toolbar"
                data-tip={'Edit annotation'}
                onClick={handleEditAnnotation}
              >
                <img src={EditAnno} />
              </li>
            </React.Fragment>
          )}
        {/* {!runAnalysis && (
          <li
            id="annotation-color-change"
            className={
              !showAnnotations || postIsValidate
                ? 'anno-tool-disable'
                : globleColorChangeActive
                ? 'active-tool'
                : when === 'quorum'
                ? postIsMine
                  ? 'nonactive-tool'
                  : 'anno-tool-disable'
                : 'nonactive-tool'
            }
            data-for="viewer-toolbar"
            data-tip={'Set annotation color'}
            onClick={
              when === 'quorum'
                ? postIsMine
                  ? changeGlobleAnnotationColor
                  : null
                : changeGlobleAnnotationColor
            }  
          >
            <img src={ColorChangeIcon} />
            <span
              style={{ backgroundColor: globleAnnotationColor }}
              class="notification-counter"
            ></span>
          </li>
        )} */}
      </ul>
      {showBrushSizeTool && (
        <div className="right-image-menu-control-parent color-adjustment-menu">
          <p className="right-image-menu-color-control-title">Brush Size</p>
          <div className="right-image-menu-item">
            <Slider
              min={10}
              max={400}
              defaultValue={60}
              onChange={setBrushSizeInAnnotation}
            />
          </div>
        </div>
      )}
      {showAnnotationDataPopup && (
        <AnnotationEditPopup
          onClose={onClose}
          setAnnoatationName={setAnnoatationName}
          handleSaveClick={handleSaveClick}
          currentAnnotationName={currentAnnotationName}
          currentAnnotationData={editAnnotationData}
        />
      )}
      {showAnnotationEditPopup && (
        <AnnotationDetailsPopup
          currentAnnotationName={currentAnnotationName}
          editBtnOnClick={editBtnOnClick}
          deleteBtnOnClick={deleteBtnOnClick}
          onColorChange={onColorChange}
          when={when}
          currentAnnotationData={currentOrgAnnotations}
          setOpenColorPicker={setOpenColorPicker}
          openColorPicker={openColorPicker}
        />
      )}
      {globleColorChangeActive && (
        <AnnotationColorPicker
          customClass={'toolbar-color-picker-continer'}
          onColorChange={onGlobleAnnoColorChange}
        />
      )}
      <ToolTip id="viewer-toolbar" place="bottom" type="info" effect="solid" />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  allAnnotationData: state.Viewer.annotationData,
  toogleAnnotation: state.Viewer.toogleData,
  isSoketDataUpdate: state.Viewer.isSoketDataUpdate,
  userDetails: state.Global.loggedUserDetails,
  isAnnotationUpload: state.Viewer.isAnnotationUpload,
  selectedAnnotationId: state.Viewer.selectedAnnotationId,
  annotationPopupShow: state.Viewer.annotationPopupShow,
});

export default connect(mapStateToProps)(ViewerToolbarAnnotation);
