import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ToolTip from "react-tooltip";
import SaveIcon from "../../../../../assets/icons/save.svg";
import InfoEditIcon from "../../../../../assets/icons/InfoEdit.svg";
import NoteIcon from "../../../../../assets/icons/note.svg";
import ReportIcon from "../../../../../assets/icons/report-status.svg";
import SlidesIcon from "../../../../../assets/icons/slides.svg";
import { Notify } from "../../../../../components";

const PatientDetailsTab = ({
  setSelectedDir,
  setCurrentCase,
  selectedDetails,
  selectedPatientInfo,
  toggleExpansible,
  historicalCasesRef,
  dateSort,
  updateSlideBoxDirDetails,
  handleOpenHistoricalCase,
  selectedDirId,
}) => {
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [allergies, setAllergies] = useState(selectedPatientInfo.allergies);
  const [clinicalHistory, setClinicalHistory] = useState(
    selectedPatientInfo.clinical_history
  );
  const [patientEditing, setPatientEditing] = useState("");
  const togglePatientRef = useRef([]);
  const [number, setNumber] = useState();

  const handleNumber = (e) =>{
    const value = e.target.value.replace(/\D/g, '');
    setNumber(value);
    console.log(number);
  }

  useEffect(() => {
    setSelectedPatient(selectedPatientInfo);
    setPatientEditing(false);
  }, [selectedPatientInfo]);

  const handleChangeInput = (e) => {
    const name = e.target.getAttribute("name");
    switch (name) {
      case "mrnNumber":
        setSelectedPatient({
          ...selectedPatient,
          mrn_number: e.target.value,
        });
        break;
      case "firstName":
        setSelectedPatient({
          ...selectedPatient,
          first_name: e.target.value,
        });
        break;
      case "middleName":
        setSelectedPatient({
          ...selectedPatient,
          middle_name: e.target.value,
        });
        break;
      case "lastName":
        setSelectedPatient({ ...selectedPatient, last_name: e.target.value });
        break;
      case "gender":
        setSelectedPatient({ ...selectedPatient, gender: e.target.value });
        break;
      case "dob":
        setSelectedPatient({ ...selectedPatient, dob: e.target.value });
        break;
      case "socialSecurityNumber":
        setSelectedPatient({
          ...selectedPatient,
          social_security_number: e.target.value,
        });
        break;
      case "contactNumber":
        setSelectedPatient({
          ...selectedPatient,
          contact_number: e.target.value.replace(/\D/g, ''),
        });
        break;
      case "patientEmail":
        setSelectedPatient({
          ...selectedPatient,
          patient_email: e.target.value,
        });
        break;
    }
  };

  const handlePatientEdit = () => {
    const {
      mrn_number,
      first_name,
      last_name,
      gender,
      contact_number,
    } = selectedPatient;
    if (patientEditing === "patient") {
      if (
        mrn_number === "" ||
        first_name === "" ||
        last_name === "" ||
        gender === "" ||
        contact_number === ""
      ) {
        Notify({
          value:
            `${
              mrn_number === ""
                ? `MRN Number`
                : first_name === ""
                ? `First Name`
                : last_name === ""
                ? `Last Name`
                : `Phone Number`
            }` +
            " " +
            "is required",
        });
        return;
      } else if (contact_number.length < 6) {
        Notify({
          value: "Phone number must be valid"
        });
        return;
      } else {
        updateSlideBoxDirDetails({
          editPatientInfo: true,
          dirId: selectedDirId,
          patientDetails: {
            patientId: selectedPatient.patient_id,
            mrnNumber: selectedPatient.mrn_number,
            firstName: selectedPatient.first_name,
            middleName: selectedPatient.middle_name || "",
            lastName: selectedPatient.last_name,
            gender: selectedPatient.gender,
            dob: moment(selectedPatient.dob).format("YYYY-MM-DD") || "",
            ssn: selectedPatient.social_security_number || null,
            contactNumber: selectedPatient.contact_number,
            patientEmail: selectedPatient.patient_email || "",
          },
        });
      }
    } else {
      updateSlideBoxDirDetails({
        dirId: selectedDetails.dirDataId,
        editPatientInfo: false,
        fieldName: patientEditing,
        fieldValue:
          patientEditing === "allergies" ? allergies : clinicalHistory,
      });
    }
    setPatientEditing("");
  };

  return (
    
      <div className="details-section" id="details-section">
        <div className="info-details-section">
          <div className="expansible-item">
            <div
              onClick={toggleExpansible}
              className="expansible-header heading-font"
              ref={(ref) => (togglePatientRef.current[0] = ref)}
            >
              <span className="file-name">Patient Information</span>
              <span>
                <img
                  src={patientEditing === "patient" ? SaveIcon : InfoEditIcon}
                  alt=""
                  className="edit-icon"
                  data-test="edit-patient-btn"
                  onClick={() => {
                    patientEditing !== "patient"
                      ? setPatientEditing("patient")
                      : handlePatientEdit();
                    !togglePatientRef.current[0].classList.contains("active") &&
                      togglePatientRef.current[0].click();
                  }}
                />
              </span>
            </div>
            <div class="expansible-content patient-tab">
              <div className="input-field mrn-number">
                <label>MRN Number<sup className="required">*</sup></label>
                <input
                  type="text"
                  maxLength={7}
                  name="mrnNumber"
                  data-test="mrn-number-field"
                  value={selectedPatient.mrn_number || ""}
                  onChange={handleChangeInput}
                  disabled={patientEditing !== "patient"}
                />
              </div>
              <div className="patient-name">
                <div className="input-field">
                  <label>
                    First Name<sup className="required">*</sup>
                  </label>
                  <input
                    type="text"
                    required
                    name="firstName"
                    value={selectedPatient.first_name || ""}
                    onChange={handleChangeInput}
                    disabled={patientEditing !== "patient"}
                  />
                </div>
                <div className="input-field">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    name="middleName"
                    value={selectedPatient.middle_name || ""}
                    onChange={handleChangeInput}
                    disabled={patientEditing !== "patient"}
                  />
                </div>
                <div className="input-field">
                  <label>
                    Last Name<sup className="required">*</sup>
                  </label>
                  <input
                    type="text"
                    value={selectedPatient.last_name || ""}
                    name="lastName"
                    onChange={handleChangeInput}
                    disabled={patientEditing !== "patient"}
                    required
                  />
                </div>
              </div>
              <div className="gender">
                <span>
                  Gender<sup className="required">*</sup>
                </span>
                <label className="specific-gender male">
                  Male
                  <input
                    type="radio"
                    checked={selectedPatient.gender === "Male"}
                    name="gender"
                    value="Male"
                    onChange={handleChangeInput}
                    disabled={patientEditing !== "patient"}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="specific-gender">
                  Female
                  <input
                    type="radio"
                    name="gender"
                    value="Female"
                    checked={selectedPatient.gender === "Female"}
                    onChange={handleChangeInput}
                    disabled={patientEditing !== "patient"}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="birthday-security">
                <div className="input-field dob">
                  <label>
                    Date of Birth<sup className="required">*</sup>
                  </label>
                  <div className="dob-calender">
                    <input
                      type="date"
                      required
                      name="dob"
                      value={moment(selectedPatient.dob).format("YYYY-MM-DD")}
                      max={moment().format("YYYY-MM-DD")}
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={handleChangeInput}
                      disabled={patientEditing !== "patient"}
                    />     
                    
                  </div>
                </div>
                <div className="input-field">
                  <label>Social Security Number</label>
                  <input
                    type="text"
                    required
                    name="socialSecurityNumber"
                    maxLength="9"
                    value={patientEditing === "patient" ? number : selectedPatient.social_security_number}         
                    onChange={(e)=>{
                      handleNumber(e);
                      handleChangeInput(e);}}
                    disabled={patientEditing !== "patient"}
                  />
                </div>
              </div>
              <div className="phone-email">
                <div className="input-field">
                  <label>
                    Phone Number<sup className="required">*</sup>
                  </label>
                  <input
                    type="text"
                    required
                    name="contactNumber"
                    maxLength={12}
                    value={selectedPatient.contact_number}
                    onChange={handleChangeInput}
                    disabled={patientEditing !== "patient"}
                  />
                </div>
                <div className="input-field">
                  <label>Email Address</label>
                  <input
                    type="email"
                    name="patientEmail"
                    value={selectedPatient.patient_email}
                    onChange={handleChangeInput}
                    disabled={patientEditing !== "patient"}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="info-details-section">
          <div className="expansible-item">
            <div
              onClick={toggleExpansible}
              className="expansible-header default_open heading-font"
              ref={(ref) => (togglePatientRef.current[1] = ref)}
            >
              <span className="file-name">Allergies</span>
              <span>
                <img
                  src={patientEditing === "allergies" ? SaveIcon : InfoEditIcon}
                  alt="edit"
                  className="edit-icon"
                  onClick={() => {
                    patientEditing !== "allergies"
                      ? setPatientEditing("allergies")
                      : handlePatientEdit();
                    !togglePatientRef.current[1].classList.contains("active") &&
                      togglePatientRef.current[1].click();
                  }}
                />
              </span>
            </div>
            <div class="expansible-content">
              <textarea
                name="allergies"
                id="allergies"
                className="allergies"
                rows="2"
                onChange={(e) => setAllergies(e.target.value)}
                disabled={patientEditing !== "allergies"}
              >
                {selectedPatientInfo.allergies}
              </textarea>
            </div>
          </div>
        </div>
        <div className="info-details-section">
          <div className="expansible-item">
            <div
              onClick={toggleExpansible}
              className="expansible-header default_open heading-font"
              ref={(ref) => (togglePatientRef.current[2] = ref)}
            >
              <span className="file-name">Clinical History</span>
              <span>
                <img
                  src={
                    patientEditing === "clinical_history"
                      ? SaveIcon
                      : InfoEditIcon
                  }
                  alt="edit"
                  className="edit-icon"
                  onClick={() => {
                    patientEditing !== "clinical_history"
                      ? setPatientEditing("clinical_history")
                      : handlePatientEdit();
                    !togglePatientRef.current[2].classList.contains("active") &&
                      togglePatientRef.current[2].click();
                  }}
                />
              </span>
            </div>
            <div class="expansible-content">
              <textarea
                name="clinical-history"
                id="clinical-history"
                className="clinical-history"
                rows="2"
                onChange={(e) => setClinicalHistory(e.target.value)}
                disabled={patientEditing !== "clinical_history"}
              >
                {selectedPatientInfo.clinical_history}
              </textarea>
            </div>
          </div>
        </div>
        <div className="info-details-section">
          <div className="expansible-item">
            <div
              onClick={toggleExpansible}
              ref={historicalCasesRef}
              className="expansible-header default_open heading-font"
            >
              <span className="file-name">Historical Cases</span>
            </div>
            <div class="expansible-content historical-tab">
              <div className="historical-cases-container">
                <table>
                  <thead>
                    {selectedPatientInfo.historical_cases && (
                      <tr>
                        <th>Date</th>
                        <th>Acc. Number</th>
                        <th>Procedure Name</th>
                        <th>Other Details</th>
                      </tr>
                    )}
                  </thead>
                  {selectedPatientInfo.historical_cases &&
                    selectedPatientInfo.historical_cases
                      .sort(dateSort)
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {item.procedure_date
                                ? moment(item.procedure_date).format(
                                    "MMM D, YYYY h:m A"
                                  )
                                : "-"}
                            </td>
                            <td>
                              <a
                                onClick={() =>
                                  handleOpenHistoricalCase(
                                    item.slidebox_case_id,
                                    setCurrentCase,
                                    setSelectedDir,
                                    selectedDetails
                                  )
                                }
                              >
                                {item.case_accession_number || "-"}
                              </a>
                            </td>
                            <td>{item.case_name || "-"}</td>
                            <td className="action-icons">
                              <img
                                src={NoteIcon}
                                alt="Notes"
                                data-for="case-details-tooltip"
                                data-tip="Notes"
                              />
                              <img
                                src={ReportIcon}
                                alt="Report"
                                data-for="case-details-tooltip"
                                data-tip="Report"
                              />
                              <img
                                src={SlidesIcon}
                                alt="Slides"
                                data-for="case-details-tooltip"
                                data-tip="Slides"
                              />
                            </td>
                          </tr>
                        );
                      })}
                  <ToolTip
                    id="case-details-tooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default PatientDetailsTab;
