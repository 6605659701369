import React from "react";
import { CirclePicker } from "react-color";

const AnnotationColorPicker = ({ customClass, onColorChange, top, left }) => {
  return (
    <div className={customClass ? customClass : "color-picker-container"} style={{ top: top && top + 20, left: left && left, transform: 'translateX(-50%)'}}>
      <CirclePicker circleSize={26} onChangeComplete={onColorChange} />
    </div>
  );
};

export default AnnotationColorPicker;
