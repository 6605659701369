import React, { useCallback, useState } from "react";
import moment from "moment";
import { FeatureGate } from "react-feature-gate";
import ToolTip from "react-tooltip";
import NoteIcon from "../../../../../assets/icons/note.svg";
import ReportIcon from "../../../../../assets/icons/report-status.svg";
import SlidesIcon from "../../../../../assets/icons/slides.svg";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { Select } from "antd";
import Loader from "../../SlideTable/Loader";
const { Option } = Select;
const sortSlides = (a, b) => {
  if (a.container < b.container) return -1;
  if (a.container > b.container) return 1;

  if (a.block < b.block) return -1;
  if (a.block > b.block) return 1;
};

const SlidesTab = ({
  history,
  setSelectedDir,
  setCurrentCase,
  selectedDetails,
  allSildeinfo,
  selectedPatientInfo,
  setOpenFromCaseTab,
  setOpenCreateCase,
  setOpenFromSlideTab,
  toggleExpansible,
  dateSort,
  openCreateCase,
  handleOpenHistoricalCase,
  isOpenedFromGroups,
  isFolderSelected,
  setIsFolderSelected,
  setSelectedSlideData,
  openAllContainers,
  setReturnToWorklist,
  currentTab,
  selectedListItem,
}) => {
  const [selectedHistoricalCase, setSelectedHistoricalCase] = useState(0);
  const [slideIndex, setSlideIndex] = useState(-1);

  const containerRef = useCallback(
    (node) => {
      if (node != null) {
        if (openAllContainers) {
          !node.classList.contains("active") && node.click();
        } else if (!openAllContainers) {
          node.classList.contains("active") && node.click();
        }
      }
    },
    [openAllContainers]
  );

  const handleShowInViewer = (slideItem) => {
    history.push({
      pathname: `/viewer`,
      search: `?image=${slideItem.dzi_key_folder}&thumb=${slideItem.thumbDataToken}`,
    });
  };

  return (
    <div className="details-section">
      <div className="add-slides-container">
        <FeatureGate feature="patient_level_information">
          <p className="select-case">Select Case</p>
          <Select
            onChange={(value) => {
              setSelectedHistoricalCase(value);
            }}
          >
            {selectedPatientInfo.historical_cases &&
              selectedPatientInfo.historical_cases.map((item, index) => {
                return (
                  <Option
                    key={index}
                    value={index}
                    className="dropdown-container"
                  >
                    <div className="dropdown-details">
                      <p className="case-name">{item.case_name}</p>
                      <p className="case-accession-number">
                        Acc. No. : {item.case_accession_number}
                      </p>
                    </div>
                    <p className="procedure-date">
                      {moment(item.procedure_date).format("MMM D, YYYY")}
                    </p>
                  </Option>
                );
              })}
          </Select>
        </FeatureGate>
        <a
          className="add-slides"
          onClick={() => {
            setOpenCreateCase(!openCreateCase);
            setOpenFromSlideTab(true);
            setOpenFromCaseTab(false);
          }}
        >
          + Add Slides
        </a>
      </div>

      <div className="cbs-display">
        <div className="info-details-section">
          {allSildeinfo &&
            allSildeinfo.sort(sortSlides)[0] &&
            allSildeinfo.sort(sortSlides)[0].container !== 0 &&
            allSildeinfo
              .sort(sortSlides)
              .filter(
                (e, i) =>
                  allSildeinfo.findIndex((a) => a.container === e.container) ===
                  i
              )
              .map((item, index) => {
                return (
                  <div className="expansible-item" key={index}>
                    <div
                      onClick={toggleExpansible}
                      className="expansible-header default_open heading-font"
                      ref={containerRef}
                    >
                      <span className="file-name">
                        Container {item.container}
                      </span>
                    </div>
                    <div class="expansible-content">
                      <div className="blocks-container">
                        {allSildeinfo
                          .sort(sortSlides)
                          .filter((e, i) => e.container === item.container)
                          .map((blockItem, i) => {
                            if (
                              (i === 0 && allSildeinfo[i].block) ||
                              (allSildeinfo[i - 1] &&
                                blockItem.block !== allSildeinfo[i - 1].block)
                            ) {
                              return (
                                <div className="block" key={i}>
                                  <h1 className="block-heading">
                                    Block {blockItem.block}
                                  </h1>
                                  <div className="slides">
                                    {allSildeinfo
                                      .sort(sortSlides)
                                      .filter(
                                        (e, i) =>
                                          e.container === item.container &&
                                          e.block === blockItem.block
                                      )
                                      .map((slideItem, index) => {
                                        return (
                                          <div className="slide" key={index}>
                                            {slideItem.dzi_thumbnail ? (
                                              <img
                                                src={slideItem.dzi_thumbnail}
                                                data-test="slide"
                                                style={{
                                                  border:
                                                    !isFolderSelected &&
                                                    isOpenedFromGroups &&
                                                    index == slideIndex &&
                                                    "4px solid purple",
                                                }}
                                                onClick={() => {
                                                  if (isOpenedFromGroups) {
                                                    setSlideIndex(index);
                                                    setIsFolderSelected(false);
                                                    setSelectedSlideData(
                                                      slideItem
                                                    );
                                                  } else {
                                                    setReturnToWorklist({
                                                      tab: currentTab,
                                                      selectedItem: selectedListItem,
                                                      return: true,
                                                    });
                                                    handleShowInViewer(
                                                      slideItem
                                                    );
                                                  }
                                                }}
                                                alt="#"
                                                className="thumbnail"
                                                data-tip={slideItem.slide_name}
                                                data-for="slide-name-tooltip"
                                              />
                                            ) : (
                                              <div
                                                data-tip="Slides are getting ready..."
                                                data-for="slide-name-tooltip"
                                                style={{ paddingTop: "150%" }}
                                              >
                                                <ImageOutlinedIcon />
                                                <Loader data-test="loader" />
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                    <ToolTip
                                      id="slide-name-tooltip"
                                      place="top"
                                      type="dark"
                                      effect="solid"
                                    />
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
      <div className="info-details-section">
        <FeatureGate feature="patient_level_information">
          <div className="expansible-item">
            <div
              onClick={toggleExpansible}
              className="expansible-header default_open heading-font"
            >
              <span className="file-name">Historical Cases</span>
            </div>
            <div class="expansible-content historical-tab">
              <div className="historical-cases-container">
                <table>
                  <thead>
                    {selectedPatientInfo.historical_cases && (
                      <tr>
                        <th>Date</th>
                        <th>Acc. Number</th>
                        <th>Procedure Name</th>
                        <th>Other Details</th>
                      </tr>
                    )}
                  </thead>
                  {selectedPatientInfo.historical_cases &&
                    selectedPatientInfo.historical_cases
                      .sort(dateSort)
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {item.procedure_date
                                ? moment(item.procedure_date).format(
                                    "MMM D, YYYY h:m A"
                                  )
                                : "-"}
                            </td>
                            <td>
                              <a
                                onClick={() =>
                                  handleOpenHistoricalCase(
                                    item.slidebox_case_id,
                                    setCurrentCase,
                                    setSelectedDir,
                                    selectedDetails
                                  )
                                }
                              >
                                {item.case_accession_number || "-"}
                              </a>
                            </td>
                            <td>{item.case_name || "-"}</td>
                            <td className="action-icons">
                              <img
                                src={NoteIcon}
                                alt="Notes"
                                data-for="case-details-tooltip"
                                data-tip="Notes"
                              />
                              <img
                                src={ReportIcon}
                                alt="Report"
                                data-for="case-details-tooltip"
                                data-tip="Report"
                              />
                              <img
                                src={SlidesIcon}
                                alt="Slides"
                                data-for="case-details-tooltip"
                                data-tip="Slides"
                              />
                            </td>
                          </tr>
                        );
                      })}
                  <ToolTip
                    id="case-details-tooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                  />
                </table>
              </div>
            </div>
          </div>
        </FeatureGate>
      </div>
    </div>
  );
};

export default SlidesTab;
