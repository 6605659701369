import axios from "../../../utils/axiosInstance";

export const getViewerLiveSyncUrlApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      `/api/viewer/viewerLiveShareUrl`,
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const downloadAnnotationApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      `/api/viewer/downloadAnnotation`,
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const updateDescriptionAPI = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/viewer/updateAnnotationDescription",
      dataParams
    );
    return resData;
  } catch (error) {
    throw error;
  }
};
export const setActivityLogApi = async (dataParams) => {
  try {
    let endPoint;
    if (dataParams.type === "add") {
      endPoint = "/api/viewer/addSlideReviewLog";
    } else {
      endPoint = "/api/viewer/updateSlideReviewLog";
    }
    const resData = await axios.post(endPoint, dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const getActivityLogApi = async (dataParams) => {
  try {
    const resData = await axios.get("/api/viewer/viewerReviewLog", {
      params: dataParams,
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const getHeatMapDataApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/aiAnalytics/generateHeatMap",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const livesynStopApi = async (dataParams) => {
  try {
    const resData = await axios.get("/api/viewer/liveSyncStop", dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const getSlideLabelsApi = async (dataParams) => {
  try {
    const resData = await axios.get("/api/viewer/getSlideLabels", {
      params: dataParams,
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const setViewerLabelsApi = async (dataParams) => {
  try {
    const resData = await axios.post("/api/viewer/setViewerLabels", dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};
