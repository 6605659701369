import * as methods from "./methods";
import { EventEmitter } from "events";

const initialState = {
  myBoards: [],
  selectedBoard: null,
  selectedMembers: null,
  invitations: [],
  requests: [],
  messages: [],
  hasNextPage: false,
  currentPage: 1,
  file: null,
  onClickEmitter: new EventEmitter(),
  publicGroups: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ADD_BOARD": {
      const { board } = action.payload;
      return {
        ...state,
        myBoards: methods.addTumorBoard(state.myBoards, board),
      };
    }

    case "SET_BOARDS": {
      const { boards } = action.payload;
      return {
        ...state,
        myBoards: methods.setTumorBoards(boards),
        selectedBoard: state.selectedBoard
          ? methods.compareSelected(
              state.selectedBoard,
              methods.setTumorBoards(boards)
            )
          : state.selectedBoard,
      };
    }

    case "SET_SELECTED_BOARD": {
      const { board } = action.payload;
      return {
        ...state,
        selectedBoard: board ? { ...board } : {},
        selectedMembers: null,
      };
    }

    case "UPDATE_SELECTED_BOARD_LIVE_SYNC": {
      const { userId, status, featureId } = action.payload;

      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          slideLiveSharePerson: status ? userId : null,
          slideLiveShareStatus: status ? 1 : 0,
        },
        myBoards: state.myBoards.map((board) => {
          if (board.id === featureId) {
            return {
              ...board,
              slideLiveSharePerson: status ? userId : null,
              slideLiveShareStatus: status ? 1 : 0,
            };
          } else {
            return board;
          }
        }),
      };
    }

    case "SET_SELECTED_MEMBERS": {
      const { members } = action.payload;
      return {
        ...state,
        selectedMembers: members,
      };
    }

    case "REMOVE_MEMBER": {
      const { member } = action.payload;
      return {
        ...state,
        selectedMembers: state.selectedMembers
          ? methods.removeMember(member, state.selectedMembers)
          : null,
      };
    }

    case "DELETE_BOARD": {
      const { id } = action.payload;
      return {
        ...state,
        myBoards: methods.deleteTB(id, state.myBoards),
      };
    }

    case "SET_ADMIN": {
      const { userId, admin } = action.payload;
      return {
        ...state,
        selectedMembers: state.selectedMembers
          ? methods.setAdmin(userId, admin, state.selectedMembers)
          : null,
      };
    }

    case "SET_INVITATIONS": {
      const { invitations } = action.payload;
      return {
        ...state,
        invitations: methods.setInvitations(invitations),
      };
    }

    case "DELETE_INVITATION": {
      const { id } = action.payload;
      return {
        ...state,
        invitations: methods.deleteInvitation(id, state.invitations),
      };
    }

    case "ADD_INVITATION": {
      const { inv } = action.payload;
      return {
        ...state,
        invitations: methods.addInv(inv, state.invitations),
      };
    }

    case "DELETE_REQUEST": {
      const { id } = action.payload;
      return {
        ...state,
        requests: methods.deleteRequest(id, state.requests),
      };
    }

    case "SET_REQUESTS": {
      const { requests } = action.payload;
      return {
        ...state,
        requests: methods.setRequests(requests),
      };
    }

    case "ADD_REQUEST": {
      const { req } = action.payload;
      return {
        ...state,
        requests: methods.addReq(req, state.requests),
      };
    }

    case "SET_MESSAGES": {
      const { messages } = action.payload;
      const mergeMessage =
        state.currentPage === 1 ? messages : messages.concat(state.messages);
      return {
        ...state,
        messages: mergeMessage,
      };
    }

    case "SET_HAS_NEXT_PAGE": {
      const { hasNextPage } = action.payload;
      return {
        ...state,
        hasNextPage,
      };
    }

    case "SET_CURRENT_PAGE": {
      const { currentPage } = action.payload;
      return {
        ...state,
        currentPage,
      };
    }

    case "ADD_MESSAGE": {
      const { message } = action.payload;
      return {
        ...state,
        messages: [...state.messages, message],
      };
    }

    case "SET_TUMOR_SLIDE": {
      const py = action.payload;
      return {
        ...state,
        selectedBoard: methods.setSlideImage(py, state.selectedBoard),
      };
    }

    case "DELETE_MESSAGE": {
      const { id } = action.payload;
      return {
        ...state,
        messages: methods.deleteMessage(id, state.messages),
      };
    }

    case "EDIT_MESSAGE": {
      const { id, body } = action.payload;
      return {
        ...state,
        messages: methods.editMessage(id, body, state.messages),
      };
    }

    case "SET_FILE": {
      const { file } = action.payload;
      return {
        ...state,
        file,
      };
    }

    case "SET_PUBLIC_GROUPS": {
      const { groups } = action.payload;
      return {
        ...state,
        publicGroups: groups,
      };
    }

    default:
      return { ...state };
  }
};
