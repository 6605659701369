import React, { useState, useEffect, useRef } from "react";
import { scaleLinear, scaleBand } from "d3-scale";
import { transition } from "d3-transition";
import * as d3 from "d3";
import { connect } from "react-redux";
import {
  getSlideboxCaseDataAction,
  getSlideboxNewAndCompletedCaseDataAction,
  getViewerAnnotationDataAction,
} from "../../../actions/adminDashboard-action";
import Line from "./line";
import { curveMonotoneX } from "d3-shape";
import { extent } from "d3-array";

const NewLineChart = ({
  caseStatusData,
  getSlideboxCaseData,
  getSlideboxCaseStatusData,
  data,
  getViewerAnnotationData,
}) => {
  const [monthSelected, setMonthSelected] = useState(true);
  const [weekSelected, setWeekSelected] = useState(false);
  const [daySelected, setDaySelected] = useState(false);
  const [newCases, setNewCases] = useState([]);
  const [completedCases, setCompletedCases] = useState([]);
  const WIDTH = 750;
  const HEIGHT = 370;

  const MARGINS = {
    top: 0,
    right: 10,
    bottom: 30,
    left: 50,
  };

  const xScale = scaleBand()
    .domain(data.map((d) => d.name))
    .range([MARGINS.left, WIDTH - MARGINS.right])
    .padding(0.1);

  const yScale = scaleLinear()
    .domain(extent(data, (d) => d.value))
    .range([HEIGHT - MARGINS.top, MARGINS.bottom])
    .nice();

  const lineGenerator = d3
    .line()
    .x(function(d) {
      return xScale(d.name);
    })
    .y(function(d) {
      return yScale(d.value);
    })
    .curve(curveMonotoneX);

  const XAxis = ({ top, bottom, left, right, height, scale }) => {
    const axis = useRef(null);
    let xAxis = d3.axisBottom().scale(xScale);

    useEffect(() => {
      d3.select(axis.current).call(d3.axisBottom(scale));
    }, [scale]);

    return (
      <g
        className="axis x"
        ref={axis}
        transform={`translate(0, ${height})`}
        style={{ fontSize: 13, fontFamily: "Roboto, sans-serif" }}
      />
    );
  };

  const YAxis = ({ top, bottom, left, right, scale }) => {
    let yAxis = d3.axisLeft().scale(yScale);

    const axis = useRef(null);

    useEffect(() => {
      d3.select(axis.current)
        .call(d3.axisLeft(scale))
        .style("font-family", "roboto");
    }, [scale]);

    return (
      <g
        className="axis y"
        ref={axis}
        transform={`translate(${left},0)`}
        style={{ fontSize: 12 }}
      />
    );
  };

  const displayAnnotationsCreated = (type) => {
    if (type === "week") {
      setWeekSelected(true);
      setMonthSelected(false);
      setDaySelected(false);
    } else if (type === "month") {
      setMonthSelected(true);
      setWeekSelected(false);
      setDaySelected(false);
    } else if (type === "day") {
      setMonthSelected(false);
      setWeekSelected(false);
      setDaySelected(true);
    }
    getViewerAnnotationData(type);
  };

  return (
    <div>
      <div className="chart-header">
        <span class="chart-heading">Annotations Created</span>
        <div className="button-section">
          <div className={`button-container ${daySelected ? `selected` : ""}`}>
            <span
              className="chart-button"
              onClick={() => displayAnnotationsCreated("day")}
            >
              D
            </span>
          </div>
          <div
            className={`button-container ${weekSelected ? `selected` : ""}`}
            onClick={() => displayAnnotationsCreated("week")}
          >
            <span className="chart-button">W</span>
          </div>
          <div
            className={`button-container ${monthSelected ? `selected` : ""}`}
            onClick={() => displayAnnotationsCreated("month")}
          >
            <span className="chart-button">M</span>
          </div>
        </div>
      </div>
      <svg
        id="productivity-line-chart"
        width="750"
        height="370"
        viewBox="0 0 820 460"
      >
        <XAxis
          scale={xScale}
          top={MARGINS.top}
          bottom={MARGINS.bottom}
          left={MARGINS.left}
          right={MARGINS.right}
          height={HEIGHT}
        />
        <YAxis
          scale={yScale}
          top={MARGINS.top}
          bottom={MARGINS.bottom}
          left={MARGINS.left}
          right={MARGINS.right}
        />
        <Line
          xScale={xScale}
          yScale={yScale}
          top={MARGINS.top}
          bottom={MARGINS.bottom}
          height={HEIGHT}
          lineGenerator={lineGenerator}
          left={MARGINS.left}
          data={data}
        />
      </svg>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
  caseStatusData: state.Dashboard.caseStatusData,
});

const mapDispatchToProps = (dispatch) => ({
  getViewerAnnotationData: (payload) =>
    dispatch(getViewerAnnotationDataAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewLineChart);
