import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import QcStatusIcon from "../../../assets/icons/qc-status.svg";
import ReportStatusIcon from "../../../assets/icons/report-status.svg";
import ScanStatusIcon from "../../../assets/icons/TotalSlides.svg";
import NoteIcon from "../../../assets/icons/note.svg";

import { Collapse } from "antd";
const { Panel } = Collapse;
import "antd/dist/antd.css";

import Paper from "@material-ui/core/Paper";
import Select from "react-select";
import { withRouter } from "react-router-dom";

import ToolTip from "react-tooltip";
import { connect } from "react-redux";
import { getNotificationsAction } from "../../notifications/actions/notification-actions";
import {
  breadcrumbAction,
  updateCaseStatusAction,
} from "../actions/slidebox-action";
import EmptyMessage from "../components/SlideTable/EmptyMessage";
import Loader from "../components/SlideTable/Loader";

import { ContextMenu, Notify } from "../../../components";
import ImageTag from "../components/SlideItems/ImageTag";
import DefaultAvatar from "../../../assets/icons/default-avatar.svg";
import UserProfilePopup from "../../profile/components/UserProfilePopup";
import {
  useDateFormat,
  useNameFormat,
} from "../../adminDashboard/components/DashboardItems/useFormat";

import StandardIcon from "../../../assets/icons/standard.svg";
import RushIcon from "../../../assets/icons/rush.svg";
import StatIcon from "../../../assets/icons/stat.svg";
import AddAttachment from "../components/SlideTable/AddAttachment";
import InfoIcon from "../../../assets/icons/info-icon.svg";
import { FadeIn } from "animate-components";
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from "../../../components";
import ColumnResizeHandle from "../components/SlideTable/ColumnResizeHandle";

const useStyles = makeStyles({
  table: {
    minWidth: "100vw",
    width: "120%",
    overflow: "auto",
    tableLayout: "fixed",
  },
  tableRow: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
  arrowIcon: {
    color: "#6a6e72",
    fontSize: 18,
    marginLeft: 5,
  },
  headerBg: {
    backgroundColor: "#ECF0F4",
  },
  actionIcon: {
    marginRight: 10,
    width: 20,
    height: 20,
  },
});

const emptyMessage = {
  root: "Use Create Case button to create a new case",
  folder: "Create a case or upload images",
  case: "Upload images",
  undefined: "",
  emptySearch: "No results found",
};

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  console.log(items);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { sortedListData: sortedItems, requestSort, sortConfig };
};

const SlideGridView = ({
  listData,
  history,
  breadcrumbAction,
  showLoader,
  toggleDirHandler,
  setSelectedDir,
  selectedList,
  selectedListType,
  selectedFolderType,
  isSearch,
  showSharedPopup,
  deleteDirItemPopup,
  renameSlideItem,
  handleToggleMovePopup,
  getNotificationList,
  conversionComplete,
  setShowDetails,
  setSelectedDetails,
  getFileDownloadUrlAction,
  selectedListItem,
  showGroupsSelectPopup,
  selectedListSlideData,
  downloadSlide,
  isModal,
  updateCaseStatus,
  selectedFolderName,
  description,
  when,
  setContinueButtonDisabled = () => {},
  setSelectedSlideDataToAnalyze,
  importSlide,
  getSearchDirData,
  setLabel,
  radioButtonChecked,
  setRadioButtonChecked,
  dxAccountDetails,

  worklistData,
}) => {
  const classes = useStyles();

  const [expandIconPosition, setExpandIconPosition] = useState("right");
  const { sortedListData, requestSort } = useSortableData(
    worklistData && worklistData[0] != null ? worklistData : listData
  );

  const patientData = groupBy(worklistData, "patientFirstName");
  const patientNames = Object.keys(patientData);

  const filteredPatientNames = patientNames.filter(Boolean);
  const patientDetails = Object.values(patientData);

  const [isTableRowSelected, setIsTableRowSelected] = useState({});
  const [nameSorting, setNameSorting] = useState(true);
  const [sizeSorting, setSizeSorting] = useState(true);
  const [lastModifiedSorting, setLastModifiedSorting] = useState(true);
  const [annotationSorting, setAnnotationSorting] = useState(true);
  const [blink, setBlink] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();
  const [attachInput, setAttachInput] = useState(false);
  const [selectedDataToAnalyze, setSelectedDataToAnalyze] = useState();
  const [selectedDirDetails, setSelectedDirDetails] = useState({});
  const dateFormat =
    dxAccountDetails && dxAccountDetails.date_format
      ? dxAccountDetails.date_format
      : "MM-DD-YYYY";
  const timezone =
    dxAccountDetails && dxAccountDetails.timezone
      ? dxAccountDetails.timezone
      : "";
  const [dirId, setDirId] = useState();
  const [status, setStatus] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const resizeRef0 = useRef();
  const resizeRef1 = useRef();
  const resizeRef2 = useRef();
  const resizeRef3 = useRef();
  const resizeRef4 = useRef();
  const resizeRef5 = useRef();
  const resizeRef6 = useRef();
  const resizeRef7 = useRef();
  const resizeRef8 = useRef();
  const resizeRef9 = useRef();
  const resizeRef10 = useRef();
  const resizeRef11 = useRef();

  const priorityOptions = [
    {
      value: "standard",
      img: StandardIcon,
    },
    {
      value: "rush",
      img: RushIcon,
    },
    {
      value: "stat",
      img: StatIcon,
    },
  ];

  const statusOptions = [
    {
      value: "ordered",
      label: "Ordered",
    },
    {
      value: "cancelled",
      label: "Cancelled",
    },
    {
      value: "arrived",
      label: "Arrived",
    },
    {
      value: "verified",
      label: "Verified",
    },
    {
      value: "transcribed",
      label: "Transcribed",
    },
    {
      value: "prelimeport",
      label: "Prelim Report",
    },
    {
      value: "reported",
      label: "Reported",
    },
  ];

  const handleModalOpen = (
    index,
    firstName,
    lastName,
    userEmail,
    roleName,
    profile,
    userImageSrc,
    newPlacement
  ) => (event) => {
    setAnchorEl(event.currentTarget);
    setUserDetails({
      firstName,
      lastName,
      userEmail,
      roleName,
      profile,
      userImageSrc,
    });
   
    setModalOpen(!modalOpen);
    setPlacement(newPlacement);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSearch = (e, search) => {
    e.stopPropagation();
    console.log(search);
    getSearchDirData({ search: search, label: search });
    setLabel(search);
  };

  const handleShowInViewer = (slideItem = selectedListSlideData) => {
    if (!isModal) {
      history.push({
        pathname: `/viewer`,
        search: `?image=${slideItem.keyFolder}&thumb=${slideItem.thumbDataToken}`,
      });
    }
  };

  const onSelectedRow = (e, listDataItem, index) => {
    const { dirDataId, parentDir, caseOrFolder } = listDataItem;
    setSelectedSlideDataToAnalyze(listDataItem);
    setContinueButtonDisabled(true);
    setIsTableRowSelected({ [index]: true });
    setRadioButtonChecked(false);
    setSelectedDir({
      dirId: dirDataId,
      parentDir,
      dirType: caseOrFolder,
    });
    setSelectedDataToAnalyze(listDataItem);

    breadcrumbAction({ showIcon: true, [index]: listDataItem });
  };

  const onDirDoubleClick = (e, listDataItem) => {
    const {
      dirDataId,
      parentDir,
      caseOrFolder,
      description,
      dirDataName,
    } = listDataItem;
    if (caseOrFolder === "slide" || caseOrFolder === "document") {
      if (!isModal) {
        toggleDirHandler({
          dirId: dirDataId,
          parentDir,
          dirType: caseOrFolder,
        });
      }
    } else {
      toggleDirHandler({
        dirId: dirDataId,
        parentDir,
        dirType: caseOrFolder,
      });
    }
  };

  const mapSlideStatus = (status) => {
    if (status === "success") {
      return "Ready";
    }
    if (status === "pending") {
      return "Progress";
    }
    if (status === "fail") {
      return "Retry";
    }
  };

  const T1Obj = useRef();
  const T2Obj = useRef();

  const [isHeaderTableScrolled, setIsHeaderTableScrolled] = useState(false);
  const [isBodyTableScrolled, setIsBodyTableScrolled] = useState(false);

  const headerTableScrolled = () => {
    setIsHeaderTableScrolled(true);
  };

  const bodyTableScrolled = () => {
    setIsBodyTableScrolled(true);
  };

  if (isHeaderTableScrolled === true) {
    if (T1Obj.current.scrollLeft !== 0) {
      T2Obj.current.scrollLeft = T1Obj.current.scrollLeft;
      setIsHeaderTableScrolled(false);
      setIsBodyTableScrolled(false);
    }
    /*  */
  }

  if (isBodyTableScrolled === true) {
    if (T2Obj.current.scrollLeft !== 0) {
      T1Obj.current.scrollLeft = T2Obj.current.scrollLeft;
      setIsBodyTableScrolled(false);
      setIsHeaderTableScrolled(false);
    }
    /* */
  }

  const downloadAttachment = (document) => {
    getFileDownloadUrlAction(
      { filePath: document.slidePath },
      {
        onSuccess: downloadFile,
        onError: downloadFileFail,
      }
    );
  };

  const downloadFile = (newFileUrl) => {
    window.location.href = newFileUrl;
  };

  const downloadFileFail = () => {
    Notify({ value: `download failed` });
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleClickAnalyzeSlide = () => {
    history.push({
      pathname: `/analysis`,
      state: { fromSlidebox: true, selectedDataToAnalyze, selectedDirDetails },
    });
  };

  const handleSelectSlide = (e, row, index) => {
    const { dirDataId, parentDir, caseOrFolder } = row;
    if (when === "analysis") {
      if (row.status === "pending") {
        Notify({ value: "Slide conversion is in progress" });
        return;
      }
    }
    setSelectedSlideDataToAnalyze(row);
    setContinueButtonDisabled(false);

    setIsTableRowSelected({ [index]: true });
    setSelectedDir({
      dirId: dirDataId,
      parentDir,
      dirType: caseOrFolder,
    });
    setRadioButtonChecked({ [index]: true });
    setSelectedDataToAnalyze(row);
  };

  React.useEffect(() => {
    setSelectedDirDetails({ dirDataName: selectedFolderName, description });
  }, [selectedFolderName, description]);

  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, [setScreenWidth, window.innerWidth]);

  React.useEffect(() => {
    getNotificationList();
  }, [listData, conversionComplete]);

  let slideFileNameSize =
    screenWidth > 1600 && screenWidth < 1900
      ? 18
      : screenWidth < 1400
      ? 11
      : screenWidth > 1900
      ? 25
      : 14;

  return showLoader ? (
    <div style={{ marginTop: "300px" }}>
      <Loader />
    </div>
  ) : sortedListData.length ? (
    <React.Fragment>
      <UserProfilePopup
        openModal={modalOpen}
        onClose={handleModalClose}
        userDetails={userDetails}
        isDashboard={true}
        isHeader={false}
        placement={placement}
        anchorEl={anchorEl}
      />
      <TableContainer
        elevation={0}
        component={Paper}
        style={{ position: "sticky", top: 0, zIndex: 2 }}
        onScroll={headerTableScrolled}
        ref={T1Obj}
      >
        <Table aria-label="simple table" className={classes.table} id="T1">
          <TableHead>
            <TableRow className="table-padding">
              {worklistData && (
                <React.Fragment>
                  <TableCell
                    ref={resizeRef0}
                    align="left"
                    width={"17%"}
                    style={{ backgroundColor: "#ECF0F4" }}
                  >
                    <ColumnResizeHandle resizeRef={resizeRef0} />
                  </TableCell>
                  <TableCell
                    ref={resizeRef1}
                    align="left"
                    width={"8%"}
                    style={{ backgroundColor: "#ECF0F4" }}
                  >
                    Mrn Number
                    <ColumnResizeHandle resizeRef={resizeRef1} />
                  </TableCell>
                  <TableCell
                    ref={resizeRef2}
                    align="left"
                    width={"13%"}
                    style={{ backgroundColor: "#ECF0F4" }}
                  >
                    Patient Name
                    <ColumnResizeHandle resizeRef={resizeRef2} />
                  </TableCell>
                  <TableCell
                    ref={resizeRef3}
                    align="left"
                    width={"8%"}
                    style={{ backgroundColor: "#ECF0F4" }}
                  >
                    Accession Number
                    <ColumnResizeHandle resizeRef={resizeRef3} />
                  </TableCell>
                </React.Fragment>
              )}
              <TableCell
                ref={resizeRef4}
                width={"13%"}
                style={{ backgroundColor: "#ECF0F4", paddingLeft: "30px" }}
                align="center"
              >
                {worklistData ? "Procedure" : "Name"}
                <span
                  onClick={() => {
                    setBlink(true);
                    setNameSorting(!nameSorting);
                    requestSort("dirDataName");
                    setTimeout(() => setBlink(false), 500);
                  }}
                ></span>
                <ColumnResizeHandle resizeRef={resizeRef4} />
              </TableCell>
              {!worklistData && (
                <TableCell
                  ref={resizeRef5}
                  align="left"
                  style={{ backgroundColor: "#ECF0F4" }}
                >
                  Size
                  <span
                    onClick={() => {
                      setBlink(true);
                      requestSort("dirDataSize");
                      setSizeSorting(!sizeSorting);
                      setTimeout(() => setBlink(false), 500);
                    }}
                  ></span>
                  <ColumnResizeHandle resizeRef={resizeRef5} />
                </TableCell>
              )}
              <TableCell
                ref={resizeRef6}
                align="left"
                style={{ backgroundColor: "#ECF0F4" }}
                width={worklistData ? "13%" : "13%"}
              >
                {worklistData ? "Procedure Date" : "Last Modified"}
                <span
                  onClick={() => {
                    setBlink(true);
                    setLastModifiedSorting(!lastModifiedSorting);
                    requestSort("lastModified");
                    setTimeout(() => setBlink(false), 500);
                  }}
                ></span>
                <ColumnResizeHandle resizeRef={resizeRef6} />
              </TableCell>
              <TableCell
                ref={resizeRef7}
                align="left"
                width={"13%"}
                style={{ backgroundColor: "#ECF0F4" }}
              >
                Labels
                <ColumnResizeHandle resizeRef={resizeRef7} />
              </TableCell>

              {selectedFolderType !== "case" && (
                <TableCell
                  ref={resizeRef8}
                  align="left"
                  width={"8%"}
                  style={{ backgroundColor: "#ECF0F4" }}
                >
                  Assigned To
                  <ColumnResizeHandle resizeRef={resizeRef8} />
                </TableCell>
              )}
              {worklistData && (
                <TableCell
                  ref={resizeRef9}
                  align="left"
                  width={"8%"}
                  style={{ backgroundColor: "#ECF0F4" }}
                >
                  Referred To
                  <ColumnResizeHandle resizeRef={resizeRef9} />
                </TableCell>
              )}
              {worklistData && (
                <TableCell
                  ref={resizeRef10}
                  align="center"
                  width={"15%"}
                  style={{ backgroundColor: "#ECF0F4" }}
                >
                  Status
                  <ColumnResizeHandle resizeRef={resizeRef10} />
                </TableCell>
              )}
              {selectedFolderType !== "root" && !worklistData && (
                <TableCell
                  ref={resizeRef11}
                  align="left"
                  style={{ backgroundColor: "#ECF0F4" }}
                >
                  {!importSlide ? "Stain Type" : "Analyze"}
                  <ColumnResizeHandle resizeRef={resizeRef11} />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Collapse
        expandIconPosition={
          expandIconPosition
        } /* style={{paddingLeft: '-2%'}} */
      >
        {filteredPatientNames.map((patientName, index) => {
          const details = patientDetails[index];

          return (
            <Panel header={patientName}>
              <TableContainer
                style={{ zIndex: 1 }}
                onScroll={bodyTableScrolled}
                ref={T2Obj}
              >
                <Table className={classes.table} id="T2">
                  <TableBody className={classes.tableRow}>
                    {details.map((row) => {
                      const tableRowSelected =
                        selectedList === row.dirDataId &&
                        selectedListType === row.caseOrFolder;
                      const className = tableRowSelected
                        ? "selected-table-cell-color"
                        : "";
                      return (
                        <TableRow
                          id="slideTableBodyRow"
                          key={row.listDataId}
                          onDoubleClick={() => {
                            setShowDetails();
                            setSelectedDetails(selectedListItem);
                          }}
                          onClick={(e) =>
                            row.caseOrFolder !== "slide"
                              ? onSelectedRow(e, row, index)
                              : handleSelectSlide(e, row, index)
                          }
                          className={`table-padding ${
                            selectedList === row.dirDataId &&
                            selectedListType === row.caseOrFolder
                              ? "selected-table-row-bg"
                              : ""
                          }`}
                        >
                          <TableCell
                            align="left"
                            className={className}
                            width={"13%"}
                          >
                            <span>
                              <img
                                src={
                                  priorityOptions.filter(
                                    (item) => item.value === row.priority
                                  )[0]
                                    ? priorityOptions.filter(
                                        (item) => item.value === row.priority
                                      )[0].img
                                    : StandardIcon
                                }
                                alt="#"
                                className={classes.actionIcon}
                                data-for={
                                  priorityOptions.filter(
                                    (item) => item.value === row.priority
                                  )[0] && "slidebox-tooltip"
                                }
                                data-tip="Priority"
                                style={{
                                  opacity: priorityOptions.filter(
                                    (item) => item.value === row.priority
                                  )[0]
                                    ? 1
                                    : 0,
                                }}
                                data-for={
                                  priorityOptions.filter(
                                    (item) => item.value === row.priority
                                  )[0] &&
                                  row.priority !== "standard" &&
                                  "slidebox-tooltip"
                                }
                                data-tip="Priority"
                                style={{
                                  opacity: priorityOptions.filter(
                                    (item) =>
                                      item.value === row.priority &&
                                      item.value !== "standard"
                                  )[0]
                                    ? 1
                                    : 0,
                                }}
                              />
                              <img
                                src={ScanStatusIcon}
                                alt="scan status"
                                className={classes.actionIcon}
                                data-for="slidebox-tooltip"
                                data-tip="Scan Status"
                              />
                              <img
                                src={QcStatusIcon}
                                alt="qc status"
                                className={classes.actionIcon}
                                data-for={
                                  row.review_status === "verified" &&
                                  "slidebox-tooltip"
                                }
                                data-tip="QC Status"
                                style={{
                                  opacity:
                                    row.review_status === "verified" ? 1 : 0,
                                }}
                              />
                              <img
                                src={NoteIcon}
                                alt="notes"
                                className={classes.actionIcon}
                                data-for={row.notes && "slidebox-tooltip"}
                                data-tip="Notes"
                                style={{ opacity: row.notes ? 1 : 0 }}
                              />
                              <img
                                src={ReportStatusIcon}
                                alt="report status"
                                className={classes.actionIcon}
                                data-for={
                                  row.review_status === "reported" &&
                                  "slidebox-tooltip"
                                }
                                data-tip="Report Status"
                                style={{
                                  opacity:
                                    row.review_status === "reported" ? 1 : 0,
                                }}
                              />
                            </span>
                          </TableCell>
                          <TableCell align="center" width={"7%"}>
                            <span
                              data-for="slidebox-tooltip"
                              data-tip={row.mrnNumber && row.mrnNumber}
                            >
                              {row.mrnNumber ? row.mrnNumber : "—"}
                            </span>
                          </TableCell>
                          <TableCell align="center" width={"8%"}>
                            <span
                              data-for="slidebox-tooltip"
                              data-tip={
                                row.patientFirstName &&
                                useNameFormat(
                                  row.patientFirstName,
                                  row.patientLastName
                                )
                              }
                            >
                              {row.patientFirstName
                                ? useNameFormat(
                                    row.patientFirstName,
                                    row.patientLastName
                                  )
                                : "—"}
                            </span>
                          </TableCell>
                          <TableCell align="center" width={"9%"}>
                            <span
                              data-for="slidebox-tooltip"
                              data-tip={
                                row.accessionNumber && row.accessionNumber
                              }
                            >
                              {row.accessionNumber ? row.accessionNumber : "—"}
                            </span>
                          </TableCell>
                          <TableCell align="center" width={"11%"}>
                            <span
                              data-for="slidebox-tooltip"
                              data-tip={row.dirDataName}
                            >
                              {row.dirDataName.length > 20
                                ? row.dirDataName.substring(0, 19) + "..."
                                : row.dirDataName}
                            </span>
                          </TableCell>
                          <TableCell align="center" width={"9%"}>
                            <span
                              data-for="slidebox-tooltip"
                              data-tip={
                                details && row.procedureDate
                                  ? useDateFormat({ date: row.procedureDate })
                                  : !worklistData
                                  ? useDateFormat({ date: row.lastModified })
                                  : "-"
                              }
                            >
                              {details
                                ? useDateFormat({ date: row.procedureDate })
                                : useDateFormat({ date: row.lastModified })}
                            </span>
                          </TableCell>
                          <TableCell align="center" width={"12%"}>
                            {row.labels &&
                              row.labels
                                .split(",")
                                .slice(0, 4)
                                .map((label, index) => (
                                  <div
                                    className="lable_tags"
                                    onClick={(e) => handleSearch(e, label)}
                                    key={index}
                                  >
                                    <span>{index < 3 ? label : "..."}</span>
                                  </div>
                                ))}
                          </TableCell>
                          {details && (
                            <TableCell align="center" width={"8%"}>
                              {row.caseOrFolder === "case" ? (
                                <React.Fragment>
                                  {row.assignTo && row.assignTo.length > 0 ? (
                                    <ImageTag
                                      onMouseOver={handleModalOpen(
                                        index,
                                        row.assignTo[0].first_name,
                                        row.assignTo[0].last_name,
                                        row.assignTo[0].user_email,
                                        row.assignTo[0].role_name,
                                        "",
                                        row.assignTo[0].user_image_src,
                                        "left-end"
                                      )}
                                      onMouseOut={handleModalClose}
                                      src={
                                        row.assignTo &&
                                        row.assignTo.length > 0 &&
                                        row.assignTo[0].user_image_src
                                          ? row.assignTo[0].user_image_src
                                          : DefaultAvatar
                                      }
                                      className="user-profile-image"
                                    />
                                  ) : (
                                    "—"
                                  )}
                                </React.Fragment>
                              ) : null}
                            </TableCell>
                          )}
                          
                          
                          {when === "analysis" &&
                            selectedFolderType !== "root" && (
                              <TableCell align="center">
                                {row.caseOrFolder === "slide" ? (
                                  <label class="radio-button-container">
                                    <input
                                      className="select-slide-radio"
                                      type="radio"
                                      name="select-slide"
                                      value={row.dirDataId}
                                      onChange={(e) => {
                                        handleSelectSlide(row);
                                      }}
                                    />
                                    <span
                                      className={`checkmark-radio-button ${
                                        radioButtonChecked[index] === true
                                          ? "checked"
                                          : "unchecked"
                                      }`}
                                    ></span>
                                  </label>
                                ) : null}
                              </TableCell>
                            )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <ToolTip
                  id="slidebox-tooltip"
                  place="top"
                  type="dark"
                  effect="solid"
                />
                {!importSlide && (
                  <ContextMenu
                    setAttachInput={setAttachInput}
                    domElement={"table-padding"}
                    showSharedPopup={showSharedPopup}
                    deleteDirItemPopup={deleteDirItemPopup}
                    renameSlideItem={renameSlideItem}
                    handleToggleMovePopup={handleToggleMovePopup}
                    setShowDetails={setShowDetails}
                    selectedListItem={selectedListItem}
                    showGroupsSelectPopup={showGroupsSelectPopup}
                    downloadSlide={downloadSlide}
                    openInViewer={() => handleShowInViewer()}
                    rightClickFrom={"list-table"}
                    isModal={isModal}
                    analyzeSlide={() => handleClickAnalyzeSlide()}
                    importSlide={importSlide}
                  />
                )}
                <AddAttachment
                  attachInput={attachInput}
                  setAttachInput={setAttachInput}
                />
                {showStatusModal && (
                  <React.Fragment>
                    <Overlay />
                    <div className="prompt priority-prompt">
                      <FadeIn duration="300ms">
                        <ModalHeader
                          img={InfoIcon}
                          alt="info icon"
                          title="Status Update"
                          onClick={() => setShowStatusModal(false)}
                          id="modalHeader"
                        />
                        <div className="modal_middle">
                          <p className="Confirmation text">
                            Are you sure you want to update the status of this
                            case?
                          </p>
                        </div>
                        <div className="prompt-bottom">
                          <SecondaryButton
                            label="Cancel"
                            onClick={() => setShowStatusModal(false)}
                          />
                          <PrimaryButton
                            label="Confirm"
                            onClick={() => {
                              updateCaseStatus({
                                dirId: dirId,
                                reviewStatus: status,
                              });
                              setShowStatusModal(false);
                              Notify({ value: "Status updated successfully" });
                            }}
                          />
                        </div>
                      </FadeIn>
                    </div>
                  </React.Fragment>
                )}
              </TableContainer>
            </Panel>
          );
        })}
      </Collapse>

      
    </React.Fragment>
  ) : (
    <EmptyMessage
      mainMessage={emptyMessage[isSearch ? "emptySearch" : selectedFolderType]}
      selectedFolderType={selectedFolderType}
    />
  );
};

const mapStateToProps = (state) => ({
  
  isPatientView: state.SlideBox.isPatientView,
  selectedListSlideData: state.SlideBox.selectedListSlideData,
  selectedFolderData: state.SlideBox.selectedFolderData,
  notificationList: state.Notifications.notificationList,
  conversionComplete: state.SlideBox.conversionComplete,
  worklistData: state.SlideBox.worklistColumnData,
  dxAccountDetails: state.Global.dxAccountDetails,
  ...state.SlideBox.selectedListData,
  ...state.SlideBox.selectedFolderData,
  loggedUserDetails: state.Global.loggedUserDetails,
});
const mapDispatchToProps = (dispatch) => ({
  breadcrumbAction: (payload) => dispatch(breadcrumbAction(payload)),
  getNotificationList: () => dispatch(getNotificationsAction()),
  updateCaseStatus: (payload) => dispatch(updateCaseStatusAction(payload)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SlideGridView)
);

