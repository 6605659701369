import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ToolTip from "react-tooltip";
import NoteIcon from "../../../../../assets/icons/note.svg";
import ReportIcon from "../../../../../assets/icons/report-status.svg";
import SlidesIcon from "../../../../../assets/icons/slides.svg";
import TotalContainers from "../../../../../assets/icons/TotalContainers.svg";
import TotalBlocks from "../../../../../assets/icons/TotalBlocks.svg";
import TotalSections from "../../../../../assets/icons/TotalSections.svg";
import TotalSlides from "../../../../../assets/icons/TotalSlides.svg";
import InfoEdit from "../../../../../assets/icons/InfoEdit.svg";
import Save from "../../../../../assets/icons/save.svg";
import Show from "../../../../../assets/icons/chevron-down.svg";
import Hide from "../../../../../assets/icons/chevron-up.svg";
import { SingleSelect } from "../../../../../components";
import "react-multi-carousel/lib/styles.css";
import { FeatureGate } from "react-feature-gate";
import { procedureNames } from "../../../../../../config/constants";
import {
  Notify,
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from "../../../../../components";
import { FadeIn } from "animate-components";

const CaseDetailsTab = ({
  selectedCaseInfo,
  editing,
  setEditing,
  toggleExpansible,
  setCurrentCase,
  selectedDetails,
  setSelectedDir,
  allAttachmentsInfo,
  selectedPatientInfo,
  dateSort,
  handleOpenHistoricalCase,
  memberDetails,
  updateCaseDetails,

  updateLabels,

  openCreateCase,
  setOpenCreateCase,
  setOpenFromCaseTab,
  setOpenFromSlideTab,
  openNotesSection,

  openLabelsSection,
  setOpenLabelsSection,
  getNotesHistory,
  getFileDownloadUrl,
  selectedDirId,
  notesHistoryData,
  addNotesFn,
  referredNotesData,
  getAllPhysicians,
  orderingPhysicians,
  worklistColumnData,
  proceduresList,
}) => {
  const caseDetailsRef = useRef([]);
  const toggleLabelsRef = useRef();

  const [updatedDetails, setUpdatedDetails] = useState(selectedDetails);
  const [labels, setLabels] = useState([]);
  const [openReferredNotesSection, setOpenReferredNotesSection] = useState(
    false
  );
  const [showAddNotesModal, setShowAddNotesModal] = useState(false);
  const [note, setNote] = useState("");
  const [referredNote, setReferredNote] = useState("");
  const [selectedPhysician, setSelectedPhysician] = useState({ id: '', name: '' });

  useEffect(() => {
    getAllPhysicians();
  }, [])
  const [showFullNote, setShowFullNote] = useState(false);

  useEffect(() => {
    setUpdatedDetails(selectedDetails);
    setEditing(false);
    if(selectedDetails.orderingPhysicianFirstName) {
      setSelectedPhysician({ id: selectedDetails.orderingPhysicianId, name: `${selectedDetails.orderingPhysicianFirstName} ${selectedDetails.orderingPhysicianMiddleName ? selectedDetails.orderingPhysicianMiddleName + ' ' : ''}${selectedDetails.orderingPhysicianLastName}`})
    } else {
      setSelectedPhysician({ id: '', name: '' })
    }
  }, [selectedDetails]);

  useEffect(() => {
    if (openLabelsSection) {
      setEditing(2);
      !caseDetailsRef.current[2].classList.contains("active") &&
        caseDetailsRef.current[2].click();
    } else {
      setEditing("");
      caseDetailsRef.current[2].classList.contains("active") &&
        caseDetailsRef.current[2].click();
    }
  }, [openLabelsSection]);

  useEffect(() => {
    if (updatedDetails.labels) {
      setLabels(updatedDetails.labels.split());
    } else {
      setLabels([]);
    }
  }, [updatedDetails, setLabels]);

  useEffect(() => {
    getNotesHistory({ caseId: selectedDirId });
  }, [selectedDirId]);

  const saveCaseDetails = () => {
    const {
      accessionNumber,
      procedureId,
      procedureDate,
      bodySite,
      orderingPhysicianId,
      priority,
      caseStatus,
      assignedTo,
      referredTo,
      caseHistory,
      microscopicDescription,
      diagnosis,
      notes,
      grossDescription,
    } = updatedDetails;
    updateCaseDetails({
      dirId: selectedDirId,
      caseDetails: {
        accessionNumber,
        // procedureId: procedureName.id,
        procedureId,
        procedureDate: moment(procedureDate).format("YYYY-MM-DD"),
        bodySite,
        orderingPhysicianId,
        priority,
        caseStatus,
        assignedToId: assignedTo,
        referredToId: referredTo,
        caseHistory,
        grossDescription,
        microscopicDescription,
        diagnosis,
        notes,
      },
    });

    setEditing("");
  };

  const handleSaveRefferedNote = () => {
    if (referredNote === "") {
      Notify({ value: "Note with empty description" });
      setEditing("");
    } else {
      addNotesFn({
        caseId: selectedDirId,
        notesDescription: referredNote,
        noteType: "referred",
      });
      setShowAddNotesModal(false);
      setEditing("");
    }
  };

  const activeMembers = memberDetails.filter((members) => {
    return members.status === "active";
  });

  const filteredAssignToMembers = activeMembers.map((members) => {
    const fullName = members.firstName + " " + members.lastName;
    const displayName =
      fullName.length > 18 ? fullName.substring(0, 18) + "..." : fullName;
    return {
      value: members.userID,
      label: displayName,
      userId: members.userID,
    };
  });

  const handleSaveLabels = () => {
    setUpdatedDetails({
      ...updatedDetails,
      labels: labels.join(),
    });
    updateLabels({
      entityId: selectedDirId,
      entityType: selectedDetails.caseOrFolder,
      labels: labels.map((element) => {
        return { value: element, label: element };
      }),
    });
    Notify({ value: "Labels updated." });
    setEditing("");
    setOpenLabelsSection(false);
  };

  const handleSelectChange = (selectedOption) => {
    selectedOption !== null
      ? setLabels(selectedOption.map((element) => element.value))
      : setLabels([]);
  };

  const downloadFile = (newFileUrl) => {
    window.open(newFileUrl, "_blank").focus();
  };

  const downloadFileFail = () => {
    Notify({ value: `download failed` });
  };

  const downloadAttachment = (document) => {
    getFileDownloadUrl(
      { filePath: document.document_path },
      {
        onSuccess: downloadFile,
        onError: downloadFileFail,
      }
    );
  };

  const handleSaveNotes = () => {
    if (note === "") {
      Notify({ value: "Note description is empty." });
      setEditing("");
      setNote("");
    } else {
      addNotesFn({
        caseId: selectedDirId,
        notesDescription: note,
        noteType: "other",
      });
      setEditing("");
      setNote("");
      setUpdatedDetails({
        ...updatedDetails,
        notes: "",
      });
    }
  };
  const descendingNotesDataArray = [...notesHistoryData].reverse();
  const descendingReferredNotesDataArray = [...referredNotesData].reverse();

  const getDateTime = (dateCreated) => {
    const dateTimeString = new Date(dateCreated).toLocaleDateString("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return dateTimeString;
  };

  return (
   <div className='details-section'>
    <div className='info-details-section'>
     <div className='case-details-cards'>
      <div className='card'>
       <div className='left-container'>
        <p className='card-label'>Containers</p>
        <p className='count'>{selectedCaseInfo.totalContainers}</p>
       </div>
       <img src={TotalContainers} alt='containers' />
      </div>
      <div className='card'>
       <div className='left-container'>
        <p className='card-label'>Blocks</p>
        <p className='count'>{selectedCaseInfo.totalBlocks}</p>
       </div>
       <img src={TotalBlocks} alt='blocks' />
      </div>
      <div className='card'>
       <div className='left-container'>
        <p className='card-label'>Sections</p>
        <p className='count'>{selectedCaseInfo.totalSections}</p>
       </div>
       <img src={TotalSections} alt='sections' />
      </div>
      <div className='card'>
       <div className='left-container'>
        <p className='card-label'>Slides</p>
        <p className='count'>{selectedCaseInfo.totalSlides}</p>
       </div>
       <img src={TotalSlides} alt='slides' />
      </div>
     </div>
     <div className='expansible-item'>
      <div
       onClick={toggleExpansible}
       className='expansible-header default_open heading-font'
       ref={(ref) => (caseDetailsRef.current[0] = ref)}
      >
       <span className='file-name'>Case Details</span>
       <span>
        <img
         src={editing === 0 ? Save : InfoEdit}
         alt=''
         data-test='case-save-btn'
         className='edit-icon'
         onClick={() => {
          if (editing === 0) {
           if (updatedDetails.accessionNumber === '') {
            Notify({
             value: 'Empty accession number detected, please enter accession number.',
            });
           } else if (
                   worklistColumnData &&
                   worklistColumnData.findIndex((item) => item.accessionNumber === updatedDetails.accessionNumber) !== -1 &&
                   worklistColumnData.filter((item) => item.accessionNumber === updatedDetails.accessionNumber)[0].slidebox_case_id !==
                    selectedDirId
                  ) {
                   Notify({
                    value: 'Duplicate accession number detected, please enter unique accession number.',
                   });
                  } else {
                   saveCaseDetails();
                  }
          } else {
           setEditing(0);
          }
          !caseDetailsRef.current[0].classList.contains('active') && caseDetailsRef.current[0].click();
         }}
        />
       </span>
      </div>
      <div className='expansible-content case-details'>
       <div className='case-detail-item'>
        <div className='slide-item-heading'>Case / Accession Number</div>
        <div className='item-value' id='slideUniqueIdContainer'>
         <input
          type='text'
          className='input-item'
          value={updatedDetails.accessionNumber}
          disabled={editing !== 0}
          data-test='accession-number'
          onChange={(e) =>
           setUpdatedDetails({
            ...updatedDetails,
            accessionNumber: e.target.value,
           })
          }
         />
        </div>
       </div>
       <div className='case-detail-item'>
        <div className='slide-item-heading'>Procedure Name</div>
        <div className='item-value' id='slideUniqueIdContainer'>
         {editing !== 0 ? (
          <input
           type='text'
           className='input-item'
           value={
            updatedDetails.procedureId && proceduresList.find((procedure) => +procedure.procedureId === +updatedDetails.procedureId)
             ? proceduresList.find((procedure) => +procedure.procedureId === +updatedDetails.procedureId).procedureName
             : ''
           }
           // value={updatedDetails.procedureId}
           disabled={editing !== 0}
          />
         ) : (
          <select
           className='input-item'
           name='procedure-name'
           id='case-status'
           data-test='procedure-name'
           value={updatedDetails.procedureId}
           disabled={editing !== 0}
           onChange={(e) => {
            let choice = true;
            if (
             proceduresList.filter((o) => o.procedureId == e.target.value).length > 0 &&
             proceduresList.filter((o) => o.procedureId == e.target.value)[0].status === 'inactive'
            ) {
             choice = confirm('The selected procedure is inactive, and will not be available further. Do you want to continue?');
            }
            if (choice) {
             setUpdatedDetails({
              ...updatedDetails,
              editCaseInfo: true,
              // procedureName: {
              //   id: e.target.value,
              //   label: e.target.selectedOptions[0].text,
              // }
              procedureId: e.target.value,
             });
            }
           }}
          >
           {proceduresList &&
            proceduresList.map((procedure) => (
             <option kay={procedure.procedureId} value={procedure.procedureId}>
              {procedure && procedure.procedureName}
             </option>
            ))}
          </select>
         )}
        </div>
       </div>
       <div className='case-detail-item'>
        <div className='slide-item-heading'>Procedure Date</div>
        <div className='item-value' id='slideUniqueIdContainer'>
         <input
          type='date'
          className='input-item'
          value={moment(updatedDetails.procedureDate).format('YYYY-MM-DD')}
          disabled={editing !== 0}
          onChange={(e) =>
           setUpdatedDetails({
            ...updatedDetails,
            editCaseInfo: true,
            procedureDate: e.target.value,
           })
          }
          onKeyDown={(e) => e.preventDefault()}
         />
        </div>
       </div>
       <div className='case-detail-item'>
        <div className='slide-item-heading'>Body Site</div>
        <div className='item-value' id='slideUniqueIdContainer'>
         <input
          type='text'
          className='input-item'
          value={updatedDetails.bodySite}
          disabled={editing !== 0}
          onChange={(e) =>
           setUpdatedDetails({
            ...updatedDetails,
            editCaseInfo: true,
            bodySite: e.target.value,
           })
          }
         />
        </div>
       </div>
       <div className='case-detail-item'>
        <div className='slide-item-heading'>Ordering Physician</div>
        <div className='item-value' id='slideUniqueIdContainer'>
         {editing !== 0 ? (
          <input type='text' className='input-item' value={selectedPhysician.name} disabled={editing !== 0} />
         ) : (
          <select
           className='input-item'
           name='ordering-physician'
           id='case-status'
           value={updatedDetails.orderingPhysicianId || selectedPhysician.id}
           disabled={editing !== 0}
           onChange={(e) => {
            let choice = true;
            if (
             orderingPhysicians.filter((o) => o.orderingPhysicianId == e.target.value).length > 0 &&
             orderingPhysicians.filter((o) => o.orderingPhysicianId == e.target.value)[0].status === 'inactive'
            ) {
             choice = confirm('The selected doctor is inactive, and will not be available further. Do you want to continue?');
            }
            if (choice) {
             setUpdatedDetails({
              ...updatedDetails,
              editCaseInfo: true,
              orderingPhysicianId: e.target.value,
             });
             setSelectedPhysician({
              id: e.target.value,
              name: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
             });
            }
           }}
          >
           <option value='' selected disabled></option>
           {orderingPhysicians &&
            orderingPhysicians.map((physician) => (
             <option value={physician.orderingPhysicianId}>{`${physician.orderingPhysicianFirstName} ${
              physician.orderingPhysicianMiddleName ? physician.orderingPhysicianMiddleName + ' ' : ''
             }${physician.orderingPhysicianLastName}`}</option>
            ))}
          </select>
         )}
        </div>
       </div>
      </div>
     </div>
    </div>
    {showAddNotesModal && (
     <React.Fragment>
      <Overlay />
      <div className='prompt priority-prompt' style={{ width: '30%' }}>
       <FadeIn duration='300ms'>
        <ModalHeader img={NoteIcon} alt='add notes' title='Add Notes' onClick={() => setShowAddNotesModal(false)} id='modalHeader' />
        <div className='modal_middle'>
         <form>
          <input
           type='text'
           style={{ width: '100%' }}
           placeholder='Enter Note Here'
           onChange={(e) => setReferredNote(e.target.value)}
          ></input>
         </form>
        </div>

        <div className='prompt-bottom'>
         <SecondaryButton label='Cancel' onClick={() => setShowAddNotesModal(false)} />
         <PrimaryButton label='Save' disabled={referredNote === ''} onClick={handleSaveRefferedNote} />
        </div>
       </FadeIn>
      </div>
     </React.Fragment>
    )}
    <div className='info-details-section'>
     <div className='expansible-item'>
      <div
       onClick={toggleExpansible}
       ref={(ref) => {
        if (ref) caseDetailsRef.current[1] = ref;
       }}
       className='expansible-header heading-font'
      >
       <span className='file-name'>Other Information</span>
       <span>
        <img
         src={editing === 1 ? Save : InfoEdit}
         alt=''
         className='edit-icon'
         onClick={() => {
          editing !== 1 ? setEditing(1) : saveCaseDetails();
          !caseDetailsRef.current[1].classList.contains('active') && caseDetailsRef.current[1].click();
         }}
        />
       </span>
      </div>
      <div class='expansible-content'>
       <div className='case-detail-item'>
        <div className='slide-item-heading'>Case Priority</div>
        <div className='item-value' id='slideUniqueIdContainer'>
         {editing !== 1 ? (
          <input
           type='text'
           className='input-item'
           value={updatedDetails.priority && updatedDetails.priority.charAt(0).toUpperCase() + updatedDetails.priority.slice(1)}
           disabled
          />
         ) : (
          <select
           name='priority'
           id='priority'
           value={updatedDetails.priority}
           disabled={editing !== 1}
           onChange={(e) =>
            setUpdatedDetails({
             ...updatedDetails,
             editCaseInfo: false,
             isEditOtherInformation: true,
             priority: e.target.value,
            })
           }
          >
           <option value='standard'>Standard</option>
           <option value='rush'>Rush</option>
           <option value='stat'>Stat</option>
          </select>
         )}
        </div>
       </div>
       <div className='case-detail-item'>
        <div className='slide-item-heading'>Case Status</div>
        <div className='item-value' id='slideUniqueIdContainer'>
         {editing !== 1 ? (
          <input
           type='text'
           className='input-item'
           value={updatedDetails.caseStatus && updatedDetails.caseStatus.charAt(0).toUpperCase() + updatedDetails.caseStatus.slice(1)}
           disabled
          />
         ) : (
          <select
           name='case-status'
           id='case-status'
           value={updatedDetails.caseStatus}
           disabled={editing !== 1}
           onChange={(e) =>
            setUpdatedDetails({
             ...updatedDetails,
             editCaseInfo: false,
             isEditOtherInformation: true,

             caseStatus: e.target.value,
            })
           }
          >
           <option value='ordered'>Ordered</option>
           <option value='cancelled'>Cancelled</option>
           <option value='verified'>Verified</option>
           <option value='transcribed'>Transcribed</option>
           <option value='prelim-report'>Prelim Report</option>
           <option value='reported'>Reported</option>
           <option value='arrived'>Arrived</option>
          </select>
         )}
        </div>
       </div>
       <div className='case-detail-item'>
        <div className='slide-item-heading'>Assigned To</div>
        <div className='item-value' id='slideUniqueIdContainer'>
         {editing !== 1 ? (
          <input
           type='text'
           className='input-item'
           value={updatedDetails.assignedTo === null ? ' ' : updatedDetails.assignedToFirstName + ' ' + updatedDetails.assignedToLastName}
           disabled
          />
         ) : (
          <select
           name='assigned to'
           id='assigned to'
           value={updatedDetails.assignedTo}
           disabled={editing !== 1}
           onChange={(e) => {
            setUpdatedDetails({
             ...updatedDetails,
             editCaseInfo: false,
             isEditOtherInformation: true,
             assignedTo: e.target.value,
            });
           }}
          >
           {filteredAssignToMembers.map((member) => (
            <option value={member.value}>{member.label}</option>
           ))}
          </select>
         )}
        </div>
       </div>
       <div className='case-detail-item'>
        <div className='slide-item-heading'>Referred To</div>
        <div className='item-value' id='slideUniqueIdContainer'>
         {editing !== 1 ? (
          <input
           type='text'
           className='input-item'
           value={updatedDetails.referredTo === null ? ' ' : updatedDetails.referredToFirstName + ' ' + updatedDetails.referredToLastName}
           disabled
          />
         ) : (
          <select
           name='referred to'
           id='referred to'
           value={updatedDetails.referredTo}
           disabled={editing !== 1}
           onFocus={() => {
            console.log(updatedDetails.referredTo);
            console.log(updatedDetails.assignedTo);
           }}
           onChange={(e) => {
            setUpdatedDetails({
             ...updatedDetails,
             editCaseInfo: false,
             isEditOtherInformation: true,
             referredTo: e.target.value,
            });
            {
             updatedDetails.referredTo === updatedDetails.assignedTo ? setShowAddNotesModal(false) : setShowAddNotesModal(true);
            }
           }}
          >
           {filteredAssignToMembers.map((member) => (
            <option value={member.value}>{member.label}</option>
           ))}
          </select>
         )}
        </div>
       </div>
      </div>
     </div>
    </div>
    <div className='info-details-section'>
     <div className='expansible-item'>
      <div
       onClick={toggleExpansible}
       className={`expansible-header ${openLabelsSection && 'default_open'} heading-font`}
       ref={(ref) => {
        if (ref) caseDetailsRef.current[2] = ref;
       }}
      >
       <span className='file-name'>Labels</span>
       <span>
        <img
         src={openLabelsSection ? Save : editing === 2 ? Save : InfoEdit}
         alt=''
         className='edit-icon'
         onClick={() => {
          editing !== 2 ? setEditing(2) : handleSaveLabels();
          !caseDetailsRef.current[2].classList.contains('active') && caseDetailsRef.current[2].click();
         }}
        />
       </span>
      </div>
      <div
       class='expansible-content'
       style={{ display: caseDetailsRef.current[2] && caseDetailsRef.current[2].classList.contains('active') ? 'block' : 'none' }}
      >
       <div className='item'>
        <div className='item-value fit-width' id='slideUniqueIdContainer'>
         <div className='information-row-content labels' style={{ height: '125px' }}>
          <b>
           {editing === 2 || openLabelsSection ? (
            <SingleSelect
             defaultValue={
              updatedDetails.labels && updatedDetails.labels
               ? updatedDetails.labels.split(',').map((label, index) => ({
                  value: label,
                  label: label,
                 }))
               : ''
             }
             options={[]}
             handleChange={handleSelectChange}
             placeholder={'Add labels'}
             customStyles={true}
             style={{ width: '100%' }}
            />
           ) : (
            <div style={{ display: 'block' }}>
             {updatedDetails.labels
              ? updatedDetails.labels.split(',').map((label, index) => (
                 <div className='lable_tags'>
                  <span key={index}>{label}</span>
                 </div>
                ))
              : ''}
            </div>
           )}
          </b>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>

    <div className='info-details-section'>
     <div className='expansible-item'>
      <div
       onClick={toggleExpansible}
       ref={(ref) => {
        if (ref) caseDetailsRef.current[3] = ref;
       }}
       className='expansible-header heading-font'
      >
       <span className='file-name'>Case History</span>
       <span>
        <img
         src={editing === 3 ? Save : InfoEdit}
         alt=''
         className='edit-icon'
         onClick={() => {
          editing !== 3 ? setEditing(3) : saveCaseDetails();
          !caseDetailsRef.current[3].classList.contains('active') && caseDetailsRef.current[3].click();
         }}
        />
       </span>
      </div>
      <div class='expansible-content'>
       <textarea
        name='case-history'
        id='case-history'
        className='case-history'
        rows='2'
        disabled={editing !== 3}
        onChange={(e) =>
         setUpdatedDetails({
          ...updatedDetails,
          caseHistory: e.target.value,
         })
        }
        value={updatedDetails.caseHistory ? updatedDetails.caseHistory : ''}
       >
        {updatedDetails.caseHistory ? updatedDetails.caseHistory : ''}
       </textarea>
      </div>
     </div>
    </div>

    <div className='info-details-section'>
     <div className='expansible-item'>
      <div
       onClick={toggleExpansible}
       ref={(ref) => {
        if (ref) caseDetailsRef.current[4] = ref;
       }}
       className='expansible-header heading-font'
      >
       <span className='file-name'>Gross Description</span>
       <span>
        <img
         src={editing === 4 ? Save : InfoEdit}
         alt=''
         className='edit-icon'
         onClick={() => {
          editing !== 4 ? setEditing(4) : saveCaseDetails();
          !caseDetailsRef.current[4].classList.contains('active') && caseDetailsRef.current[4].click();
         }}
        />
       </span>
      </div>
      <div class='expansible-content'>
       <textarea
        name='gross-description'
        id='gross-description'
        className='gross-description'
        rows='2'
        disabled={editing !== 4}
        onChange={(e) =>
         setUpdatedDetails({
          ...updatedDetails,
          grossDescription: e.target.value,
         })
        }
        value={updatedDetails.grossDescription ? updatedDetails.grossDescription : ''}
       >
        {updatedDetails.grossDescription ? updatedDetails.grossDescription : ''}
       </textarea>
      </div>
     </div>
    </div>
    <div className='info-details-section'>
     <div className='expansible-item'>
      <div
       onClick={toggleExpansible}
       ref={(ref) => {
        if (ref) caseDetailsRef.current[5] = ref;
       }}
       className='expansible-header heading-font'
      >
       <span className='file-name'>Microscopic Description</span>
       <span>
        <img
         src={editing === 5 ? Save : InfoEdit}
         alt=''
         className='edit-icon'
         onClick={() => {
          editing !== 5 ? setEditing(5) : saveCaseDetails();
          !caseDetailsRef.current[5].classList.contains('active') && caseDetailsRef.current[5].click();
         }}
        />
       </span>
      </div>
      <div class='expansible-content'>
       <textarea
        name='microscopic-description'
        id='microscopic-description'
        className='microscopic-description'
        rows='4'
        disabled={editing !== 5}
        onChange={(e) =>
         setUpdatedDetails({
          ...updatedDetails,
          microscopicDescription: e.target.value,
         })
        }
        value={updatedDetails.microscopicDescription ? updatedDetails.microscopicDescription : ''}
       >
        {updatedDetails.microscopicDescription ? updatedDetails.microscopicDescription : ''}
       </textarea>
      </div>
     </div>
    </div>
    <div className='info-details-section'>
     <FeatureGate feature='patient_level_information'>
      <div className='expansible-item'>
       <div
        onClick={toggleExpansible}
        ref={(ref) => {
         if (ref) caseDetailsRef.current[6] = ref;
        }}
        className='expansible-header heading-font'
       >
        <span className='file-name'>Historical Cases</span>
       </div>
       <div className='expansible-content historical-tab'>
        <div className='historical-cases-container'>
         <table>
          {selectedPatientInfo.historical_cases && (
           <tr>
            <th>Date</th>
            <th>Acc. Number</th>
            <th>Procedure Name</th>
            <th>Other Details</th>
           </tr>
          )}
          {selectedPatientInfo.historical_cases &&
           selectedPatientInfo.historical_cases.sort(dateSort).map((item, index) => {
            return (
             <tr key={index}>
              <td>{item.procedure_date ? moment(item.procedure_date).format('MMM D, YYYY h:m A') : '-'}</td>
              <td>
               <a onClick={() => handleOpenHistoricalCase(item.slidebox_case_id, setCurrentCase, setSelectedDir, selectedDetails)}>
                {item.case_accession_number || '-'}
               </a>
              </td>
              <td>{item.case_name || '-'}</td>
              <td className='action-icons'>
               <img src={NoteIcon} alt='Notes' data-for='case-details-tooltip' data-tip='Notes' />
               <img src={ReportIcon} alt='Report' data-for='case-details-tooltip' data-tip='Report' />
               <img
                src={SlidesIcon}
                alt='Slides'
                data-for='case-details-tooltip'
                data-tip='Slides'
                style={{ height: '5px', width: '5px' }}
               />
              </td>
             </tr>
            );
           })}
          <ToolTip id='case-details-tooltip' place='top' type='dark' effect='solid' />
         </table>
        </div>
       </div>
      </div>
     </FeatureGate>
    </div>
    <div className='info-details-section'>
     <div className='expansible-item'>
      <div
       onClick={toggleExpansible}
       ref={(ref) => {
        if (ref) caseDetailsRef.current[7] = ref;
       }}
       className='expansible-header heading-font'
      >
       <span className='file-name'>Diagnosis</span>
       <span>
        <img
         src={editing === 7 ? Save : InfoEdit}
         alt=''
         className='edit-icon'
         onClick={() => {
          editing !== 7 ? setEditing(7) : saveCaseDetails();
          !caseDetailsRef.current[7].classList.contains('active') && caseDetailsRef.current[7].click();
         }}
        />
       </span>
      </div>
      <div class='expansible-content'>
       <textarea
        name='diagnosis'
        id='diagnosis'
        className='diagnosis'
        rows='4'
        disabled={editing !== 7}
        onChange={(e) =>
         setUpdatedDetails({
          ...updatedDetails,
          diagnosis: e.target.value,
         })
        }
        value={updatedDetails.diagnosis ? updatedDetails.diagnosis : ''}
       >
        {updatedDetails.diagnosis ? updatedDetails.diagnosis : ''}
       </textarea>
      </div>
     </div>
    </div>
    <div className='info-details-section'>
     <div className='expansible-item'>
      <div
       onClick={toggleExpansible}
       className={`expansible-header ${openNotesSection && 'default_open'} heading-font`}
       ref={(ref) => {
        if (ref) caseDetailsRef.current[8] = ref;
       }}
      >
       <span className='file-name'>Notes</span>
       <span>
        <img
         src={editing === 8 ? Save : InfoEdit}
         alt=''
         className='edit-icon'
         onClick={() => {
          editing !== 8 ? setEditing(8) : handleSaveNotes();
          !caseDetailsRef.current[8].classList.contains('active') && caseDetailsRef.current[8].click();
         }}
        />
       </span>
      </div>
      <div className='expansible-content referred-notes'>
       {editing === 8 && (
        <textarea
         name='notes'
         id='notes'
         className='notes'
         rows='4'
         value={updatedDetails.notes ? updatedDetails.notes : ''}
         value={note}
         onChange={(e) => {
          setNote(e.target.value);
          setUpdatedDetails({
           ...updatedDetails,
           notes: e.target.value,
          });
         }}
        >
         {note}
        </textarea>
       )}

       <div className='historical-cases-container'>
        <table>
         <tr>
          <th style={{ width: '100px' }}>Notes Description</th>
          <th style={{ width: '50px' }}>Timestamp</th>
          <th style={{ width: '50px' }}>Created By</th>
         </tr>
         {descendingNotesDataArray.length > 0 &&
          descendingNotesDataArray.map((item) => {
           const dateTimeString = getDateTime(item.dateCreated);

           if (item.notesDescription && item.notesDescription.length > 100) {
            return (
             <tr>
              <td>
               {showFullNote === true ? item.notesDescription : item.notesDescription.substring(0, 99) + '...'}
               <img
                src={showFullNote === false ? Show : Hide}
                onClick={() => {
                 if (showFullNote === false) {
                  setShowFullNote(true);
                 } else {
                  setShowFullNote(false);
                 }
                }}
               ></img>
              </td>
              <td>{dateTimeString}</td>
              <td>{item.createdBy}</td>
             </tr>
            );
           } else {
            return (
             <tr>
              <td>{item.notesDescription}</td>
              <td>{dateTimeString}</td>
              <td>{item.createdBy}</td>
             </tr>
            );
           }
          })}
        </table>
       </div>
      </div>
     </div>
    </div>
    <div className='info-details-section'>
     <div className='expansible-item'>
      <div
       onClick={toggleExpansible}
       className={`expansible-header ${openReferredNotesSection && 'default_open'} heading-font`}
       ref={(ref) => {
        if (ref) caseDetailsRef.current[9] = ref;
       }}
      >
       <span className='file-name'>Referred Notes</span>
       <span>
        <img
         src={editing === 9 ? Save : InfoEdit}
         alt=''
         className='edit-icon'
         onClick={() => {
          editing !== 9 ? setEditing(9) : handleSaveRefferedNote();
          !caseDetailsRef.current[9].classList.contains('active') && caseDetailsRef.current[9].click();
         }}
        />
       </span>
      </div>
      <div class='expansible-content referred-notes'>
       {editing === 9 && (
        <textarea
         name='referred_notes'
         id='referred_notes'
         className='notes'
         rows='4'
         onChange={(e) => setReferredNote(e.target.value)}
        ></textarea>
       )}
       <div className='historical-cases-container'>
        <table>
         <tr>
          <th>Notes Description</th>
          <th>Timestamp</th>
          <th>Created by</th>
         </tr>
         {descendingReferredNotesDataArray.length > 0 &&
          descendingReferredNotesDataArray.map((item) => {
           const dateTimeString = getDateTime(item.dateCreated);
           return (
            <tr>
             <td>{item.notesDescription}</td>
             <td>{dateTimeString}</td>
             <td>{item.createdBy}</td>
            </tr>
           );
          })}
        </table>
       </div>
      </div>
     </div>
    </div>
    <div className='info-details-section'>
     <div className='expansible-item'>
      <div
       onClick={toggleExpansible}
       ref={(ref) => {
        if (ref) caseDetailsRef.current[10] = ref;
       }}
       className='expansible-header heading-font'
      >
       <span className='file-name'>Attachments</span>
      </div>
      <div class='expansible-content'>
       <a
        className='add-attachments'
        onClick={() => {
         setOpenCreateCase(!openCreateCase);
         setOpenFromCaseTab(true);
         setOpenFromSlideTab(false);
        }}
       >
        + Add Attachments
       </a>
       {allAttachmentsInfo &&
        allAttachmentsInfo.map((attachment) => {
         return (
          <a
           className='attachment-link item'
           onClick={() => {
            downloadAttachment(attachment);
           }}
          >
           {attachment.document_name}
          </a>
         );
        })}
      </div>
     </div>
    </div>
   </div>
  );
};

export default CaseDetailsTab;
