import React from "react";
import Done from "../../../../assets/icons/done.svg";
import "./CreateCaseTab.scss";
import { PrimaryButton, SecondaryButton } from "../../../../components";
import { connect } from "react-redux";
import {
	getAllPhysiciansAction,
	getAllProceduresAction,
} from "../../../adminDashboard/actions/adminDashboard-action";
import {
	createSlideBoxDirectory,
	checkAccessionNumberAction,
	openAllCasesTab,
} from "../../../slidebox/actions/slidebox-action";
import { useState, useEffect } from "react";
import Select from "react-select";
import { procedureNames } from "../../../../../config/constants";
import { useDateFormat } from "../../../adminDashboard/components/DashboardItems/useFormat";
import { FeatureGate } from "react-feature-gate";

const CreateCaseTab = ({
	patientDetails,
	onClose,
	setOpenTab,
	createNewCase,
	age,
	caseData,
	setCaseData,
	licenseTypeFeatures,
	caseDetails,
	setCaseDetails,
	defaultWorklist,
	checkAccessionNumber,
	isAccessionNumberUnique,
	orderingPhysicians,
	getAllPhysicians,
	getAllProcedures,
	proceduresList,
	openAllCasesTab,
}) => {
	const [selectedPhysician, setSelectedPhysician] = useState({
		value: "",
		label: "",
	});
	const [procedureName, setProcedureName] = useState(
		caseData.procedureName ? caseData.procedureName : ""
	);
	const [confidentialCase, setConfidentialCase] = useState("no");
	const [priority, setPriority] = useState(
		caseData.priority ? caseData.priority : ""
	);
	const [menuPlacement, setMenuPlacement] = useState("bottom");

	const [orderingPhysicianOptions, setOrderingPhysicianOptions] = useState(
		[]
	);
	const [proceduresOptions, setProceduresOptions] = useState([]);

	useEffect(() => {
		async function fetchPhysicians() {
			await getAllPhysicians();
			const physiciansArray =
				orderingPhysicians &&
				orderingPhysicians
					.filter((physician) => physician.status === "active")
					.map((physician) => {
						return {
							label: `${physician.orderingPhysicianFirstName} ${
								physician.orderingPhysicianMiddleName
									? physician.orderingPhysicianMiddleName +
									  " "
									: ""
							}${physician.orderingPhysicianLastName}`,
							value: physician.orderingPhysicianId,
						};
					});
			if (caseData.physicianId)
				setSelectedPhysician(
					physiciansArray.find(
						(physician) => physician.value === caseData.physicianId
					)
				);
			setOrderingPhysicianOptions(physiciansArray);
		}
		async function fetchProcedures() {
			await getAllProcedures();
			setProceduresOptions(
				proceduresList &&
					proceduresList
						.filter((procedure) => procedure.status === "active")
						.map((procedure) => {
							return {
								label: procedure.procedureName,
								value: procedure.procedureId,
							};
						})
			);
		}
		fetchPhysicians();
		fetchProcedures();
	}, []);

	const casePriorities = [
		{ value: "standard", label: "Standard" },
		{ value: "rush", label: "Rush" },
		{ value: "stat", label: "Stat" },
	];

	const handleChange = (e) => {
		const newInfo = { ...caseDetails };
		newInfo[e.target.name] = e.target.value;
		setCaseDetails(newInfo);
	};

	const handleProceduceNameChange = (e) => {
		const newInfo = { ...caseDetails };
		newInfo["procedureId"] = e.value;
		setCaseDetails(newInfo);

		setProcedureName({ label: e.label, id: e.value });
	};

	const handlePhysicianChange = (e) => {
		const newInfo = { ...caseDetails };
		newInfo["physicianId"] = e.value;
		setCaseDetails(newInfo);

		setSelectedPhysician({ label: e.label, value: e.value });
	};

	const handlePriorityChange = (e) => {
		const newInfo = { ...caseDetails };
		newInfo["priority"] = e.label;
		setCaseDetails(newInfo);
		setPriority(e.value);
	};

	const handleSaveCase = () => {
		createNewCase({
			parentDirType: "root",
			parentDir: 1,
			patientDetails: patientDetails,
			caseDetails: {
				...caseDetails,
				priority:
					caseDetails.priority.charAt(0).toLowerCase() +
					caseDetails.priority.slice(1),
			},
			defaultWorklistId:
				defaultWorklist &&
				defaultWorklist.length > 0 &&
				defaultWorklist.find(
					(worklist) => worklist.worklistName === "All Cases"
				),
		});
		openAllCasesTab();
		setCaseData({
			...caseDetails,
			physicianId: selectedPhysician.value,
			procedureName,
			confidentialCase,
			priority,
		});
		setOpenTab("slide");
	};

	const handleSecondaryButton = () => {
		setCaseData({
			...caseDetails,
			physicianId: selectedPhysician.value,
			procedureId: procedureName.id,
			confidentialCase,
			priority,
		});
		if (licenseTypeFeatures.patient_level_information) {
			setOpenTab("patient");
		} else {
			onClose();
		}
	};

	const isUnique = () => {
		checkAccessionNumber({ accessionNumber: caseDetails.accessionNumber });
	};

	return (
		<div className="create-case-tab">
			<div className="prompt-middle" style={{ paddingTop: "10px" }}>
				<FeatureGate feature="patient_level_information">
					<div className="patient-details">
						<span className="patient-name">
							{Object.keys(patientDetails).length > 0 &&
							patientDetails.middleName === undefined
								? patientDetails.firstName +
								  " " +
								  patientDetails.lastName +
								  "/" +
								  patientDetails.gender[0]
								: patientDetails.firstName +
								  " " +
								  patientDetails.middleName +
								  " " +
								  patientDetails.lastName +
								  "/" +
								  patientDetails.gender[0]}
						</span>
						<div className="mrn-dob-age">
							<span>MRN Number: {patientDetails.mrnNumber}</span>
							<span>
								D.O.B:{" "}
								{useDateFormat({ date: patientDetails.dob })}
							</span>
							<span>AGE: {age}</span>
						</div>
					</div>
				</FeatureGate>
				<div className="input-field accession-number">
					<label>
						Case/ Accession Number<sup className="required">*</sup>
					</label>
					<input
						type="text"
						onChange={(e) => handleChange(e)}
						onBlur={isUnique}
						name="accessionNumber"
						value={caseDetails.accessionNumber || ""}
						className={
							caseDetails.accessionNumber &&
							!isAccessionNumberUnique
								? "number-input-error"
								: "number-input"
						}
					/>
					{caseDetails.accessionNumber && isAccessionNumberUnique && (
						<img src={Done} alt="done" />
					)}
					<span className="error-message">
						{caseDetails.accessionNumber && !isAccessionNumberUnique
							? "Please enter a unique accession number"
							: ""}
					</span>
				</div>
				<div className="procedureName-date">
					<div className="input-field dob">
						<label>
							Procedure Date<sup className="required">*</sup>
						</label>
						<input
							type="date"
							name="procedureDate"
							onChange={(e) => handleChange(e)}
							onKeyDown={(e) => e.preventDefault()}
							value={caseDetails.procedureDate || ""}
						/>
						{caseDetails.procedureDate && (
							<img src={Done} alt="done" className="date" />
						)}
					</div>
					<div className="input-field">
						<label>
							Procedure Name<sup className="required">*</sup>
						</label>
						<div className="folder-select">
							<Select
								options={proceduresOptions}
								classname="stain-select"
								defaultValue={{ value: "", label: "" }}
								placeholder={
									caseDetails.procedureName ||
									procedureName.label
								}
								customStyles={true}
								style={{
									width: "100%",
									height: "30px",
									input: { fontSize: "12px" },
									marginTop: 10,
								}}
								styles={{
									placeholder: (provided, state) => {
										const placeholderStyle = {
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											width: "90%",
											color: "#000",
										};
										return {
											...provided,
											...placeholderStyle,
										};
									},
								}}
								onChange={handleProceduceNameChange}
								isSearchable={false}
								components={{
									IndicatorSeparator: () => null,
								}}
								name="procedureName"
								value={
									caseDetails.procedureId ||
									procedureName.value
								}
							/>
						</div>

						{caseDetails.procedureName && (
							<img src={Done} alt="search" className="date" />
						)}
					</div>
				</div>
				<div className="note-physician">
					<div className="input-field">
						<label>Notes</label>
						<input
							type="text"
							required
							name="notes"
							onChange={(e) => handleChange(e)}
							value={caseDetails.notes || ""}
						/>
						{caseDetails.notes && (
							<div className="icon-div">
								<img src={Done} alt="search" />
							</div>
						)}
					</div>
					<div className="input-field">
						<label>
							Ordering Physician<sup className="required">*</sup>
						</label>

						<div className="folder-select">
							<Select
								options={orderingPhysicianOptions}
								classname="stain-select"
								defaultValue={{ value: "", label: "" }}
								placeholder={selectedPhysician.label}
								customStyles={true}
								style={{
									width: "100%",
									height: "30px",
									input: { fontSize: "12px" },
									marginTop: 10,
								}}
								styles={{
									placeholder: (provided, state) => {
										const placeholderStyle = {
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											width: "90%",
											color: "#000",
										};
										return {
											...provided,
											...placeholderStyle,
										};
									},
								}}
								onChange={handlePhysicianChange}
								isSearchable={false}
								components={{
									IndicatorSeparator: () => null,
								}}
								name="physicianId"
								value={
									caseDetails.physicianId ||
									selectedPhysician.value
								}
							/>
						</div>
						{caseDetails.physicianId && (
							<div className="icon-div">
								<img src={Done} alt="search" />
							</div>
						)}
					</div>
				</div>
				<div className="note-physician">
					<div className="input-field">
						<label>
							Case Priority
							<sup className="required">*</sup>
						</label>
						<div className="folder-select">
							<Select
								options={casePriorities}
								placeholder={caseDetails.priority || priority}
								classname="stain-select"
								defaultValue={{ value: "", label: "" }}
								customStyles={true}
								onMenuOpen={() => {
									setMenuPlacement(
										window.innerHeight > 680
											? "bottom"
											: "top"
									);
								}}
								menuPlacement={menuPlacement}
								style={{
									width: "100%",
									height: "30px",
									input: { fontSize: "12px" },
									marginTop: 10,
								}}
								onChange={handlePriorityChange}
								isSearchable={false}
								components={{
									IndicatorSeparator: () => null,
								}}
								name="priority"
								value={caseDetails.priority}
							/>
						</div>

						{caseDetails.priority && (
							<img src={Done} alt="search" className="date" />
						)}
					</div>
				</div>
				<div className="bottom-section">
					<div className="confidential-case"></div>
					<div className="prompt-bottom">
						<SecondaryButton
							label={
								licenseTypeFeatures.patient_level_information
									? "Back"
									: "Cancel"
							}
							onClick={handleSecondaryButton}
						/>
						<PrimaryButton
							label="Create Case"
							extraClass="prompt-done"
							onClick={() => handleSaveCase()}
							disabled={
								!caseDetails.accessionNumber ||
								!caseDetails.procedureId ||
								!caseDetails.procedureDate ||
								!caseDetails.physicianId ||
								!caseDetails.priority ||
								!isAccessionNumberUnique
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	patientSearchDataList: state.SlideBox.patientDataList,
	defaultWorklist: state.SlideBox.systemWorklists,
	isAccessionNumberUnique: state.SlideBox.isAccessionNumberUnique,
	orderingPhysicians: state.Dashboard.orderingPhysicians,
	proceduresList: state.Dashboard.proceduresList,
});

const mapDispatchToProps = (dispatch) => ({
	createNewCase: (payload) => dispatch(createSlideBoxDirectory(payload)),
	checkAccessionNumber: (payload) =>
		dispatch(checkAccessionNumberAction(payload)),
	getAllPhysicians: () => dispatch(getAllPhysiciansAction()),
	getAllProcedures: () => dispatch(getAllProceduresAction()),
	openAllCasesTab: () => dispatch(openAllCasesTab()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCaseTab);
