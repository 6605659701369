import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ToolTip from "react-tooltip";
import BottomDragger from "../../../../assets/icons/bottom-dragger.svg";
import DragHandle from "../../../../assets/icons/dnd.svg";
import CaseDirInActiveIcon from "../../../../assets/icons/case-close.svg";
import WorklistContextMenu from "./WorklistContextMenu";
import ManageWorklistIcon from "../../../../assets/icons/manage-worklist.svg";
import ManageWorklist from "../../components/ManageWorklist";
import { FeatureGate } from "react-feature-gate";

const FolderTreeView = ({
  slideboxLeftSiderIsCollapse,
  myWorklists,
  systemWorklists,
  newTab,
  allTabs,
  setCurrentTab,
  roleName,
  getWorklistData,
  currentTab,
  setIsSearchTabActive,
  isOpenedFromGroups,
}) => {
  const [selectedWorklist, setSelectedWorklist] = useState("");
  const [contextWorklist, setContextWorklist] = useState({});
  const [contextOpen, setContextOpen] = useState(false);
  const [contextPos, setContextPos] = useState({});
  const [mouseDown, setMouseDown] = useState(false);
  const [isSystemWorklist, setIsSystemWorklist] = useState(false);
  const [openManageWorklistModal, setOpenManageWorklistModal] = useState(false);
  const [isAllCasesWorklist, setIsAllCasesWorklist] = useState(false);

  const resizeRef = useRef();
  const myWorklistRef = useRef();

  useEffect(() => {
    const handleMouseMove = (e) => {
      handleResize(e.movementY);
    };

    if (mouseDown) {
      window.addEventListener("mousemove", handleMouseMove);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [mouseDown, handleResize]);

  useEffect(() => {
    const handleMouseUp = () => setMouseDown(false);
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    const allCases = systemWorklists.find(
      (worklist) => worklist.worklistName === "All Cases"
    );
    if (allCases) {
      getWorklistData({ worklistId: allCases.worklistId });
    }
  }, [systemWorklists]);

  useEffect(() => {
    if (resizeRef.current && myWorklistRef.current) {
      const { height } = resizeRef.current.getBoundingClientRect();

      resizeRef.current.style.height = `${height}px`;
      myWorklistRef.current.style.height = `calc(${
        isOpenedFromGroups ? "81vh" : "100vh"
      } - 88px - ${height}px)`;
    }
  }, [systemWorklists]);

  const handleMouseDown = () => {
    setMouseDown(true);
  };

  const handleResize = (movementY) => {
    if (!resizeRef.current) return;
    const { height } = resizeRef.current.getBoundingClientRect();

    resizeRef.current.style.height = `${height + movementY}px`;
  };

  const handleContextOpen = (e, id, worklist, worklistType, worklistName) => {
    e.preventDefault();
    if (worklistName === "All Cases" || worklistName === "Today") {
      setIsAllCasesWorklist(true);
    } else {
      setIsAllCasesWorklist(false);
    }
    setContextPos({
      x:
        e.clientX + 200 > window.innerWidth
          ? window.innerWidth - 200
          : e.clientX - e.target.offsetLeft,
      y:
        e.clientY + 120 > window.innerHeight
          ? window.innerHeight - 120
          : e.clientY - e.target.offsetTop,
    });
    setContextOpen(true);
    setSelectedWorklist(id);
    setContextWorklist(worklist);
    setIsSystemWorklist(worklistType === "system" ? true : false);
  };

  const WorklistListItem = ({ worklist, index, provided, worklistType }) => {
    return (
      <div
        className="draggable-container"
        ref={provided.innerRef}
        {...provided.draggableProps}
        key={index}
      >
        <div
          className={`worklist-item caseDirectory-container ${currentTab &&
            currentTab.label === worklist.worklistName &&
            "active"}`}
          data-for="tree-view-tooltip"
          data-tip={worklist.worklistName}
          onContextMenu={(e) => {
            handleContextOpen(
              e,
              worklist.worklistId,
              worklist,
              worklistType,
              worklist.worklistName
            );
          }}
          onDoubleClick={(e) => {
            if (allTabs) {
              if (
                !allTabs.some((item) => item.worklistId === worklist.worklistId)
              ) {
                newTab({
                  worklistId: worklist.worklistId,
                  label: worklist.worklistName,
                  worklistColor: worklist.worklistColor,
                  worklistType,
                  finalTab: allTabs.length >= 8 && {
                    worklistId: allTabs.slice(-1)[0].worklistId,
                    worklistType: allTabs.slice(-1)[0].worklistType,
                  },
                });
              } else {
                setCurrentTab(
                  allTabs.filter(
                    (tab) => tab.worklistId === worklist.worklistId
                  )[0]
                );
              }
              getWorklistData({ worklistId: worklist.worklistId });
              setSelectedWorklist({ [worklist.worklistId]: true });
              setIsSearchTabActive(false);
            }
          }}
        >
          <img src={CaseDirInActiveIcon} className="case-dir" />
          {worklist.worklistName}
        </div>
        <img
          src={DragHandle}
          style={{ display: "none" }}
          alt="drag handle"
          {...provided.dragHandleProps}
        />
        {!mouseDown && <ToolTip
          id="tree-view-tooltip"
          place="right"
          type="dark"
          effect="solid"
        />}
      </div>
    );
  };

  return (
    <div className="resize-wrapper">
      <div className="system-worklist-resize-container" style={{ height: `${70 + systemWorklists.length * 32}px`, minHeight: '21vh', maxHeight: '60vh' }} ref={resizeRef}>
        <div
          className="folderTreeView-container myWorklists-container"
          data-test="folderTreeView"
          style={{
            overflowY: slideboxLeftSiderIsCollapse ? "scroll" : "hidden",
          }}
        >
          <DragDropContext
            onDragEnd={(...props) => {
              console.log(props);
            }}
          >
            <Droppable droppableId="droppable-1">
              {(provided, _) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <div>
                    {slideboxLeftSiderIsCollapse && (
                      <React.Fragment>
                        <div
                          className="rootDirectory-container active"
                          data-for="viewer-tooltip"
                          data-tip-disable={slideboxLeftSiderIsCollapse}
                          data-test="root-container"
                        >
                          {slideboxLeftSiderIsCollapse && "Worklists"}
                          {roleName === "System Administrator" && (
                            <img
                              src={ManageWorklistIcon}
                              width="20px"
                              height="20px"
                              onClick={() => setOpenManageWorklistModal(true)}
                              data-for="worklist-tooltip"
                              data-tip="Manage Worklist"
                            />
                          )}
                        </div>

                        {openManageWorklistModal && (
                          <ManageWorklist
                            setOpenManageWorklistModal={
                              setOpenManageWorklistModal
                            }
                          />
                        )}
                      </React.Fragment>
                    )}
                  </div>
                  {slideboxLeftSiderIsCollapse &&
                    systemWorklists &&
                    systemWorklists.map((worklist, index) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={"draggable-" + index}
                          index={index}
                        >
                          {(provided, _) => (
                            <div
                              data-for="tree-view-tooltip"
                              data-tip={worklist.worklistName}
                            >
                              <WorklistListItem
                                {...{
                                  provided,
                                  worklist,
                                  index,
                                  worklistType: "system",
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        {slideboxLeftSiderIsCollapse && (
          <div className="bottom-dragger" data-test="bottom-dragger" onMouseDown={() => handleMouseDown()}>
            <div className="bottom-border"></div>
            <img src={BottomDragger} alt="bottom dragger" />
          </div>
        )}
      </div>
      {slideboxLeftSiderIsCollapse && (
        <FeatureGate feature="user_worklist">
          <div
            className="myWorklists-container"
            ref={myWorklistRef}
            style={{ display: `${!slideboxLeftSiderIsCollapse ? "none" : ""}` }}
          >
            <DragDropContext
              onDragEnd={(...props) => {
                console.log(props);
              }}
            >
              <div className="caseDirectory-container my-worklist-header worklist-active">
                My Worklists (
                <span>
                  {slideboxLeftSiderIsCollapse && myWorklists
                    ? myWorklists.length
                    : ""}
                </span>
                )
              </div>
              <Droppable droppableId="droppable-2">
                {(provided, _) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {myWorklists &&
                      myWorklists.map((worklist, index) => (
                        <Draggable
                          key={index}
                          draggableId={"draggable-" + index}
                          index={index}
                        >
                          {(provided, _) => (
                            <div
                              data-for="tree-view-tooltip"
                              data-tip={worklist.worklistName}
                            >
                              <WorklistListItem
                                {...{
                                  provided,
                                  worklist,
                                  index,
                                  worklistType: "user",
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <WorklistContextMenu
              {...{
                x: contextPos && contextPos.x,
                y: contextPos && contextPos.y,
                contextOpen,
                setContextOpen,
                selectedWorklist,
                isSystemWorklist,
                isAllCasesWorklist,
                currentWorklistColor: contextWorklist.worklistColor,
                currentWorklistName: contextWorklist.worklistName,
              }}
            />
            {!mouseDown && <ToolTip
              id="worklist-tooltip"
              place="bottom"
              type="dark"
              effect="solid"
            />}
          </div>
        </FeatureGate>
      )}
    </div>
  );
};

export default FolderTreeView;
