export const activateOrDeactivateUserListStatusMethod = (
  userList,
  data,
  isRoleChange
) => {
  const userId = data.userId;
  const index = userList.findIndex(({ userID }) => userID === userId);
  if (~index) {
    if (isRoleChange) {
      userList[index].rolId = data.newRoleId;
      userList[index].roleName = data.newRoleName;
    } else {
      userList[index].status = data.status;
    }
  }
  return userList;
};
