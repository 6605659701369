const findFromPlainArray = (array, value) => {
    return array.indexOf(value) > -1;
}

const includesText = (value, searchText) => {
    return value.toLowerCase().includes(searchText.toLowerCase());
}

const filterArray = (array, filters) => {
    const filterKeys = Object.keys(filters);
    return array.filter(item => {
        return filterKeys.every(key => {
            // ignores an empty filter
            if (!filters[key].length) return true;
            return (typeof filters[key] === 'object' ?
                findFromPlainArray(filters[key], item[key]) :
                includesText(item[key], filters[key]));
        });
    });
}

export default filterArray;
