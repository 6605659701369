import React from "react";
import DashboardFilter from "./DashboardFilter";
import Can from "../../../../hoc/FullControllHoc/can";
import SearchIcon from "../../../../assets/icons/search-icon.svg";
import CloseIcon from "../../../../assets/icons/close-new.svg";
import { PrimaryButton } from "../../../../components";

const UserSearch = ({
  setAddNewUser,
  searchDashboardUserListFn,
  getUserListFn,
  ...rest
}) => {
  const [search, setSearch] = React.useState("");
  const [showCloseIcon, setShowCloseIcon] = React.useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setShowCloseIcon(true);
      searchDashboardUserListFn(event.target.value);
    }
  };

  const handleSearch = () => {
    setShowCloseIcon(true);
    searchDashboardUserListFn(search);
  };

  return (
    <div className="user-list-container">
      <div className="user-list-items">
        <Can
          task={"manage-members"}
          taskContainer="dashboard"
          edit={() => (
            <div className="btn-group">
              <PrimaryButton
                label="Invite People"
                onClick={() => setAddNewUser(true)}
                extraClass="invite-people-btn"
              />
            </div>
          )}
        />
      </div>
      <div className="filter-wrapper">
        <div className="filter-i-input">
          <img
            src={SearchIcon}
            className="searchIcon"
            alt="search"
            onClick={() => handleSearch()}
          />
          <input
            className="filter-input"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {showCloseIcon && (
            <span
              className="search-close-icon"
              onClick={() => {
                setShowCloseIcon(false);
                setSearch("");
                getUserListFn();
              }}
            >
              <img src={CloseIcon} alt="close" />
            </span>
          )}
        </div>
        <DashboardFilter {...{ ...rest }} />
      </div>
    </div>
  );
};

export default UserSearch;
