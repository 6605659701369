import React from "react";
import UserProfilePopup from "../../../../profile/components/UserProfilePopup";
import DefaultAvatar from "../../../../../assets/icons/default-avatar.svg";
import { useDateFormat } from "../../../../adminDashboard/components/DashboardItems/useFormat";
import ImageTag from ".././ImageTag";

const ActivityTab = ({ activityData }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userDetails, setUserDetails] = React.useState({});
  const [modalOpen, setModalOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleModalOpen = (
    
    firstName,
    lastName,
    userEmail,
    roleName,
    profile,
    userImageSrc,
    newPlacement
  ) => (event) => {
    setAnchorEl(event.currentTarget);
    setUserDetails({
      firstName,
      lastName,
      userEmail,
      roleName,
      profile,
      userImageSrc,
    });
    setModalOpen(!modalOpen);
    setPlacement(newPlacement);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="details-activity-container">
      {activityData.length > 0 ? (
        activityData.map((activity, index) => (
          <React.Fragment>
            {activity.slidebox_activity !== "review-slide" && (
              <div key={index} className="details-activity-block">
                <UserProfilePopup
                  openModal={modalOpen}
                  onClose={handleModalClose}
                  userDetails={userDetails}
                  isDashboard={true}
                  placement={placement}
                  anchorEl={anchorEl}
                />
                <span className="activity-date">
                  {useDateFormat({ date: activity.activity_end_at })}
                </span>
                <div className="activity-details">
                  <ImageTag
                    onMouseOver={handleModalOpen(
                      index,
                      activity.first_name,
                      activity.last_name,
                      activity.userEmail,
                      activity.userRole,
                      "",
                      activity.userImageSrc,
                      "left-end"
                    )}
                    onMouseOut={handleModalClose}
                    src={
                      activity.userImageSrc
                        ? activity.userImageSrc
                        : DefaultAvatar
                    }
                    className="user-profile-image"
                  />
                  {userDetails.userID === activity.user_id ? (
                    <b>You </b>
                  ) : (
                    <b>
                      {activity.first_name} {activity.last_name}{" "}
                    </b>
                  )}
                  {activity.entity_type === "status"
                    ? `updated the case status to ${activity.slidebox_activity}`
                    : activity.slidebox_activity === "create-case"
                    ? "created this case"
                    : activity.slidebox_activity === "create-folder"
                    ? "created this folder"
                    : activity.slidebox_activity === "rename-case"
                    ? "renamed this case"
                    : activity.slidebox_activity === "rename-folder"
                    ? "renamed this folder"
                    : activity.slidebox_activity === "assign-case" &&
                      "assigned the case to"}
                  {activity.other_user_data ? (
                    <b>
                      {" "}
                      {activity.other_user_data.first_name}{" "}
                      {activity.other_user_data.last_name}
                    </b>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        ))
      ) : (
        <span className="activity-empty">No activity record found</span>
      )}
    </div>
  );
};

export default ActivityTab;
