import React from 'react';
import SlideBoxUpload from '../../../slidebox/SlideBox-upload';

const UploadAttachments = ({ onClose, patientDetails, caseData, age }) => {
  return (
    <SlideBoxUpload
      onClose={onClose}
      patientDetails={patientDetails}
      caseData={caseData}
      age={age}
    />
  );
};

export default UploadAttachments;