import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import altIconAvatar from '../../../assets/icons/default-avatar.svg';
import ThreeDotsIcon from '../../../assets/icons/more-icon.svg';
import { Notify } from '../../../components';
import Fade from '../../../components/Animations/Fade';
import UserProfilePopup from '../../../pages/profile/components/UserProfilePopup';
import axios from '../../../utils/axiosInstance';
import { useNameFormat } from '../../adminDashboard/components/DashboardItems/useFormat';
import { removeMember, setAdmin } from '../actions/groups-action';
import GroupLeavePopup from './group-leave-popup';

const GroupDetailsUser = ({
  member,
  group,
  onGroupRefresh,
  loggedUser,
  popupActive,
  onOptionClick,
  dispatch,
  changeActiveTab,
}) => {
  const isCreator = group.creator.id === loggedUser;
  const self = member.id === loggedUser;
  const [openPopup, setOpenPopup] = useState(false);
  const [showLeave, setShowLeave] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();

  const handleModalOpen = (
    firstName,
    lastName,
    userEmail,
    roleName,
    userImageSrc,
    newPlacement
  ) => (event) => {
    setUserDetails({ firstName, lastName, userEmail, roleName, userImageSrc });
    setAnchorEl(event.currentTarget);

    setModalOpen(!modalOpen);

    setPlacement(newPlacement);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const onMakeGroupAdmin = async () => {
    setOpenPopup(false);
    const { status } = await axios
      .put(
        '/api/groups/setAdmin',
        { userId: member.id, tumorBoardId: group.id },
        { withCredentials: true }
      )
      .catch(console.log);
    if (status === 200) {
      dispatch(setAdmin(member.id, true));
      Notify({ value: 'Set admin successfully.' });
    }
  };
  const onRevokeGroupAdmin = async () => {
    setOpenPopup(false);
    const { status } = await axios
      .put(
        '/api/groups/removeAdmin',
        { userId: member.id, tumorBoardId: group.id },
        { withCredentials: true }
      )
      .catch(console.log);
    if (status === 200) {
      dispatch(setAdmin(member.id, false));
      Notify({ value: 'Revoke admin successfully.' });
    }
  };
  const onRemoveFromGroup = async () => {
    setOpenPopup(false);
    const { status } = await axios
      .delete(`/api/groups/removeMember/${group.id}/${member.id}`, {
        withCredentials: true,
      })
      .catch(console.log);
    if (status === 200) {
      onGroupRefresh();
      dispatch(removeMember(member.id));
      Notify({ value: 'Removed member successfully.' });
    }
  };
  return (
    <Fragment>
      <div className="user">
        <div className="user-avatar">
          <img
            src={
              member.userImageSrc === null ? altIconAvatar : member.userImageSrc
            }
            alt="user"
            height="40"
            width="40"
            style={{ borderRadius: '100%', cursor: 'pointer' }}
            onMouseOver={handleModalOpen(
              member.firstName,
              member.surname,
              member.email,
              member.userRole,
              member.userImageSrc,
              'left-end'
            )}
            onMouseOut={handleModalClose}
          />
        </div>
        <h3 className="user-name">{useNameFormat(member.firstName, member.surname)}</h3>
        <div className="user-info">
          <button className="three-dots-btn" onClick={onOptionClick}>
            {isCreator || self ? (
              <img src={ThreeDotsIcon} alt="user options" />
            ) : null}
          </button>
        </div>
        {popupActive && (
          <div className="options user_options">
            <ul>
              {isCreator && !member.admin && !self && (
                <li>
                  <a onClick={onMakeGroupAdmin} ispopup="true">
                    Make Group Admin
                  </a>
                </li>
              )}
              {isCreator && member.admin && !self && (
                <li>
                  <a
                    onClick={onRevokeGroupAdmin}
                    style={{ color: 'red' }}
                    ispopup="true"
                  >
                    Revoke Admin
                  </a>
                </li>
              )}
              {((isCreator && !self) ||
                (group.admin && !member.admin && !self)) && (
                  <li>
                    <a
                      onClick={onRemoveFromGroup}
                      style={{ color: 'red' }}
                      ispopup="true"
                    >
                      Remove from Group
                  </a>
                  </li>
                )}
              {self && (
                <li>
                  <a
                    onClick={() => setShowLeave(true)}
                    style={{ color: 'red' }}
                    ispopup="true"
                  >
                    Leave Group
                  </a>
                </li>
              )}
            </ul>
          </div>
        )}
        <UserProfilePopup
          openModal={modalOpen}
          onClose={handleModalClose}
          userDetails={userDetails}
          isDashboard={false}
          isHeader={false}
          placement={placement}
          anchorEl={anchorEl}
        />
      </div>
      <Fade show={showLeave} duration="300ms">
        <div id="create-board-overlay">
          <GroupLeavePopup
            close={() => setShowLeave(false)}
            changeActiveTab={changeActiveTab}
          />
        </div>
      </Fade>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  const { loggedUserDetails } = state.Global;
  return {
    loggedUser: loggedUserDetails.userID,
  };
};

export default connect(mapStateToProps)(GroupDetailsUser);
