import React, { useEffect, useState } from "react";
import AnalysisSection from "./AnalysisSection/AnalysisSection";
import RunAnalysisSection from "./AnalysisSection/RunAnalysisSection";
import "../AiAnalytics.scss";
import AnalysisProgressBar from "./ProgressBar/AnalysisProgressBar";
import { connect } from "react-redux";
import "../../slidebox/SlideBox.scss";
import "../TableSlide.scss";
import PrimaryButton from "./../../../components/Buttons/primaryButton";
import {
  loadSlideboxDirDataList,
  setSelectedDirectory,
} from "../../slidebox/actions/slidebox-action";
import HighlightAreaStep from "./AnalysisSection/HighlightAreaStep";
import { withRouter } from "react-router";
import {
  getPreviousRunsData,
  runAnalysisAction,
} from "../actions/aiAnalytics-action";
import { Notify } from "../../../components";
import SlideboxDataSection from "../../slidebox/components/SlideBoxDataSection";

const AnalysisPage = ({
  isModal,
  setOpenSlidebox,
  isFolderSelected,
  setIsFolderSelected,
  loadSlideboxDirDataList,
  setSelectedDirectory,
  showSidebar,
  history,
  runAnalysisAction,
  runAnalysisPending,
  getPreviousRuns,
  selectionJson,
  annotationData,
  scaleRatio,
  scaleUnit,
  location,
  selectedFolderData,
}) => {
  const [progressState, setProgressState] = useState({
    importSlide: true,
    selectAnalysisType: false,
    highlightAreas: false,
    runAnalysis: false,
    viewResults: false,
  });
  const [
    selectedSlideDataToAnalyze,
    setSelectedSlideDataToAnalyze,
  ] = useState();

  const [selectedAlgorithm, setSelectedAlgorithm] = useState(null);
  const [selectedAlgorithmId, setSelectedAlgorithmId] = useState(null);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [annotationCoordinates, setAnnotationCoordinates] = React.useState(
    null
  );

  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [fromSlidebox, setFromSlidebox] = useState(false);

  const [selectedDirDetails, setSelectedDirDetails] = useState({}); //For Dir name and description

  useEffect(() => {
    //console.log(selectedSlideDataToAnalyze);
  }, [selectedSlideDataToAnalyze]);

  useEffect(() => {
    if (location.state !== undefined) {
      const {
        fromSlidebox,
        selectedDataToAnalyze,
        selectedDirDetails,
      } = location.state;
      setFromSlidebox(fromSlidebox);
      setSelectedSlideDataToAnalyze(selectedDataToAnalyze);
      setSelectedDirDetails(selectedDirDetails);
      setProgressState({ ...progressState, selectAnalysisType: true });
    }
  }, []);
  const {
    initialState,
    importSlide,
    selectAnalysisType,
    highlightAreas,
    runAnalysis,
  } = progressState;

  const handleClick = (event) => {
    if (importSlide) {
      setProgressState({ ...progressState, selectAnalysisType: true });
      setContinueButtonDisabled(true);
    }
    if (selectAnalysisType) {
      if (selectedAlgorithm === "Nuclei Segmentation") {
        setProgressState({ ...progressState, highlightAreas: true });
      } else {
        setProgressState({
          ...progressState,
          runAnalysis: true,
          highlightAreas: true,
        });
      }

      setContinueButtonDisabled(false);
    }
    if (highlightAreas && selectedAlgorithm === "Nuclei Segmentation") {
      setProgressState({ ...progressState, runAnalysis: true });
    }
    if (runAnalysis) {
      const data = {
        slideId: selectedSlideDataToAnalyze.slidebox_slide_id,
        caseDirId: selectedSlideDataToAnalyze.parentDir,
        selectionJson: selectionJson.length > 0 ? selectionJson[0] : null,
        algorithm: selectedAlgorithm === "Nuclei Segmentation" ? 1 : 2,
        scaleRatio,
        scaleUnit,
      };
      setProgressState({ ...progressState, viewResults: true });
      runAnalysisAction(data, { onSuccess, onError });
    }
  };

  const onSuccess = () => {
    getPreviousRuns();
    history.push("/analysis-previous-runs");
  };

  const onError = () => {
    Notify({
      value: "Error in run analysis",
    });
  };

  useEffect(() => {
    setSelectedAreaName(
      annotationData.length !== 0 ? annotationData[0].text : ""
    );
  }, [annotationData]);

  useEffect(() => {
    loadSlideboxDirDataList({
      dirId: 1,
      dirType: "root",
    });

    setSelectedDirectory({
      dirId: 1,
      listId: 1,
      parentDir: null,
      dirType: "root",
    });
  }, []);
  return (
    <React.Fragment>
      <div className="analysis-page-main-container">
        <AnalysisProgressBar
          progressState={progressState}
          setProgressState={setProgressState}
          loadSlideboxDirDataList={loadSlideboxDirDataList}
          setSelectedDirectory={setSelectedDirectory}
          selectedAlgorithm={selectedAlgorithm}
          setSelectedAlgorithm={setSelectedAlgorithm}
          setFromSlidebox={setFromSlidebox}
          setContinueButtonDisabled={setContinueButtonDisabled}
          setSelectedAlgorithm={setSelectedAlgorithm}
        />
        {importSlide && !selectAnalysisType && !fromSlidebox && (
          <div
            className={
              showSidebar
                ? "analysis-slide-table expand"
                : "analysis-slide-table"
            }
          >
            <SlideboxDataSection
              isModal={isModal}
              setOpenSlidebox={setOpenSlidebox}
              isFolderSelected={isFolderSelected}
              setIsFolderSelected={setIsFolderSelected}
              setSelectedSlideDataToAnalyze={setSelectedSlideDataToAnalyze}
              continueButtonDisabled={continueButtonDisabled}
              setContinueButtonDisabled={setContinueButtonDisabled}
              setSelectedDirectory={setSelectedDirectory}
              selectedDirDetails={selectedDirDetails}
              setSelectedDirDetails={setSelectedDirDetails}
              when="analysis"
              importSlide={importSlide}
              selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
            />
          </div>
        )}

        <PrimaryButton
          label={
            runAnalysis
              ? runAnalysisPending
                ? "Running..."
                : "Run Analysis"
              : "Continue"
          }
          onClick={handleClick}
          disabled={continueButtonDisabled}
          extraClass="continue_button"
        />

        {selectAnalysisType && !highlightAreas && (
          <AnalysisSection
            state={progressState}
            selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
            selectAnalysisType={selectAnalysisType}
            highlightAreas={highlightAreas}
            importSlide={importSlide}
            selectedAlgorithm={selectedAlgorithm}
            setSelectedAlgorithm={setSelectedAlgorithm}
            setContinueButtonDisabled={setContinueButtonDisabled}
            selectedDirDetails={selectedDirDetails}
            selectedFolderData={selectedFolderData}
            fromSlidebox={fromSlidebox}
          />
        )}
        {highlightAreas &&
          !runAnalysis &&
          selectedAlgorithm === "Nuclei Segmentation" && (
            <HighlightAreaStep
              state={progressState}
              selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
              selectAnalysisType={selectAnalysisType}
              highlightAreas={highlightAreas}
              importSlide={importSlide}
              selectedAlgorithm={selectedAlgorithm}
              setSelectedAlgorithm={setSelectedAlgorithm}
              setContinueButtonDisabled={setContinueButtonDisabled}
              annotationCoordinates={annotationCoordinates}
              setAnnotationCoordinates={setAnnotationCoordinates}
              runAnalysis={runAnalysis}
              selectedAreaName={selectedAreaName}
              setSelectedAreaName={setSelectedAreaName}
            />
          )}
        {runAnalysis && (
          <RunAnalysisSection
            state={progressState}
            selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
            selectAnalysisType={selectAnalysisType}
            highlightAreas={highlightAreas}
            importSlide={importSlide}
            selectedAlgorithm={selectedAlgorithm}
            setSelectedAlgorithm={setSelectedAlgorithm}
            setContinueButtonDisabled={setContinueButtonDisabled}
            setProgressState={setProgressState}
            annotationCoordinates={annotationCoordinates}
            runAnalysis={runAnalysis}
            annotationData={annotationData}
            selectedAreaName={selectedAreaName}
            setSelectedAreaName={setSelectedAreaName}
            selectedDirDetails={selectedDirDetails}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state.SlideBox.selectedListData,
  showSidebar: state.Global.showSidebar,
  runAnalysisPending: state.AIAnalytics.runAnalysisPending,
  selectionJson: state.Viewer.roiData,
  annotationData: state.Viewer.annotationData,
  scaleRatio: state.Viewer.scaleRatio,
  scaleUnit: state.Viewer.scaleUnit,
  selectedFolderData: state.SlideBox.selectedFolderData,
});

const mapDispatchToProps = (dispatch) => ({
  loadSlideboxDirDataList: (payload) =>
    dispatch(loadSlideboxDirDataList(payload)),
  setSelectedDirectory: (payload) => dispatch(setSelectedDirectory(payload)),
  runAnalysisAction: (payload, callbackFn) =>
    dispatch(runAnalysisAction(payload, callbackFn)),
  getPreviousRuns: () => dispatch(getPreviousRunsData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AnalysisPage));
