import React, { Component } from "react";
import HeaderOptions from "./header-options";
import HeaderTopLinks from "./top-links";
import HeaderLogo from "./logo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import MenuIcon from "../../assets/icons/main-menu.svg";
import DefaultAvatar from "../../assets/icons/default-avatar.svg";
import UserProfilePopup from "../../pages/profile/components/UserProfilePopup";
// import LicenceExpire from './licenseExpire';
import { useNameFormat } from "../../pages/adminDashboard/components/DashboardItems/useFormat";

class Header extends Component {
  state = {
    showOptions: false,
    postIt: false,
    location: "",
    hover: false,
    navOpen: false,
    showSubmitMenu: false,
    showQuorumModal: false,
    showSlideboxModal: false,
    collapseSidebar: true,
    modalOpen: false,
    anchorEl: null,
    placement: "",
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  setSlidebarWrapperRef = (node) => {
    this.slidebarWrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.id !== "profile-nav-image"
    ) {
      this.setState({ navOpen: !this.state.navOpen, showOptions: false });
    }
    if (
      this.slidebarWrapperRef &&
      !this.slidebarWrapperRef.contains(event.target)
    ) {
      this.setState({ navOpen: false });
    }
  };

  toggleOptions = (e) => {
    this.setState({ showOptions: !this.state.showOptions });
  };

  toggleSidebar = () => {
    this.props.toggleSidebar(!this.props.collapseSidebar);
    this.setState({ collapseSidebar: !this.props.collapseSidebar });
  };

  topToPage = (e) => {
    e.preventDefault();
    if (this.props.isLoggedUser) {
      this.props.history.push("/");
    } else {
      //window.location.href = "/login";
    }
  };

  handleModalOpen = (e) => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      anchorEl: e.currentTarget,
    });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    let { showOptions } = this.state;
    const {
      userData: { firstName, lastName, userImageSrc, userName },
      isLoggedUser,
      dxAccountDetails,
    } = this.props;

    const fullname = useNameFormat(firstName, lastName);

    return (
      <React.Fragment>
        <div
          className={
            isLoggedUser ? "header_container" : "header_container visitor-view"
          }
        >
          <div className={isLoggedUser ? "header" : "header logged_out"}>
            {isLoggedUser && (
              <div className="sidebar-button">
                <img onClick={this.toggleSidebar} src={MenuIcon} alt="menu" />
              </div>
            )}
            <HeaderLogo topToPage={this.topToPage} />
            {/* <LicenceExpire /> */}
            {isLoggedUser && (
              <div className="header_right">
                <HeaderTopLinks
                  un={this.props.un}
                  showOptions={showOptions}
                  toggleOptions={this.toggleOptions}
                  userProfileImage={userImageSrc}
                />
              </div>
            )}

            {showOptions ? (
              <div className="sp_options options" ref={this.setWrapperRef}>
                <div className="userDropDown">
                  <div className="userImgCon">
                    <img
                      src={userImageSrc !== null ? userImageSrc : DefaultAvatar}
                      alt="avatar"
                      className="sp_img"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = { DefaultAvatar };
                      }}
                      onMouseOver={this.handleModalOpen}
                      onMouseOut={this.handleModalClose}
                    />
                  </div>
                  <div className="usernames">
                    <h4>{fullname}</h4>
                    <h5>@{userName ? userName : firstName}</h5>
                  </div>
                </div>
                {/* <li className="o_li">
                  <a className="o_a">Edit Profile</a>
                </li> */}
                <HeaderOptions
                  toggleOptions={this.toggleOptions}
                  user={firstName}
                  userData={this.props.userData}
                />
              </div>
            ) : null}

            <UserProfilePopup
              openModal={this.state.modalOpen}
              onClose={this.handleModalClose}
              userDetails={this.props.userData}
              isHeader={true}
              isDashboardTable={false}
              anchorEl={this.state.anchorEl}
              extraClass="profile-popup"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dxAccountDetails: state.Global.dxAccountDetails,
});

export default connect(mapStateToProps)(withRouter(Header));
export { Header as PureHeader };
