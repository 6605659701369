import React, { Component, Fragment } from "react";
import { FadeIn } from "animate-components";
import PropTypes from "prop-types";
import d from "../../utils/dom";
import { Overlay, PrimaryButton, SecondaryButton } from "../index";

import ClosePopupIcon from "../../assets/icons/close-new.svg";
import DeleteIcon from "../../assets/icons/delete-icon-new.svg";
import AnalysisStepIcon from "../../assets/icons/analysis-step.svg";

export default class Prompt extends Component {
  componentDidMount = () => {
    new d(".prompt-done").focus();
  };

  close = (e) => {
    e.preventDefault();
    this.props.back();
  };

  render() {
    let {
      title,
      content,
      actionText,
      action,
      blurred,
      promptType,
      customClass,
      onClose,
      isStorageExceed,
    } = this.props;
    let style = {
      marginBottom: blurred ? 9 : 10,
    };

    return (
      <Fragment>
        <Overlay />

        <div
          className="prompt"
          style={{ width: isStorageExceed ? "60%" : "auto" }}
        >
          <FadeIn duration="300ms">
            <div className="prompt-top">
              <div className="top-icon-and-text">
                <img
                  src={
                    title.slice(0, 6) === "Delete"
                      ? DeleteIcon
                      : AnalysisStepIcon
                  }
                  alt="Delete"
                />
                <span className="prompt-title">{title}</span>
              </div>
              <img
                className="close-icon"
                src={ClosePopupIcon}
                onClick={onClose ? onClose : this.close}
              />
            </div>

            <div className="prompt-middle" style={style}>
              <span className="prompt-content">{content}</span>
            </div>

            {promptType !== "alert" ? (
              <div
                className={
                  customClass ? `prompt-bottom ${customClass}` : "prompt-bottom"
                }
              >
                {promptType != "HideCancelButton" && (
                  <SecondaryButton
                    label="Cancel"
                    onClick={onClose ? onClose : this.close}
                  />
                )}
                <PrimaryButton
                  label={actionText}
                  onClick={action}
                  extraClass="prompt-done"
                />
              </div>
            ) : (
              <div className="prompt-bottom">
                <PrimaryButton
                  label={actionText}
                  onClick={action}
                  extraClass="prompt-done"
                />
              </div>
            )}
          </FadeIn>
        </div>
      </Fragment>
    );
  }
}

Prompt.defaultProps = {
  title: "Title",
  content: "Main content goes here",
  actionText: "Action",
  action: () => null,
  back: () => null,
  blurred: false, // chrome blurs it, if blurred, set it true to remove it
};

Prompt.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  blurred: PropTypes.bool,
};
