import React, { useState, useEffect } from "react";
import NavigatorToggle from "../icons/icons8-compass.png";
import SlideThumbToggle from "../icons/icons8-color_palette.png";
import LiveSyncIcon from "../icons/live-sync-icon.png";
import LiveShare from "../icons/live-share.png";
import LiveSynUrl from "../icons/icon-url.png";

import ViewerToolBarBasic from "./viewer-toolbar-basic";
import ViewerToolBarFilter from "./viewer-toolbar-filters";
import ViewerToolbarAnnotation from "./viewer-toolbar-annotation";
import ToolTip from "react-tooltip";
import { connect } from "react-redux";
import {
  liveShareOnAction,
  liveShareUserOnAction,
  gernerateLiveSyncUrl,
  deleteAnnotionPopupAction,
  resetAnnotationUploadAction,
} from "../actions/viewer-action";
import { Notify } from "../../../components";
import "./viewer-toolbar.scss";
import { livesynStopApi } from "../services/viewer-service";
import Can from "../../../hoc/FullControllHoc/can";

const ViewerToolBar = ({
  viewer,
  OpenSeadragon,
  toggleThumbnailSlider,
  osdButtons,
  overlay,
  slideThumbData,
  // zoomValueBar,
  slideId,
  when,
  featureId,
  fromCaseStream,
  postIsMine,
  slideOwnerOrCollaborator,
  liveShareOn,
  liveShareUserOn,
  groupAdmin,
  enableFullScreenState,
  setFullScreenRef,
  thumbnailSliderShow,
  selectedGroupData,
  userDetails,
  userLiveShareOn,
  postIsValidate,
  liveSyncMetaData,
  toggleLiveSyncPopup,
  deleteAnnotionPopup,
  resetAnnotationUpload,
  liveShareIsOn,
  showToolbar,
  annotationCoordinates,
  setAnnotationCoordinates,
  highlightAreas,
  runAnalysis,
}) => {
  const [showMiniMap, setShowMiniMap] = useState(true);
  const [liveShareState, setLiveShareState] = useState(false);
  const [userLiveShareState, setUserLiveShareState] = useState(false);
  useEffect(() => {
    if (selectedGroupData) {
      if (when === "groups") {
        setLiveShareState(selectedGroupData.slideLiveShareStatus);
      }
    }
    if (
      userLiveShareOn &&
      !groupAdmin &&
      ((when === "groups" &&
        userDetails.userID !== selectedGroupData.slideLiveSharePerson) ||
        (when === "groups" && selectedGroupData.slideLiveSharePerson))
    ) {
      Notify({
        value:
          "Successfully joined LiveSync. Slide Viewer is in SYNC with the group admin.",
      });
    }
    setUserLiveShareState(userLiveShareOn);
  }, [selectedGroupData, userLiveShareOn]);

  const onToggleMiniMap = (e) => {
    e.preventDefault();
    if (showMiniMap) {
      viewer.navigator.element.style.display = "none";
    } else {
      viewer.navigator.element.style.display = "inline-block";
    }
    setShowMiniMap(!showMiniMap);
  };

  const onToggleLiveSharing = () => {
    setLiveShareState(!liveShareState);
    liveShareOn(!liveShareState);
    if (liveShareState) {
      Notify({ value: "Successfully stopped LiveSync." });
    } else {
      Notify({
        value:
          "Successfully started LiveSync. All group members can see your viewer.",
      });
    }
  };

  const onLiveSharingUserStatus = () => {
    setUserLiveShareState(!userLiveShareState);
    liveShareUserOn(!userLiveShareState);
    if (userLiveShareState) {
      Notify({ value: "Successfully exited LiveSync." });
    } else {
      Notify({
        value:
          "Successfully joined LiveSync. Slide Viewer is in SYNC with the group admin.",
      });
    }
  };

  const liveSyncPopupToggle = () => {
    toggleLiveSyncPopup(null);
  };

  const stopLiveSync = async () => {
    const stopliveSync = await livesynStopApi({ url: window.location.href });
    const baseUrl = window.location.href.split("?")[0];
    const searchParams = window.location.search.split("&")[0];
    const newUrl = `${baseUrl}${searchParams}`;
    window.location.href = newUrl;
  };

  return (
    <div
      className={highlightAreas ? "viewer-toolbar-container" : ""}
      style={
        when === "groups" &&
        userDetails.userID === selectedGroupData.slideLiveSharePerson
          ? liveShareIsOn
            ? { borderBottom: "5px solid #2dbb34", paddingLeft: "10px" }
            : { borderBottom: "5px solid #FF000000", paddingLeft: "10px" }
          : when === "groups" &&
            userLiveShareOn &&
            selectedGroupData.slideLiveShareStatus
          ? { borderBottom: "5px solid #2dbb34", paddingLeft: "10px" }
          : { borderBottom: "5px solid #FF000000", paddingLeft: "10px" }
      }
    >
      {!runAnalysis && (
        <React.Fragment>
          <ViewerToolBarBasic
            overlay={overlay}
            viewer={viewer}
            OpenSeadragon={OpenSeadragon}
            osdButtons={osdButtons}
            enableFullScreenState={enableFullScreenState}
            setFullScreenRef={setFullScreenRef}
          />
          <ViewerToolBarFilter viewer={viewer} OpenSeadragon={OpenSeadragon} />
        </React.Fragment>
      )}

      <ul>
        {!showToolbar && !runAnalysis && (
          <li
            id="show-navigator"
            className={showMiniMap ? "active-tool" : "nonactive-tool"}
            data-for="viewer-toolbar"
            data-tip={
              showMiniMap ? "Hide slide navigator" : "Show slide navigator"
            }
            onClick={onToggleMiniMap}
          >
            <img src={NavigatorToggle} />
          </li>
        )}
        {showToolbar
          ? null
          : slideThumbData &&
            slideThumbData.length > 0 &&
            !enableFullScreenState && (
              <li
                id="show-slidethumb"
                className={
                  thumbnailSliderShow ? "active-tool" : "nonactive-tool"
                }
                data-for="viewer-toolbar"
                data-tip={
                  thumbnailSliderShow
                    ? when == "groups"
                      ? "Hide group history"
                      : "Hide slide tray"
                    : when == "groups"
                    ? "Show group history"
                    : "Show slide tray"
                }
                onClick={toggleThumbnailSlider}
              >
                <img src={SlideThumbToggle} />
              </li>
            )}
      </ul>
      {(!enableFullScreenState || runAnalysis) && (
        <ViewerToolbarAnnotation
          overlay={overlay}
          viewer={viewer}
          // zoomValueBar={zoomValueBar}
          slideId={slideId}
          when={when}
          featureId={featureId}
          fromCaseStream={fromCaseStream}
          postIsMine={postIsMine}
          slideOwnerOrCollaborator={slideOwnerOrCollaborator}
          postIsValidate={postIsValidate}
          deleteAnnotionPopup={deleteAnnotionPopup}
          resetAnnotationUpload={resetAnnotationUpload}
          annotationCoordinates={annotationCoordinates}
          setAnnotationCoordinates={setAnnotationCoordinates}
          highlightAreas={highlightAreas}
          runAnalysis={runAnalysis}
        />
      )}
      {when == "groups" && (
        <React.Fragment>
          {groupAdmin &&
          !enableFullScreenState &&
          (userDetails.userID === selectedGroupData.slideLiveSharePerson ||
            !selectedGroupData.slideLiveSharePerson) ? (
            <ul className="live-sync-indicator">
              <li
                id="show-navigator"
                className={liveShareState ? "active-tool" : "nonactive-tool"}
                data-for="viewer-toolbar"
                data-tip={liveShareState ? "Stop LiveSync" : "Start LiveSync"}
                onClick={onToggleLiveSharing}
              >
                <img src={LiveShare} />
                <span
                  class={
                    liveShareState
                      ? "live-share-on-indicator"
                      : "live-share-off-indicator"
                  }
                ></span>
              </li>
            </ul>
          ) : (
            <ul className="live-sync-indicator">
              <li
                id="show-navigator"
                className={
                  selectedGroupData.slideLiveShareStatus
                    ? userLiveShareState
                      ? "active-tool"
                      : "nonactive-tool"
                    : "anno-tool-disable"
                }
                data-for="viewer-toolbar"
                data-tip={
                  userLiveShareState ? "Exit LiveSync" : "Join LiveSync"
                }
                onClick={
                  selectedGroupData.slideLiveShareStatus &&
                  onLiveSharingUserStatus
                }
              >
                <img src={LiveShare} />
                <span
                  class={
                    userLiveShareState
                      ? "live-share-on-indicator"
                      : "live-share-off-indicator"
                  }
                ></span>
              </li>
            </ul>
          )}
        </React.Fragment>
      )}

      {liveSyncMetaData && (
        <React.Fragment>
          {liveSyncMetaData &&
            userDetails.userID === liveSyncMetaData.liveBroadcasterId && (
              <ul className="live-sync-indicator">
                <li
                  id="show-navigator"
                  className={"active-tool"}
                  data-for="viewer-toolbar"
                  data-tip={"Stop live sync"}
                  onClick={stopLiveSync}
                >
                  <img src={LiveSyncIcon} />
                  <span class={"live-share-on-indicator"}></span>
                </li>
              </ul>
            )}
        </React.Fragment>
      )}

      {liveSyncMetaData && (
        <React.Fragment>
          {liveSyncMetaData &&
          userDetails.userID === liveSyncMetaData.liveBroadcasterId ? (
            <ul className="live-sync-indicator">
              <li
                id="show-navigator"
                className={"active-tool"}
                data-for="viewer-toolbar"
                data-tip={"LiveSync Active"}
              >
                <img src={LiveShare} />
                <span class={"live-share-on-indicator"}></span>
              </li>
            </ul>
          ) : (
            <ul className="live-sync-indicator">
              <li
                id="show-navigator"
                className={"nonactive-tool"}
                data-for="viewer-toolbar"
                data-tip={"LiveSync Active"}
              >
                <img src={LiveShare} />
                <span class={"live-share-on-indicator"}></span>
              </li>
            </ul>
          )}
        </React.Fragment>
      )}

      {!liveSyncMetaData && when === "viewer" && (
        <Can
          taskContainer="viewer"
          task="live-sync"
          edit={() => (
            <ul className="live-sync-indicator">
              <li
                id="show-navigator"
                className={"active-tool"}
                data-for="viewer-toolbar"
                data-tip={"LiveSync URL"}
                onClick={() => liveSyncPopupToggle(true)}
              >
                <img src={LiveSynUrl} />
              </li>
            </ul>
          )}
        />
      )}

      <ToolTip id="viewer-toolbar" place="bottom" type="info" effect="solid" />
    </div>
  );
};

//liveShareOnAction

const mapStateToProps = (state) => ({
  userDetails: state.Global.loggedUserDetails,
  userLiveShareOn: state.Viewer.userLiveShareOn,
  liveShareIsOn: state.Viewer.liveShareOn,
  selectedListData: state.SlideBox.selectedListData,
  liveSyncMetaData: state.Viewer.liveSyncMetaData,
});

const mapDispatchToProps = (dispatch) => ({
  toggleLiveSyncPopup: (payload) => dispatch(gernerateLiveSyncUrl(payload)),
  liveShareOn: (payload) => dispatch(liveShareOnAction(payload)),
  liveShareUserOn: (payload) => dispatch(liveShareUserOnAction(payload)),
  deleteAnnotionPopup: (payload) =>
    dispatch(deleteAnnotionPopupAction(payload)),
  resetAnnotationUpload: () => dispatch(resetAnnotationUploadAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewerToolBar);
