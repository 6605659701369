import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import ToolTip from "react-tooltip";
import { connect } from "react-redux";
import { FadeIn } from "animate-components";
import { FeatureGate } from "react-feature-gate";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import FolderDirInactiveIcon from "../../../../assets/icons/folder-close-new.svg";
import CaseDirInActiveIcon from "../../../../assets/icons/case-close.svg";
import DocumentSingleIcon from "../../../../assets/icons/doc-single.png";
import defaultFileIcon from "../../../../assets/icons/default-file.svg";
import QcStatusIcon from "../../../../assets/icons/qc-status.svg";
import ReportStatusIcon from "../../../../assets/icons/report-status.svg";
import ScanStatusIcon from "../../../../assets/icons/slides.svg";
import NoteIcon from "../../../../assets/icons/AddNotes.svg";
import StandardIcon from "../../../../assets/icons/standard.svg";
import RushIcon from "../../../../assets/icons/rush.svg";
import StatIcon from "../../../../assets/icons/stat.svg";
import AddAttachment from "./AddAttachment";
import InfoIcon from "../../../../assets/icons/info-icon.svg";

import { getNotificationsAction } from "../../../notifications/actions/notification-actions";
import {
  updateCaseStatusAction,
  getAllCaseDetailsTabsInfoAction,
  changePriorityAction,
} from "../../actions/slidebox-action";
import EmptyMessage from "./EmptyMessage";
import Loader from "./Loader";
import { bytesToSize } from "../../../../utils/utils";
import {
  useDateFormat,
  useNameFormat,
} from "../../../adminDashboard/components/DashboardItems/useFormat";
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  ContextMenu,
  Notify,
} from "../../../../components";
import ColumnResizeHandle from "./ColumnResizeHandle";
import CreateCaseModal from "../../../../pages/createCase/CreateCaseModal";
import ChangePriorityModal from "./ChangePriorityModal";
import CaseStatusModal from "./CaseStatusModal";

const useStyles = makeStyles({
  table: {
    minWidth: "800px",
    width: "100%",
    overflow: "scroll",
    tableLayout: "fixed",
  },
  arrowIcon: {
    color: "#6a6e72",
    fontSize: 18,
    marginLeft: 5,
  },
  headerBg: {
    backgroundColor: "#ECF0F4",
  },
  actionIcon: {
    marginRight: 7,
    width: 20,
    height: 20,
  },
  input: {
    width: 350,
    height: 33,

    border: 1,
    border_type: "solid",
    border_color: "#c7c8cb",
    box_sizing: "border-box",
    border_radius: 4,
  },
  images: {
    display: "flex",
    flexDirection: "row",
    padding: "2%",
  },
});

let worklistMouseEnterTimerHandle = 0;

const emptyMessage = {
  root: "Use Create Case button to create a new case",
  folder: "Create a case or upload images",
  case: "Upload images",
  undefined: "",
  emptySearch: "No results found",
};

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];

    if (sortConfig !== null) {
      if (sortConfig.initial) {
        const item_order = ["stat", "rush", "standard"];
        var pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
        sortableItems.sort((a, b) => {
          if (
            new Date(a.procedureDate.replace(pattern, "$3-$1-$2")) <
            new Date(b.procedureDate.replace(pattern, "$3-$1-$2"))
          )
            return 1;
          if (
            new Date(a.procedureDate.replace(pattern, "$3-$1-$2")) >
            new Date(b.procedureDate.replace(pattern, "$3-$1-$2"))
          )
            return -1;
          return (
            item_order.indexOf(a.priority) - item_order.indexOf(b.priority)
          );
        });
      } else {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] == null)
            return sortConfig.direction === "ascending" ? -1 : 1;
          if (b[sortConfig.key] == null)
            return sortConfig.direction === "ascending" ? 1 : -1;
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction, initial: false });
  };

  const initialSort = () => {
    setSortConfig({ initial: true });
  };

  return { sortedListData: sortedItems, requestSort, initialSort, sortConfig };
};

export const SlideTable = ({
  listData,
  history,
  showLoader,
  setSelectedDir,
  selectedList,
  selectedListType,
  selectedFolderType,
  isSearch,
  showSharedPopup,
  deleteDirItemPopup,
  renameSlideItem,
  handleToggleMovePopup,
  getNotificationList,
  conversionComplete,
  setShowDetails,
  setSelectedDetails,
  selectedListItem,
  showGroupsSelectPopup,
  selectedListSlideData,
  downloadSlide,
  isModal,
  updateCaseStatus,
  selectedFolderName,
  description,
  when,
  setContinueButtonDisabled = () => {},
  setSelectedSlideDataToAnalyze,
  importSlide,
  getSearchDirData,
  setLabel,
  radioButtonChecked,
  setRadioButtonChecked,
  worklistData,
  licenseTypeFeatures,
  tabOption,
  setTabOption,
  openNotesSection,
  setOpenNotesSection,
  getAllTabsInfo,
  openCreateCaseModal,
  showCreateCaseModal,
  setShowCreateCaseModal,
  selectedPatientInfo,
  openLabelsSection,
  setOpenLabelsSection,
  changePriorityFn,
  selectedCaseInfo,
  setOpenAllContainers,
  proceduresList,
}) => {
  const [modalIsShown, setModalIsShown] = useState(false);

  const showModal = () => {
    setModalIsShown(true);
  };

  const hideModal = () => {
    setModalIsShown(false);
  };

  const classes = useStyles();
  const { sortedListData, requestSort, initialSort } = useSortableData(
    worklistData && worklistData[0] != null ? worklistData : listData
  );

  const [defaultSort, setDefaultSort] = useState(false);
  const [isTableRowSelected, setIsTableRowSelected] = useState({});
  const [nameSorting, setNameSorting] = useState(true);
  const [mrnSorting, setMrnSorting] = useState(true);
  const [patientNameSorting, setPatientNameSorting] = useState(true);
  const [sizeSorting, setSizeSorting] = useState(true);
  const [lastModifiedSorting, setLastModifiedSorting] = useState(true);
  const [blink, setBlink] = useState(false);
  const [attachInput, setAttachInput] = useState(false);
  const [selectedDataToAnalyze, setSelectedDataToAnalyze] = useState();
  const [selectedDirDetails, setSelectedDirDetails] = useState({});
  const [openSlideTab, setOpenSlideTab] = useState(false);
  const [openAttachmentTab, setOpenAttachmentTab] = useState(false);
  const [dirId, setDirId] = useState();
  const [status, setStatus] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showPriorityModal, setShowPriorityModal] = useState(false);
  const [showCaseStatusModal, setShowCaseStatusModal] = useState(false);

  const [showAddNotesModal, setShowAddNotesModal] = useState(false);

  const [patientDetails, setPatientDetails] = useState();

  const resizeRef0 = useRef();
  const resizeRef1 = useRef();
  const resizeRef2 = useRef();
  const resizeRef3 = useRef();
  const resizeRef4 = useRef();
  const resizeRef5 = useRef();
  const resizeRef6 = useRef();
  const resizeRef7 = useRef();
  const resizeRef8 = useRef();
  const resizeRef9 = useRef();
  const resizeRef10 = useRef();
  const resizeRef11 = useRef();

  const priorityOptions = [
    {
      value: "standard",
      img: StandardIcon,
    },
    {
      value: "rush",
      img: RushIcon,
    },
    {
      value: "stat",
      img: StatIcon,
    },
  ];

  const caseStatusOptions = [
    {
      value: "ordered",
      label: "Ordered",
    },
    {
      value: "cancelled",
      label: "Cancelled",
    },
    {
      value: "arrived",
      label: "Arrived",
    },
    {
      value: "verified",
      label: "Verified",
    },
    {
      value: "transcribed",
      label: "Transcribed",
    },
    {
      value: "prelim-report",
      label: "Prelim Report",
    },
    {
      value: "reported",
      label: "Reported",
    },
  ];

  const handleShowInViewer = (slideItem = selectedListSlideData) => {
    if (!isModal) {
      history.push({
        pathname: `/viewer`,
        search: `?image=${slideItem.keyFolder}&thumb=${slideItem.thumbDataToken}`,
      });
    }
  };

  const handleSelectRow = (e, row, index) => {
    onSelectedRow(e, row, index);
    getAllTabsInfo({
      dirId: row.dirDataId,
      dirType: "case",
    });
    if(selectedListItem) setSelectedDetails(selectedListItem);
    setOpenLabelsSection(false);
  };

  const handleHoverOnRow = (e, row, index) => {
    onSelectedRow(e, row, index);
    setOpenLabelsSection(false);
  };

  const onSelectedRow = (e, listDataItem, index) => {
    const { dirDataId, parentDir, caseOrFolder } = listDataItem;
    setSelectedSlideDataToAnalyze(listDataItem);
    setContinueButtonDisabled(true);
    setIsTableRowSelected({ [index]: true });
    setRadioButtonChecked(false);
    setSelectedDir({
      dirId: dirDataId,
      parentDir,
      dirType: caseOrFolder,
    });
    setSelectedDataToAnalyze(listDataItem);
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleClickAnalyzeSlide = () => {
    history.push({
      pathname: `/analysis`,
      state: { fromSlidebox: true, selectedDataToAnalyze, selectedDirDetails },
    });
  };

  const handleSelectSlide = (e, row, index) => {
    const { dirDataId, parentDir, caseOrFolder } = row;
    if (when === "analysis") {
      if (row.status === "pending") {
        Notify({ value: "Slide conversion is in progress" });
        return;
      }
    }
    setSelectedSlideDataToAnalyze(row);
    setContinueButtonDisabled(false);

    setIsTableRowSelected({ [index]: true });
    setSelectedDir({
      dirId: dirDataId,
      parentDir,
      dirType: caseOrFolder,
    });
    setRadioButtonChecked({ [index]: true });
    setSelectedDataToAnalyze(row);
  };

  useEffect(() => {
    setSelectedDirDetails({ dirDataName: selectedFolderName, description });
  }, [selectedFolderName, description]);

  useEffect(() => {
    getNotificationList();
  }, [listData, conversionComplete]);

  useEffect(() => {
    setPatientDetails({
      firstName: selectedPatientInfo.first_name,
      middleName: selectedPatientInfo.middle_name,
      lastName: selectedPatientInfo.last_name,
      mrnNumber: selectedPatientInfo.mrn_number,
      dob: selectedPatientInfo.dob,
      age: 1,
      gender: selectedPatientInfo.gender,
    });
  }, [selectedPatientInfo]);

  useEffect(() => {
    setLastModifiedSorting(!lastModifiedSorting);
  }, []);

  useEffect(() => {
    (async function sortByProcDate() {
      if (sortedListData.length > 0 && !defaultSort) {
        initialSort();
        setDefaultSort(true);
      }
    })();
  }, [sortedListData]);

  return showLoader ? (
    <div style={{ marginTop: "300px" }}>
      <Loader />
    </div>
  ) : sortedListData.length ? (
    <React.Fragment>
      <TableContainer className="table-scroll" elevation={0} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className="table-padding">
              {worklistData && (
                <React.Fragment>
                  <TableCell
                    ref={resizeRef0}
                    align="left"
                    width={"10%"}
                    style={{
                      backgroundColor: "#ECF0F4",
                    }}
                  >
                    <ColumnResizeHandle resizeRef={resizeRef0} />
                    <div
                      style={{
                        borderRight: true,
                        borderRightWidth: "1px",
                        borderRightColor: "gray",
                        borderRightStyle: "solid",
                        height: "20px",
                      }}
                    ></div>
                  </TableCell>

                  <FeatureGate feature="patient_level_information">
                    <TableCell
                      ref={resizeRef1}
                      align="left"
                      width={"10%"}
                      style={{ backgroundColor: "#ECF0F4", paddingLeft: "3px" }}
                    >
                      MRN Number
                      <span
                        style={{ paddingLeft: "7px" }}
                        onClick={() => {
                          setBlink(true);
                          setMrnSorting(!mrnSorting);
                          requestSort("mrnNumber");
                          setTimeout(() => setBlink(false), 500);
                        }}
                      >
                        <i
                          className={`fas fa-arrow-up ${
                            mrnSorting ? "" : " arrow-down"
                          } arrow-icon`}
                        />
                      </span>
                      <ColumnResizeHandle resizeRef={resizeRef1} />
                      <div
                        style={{
                          borderRight: true,
                          borderRightWidth: "1px",
                          borderRightColor: "gray",
                          borderRightStyle: "solid",
                          height: "20px",
                          alignSelf: "center",
                          marginTop: "-24.5px",
                        }}
                      ></div>
                    </TableCell>

                    <TableCell
                      ref={resizeRef2}
                      align="left"
                      width={"10%"}
                      style={{ backgroundColor: "#ECF0F4", paddingLeft: "3px" }}
                    >
                      Patient Name
                      <span
                        style={{ paddingLeft: "7px" }}
                        onClick={() => {
                          setBlink(true);
                          setPatientNameSorting(!patientNameSorting);
                          requestSort("patientFirstName");
                          setTimeout(() => setBlink(false), 500);
                        }}
                      >
                        <i
                          className={`fas fa-arrow-up ${
                            patientNameSorting ? "" : " arrow-down"
                          } arrow-icon`}
                        />
                      </span>
                      <ColumnResizeHandle resizeRef={resizeRef2} />
                      <div
                        style={{
                          borderRight: true,
                          borderRightWidth: "1px",
                          borderRightColor: "gray",
                          borderRightStyle: "solid",
                          height: "20px",
                          alignSelf: "center",
                          marginTop: "-24.5px",
                        }}
                      ></div>
                    </TableCell>
                  </FeatureGate>

                  <TableCell
                    ref={resizeRef3}
                    align="left"
                    width={"10%"}
                    style={{ backgroundColor: "#ECF0F4", paddingLeft: "3px" }}
                  >
                    Accession Number
                    <ColumnResizeHandle resizeRef={resizeRef3} />
                    <div
                      style={{
                        borderRight: true,
                        borderRightWidth: "1px",
                        borderRightColor: "gray",
                        borderRightStyle: "solid",
                        height: "20px",
                        alignSelf: "center",
                        marginTop: "-24.5px",
                      }}
                    ></div>
                  </TableCell>
                </React.Fragment>
              )}
              <TableCell
                ref={resizeRef4}
                width={"9%"}
                style={{ backgroundColor: "#ECF0F4", paddingLeft: "3px" }}
                align="left"
              >
                {worklistData ? "Procedure" : "Name"}
                <span
                  style={{ paddingLeft: "7px" }}
                  onClick={() => {
                    setBlink(true);
                    setNameSorting(!nameSorting);
                    requestSort("dirDataName");
                    setTimeout(() => setBlink(false), 500);
                  }}
                >
                  <i
                    className={`fas fa-arrow-up ${
                      nameSorting ? "" : " arrow-down"
                    } arrow-icon`}
                  />
                </span>
                <ColumnResizeHandle resizeRef={resizeRef4} />
                <div
                  style={{
                    borderRight: true,
                    borderRightWidth: "1px",
                    borderRightColor: "gray",
                    borderRightStyle: "solid",
                    height: "20px",
                    alignSelf: "center",
                    marginTop: "-24.5px",
                  }}
                ></div>
              </TableCell>
              {!worklistData && (
                <TableCell
                  ref={resizeRef5}
                  align="left"
                  style={{ backgroundColor: "#ECF0F4", paddingLeft: "3px" }}
                >
                  Size
                  <span
                    style={{ paddingLeft: "7px" }}
                    onClick={() => {
                      setBlink(true);
                      requestSort("dirDataSize");
                      setSizeSorting(!sizeSorting);
                      setTimeout(() => setBlink(false), 500);
                    }}
                  >
                    <i
                      className={`fas fa-arrow-up ${
                        sizeSorting ? "" : " arrow-down"
                      } arrow-icon`}
                    />
                  </span>
                  <ColumnResizeHandle resizeRef={resizeRef5} />
                  <div
                    style={{
                      borderRight: true,
                      borderRightWidth: "1px",
                      borderRightColor: "gray",
                      borderRightStyle: "solid",
                      height: "20px",
                      alignSelf: "center",
                      marginTop: "-24.5px",
                    }}
                  ></div>
                </TableCell>
              )}
              <TableCell
                ref={resizeRef6}
                align="left"
                style={{ backgroundColor: "#ECF0F4", paddingLeft: "3px" }}
                width={worklistData ? "9%" : "9%"}
              >
                {worklistData ? "Procedure Date" : "Last Modified"}
                <span
                  style={{ paddingLeft: "7px" }}
                  onClick={() => {
                    setBlink(true);
                    setLastModifiedSorting(!lastModifiedSorting);
                    requestSort("procedureDate");
                    setTimeout(() => setBlink(false), 500);
                  }}
                >
                  <i
                    className={`fas fa-arrow-up ${
                      lastModifiedSorting ? "" : " arrow-down"
                    } arrow-icon`}
                  />
                </span>
                <ColumnResizeHandle resizeRef={resizeRef6} />
                <div
                  style={{
                    borderRight: true,
                    borderRightWidth: "1px",
                    borderRightColor: "gray",
                    borderRightStyle: "solid",
                    height: "20px",
                    alignSelf: "center",
                    marginTop: "-24.5px",
                  }}
                ></div>
              </TableCell>
              {worklistData && (
                <TableCell
                  ref={resizeRef7}
                  align="left"
                  width={"10%"}
                  style={{ backgroundColor: "#ECF0F4", paddingLeft: "3px" }}
                  className="status-column"
                >
                  Status
                  <ColumnResizeHandle resizeRef={resizeRef7} />
                  <div
                    style={{
                      borderRight: true,
                      borderRightWidth: "1px",
                      borderRightColor: "gray",
                      borderRightStyle: "solid",
                      height: "20px",
                      alignSelf: "center",
                      marginTop: "-24.5px",
                    }}
                  ></div>
                </TableCell>
              )}

              <TableCell
                ref={resizeRef8}
                align="left"
                width={"22%"}
                style={{ backgroundColor: "#ECF0F4", paddingLeft: "3px" }}
              >
                Labels
                <ColumnResizeHandle resizeRef={resizeRef8} />
                <div
                  style={{
                    borderRight: true,
                    borderRightWidth: "1px",
                    borderRightColor: "gray",
                    borderRightStyle: "solid",
                    height: "20px",
                    alignSelf: "center",
                    marginTop: "-24.5px",
                  }}
                ></div>
              </TableCell>

              {selectedFolderType !== "case" && (
                <TableCell
                  ref={resizeRef9}
                  align="left"
                  width={"10%"}
                  style={{ backgroundColor: "#ECF0F4", paddingLeft: "3px" }}
                >
                  Assigned To
                  <ColumnResizeHandle resizeRef={resizeRef9} />
                  <div
                    style={{
                      borderRight: true,
                      borderRightWidth: "1px",
                      borderRightColor: "gray",
                      borderRightStyle: "solid",
                      height: "20px",
                      alignSelf: "center",
                      marginTop: "-24.5px",
                    }}
                  ></div>
                </TableCell>
              )}
              {worklistData && (
                <TableCell
                  ref={resizeRef10}
                  align="left"
                  width={"10%"}
                  style={{ backgroundColor: "#ECF0F4", paddingLeft: "3px" }}
                >
                  Referred To
                  <ColumnResizeHandle resizeRef={resizeRef10} />
                </TableCell>
              )}

              {selectedFolderType !== "root" && !worklistData && (
                <TableCell
                  ref={resizeRef11}
                  align="left"
                  style={{ backgroundColor: "#ECF0F4", paddingLeft: "3px" }}
                >
                  {!importSlide ? "Stain Type" : "Analyze"}
                  <ColumnResizeHandle resizeRef={resizeRef11} />
                  <div
                    style={{
                      borderRight: true,
                      borderRightWidth: "1px",
                      borderRightColor: "gray",
                      borderRightStyle: "solid",
                      height: "20px",
                      alignSelf: "center",
                      marginTop: "-24.5px",
                    }}
                  ></div>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody id="slideTableBody">
            {sortedListData.map((row, index) => {
              const priority_value = priorityOptions.find(
                (priority) => priority.value === row.priority
              );
              const img_src =
                priority_value !== undefined
                  ? priority_value.img
                  : StandardIcon;
              const img_opacity = img_src === StandardIcon ? 0 : 1;
              const tableRowSelected =
                selectedList === row.dirDataId &&
                selectedListType === row.caseOrFolder;
              const className = tableRowSelected
                ? "selected-table-cell-color"
                : "";
              return isModal === true && row.caseOrFolder === 'document' ? null : (
               <TableRow
                id='slideTableBodyRow'
                key={row.listDataId}
                onClick={(e) => {
                 console.log(row, index);
                 handleSelectRow(e, row, index);
                 // setShowDetails();
                 setOpenAllContainers(false);
                 setSelectedDetails(selectedListItem);
                }}
                onMouseOver={(e) => {
                  clearTimeout(worklistMouseEnterTimerHandle);
                  worklistMouseEnterTimerHandle = setTimeout(function() {
                    console.log(row, index);
                    handleHoverOnRow(e, row, index);
                  }, 100);
                }}
                onMouseOut={(e) => {
                  clearTimeout(worklistMouseEnterTimerHandle);
                    worklistMouseEnterTimerHandle = 0;
                }}
                className={`table-padding ${
                 selectedList === row.dirDataId && selectedListType === row.caseOrFolder ? 'selected-table-row-bg' : ''
                }`}
               >
                {worklistData && (
                 <React.Fragment>
                  <TableCell
                   align='left'
                   className={className}
                   onClick={(e) => {
                    setShowDetails();
                   }}
                  >
                   <span className={classes.images}>
                    <img
                     src={img_src}
                     alt='#'
                     className={classes.actionIcon}
                     style={{ opacity: img_opacity }}
                     data-for={priorityOptions.filter((item) => item.value === row.priority)[0] && 'slidebox-tooltip'}
                     data-tip='Priority'
                     data-for={
                      priorityOptions.filter((item) => item.value === row.priority)[0] && row.priority !== 'standard' && 'slidebox-tooltip'
                     }
                     data-tip={row.priority !== null && capitalizeFirstLetter(row.priority)}
                    />
                    <img
                     src={ScanStatusIcon}
                     alt='scan status'
                     className={classes.actionIcon}
                     data-for={row.slidesCount && row.slidesCount > 0 && 'slidebox-tooltip'}
                     data-tip='Scans Available'
                     style={{
                      opacity: row.slidesCount && row.slidesCount > 0 ? 1 : 0,
                     }}
                    />
                    <img
                     src={QcStatusIcon}
                     alt='qc status'
                     className={classes.actionIcon}
                     data-for={
                      (row.review_status === 'verified' ||
                       row.review_status === 'transcribed' ||
                       row.review_status === 'prelim-report' ||
                       row.review_status === 'reported') &&
                      'slidebox-tooltip'
                     }
                     data-tip='QC Verified'
                     style={{
                      opacity:
                       row.review_status === 'verified' ||
                       row.review_status === 'transcribed' ||
                       row.review_status === 'prelim-report' ||
                       row.review_status === 'reported'
                        ? 1
                        : 0,
                     }}
                    />
                    <img
                     src={NoteIcon} /* this is the notes image, add everything here on onClick function */
                     alt='notes'
                     className={classes.actionIcon}
                     data-for={row.notes && 'slidebox-tooltip'}
                     data-tip='Notes Available'
                     style={{ opacity: row.notes ? 1 : 0 }}
                    />

                    <img
                     src={ReportStatusIcon}
                     alt='report status'
                     className={classes.actionIcon}
                     data-for={row.review_status === 'reported' && 'slidebox-tooltip'}
                     data-tip='Report Available'
                     style={{
                      opacity: row.review_status === 'reported' ? 1 : 0,
                     }}
                    />
                   </span>
                  </TableCell>
                  <FeatureGate feature='patient_level_information'>
                   <TableCell
                    align='left'
                    className={className}
                    onClick={(e) => {
                     setShowDetails();
                    }}
                   >
                    <span data-for='slidebox-tooltip' data-tip={row.mrnNumber && row.mrnNumber}>
                     {row.mrnNumber ? row.mrnNumber : '—'}
                    </span>
                   </TableCell>

                   <TableCell
                    align='left'
                    className={className}
                    onClick={(e) => {
                     setShowDetails();
                    }}
                   >
                    <span
                     data-for='slidebox-tooltip'
                     data-tip={row.patientFirstName && useNameFormat(row.patientFirstName, row.patientLastName)}
                    >
                     {row.patientFirstName ? useNameFormat(row.patientFirstName, row.patientLastName) : '—'}
                    </span>
                   </TableCell>
                  </FeatureGate>
                  <TableCell
                   align='left'
                   className={className}
                   onClick={(e) => {
                    setShowDetails();
                   }}
                  >
                   <span data-for='slidebox-tooltip' data-tip={row.accessionNumber && row.accessionNumber}>
                    {row.accessionNumber ? row.accessionNumber : '—'}
                   </span>
                  </TableCell>
                 </React.Fragment>
                )}
                <TableCell
                 component='th'
                 scope='row'
                 className={className}
                 onClick={(e) => {
                  setShowDetails();
                 }}
                >
                 {!worklistData && (
                  <img
                   src={
                    row.caseOrFolder === 'folder'
                     ? FolderDirInactiveIcon
                     : row.caseOrFolder === 'case'
                     ? CaseDirInActiveIcon
                     : row.dzi_thumbnail
                     ? row.dzi_thumbnail
                     : row.caseOrFolder === 'document'
                     ? DocumentSingleIcon
                     : defaultFileIcon
                   }
                   className={row.caseOrFolder ? 'slide-dir' : 'slide-image'}
                  />
                 )}
                 <span data-for='slidebox-tooltip' data-tip={row.dirDataName} data-test='procedure-name'>
                  {!row.procedureId || !proceduresList.length
                   ? '--'
                   : proceduresList.find((procedure) => +procedure.procedureId === +row.procedureId)
                   ? proceduresList.find((procedure) => +procedure.procedureId === +row.procedureId).procedureName
                   : ''}
                  {/* {!row.dirDataName ? '---' : row.dirDataName.length > 23
                        ? row.dirDataName.substring(0, 22) + "..."
                        : row.dirDataName} */}
                 </span>
                </TableCell>
                {!worklistData && (
                 <TableCell
                  align='left'
                  className={className}
                  onClick={(e) => {
                   setShowDetails();
                  }}
                 >
                  {row.dirDataSize ? bytesToSize(row.dirDataSize) : '—'}
                 </TableCell>
                )}

                <TableCell
                 align='left'
                 className={className}
                 onClick={(e) => {
                  setShowDetails();
                 }}
                >
                 <span
                  data-for='slidebox-tooltip'
                  data-test='procedure-date'
                  data-tip={
                   worklistData && row.procedureDate
                    ? useDateFormat({ date: row.procedureDate })
                    : !worklistData
                    ? useDateFormat({ date: row.lastModified })
                    : '-'
                  }
                 >
                  {worklistData ? useDateFormat({ date: row.procedureDate }) : useDateFormat({ date: row.lastModified })}
                 </span>
                </TableCell>
                {row.caseOrFolder !== 'slide' && (
                 <TableCell align='left' className={className}>
                  <Select
                   placeholder={
                    row.review_status && caseStatusOptions.filter((stat) => stat.value === row.review_status)[0]
                     ? caseStatusOptions.filter((stat) => stat.value === row.review_status)[0].label
                     : 'None'
                   }
                   labelId='demo-simple-select-placeholder-label'
                   id='demo-simple-select-placeholder'
                   style={{
                    width: '100%',
                    input: { fontSize: '14px' },
                    placeholder: { fontSize: '14px' },
                    color: '#9e9ea6',
                    focus: 'disabled',
                   }}
                   customStyles={true}
                   options={caseStatusOptions}
                   isSearchable={false}
                   value={row.review_status}
                   className='status-dropdown'
                   menuPlacement='auto'
                   menuPosition='fixed'
                   components={{
                    IndicatorSeparator: () => null,
                   }}
                   onChange={({ value }) => {
                    setStatus(value);
                    setDirId(row.slidebox_case_id ? row.slidebox_case_id : row.dirDataId);
                    setShowStatusModal(true);
                   }}
                  />
                 </TableCell>
                )}
                <TableCell
                 align='left'
                 className={className}
                 width={'25%'}
                 onClick={(e) => {
                  setShowDetails();
                 }}
                >
                 <div
                  style={{
                   display: 'flex',
                   flexDirection: 'row',
                   width: '100%',
                  }}
                 >
                  {row.labels &&
                   row.labels
                    .split(',')
                    .slice(0, 4)
                    .map((label, index) => (
                     <div className='lable_tags' key={index} style={{ width: '100%' }}>
                      <span
                       data-for='label-tooltip'
                       data-tip={
                        index < 3
                         ? label
                         : row.labels
                            .split(',')
                            .slice(3)
                            .join()
                       }
                      >
                       {index < 3 ? label : '...'}
                      </span>
                     </div>
                    ))}
                 </div>
                </TableCell>

                {worklistData && (
                 <TableCell
                  align='left'
                  className={className}
                  onClick={(e) => {
                   setShowDetails();
                  }}
                 >
                  {row.assignTo.length > 0 ? useNameFormat(row.assignTo[0].first_name, row.assignTo[0].last_name) : '—'}
                 </TableCell>
                )}
                {worklistData && (
                 <TableCell
                  align='left'
                  className={className}
                  onClick={(e) => {
                   setShowDetails();
                  }}
                 >
                  {row.caseOrFolder === 'case' ? (
                   <React.Fragment>
                    {row.referredToFirstName !== null ? useNameFormat(row.referredToFirstName, row.referredToLastName) : '—'}
                   </React.Fragment>
                  ) : null}
                 </TableCell>
                )}

                {when === 'analysis' && selectedFolderType !== 'root' && (
                 <TableCell
                  align='left'
                  className={className}
                  onClick={(e) => {
                   setShowDetails();
                  }}
                 >
                  {row.caseOrFolder === 'slide' ? (
                   <label class='radio-button-container'>
                    <input
                     className='select-slide-radio'
                     type='radio'
                     name='select-slide'
                     value={row.dirDataId}
                     onChange={(e) => {
                      handleSelectSlide(row);
                     }}
                    />
                    <span className={`checkmark-radio-button ${radioButtonChecked[index] === true ? 'checked' : 'unchecked'}`}></span>
                   </label>
                  ) : null}
                 </TableCell>
                )}
                {when !== 'analysis' && selectedFolderType !== 'root' && !worklistData && (
                 <TableCell
                  align='left'
                  className={className}
                  onClick={(e) => {
                   setShowDetails();
                  }}
                 >
                  {row.stainType || '—'}
                 </TableCell>
                )}
               </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <ToolTip id="slidebox-tooltip" place="top" type="dark" effect="solid" />
        <ToolTip id="label-tooltip" place="left" type="dark" effect="solid" />
        {!importSlide && (
          <ContextMenu
            setOpenAllContainers={setOpenAllContainers}
            setAttachInput={setAttachInput}
            domElement={"table-padding"}
            showSharedPopup={showSharedPopup}
            deleteDirItemPopup={deleteDirItemPopup}
            renameSlideItem={renameSlideItem}
            handleToggleMovePopup={handleToggleMovePopup}
            setShowDetails={setShowDetails}
            selectedListItem={selectedListItem}
            showGroupsSelectPopup={showGroupsSelectPopup}
            downloadSlide={downloadSlide}
            openInViewer={() => handleShowInViewer()}
            rightClickFrom={"list-table"}
            isModal={isModal}
            analyzeSlide={() => handleClickAnalyzeSlide()}
            importSlide={importSlide}
            setSelectedDetails={setSelectedDetails}
            tabOption={tabOption}
            setTabOption={setTabOption}
            openNotesSection={openNotesSection}
            setOpenNotesSection={setOpenNotesSection}
            openCreateCaseModal={openCreateCaseModal}
            showCreateCaseModal={showCreateCaseModal}
            openSlideTab={openSlideTab}
            setOpenSlideTab={setOpenSlideTab}
            openAttachmentTab={openAttachmentTab}
            setOpenAttachmentTab={setOpenAttachmentTab}
            openLabelsSection={openLabelsSection}
            setOpenLabelsSection={setOpenLabelsSection}
            getAllTabsInfo={getAllTabsInfo}
            selectedDirId={selectedList}
            setShowPriorityModal={setShowPriorityModal}
            history={history}
            setShowCaseStatusModal={setShowCaseStatusModal}
          />
        )}
        <AddAttachment
          attachInput={attachInput}
          setAttachInput={setAttachInput}
        />

        {showAddNotesModal && (
          <React.Fragment>
            <Overlay />
            <div className="prompt priority-prompt">
              <FadeIn duration="300ms">
                <ModalHeader
                  img={NoteIcon}
                  alt="add notes"
                  title="Add Notes"
                  onClick={() => setShowAddNotesModal(false)}
                  id="modalHeader"
                />
                <div className="modal_middle">
                  <form>
                    <input
                      type="text"
                      className={classes.input}
                      placeholder="Enter Note Here"
                    ></input>
                  </form>
                </div>

                <div className="prompt-bottom">
                  <SecondaryButton
                    label="Cancel"
                    onClick={() => setShowAddNotesModal(false)}
                  />
                  <PrimaryButton
                    label="Save"
                    onClick={() => {
                      setShowAddNotesModal(false);
                      Notify({ value: "Note Added successfully" });
                    }}
                  />
                </div>
              </FadeIn>
            </div>
          </React.Fragment>
        )}

        {showStatusModal && (
          <React.Fragment>
            <Overlay />
            <div className="prompt priority-prompt">
              <FadeIn duration="300ms">
                <ModalHeader
                  img={InfoIcon}
                  alt="info icon"
                  title="Status Update"
                  onClick={() => setShowStatusModal(false)}
                  id="modalHeader"
                />
                <div className="modal_middle">
                  <p className="Confirmation text">
                    Are you sure you want to update the status of this case?
                  </p>
                </div>
                <div className="prompt-bottom">
                  <SecondaryButton
                    label="Cancel"
                    onClick={() => setShowStatusModal(false)}
                  />
                  <PrimaryButton
                    label="Confirm"
                    onClick={() => {
                      updateCaseStatus({
                        dirId: dirId,
                        reviewStatus: status,
                      });
                      setShowStatusModal(false);
                    }}
                  />
                </div>
              </FadeIn>
            </div>
          </React.Fragment>
        )}

        {showCreateCaseModal && (
          <CreateCaseModal
            onClose={() => setShowCreateCaseModal(false)}
            licenseTypeFeatures={licenseTypeFeatures}
            openSlideTab={openSlideTab}
            allPatientDetails={patientDetails}
            openCaseTab={openAttachmentTab}
          />
        )}

        {showPriorityModal && (
          <ChangePriorityModal
            changePriorityFn={changePriorityFn}
            selectedDirId={selectedList}
            setShowPriorityModal={setShowPriorityModal}
            selectedCaseInfo={selectedCaseInfo}
          />
        )}
        {showCaseStatusModal && (
          <CaseStatusModal
            updateCaseStatus={updateCaseStatus}
            selectedDirId={selectedList}
            setShowCaseStatusModal={setShowCaseStatusModal}
            caseStatusOptions={caseStatusOptions}
            selectedCaseInfo={selectedCaseInfo}
          />
        )}
      </TableContainer>
    </React.Fragment>
  ) : (
    <EmptyMessage
      mainMessage={emptyMessage[isSearch ? "emptySearch" : selectedFolderType]}
      selectedFolderType={selectedFolderType}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedListSlideData: state.SlideBox.selectedListSlideData,
  conversionComplete: state.SlideBox.conversionComplete,
  worklistData: state.SlideBox.worklistColumnData,
  licenseTypeFeatures: state.Global.loggedUserDetails.licenseTypeAccessData,
  selectedPatientInfo: state.SlideBox.selectedPatientInfo,
  selectedCaseInfo: state.SlideBox.selectedCaseInfo,
  proceduresList: state.Dashboard.proceduresList,
  ...state.SlideBox.selectedListData,
  ...state.SlideBox.selectedFolderData,
});

const mapDispatchToProps = (dispatch) => ({
  getNotificationList: () => dispatch(getNotificationsAction()),
  updateCaseStatus: (payload) => dispatch(updateCaseStatusAction(payload)),
  getAllTabsInfo: (payload) =>
    dispatch(getAllCaseDetailsTabsInfoAction(payload)),
  changePriorityFn: (payload) => dispatch(changePriorityAction(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SlideTable)
);
