import { conforms } from "lodash";
import React, { useState, useRef } from "react";
import CloseIcon from "../../../../assets/icons/close-new.svg";
import { Notify, SingleSelect } from "../../../../components";

const ExistingSlides = ({
  file,
  index,
  renameSlideAction,
  updateStainType,
  deleteSlide,
  getcaseDetails,
  selectedListData,
}) => {
  const [fileEditState, setFileEditState] = useState(false);
  const [selectedFileIndexState, setSelectedFileIndexState] = useState(null);
  const [changedFileNameState, setChangedFileNameState] = useState(null);
  const [fileExtensionState, setFileExtensionState] = useState(null);
console.log(file);
  const inputEl = useRef();

  const onFileNameEdit = (file, index) => {
    setFileEditState(true);
    const splittingIndex = file.slide_original_name.lastIndexOf(".");
    console.log(splittingIndex);
    const fileName = file.slide_name.substring(0, splittingIndex);
    const extension = file.slide_original_name.substring(splittingIndex + 1);
    setChangedFileNameState(fileName);
    setFileExtensionState(extension);
    setSelectedFileIndexState(index);
  };

  const handleClickEditDone = (event, file, index) => {
    event.preventDefault();
    if (changedFileNameState === "") {
      Notify({ value: "File name cannot be empty" });
      return;
    }
    renameSlideAction({
      dirType: "slide",
      slideName: changedFileNameState,
      slideId: file.slidebox_slide_id,
    });
    setSelectedFileIndexState(null);
    setFileEditState(false);
  };

  const handleDeleteSlide = () => {
    if (fileEditState) {
      setChangedFileNameState(null);
      setSelectedFileIndexState(null);
    } else {
      deleteSlide({ dirId: file.slidebox_slide_id });
      getcaseDetails({
        dirId: selectedListData.selectedList,
        dirType: "case",
      });
    }
  };

  const handleSelectStainChange = ({ value }) => {
    updateStainType({ slideId: file.slidebox_slide_id, stainType: value });
  };

  return (
    <div className="attach-item px-4 py-3">
      <div className="attach-item-details">
        <div className="attach-item-details-sec">
          {fileEditState && selectedFileIndexState === index ? (
            <input
              id={index}
              ref={inputEl}
              autoFocus={true}
              type="text"
              value={changedFileNameState}
              style={{ width: "175px" }}
              onChange={(e) => {
                setChangedFileNameState(e.target.value);
              }}
            />
          ) : (
            <span className="attach-item-name">{file.slide_name}</span>
          )}
          <p className="attach-item-size">
            {parseFloat(file.slide_size / 1024).toFixed(2) + " KB"}
          </p>
        </div>
        {file.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
          <SingleSelect
            classname="stain-select"
            defaultValue={{
              value: file.slide_stain ? file.slide_stain : "H&E",
              label: file.slide_stain ? file.slide_stain : "H&E",
            }}
            handleChange={handleSelectStainChange}
            placeholder="H&E"
            type={"stains"}
            customStyles={true}
            style={{ width: "100%" }}
          />
        )}
      </div>

      <span className="file-name-edit" id="file-edit-init-span"></span>
      {fileEditState && index === selectedFileIndexState ? (
        <span
          id="file-edit-done-span"
          className="file-name-edit"
          onClick={(e) => {
            handleClickEditDone(e, file, index);
          }}
        >
          <i class={"fa fa-check"}></i>
        </span>
      ) : (
        <span
          id="file-edit-init-span"
          className="file-name-edit"
          onClick={() => {
            onFileNameEdit(file, index);
          }}
        >
          <i class={"fa fa-pencil-alt"}></i>
        </span>
      )}
      <label
        htmlFor=""
        className="checkbox-container"
        onClick={handleDeleteSlide}
      >
        <img src={CloseIcon} />
      </label>
    </div>
  );
};

export default ExistingSlides;
