import React, { useRef, useState, useEffect } from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import {
  getSlideboxCaseDataAction,
  getSlideboxNewAndCompletedCaseDataAction,
} from "../../../actions/adminDashboard-action";

const MonthChart = ({
  caseStatusData,
  getSlideboxCaseData,
  getSlideboxCaseStatusData,
}) => {
  const svgRef = useRef();
  const [monthSelected, setMonthSelected] = useState(true);
  const [weekSelected, setWeekSelected] = useState(false);
  const [daySelected, setDaySelected] = useState(false);
  const [groupData, setGroupData] = useState(caseStatusData);

  React.useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 },
      width = 800 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    const x0 = d3
      .scaleBand()
      .rangeRound([0, width], 0.5)
      .padding(-0.2);
    const x1 = d3.scaleBand();
    const y = d3.scaleLinear().rangeRound([height, 0]);

    const xAxis = d3
      .axisBottom()
      .scale(x0)
      //   .tickFormat(d3.timeFormat("Week %V"))
      .tickValues(groupData.map((d) => d.key));

    const yAxis = d3.axisLeft().scale(y);

    // const color = d3.scaleOrdinal(d3.schemeCategory10);
    const color = ["#6332B3", "#0F0DA1"];

    const svg = d3
      .select(".new-completed-container-month")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", 350)
      .attr("viewBox", "0 0 800 400")
      .attr("margin-top", "40px")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const categoriesNames = groupData.map(function(d) {
      return d.key;
    });
    const rateNames = groupData[0].values.map(function(d) {
      return d.grpName;
    });

    x0.domain(categoriesNames);
    x1.domain(rateNames).rangeRound([0, x0.bandwidth()]);
    y.domain([
      0,
      d3.max(groupData, function(key) {
        return d3.max(key.values, function(d) {
          return d.grpValue;
        });
      }),
    ]);

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    svg
      .append("g")
      .attr("class", "y-axis")
      .style("opacity", "0")
      .call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", ".71em")
      .style("text-anchor", "end")
      .style("font-weight", "bold")
      .text("Value");

    svg
      .select(".y")
      .transition()
      .duration(500)
      .delay(1300)
      .style("opacity", "1");

    const slice = svg
      .selectAll(".slice")
      .data(groupData)
      .enter()
      .append("g")
      .attr("class", "g")
      .attr("transform", function(d) {
        return "translate(" + x0(d.key) + ",0)";
      });

    slice
      .selectAll("rect")
      .data(function(d) {
        return d.values;
      })
      .enter()
      .append("rect")
      .attr("width", 30)
      .attr("x", function(d) {
        return x1(d.grpName);
      })
      .style("fill", function(d, i) {
        return color[i];
      })
      .attr("y", function(d) {
        return y(0);
      })
      .attr("height", function(d) {
        return height - y(0);
      });

    slice
      .selectAll("rect")
      .transition()
      .delay(function(d) {
        return Math.random() * 1000;
      })
      .duration(1000)
      .attr("y", function(d) {
        return y(d.grpValue);
      })
      .attr("height", function(d) {
        return height - y(d.grpValue);
      });

    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -100)
      .attr("x", 0 - height / 2)
      .attr("dy", "5em")
      .style("text-anchor", "middle")
      .style("letter-spacing", "1px")
      .style("font-size", "14px")
      .style("font-family", "Roboto, sans-serif")
      .text("Number of Cases");

    const legend = svg
      .selectAll(".legend")
      .data(
        groupData[0].values
          .map(function(d) {
            return d.grpName;
          })
          .reverse()
      )
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", function(d, i) {
        return "translate(0," + i * 20 + ")";
      })
      .style("opacity", "0");
  }, []);

  useEffect(() => {
    setGroupData(caseStatusData);
  }, [caseStatusData, setGroupData]);

  const displayProductivityData = (type) => {
    if (type === "week") {
      setWeekSelected(true);
      setMonthSelected(false);
      setDaySelected(false);
    } else if (type === "month") {
      setMonthSelected(true);
      setWeekSelected(false);
      setDaySelected(false);
    } else if (type === "day") {
      setMonthSelected(false);
      setWeekSelected(false);
      setDaySelected(true);
    }
    getSlideboxCaseData();
    getSlideboxCaseStatusData(type);
  };

  return <div className="new-completed-container-month"></div>;
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  getSlideboxCaseStatusData: (payload) =>
    dispatch(getSlideboxNewAndCompletedCaseDataAction(payload)),
  getSlideboxCaseData: () => dispatch(getSlideboxCaseDataAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonthChart);
