// to do
import axios from "../../../utils/axiosInstance";

export const dashboardUserListApi = async () => {
  try {
    return await axios.get(`/api/user/dxAccountUsers`);
  } catch (err) {
    throw err;
  }
};

export const searchUserListApi = async (data) => {
  try {
    return await axios.get(`/api/user/dxAccountUsers?filter=${data}`);
  } catch (err) {
    throw err;
  }
};

export const addNewUserApi = async (data) => {
  try {
    return await axios.post(`/api/auth/createDxUser`, data);
  } catch (err) {
    throw err;
  }
};

export const activateOrDeactivateUserApi = async (data) => {
  try {
    return await axios.post(`/api/user/activeOrDeactiveDxUser`, data);
  } catch (err) {
    throw err;
  }
};

export const updateDxUserRoleApi = async (data) => {
  try {
    return await axios.post(`/api/user/updateDxUserRole`, data);
  } catch (err) {
    throw err;
  }
};

export const resendUserInvitationApi = async (data) => {
  try {
    return await axios.post(`/api/auth/resendUserInvitation`, data);
  } catch (err) {
    throw err;
  }
};

export const revokeUserInvitationApi = async (data) => {
  try {
    return await axios.post(`/api/auth/revokeUserInvitation`, data);
  } catch (err) {
    throw err;
  }
};

export const getSlideboxCaseDataApi = async () => {
  try {
    return await axios.get(`/api/dashboardAnalytics/getSlideboxCaseData`);
  } catch (err) {
    throw err;
  }
};

export const getSlideboxNewAndCompletedCaseDataApi = async (data) => {
  try {
    return await axios.get(
      `/api/dashboardAnalytics/getSlideboxCaseActivityData?showBy=${data}`
    );
  } catch (err) {
    throw err;
  }
};

export const getSlideboxSlideDataApi = async (data) => {
  try {
    return await axios.get(
      `/api/dashboardAnalytics/getSlideboxSlidesData?showBy=${data}`
    );
  } catch (err) {
    throw err;
  }
};

export const getViewerAnnotationDataApi = async (data) => {
  try {
    return await axios.get(
      `/api/dashboardAnalytics/getViewerAnnotationData?showBy=${data}`
    );
  } catch (err) {
    throw err;
  }
};

export const getCaseSummaryApi = async (data) => {
  try {
    return await axios.get(`/api/dashboardAnalytics/getCaseData`);
  } catch (err) {
    throw err;
  }
};

export const getAllRolesApi = async () => {
  try {
    return await axios.get(`/api/user/getAllRolesData`);
  } catch (err) {
    throw err;
  }
};

export const getAllPhysiciansApi = async () => {
  try {
    return await axios.get(`/api/user/get-ordering-physicians`);
  } catch (err) {
    throw err;
  }
};

export const getAllProceduresApi = async () => {
  try {
    return await axios.get(`/api/user/all-procedures`);
  } catch (err) {
    throw err;
  }
};

export const addPhysicianApi = async (data) => {
  try {
    return await axios.post(`/api/user/add-ordering-physician`, data);
  } catch (err) {
    throw err;
  }
};

export const addProcedureApi = async (data) => {
  try {
    return await axios.post(`/api/user/new-procedure`, data);
  } catch (err) {
    throw err;
  }
};

export const editPhysicianApi = async (data) => {
  try {
    const { id } = data;
    return await axios.put(`/api/user/update-ordering-physician`, data, {
      params: { id },
    });
  } catch (err) {
    throw err;
  }
};

export const editProcedureApi = async (data) => {
  try {
    const { id } = data;
    return await axios.put(`/api/user/procedure`, data, {
      params: { id },
    });
  } catch (err) {
    throw err;
  }
};

export const deletePhysicianApi = async (data) => {
  try {
    return await axios.delete(`/api/user/delete-ordering-physician`, {
      params: { id: data },
    });
  } catch (err) {
    throw err;
  }
};

export const deleteProcedureApi = async (data) => {
  try {
    return await axios.delete(`/api/user/delete-procedure`, {
      params: { id: data },
    });
  } catch (err) {
    throw err;
  }
};

export const addOrEditRoleApi = async (data) => {
  try {
    return await axios.post(`/api/user/addOrEditRole`, data);
  } catch (err) {
    throw err;
  }
};

export const updatePermissionsApi = async (data) => {
  try {
    return await axios.post(`/api/user/updatePermissions`, data);
  } catch (err) {
    throw err;
  }
};

export const updateAccountDetailsApi = async (data) => {
  try {
    return await axios.post(`/api/siteAdmin/updateAccountDetails`, data);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAllWorkgroupsApi = async () => {
  try {
    return await axios.get(`/api/user/getAllWorkgroupsData`);
  } catch (err) {
    throw err;
  }
};

export const addNewWorkGroupApi = async (data) => {
  try {
    return await axios.post(`/api/user/createWorkgroup`, data);
  } catch (err) {
    throw err;
  }
};

export const editWorkGroupNameApi = async (data) => {
  try {
    return await axios.post(`/api/user/editWorkgroupName`, data);
  } catch (err) {
    throw err;
  }
};

export const deleteWorkgroupApi = async (data) => {
  try {
    return await axios.post(`/api/user/deleteWorkgroup`, data);
  } catch (err) {
    throw err;
  }
};

export const editWorkgroupMembersApi = async (data) => {
  try {
    return await axios.post(`/api/user/updateWorkgroupUsers`, data);
  } catch (error) {}
};

export const deleteRoleApi = async (data) => {
  try {
    return await axios.post(`/api/user/deleteRole`, data);
  } catch (error) {
    throw error;
  }
};
