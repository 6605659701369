import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Notify } from '../src/components';
import { FadeIn } from 'animate-components';
import MoveIcon from '../src/assets/icons/move-new.svg';

import {
  setOpenDirectoryInMove,
  loadSlideboxDirectory,
  loadSlideboxDirDataList,
  breadcrumbAction,
  setSelectedDirectoryInMove,
  setExpandDirectoryInMove,
  getSearchDirData,
  setSlideBoxMoveDirAction,
  resetMoveDirActive,
  selectedList,
  selectedMoveFolder,
} from '../src/pages/slidebox/actions/slidebox-action';

import FolderTreeView from '../src/pages/slidebox/components/SlideItems/FolderTreeView';
import {
  Overlay,
  ModalHeader,
  SecondaryButton,
  PrimaryButton,
} from '../src/components';

const MoveToPopUp = ({
  slideDirectory,
  setSelectedDirectory,
  setExpandDirectory,
  setOpenDirectory,
  loadSlideboxDirDataList,
  breadcrumbAction,
  selectedList,
  selectedListType,
  selectedMoveFolderType,
  selectedMoveFolder,
  onClose,
  setSlideBoxMoveDir,
  selectedFolder,
  selectedFolderType,
  outSideList,
  loadSlideboxDirectory,
  resetMoveDirActive,
}) => {
  const [createDirDisable, setCreateDirDisable] = useState(false);
  const [dirType, setDirType] = useState(
    outSideList ? selectedFolderType : selectedListType
  );
  const [
    slideboxLeftSiderIsCollapse,
    setSlideboxLeftSiderIsCollapse,
  ] = useState(true);

  const handleSaveClick = () => {
    const data = {
      selectedDirId: outSideList ? selectedFolder : selectedList,
      destinationDirId: selectedMoveFolder,
      selectedDirType: dirType,
      destinationDirType:
        selectedMoveFolderType === 'root' ? 'folder' : selectedMoveFolderType,
    };
    setSlideBoxMoveDir(data, {
      onSuccess: afterDirMovedSuccess,
      onError: afterDirMovedFail,
    });
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const afterDirMovedSuccess = () => {
    Notify({
      value: `${capitalizeFirstLetter(dirType)} moved successfully`,
    });
    resetMoveDirActive(true);
    setTimeout(() => {
      loadSlideboxDirDataList({
        dirId: selectedFolder,
        dirType: selectedFolderType,
      });
      loadSlideboxDirectory();
    }, 3000);
    onClose();
  };

  const afterDirMovedFail = () => {
    Notify({
      value: `${capitalizeFirstLetter(dirType)} failed to move`,
    });
    onClose();
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className='prompt'>
        <FadeIn duration='300ms'>
          <ModalHeader
            img={MoveIcon}
            alt={'move'}
            title={`Move ${dirType}`}
            onClick={() => onClose(false)}
          />
          <div className='modal_middle'>
            <div className='move-to-pop-up'>
              <FolderTreeView
                slideDirectory={slideDirectory}
                setOpenDirectory={setOpenDirectory}
                setSelectedDirectory={setSelectedDirectory}
                setExpandDirectory={setExpandDirectory}
                loadSlideboxDirDataList={loadSlideboxDirDataList}
                slideboxLeftSiderIsCollapse={slideboxLeftSiderIsCollapse}
                setCreateDirDisable={(status) => setCreateDirDisable(status)}
                breadcrumbAction={(status) =>
                  breadcrumbAction(
                    dirType === 'root'
                      ? { showIcon: false }
                      : { showIcon: true }
                  )
                }
                isMovePopup={true}
              />
            </div>
          </div>
          <div className='prompt-bottom' style={{ marginTop: 0 }}>
            <SecondaryButton label='Cancel' onClick={() => onClose(false)} />
            <PrimaryButton
              label='Move'
              onClick={handleSaveClick}
              disabled={
                (selectedMoveFolderType === 'root' && dirType === 'slide') ||
                (selectedMoveFolderType === 'case' && dirType === 'case') ||
                (selectedMoveFolderType === 'case' && dirType === 'folder') ||
                selectedList === selectedMoveFolder
                  ? 'disabled'
                  : ''
              }
              extraClass='prompt-done'
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  slideDirectory: state.SlideBox.moveFolders,
  ...state.SlideBox.selectedMoveFolderData,
  ...state.SlideBox.selectedFolderData,
  ...state.SlideBox.selectedListData,
});

const mapDispatchToProps = (dispatch) => ({
  setOpenDirectory: (payload) => dispatch(setOpenDirectoryInMove(payload)),
  setSelectedDirectory: (payload) =>
    dispatch(setSelectedDirectoryInMove(payload)),
  setExpandDirectory: (payload) => dispatch(setExpandDirectoryInMove(payload)),
  loadSlideboxDirectory: () => dispatch(loadSlideboxDirectory()),
  loadSlideboxDirDataList: (payload) =>
    dispatch(loadSlideboxDirDataList(payload)),
  breadcrumbAction: (payload) => dispatch(breadcrumbAction(payload)),
  getSearchDirData: (payload) => dispatch(getSearchDirData(payload)),
  setSlideBoxMovePopup: (payload) =>
    dispatch(setSlideBoxMovePopupAction(payload)),
  setSlideBoxMoveDir: (payload, callbackFn) =>
    dispatch(setSlideBoxMoveDirAction(payload, callbackFn)),
  resetMoveDirActive: (payload) => dispatch(resetMoveDirActive(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveToPopUp);
