import axios from "../../../utils/axiosInstance";
import { Notify } from "../../../components";
import {
  setAnnotionDataAction,
  updateAnnotionDataAction,
  deleteAnnotionDataAction,
  editAnnotionDataAction,
  publicAnnotationDataAction,
  toggleAnnotationDataAction,
  validateAnnotionDataAction,
  setSocketDataUpdateAction,
  setAnnotationDescriptionAction,
} from "../actions/viewer-action";

export const setAnnotations = async (options) => {
  let {
    annotationData,
    slideId,
    featureId,
    dispatch,
    when,
    callback,
  } = options;
  let {
    data: { success, mssg, insertId, insertData },
  } = await axios.post("/api/viewer/addAnnotation", {
    annotationData,
    slideId,
    featureId,
    when,
  });

  if (success) {
    dispatch(
      updateAnnotionDataAction({
        ...annotationData,
        pk: insertId,
        slide_id: slideId,
        user_id: insertData.user_id,
        first_name: insertData.first_name,
        surname: insertData.last_name,
        is_public: insertData.is_public,
        unique_id: insertData.unique_id,
        toggle: "on",
      })
    );
    callback();
  }
  when !== "quorum" && Notify({ value: mssg });
};

export const updateAnnotations = async (options) => {
  let { annotationData, slideId, dispatch, when, updateRedux = true } = options;
  let {
    data: { success, mssg, updateData },
  } = await axios.post("/api/viewer/updateAnnotation", {
    annotationData,
    slideId,
    when,
  });
  if (success) {
    dispatch(
      editAnnotionDataAction({
        annotationData: {
          ...annotationData,
          slide_id: slideId,
          unique_id: updateData.unique_id,
          user_id: updateData.user_id,
          first_name: updateData.first_name,
          surname: updateData.surname,
          is_public: updateData.is_public,
          isValidate: updateData.isValidate,
        },
        pk: annotationData["pk"],
      })
    );
  }

  Notify({ value: mssg });
};

export const getAnnotations = async (options) => {
  let { slideId, dispatch, when, featureId } = options;
  let {
    data: { success, mssg, annotationData },
  } = await axios.post("/api/viewer/getAnnotations", {
    slideId,
    when,
    featureId,
  });
  success ? dispatch(setAnnotionDataAction(annotationData)) : null;
};

export const getAnnotationDescription = async (options) => {
  let { slideId, dispatch } = options;
  let {
    data: { success, annotationDescription },
  } = await axios.post("/api/viewer/getAnnotationDescription", {
    slideId,
  });
  success
    ? dispatch(setAnnotationDescriptionAction(annotationDescription))
    : null;
};

export const deleteAnnotations = async (options) => {
  let { annotationId, dispatch } = options;
  let {
    data: { success, mssg },
  } = await axios.post("/api/viewer/deleteAnnotation", { annotationId });
  success ? dispatch(deleteAnnotionDataAction(annotationId)) : null;
  Notify({ value: mssg });
};

export const publishAnnotations = async (options) => {
  let { slide_id, status, user_id, dispatch } = options;
  let {
    data: { success, mssg },
  } = await axios.post("/api/viewer/publishAnnotation", {
    slideId: slide_id,
    status,
    when: "quorum",
  });
  success ? dispatch(publicAnnotationDataAction(options)) : null;
  Notify({ value: mssg });
};

export const toggleAnnotations = (options) => {
  let { dispatch } = options;
  if (options || options.length > 0) {
    dispatch(toggleAnnotationDataAction(options));
  }
};

export const validateAnnotations = async (options) => {
  let { annotationIds, userId, dispatch } = options;
  let {
    data: { success, mssg },
  } = await axios.post("/api/viewer/validateAnnoation", { annotationIds });
  success ? dispatch(validateAnnotionDataAction(annotationIds)) : null;
  Notify({ value: mssg });
};

export const setSocketDataUpdateFlase = (options) => {
  let { dispatch } = options;
  dispatch(setSocketDataUpdateAction(options));
};
