import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import DefaultAvatar from "../../../assets/icons/default-avatar.svg";
import "../Profile.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
  },
  typography: {
    padding: theme.spacing(2),
  },
  profileImg: {
    height: 125,
    width: 125,
  },
  popupStyling: {
    display: "flex",
    flexDirection: "row",
    height: "150px",
    alignItems: "center",
    justifyContent: "center",
    width: "380px",
    marginLeft: "-405px",
    marginTop: "-56px",
    boxShadow: "0px 2px 10px #999999",
  },
  popupStylingForDashboard: {
    display: "flex",
    flexDirection: "row",
    height: "150px",
    alignItems: "center",
    justifyContent: "center",
    width: "380px",
    boxShadow: "0px 2px 10px #999999",
  },
  upperCase: {
    textTransform: "capitalize",
  },
  nameStyles: {
    fontWeight: "bolder",
    fontSize: "large",
  },
  emailSpacing: {
    paddingTop: 0,
  },
  position: {
    top: "-72px",
    left: "-100px",
  },
  collaboratorsPosition: {
    zIndex: 9999,
  },
}));

const UserProfilePopup = ({
  openModal,
  anchorEl,
  userDetails,
  placement,
  isDashboard,
  isCollaborators,
  extraClass,
}) => {
  const classes = useStyles();

  return (
    <Popper
      open={openModal}
      anchorEl={anchorEl}
      placement={placement}
      transition
      className={
        isCollaborators
          ? classes.collaboratorsPosition
          : extraClass
          ? extraClass
          : "popper-styling"
      }
    >
      <Paper
        className={
          isDashboard
            ? classes.popupStylingForDashboard
            : classes.popupStylingForDashboard
        }
      >
        <img
          src={
            userDetails.userImageSrc !== null && userDetails.userImageSrc !== ""
              ? userDetails.userImageSrc
              : DefaultAvatar
          }
          className={classes.profileImg}
        />
        <List>
          <ListItem className={classes.nameStyles}>
            {userDetails.firstName} {userDetails.lastName}
          </ListItem>
          <ListItem className={classes.upperCase}>
            {userDetails.roleName}
          </ListItem>
          <ListItem className={classes.emailSpacing}>
            {userDetails.userEmail}
          </ListItem>
        </List>
      </Paper>
    </Popper>
  );
};

export default UserProfilePopup;
