import React, { useState } from "react";
import { connect } from "react-redux";

import Croppie from "croppie";

import { StepLabel, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FadeIn } from "animate-components";
import Select from "react-select";

import DefaultAvatar from "../../../assets/icons/default-avatar.svg";
import CloseIcon from "../../../assets/icons/close-image.svg";
import EditProfileIcon from "../../../assets/icons/edit-profile.svg";
import ChangeAvatarPreview from "./ChangeAvatarPreview";

import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Notify,
} from "../../../components";

import {
  updateProfileDetails,
  uploadProfilePic,
} from ".././actions/profile-action.js";

import { fetchUserBoards } from "../../groups/actions/groups-action";
import { getCaseDetailsInfoApi } from "../../slidebox/services/slidebox-service";
import { type } from "jquery";

const useStyles = makeStyles({
  inputFieldStyles: {
    // width: "50%",
    marginRight: "5%",
    height: 34,
  },
  inputFieldStylesDisabled: {
    // width: "50%",
    color: "black",
    backgroundColor: "#ededed",
  },
  inputField: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    width: "85%",
  },
  specialInputField: {
    display: "flex",
    flexDirection: "row",
    marginTop: 4,
    width: "85%",
  },
  inputControl: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  finalInput: {
    marginRight: 0,
  },
  required: {
    color: 'red',
  },
  email: {
    width: "95%",
    color: "black",
    backgroundColor: "#ededed",
  },
});

export const EditProfile = ({
  openModal,
  onClose,
  userDetails,
  profileDetails,
  updateUserProfileDetails,
  uploadProfilePhoto,
  profileUploadPending,
  fetchUserBoards,
}) => {
  const classes = useStyles();
  const [userName, setUserName] = useState(userDetails.userName);
  const [firstName, setFirstName] = useState(userDetails.firstName);
  const [middleName, setMiddleName] = useState(userDetails.middleName);
  const [lastName, setLastName] = useState(userDetails.lastName);
  const [gender, setGender] = useState(userDetails.gender);
  const [homePhone, setHomePhone] = useState(userDetails.homePhone);
  const [mobilePhone, setMobilePhone] = useState(userDetails.mobilePhone);
  const [designation, setDesignation] = useState(userDetails.designation);
  const [availability, setAvailability] = useState(userDetails.availability);
  const [department, setDepartment] = useState(userDetails.department);
  const [lab, setLab] = useState(userDetails.lab);
  const [location, setLocation] = useState(userDetails.location);
  const [nationalIdentityNumber, setNationalIdentityNumber] = useState(
    userDetails.nationalIdentityNumber
  );
  const [userLicenseNumber, setUserLicenseNumber] = useState(
    userDetails.userLicenseNumber
  );
  const [employeeId, setEmployeeId] = useState(userDetails.employeeId);

  const [croppieInstance, setCroppieInstance] = useState(null);
  const [inputImageLoad, setInputImageLoad] = useState(false);
  const [changeAvatar, setChangeAvatar] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(
    userDetails.userImageSrc
  );
  const uploadedImage = React.useRef(null);
  const uploadPhotoRef = React.useRef(null);

  const genderOps = [
    { value: "male", label: "male" },
    { value: "female", label: "female" },
    { value: "other", label: "other" },
  ];

  const genderOptions = genderOps.map((data) => {
    return {
      value: data.value,
      label: data.label,
    };
  });

  const handleGenderChange = (e) => {
    setGender(e.value);
    console.log(e);
    /* setGender(e.value);
     */
    console.log(gender);
  };

  const designationOps = [
    {
      designationType: "Biomedical laboratory scientist ",
    },
    {
      designationType: "Anatomic pathologist",
    },
    {
      designationType: "Biomedical technician",
    },
    {
      designationType: "Laboratory quality manager",
    },
    {
      designationType: "Clerical support worker",
    },
    {
      designationType: "Account Executive",
    },
    {
      designationType: "Account Manager",
    },
    {
      designationType: "Accountant",
    },
    {
      designationType: "Accounting Clerk",
    },
    {
      designationType: "Accounting Manager",
    },
    {
      designationType: "Administrative Assistant",
    },
    {
      designationType: "Administrative Medical Assistant",
    },
    {
      designationType: "Administrator",
    },
    {
      designationType: "Admissions Clerk",
    },
    {
      designationType: "Admissions Director",
    },
    {
      designationType: "Analyst",
    },
    {
      designationType: "Assistant Administrator",
    },
    {
      designationType: "Assistant Admissions Director",
    },
    {
      designationType: "Assistant Director of Nursing",
    },
    {
      designationType: "Bereavement Coordinator",
    },
    {
      designationType: "Billing Manager",
    },
    {
      designationType: "Billing Specialist",
    },
    {
      designationType: "Business Analyst",
    },
    {
      designationType: "Case Manager",
    },
    {
      designationType: "Chief Financial Officer",
    },
    {
      designationType: "Claims Examiner",
    },
    {
      designationType: "Claims Specialist",
    },
    {
      designationType: "Clerk",
    },
    {
      designationType: "Clinical Coordinator",
      undefined: "Recovery Services",
    },
    {
      designationType: "Coder",
    },
    {
      designationType: "Coding Educator",
    },
    {
      designationType: "Computer Analyst",
    },
    {
      designationType: "Computer Programmer",
    },
    {
      designationType: "Consultant",
    },
    {
      designationType: "Coordinator",
    },
    {
      designationType: "Customer Service Representative",
    },
    {
      designationType: "Director of Nursing",
    },
    {
      designationType: "Director of Operations",
    },
    {
      designationType: "Director of Rehabilitation",
    },
    {
      designationType: "Executive Assistant",
    },
    {
      designationType: "Executive Director",
    },
    {
      designationType: "Financial Analyst",
    },
    {
      designationType: "Front Office Clerk",
    },
    {
      designationType: "Health Facilities Surveyor",
    },
    {
      designationType: "Health Services Manager",
    },
    {
      designationType: "Healthcare Administrator",
    },
    {
      designationType: "Healthcare Management",
    },
    {
      designationType: "Healthcare Specialist",
    },
    {
      designationType: "Hospice Administrator",
    },
    {
      designationType: "Hospital Administrator",
    },
    {
      designationType: "Information Technology Specialist",
    },
    {
      designationType: "Medical Administrative",
    },
    {
      designationType: "Medical Assistant",
    },
    {
      designationType: "Medical Assistant or Receptionist",
    },
    {
      designationType: "Medical Associate",
    },
    {
      designationType: "Medical Billing Specialist",
    },
    {
      designationType: "Medical Claims and Billing Specialist",
    },
    {
      designationType: "Medical Coder",
    },
    {
      designationType: "Medical Manager",
    },
    {
      designationType: "Medical Office Assistant",
    },
    {
      designationType: "Medical Office Manager",
    },
    {
      designationType: "Medical Office Specialist",
    },
    {
      designationType: "Medical or Health Services Manager",
    },
    {
      designationType: "Medical Receptionist",
    },
    {
      designationType: "Medical Records Clerk",
    },
    {
      designationType: "Medical Records Director",
    },
    {
      designationType: "Medical Records Technician",
    },
    {
      designationType: "Medical Sales",
    },
    {
      designationType: "Medical Secretary",
    },
    {
      designationType: "Medical Technologist",
    },
    {
      designationType: "Medical Transcriptionist",
    },
    {
      designationType: "Mobile Director of Nursing Services",
    },
    {
      designationType: "Nursing Home Administrator",
    },
    {
      designationType: "Office Assistant",
    },
    {
      designationType: "Office Clerk",
    },
    {
      designationType: "Office Manager",
    },
    {
      designationType: "Operations Manager",
    },
    {
      designationType: "Patient Access Supervisor",
    },
    {
      designationType: "Patient Care Associate",
    },
    {
      designationType: "Patient Services Representative",
    },
    {
      designationType: "Patient Services Technician",
    },
    {
      designationType: "Pharmaceutical Sales",
    },
    {
      designationType: "Pharmaceutical Sales Representative",
    },
    {
      designationType: "Program Director",
    },
    {
      designationType: "Program Manager",
    },
    {
      designationType: "Programmer",
    },
    {
      designationType: "Programmer Analyst",
    },
    {
      designationType: "Project Manager",
    },
    {
      designationType: "Quality Coordinator",
    },
    {
      designationType: "Receptionist",
    },
    {
      designationType: "Recruiter",
    },
    {
      designationType: "Regional Sales Manager",
    },
    {
      designationType: "Safety Surveillance Associate",
    },
    {
      designationType: "Sales Associate",
    },
    {
      designationType: "Sales Manager",
    },
    {
      designationType: "Sales Representative",
    },
    {
      designationType: "Secretary",
    },
    {
      designationType: "Senior Programmer Analyst",
    },
    {
      designationType: "Social Services",
    },
    {
      designationType: "Software Developer",
    },
    {
      designationType: "Software Engineer",
    },
    {
      designationType: "Staffing Coordinator",
    },
    {
      designationType: "Supervisor",
    },
    {
      designationType: "Transcriptionist",
    },
    {
      designationType: "Ambulatory Nurse",
    },
    {
      designationType: "Anesthesiologist",
    },
    {
      designationType: "Audiologist",
    },
    {
      designationType: "Behavioral Health Charge Nurse",
    },
    {
      designationType: "Bereavement Counselor",
    },
    {
      designationType: "Cardiac Catheterization Lab Nurse",
    },
    {
      designationType: "Cardiovascular Operating Room Nurse",
    },
    {
      designationType: "Cardiovascular Technologist",
    },
    {
      designationType: "Certified Nursing Assistant",
    },
    {
      designationType: "Charge Nurse",
    },
    {
      designationType: "Chiropractor",
    },
    {
      designationType: "Counselor",
    },
    {
      designationType: "Dentist",
    },
    {
      designationType: "Dermatology Nurse",
    },
    {
      designationType: "Dialysis Nurse",
    },
    {
      designationType: "Doctor",
    },
    {
      designationType: "Emergency Room Nurse",
    },
    {
      designationType: "Endoscopy Nurse",
    },
    {
      designationType: "Family Nurse Practitioner",
    },
    {
      designationType: "Flight Nurse",
    },
    {
      designationType: "Genetic Counselor",
    },
    {
      designationType: "Home Health Nurse",
    },
    {
      designationType: "Hospice Counselor",
    },
    {
      designationType: "Hospice Nurse",
    },
    {
      designationType: "House Supervisor Nurse",
    },
    {
      designationType: "Intensive Care Nurse",
    },
    {
      designationType: "Interventional Radiology Nurse",
    },
    {
      designationType: "Labor and Delivery Nurse",
    },
    {
      designationType: "Lead Registered Nurse",
    },
    {
      designationType: "Legal Nurse Consultant",
    },
    {
      designationType: "Licensed Practical Nurse",
    },
    {
      designationType: "Licensed Vocational Nurse",
    },
    {
      designationType: "Medical Surgery Nurse",
    },
    {
      designationType: "Microbiologist",
    },
    {
      designationType: "Neonatal Intensive Care Nurse",
    },
    {
      designationType: "Nurse",
    },
    {
      designationType: "Nurse Anesthetist",
    },
    {
      designationType: "Nurse Midwife",
    },
    {
      designationType: "Nurse Practitioner",
    },
    {
      designationType: "Occupational Health Nurse",
    },
    {
      designationType: "Occupational Health and Safety Specialist",
    },
    {
      designationType: "Occupational Therapist",
    },
    {
      designationType: "Office Nurse",
    },
    {
      designationType: "Oncology Nurse",
    },
    {
      designationType: "Operating Room Nurse",
    },
    {
      designationType: "Optician",
    },
    {
      designationType: "Optometrist",
    },
    {
      designationType: "Orthodontist",
    },
    {
      designationType: "Orthotist",
    },
    {
      designationType: "Outreach RN",
    },
    {
      designationType: "Paramedic",
    },
    {
      designationType: "Pediatrician",
    },
    {
      designationType: "Pediatric Endocrinology Nurse",
    },
    {
      designationType: "Pediatric Intensive Care Nurse",
    },
    {
      designationType: "Pediatric Nurse",
    },
    {
      designationType: "Pediatric Nurse Practitioner",
    },
    {
      designationType: "Perioperative Nurse",
    },
    {
      designationType: "Pharmacist",
    },
    {
      designationType: "Prosthetist",
    },
    {
      designationType: "Physician",
    },
    {
      designationType: "Podiatrist",
    },
    {
      designationType: "Post Anesthesia Nurse",
    },
    {
      designationType: "Postpartum Nurse",
    },
    {
      designationType: "Progressive Care Nurse",
    },
    {
      designationType: "Psychiatric Nurse",
    },
    {
      designationType: "Psychiatric Nurse Practitioner",
    },
    {
      designationType: "Public Health Nurse",
    },
    {
      designationType: "Registered Nurse (RN)",
    },
    {
      designationType: "Registered Nurse (RN) Case Manager",
    },
    {
      designationType: "Registered Nurse(RN) Data Coordinator",
    },
    {
      designationType: "Registered Nurse (RN) First Assistant",
    },
    {
      designationType: "Registered Nurse (RN) Geriatric Care",
    },
    {
      designationType: "Registered Nurse (RN) Medical Inpatient Services",
    },
    {
      designationType: "Registered Nurse (RN) Patient Call Center",
    },
    {
      designationType: "Registered Nurse (RN) Student Health Services",
    },
    {
      designationType: "Registered Nurse (RN) Telephone Triage",
    },
    {
      designationType: "Registered Nurse (RN) Urgent Care",
    },
    {
      designationType: "Registered Nurse (RN) Women's Services",
    },
    {
      designationType: "Restorative Nurse",
    },
    {
      designationType: "Registered Medical Assistant",
    },
    {
      designationType: "Respiration (Inhalation) Therapist",
    },
    {
      designationType: "School Nurse",
    },
    {
      designationType: "Speech-Language Pathologist",
    },
    {
      designationType: "Surgeon",
    },
    {
      designationType: "Telemetry Nurse",
    },
    {
      designationType: "Therapist",
    },
    {
      designationType: "Veterinarian",
    },
    {
      designationType: "Veterinary Assistant",
    },
    {
      designationType: "Veterinary Technologist",
    },
    {
      designationType: "Wellness Nurse",
    },
    {
      designationType: "Athletic Trainer",
    },
    {
      designationType: "Certified Medical Assistant",
    },
    {
      designationType: "Certified Nurse Assistant",
    },
    {
      designationType: "Certified Nursing Assistant",
    },
    {
      designationType: "Clinical Liaison",
    },
    {
      designationType: "Clinical Nurse Manager",
    },
    {
      designationType: "Clinical Research Associate",
    },
    {
      designationType: "Clinical Research Coordinator",
    },
    {
      designationType: "Clinical Reviewer",
    },
    {
      designationType: "Clinical Specialist",
    },
    {
      designationType: "Dental Assistant",
    },
    {
      designationType: "Dental Hygienist",
    },
    {
      designationType: "Dietitian",
    },
    {
      designationType: "Exercise Physiologist",
    },
    {
      designationType: "Health Educator",
    },
    {
      designationType: "Home Health Aide",
    },
    {
      designationType: "Hospice Aide",
    },
    {
      designationType: "Massage Therapist",
    },
    {
      designationType: "Nurse Aide",
    },
    {
      designationType: "Nurse Clinical Educator",
    },
    {
      designationType: "Nurse Consultant",
    },
    {
      designationType: "Nurse Informatics Analyst",
    },
    {
      designationType: "Nurse Manager",
    },
    {
      designationType: "Nurse Paralegal",
    },
    {
      designationType: "Nutritionist",
    },
    {
      designationType: "Occupational Therapy Assistant",
    },
    {
      designationType: "Orderly Attendant",
    },
    {
      designationType: "Pharmacy Clerk",
    },
    {
      designationType: "Pharmacy Technician",
    },
    {
      designationType: "Physical Therapist Assistant",
    },
    {
      designationType: "Physician Aide",
    },
    {
      designationType: "Physician Assistant",
    },
    {
      designationType: "Psychiatric Aide",
    },
    {
      designationType: "Radiation Therapist",
    },
    {
      designationType: "Recreational Therapist",
    },
    {
      designationType: "Regional Kidney Smart Educator",
    },
    {
      designationType: "Athletic Trainer",
    },
    {
      designationType: "Certified Medical Assistant",
    },
    {
      designationType: "Certified Nurse Assistant",
    },
    {
      designationType: "Certified Nursing Assistant",
    },
    {
      designationType: "Clinical Liaison",
    },
    {
      designationType: "Clinical Nurse Manager",
    },
    {
      designationType: "Clinical Research Associate",
    },
    {
      designationType: "Clinical Research Coordinator",
    },
    {
      designationType: "Clinical Reviewer",
    },
    {
      designationType: "Clinical Specialist",
    },
    {
      designationType: "Dental Assistant",
    },
    {
      designationType: "Dental Hygienist",
    },
    {
      designationType: "Dietitian",
    },
    {
      designationType: "Exercise Physiologist",
    },
    {
      designationType: "Health Educator",
    },
    {
      designationType: "Home Health Aide",
    },
    {
      designationType: "Hospice Aide",
    },
    {
      designationType: "Massage Therapist",
    },
    {
      designationType: "Nurse Aide",
    },
    {
      designationType: "Nurse Clinical Educator",
    },
    {
      designationType: "Nurse Consultant",
    },
    {
      designationType: "Nurse Informatics Analyst",
    },
    {
      designationType: "Nurse Manager",
    },
    {
      designationType: "Nurse Paralegal",
    },
    {
      designationType: "Nutritionist",
    },
    {
      designationType: "Occupational Therapy Assistant",
    },
    {
      designationType: "Orderly Attendant",
    },
    {
      designationType: "Pharmacy Clerk",
    },
    {
      designationType: "Physical Therapist Assistant",
    },
    {
      designationType: "Physician Aide",
    },
    {
      designationType: "Physician Assistant",
    },
    {
      designationType: "Psychiatric Aide",
    },
    {
      designationType: "Radiation Therapist",
    },
    {
      designationType: "Recreational Therapist",
    },
    {
      designationType: "Regional Kidney Smart Educator",
    },
  ];

  const designationOptions = designationOps.map((data) => {
    return {
      value: data.designationType,
      label: data.designationType,
    };
  });

  const handleDesignationChange = (e) => {
    console.log(e.value);
    setDesignation(e.value);
  };

  const departmentOps = [
    {
      availabilityType: "Operating Room",
    },
    {
      availabilityType: "Recovery Room",
    },
    {
      availabilityType: "Delivery Room / Labor Room",
    },
    {
      availabilityType: "Anesthesiology",
    },
    {
      availabilityType: "Radiology - Diagnostic",
    },
    {
      availabilityType: "Radiology - Therapeutic",
    },
    {
      availabilityType: "Radioisotope",
    },
    {
      availabilityType: "Computed Tomography (CT) Scan",
    },
    {
      availabilityType: "Magnetic Resonance Imaging (MRI)",
    },
    {
      availabilityType: "Cardiac Catheterization",
    },
    {
      availabilityType: "Laboratory",
    },
    {
      availabilityType: "PBP Clinical Lab Services",
    },
    {
      availabilityType: "Whole Blood / Packed RBC",
    },
    {
      availabilityType: "Blood Stor",
    },
    {
      availabilityType: "IV Therapy",
    },
    {
      availabilityType: "Respiratory Therapy",
    },
    {
      availabilityType: "Physical Therapy",
    },
    {
      availabilityType: "Occupational Therapy",
    },
    {
      availabilityType: "Speech Pathology",
    },
    {
      availabilityType: "Electrocardiology",
    },
    {
      availabilityType: "Electroencephalography",
    },
    {
      availabilityType: "Medical Supplies (charged)",
    },
    {
      availabilityType: "Implantable Devices (charged)",
    },
    {
      availabilityType: "Drugs (charged)",
    },
    {
      availabilityType: "Renal Dialysis",
    },
    {
      availabilityType: "ASC (non-distinct part)",
    },
    {
      availabilityType: "Clinic",
    },
    {
      availabilityType: "Emergency",
    },
    {
      availabilityType: "Observation Beds",
    },
    {
      availabilityType: "General Med/Surg",
    },
    {
      availabilityType: "Intensive Care Unit",
    },
    {
      availabilityType: "Coronary Care Unit",
    },
    {
      availabilityType: "Burn ICU",
    },
    {
      availabilityType: "Surgical ICU",
    },
    {
      availabilityType: "Psychiatric ICU",
    },
    {
      availabilityType: "Pediatric ICU",
    },
    {
      availabilityType: "Neonatal ICU",
    },
    {
      availabilityType: "Trauma ICU",
    },
    {
      availabilityType: "Detox ICU",
    },
    {
      availabilityType: "Premature ICU",
    },
    {
      availabilityType: "Psych Subprovider",
    },
    {
      availabilityType: "Rehab Subprovider",
    },
    {
      availabilityType: "Other Subprovider",
    },
    {
      availabilityType: "Nursery",
    },
    {
      availabilityType: "Skilled Nursing (SNF)",
    },
    {
      availabilityType: "Nursing Facility",
    },
    {
      availabilityType: "Other Long Term Care",
    },
    {
      availabilityType: "Pathology/Laboratory Department",
    },
    {
      availabilityType: "Radiology/Imaging Department",
    },
    {
      availabilityType: "Administrative",
    },
  ];

  const departmentOptions = departmentOps.map((data) => {
    return {
      value: data.availabilityType,
      label: data.availabilityType,
    };
  });

  const handleDepartmentChange = (e) => {
    console.log(e);
    setDepartment(e.value);
  };

  const availabilityOps = [
    {
      availabilityType: "Full Time",
    },
    {
      availabilityType: "Part Time",
    },
    {
      availabilityType: "Consultation",
    },
  ];

  const availabilityOptions = availabilityOps.map((data) => {
    return {
      value: data.availabilityType,
      label: data.availabilityType,
    };
  });

  const handleAvailabilityChange = (e) => {
    setAvailability(e.value);
  };

  const labOps = [
    {
      labType: "Lab A",
    },
    {
      labType: "Lab B",
    },
    {
      labType: "Lab C",
    },
    {
      labType: "Lab D",
    },
  ];

  const labOptions = labOps.map((data) => {
    return {
      value: data.labType,
      label: data.labType,
    };
  });

  const handleLabChange = (e) => {
    setLab(e.value);
  };

  const locationOps = [
    {
      locationType: "Location A",
    },
    {
      locationType: "Location B",
    },
    {
      locationType: "Location C",
    },
    {
      locationType: "Location D",
    },
  ];

  const locationOptions = locationOps.map((data) => {
    return {
      value: data.locationType,
      label: data.locationType,
    };
  });

  const handleLocationChange = (e) => {
    setLocation(e.value);
  };

  const fileChange = async ({ target }) => {
    const screenWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    let viewportSize, boundarySize;
    if (screenWidth <= 768) {
      viewportSize = 100;
      boundarySize = 138;
    } else if (screenWidth < 993) {
      viewportSize = 137;
      boundarySize = 175;
    } else {
      viewportSize = 150;
      boundarySize = 200;
    }
    await setChangeAvatar(true);
    let profileImage = new Croppie(
      document.getElementById("create-profile-image"),
      {
        viewport: { width: viewportSize, height: viewportSize, type: "circle" },
        boundary: { width: boundarySize, height: boundarySize },
        showZoomer: true,
        enableOrientation: true,
      }
    );

    let input = target;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        profileImage
          .bind({
            url: e.target.result,
          })
          .then(function() {
            //console.log('jQuery bind complete');
            // console.log(profileImage.data.url);
            setProfileImageUrl(profileImage.data.url);
          });
      };
      reader.readAsDataURL(input.files[0]);
    } else {
      // swal("Sorry - you're browser doesn't support the FileReader API");
    }
    setCroppieInstance(profileImage);
    setInputImageLoad(true);
  };

  const uploadProfileHandler = () => {
    croppieInstance
      .result({ type: "blob", size: "original" })
      .then((blobData) => {
        const options = {
          file: blobData,
          user_id: userDetails.userID,
        };
        uploadAvatar(options);
      });
  };

  const uploadAvatar = (options) => {
    uploadProfilePhoto({ file: options.file, userId: userDetails.userID });
    setProfileImageUrl(croppieInstance.data.url);
    setChangeAvatar(false);
  };

  const handleSaveClick = () => {
    updateUserProfileDetails(
      {
        userId: userDetails.userID,
        username: userName,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        gender: gender,
        homePhone: homePhone,
        mobilePhone: mobilePhone,
        nationalIdentityNumber: nationalIdentityNumber,
        userLicenseNumber: userLicenseNumber,
        employeeId: employeeId,
        designation: designation,
        availability: availability,
        department: department,
        lab: lab,
        location: location,
      },
      {
        onSuccess: afterEditProfileDetailsUpdateSuccess,
        onError: afterProfileDetailsUpdateFail,
      }
    );
    console.log(gender);

    onClose(false);
  };

  const afterEditProfileDetailsUpdateSuccess = () => {
    Notify({ value: "Profile updated successfully!" });
    fetchUserBoards();
  };

  const afterProfileDetailsUpdateFail = () => {
    Notify({ value: "Unable to update profile" });
  };

  const handleChange = (e) => {
    setFirstName(e.target.value);
  };

  const cancleUpload = () => {
    setChangeAvatar(false);
    setInputImageLoad(false);
    setProfileImageUrl(userDetails.userImageSrc);
    removePreviewImg();
  };
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const removePreviewImg = () => {
    uploadPhotoRef.current.value = null;
  };

  React.useEffect(() => {
    if (profileDetails.url !== undefined) {
      setProfileImageUrl(profileDetails.url);
    }
  }, []);

  return (
    openModal && (
      <React.Fragment>
        <Overlay />
        <div className="prompt edit-profile">
          <FadeIn duration="300ms">
            <ModalHeader
              title="Edit Profile"
              onClick={() => {
                onClose(false);
                removePreviewImg(); //this
                setUserName(userDetails.userName);
                setFirstName(userDetails.firstName);
                setMiddleName(userDetails.middleName);
                setLastName(userDetails.lastName);
                setGender(userDetails.gender);
                setHomePhone(userDetails.homePhone);
                setMobilePhone(userDetails.mobilePhone);
                setNationalIdentityNumber(userDetails.nationalIdentityNumber);
                setUserLicenseNumber(userDetails.userLicenseNumber);
                setEmployeeId(userDetails.employeeId);
                setDesignation(userDetails.designation);
                setAvailability(userDetails.availability);
                setDepartment(userDetails.department);
                setLab(userDetails.lab);
                setLocation(userDetails.location);
                setProfileImageUrl(userDetails.userImageSrc);
                setChangeAvatar(false);
              }}
              img={EditProfileIcon}
              alt={"edit-profile"}
            />
            <div className="modal_middle">
              <div className="image-section">
                <input
                  type="file"
                  id="file_input"
                  accept="image/*"
                  onChange={fileChange}
                  ref={uploadPhotoRef}
                />
                {changeAvatar && (
                  <div className="close-image" onClick={cancleUpload}>
                    <img src={CloseIcon} alt="close" />
                  </div>
                )}
                {changeAvatar ? (
                  <ChangeAvatarPreview
                    uploadProfileHandler={uploadProfileHandler}
                    cancleUpload={cancleUpload}
                  />
                ) : (
                  <img
                    src={
                      profileImageUrl !== null ? profileImageUrl : DefaultAvatar
                    }
                    width="150px"
                    height="150px"
                    ref={uploadedImage}
                    style={{ borderRadius: "50%" }}
                  />
                )}

                {!changeAvatar && (
                  <button
                    className="button-styles"
                    onClick={() => uploadPhotoRef.current.click()}
                  >
                    {profileUploadPending ? "Uploading..." : "Upload an Image"}
                  </button>
                )}
              </div>

              <div className="profile-details" data-test="edit-product">
                <div className="username-role">
                  <div className={classes.specialInputField}>
                    <div className={classes.inputControl} style={{ width: '100%' }}>
                      <label htmlFor="username">Username <sup className={classes.required}>*</sup></label>
                      <TextField
                        placeholder="Username *"
                        InputProps={{
                          style: { fontSize: "14px" },
                          disableUnderline: true,
                        }}
                        name="username"
                        data-test="username"
                        value={userName}
                        className={classes.inputFieldStyles}
                        onChange={(e) => setUserName(e.target.value)}
                        required
                        disabled
                      />
                    </div>
                    <div className={classes.inputControl} style={{ width: '100%' }}>
                      <label htmlFor="role">Role</label>
                      <TextField
                        placeholder="Role"
                        InputProps={{
                          style: { fontSize: "14px" },
                          disableUnderline: true,
                        }}
                        name="role"
                        value={capitalizeFirstLetter(userDetails.roleName)}
                        className={classes.inputFieldStylesDisabled}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.inputField}>
                  <div>
                  <label htmlFor="firstName">First Name <sup className={classes.required}>*</sup></label>
                    <TextField
                      placeholder="First Name *"
                      InputProps={{
                        style: { fontSize: "14px" },
                        disableUnderline: true,
                      }}
                      name="firstName"
                      value={firstName}
                      onChange={handleChange}
                      className={classes.inputFieldStyles}
                    />
                  </div>
                  <div>
                    <label htmlFor="middleName">Middle Name</label>
                    <TextField
                      placeholder="Middle Name"
                      InputProps={{
                        style: { fontSize: "14px" },
                        disableUnderline: true,
                      }}
                      name="middleName"
                      value={middleName}
                      onChange={(e) => {
                        setMiddleName(e.target.value);
                      }}
                      className={classes.inputFieldStyles}
                    />
                  </div>
                  <div>
                    <label htmlFor="lastName">Last Name <sup className={classes.required}>*</sup></label>
                    <TextField
                      placeholder="Last Name *"
                      className={`${classes.inputFieldStyles} ${classes.finalInput}`}
                      InputProps={{
                        style: { fontSize: "14px", color: "#2D1956" },
                        disableUnderline: true,
                      }}
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>

                <div className={classes.specialInputField}>
                  <div style={{ width: '100%' }}>
                    <label htmlFor="gender">Gender <sup className={classes.required}>*</sup></label>
                    <Select
                      options={genderOptions}
                      placeholder="Gender *"
                      customStyles={true}
                      isSearchable={false}
                      className={classes.inputFieldStyles}
                      style={{
                        width: "100%",

                        input: { fontSize: "14px" },
                        placeholder: { fontSize: "14px" },
                        color: "#9e9ea6",
                        focus: "disabled",
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      name="gender"
                      onChange={handleGenderChange}
                      defaultValue={{
                        label:
                          gender === null || gender === "" ? "Gender" : gender,
                        value:
                          gender === null || gender === "" ? "Gender" : gender,
                      }}
                    />
                  </div>
                  <div style={{ width: '100%' }} className={classes.inputControl}>
                    <label htmlFor="dob">Date of Birth <sup className={classes.required}>*</sup></label>
                    <TextField
                      InputProps={{
                        style: { fontSize: "14px" },
                        disableUnderline: true,
                      }}
                      placeholder="Date of Birth *"
                      name="dob"
                      className={classes.inputFieldStylesDisabled}
                      value={userDetails.dob}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className={classes.inputField}>
                  <div className={classes.inputControl}>
                    <label htmlFor="homePhone">Home Phone Number</label>
                    <TextField
                      placeholder="Home Phone Number"
                      InputProps={{
                        style: { fontSize: "14px" },
                        disableUnderline: true,
                      }}
                      name="homePhone"
                      value={homePhone}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        setHomePhone(value);
                        console.log(homePhone);
                      }}
                      className={classes.inputFieldStyles}
                    />
                  </div>
                  <div className={classes.inputControl}>
                    <label htmlFor="mobilePhone">Mobile Phone Number <sup className={classes.required}>*</sup></label>
                    <TextField
                      placeholder="Mobile Phone Number *"
                      InputProps={{
                        style: { fontSize: "14px" },
                        disableUnderline: true,
                      }}
                      name="mobilePhone"
                      value={mobilePhone}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        setMobilePhone(value);
                        console.log(mobilePhone);
                      }}
                      className={`${classes.inputFieldStyles} ${classes.finalInput}`}
                    />
                  </div>
                </div>

                <div style={{ width: '90%' }} className={`${classes.inputField} ${classes.inputControl}`}>
                  <label htmlFor="email">Email</label>
                  <TextField
                    placeholder="Email"
                    InputProps={{
                      style: { fontSize: "14px" },
                      disableUnderline: true,
                    }}
                    name="email"
                    value={userDetails.userEmail}
                    className={classes.email}
                    disabled={true}
                  />
                </div>

                <div className={classes.inputField}>
                  <div className={classes.inputControl}>
                    <label htmlFor="nationalIdentityNumber">National Identity Number</label>
                    <TextField
                      placeholder="National Identity Number "
                      InputProps={{
                        style: { fontSize: "14px" },
                        disableUnderline: true,
                      }}
                      name="nationalIdentityNumber"
                      value={nationalIdentityNumber}
                      onChange={(e) => {
                        setNationalIdentityNumber(e.target.value);
                      }}
                      className={classes.inputFieldStyles}
                    />
                  </div>
                  <div className={classes.inputControl}>
                    <label htmlFor="userLicenseNumber">License Number <sup className={classes.required}>*</sup></label>
                    <TextField
                      placeholder="License Number *"
                      InputProps={{
                        style: { fontSize: "14px" },
                        disableUnderline: true,
                      }}
                      name="userLicenseNumber"
                      value={userLicenseNumber}
                      onChange={(e) => {
                        setUserLicenseNumber(e.target.value);
                      }}
                      className={`${classes.inputFieldStyles} ${classes.finalInput}`}
                    />
                  </div>
                </div>
                <div className={classes.inputField}>
                  <div className={classes.inputControl}>
                    <label htmlFor="employeeId">Employee ID</label>
                    <TextField
                      placeholder="Employee ID"
                      InputProps={{
                        style: { fontSize: "14px" },
                        disableUnderline: true,
                      }}
                      name="employeeId"
                      value={employeeId}
                      onChange={(e) => {
                        setEmployeeId(e.target.value);
                      }}
                      className={classes.inputFieldStyles}
                    />
                  </div>
                  <div className={classes.inputControl}>
                    <label htmlFor="designation">Designation</label>
                    <Select
                      options={designationOptions}
                      placeholder="Designation"
                      customStyles={true}
                      isSearchable={false}
                      className={`${classes.inputFieldStyles} ${classes.finalInput}`}
                      style={{
                        width: "100%",
                        input: { fontSize: "14px" },
                        placeholder: { fontSize: "14px" },
                        color: "#9e9ea6",
                        focus: "disabled",
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      name="designation"
                      onChange={handleDesignationChange}
                      defaultValue={{
                        label:
                          designation === null || designation === ""
                            ? "Designation"
                            : designation,
                        value:
                          designation === null || designation === ""
                            ? "Designation"
                            : designation,
                      }}
                      menuPlacement="top"
                    />
                  </div>
                </div>
                <div className={classes.inputField}>
                  <div className={classes.inputControl}>
                  <label htmlFor="availability">Availability</label>
                  <Select
                    options={availabilityOptions}
                    placeholder="Availability"
                    customStyles={true}
                    isSearchable={false}
                    className={classes.email}
                    style={{
                      width: "100%",
                      marginTop: "5%",
                      input: { fontSize: "14px" },
                      placeholder: { fontSize: "14px" },
                      color: "#9e9ea6",
                      focus: "disabled",
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    name="availability"
                    onChange={handleAvailabilityChange}
                    /* value={userDetails.availability} */
                    defaultValue={{
                      label:
                        availability === null || availability === ""
                          ? "Availability"
                          : availability,
                      value:
                        availability === null || availability === ""
                          ? "Availability"
                          : availability,
                    }}
                    menuPlacement="top"
                  />
                  </div>
                  <div className={classes.inputControl}>
                    <label htmlFor="department">Department</label>
                    <Select
                      options={departmentOptions}
                      placeholder="Department"
                      customStyles={true}
                      isSearchable={false}
                      className={`${classes.inputFieldStyles} ${classes.finalInput}`}
                      style={{
                        width: "100%",
                        marginTop: "5%",
                        input: { fontSize: "14px" },
                        placeholder: { fontSize: "14px" },
                        color: "#9e9ea6",
                        focus: "disabled",
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      name="department"
                      onChange={handleDepartmentChange}
                      defaultValue={{
                        label:
                          department === null || department === ""
                            ? "Department"
                            : department,
                        value:
                          department === null || department === ""
                            ? "Department"
                            : department,
                      }}
                      menuPlacement="top"
                    />
                  </div>
                </div>
                {/* <div className={`${classes.inputField} ${classes.inputControl}`} style={{ width: '90%' }}>
                  <label htmlFor="availability">Availability</label>
                  <Select
                    options={availabilityOptions}
                    placeholder="Availability"
                    customStyles={true}
                    isSearchable={false}
                    className={classes.email}
                    style={{
                      width: "100%",
                      marginTop: "5%",
                      input: { fontSize: "14px" },
                      placeholder: { fontSize: "14px" },
                      color: "#9e9ea6",
                      focus: "disabled",
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    name="availability"
                    onChange={handleAvailabilityChange}
                    /* value={userDetails.availability}
                    defaultValue={{
                      label:
                        availability === null || availability === ""
                          ? "Availability"
                          : availability,
                      value:
                        availability === null || availability === ""
                          ? "Availability"
                          : availability,
                    }}
                    menuPlacement="top"
                  />
                </div> */}
                <div className={classes.inputField}>
                  <div className={`${classes.inputControl}`}>
                    <label htmlFor="lab">Lab</label>
                    <Select
                      options={labOptions}
                      placeholder="lab"
                      customStyles={true}
                      isSearchable={false}
                      className={classes.inputFieldStyles}
                      style={{
                        width: "100%",
                        marginTop: "5%",
                        input: { fontSize: "14px" },
                        placeholder: { fontSize: "14px" },
                        color: "#9e9ea6",
                        focus: "disabled",
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      name="lab"
                      onChange={handleLabChange}
                      defaultValue={{
                        label: lab === null || lab === "" ? "Lab" : lab,
                        value: lab === null || lab === "" ? "Lab" : lab,
                      }}
                      menuPlacement="top"
                    />
                  </div>

                  <div className={`${classes.inputControl}`}>
                    <label htmlFor="location">Location</label>
                    <Select
                      options={locationOptions}
                      placeholder="Location"
                      customStyles={true}
                      isSearchable={false}
                      className={`${classes.inputFieldStyles}`}
                      style={{
                        width: "100%",
                        marginTop: "5%",
                        input: { fontSize: "14px" },
                        placeholder: { fontSize: "14px" },
                        color: "#9e9ea6",
                        focus: "disabled",
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      name="location"
                      onChange={handleLocationChange}
                      defaultValue={{
                        label:
                          location === null || location === ""
                            ? "Location"
                            : location,
                        value:
                          location === null || location === ""
                            ? "Location"
                            : location,
                      }}
                      menuPlacement="top"
                    />
                  </div>
                  <div className={`${classes.inputControl}`}>
                    <label htmlFor="workgroups">Workgroups</label>
                    <TextField
                      value={userDetails.workgroupNames.map(
                        (workgroup) => workgroup.workgroupName
                      )}
                      placeholder="Workgroups"
                      InputProps={{
                        style: { fontSize: "14px" },
                        disableUnderline: true,
                      }}
                      name="workgroups"
                      disabled={true}
                    />
                  </div>
                </div>
                {/* <div className={`${classes.inputField} ${classes.inputControl}`} style={{ width: '90%' }}>
                  <label htmlFor="workgroups">Workgroups</label>
                  <TextField
                    value={userDetails.workgroupNames.map(
                      (workgroup) => workgroup.workgroupName
                    )}
                    placeholder="Workgroups"
                    InputProps={{
                      style: { fontSize: "14px" },
                      disableUnderline: true,
                    }}
                    name="workgroups"
                    className={classes.email}
                    disabled={true}
                  />
                </div> */}
              </div>
            </div>
            <div className="prompt-bottom" style={{ marginTop: 0 }}>
              <SecondaryButton
                label="Cancel"
                onClick={() => {
                  onClose(false);
                  setUserName(userDetails.userName);
                  setFirstName(userDetails.firstName);
                  setMiddleName(userDetails.middleName);
                  setLastName(userDetails.lastName);
                  setGender(userDetails.gender);
                  setHomePhone(userDetails.homePhone);
                  setMobilePhone(userDetails.mobilePhone);
                  setNationalIdentityNumber(userDetails.nationalIdentityNumber);
                  setUserLicenseNumber(userDetails.userLicenseNumber);
                  setEmployeeId(userDetails.employeeId);
                  setDesignation(userDetails.designation);
                  setAvailability(userDetails.availability);
                  setDepartment(userDetails.department);
                  setLab(userDetails.lab);
                  setLocation(userDetails.location);
                  setProfileImageUrl(userDetails.userImageSrc);
                  setChangeAvatar(false);
                }}
              />
              <PrimaryButton
                label="Save"
                onClick={!profileUploadPending ? handleSaveClick : null}
                disabled={
                  profileUploadPending ||
                  firstName.trim() === "" ||
                  lastName.trim() === "" ||
                  userName.trim() === ""
                    ? true
                    : false
                }
                extraClass="prompt-done"
              />
            </div>
          </FadeIn>
        </div>
      </React.Fragment>
    )
  );
};

const mapStateToProps = (state) => ({
  profileDetails: state.Global.loggedUserDetails,
  profileUploadPending: state.Global.profileUploadPending,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserProfileDetails: (payload, actions) =>
    dispatch(updateProfileDetails(payload, actions)),
  uploadProfilePhoto: (payload, actions) =>
    dispatch(uploadProfilePic(payload, actions)),
  fetchUserBoards: () => dispatch(fetchUserBoards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
