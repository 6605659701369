import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Input,
  FormControl,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { PrimaryButton, SecondaryButton, Notify } from "../../../components";

import { changeUserPassword } from ".././actions/profile-action.js";

const useStyles = makeStyles({
  inputFieldStyles: {
    boder: "none",
  },
  inputField: {
    display: "flex",
    flexDirection: "column",
    marginTop: 25,
  },
  eyeIcon: {
    cursor: "pointer",
    color: "#4b4b4b",
  },
  helperText: {
    color: "red",
  },
  root: {
    "& .MuiInput-input.Mui-focused": {
      color: "purple",
    },
  },
});

const ChangePassword = ({ userDetails, onClose, updateUserPassword }) => {
  const classes = useStyles();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [showPassword_1, setShowPassword_1] = useState(false);
  const [showPassword_2, setShowPassword_2] = useState(false);
  const [showPassword_3, setShowPassword_3] = useState(false);

  const [currentPasswordMatchError, setCurrentPasswordMatchError] = useState(
    false
  );

  const [touched, setTouched] = useState({
    values: {
      currentPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    },
  });

  const handleSaveClick = () => {
    !errors.currentPassword &&
      !errors.newPassword &&
      !errors.confirmNewPassword &&
      updateUserPassword(
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
          confirmNewPassword: confirmNewPassword,
          userId: userDetails.userID,
        },
        {
          onSuccess: afterChangingPasswordSuccess,
          onError: afterChangingPasswordFail,
        }
      );
  };

  const afterChangingPasswordSuccess = () => {
    setCurrentPassword("");
    setShowPassword_1(false);
    setNewPassword("");
    setShowPassword_2(false);
    setConfirmNewPassword("");
    setShowPassword_3(false);

    setTouched({
      values: {
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
      },
    });

    Notify({ value: "Password changed successfully" });
  };

  const afterChangingPasswordFail = () => {
    setShowPassword_1(false);
    setNewPassword("");
    setShowPassword_2(false);
    setConfirmNewPassword("");
    setShowPassword_3(false);

    setTouched({
      values: {
        currentPassword: true,
        newPassword: false,
        confirmNewPassword: false,
      },
    });
    setCurrentPasswordMatchError(true);
    Notify({ value: "Please check your current password !" });
  };

  const handleFocus = (field) => () => {
    setTouched({
      values: { ...touched.values, [field]: true },
    });
  };

  const validate = (currentPassword, newPassword, confirmNewPassword) => {
    const errors = {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };

    if (touched.values.currentPassword && !currentPassword.length > 0) {
      errors.currentPassword = "Current password should not be empty";
    }
    if (touched.values.currentPassword && currentPasswordMatchError) {
      errors.currentPassword = "Please enter correct password";
    }
    if (touched.values.newPassword) {
      if (!newPassword.match(/[A-Z]/g) || !newPassword.match(/[0-9]/g)) {
        if (!newPassword.match(/[A-Z]/g) && newPassword.match(/[0-9]/g)) {
          errors.newPassword = "Password must contain at least one uppercase";
        }

        if (!newPassword.match(/[0-9]/g) && newPassword.match(/[A-Z]/g)) {
          errors.newPassword = "Password must contain at least one number";
        }

        if (!newPassword.match(/[A-Z]/g) && !newPassword.match(/[0-9]/g)) {
          errors.newPassword =
            "Password must contain at least one uppercase and one number";
        }
      } else {
        if (!newPassword.match(/[a-z]/g)) {
          errors.newPassword = "Password must contain lowercase";
        }
        if (newPassword.length < 6) {
          errors.newPassword = "Password must be at least 6 characters long";
        }
      }
    }

    if (touched.values.confirmNewPassword) {
      if (confirmNewPassword !== newPassword) {
        errors.confirmNewPassword = "New passwords should match";
      }
    }

    return errors;
  };
  const errors = validate(currentPassword, newPassword, confirmNewPassword);

  return (
    <React.Fragment>
      <div>
        <span className="change-your-password">Change Your Password</span>
        <FormControl className={classes.inputField}>
          <span className="label-styles">Current Password</span>
          <Input
            disableUnderline={true}
            placeholder="Enter your current password"
            type={showPassword_1 ? "text" : "password"}
            name="currentPassword"
            value={currentPassword}
            valid={errors.currentPassword === "" ? 1 : 0}
            invalid={errors.currentPassword !== "" ? 1 : 0}
            onFocus={handleFocus("currentPassword")}
            onChange={(e) => {
              setCurrentPassword(e.target.value);
              setCurrentPasswordMatchError(false);
            }}
            className={classes.inputFieldStyles}
            endAdornment={
              <InputAdornment position="end">
                {showPassword_1 ? (
                  <a
                    className={classes.eyeIcon}
                    onClick={() => setShowPassword_1(!showPassword_1)}
                  >
                    <i className="fas fa-eye"></i>{" "}
                  </a>
                ) : (
                  <a
                    className={classes.eyeIcon}
                    onClick={() => setShowPassword_1(!showPassword_1)}
                  >
                    <i className="fas fa-eye-slash"></i>
                  </a>
                )}
              </InputAdornment>
            }
          />
          <FormHelperText
            id="component-error-text"
            className={classes.helperText}
          >
            {errors.currentPassword}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.inputField}>
          <span className="label-styles">New password</span>
          <Input
            disableUnderline={true}
            placeholder="Enter your new password"
            name="newPassword"
            type={showPassword_2 ? "text" : "password"}
            value={newPassword}
            valid={errors.newPassword === "" ? 1 : 0}
            invalid={errors.newPassword !== "" ? 1 : 0}
            onFocus={handleFocus("newPassword")}
            onChange={(e) => setNewPassword(e.target.value)}
            className={classes.inputFieldStyles}
            endAdornment={
              <InputAdornment position="end">
                {showPassword_2 ? (
                  <a
                    className={classes.eyeIcon}
                    onClick={() => setShowPassword_2(!showPassword_2)}
                  >
                    <i className="fas fa-eye large"></i>{" "}
                  </a>
                ) : (
                  <a
                    className={classes.eyeIcon}
                    onClick={() => setShowPassword_2(!showPassword_2)}
                  >
                    <i className="fas fa-eye-slash"></i>
                  </a>
                )}
              </InputAdornment>
            }
          />
          <FormHelperText
            id="component-error-text"
            className={classes.helperText}
          >
            {errors.newPassword}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.inputField}>
          <span className="label-styles">Confirm new password</span>
          <Input
            disableUnderline={true}
            placeholder="Confirm your new password"
            name="confirmNewPassword"
            type={showPassword_3 ? "text" : "password"}
            value={confirmNewPassword}
            valid={errors.confirmNewPassword === "" ? 1 : 0}
            invalid={errors.confirmNewPassword !== "" ? 1 : 0}
            onFocus={handleFocus("confirmNewPassword")}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            className={classes.inputFieldStyles}
            endAdornment={
              <InputAdornment position="end">
                {showPassword_3 ? (
                  <a
                    className={classes.eyeIcon}
                    onClick={() => setShowPassword_3(!showPassword_3)}
                  >
                    <i className="fas fa-eye large"></i>{" "}
                  </a>
                ) : (
                  <a
                    className={classes.eyeIcon}
                    onClick={() => setShowPassword_3(!showPassword_3)}
                  >
                    <i className="fas fa-eye-slash"></i>
                  </a>
                )}
              </InputAdornment>
            }
          />
          <FormHelperText
            id="component-error-text"
            className={classes.helperText}
          >
            {errors.confirmNewPassword}
          </FormHelperText>
        </FormControl>
      </div>
      <div className="prompt-bottom" style={{ marginTop: 0 }}>
        <SecondaryButton label="Cancel" onClick={() => onClose(false)} />

        <PrimaryButton
          label="Confirm"
          onClick={handleSaveClick}
          disabled={
            errors.currentPassword !== "" ||
            errors.newPassword !== "" ||
            errors.confirmNewPassword !== "" ||
            currentPassword === "" ||
            newPassword === "" ||
            confirmNewPassword === ""
          }
          extraClass="prompt-done"
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  profileDetails: state.Global.loggedUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserPassword: (payload, actions) =>
    dispatch(changeUserPassword(payload, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
