import React, { useState, useEffect } from 'react';
import PreviewImage from './preview-image.png';

const previewData = [
  {
    key: 1,
    name: 'Mitochondrial Abnormalities',
    selected: true,
    imageThumb: PreviewImage,
  },
];

const AnalysisAreaPreview = ({
  setContinueButtonDisabled,
  annotationCoordinates,
  selectedAreaName,
  setSelectedAreaName,
}) => {
  useEffect(() => {
    annotationCoordinates === null
      ? setContinueButtonDisabled(true)
      : setContinueButtonDisabled(false);
  }, [annotationCoordinates]);


  return (
    <div className="analysis-area-preview-contianer">
      <span className="selected-area-heading">Selected Area</span>
      {annotationCoordinates && (
        <div className="preview-area">
          {previewData.map((analyticsArea, index) => (
            <div
              key={index}
              className={
                analyticsArea.selected
                  ? 'analytics-area selected'
                  : 'analytics-area'
              }
              style={{ backgroundImage: `url(${analyticsArea.imageThumb})` }}
            ></div>
          ))}
          <h6> {annotationCoordinates !== null ? selectedAreaName : ''} </h6>
        </div>
      )}
    </div>
  );
};

export default AnalysisAreaPreview;
