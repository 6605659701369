import axios from "../utils/axiosInstance";

export const loggedUserDetailsApi = async () => {
  try {
    const resData = await axios.get(`/api/user/getUserDetails`);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const useLogoutApi = async () => {
  try {
    const resData = await axios.get(`/api/user/logoutUser`);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const dxAccountDetailsApi = async () => {
  try {
    const resData = await axios.get(`/api/user/dxAccountDetails`);
    return resData;
  } catch (err) {
    throw err;
  }
};
