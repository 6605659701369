import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./src/store";
import App from "./src/App";
import { SocketService } from "./src/utils/socketService";
import { SocketContext } from "./src/utils/serviceContext";
import { PersistGate } from "redux-persist/integration/react";
import SiteAdministratorDashboard from "../frontend/src/pages/siteAdminDashboard/components/SiteAdministratorDashboard";

import "./main.scss";

const element = document.getElementById("root");
const adminLoginElement = document.getElementById("admin-root");
const service = new SocketService();
const socket = service.init();

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

if (element) {
  ReactDOM.render(
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </SocketContext.Provider>,
    element
  );
} else if (adminLoginElement) {
  ReactDOM.render(
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SiteAdministratorDashboard />
        </PersistGate>
      </Provider>
    </SocketContext.Provider>,
    adminLoginElement
  );
} else {
  // USER SYSTEM (FOR NOT-LOGGEDIN USER)
  require("./utils/userSystem");
}
