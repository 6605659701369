import React, { useState, useEffect, useRef } from "react";
import closeIcon from "../../../assets/icons/close-new.svg";
import SearchIcon from "../../../assets/icons/SearchIcon.svg";
import SlideboxFilters from "./SlideboxFilters";
import Paper from "@material-ui/core/Paper";

const SlideboxSearch = ({
  getSearchDirData,
  loadSlideboxDirDataList,
  folderType,
  selectedFolderId,
  setFilteredData,
  memberDetails,
  setRadioButtonChecked,
  setSearchParams,
  isSearchTabActive,
  setIsSearchTabActive,
  showAdvancedSearchModal,
  setShowAdvancedSearchModal,
}) => {
  const [search, setSearch] = useState("");
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setShowCloseIcon(true);
      getSearchDirData({ search: event.target.value });
      setSearchParams({ search: event.target.value });
      setRadioButtonChecked({});
      setIsSearchTabActive(true);
      setSearch("");
    }
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearch = () => {
    setShowCloseIcon(true);
    getSearchDirData({ search: search });
  };

  return (
    <div
      class="slidebox-search"
      data-test="slidebox-search"
      elevation={0}
      component={Paper}
    >
      <div
        className="filter-i-input"
        style={{ position: "relative", width: "100%" }}
      >
        <input
          className="filter-input filter-slidebox"
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => handleChange(e)}
          onKeyDown={handleKeyDown}
        />
        <img
          src={SearchIcon}
          className="searchIcon"
          alt="search"
          onClick={() => handleSearch()}
        />
        {showCloseIcon && (
          <span
            className="search-close-icon"
            onClick={() => {
              setShowCloseIcon(false);
              setSearch("");
              loadSlideboxDirDataList({
                dirId: selectedFolderId,
                dirType: folderType,
              });
            }}
          >
            <img src={closeIcon} alt="" />
          </span>
        )}
        <SlideboxFilters
          memberDetails={memberDetails}
          setFilteredData={setFilteredData}
          getSearchDirData={getSearchDirData}
          search={search}
          loadSlideboxDirDataList={loadSlideboxDirDataList}
          dirType={folderType}
          selectedFolderId={selectedFolderId}
          isSearchTabActive={isSearchTabActive}
          setIsSearchTabActive={setIsSearchTabActive}
          showAdvancedSearchModal={showAdvancedSearchModal}
          setShowAdvancedSearchModal={setShowAdvancedSearchModal}
        />
      </div>
    </div>
  );
};

export default SlideboxSearch;
