import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Input } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { PrimaryButton, Notify } from "../../../../components";
import Loader from "../../../slidebox/components/SlideTable/Loader";
import {
  getAllRolesAction,
  addOrEditRoleAction,
  deleteRoleAction,
} from "../../actions/adminDashboard-action";
import EditIcon from "../../../../assets/icons/EditRoleIcon.svg";
import cancelIcon from "../../../../assets/icons/close-new.svg";
import DeleteIcon from "../../../../assets/icons/trash-icon.svg";
import Can from "../../../../hoc/FullControllHoc/can";

const useStyles = makeStyles({
  container: {
    height: "55vh !important",
  },
});

const RolesTable = ({
  showLoader,
  isPopUpOpen,
  userList,
  loggedUserEmail,
  loggedUserRoleId,
  blink,
  rolesData,
  getRolesData,
  addOrEditRole,
  deleteRoleFn,
  ...rest
}) => {
  const classes = useStyles();

  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [showCloseIcon, setShowCloseIcon] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [roleEdit, setRoleEdit] = useState([]);
  const [addRole, setAddRole] = useState(false);
  const [editRoleName, setEditRoleName] = useState([]);

  const handleAddNewRole = () => {
    setAddRole(true);
    setRoleName("");
  };

  const handleSubmitAddRole = (event) => {
    if (event.key === "Enter") {
      if (roleName === "") {
        Notify({ value: "Role name is required" });
        return;
      } else {
        addOrEditRole({ roleName, addRoleName: true });
        setAddRole(false);
      }
    }
  };

  const handleSubmitEditRole = (event, roleId) => {
    if (event.key === "Enter") {
      if (Object.values(editRoleName)[0] === "") {
        Notify({ value: "Role name is required" });
        return;
      } else {
        addOrEditRole({
          roleName: Object.values(editRoleName)[0],
          addRoleName: false,
          roleId,
        });
        setAddRole(false);
        setRoleEdit([]);
        setShowCloseIcon([]);
      }
    }
  };

  const handleRoleEdit = (index) => {
    setRoleEdit({ [index]: true });
    setShowCloseIcon({ [index]: true });
  };

  const handleDeleteRole = (roleId) => {
    deleteRoleFn({ roleId });
  };

  useEffect(() => {
    !isPopUpOpen && setSelectedRowIndex("");
  }, [isPopUpOpen]);

  useEffect(() => {
    getRolesData();
  }, []);

  return showLoader ? (
    <Loader />
  ) : (
    <div className="roles-container">
      <div className="buttons-container">
        <Can
          task={"manage-role"}
          taskContainer="roles"
          edit={() => (
            <PrimaryButton
              label="Add Role"
              onClick={() => handleAddNewRole(rolesData.length + 1)}
              extraClass="add-role"
            />
          )}
        />
      </div>
      <div className="roles-list-container">
        <TableContainer
          className={classes.container + "table-scroll"}
          elevation={0}
          component={Paper}
        >
          <Table className="roles-table" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" className="table-heading">
                  <span className="roles-list-name">Role Name</span>
                </TableCell>
                <TableCell align="left">Role Type</TableCell>
                <TableCell align="center">Number of Users</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={blink ? "blink-user-list-table" : ""}>
              {rolesData &&
                rolesData.map((role, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        align="left"
                        className="role-capitalize role-name"
                      >
                        {!roleEdit[index] && role.roleName}

                        {roleEdit[index] && showCloseIcon[index] && (
                          <Input
                            className="role-name-value"
                            defaultValue={role.roleName}
                            value={editRoleName[index]}
                            onChange={(e) =>
                              setEditRoleName({ [index]: e.target.value })
                            }
                            onKeyDown={(e) =>
                              handleSubmitEditRole(e, role.roleId)
                            }
                          />
                        )}

                        {showCloseIcon[index] && (
                          <span
                            onClick={() => {
                              setShowCloseIcon({ [index]: false });
                              setRoleEdit({ [index]: false });
                            }}
                          >
                            <img
                              width="12px"
                              height="12px"
                              src={cancelIcon}
                              className="close-icon"
                              alt="Cancel"
                            />
                          </span>
                        )}

                        {(!showCloseIcon[index] ||
                          showCloseIcon.length === 0) &&
                          role.roleName !== "System Administrator" && (
                            <React.Fragment>
                              <Can
                                task={"manage-role"}
                                taskContainer="roles"
                                edit={() => (
                                  <img
                                    width="14px"
                                    height="14px"
                                    src={EditIcon}
                                    className="edit-role-icon"
                                    onClick={() => handleRoleEdit(index)}
                                  />
                                )}
                              />
                              {role.roleType === "custom" &&
                                role.count.totalUsers === 0 && (
                                  <Can
                                    task={"manage-role"}
                                    taskContainer="roles"
                                    edit={() => (
                                      <img
                                        width="14px"
                                        height="14px"
                                        src={DeleteIcon}
                                        className="edit-role-icon"
                                        onClick={() =>
                                          handleDeleteRole(role.roleId)
                                        }
                                      />
                                    )}
                                  />
                                )}
                            </React.Fragment>
                          )}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="role-capitalize"
                        style={{ width: "25%" }}
                      >
                        {role.roleType}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="role-capitalize"
                        style={{ width: "25%" }}
                      >
                        {role.count.totalUsers}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {addRole && (
                <React.Fragment>
                  <Input
                    className="add-new-role-input"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                    onKeyDown={handleSubmitAddRole}
                    placeholder="Role name"
                    autoFocus
                  />
                  <span
                    onClick={() => {
                      setAddRole(false);
                    }}
                  >
                    <img
                      width="12px"
                      height="12px"
                      className="edit-role-icon"
                      src={cancelIcon}
                      alt="Cancel"
                    />
                  </span>
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
  rolesData: state.Dashboard.rolesData,
});

const mapDispatchToProps = (dispatch) => ({
  getRolesData: () => dispatch(getAllRolesAction()),
  addOrEditRole: (payload) => dispatch(addOrEditRoleAction(payload)),
  deleteRoleFn: (payload) => dispatch(deleteRoleAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesTable);
