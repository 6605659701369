import React from "react";
import { FadeIn } from "animate-components";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { PrimaryButton, SecondaryButton, Notify } from "../../../components";
import { changeNotificationPreference } from "../actions/profile-action";
import { connect } from "react-redux";

const preferences = [
  { id: 1, label: "Both email and application" },
  { id: 2, label: "Email only" },
  { id: 3, label: "Application only" },
  { id: 4, label: "None" },
];

const NotificationPreference = ({
  userDetails,
  onClose,
  updateNotificationPreference,
  closeModal
}) => {
  const { userID, notificationPreference } = userDetails;
  const [
    newNotificationPreference,
    setNewNotificationPreference,
  ] = React.useState(notificationPreference);

  const handleSaveClick = () => {
    updateNotificationPreference(
      { userID, newNotificationPreference },
      {
        onSuccess: afterChangingPreferenceSuccess,
        onError: afterChangingPreferenceFail,
      }
    );
    closeModal();
  };

  const afterChangingPreferenceSuccess = () => {
    Notify({ value: "Notification preference changed successfully." });
  };

  const afterChangingPreferenceFail = () => {
    Notify({ value: "Error in changing notification preference." });
  };

  return (
    <React.Fragment>
      <FadeIn duration="300ms">
        <div className="notification-preference-section">
          <span className="preference-heading">
            Notify me through...
            <a href="#" className="right-section">
              About notifications <HelpOutlineIcon fontSize="small" />
            </a>
          </span>

          <div className="custom-radio" style={{ marginTop: 30 }}>
            {preferences.map(({ label, id }, index) => (
              <div className="form-group" key={index}>
                <input
                  type="radio"
                  name="preferences"
                  value={label}
                  checked={newNotificationPreference === id}
                  onChange={(e) => {
                    setNewNotificationPreference(id);
                  }}
                />
                <span>{label}</span>
              </div>
            ))}
          </div>
          <div className="prompt-bottom" style={{ marginTop: 0 }}>
            <SecondaryButton label="Cancel" onClick={() => { onClose(false), closeModal() }} />
            <PrimaryButton
              label="Change"
              onClick={handleSaveClick}
              disabled={newNotificationPreference == notificationPreference}
            />
          </div>
        </div>
      </FadeIn>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateNotificationPreference: (payload, actions) =>
    dispatch(changeNotificationPreference(payload, actions)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationPreference);
