import React from "react";
import PropTypes from "prop-types";
import { Links } from "../index";
import { connect } from "react-redux";
import DefaultAvatar from "../../assets/icons/default-avatar.svg";
import { userLogoutAction } from "../../actions/global-action";
import EditProfile from "../../pages/profile/components/EditProfile";
import ChangePassword from "../../pages/profile/components/ChangePassword";
import AccountPreferences from "../../pages/profile/components/AccountPreferences";
import { useNameFormat } from "../../pages/adminDashboard/components/DashboardItems/useFormat";

const HeaderOptions = ({
  toggleOptions,
  isMobile,
  ref,
  userData: {
    firstName,
    lastName,
    userId,
    userEmail,
    password,
    middleName,
    gender,
    dob,
    homePhone,
    mobilePhone,
    nationalIdentityNumber,
    userLicenseNumber,
    employeeId,
    designation,
    availability,
    department,
    lab,
    location,
  },
  userData,
  userLogOut,
  dxAccountDetails,
}) => {
  let clicked = () => toggleOptions();
  const logOut = (e) => {
    userLogOut();
  };

  const [openEditProfileModal, setOpenEditProfileModal] = React.useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = React.useState(
    false
  );
  const [
    openAccountPreferencesModal,
    setOpenAccountPreferencesModal,
  ] = React.useState(false);

  const handleEditProfileModalOpen = () => {
    setOpenEditProfileModal(!openEditProfileModal);
  };

  const handleEditProfileModalClose = () => {
    setOpenEditProfileModal(false);
  };

  const handleChangePasswordModalOpen = () => {
    setOpenChangePasswordModal(!openChangePasswordModal);
  };

  const handleChangePasswordModalClose = () => {
    setOpenChangePasswordModal(false);
  };

  const handleAccountPreferencesModalOpen = () => {
    setOpenAccountPreferencesModal(!openAccountPreferencesModal);
  };
  const handleAccountPreferencesModalClose = () => {
    setOpenAccountPreferencesModal(false);
  };
  return (
    <React.Fragment>
      {isMobile ? (
        <ul className="o_ul_mobile">
          <li className="o_li_mobile_profile">
            <img
              // src={`/users/${userId}/avatar.jpg`}
              src={DefaultAvatar}
              alt="avatar"
              className="sp_img"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = { DefaultAvatar };
              }}
            />
            <div className="user_name">
              {useNameFormat(firstName, lastName)}
            </div>
            <h5>@{firstName}</h5>
          </li>
          <li className="o_li_mobile" onClick={clicked}>
            <Links
              url={`/profile/${firstName}`}
              className="o_a"
              alt="Profile"
              label="Profile"
              iconClass="profileIcon"
            />
          </li>
          <li className="o_li_mobile" onClick={clicked}>
            <Links
              url="/edit-profile"
              className="o_a"
              alt="Edit Profile"
              label="Edit Profile"
              iconClass="editProfileIcon"
            />
          </li>
          <li className="o_li_mobile o_divider">
            <hr className="menu_divider" />
          </li>

          <li className="o_li_mobile">
            <a href="/logout" className="o_a" alt="Settings">
              <span className="logoutIcon"></span>
              Logout
            </a>
          </li>
        </ul>
      ) : (
        <ul className="o_ul" ref={ref}>
          <li className="o_li" onClick={handleAccountPreferencesModalOpen}>
            <a className="o_a">
              <span className="accountPreferencesIcon"></span>
              Account Preferences
            </a>
          </li>
          <AccountPreferences
            openModal={openAccountPreferencesModal}
            onClose={handleAccountPreferencesModalClose}
            userDetails={userData}
          />
          <li className="o_li" onClick={handleEditProfileModalOpen}>
            <a className="o_a" alt="edit">
              <span className="editProfileIcon"></span>
              Edit Profile
            </a>
          </li>

          <EditProfile
            openModal={openEditProfileModal}
            onClose={handleEditProfileModalClose}
            userDetails={userData}
          />

          {/* <li className="o_li" onClick={handleChangePasswordModalOpen}>
            <a className="o_a" alt="edit">
              <span className="editProfileIcon"></span>
              Change Password
            </a>
          </li>
          <ChangePassword
            openModal={openChangePasswordModal}
            onClose={handleChangePasswordModalClose}
            userDetails={userData}
          /> */}
          {/* <li className="o_li" onClick={clicked}>
            <Links
              url="/help"
              className="o_a"
              alt="Help"
              label="Help"
              iconClass="helpIcon"
            />
          </li> */}
          <li className="o_li o_divider">
            <hr className="menu_divider" />
          </li>
          <li className="o_li">
            <a className="o_a" alt="Settings" onClick={logOut}>
              <span className="logoutIcon"></span>
              Logout
            </a>
          </li>
        </ul>
      )}
    </React.Fragment>
  );
};

HeaderOptions.propTypes = {
  toggleOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dxAccountDetails: state.Global.dxAccountDetails,
});

const mapDispatchToProps = (dispatch) => ({
  userLogOut: () => dispatch(userLogoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderOptions);
