import React, { useState } from "react";
import { Prompt } from "../../../../components";

const AnalysisProgressBar = ({
  progressState,
  setProgressState,
  loadSlideboxDirDataList,
  setSelectedDirectory,
  selectedAlgorithm,
  setFromSlidebox,
  setSelectedAlgorithm,
  setContinueButtonDisabled,
}) => {
  const [changeStep, setChangeStep] = useState({
    importSlideStep: false,
    selectAnalysisType: false,
    highlightAreas: false,
    runAnalysis: false,
  });

  const {
    importSlide,
    selectAnalysisType,
    highlightAreas,
    runAnalysis,
  } = progressState;

  const handleClickImportSlide = () => {
    setChangeStep({ importSlideStep: false });
    setFromSlidebox(false);
    loadSlideboxDirDataList({
      dirId: 1,
      dirType: "root",
    });

    setSelectedDirectory({
      dirId: 1,
      listId: 1,
      parentDir: null,
      dirType: "root",
    });

    setProgressState({
      ...progressState,
      selectAnalysisType: false,
      highlightAreas: false,
      runAnalysis: false,
    });
    setContinueButtonDisabled(true);
    setSelectedAlgorithm(null);
  };

  const handleChangeSelectAnalysisType = () => {
    setChangeStep({ selectAnalysisType: false });
    setSelectedAlgorithm(null);

    setProgressState({
      ...progressState,
      highlightAreas: false,
      runAnalysis: false,
    });
    setContinueButtonDisabled(true);
  };

  const handleClickHighlightAreas = () => {
    setChangeStep({ highlightAreas: false });

    setProgressState({
      ...progressState,
      runAnalysis: false,
    });
  };

  return (
    <div className="progress-bar-parent">
      <h3 className="progress-bar-heading">Run Analysis</h3>
      <div className="analysis-description-section">
        <p className="analysis-description">
          Leverage this AI tool on slides using selected algorithms to discover
          patterns and insights.
        </p>
      </div>
      <div className="horizontal-line"></div>
      <div className="analysis-progress-bar">
        <div
          className={"progress-line-first-step"}
          onClick={() =>
            importSlide && selectAnalysisType
              ? setChangeStep({ importSlideStep: true })
              : null
          }
        >
          Import Slide
        </div>

        <div className="seperator"></div>
        <div
          className={
            selectAnalysisType ? `progress-line selected` : `progress-line`
          }
          onClick={() =>
            selectAnalysisType && highlightAreas
              ? setChangeStep({ selectAnalysisType: true })
              : null
          }
        >
          Select Analysis Type
        </div>

        <div className="seperator"></div>
        {selectedAlgorithm === "Nuclei Segmentation" && (
          <React.Fragment>
            <div
              className={
                highlightAreas ? `progress-line selected` : `progress-line`
              }
              onClick={() =>
                highlightAreas && runAnalysis
                  ? setChangeStep({ highlightAreas: true })
                  : null
              }
            >
              Highlight Areas
            </div>

            <div className="seperator"></div>
          </React.Fragment>
        )}
        <div
          className={
            runAnalysis
              ? "progress-line selected-last-item"
              : "progress-line-last-item"
          }
        >
          Run Analysis
        </div>
      </div>

      {changeStep.importSlideStep ? (
        <Prompt
          title={"Import Slide"}
          content="Do you want to import another slide?"
          actionText="Confirm"
          customClass={"analytic-prompt"}
          action={handleClickImportSlide}
          back={() => {
            setChangeStep({ importSlideStep: false });
          }}
        />
      ) : changeStep.selectAnalysisType ? (
        <Prompt
          title={"Select Analysis Type"}
          content="Do you want to re-select Analysis Type?"
          actionText="Confirm"
          customClass={"analytic-prompt"}
          action={handleChangeSelectAnalysisType}
          back={() => {
            setChangeStep({ selectAnalysisType: false });
          }}
        />
      ) : changeStep.highlightAreas ? (
        <Prompt
          title={"Highlight Areas"}
          content="Do you want to select another area on the slide?"
          actionText="Confirm"
          customClass={"analytic-prompt"}
          action={handleClickHighlightAreas}
          back={() => {
            setChangeStep({ highlightAreas: false });
          }}
        />
      ) : null}
    </div>
  );
};

export default AnalysisProgressBar;
