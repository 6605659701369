import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { animateScroll } from "react-scroll";
import {
  addMessage, setCurrentPage, setFile,
  setHasNextPage, setMessages
} from "../../pages/groups/actions/groups-action";
import axios from "../../utils/axiosInstance";
import { SocketContext } from "../../utils/serviceContext";
import { Notify } from "../index";
import ChatHeader from "./components/chat-header";
import IsLoading from "./components/is-loading";
import MessageBar from "./components/message-bar";
import messageRender from "./message-render";


const Chat = ({
  messages,
  hasNextPage,
  currentPage,
  group,
  onGroupRefresh,
  activeTab,
  onOpenSlidebox,
  dispatch,
  selectedMembers,
  isPublicGroup,
  footerNav,
  selectedBoard,
  id,
  dxAccountDetails,
}) => {
  const socket = useContext(SocketContext);
  const [text, setText] = useState("");
  const [edit, setEdit] = useState(-1);
  const [msgHeight, setMsgHeight] = useState(0);
  const [loading, setLoading] = useState(true);
  const [groupState, setGroupState] = useState(group);
  const [disabledJoinButton, setDisabledJoinButton] = useState(false);

  useEffect(() => {
    socket.onUpdateMembers().subscribe((id) => {
      if (group.id === parseInt(id)) {
        onGroupRefresh();
      }
    });
    fetchMessages();
  }, []);
  useEffect(() => {
    fetchMessages();
  }, [groupState]);
  useEffect(() => {
    if (groupState && groupState.id !== group.id) {
      setLoading(true);
      setCurrentPage(1);
      setHasNextPage(false);
      setGroupState(group);
    }
  }, [group]);
  useEffect(() => {
    const newHeight = getMsgHeight();
    if (currentPage === 1) {
      scrollToBottom();
    } else {
      scrollToBeginningOfNewPage(
        newHeight > msgHeight ? newHeight - msgHeight : 0
      );
    }
    setMsgHeight(newHeight);
  }, [messages]);
  useEffect(() => {
    if (footerNav) {
      footerNav("middle");
    }
  }, []);
  const diffDays = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((date1.getTime() - date2.getTime()) / oneDay));
  };
  const fetchMoreMessages = () => {
    fetchMessages(currentPage + 1);
  };
  const getMsgHeight = () => {
    return document.getElementById("chat-conversation").scrollHeight;
  };
  const fetchMessages = async (page = 1) => {
    setLoading(true);
    const { status, data } = await axios.get(
      `/api/groups/msgs/${groupState.id}?page=${page}`,
      {
        withCredentials: true,
      }
    );
    if (status === 200) {
      const _msgs = data.msgs.map((msg) => ({
        email: msg.email,
        firstName: msg.firstname,
        surname: msg.surname,
        username: msg.username,
        id: msg.group_message_id,
        board: { id: groupState.id },
        type: msg.group_message_type,
        time: msg.group_message_time,
        body: msg.group_message_msg,
        userId: msg.user_id,
        img: msg.group_message_img,
        filename: msg.group_message_filename,
        status: msg.group_message_status,
        roleName: msg.roleName,
        userImageSrc: msg.userImageSrc,
      }));
      // add time message based on time between messages
      for (let i = 1; i < _msgs.length; i++) {
        if (
          typeof _msgs[i - 1] !== "undefined" &&
          diffDays(
            new Date(Date.parse(_msgs[i].time)),
            new Date(Date.parse(_msgs[i - 1].time))
          ) > 0
        ) {
          // add time message at i
          _msgs.splice(i + 1, 0, {
            type: "time",
            time: _msgs[i].time,
          });
          i += 1;
        }
      }

      dispatch(setCurrentPage(data.currentPage));
      dispatch(setHasNextPage(data.hasNextPage));
      dispatch(
        setMessages(
          _msgs.sort((a, b) => {
            let timeA = Date.parse(a.time);
            let timeB = Date.parse(b.time);
            if (timeA > timeB) return 1;
            if (timeA === timeB) return 0;
            if (timeB > timeA) return -1;
          })
        )
      );
    }
    setLoading(false);
  };
  const onChangeActiveTab = () => {
    activeTab && activeTab("details");
  };

  const send = async () => {
    if (text.trim() !== "") {
      const formData = new FormData();
      formData.append("tumorBoardId", groupState.id);
      formData.append("msg", text);
      const { status, data } = await axios
        .post("/api/groups/msgs", formData, { withCredentials: true })
        .catch(() => {
          Notify({ value: "Could not send message. " });
        });
      if (status === 201) {
        dispatch(
          addMessage({
            email: data.sender.email,
            firstName: data.sender.firstname,
            surname: data.sender.surname,
            username: data.sender.username,
            id: data.msgId,
            userImageSrc: data.sender.userImageSrc,
            board: { id: groupState.id },
            type: data.msg.group_message_type,
            time: data.msg.group_message_time,
            body: data.msg.group_message_msg,
            userId: data.sender.id,
            img: data.msg.group_message_img,
            status: data.msg.group_message_status,
          })
        );
      }
      setText("");
      dispatch(setFile(null));
    }
  };
  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: "chat-conversation",
      duration: 100,
    });
  };
  const scrollToBeginningOfNewPage = (heightDiff) => {
    if (!heightDiff) return;
    let viewHeight = document.getElementById("chat-conversation").clientHeight;
    animateScroll.scrollMore(heightDiff - viewHeight + 183, {
      containerId: "chat-conversation",
      duration: 400,
    });
  };
  const requestJoin = async () => {
    let res;
    try {
      res = await axios.post(
        "/api/groups/request",
        { tumorBoardId: group.id },
        { withCredentials: true }
      );
    } catch (err) {
      Notify({ value: "You already made a request to join this group." });
    }
    if (res && res.data) {
      Notify({ value: "Request sent." });
    }
    setDisabledJoinButton(true);
  };

  const selectedMembersId =
    selectedMembers !== null && selectedMembers.map((item) => item.id);

  return (
    <div id="group-chat" className="chat">
      {/* End Chat Header */}
      <ChatHeader
        group={selectedBoard}
        onChangeActiveTab={onChangeActiveTab}
        groupMembers={selectedMembers}
        onOpenSlidebox={onOpenSlidebox}
      />
      {selectedMembersId !== null &&
      selectedMembers !== null &&
      !selectedMembersId.includes(id) &&
      isPublicGroup &&
      true ? (
        <div className="chat-conversation" id="chat-conversation">
          <div className="submit-button-wrapper">
            <button
              onClick={requestJoin}
              disabled={disabledJoinButton}
              className={disabledJoinButton && "disabled"}
            >
              Request to join
            </button>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="chat-conversation" id="chat-conversation">
            <IsLoading loading={loading} when="component" />
            {hasNextPage ? (
              <div className="loadMore" onClick={fetchMoreMessages}>
                <p>
                  <span>Load more messages</span>
                </p>
              </div>
            ) : (
              <div className="endOfMsg">
                <p>
                  <span>Group Created</span>
                </p>
              </div>
            )}
            {messages.map((conversation, index) =>
              messageRender(conversation, index, edit, setEdit, dxAccountDetails)
            )}
          </div>
          <MessageBar send={send} text={text} setText={setText} />
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    selectedBoard,
    selectedMembers,
    messages,
    file,
    hasNextPage,
    currentPage,
  } = state.Groups;
  const { loggedUserDetails, dxAccountDetails } = state.Global;
  return {
    selectedBoard,
    id: loggedUserDetails.userID,
    selectedMembers,
    messages,
    file,
    hasNextPage,
    currentPage,
    dxAccountDetails,
  };
};

export default connect(mapStateToProps)(Chat);
