import React from "react";
import SlideTable from "./SlideTable/SlideTable";
import SlideGridView from "./SlideGridView";

const ListView = ({
  listData,
  showLoader,
  toggleDirHandler,
  setSelectedDir,
  isSearch,
  showSharedPopup,
  deleteDirItemPopup,
  renameSlideItem,
  handleToggleMovePopup,
  setShowDetails,
  setSelectedDetails,
  selectedListItem,
  showGroupsSelectPopup,
  selectedFolderType,
  isListView,
  downloadSlide,
  getFileDownloadUrlAction,
  filteredData,
  isModal,
  when,
  setContinueButtonDisabled,
  setSelectedSlideDataToAnalyze,
  importSlide,
  selectedSlideDataToAnalyze,
  getSearchDirData,
  setLabel,
  setRadioButtonChecked,
  radioButtonChecked,
  selectedDetails,
  tabOption,
  setTabOption,
  openNotesSection,
  setOpenNotesSection,
  openCreateCaseModal,
  showCreateCaseModal,
  setShowCreateCaseModal,
  openLabelsSection,
  setOpenLabelsSection,
  setOpenAllContainers,
}) => {
  return (
    <div className="slide-content"
    style={{
      width: 'auto'
    }}
    >
      <SlideTable
        setOpenAllContainers={setOpenAllContainers}
        getFileDownloadUrlAction={getFileDownloadUrlAction}
        selectedDetails={selectedDetails}
        listData={listData}
        showLoader={showLoader}
        toggleDirHandler={toggleDirHandler}
        setSelectedDir={setSelectedDir}
        isSearch={isSearch}
        showSharedPopup={showSharedPopup}
        deleteDirItemPopup={deleteDirItemPopup}
        renameSlideItem={renameSlideItem}
        handleToggleMovePopup={handleToggleMovePopup}
        setShowDetails={setShowDetails}
        setSelectedDetails={setSelectedDetails}
        selectedListItem={selectedListItem}
        showGroupsSelectPopup={showGroupsSelectPopup}
        selectedFolderType={selectedFolderType}
        isListView={isListView}
        downloadSlide={downloadSlide}
        filteredData={filteredData}
        isModal={isModal}
        when={when}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setSelectedSlideDataToAnalyze={setSelectedSlideDataToAnalyze}
        importSlide={importSlide}
        selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
        getSearchDirData={getSearchDirData}
        setLabel={setLabel}
        radioButtonChecked={radioButtonChecked}
        setRadioButtonChecked={setRadioButtonChecked}
        tabOption={tabOption}
        setTabOption={setTabOption}
        openNotesSection={openNotesSection}
        setOpenNotesSection={setOpenNotesSection}
        openCreateCaseModal={openCreateCaseModal}
        showCreateCaseModal={showCreateCaseModal}
        setShowCreateCaseModal={setShowCreateCaseModal}
        openLabelsSection={openLabelsSection}
        setOpenLabelsSection={setOpenLabelsSection}
      />
    </div>
  );
};

const GridView = ({
  listData,
  showLoader,
  toggleDirHandler,
  setSelectedDir,
  isSearch,
  showSharedPopup,
  deleteDirItemPopup,
  renameSlideItem,
  handleToggleMovePopup,
  setShowDetails,
  setSelectedDetails,
  selectedListItem,
  showGroupsSelectPopup,
  selectedFolderType,
  isListView,
  downloadSlide,
  getFileDownloadUrlAction,
  filteredData,
  isModal,
  when,
  setContinueButtonDisabled,
  setSelectedSlideDataToAnalyze,
  importSlide,
  selectedSlideDataToAnalyze,
  getSearchDirData,
  setLabel,
  setRadioButtonChecked,
  radioButtonChecked,
  selectedDetails,
  tabOption,
  setTabOption,
  openNotesSection,
  setOpenNotesSection,
  openCreateCaseModal,
  showCreateCaseModal,
  setShowCreateCaseModal,
  openLabelsSection,
  setOpenLabelsSection,
}) => {
  return (
    <div className="grid-content">
      <SlideGridView
        getFileDownloadUrlAction={getFileDownloadUrlAction}
        selectedDetails={selectedDetails}
        listData={listData}
        showLoader={showLoader}
        toggleDirHandler={toggleDirHandler}
        setSelectedDir={setSelectedDir}
        isSearch={isSearch}
        showSharedPopup={showSharedPopup}
        deleteDirItemPopup={deleteDirItemPopup}
        renameSlideItem={renameSlideItem}
        handleToggleMovePopup={handleToggleMovePopup}
        setShowDetails={setShowDetails}
        setSelectedDetails={setSelectedDetails}
        selectedListItem={selectedListItem}
        showGroupsSelectPopup={showGroupsSelectPopup}
        selectedFolderType={selectedFolderType}
        isPatientView={isListView}
        downloadSlide={downloadSlide}
        filteredData={filteredData}
        isModal={isModal}
        when={when}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setSelectedSlideDataToAnalyze={setSelectedSlideDataToAnalyze}
        importSlide={importSlide}
        selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
        getSearchDirData={getSearchDirData}
        setLabel={setLabel}
        radioButtonChecked={radioButtonChecked}
        setRadioButtonChecked={setRadioButtonChecked}
        tabOption={tabOption}
        setTabOption={setTabOption}
        openNotesSection={openNotesSection}
        setOpenNotesSection={setOpenNotesSection}
        openCreateCaseModal={openCreateCaseModal}
        showCreateCaseModal={showCreateCaseModal}
        setShowCreateCaseModal={setShowCreateCaseModal}
        openLabelsSection={openLabelsSection}
        setOpenLabelsSection={setOpenLabelsSection}
      />
    </div>
  );
};

const SlideboxListView = ({
  view = "list",
  showLoader,
  toggleDirHandler,
  setSelectedDir,
  isSearch,
  showSharedPopup,
  deleteDirItemPopup,
  renameSlideItem,
  handleToggleMovePopup,
  setShowDetails,
  setSelectedDetails,
  selectedListItem,
  getFileDownloadUrlAction,
  showGroupsSelectPopup,
  selectedListType,
  selectedList,
  selectedFolderType,
  isListView,
  downloadSlide,
  filteredData,
  isModal,
  when,
  setContinueButtonDisabled,
  setSelectedSlideDataToAnalyze = () => {},
  importSlide,
  selectedSlideDataToAnalyze,
  getSearchDirData,
  setLabel,
  radioButtonChecked,
  setRadioButtonChecked,
  tabOption,
  setTabOption,
  openNotesSection,
  setOpenNotesSection,
  openCreateCaseModal,
  showCreateCaseModal,
  setShowCreateCaseModal,
  openLabelsSection,
  setOpenLabelsSection,
  setOpenAllContainers,
}) => {
  if (view === "list")
    if (isListView) {
      return (
        <ListView
          setOpenAllContainers={setOpenAllContainers}
          getFileDownloadUrlAction={getFileDownloadUrlAction}
          listData={filteredData}
          showLoader={showLoader}
          toggleDirHandler={toggleDirHandler}
          setSelectedDir={setSelectedDir}
          isSearch={isSearch}
          showSharedPopup={showSharedPopup}
          deleteDirItemPopup={deleteDirItemPopup}
          renameSlideItem={renameSlideItem}
          handleToggleMovePopup={handleToggleMovePopup}
          setShowDetails={setShowDetails}
          setSelectedDetails={setSelectedDetails}
          selectedListItem={selectedListItem}
          showGroupsSelectPopup={showGroupsSelectPopup}
          selectedList={selectedList}
          selectedListType={selectedListType}
          selectedFolderType={selectedFolderType}
          downloadSlide={downloadSlide}
          isModal={isModal}
          when={when}
          setContinueButtonDisabled={setContinueButtonDisabled}
          setSelectedSlideDataToAnalyze={setSelectedSlideDataToAnalyze}
          importSlide={importSlide}
          selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
          getSearchDirData={getSearchDirData}
          setLabel={setLabel}
          radioButtonChecked={radioButtonChecked}
          setRadioButtonChecked={setRadioButtonChecked}
          tabOption={tabOption}
          setTabOption={setTabOption}
          openNotesSection={openNotesSection}
          setOpenNotesSection={setOpenNotesSection}
          openCreateCaseModal={openCreateCaseModal}
          showCreateCaseModal={showCreateCaseModal}
          setShowCreateCaseModal={setShowCreateCaseModal}
          openLabelsSection={openLabelsSection}
          setOpenLabelsSection={setOpenLabelsSection}

        />
      );
    } else
      return (
        <GridView
          getFileDownloadUrlAction={getFileDownloadUrlAction}
          listData={filteredData}
          showLoader={showLoader}
          toggleDirHandler={toggleDirHandler}
          setSelectedDir={setSelectedDir}
          isSearch={isSearch}
          showSharedPopup={showSharedPopup}
          deleteDirItemPopup={deleteDirItemPopup}
          renameSlideItem={renameSlideItem}
          handleToggleMovePopup={handleToggleMovePopup}
          setShowDetails={setShowDetails}
          setSelectedDetails={setSelectedDetails}
          selectedListItem={selectedListItem}
          showGroupsSelectPopup={showGroupsSelectPopup}
          selectedList={selectedList}
          selectedListType={selectedListType}
          selectedFolderType={selectedFolderType}
          downloadSlide={downloadSlide}
          isModal={isModal}
          when={when}
          setContinueButtonDisabled={setContinueButtonDisabled}
          setSelectedSlideDataToAnalyze={setSelectedSlideDataToAnalyze}
          importSlide={importSlide}
          selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
          getSearchDirData={getSearchDirData}
          setLabel={setLabel}
          radioButtonChecked={radioButtonChecked}
          setRadioButtonChecked={setRadioButtonChecked}
          tabOption={tabOption}
          setTabOption={setTabOption}
          openNotesSection={openNotesSection}
          setOpenNotesSection={setOpenNotesSection}
          openCreateCaseModal={openCreateCaseModal}
          showCreateCaseModal={showCreateCaseModal}
          setShowCreateCaseModal={setShowCreateCaseModal}
          openLabelsSection={openLabelsSection}
          setOpenLabelsSection={setOpenLabelsSection}
        />
      );
};

export default SlideboxListView;
