import React from "react";
import { FadeIn } from "animate-components";
import {
  Overlay,
  PrimaryButton,
  SecondaryButton,
} from "../../../../components";
import { Notify } from "../../../../components";
import CaseIcon from "../../../../assets/icons/case-view-icon.svg";
import CloseIcon from "../../../../assets/icons/close-new.svg";
import { makeStyles } from "@material-ui/core/styles";

const AddProcedureModal = ({
  onClose,
  addProcedureAction,
  isProcedureEdit,
  setIsProcedureEdit,
  editProcedureAction,
}) => {
  const [error, setError] = React.useState({
    procedureName: false,
  });
  const [procedureData, setProcedureData] = React.useState(isProcedureEdit ? {...isProcedureEdit} : {
    procedureName: '',
    procedureCode: '',
  });

  const handleChange = (e) => {
    if(['procedureName'].includes(e.target.name) && e.target.value === '') {
      setError({...error, [e.target.name]: true });
    } else if(['procedureName'].includes(e.target.name) && e.target.value !== '') {
      setError({...error, [e.target.name]: false });
    }
    setProcedureData({...procedureData, [e.target.name]: e.target.value});
  }

  const handleSaveClick = () => {
    addProcedureAction(procedureData);
    onClose(false);
    setIsProcedureEdit({});
  };

  const handleEditProcedure = () => {
    editProcedureAction({ ...procedureData, id: isProcedureEdit.id });
    onClose(false);
    setIsProcedureEdit({});
  }

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt add-physician-modal add-procedure-modal" data-test="add-procedure-modal">
        <FadeIn duration="300ms">
        <div className="modal-header">
            <div>
                <img src={CaseIcon} alt="case" />
                <p className="title">{isProcedureEdit ? 'Updating' : 'Creating New'} Procedure</p>
            </div>
            <img className="close-icon" src={CloseIcon} alt="close" onClick={() => onClose(false)} />
        </div>
          <div className="modal_middle">
            <div className="form-group">
                <div className="procedure-name">
            <div className="input-field">
                <label>
                Procedure Name<sup className="required">*</sup>
                </label>
                <input
                    type="text"
                    required
                    name="procedureName"
                    data-test="procedure-name"
                    onChange={handleChange}
                    value={procedureData.procedureName}
                />
                <span>{error.procedureName && 'Enter procedure name'}</span>
            </div>
            <div className="input-field">
                <label>
                Procedure Code
                </label>
                <input
                    type="text"
                    required
                    name="procedureCode"
                    data-test="first-name"
                    onChange={handleChange}
                    value={procedureData.procedureCode}
                />
            </div>
          </div>
          </div>

            {isProcedureEdit && (
              <div className="input-field deactivate">
                  <input type="checkbox" name="deactivate" id="deactivate" checked={procedureData.status === 'inactive'} onChange={(e) => {
                    e.target.checked ? 
                    setProcedureData({...procedureData, status: 'inactive' })
                    : setProcedureData({...procedureData, status: 'active' })
                  }}/>
                  <label htmlFor="deactivate">Deactivate</label>
              </div>
            )}
          </div>
          <div className="prompt-bottom" style={{ 
            marginTop: 0,
            position: 'absolute',
            bottom: 50,
            width: '100%',
          }}>
            <SecondaryButton label="Cancel" onClick={() => {
              onClose(false)
              setIsProcedureEdit({});
            }} />
            <PrimaryButton
              label="Save"
              data-test="save-btn"
              onClick={() => !isProcedureEdit ? handleSaveClick() : handleEditProcedure()}
              disabled={!procedureData.procedureName}
              extraClass="prompt-done"
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

export default AddProcedureModal;
