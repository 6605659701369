import React, { useEffect, useState } from "react";
import SearchIcon from "../../../../assets/icons/SearchIcon.svg";
import Done from "../../../../assets/icons/done.svg";
import "./CreatePatientTab.scss";
import { PrimaryButton, SecondaryButton } from "../../../../components";
import { connect } from "react-redux";
import moment from "moment";
import {
	createSlideBoxDirectory,
	getPatientData,
} from "../../../slidebox/actions/slidebox-action";
import PatientCard from "./PatientCard";

const CreatePatientTab = ({
	setOpenTab,
	onClose,
	setPatientDetails,
	patientSearchDataList,
	setPatientSearchKey,

	patientDetails,
	patientInfo,
	setPatientInfo,
	dxAccountDetails,
}) => {
	const [gender, setGender] = useState(
		patientDetails.gender === "Female" ? "Female" : "Male"
	);

	const [errorMessage, setErrorMessage] = useState(
		"This is a mandatory field"
	);
	const [showDoneIcon, setShowDoneIcon] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [searchPatientResult, setSearchPatientResult] = useState([]);
	const [searching, setSearching] = useState("");
	const [isNewPatient, setIsNewPatient] = useState(true);
	const [error, setError] = useState({
		firstName: false,
		lastName: false,
		dob: false,
		contactNumber: false,
	});
	const [number, setNumber] = useState();

	const handleNumber = (e) => {
		const value = e.target.value.replace(/\D/g, "");
		setNumber(value);
	};

	const DATE_FORMAT =
		dxAccountDetails && dxAccountDetails.date_format
			? dxAccountDetails.date_format
			: "MM-DD-YYYY";

	useEffect(() => {
		setSearchPatientResult(patientSearchDataList && patientSearchDataList);
	}, [searching]);

	const isPhoneCorrect = () => {
		const phoneRegex = /^\d{6,12}$/;
		return phoneRegex.test(patientDetails.contactNumber);
	};

	const handleChange = (e) => {
		const newInfo = { ...patientDetails };
		newInfo[e.target.name] = e.target.value;
		if (e.target.name === "dob") {
			newInfo[e.target.name] = moment(e.target.value).format(DATE_FORMAT);
		}

		setPatientDetails(newInfo);
	};

	const handleGenderChange = (value) => {
		setGender(value);
	};

	const handleSearchChange = (e) => {
		setSearching(e.target.value);
		setPatientSearchKey(e.target.value);
	};

	const handleSearchResult = (event) => {
		if (event.key === "Enter") {
			setPatientSearchKey(searching);
		}
		if (event === "icon") {
			setPatientSearchKey(searching);
		}
	};

	const handleAddPatientInField = (patient) => {
		patient.dob = moment(patient.dob, DATE_FORMAT).format("YYYY-MM-DD");
		setPatientInfo(patient);
		setGender(patient.gender);
		setSearching("");
		setIsDisabled(false);
		setPatientDetails(patient);
		setIsNewPatient(false);
	};

	const handleSavePatient = () => {
		setPatientDetails({
			...patientDetails,
			gender,
			isNewPatient,
		});
		setOpenTab("create_case");
	};

	return (
		<div className="patient-tab">
			<div
				className="prompt-middle"
				style={{ fontSize: "12px", marginTop: "5px" }}
			>
				<div className="search-patient">
					<input
						type="text"
						placeholder="Search Patient Name, MRN, Phone Number..."
						onChange={(e) => handleSearchChange(e)}
						onKeyDown={(e) => handleSearchResult(e)}
						spellCheck={false}
					/>
					<img
						src={SearchIcon}
						alt="search"
						onClick={() => handleSearchResult("icon")}
					/>

					{patientSearchDataList.length !== 0 && searching !== "" && (
						<div
							className="patientSearchResult"
							id={
								patientSearchDataList &&
								patientSearchDataList.length > 0
									? "withResults"
									: "noResults"
							}
						>
							{patientSearchDataList &&
								patientSearchDataList.map((patient, i) => (
									<PatientCard
										patient={patient}
										key={i}
										handleAddPatientInField={
											handleAddPatientInField
										}
									/>
								))}
						</div>
					)}
				</div>
				<div className="input-field mrn-number">
					<label>
						MRN Number <sup className="required">*</sup>
					</label>
					<input
						type="text"
						onChange={(e) => {
							handleChange(e);
						}}
						maxLength={32}
						name="mrnNumber"
						value={patientDetails.mrnNumber || ""}
						disabled={
							Object.keys(patientInfo).length === 0 ? false : true
						}
					/>
					{(patientDetails.mrnNumber || showDoneIcon) && (
						<div className="icon-div">
							<img src={Done} alt="search" />
						</div>
					)}
				</div>
				<div className="patient-name">
					<div className="input-field">
						<label>
							First Name<sup className="required">*</sup>
						</label>
						<input
							type="text"
							required
							name="firstName"
							onChange={(e) => handleChange(e)}
							value={patientDetails.firstName || ""}
							disabled={
								Object.keys(patientInfo).length === 0
									? false
									: true
							}
						/>
						{(patientDetails.firstName || showDoneIcon) && (
							<div className="icon-div">
								<img src={Done} alt="search" />
							</div>
						)}
						<span>{error.firstName && errorMessage}</span>
					</div>
					<div className="input-field">
						<label>Middle Name</label>
						<input
							type="text"
							name="middleName"
							onChange={(e) => handleChange(e)}
							value={patientDetails.middleName || ""}
							disabled={
								Object.keys(patientInfo).length === 0
									? false
									: true
							}
						/>
						{(patientDetails.middleName || showDoneIcon) && (
							<div className="icon-div">
								<img src={Done} alt="search" />
							</div>
						)}
					</div>
					<div className="input-field">
						<label>
							Last Name<sup className="required">*</sup>
						</label>
						<input
							type="text"
							value={patientDetails.lastName || ""}
							name="lastName"
							onChange={(e) => handleChange(e)}
							required
							disabled={
								Object.keys(patientInfo).length === 0
									? false
									: true
							}
						/>
						{(patientDetails.lastName || showDoneIcon) && (
							<div className="icon-div">
								<img src={Done} alt="search" />
							</div>
						)}
						<span>{error.lastName && errorMessage}</span>
					</div>
				</div>
				<div className="gender">
					<span>
						Gender<sup className="required">*</sup>
					</span>
					<label className="specific-gender male">
						Male
						<input
							type="radio"
							checked={gender === "Male"}
							name="gender"
							value={gender}
							onChange={(e) => handleGenderChange("Male")}
							disabled={
								Object.keys(patientInfo).length === 0
									? false
									: true
							}
						/>
						<span className="checkmark"></span>
					</label>
					<label className="specific-gender">
						Female
						<input
							type="radio"
							name="gender"
							value={gender}
							checked={gender === "Female"}
							onChange={(e) => handleGenderChange("Female")}
							disabled={
								Object.keys(patientInfo).length === 0
									? false
									: true
							}
						/>
						<span className="checkmark"></span>
					</label>
				</div>
				<div className="birthday-security">
					<div className="input-field dob">
						<label>
							Date of Birth<sup className="required">*</sup>
						</label>
						<input
							type="date"
							name="dob"
							onChange={(e) => handleChange(e)}
							value={
								(patientDetails.dob &&
									moment(
										patientDetails.dob,
										DATE_FORMAT
									).format("YYYY-MM-DD")) ||
								""
							}
							max={moment().format("YYYY-MM-DD")}
							disabled={
								Object.keys(patientInfo).length === 0
									? false
									: true
							}
							onKeyDown={(e) => e.preventDefault()}
						/>
						{(patientDetails.dob || showDoneIcon) && (
							<img src={Done} alt="search" />
						)}
					</div>
					<div className="input-field">
						<label>Social Security Number</label>
						<input
							type="text"
							required
							maxLength="9"
							name="socialSecurityNumber"
							onChange={(e) => {
								handleNumber(e);
								handleChange(e);
							}}
							value={
								Object.keys(patientInfo).length === 0
									? number
									: patientDetails.socialSecurityNumber
							}
							disabled={
								Object.keys(patientInfo).length === 0
									? false
									: true
							}
						/>
						{((Object.keys(patientInfo).length === 0
							? number
							: patientDetails.socialSecurityNumber) ||
							showDoneIcon) && (
							<div className="icon-div">
								<img src={Done} alt="search" />
							</div>
						)}
					</div>
				</div>
				<div className="phone-email">
					<div className="input-field">
						<label>
							Phone Number<sup className="required">*</sup>
						</label>
						<input
							type="text"
							required
							name="contactNumber"
							onChange={(e) => handleChange(e)}
							value={patientDetails.contactNumber || ""}
							disabled={
								Object.keys(patientInfo).length === 0
									? false
									: true
							}
						/>
						{(isPhoneCorrect() || showDoneIcon) && (
							<div className="icon-div">
								<img src={Done} alt="search" />
							</div>
						)}
					</div>
					<div className="input-field">
						<label>Email Address</label>
						<input
							type="email"
							name="patientEmail"
							value={patientDetails.patientEmail || ""}
							required
							onChange={(e) => handleChange(e)}
							disabled={
								Object.keys(patientInfo).length === 0
									? false
									: true
							}
						/>
						{(patientDetails.email || showDoneIcon) && (
							<div className="icon-div">
								<img src={Done} alt="search" />
							</div>
						)}
					</div>
				</div>

				<div className="bottom-section">
					<div className="confidential-patient"></div>
					<div className="prompt-bottom">
						<SecondaryButton
							label="Cancel"
							onClick={() => onClose()}
						/>
						<PrimaryButton
							label="Next"
							extraClass="prompt-done"
							disabled={
								!patientDetails.mrnNumber ||
								!patientDetails.firstName ||
								!patientDetails.lastName ||
								!patientDetails.dob ||
								patientDetails.dob == "Invalid date" ||
								!isPhoneCorrect()
							}
							onClick={() => handleSavePatient()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	patientSearchDataList: state.SlideBox.patientDataList,
	dxAccountDetails: state.Global.dxAccountDetails,
});

const mapDispatchToProps = (dispatch) => {
	return {
		savePatientInformations: (payload) =>
			dispatch(createSlideBoxDirectory(payload)),
		setPatientSearchKey: (payload) => dispatch(getPatientData(payload)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePatientTab);
