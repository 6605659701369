import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ClosePopupIcon from '../../../assets/icons/close-new.svg';
import { Notify, PrimaryButton, SecondaryButton } from '../../../components';
import axios from '../../../utils/axiosInstance';
import { useNameFormat } from '../../adminDashboard/components/DashboardItems/useFormat';
import { deleteTB, setSelectedBoard } from '../actions/groups-action';
import UserCard from './user-card';

const GroupLeavePopup = ({
  close,
  loggedUser,
  selectedMembers,
  selectedBoard,
  dispatch,
  changeActiveTab,
}) => {
  const [isCreator, setIsCreator] = useState(false);
  const [qty, setQty] = useState(0);

  const [searching, setSearching] = useState('');
  const [results, setResults] = useState([]);
  const [delegated, setDelegated] = useState(null);

  const memberRef = useRef(null);

  useEffect(() => {
    setIsCreator(selectedBoard.creator.id === loggedUser);
    setQty(selectedMembers.length);
  }, []);

  useEffect(() => {
    if (searching !== '') {
      search();
    }
  }, [searching]);

  const leave = async () => {
    if (isCreator && qty === 1) {
      const { status } = await axios
        .delete(`/api/groups/${selectedBoard.id}`, {
          withCredentials: true,
        })
        .catch(console.log);
      if (status === 200) {
        close();
        dispatch(setSelectedBoard(null));
        dispatch(deleteTB(selectedBoard.id));
      }
    } else if (isCreator && qty > 1 && delegated) {
      const { status } = await axios
        .patch(
          '/api/groups/leaveTB',
          { tumorBoardId: selectedBoard.id, newCreator: delegated.id },
          { withCredentials: true }
        )
        .catch(console.log);
      if (status === 200) {
        close();
        dispatch(setSelectedBoard(null));
        dispatch(deleteTB(selectedBoard.id));
      }
    } else {
      const { status } = await axios
        .patch(
          '/api/groups/leaveTB',
          { tumorBoardId: selectedBoard.id },
          { withCredentials: true }
        )
        .catch(console.log);
      if (status === 200) {
        close();
        dispatch(setSelectedBoard(null));
        dispatch(deleteTB(selectedBoard.id));
      }
    }
    changeActiveTab('');
    Notify({ value: 'You left the group' });
  };

  const search = () => {
    const _results = selectedMembers.filter((member) => {
      let name = member.firstName + ' ' + member.surname;
      if (
        member.id !== loggedUser &&
        (member.firstName.toLowerCase().includes(searching) ||
          name.toLowerCase().includes(searching) ||
          member.surname.toLowerCase().includes(searching) ||
          member.username.toLowerCase().includes(searching))
      )
        return member;
    });
    setResults(_results);
  };

  return (
    selectedBoard && (
      <div class="prompt">
        <div class="prompt-top">
          <span className="prompt-title">Leave group</span>
          <img
            src={ClosePopupIcon}
            id="close-create-board"
            onClick={() => close()}
          />
        </div>
        <div class="prompt-middle">
          {!isCreator && (
            <div>
              <p>
                Are you sure you want to leave <br />
                <b>
                  {
                    selectedBoard.name.length > 22 ?
                      selectedBoard.name.substring(0, 20) + '...'
                      : selectedBoard.name
                  }
                ?
                </b>
                <br />
                This action <b>cannot</b> be undone.
              </p>
            </div>
          )}
          {isCreator && qty === 1 && (
            <div>
              <p>
                Are you sure you want to leave <br />
                <b>
                  {
                    selectedBoard.name.length > 22 ?
                      selectedBoard.name.substring(0, 20) + '...'
                      : selectedBoard.name
                  }
                ?
                </b>
                <br />
                 You are the only member left and the tumor board will be{' '}
                <b>deleted</b>. This action <b>cannot</b> be undone.
              </p>
            </div>
          )}
          {isCreator && qty > 1 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                justifyContent: 'flex-start'
              }}
            >
              <p>
                Please select a member that will inherit the creator's position
                in the tumor board.
              </p>
              <div class="input-wrapper">
                <input
                  className="create-board-input"
                  placeholder="Add names or email addresses"
                  style={{ borderColor: '#d0cfd2', outline: "none", marginRight: '10px' }}
                  onChange={() => {
                    setSearching(memberRef.current.value.trim().toLowerCase());
                  }}
                  ref={memberRef}
                  spellCheck={false}
                />
              </div>
              {searching !== '' && (
                <div
                  className="usersSearch"
                  id={results.length > 0 ? 'withResults' : 'noResults'}
                >
                  {results.length === 0 && (
                    <p className="infoText">Could not find any users.</p>
                  )}
                  {results.map((user) => (
                    <UserCard
                      user={user}
                      key={user.username}
                      addSelf={() => {
                        setDelegated(user);
                        memberRef.current.value = '';
                        setSearching('');
                      }}
                    />
                  ))}
                </div>
              )}
              {delegated && (
                <div style={{ marginTop: '10px' }}>
                  <p>
                    Delegated User:&nbsp;
                    <b style={{ color: '#0f0da1' }}>{`${useNameFormat(firstName, surname)}`}</b>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <div class="prompt-bottom">
          <SecondaryButton label="Cancel" onClick={() => close()} />
          {
            isCreator && qty > 1 ?
              <PrimaryButton label="Leave" onClick={() => leave()} disabled={!delegated} />
              : <PrimaryButton label="Leave" onClick={() => leave()} />
          }
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  const { loggedUserDetails } = state.Global;
  const { selectedMembers, selectedBoard } = state.Groups;
  return {
    loggedUser: loggedUserDetails.userID,
    selectedMembers,
    selectedBoard,
  };
};

export default connect(mapStateToProps)(GroupLeavePopup);
