import axios from '../../../utils/axiosInstance';

export const addTumorBoard = (board) => {
  return {
    type: 'ADD_BOARD',
    payload: {
      board,
    },
  };
};

const setUserBoards = (boards) => {
  return {
    type: 'SET_BOARDS',
    payload: {
      boards,
    },
  };
};

export const fetchUserBoards = () => {
  return (dispatch) => {
    axios
      .get('/api/groups/usersGroups', { withCredentials: true })
      .then((response) => {
        if (response.status === 200)
          dispatch(setUserBoards(response.data.TumorBoards));
      })
      .catch(console.warn);
  };
};

export const setSelectedBoard = (board) => {
  return {
    type: 'SET_SELECTED_BOARD',
    payload: {
      board,
    },
  };
};

export const updateSelectedBoardLiveSync = (liveSyncData) => {
  return {
    type: 'UPDATE_SELECTED_BOARD_LIVE_SYNC',
    payload: liveSyncData,
  };
};

export const setSelectedMembers = (members) => {
  return {
    type: 'SET_SELECTED_MEMBERS',
    payload: {
      members,
    },
  };
};

export const setAdmin = (userId, admin) => {
  return {
    type: 'SET_ADMIN',
    payload: {
      userId,
      admin,
    },
  };
};

export const removeMember = (member) => {
  return {
    type: 'REMOVE_MEMBER',
    payload: {
      member,
    },
  };
};

export const deleteTB = (id) => {
  return {
    type: 'DELETE_BOARD',
    payload: {
      id,
    },
  };
};

const setInvitations = (invitations) => {
  return {
    type: 'SET_INVITATIONS',
    payload: {
      invitations,
    },
  };
};

export const fetchInvitations = () => {
  return (dispatch) => {
    axios
      .get('/api/groups/invitations', { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setInvitations(response.data.invAndSuggestions));
        }
      });
  };
};

const setRequests = (requests) => {
  return {
    type: 'SET_REQUESTS',
    payload: {
      requests,
    },
  };
};

export const fetchRequests = () => {
  return (dispatch) => {
    axios
      .get('/api/groups/requests', { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setRequests(response.data.requests));
        }
      });
  };
};

export const deleteInvitation = (id) => {
  return {
    type: 'DELETE_INVITATION',
    payload: {
      id,
    },
  };
};

export const deleteRequest = (id) => {
  return {
    type: 'DELETE_REQUEST',
    payload: {
      id,
    },
  };
};

export const addInvitation = (inv) => {
  return {
    type: 'ADD_INVITATION',
    payload: {
      inv,
    },
  };
};

export const addRequest = (req) => {
  return {
    type: 'ADD_REQUEST',
    payload: {
      req,
    },
  };
};

export const setMessages = (messages) => {
  return {
    type: 'SET_MESSAGES',
    payload: {
      messages,
    },
  };
};

export const setHasNextPage = (hasNextPage) => {
  return {
    type: 'SET_HAS_NEXT_PAGE',
    payload: {
      hasNextPage,
    },
  };
};

export const setCurrentPage = (currentPage) => {
  return {
    type: 'SET_CURRENT_PAGE',
    payload: {
      currentPage,
    },
  };
};

export const addMessage = (message) => {
  return {
    type: 'ADD_MESSAGE',
    payload: {
      message,
    },
  };
};

export const setSlideUrl = (slide, isPath) => {
  return {
    type: 'SET_TUMOR_SLIDE',
    payload: {
      slide,
      isPath,
    },
  };
};

export const deleteMessage = (id) => {
  return {
    type: 'DELETE_MESSAGE',
    payload: {
      id,
    },
  };
};

export const editMessage = (id, body) => {
  return {
    type: 'EDIT_MESSAGE',
    payload: {
      id,
      body,
    },
  };
};

export const setFile = (file) => {
  return {
    type: 'SET_FILE',
    payload: {
      file,
    },
  };
};

export const setPublicGroups = (groups) => {
  return {
    type: 'SET_PUBLIC_GROUPS',
    payload: {
      groups,
    },
  };
};
